export class CommonMethods {
  protected ignoreKeysForCopy: Record<string, null> = {};
  public copyVals(obj: Record<string, any> = {}): void {
    for (const [key, value] of Object.entries(obj)) {
      if (this.ignoreKeysForCopy[key] === undefined && this[key as keyof typeof this] !== value) {
        this[key as keyof typeof this] = value;
      }
    }
  }
}
