import {getUserPremiumLevel, isUserLoggedIn, isUserPremium} from '@Libraries/user.library';
import {openMessageModal} from '@Modals/message-modal';
import {isMobile} from 'react-device-detect';
import {openResizeModal} from '@Modals/resize-modal';
import {openTableModal} from '@Modals/user-table-modal';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import {addScheduleToPoster} from '@PosterWhiteboard/items/table-item/table-item.class';
import {DEFAULT_COLUMNS, DEFAULT_ROWS, getDefaultScheduleData, getDefaultTableData, getScheduleData, getTableData} from '@PosterWhiteboard/items/table-item/user-table';
import {openScheduleModal} from '@Modals/user-schedule-modal/user-schedule-modal';
import {LayoutData} from '@Panels/user-schedule-panel/user-schedule-panel.types';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {addMenuToPoster} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {openUserMenuModal} from '@Modals/user-menu-modal';
import {getSelectedMenuItems} from '@PosterWhiteboard/items/menu-item/user-menu';
import {openFancytextModal} from '@Modals/fancytext-modal/fancytext-modal';
import {
  AudioData,
  ElementData,
  ElementDataType,
  ImageData,
  PMWExtractedGettyStickerData,
  PMWStillStickerData,
  TableData,
  TempImageData,
  VideoData,
} from '@Libraries/add-media-library';
import type {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import {openTrimVideoModal} from '@Modals/trim-video-modal';
import {repoVideoURLForModel} from '@Libraries/user-video-library';
import type {TrimData} from '@Panels/trim-panels/trim-panels.types';
import {GA4EventName, GA4EventParamName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {openTrimAudioModal} from '@Modals/trim-audio-modal';
import {getAudioUrl} from '@Libraries/user-audio-library';
import type {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import {openCropperModal} from '@Modals/cropper-modal/cropper-modal.library';
import type {CropperPanelApplyResponse} from '@Panels/cropper-panel';
import {ReplaceMediaControlsButtons} from '@Components/replace-media-controls';
import {
  isEditorMobileVariant,
  onAddBrandAssetClickedHandler,
  removeVideoBackgroundFromId,
  removeVideoSlideBackgroundFromId,
} from '@Components/poster-editor/library/poster-editor-library';
import type {ItemData} from '@PosterWhiteboard/page/add-item.class';
import {loadImageAsync} from '@Utils/image.util';
import type {MaskingObject} from '@PosterWhiteboard/classes/masking/masking.class';
import {doesPublicFileExistAsync} from '@Utils/file.util';
import {openMaskingModal} from '@Modals/masking-modal';
import {openColorBackgroundModal} from '@Modals/color-background-modal';
import type {BackgroundColorType} from '@Panels/color-background-panel/color-background-type.types';
import {BACKGROUND_TYPE, GRADIENT_TYPE} from '@Panels/color-background-panel/color-background-type.types';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import type {RGB} from '@Utils/color.util';
import {hexToRgb, lightenDarkenColor, rgbToHex} from '@Utils/color.util';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {POSTER_GETTY_LIMIT, POSTER_MAX_DURATION} from '@PosterWhiteboard/poster/poster.types';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {DEFAULT_BACKGROUND_COLOR, MAX_VIDEO_ITEMS} from '@PosterWhiteboard/page/page.types';
import {openUserMediaModal} from '@Modals/user-media-modal';
import {UploadsMediaType} from '@Panels/user-media-panel/user-media-panel.types';
import type {CopyAndResizeData, ResizeModalPosterInformation} from '@Panels/resize-panel/resize-panel.types';
import {openStockMediaModal} from '@Modals/stock-modal';
import {openPosterEditorLanguageModal} from '@Modals/poster-editor-language-modal';
import type {UserTableProps} from '@Panels/user-table-panel/user-table-panel';
import {StockPanelTabs} from '@Panels/stock-panel/stock-panel.types';
import type {BuyCreditsResponse} from '@Components/remove-bg-purchase-modal';
import {closeModal} from '@Components/modal-container/modal-container-reducer';
import {openSettingsModal} from '@Modals/settings-modal';
import {openConfirmRemoveVideoBackgroundModal} from '@Modals/confirm-remove-video-background-modal';
import {updateTeamCredits} from '@Components/team-credits/team-credits-slice';
import {isPremiumUser} from '@Utils/user.util';
import {openResumableStockMediaMoal} from '@Modals/resumable-stock-modal/resumable-stock-modal';
import {DownloadPublishModalMode} from '@Components/poster-editor/components/poster-editor-nav-bar/components/nav-right-options/nav-right-options.types';
import {openPublishingOptionsModal} from '@Modals/publishing-options-modal/publishing-options-modal-library';
import {DisplayType} from '@Panels/poster-publishing-options-panel/poster-publishing-options-panel.types';
import {openUploadingImagesModal} from '@Modals/uploading-images-modal';
import {copyAndResizePoster, getWordFromUnits, resizePosterForResizeData, SizeUnits} from '@/poster-whiteboard/libraries/resize-poster.library';
import {PMWStockImageSource} from '@Libraries/pmw-stock-media-library.types';
import {createKeepFiltersHiddenForTab, getAnimatedStickerSources} from '@Panels/stock-panel/stock-panel.library';

interface BuyMoreCreditsParams {
  headingText?: string;
  modalTitle?: string;

  cb(credits?: number): void;
}

export const onAddItems = (elementsData: Array<ItemData | TempImageData>): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addNewItems(elementsData);
};

const onAddAudioItems = (audioItemsData: Array<AudioData>): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }

  void currentPage.items.addItems.addAudioItems(audioItemsData, {});
  trackPosterBuilderGA4Events(GA4EventName.ADD_OWN_AUDIO);
};

export const onReplaceAudioItem = (audioItemIdToReplace: string, audioItemsData: Array<AudioData>): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage || audioItemsData.length === 0) {
    return;
  }

  void window.posterEditor?.whiteboard?.audioClips.replaceAudioItem(audioItemIdToReplace, audioItemsData[0]);
};

export const onAddMediaSlideshow = (elementsData: Array<ImageData | TempImageData | VideoData | PMWStillStickerData | PMWExtractedGettyStickerData>): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  void currentPage?.items.addItems.onAddMediaSlideshow(elementsData);
};

export const addImageBackground = (elementsData: Array<ImageData | TempImageData>): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.onAddImageBackground(elementsData);
};

export const onOpenUserMediaModal = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  openUserMediaModal({
    onAddSelected: onAddItems,
    shouldLoadDataUrlImage: true,
    onAddBrandAsset: (): void => {
      void onAddBrandAssetClickedHandler();
    },
    defaultTab: UploadsMediaType.IMAGE,
  });
};

export const onOpenSlideshowUserMediaModal = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  openUserMediaModal({
    onAddSelected: onAddMediaSlideshow,
    shouldLoadDataUrlImage: true,
    onAddBrandAsset: (): void => {
      void onAddBrandAssetClickedHandler();
    },
    addInBulk: true,
    showVectors: false,
    uploadAcceptedMimetype: `image/jpeg,image/heic,image/heif,image/png,image/webp,image/gif,video/quicktime,video/mp4,video/x-m4v,video/*`,
    mediaTypesToShow: [UploadsMediaType.IMAGE, UploadsMediaType.VIDEO],
    defaultTab: UploadsMediaType.IMAGE,
  });
};

export const onOpenSlideshowStockMediaModal = (onAddSelected?: (elementsData: Array<ImageData | VideoData>) => void): void => {
  openStockMediaModal({
    showTextToImageTool: true,
    blacklistedSources: getAnimatedStickerSources(),
    onAddSelected: onAddSelected ?? onAddMediaSlideshow,
    tabsToShow: [StockPanelTabs.PHOTOS, StockPanelTabs.VIDEOS, StockPanelTabs.TEXT_TO_IMAGE, StockPanelTabs.STICKERS],
    addInBulk: !onAddSelected,
    shouldLoadDataUrlImage: true,
    hideOnClose: false,
    panelId: 'STOCK_MEDIA_VIA_SLIDESHOW',
    keepFiltersHiddenForTab: createKeepFiltersHiddenForTab([StockPanelTabs.STICKERS])
  });
};

export const onOpenUserBackgroundUploadsModal = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  openUserMediaModal({
    onAddSelected: addImageBackground,
    shouldLoadDataUrlImage: true,
    onAddBrandAsset: (): void => {
      void onAddBrandAssetClickedHandler();
    },
    mediaTypesToShow: [UploadsMediaType.IMAGE],
    maxSelectionNumber: 1,
    showVectors: false,
    uploadAcceptedMimetype: 'image/jpeg,image/heic,image/heif,image/png,image/webp,image/gif',
    defaultTab: UploadsMediaType.IMAGE_BACKGROUND,
  });
};

export const onOpenStockBackgroundModal = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  openStockMediaModal({
    onAddSelected: addImageBackground,
    shouldLoadDataUrlImage: true,
    tabsToShow: [StockPanelTabs.PHOTOS],
    defaultSearchTab: StockPanelTabs.PHOTOS,
    maxSelectionNumber: 1,
    hideOnClose: false,
    panelId: 'STOCK_MEDIA_VIA_BACKGROUND',
  });
};

export const onOpenStockMediaModal = (): void => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  if (poster.mode.isRegen()) {
    openMessageModal({
      title: window.i18next.t('pmwjs_add_stock_image_disabled_title'),
      text: window.i18next.t('pmwjs_add_stock_image_disabled_message'),
    });
    return;
  }

  openResumableStockMediaMoal({
    onAddSelected: onAddItems,
    shouldLoadDataUrlImage: true,
    showTextToImageTool: true,
    hideOnClose: true,
    panelId: 'RESUMABLE_STOCK_MEDIA_PANEL',
    tabId: 'RESUMABLE_STOCK_MEDIA_TAB_ID',
    stockTabToGridIdMapping: {
      [StockPanelTabs.ALL]: 'resumable-stock-grid-all',
      [StockPanelTabs.PHOTOS]: 'resumable-stock-grid-photos',
      [StockPanelTabs.TEXT_TO_IMAGE]: 'resumable-stock-grid-t2i',
      [StockPanelTabs.VIDEOS]: 'resumable-stock-grid-videos',
      [StockPanelTabs.SHAPES]: 'resumable-stock-grid-shapes',
      [StockPanelTabs.AUDIOS]: 'resumable-stock-grid-audios',
      [StockPanelTabs.STICKERS]: 'resumable-stock-grid-stickers',
      [StockPanelTabs.ICONS]: 'resumable-stock-grid-icons',
    },
  });
};

export const onOpenUserAudioModal = (): void => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  openUserMediaModal({
    onAddSelected: onAddAudioItems,
    defaultTab: UploadsMediaType.AUDIO,
    mediaTypesToShow: [UploadsMediaType.AUDIO],
    uploadAcceptedMimetype: 'audio/*',
  });
};

export const onOpenUserAudioUploadModalForReplace = (audioItemIdToReplace: string): void => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  openUserMediaModal({
    onAddSelected: (items: Array<AudioData>) => {
      onReplaceAudioItem(audioItemIdToReplace, items);
    },
    onAddBrandAsset: (): void => {
      void onAddBrandAssetClickedHandler();
    },
    maxSelectionNumber: 1,
    defaultTab: UploadsMediaType.AUDIO,
    mediaTypesToShow: [UploadsMediaType.AUDIO],
    uploadAcceptedMimetype: 'audio/*',
  });
};

export const onOpenStockAudioModalForReplace = (audioItemIdToReplace: string): void => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  if (poster.mode.isRegen()) {
    openMessageModal({
      title: window.i18next.t('pmwjs_add_stock_audio_disabled_title'),
      text: window.i18next.t('pmwjs_add_stock_audio_disabled_message'),
    });
    return;
  }

  openStockMediaModal({
    onAddSelected: onReplaceAudioItem.bind(null, audioItemIdToReplace),
    tabsToShow: [StockPanelTabs.AUDIOS],
    defaultSearchTab: StockPanelTabs.AUDIOS,
    hideOnClose: false,
    panelId: 'STOCK_MEDIA_VIA_AUDIO_REPLACE',
  });
};

export const onOpenReplaceAudio = (type: ReplaceMediaControlsButtons, audioItemIdToReplace: string): void => {
  switch (type) {
    case ReplaceMediaControlsButtons.EXPLORE_MEDIA:
      return onOpenStockAudioModalForReplace(audioItemIdToReplace);
    case ReplaceMediaControlsButtons.MY_UPLOADS:
      return onOpenUserAudioUploadModalForReplace(audioItemIdToReplace);
    default:
      throw new Error('unhandled type of id received from replace media controls');
  }
};

export const openTrimAudioModalForSelectedAudioItem = (): void => {
  const currentAudioItem = window.posterEditor?.whiteboard?.audioClips.getSelectedAudioItem();
  if (!window.posterEditor?.whiteboard || !currentAudioItem) {
    return;
  }

  const maxDurationForAudio = window.posterEditor.whiteboard.audioClips.getMaxAllowedDurationForAudioItem(currentAudioItem);
  openTrimAudioModal({
    duration: currentAudioItem.audioPlayer.originalDuration,
    start: currentAudioItem.audioPlayer.getTrimmedStartTime(),
    end: currentAudioItem.audioPlayer.getTrimmedEndTime(),
    source: getAudioUrl(currentAudioItem.hashedFilename),
    audioTitle: currentAudioItem.name,
    onTrim: (trimData) => {
      trackPosterBuilderGA4Events(GA4EventName.TRIM_AUDIO_MODAL, {
        [GA4EventParamName.TYPE]: isEditorMobileVariant() ? 'mobile' : 'web',
      });
      void window.posterEditor?.whiteboard?.audioClips.trimAudioItem(currentAudioItem.uid, trimData.startTime, trimData.endTime);
    },
    maxDuration: maxDurationForAudio,
  });
};

export const openVideoLimitReachedMessageModal = (): void => {
  openMessageModal({
    title: window.i18next.t('pmwjs_video_limit'),
    text: window.i18next.t('pmwjs_video_limit_message', {
      num: MAX_VIDEO_ITEMS,
    }),
  });
};

export const openGettyImageLimitReachedMessageModal = (): void => {
  trackPosterBuilderGA4Events(GA4EventName.GETTY_IMAGE_LIMIT_REACHED);
  openMessageModal({
    title: window.i18next.t('pmwjs_getty_limit'),
    text: window.i18next.t('pmwjs_getty_limit_message', {
      limit: POSTER_GETTY_LIMIT.IMAGES,
    }),
  });
};
export const openExtractedGettyStickerLimitReachedMessageModal = (): void => {
  trackPosterBuilderGA4Events(GA4EventName.EXTRACTED_GETTY_STICKER_LIMIT_REACHED);
  openMessageModal({
    title: window.i18next.t('pmwjs_extract_getty_stickers_limit'),
    text: window.i18next.t('pmwjs_extract_getty_stickers_limit_message', {
      limit: POSTER_GETTY_LIMIT.EXTRACTED_GETTY_STICKER,
    }),
  });
};
export const openGettyVideoLimitReachedMessageModal = (): void => {
  trackPosterBuilderGA4Events(GA4EventName.GETTY_VIDEO_LIMIT_REACHED);
  openMessageModal({
    title: window.i18next.t('pmwjs_getty_video_limit'),
    text: window.i18next.t('pmwjs_getty_video_limit_message', {
      num: POSTER_GETTY_LIMIT.VIDEOS,
    }),
  });
};

export const onOpenStockAudioModal = (): void => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  if (poster.mode.isRegen()) {
    openMessageModal({
      title: window.i18next.t('pmwjs_add_stock_audio_disabled_title'),
      text: window.i18next.t('pmwjs_add_stock_audio_disabled_message'),
    });
    return;
  }

  openStockMediaModal({
    onAddSelected: onAddAudioItems,
    tabsToShow: [StockPanelTabs.AUDIOS],
    defaultSearchTab: StockPanelTabs.AUDIOS,
    hideOnClose: false,
    panelId: 'STOCK_MEDIA_VIA_AUDIO',
  });
};

export const onOpenResizeModal = (): void => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  const getPosterInformationText = (): ResizeModalPosterInformation | undefined => {
    if (!window.PMW.redux.store.getState().posterEditor.isMobileVariant) {
      return undefined;
    }

    const showCustomSize = window.posterEditor?.whiteboard?.type.isCustomSize() && window.posterEditor?.whiteboard?.type.idUserPosterType === 0;
    let subText = '';
    if (showCustomSize) {
      if (poster.width && poster.units && poster.height) {
        subText = `${poster.width}${poster.units} x ${poster.height}${poster.units}`;
      }
    } else if (poster.type.description) {
      subText = poster.type.description;
    }

    return {
      posterType: window.PMW.redux.store.getState().posterEditor.posterObject?.type.displayName,
      posterDimensionText: subText,
    };
  };

  openResizeModal({
    allowSaveCustomSize: isUserLoggedIn(),
    allowMultiSelect: !isMobile && !window.PMW.isEmbedded(),
    unsavedCustomDimension: {
      width: poster.userWidth,
      height: poster.userHeight,
      units: getWordFromUnits(poster.units),
    },
    onResizeCallback: resizePosterForResizeData,
    onCopyAndResize: (data: CopyAndResizeData): void => {
      void copyAndResizePoster(data);
    },
    currentUnit: SizeUnits.PX,
    currentHeight: poster.height,
    currentWidth: poster.width,
    posterInformation: getPosterInformationText(),
  });
};

export const onOpenSettingsModal = (): void => {
  openSettingsModal({
    onLangChange: async (): Promise<void> => {
      if (window.posterEditor?.whiteboard?.savePoster.hasUnsavedChanges()) {
        await window.posterEditor?.whiteboard?.savePoster.save();
      }
    },
    isAlignmentEnabled: window.posterEditor?.whiteboard?.alignmentGuides.enabled,
    onToggleAlignment: (): void => {
      const areAlignmentGuidesEnabled = window.posterEditor?.whiteboard?.alignmentGuides.enabled;
      void window.posterEditor?.whiteboard?.alignmentGuides.updateFromObject({
        enabled: !areAlignmentGuidesEnabled,
      });
    },
    onOpenAccessibilityMenu: (): void => {
      window.PMW.initAccessibilityMenu();
    },
  });
};

export const onOpenLanguageModal = (): void => {
  openPosterEditorLanguageModal({
    panelId: 'poster-editor-language-panel',
    onLangChange: async (): Promise<void> => {
      if (window.posterEditor?.whiteboard?.savePoster.hasUnsavedChanges()) {
        await window.posterEditor?.whiteboard?.savePoster.save();
      }
    },
  });
};

const addTableToPoster = (tableData: UserTableProps): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addTableItem(
    {
      type: ElementDataType.TABLE,
      ...tableData,
    } as TableData,
    {},
    true
  );
};

export const onOpenTableModal = (): void => {
  const data = getDefaultTableData();
  openTableModal({
    isNew: true,
    initialTableData: data,
    initialRows: DEFAULT_ROWS,
    initialColumns: DEFAULT_COLUMNS,
    highlightedRows: [],
    onAddTable: addTableToPoster,
  });
};

export const openEditTableContentModal = (activeItem: TableItem): void => {
  if (activeItem.isSchedule()) {
    openEditScheduleModal(activeItem);
  } else {
    openEditTableModal(activeItem);
  }
};

export const openEditTableModal = (tableItem: TableItem): void => {
  const data = getTableData(tableItem.layoutDataMap, tableItem.rows);

  openTableModal({
    isNew: !tableItem,
    initialRows: tableItem.rows,
    initialColumns: tableItem.columns,
    onAddTable: tableItem.updateFromTableData.bind(tableItem),
    highlightedRows: data.highlightedRows,
    initialTableData: data.tableData,
  });
};

export const openEditScheduleModal = (tableItem: TableItem): void => {
  const data = getScheduleData(tableItem);

  openScheduleModal({
    isNew: !tableItem,
    unusedData: data.unusedData,
    timeFormat: data.timeFormat,
    dateFormat: data.dateFormat,
    initialColumns: data.columns,
    columnsData: data.columnData,
    initialTableData: data.tableData,
    highlightedRows: data.highlightedRows,
    initialRows: tableItem.rows,
    layout: tableItem.layoutStyle as unknown as LayoutData,
    onAddSchedule: tableItem.updateFromScheduleData.bind(tableItem),
  });
};

export const openEditMenuModal = (menuItem: MenuItem): void => {
  openUserMenuModal({
    layout: menuItem.layoutStyle,
    selectedIds: menuItem.itemIds.map((id) => {
      return `${id}`;
    }),
    selectedItems: getSelectedMenuItems(menuItem),
    onAddMenuItem: menuItem.updateFromMenuData.bind(menuItem),
  });
};

export const openCollaboratorsModal = (): void => {
  const poster = window.posterEditor?.whiteboard;
  window.PMW.openCollaboratorsDialog(poster?.hashedID, true);
};

export const onOpenDownloadModal = (mode = DownloadPublishModalMode.DOWNLOAD, option = ''): void => {
  const poster = window.posterEditor?.whiteboard;
  if (poster) {
    const doesPosterHaveUploadingImages = poster.areAnyImagesUploading();
    const imageSrc = poster.getImageSrcOfUploadingImage();

    if (doesPosterHaveUploadingImages && imageSrc) {
      openUploadingImagesModal({
        title: window.i18next.t('pmwjs_uploading_images'),
        text: window.i18next.t('pmwjs_uploading_images_download_modal_description'),
        imgSrc: imageSrc,
      });
    } else {
      openDownloadModal(mode, option);
    }
  } else {
    openDownloadModal(mode, option);
  }
};

export const openDownloadModal = (mode = DownloadPublishModalMode.DOWNLOAD, option = '', hashedId?: string): void => {
  if (window.posterEditor?.whiteboard?.drawing.isDrawModeOn) {
    void window.posterEditor?.whiteboard?.drawing.updateFromObject({
      isDrawModeOn: false,
    });
  }

  window.posterEditor?.whiteboard?.deleteItemsNotVisibleOnPoster(false);
  window.PMW.openDownloadDialog({
    isPosterMaker: window.PMW.isPosterMaker() ? 1 : 0,
    preselectPaidProduct: window.PMW.isEmbedded() && isUserPremium(),
    panelToSelect: mode,
    option,
    idPosterHash: window.posterEditor?.whiteboard?.id !== undefined && Number(window.posterEditor?.whiteboard?.id) > 0 ? window.posterEditor?.whiteboard?.hashedID : hashedId || '',
    isPremium: window.posterEditor?.whiteboard?.getCurrentPage().isPremium() ? 1 : 0,
    posterType: window.posterEditor?.whiteboard?.type.name,
    idGalleryTemplate: window.posterEditor?.whiteboard?.idGalleryTemplate,
    idTemplate: window.posterEditor?.whiteboard?.idTemplate,
    uc: window.posterEditor?.whiteboard?.savePoster.hasUnsavedChanges() ? 1 : 0,
    isVideo: window.posterEditor?.whiteboard?.isVideo() ? 1 : 0,
    hasVideoItem: window.posterEditor?.whiteboard?.getCurrentPage().items.hasVideoItem() ? 1 : 0,
    isExpensiveVideo: window.posterEditor?.whiteboard?.getCurrentPage().isExpensiveVideo() ? 1 : 0,
    isTransparent: window.posterEditor?.whiteboard?.getCurrentPage().isTransparent() ? 1 : 0,
    hasClickableLink: window.posterEditor?.whiteboard?.getCurrentPage().items.hasClickableItem() ? 1 : 0,
    pw: window.posterEditor?.whiteboard?.width,
    ph: window.posterEditor?.whiteboard?.height,
    ptw: window.posterEditor?.whiteboard?.userWidth,
    pth: window.posterEditor?.whiteboard?.userHeight,
    ptu: window.posterEditor?.whiteboard?.units,
  });
};

const openPublishingOptionsModalHelper = (idPosterHash: string, displayTypeCheck: boolean): void => {
  openPublishingOptionsModal({
    panelDetails: {
      displayType: displayTypeCheck ? DisplayType.MODAL : DisplayType.DROPDOWN,
    },
    isPosterMaker: window.PMW.isReactPosterMaker() ? 1 : 0,
    preselectPaidProduct: window.PMW.isEmbedded() && isUserPremium(),
    isPremium: window.posterEditor?.whiteboard?.getCurrentPage().isPremium() ? 1 : 0,
    posterType: window.posterEditor?.whiteboard?.type.name,
    idGalleryTemplate: window.posterEditor?.whiteboard?.idGalleryTemplate,
    idTemplate: window.posterEditor?.whiteboard?.idTemplate,
    idPosterHash,
    uc: window.posterEditor?.whiteboard?.savePoster.hasUnsavedChanges() ? 1 : 0,
    isVideo: window.posterEditor?.whiteboard?.isVideo() ? 1 : 0,
    hasVideoItem: window.posterEditor?.whiteboard?.getCurrentPage().items.hasVideoItem() ? 1 : 0,
    isExpensiveVideo: window.posterEditor?.whiteboard?.getCurrentPage().isExpensiveVideo() ? 1 : 0,
    isTransparent: window.posterEditor?.whiteboard?.getCurrentPage().isTransparent() ? 1 : 0,
    hasClickableLink: window.posterEditor?.whiteboard?.getCurrentPage().items.hasClickableItem() ? 1 : 0,
    pw: window.posterEditor?.whiteboard?.width,
    ph: window.posterEditor?.whiteboard?.height,
    ptw: window.posterEditor?.whiteboard?.userWidth,
    pth: window.posterEditor?.whiteboard?.userHeight,
    ptu: window.posterEditor?.whiteboard?.units,
  });
};
export const openNewPublishDialog = (hashedId: string | undefined): void => {
  let displayTypeCheck = isMobile || !window.PMW.isReactPosterMaker();
  let idPosterHash = hashedId || window?.posterEditor?.whiteboard?.hashedID || '';
  const poster = window.posterEditor?.whiteboard;
  if (poster) {
    const doesPosterHaveUploadingImages = poster.areAnyImagesUploading();
    const imageSrc = poster.getImageSrcOfUploadingImage();

    if (doesPosterHaveUploadingImages && imageSrc) {
      openUploadingImagesModal({
        title: window.i18next.t('pmwjs_uploading_images'),
        text: window.i18next.t('pmwjs_uploading_images_publishing_modal_description'),
        imgSrc: imageSrc,
      });
    } else {
      void poster.savePoster.save().then(() => {
        displayTypeCheck = isMobile || !window.PMW.isReactPosterMaker();
        idPosterHash = hashedId || window?.posterEditor?.whiteboard?.hashedID || '';
        openPublishingOptionsModalHelper(idPosterHash, displayTypeCheck);
      });
    }
  } else {
    openPublishingOptionsModalHelper(idPosterHash, displayTypeCheck);
  }
};
export const onOpenUploadsMediaSlideshowModal = (onAddSelected: (items: Array<ItemData>) => void): void => {
  openUserMediaModal({
    onAddSelected,
    shouldLoadDataUrlImage: true,
    onAddBrandAsset: (): void => {
      void onAddBrandAssetClickedHandler();
    },
    showVectors: false,
    uploadAcceptedMimetype: 'image/jpeg,image/heic,image/heif,image/png,image/webp,image/gif,video/quicktime,video/mp4,video/x-m4v,video/*',
    defaultTab: UploadsMediaType.IMAGE,
    mediaTypesToShow: [UploadsMediaType.IMAGE, UploadsMediaType.VIDEO],
  });
};

export const onOpenFancyTextModal = (): void => {
  openFancytextModal({
    onAddSelected: (data: ItemData): void => {
      onAddItems([data]);
    },
  });
};

export const onOpenScheduleModal = (): void => {
  const data = getDefaultScheduleData();

  openScheduleModal({
    isNew: true,
    unusedData: data.unusedData,
    timeFormat: data.timeFormat,
    dateFormat: data.dateFormat,
    initialColumns: data.columns,
    columnsData: data.columnData,
    initialTableData: data.tableData,
    highlightedRows: data.highlightedRows,
    initialRows: DEFAULT_ROWS,
    layout: LayoutData.SLANTED_SPORTS_LAYOUT,
    onAddSchedule: addScheduleToPoster,
  });
};

export const onOpenMenuModal = (): void => {
  openUserMenuModal({
    layout: LayoutTypes.MENU_LAYOUT_1,
    selectedIds: [],
    selectedItems: [],
    onAddMenuItem: addMenuToPoster,
  });
};

const trimVideoCallback = async (item: VideoItem | VideoSlideItem, trimData: TrimData): Promise<void> => {
  if (item.isVideoSlide()) {
    await item.slideshow.updateSlideFromObject(
      item.uid,
      {
        ...trimData,
        slideDuration: trimData.endTime - trimData.startTime,
      },
      {checkForDurationUpdate: true}
    );
    await item.onItemTrimmed();
    return;
  }

  await item.updateFromObject(
    {
      ...trimData,
    },
    {checkForDurationUpdate: true}
  );
  await item.onItemTrimmed();
};

export const onOpenTrimVideoModal = (activeItem: VideoItem | VideoSlideItem, forceTrim = false, callback = (): void => {}): void => {
  let showMaxDurationError = false;
  let {endTime} = activeItem;
  let maxDuration = activeItem.duration;

  if (activeItem.duration > POSTER_MAX_DURATION) {
    showMaxDurationError = true;
    maxDuration = POSTER_MAX_DURATION;
  }

  if (activeItem.endTime - activeItem.startTime > POSTER_MAX_DURATION) {
    endTime = activeItem.startTime + maxDuration;
  }

  openTrimVideoModal({
    isMuted: activeItem.isMuted,
    scaledHeight: activeItem.getScaledHeight(),
    scaledWidth: activeItem.getScaledWidth(),
    duration: activeItem.duration,
    start: activeItem.startTime,
    end: endTime,
    source: repoVideoURLForModel(activeItem),
    maxDuration: POSTER_MAX_DURATION,
    panelId: 'trim-video-modal',
    onTrim: async (trimData: TrimData): Promise<void> => {
      trackPosterBuilderGA4Events(GA4EventName.TRIM_VIDEO_MODAL, {
        [GA4EventParamName.TYPE]: isEditorMobileVariant() ? 'mobile' : 'web',
      });
      await trimVideoCallback(activeItem, trimData);
      callback();
    },
    showMaxDurationError,
    forceTrim,
  });
};

export const onClickRemoveVideoBG = async (item: VideoItem | VideoSlideItem, remainingTeamCredits: number | null): Promise<void> => {
  if (!isPremiumUser(getUserPremiumLevel())) {
    window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_VIDEO_BG_REMOVALS);
    return;
  }

  void window.posterEditor?.whiteboard?.pause();

  if (item.removeBackground.isBackgroundRemoved && !item.removeBackground.isBackgroundBeingRemoved) {
    await item.disableRemoveBackgroundFlag();
    return;
  }

  if (remainingTeamCredits === null) {
    return;
  }

  const creditsToCharge = await item.removeBackground.getCreditsToChargeForBackgroundRemovalForUser();
  if (creditsToCharge > 0 && remainingTeamCredits >= creditsToCharge) {
    void onOpenRemoveVideoBackgroundModal(item, creditsToCharge, remainingTeamCredits);
  } else if (remainingTeamCredits < creditsToCharge) {
    triggerBuyMoreCredits({
      headingText: window.i18next.t('pmwjs_credits_pack_on_background_removal', {creditsRemaining: remainingTeamCredits, creditsCost: creditsToCharge}),
      cb: onCreditsPurchaseSuccess.bind(this, item),
    });
  } else {
    void onConfirmRemoveBG(item);
  }
};

export const onCreditsPurchaseSuccess = (item: VideoItem | VideoSlideItem, updatedCredits: number): void => {
  updateTeamCreditsOnPurchaseSuccess(updatedCredits);
  void onClickRemoveVideoBG(item, updatedCredits);
};

const updateTeamCreditsOnPurchaseSuccess = (credits: number): void => {
  window.PMW.redux.store.dispatch(updateTeamCredits({credits}));
};

export const onOpenRemoveVideoBackgroundModal = async (
  activeItem: VideoItem | VideoSlideItem,
  creditsToCharge: number,
  remainingBackgroundRemovalCredits: number
): Promise<void> => {
  openConfirmRemoveVideoBackgroundModal({
    onConfirm: onConfirmRemoveBG.bind(this, activeItem),
    remainingCredits: remainingBackgroundRemovalCredits,
    creditsToCharge,
    videoHashedFilename: activeItem.hashedFilename,
    videoFileExtension: activeItem.fileExtension,
    videoDuration: activeItem.getDuration(),
  });
};

export const onConfirmRemoveBG = async (item: VideoItem | VideoSlideItem): Promise<void> => {
  if (item.isVideoSlide()) {
    removeVideoSlideBackgroundFromId(item.slideshow.uid, item.uid);
  } else {
    removeVideoBackgroundFromId(item.uid);
  }
};

export const onOpenCropModal = (activeItem: ImageItem | ImageSlideItem): void => {
  const onApplyCallback = (cropPanelResponse: CropperPanelApplyResponse): void => {
    if (activeItem.isImageSlide()) {
      void activeItem.slideshow.updateSlideFromObject(activeItem.uid, {
        cropData: {
          cropped: true,
          ...cropPanelResponse.cropData,
        },
        removeBackground: {
          isBackgroundRemoved: cropPanelResponse.isBackgroundRemoved,
        },
      });
      return;
    }

    void activeItem.updateFromObject({
      cropData: {
        cropped: true,
        ...cropPanelResponse.cropData,
      },
      removeBackground: {
        isBackgroundRemoved: cropPanelResponse.isBackgroundRemoved,
      },
    });
  };

  openCropperModal({
    onApply: onApplyCallback,
    imageHashedFilename: activeItem.hashedFilename,
    dataUrl: activeItem.uploadingImageData?.dataUrl,
    isInitiallyBackgroundRemoved: activeItem.removeBackground.isBackgroundRemoved,
    imageSource: activeItem.imageSource,
    initialCropData: activeItem.cropData,
    showRemoveBackgroundOption: activeItem.imageSource !== PMWStockImageSource.PMW_STOCK_IMAGE,
  });
};

export const onOpenReplaceBackground = (type: ReplaceMediaControlsButtons): void => {
  const openStockBackgroundModal = (): void => {
    openStockMediaModal({
      onAddSelected: addImageBackground,
      shouldLoadDataUrlImage: true,
      maxSelectionNumber: 1,
      tabsToShow: [StockPanelTabs.PHOTOS, StockPanelTabs.TEXT_TO_IMAGE],
      defaultSearchTab: StockPanelTabs.PHOTOS,
      showTextToImageTool: true,
      hideOnClose: false,
      panelId: 'STOCK_MEDIA_VIA_REPLACE_BACKGROUND',
    });
  };

  const openMyBackgroundsModal = (): void => {
    openUserMediaModal({
      onAddSelected: addImageBackground,
      shouldLoadDataUrlImage: true,
      onAddBrandAsset: (): void => {
        void onAddBrandAssetClickedHandler();
      },
      mediaTypesToShow: [UploadsMediaType.IMAGE],
      maxSelectionNumber: 1,
      showVectors: false,
      uploadAcceptedMimetype: 'image/jpeg,image/heic,image/heif,image/png,image/webp,image/gif',
      defaultTab: UploadsMediaType.IMAGE_BACKGROUND,
    });
  };

  switch (type) {
    case ReplaceMediaControlsButtons.EXPLORE_MEDIA:
      return openStockBackgroundModal();
    case ReplaceMediaControlsButtons.MY_UPLOADS:
      return openMyBackgroundsModal();
    default:
      throw new Error('unhandled type of id received from replace media controls');
  }
};

export const onOpenReplaceMedia = (
  type: ReplaceMediaControlsButtons,
  item: ImageItem | ImageSlideItem | VideoItem | VideoSlideItem,
  onReplace: (itemData: ItemData) => void
): void => {
  const onOpenStockModal = (): void => {
    openStockMediaModal({
      onAddSelected: (items: Array<ElementData>) => {
        onReplace(items[0]);
      },
      shouldLoadDataUrlImage: true,
      maxSelectionNumber: 1,
      tabsToShow: [StockPanelTabs.PHOTOS, StockPanelTabs.VIDEOS, StockPanelTabs.TEXT_TO_IMAGE],
      defaultSearchTab: item.isVideo() ? StockPanelTabs.VIDEOS : StockPanelTabs.PHOTOS,
      showTextToImageTool: true,
      hideOnClose: false,
      panelId: 'STOCK_PANEL_VIA_REPLACE_MEDIA',
    });
  };

  const openUploadsMediaModal = (): void => {
    openUserMediaModal({
      shouldLoadDataUrlImage: true,
      onAddSelected: (items: Array<ElementData>) => {
        onReplace(items[0]);
      },
      maxSelectionNumber: 1,
      onAddBrandAsset: (): void => {
        void onAddBrandAssetClickedHandler();
      },
      showVectors: !item.isSlideshowSlide(),
      uploadAcceptedMimetype: `${item.isSlideshowSlide() ? 'image/jpeg,image/heic,image/heif,image/png,image/webp,image/gif' : 'image/*,image/heic,image/heif'},video/quicktime,video/mp4,video/x-m4v,video/*`,
      defaultTab: item.isImage() || item.isImageSlide() ? UploadsMediaType.IMAGE : UploadsMediaType.VIDEO,
      mediaTypesToShow: [UploadsMediaType.IMAGE, UploadsMediaType.VIDEO],
    });
  };

  switch (type) {
    case ReplaceMediaControlsButtons.EXPLORE_MEDIA:
      return onOpenStockModal();
    case ReplaceMediaControlsButtons.MY_UPLOADS:
      return openUploadsMediaModal();
    default:
      throw new Error('unhandled type of id received from replace media controls');
  }
};

export const onOpenMaskingModal = async (item: ImageItem | ImageSlideItem): Promise<void> => {
  const imageUrl = await item.getImageUrl();
  const image = await loadImageAsync(imageUrl);

  const maskingModalCallback = (maskingObjects: Array<MaskingObject>): void => {
    void item.updateMasking(maskingObjects[0] ?? undefined);
  };

  // TODO: This code logic needs to be better; Right now the masking modal doesn't handle a different size image url too
  const removeBGCallback = async (isBackgroundRemoved: boolean, replaceImageCallback: (url: string) => void): Promise<boolean> => {
    if (isBackgroundRemoved) {
      const sourceImageURL = await item.getImageUrl();
      replaceImageCallback(sourceImageURL);
      return false;
    }
    const removedBGURL = await item.removeBackground.getBackgroundRemovedURLForCurrentImageURL();
    if (await doesPublicFileExistAsync(removedBGURL)) {
      replaceImageCallback(removedBGURL);
    } else {
      try {
        showLoading('removing-background-masking-panel');
        await item.removeBackground.removeBackground();
        replaceImageCallback(removedBGURL);
      } catch (e) {
        return false;
      } finally {
        hideLoading('removing-background-masking-panel');
      }
    }

    return true;
  };

  openMaskingModal({
    onMaskCTA: maskingModalCallback,
    initialMaskingData: item.masking.maskingItem?.toObject(),
    imageUrl,
    cropData: item.cropData.isCropped() ? item.cropData.getScaledCropData(image) : undefined,
    removeBackgroundCallback: removeBGCallback,
    isBackgroundRemoved: item.removeBackground.isBackgroundRemoved,
  });
};

export const openColorBackgroundPanel = (bgType: BACKGROUND_TYPE): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    throw new Error(`Current page does not exist`);
  }
  const {background} = currentPage;

  let primaryColor = DEFAULT_BACKGROUND_COLOR;
  let secondaryColor = '';
  let gradientType = GRADIENT_TYPE.LINEAR;

  if (background.details.type !== BackgroundTypeName.IMAGE) {
    if (background.details.fill.fillType === FillTypes.SOLID) {
      primaryColor = rgbToHex(background.details.fill.fillColor[0]);
    }

    if (background.details.fill.hasGradientFill()) {
      primaryColor = rgbToHex(background.details.fill.fillColor[0]);
      secondaryColor = rgbToHex(background.details.fill.fillColor[1]);

      if (background.details.fill.hasRadialGradientFill()) {
        gradientType = GRADIENT_TYPE.RADIAL;
      }
    }
  }

  const getNewFillTypeFromObj = (obj: BackgroundColorType): FillTypes => {
    if (obj.backgroundType === BACKGROUND_TYPE.GRADIENT) {
      if (obj.gradientType === GRADIENT_TYPE.LINEAR) {
        return FillTypes.LINEAR_GRADIENT;
      }
      return FillTypes.RADIAL_GRADIENT;
    }

    return FillTypes.SOLID;
  };

  const getNewFillColorFromObj = (obj: BackgroundColorType): Array<RGB> => {
    const newFillType = getNewFillTypeFromObj(obj);
    switch (newFillType) {
      case FillTypes.SOLID:
        return [hexToRgb(obj.primaryColor)];
      case FillTypes.LINEAR_GRADIENT:
      case FillTypes.RADIAL_GRADIENT:
        return [hexToRgb(obj.primaryColor), hexToRgb(obj.secondaryColor)];
      default:
        throw new Error(`expected fill type to be solid or gradient`);
    }
  };

  const onApplyBackground = (obj: BackgroundColorType): void => {
    void currentPage.background.updateFromObject({
      details: {
        type: BackgroundTypeName.COLOR,
        fill: {
          fillType: getNewFillTypeFromObj(obj),
          fillColor: getNewFillColorFromObj(obj),
        },
      },
    });
  };

  void openColorBackgroundModal({
    primaryColor,
    secondaryColor: bgType === BACKGROUND_TYPE.GRADIENT && secondaryColor === '' ? lightenDarkenColor(hexToRgb(primaryColor), 100) : secondaryColor,
    onApplyChanges: onApplyBackground,
    bgType,
    gradientType,
  });
};

export const triggerBuyMoreCredits = (
  params: BuyMoreCreditsParams = {
    headingText: '',
    modalTitle: '',
    cb: (): void => {},
  }
): void => {
  const creditPurchaseCallBack = (data: BuyCreditsResponse): void => {
    if (window.posterEditor?.config?.isUnifiedCredits) {
      params.cb(data.creditsV2);
    } else {
      params.cb(data.credits);
    }
    if (!window.PMW.enableCheckoutV2) {
      closeModal('checkout-modal');
    }
  };

  if (window.PMW.enableCheckoutV2) {
    window.PMW.util.require(typeof window.PMW.stripeCheckout === 'undefined', 'stripeCheckoutDialog', true, () => {
      window.PMW.stripeCheckout.showStripeCheckoutForCreditPackPurchase(creditPurchaseCallBack, () => {}, params.headingText, params.modalTitle);
    });
  } else {
    window.PMW.checkout.showCheckoutDialog(window.PMW.checkout.MODE_BUY_CREDIT_PACK, {cb: creditPurchaseCallBack});
  }
};
