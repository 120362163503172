import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {ConfirmationPanelProps} from '@Panels/confirmation-panel/confirmation-panel';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'confirmation-modal-panel';

export type ConfirmationModalProps = ConfirmationPanelProps;

export default function ConfirmationModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.CONFIRMATION_MODAL];
  });

  const width = '560px';
  let height = '450px';

  if (modalData.panelProps.modalHeight) {
    height = modalData.panelProps.modalHeight as string;
  }

  return (
    <Modal modalId={MODALS_IDS.CONFIRMATION_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="confirmation-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openConfirmationModal = (props: ConfirmationModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.CONFIRMATION_MODAL,
      panelProps: props,
    })
  );
};
