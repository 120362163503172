import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {areObjectsEqual} from '@Utils/object.util';
import type {ItemFabricObject} from '@PosterWhiteboard/items/item/item.class';
import {closePosterEditorMoreOptionsModal} from '@Modals/poster-editor-more-options-modal/poster-editor-more-options-modal';
import {getSymmetricDifferenceBetweenArrays} from '@Utils/array.util';
import type {Canvas, FabricObject} from '@postermywall/fabricjs-2';
import {ActiveSelection, config} from '@postermywall/fabricjs-2';

interface ActiveSelectionPropsToCheckModification {
  left: number;
  top: number;
  scaleX: number;
  scaleY: number;
  angle: number;
}

export interface ItemsMultiSelectObject {
  enabled: boolean;
}

export class ItemsMultiSelect extends CommonMethods {
  private poster: Poster;
  private activeSelectionOnMouseDown: FabricObject | ActiveSelection | undefined;
  private selectedItemsOnMouseDown: string[] = [];
  private activeSelectionPropsOnMouseDown: ActiveSelectionPropsToCheckModification | undefined;
  public enabled = false;
  public isActiveSelectionModificationProcessing = false;

  constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public toObject(): ItemsMultiSelectObject {
    return {
      enabled: this.enabled,
    };
  }

  public updateFromObject(itemsMultiSelectObject: Partial<ItemsMultiSelectObject>): void {
    this.copyVals(itemsMultiSelectObject);
    this.invalidate();
    this.poster.redux.updateReduxData();
  }

  private invalidate(): void {
    config.enableGroupSelection = this.enabled;
  }

  public onMouseDownBefore(): void {
    if (this.enabled) {
      const currentPage = this.poster.getCurrentPage();
      const canvas = currentPage.fabricCanvas as Canvas;
      const selectedItem = canvas.getActiveObject() as FabricObject | ActiveSelection;

      this.activeSelectionOnMouseDown = selectedItem && selectedItem instanceof ActiveSelection ? selectedItem : undefined;

      if (this.activeSelectionOnMouseDown) {
        const activeSelectionOnMouseDown = this.activeSelectionOnMouseDown as ActiveSelection;
        this.selectedItemsOnMouseDown = currentPage.getSelectedItems().map((item) => {
          return item.uid;
        });
        this.activeSelectionPropsOnMouseDown = {
          left: activeSelectionOnMouseDown.left,
          top: activeSelectionOnMouseDown.top,
          scaleX: activeSelectionOnMouseDown.scaleX,
          scaleY: activeSelectionOnMouseDown.scaleY,
          angle: activeSelectionOnMouseDown.angle,
        };
        activeSelectionOnMouseDown.subTargetCheck = true;
      } else {
        this.selectedItemsOnMouseDown = [];
        this.activeSelectionPropsOnMouseDown = undefined;
      }
    }
  }

  public onMouseUp(): void {
    if (this.poster.itemsMultiSelect.enabled && this.activeSelectionOnMouseDown) {
      const currentPage = this.poster.getCurrentPage();
      const canvas = currentPage.fabricCanvas as Canvas;
      const selectedObject = canvas.getActiveObject();
      const selectedItems = currentPage.getSelectedItems();
      let objectsInActiveSelection;
      const activeSelectionOnMouseUp = selectedObject && selectedObject instanceof ActiveSelection ? selectedObject : undefined;

      if (activeSelectionOnMouseUp) {
        objectsInActiveSelection = activeSelectionOnMouseUp.getObjects();
      }

      if (this.activeSelectionOnMouseDown && activeSelectionOnMouseUp && this.activeSelectionOnMouseDown === activeSelectionOnMouseUp) {
        const selectedItemsOnMouseUp = selectedItems.map((item) => {
          return item.uid;
        });
        const activeSelectionPropsOnMouseUp = {
          left: activeSelectionOnMouseUp.left,
          top: activeSelectionOnMouseUp.top,
          scaleX: activeSelectionOnMouseUp.scaleX,
          scaleY: activeSelectionOnMouseUp.scaleY,
          angle: activeSelectionOnMouseUp.angle,
        };

        if (
          this.activeSelectionPropsOnMouseDown &&
          areObjectsEqual(this.activeSelectionPropsOnMouseDown, activeSelectionPropsOnMouseUp) &&
          getSymmetricDifferenceBetweenArrays(this.selectedItemsOnMouseDown, selectedItemsOnMouseUp).length === 0
        ) {
          let itemToUnselect;
          if (canvas.targets) {
            [itemToUnselect] = canvas.targets;

            if (itemToUnselect && objectsInActiveSelection) {
              const index = objectsInActiveSelection.indexOf(itemToUnselect);
              if (index > -1) {
                // only splice array when item is found
                objectsInActiveSelection.splice(index, 1);

                this.poster.itemsMultiSelect.isActiveSelectionModificationProcessing = true;
                currentPage.activeSelection.selectFabricObjects(objectsInActiveSelection as ItemFabricObject[]);
              }
            }
          }
        }

        this.selectedItemsOnMouseDown = [];
        this.activeSelectionPropsOnMouseDown = undefined;
        this.poster.itemsMultiSelect.isActiveSelectionModificationProcessing = false;
      }
    }
  }

  public onSelectionCleared(): void {
    if (!this.isActiveSelectionModificationProcessing) {
      closePosterEditorMoreOptionsModal();
      this.updateFromObject({
        enabled: false,
      });
    }
  }
}
