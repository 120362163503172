import type {Page} from '@PosterWhiteboard/page/page.class';
import {PosterHistory} from '@PosterWhiteboard/poster/poster-history/poster-history';
import type {PosterEvents, PosterLoadObject, PosterObject} from '@PosterWhiteboard/poster/poster.types';
import {POSTER_VERSION, POSTER_GETTY_LIMIT} from '@PosterWhiteboard/poster/poster.types';
import {SavePoster} from '@PosterWhiteboard/save-poster/save-poster.class';
import {User} from '@PosterWhiteboard/user/user.class';
import {PosterType} from '@PosterWhiteboard/poster-type/poster-type.class';
import {initDefaultFabricControls} from '@PosterWhiteboard/poster/poster-item-controls';
import {Observable} from '@PosterWhiteboard/observable';
import {PosterPages} from '@PosterWhiteboard/poster/poster-pages.class';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {AudioClips} from '@PosterWhiteboard/classes/audio-clips/audio-clips.class';
import {Clock} from '@PosterWhiteboard/clock/clock.class';
import {PosterDrawing} from '@PosterWhiteboard/poster/poster-drawing/poster-drawing';
import {Grid} from '@PosterWhiteboard/poster/grid.class';
import {Bleed} from '@PosterWhiteboard/poster/bleed.class';
import {AlignmentGuides} from '@PosterWhiteboard/poster/alignment-guides.class';
import {Fold} from '@PosterWhiteboard/poster/fold.class';
import {PosterRedux} from '@PosterWhiteboard/poster/poster-redux.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {PosterScaling} from '@PosterWhiteboard/poster/poster-scaling.class';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {PosterModeDetails, PosterModeObject} from '@PosterWhiteboard/poster/poster-mode.class';
import {PosterMode, PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import {openPosterInGenerationWarningModal} from '@Modals/poster-in-generation-warning-modal';
import {ImageBackground} from '@PosterWhiteboard/page/background/image-background.class';
import {v4 as uuidv4} from 'uuid';
import {ResizePoster} from '@PosterWhiteboard/poster/resize-poster.class';
import type {RGB} from '@Utils/color.util';
import {DEFAULT_FILL_COLOR} from '@PosterWhiteboard/classes/fill.class';
import type {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {CornerPointsArray} from '@Utils/math.util';
import {isIOS, isSafari} from 'react-device-detect';
import {PosterVideoGeneration} from '@PosterWhiteboard/poster/poster-video-generation.class';
import {openMessageModal} from '@Modals/message-modal';
import {openDownloadModal} from '@Components/poster-editor/library/poster-editor-open-modals';
import {openErrorModal} from '@Modals/error-modal';
import {ItemsMultiSelect} from '@PosterWhiteboard/poster/items-multi-select.class';
import {PosterPan} from '@PosterWhiteboard/poster/poster-pan.class';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {config} from '@postermywall/fabricjs-2';
import {getPosterBackendObjectFromPoster} from '@PosterWhiteboard/poster/poster-frontend-to-backend';
import type {PosterObjectBackend} from '@PosterWhiteboard/poster/poster-backend.types';
import type {DeepPartial} from '@/global';
import type {FolderObject} from '../classes/folder.class';
import {Folder} from '../classes/folder.class';
import Emitter from '@/services/event-emitter';
import {EmbeddedEditorMessageType} from '@Libraries/embedded-editor';

const WEBGL_TEXTURE_SIZE = 4096;
const GENERATION_WEBGL_TEXTURE_SIZE = 10800;

export interface PosterOpts {
  mode: PosterModeObject;
  folder?: FolderObject;
  isHighRes?: boolean;
  ageOfGeneratedOrder?: number;
}

export class Poster extends Observable<PosterEvents> {
  public id = '';
  public hashedID = '';
  public name = '';
  public description = '';
  public folder: Folder;
  public isTemplate = false;
  public isPublic = false;
  public isCopyable = true;
  public isPurchaseable = true;
  public createdOn = 0;
  public lastModified = 0;
  public idLastModifier = '';
  public isInternal = false;
  public idTemplate = '';
  public idGalleryTemplate?: string;
  public idGalleryTemplateCreator = 0;
  public seoName = '';
  public version: number = POSTER_VERSION.CURRENT;
  public height!: number;
  public width!: number;
  public type!: PosterType;
  public units = '';
  public creator?: User;
  public owner?: User;
  public userWidth = 0;
  public userHeight = 0;
  public uploadingImages: string[] = [];
  public audioClips: AudioClips;
  public clock;
  public pages: PosterPages;

  public HTMLElement: HTMLDivElement;
  private currentPageId = '';
  public isHighRes = false;
  public ageOfGeneratedOrder?: number;
  public mode: PosterMode;
  public drawing: PosterDrawing;
  public pan: PosterPan;
  public itemsMultiSelect: ItemsMultiSelect;
  public grid: Grid;
  public bleed: Bleed;
  public fold: Fold;
  public alignmentGuides: AlignmentGuides;
  public scaling: PosterScaling;
  public copyKey: string;
  public isInitialized = false;
  public lastAddedTextColor: RGB = DEFAULT_FILL_COLOR;
  public resizePoster: ResizePoster;
  public videoGeneration: PosterVideoGeneration;
  public readonly history: PosterHistory;
  public readonly savePoster: SavePoster;
  public readonly redux: PosterRedux;

  private pauseTimeoutId: ReturnType<typeof setTimeout> | null = null;

  public constructor(htmlElement: HTMLDivElement, modeDetails: PosterModeDetails) {
    super();
    const posterDiv = document.createElement('div');
    htmlElement.appendChild(posterDiv);
    this.HTMLElement = posterDiv;

    this.drawing = new PosterDrawing(this);
    this.pan = new PosterPan(this);
    this.history = new PosterHistory(this);
    this.savePoster = new SavePoster(this);
    this.redux = new PosterRedux(this);
    this.folder = new Folder(this);
    this.pages = new PosterPages(this);
    this.grid = new Grid(this);
    this.bleed = new Bleed(this);
    this.fold = new Fold(this);
    this.type = new PosterType();
    this.creator = new User();
    this.alignmentGuides = new AlignmentGuides(this);
    this.audioClips = new AudioClips(this);
    this.videoGeneration = new PosterVideoGeneration(this);
    this.scaling = new PosterScaling(this);
    this.resizePoster = new ResizePoster(this);
    this.mode = new PosterMode(modeDetails);
    this.itemsMultiSelect = new ItemsMultiSelect(this);
    this.copyKey = uuidv4();
    Emitter.on('user:loggedIn', this.onUserLoggedIn.bind(this));
    this.clock = new Clock(this, {
      onEnd: this.onPosterEnd.bind(this),
      onTimeUpdated: this.onTimeUpdated.bind(this),
    });

    this.initFabricDefaultValues();
    initDefaultFabricControls();
  }

  private onUserLoggedIn(): void {
    this.savePoster.initAutoSave();
  }

  /**
   * This represents the total width canvas if we didn't clip it when goes out of visible area
   */
  public getCanvasTotalWidth(): number {
    return this.scaling.scale * this.width;
  }

  /**
   * This represents the total height canvas if we didn't clip it when goes out of visible area
   */
  public getCanvasTotalHeight(): number {
    return this.scaling.scale * this.height;
  }

  public isPosterInGeneration(): boolean {
    if (!window.PMW.currentUserOnPageLoad?.isSuper) {
      return this.ageOfGeneratedOrder === 0;
    }
    return false;
  }

  public isPosterTemplate(): boolean {
    return this.isPublic && !this.isInternal;
  }

  /**
   * In builder the scrollbar don't actually scroll the canvas but transform the content within. Thus, not slowing the experience on zoom
   */
  public hasFakeScrollbars(): boolean {
    return !this.mode.isGeneration();
  }

  public onPosterInit(): void {
    this.history.reset();
    this.savePoster.initAutoSave();
    this.isInitialized = true;
    this.redux.updateReduxData();
    this.showPosterInGenerationWarning();
    this.pan.initCanvasPanEvent();
  }

  private showPosterInGenerationWarning(): void {
    if (this.mode.details.type !== PosterModeType.REGEN) {
      if (this.id && this.creator !== null) {
        if (this.isPosterInGeneration()) {
          openPosterInGenerationWarningModal();
        }
      }
    }
  }

  public getEditLink(): string {
    return window.PMW.util.site_url(`posterbuilder/load/${this.hashedID}`);
  }

  public getWebpageLink(): string {
    return window.PMW.util.site_url(`posterbuilder/view/${this.hashedID}`);
  }

  public getDuration(): number {
    return this.pages.getTotalDuration();
  }

  public dispose(): void {
    this.savePoster.onDispose();
    this.redux.onDispose();
    this.pages.disposeCanvas();
    this.scaling.dispose();
    this.HTMLElement.innerHTML = '';
  }

  public setCurrentPage(pageId: string): void {
    if (pageId !== this.currentPageId && this.pages.getPage(pageId)) {
      this.currentPageId = pageId;
      this.redux.updateReduxData();
    }
  }

  public clearItemSelection(): void {
    this.pages.clearSelection();
  }

  public clearSelection(): void {
    this.clearItemSelection();
    this.audioClips.unselectAudioPlaylist();
  }

  public deleteItemById(uid: string, undoable = true): void {
    this.pages.getPageWithItemId(uid)?.items.removeItem(uid, undoable);
  }

  public deleteItemByIds(uids: string[], undoable = true): void {
    showLoading('Deleting');
    for (let index = 0; index < uids.length; index += 1) {
      this.deleteItemById(uids[index], undoable ? index === uids.length - 1 : false);
    }
    hideLoading('Deleting');
  }

  /**
   * Exports the freebie design as a s3 URL for the embedded builder. The URL is then returned to the
   * user and passed to the callback of the openEditor or openWizard function
   */
  public async exportDesign(): Promise<void> {
    if (this.isVideo()) {
      openMessageModal({
        title: window.i18next.t('pmwjs_video_export_title'),
        text: window.i18next.t('pmwjs_video_export_desc'),
        ctaButton: {
          text: window.i18next.t('pm_download_my_project'),
          onClick: openDownloadModal,
        },
      });
    } else {
      showLoading('ExportFreeDownload', {
        text: window.i18next.t('pmwjs_exporting'),
      });

      try {
        const isWebdownload = await this.isWebDownload(
          this.id,
          this.isPosterPremium(),
          this.type.name,
          this.idGalleryTemplate ?? '',
          this.idTemplate,
          this.savePoster.hasUnsavedChanges(),
          this.isVideo()
        );

        const bits = this.getCurrentPage().pageSnapshot.getFreeDownloadSnapshot(isWebdownload);
        const response = (await window.PMW.writeLocal('posterbuilder/saveExportedPreview', {
          design: bits,
        })) as string;
        window.parent.postMessage(`${EmbeddedEditorMessageType.FREEBIE_URL}~${encodeURI(response)}`, '*');
      } catch (e) {
        console.error(e);
        openErrorModal({
          message: window.i18next.t('pmwjs_checkout_download_error'),
        });
      } finally {
        hideLoading('ExportFreeDownload');
      }
    }
  }

  /**
   * Gets a boolean value in the data variable denoting whether the design is a web download or not
   */
  public isWebDownload(
    idPoster: string,
    isPremium: boolean,
    posterType: string,
    idGalleryTemplate: string,
    idTemplate: string,
    unsavedChanges: boolean,
    isVideo: boolean
  ): Promise<boolean> {
    return window.PMW.readLocal(`posterbuilder/isWebDownload/${idPoster ?? 0}/${isPremium ? '1' : '0'}?${new Date().getTime()}`, {
      pt: posterType,
      idgt: idGalleryTemplate,
      idt: idTemplate,
      uc: unsavedChanges ? '1' : '0',
      iv: isVideo ? '1' : '0',
    }) as Promise<boolean>;
  }

  public deleteSelection(): void {
    if (this.audioClips.hasActiveSelection()) {
      this.audioClips.deleteSelectedAudioItem();
      return;
    }

    this.getCurrentPage().activeSelection.deleteSelectedItems();
  }

  public getCurrentPageId(): string {
    return this.currentPageId;
  }

  public getCurrentPage(): Page {
    const currentPage = this.pages.getPage(this.currentPageId);
    if (!currentPage) {
      throw new Error('No current page');
    }
    return currentPage;
  }

  public getBackendObject(): PosterObjectBackend {
    return getPosterBackendObjectFromPoster(this.toObjectForLoad());
  }

  public toObjectForLoad(): PosterLoadObject {
    return {
      hashedID: this.hashedID,
      type: this.type.toObject(),
      audioClips: this.audioClips.toObject(),
      userWidth: this.userWidth,
      userHeight: this.userHeight,
      units: this.units,
      version: this.version,
      name: this.name,
      width: this.width,
      height: this.height,
      id: this.id,
      idTemplate: this.idTemplate,
      description: this.description,
      isTemplate: this.isTemplate,
      isPublic: this.isPublic,
      isCopyable: this.isCopyable,
      isPurchaseable: this.isPurchaseable,
      createdOn: this.createdOn,
      lastModified: this.lastModified,
      idLastModifier: this.idLastModifier,
      isInternal: this.isInternal,
      idGalleryTemplate: this.idGalleryTemplate,
      idGalleryTemplateCreator: this.idGalleryTemplateCreator,
      seoName: this.seoName,
      creator: this.creator?.toObject(),
      pages: this.pages.toObject(),
    };
  }

  public toObject(): PosterObject {
    return {
      ...this.toObjectForLoad(),
      scaling: this.scaling.toObject(),
      resizeReference: this.resizePoster.toObject(),
      bleed: this.bleed.toObject(),
      folder: this.folder.toObject(),
      duration: this.getDuration(),
      fold: this.fold.toObject(),
      grid: this.grid.toObject(),
      mode: this.mode.toObject(),
      alignmentGuides: this.alignmentGuides.toObject(),
      drawing: this.drawing.toObject(),
      copyKey: this.copyKey,
      isInitialized: this.isInitialized,
      itemsMultiSelect: this.itemsMultiSelect.toObject(),
      uploadingImages: [...this.uploadingImages],
    };
  }

  public copyVals(posterObject: DeepPartial<PosterObject> = {}): void {
    const {creator, type, ...obj} = posterObject;
    super.copyVals(obj);
    this.type.copyVals(type);
    this.creator?.copyVals(creator);
  }

  public async updateFromObject(
    posterObject: DeepPartial<PosterObject>,
    {updateRedux = true, undoable = true, refreshActiveSelection = false, checkForDurationUpdate = false, onItemAddFail}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    const {
      pages,
      mode: _mode,
      scaling,
      audioClips,
      folder,
      grid,
      fold,
      bleed,
      alignmentGuides,
      drawing,
      resizeReference,
      itemsMultiSelect,
      ...pageObjectWithoutPages
    } = posterObject;
    const didDimensionsUpdate = this.areDimensionsDifferentFromObject(posterObject);
    let renderAllCanvases = false;

    this.copyVals({
      ...pageObjectWithoutPages,
    });

    if (scaling) {
      this.scaling.updateFromObject(scaling, false);
    }

    if (pages) {
      await this.pages.updateFromObject(pages, {
        updateRedux: false,
        undoable: false,
        refreshActiveSelection,
        checkForDurationUpdate,
        onItemAddFail,
      });
    }
    if (resizeReference) {
      this.resizePoster.copyVals(resizeReference);
    }
    if (audioClips) {
      await this.audioClips.updateFromObject(audioClips, {updateRedux: false, undoable: false});
    }

    if (folder) {
      this.folder.updateFromObject(folder);
    }
    if (grid) {
      this.grid.updateFromObject(grid);
      renderAllCanvases = true;
    }
    if (bleed) {
      this.bleed.updateFromObject(bleed);
      renderAllCanvases = true;
    }
    if (fold) {
      this.fold.updateFromObject(fold);
      renderAllCanvases = true;
    }
    if (alignmentGuides) {
      this.alignmentGuides.updateFromObject(alignmentGuides);
    }
    if (drawing) {
      this.drawing.updateFromObject(drawing);
    }
    if (itemsMultiSelect) {
      this.itemsMultiSelect.updateFromObject(itemsMultiSelect);
    }

    this.validateValues(posterObject, undoable);

    if (undoable) {
      this.history.addPosterHistory();
    }
    if (updateRedux) {
      this.redux.updateReduxData();
    }

    if (didDimensionsUpdate) {
      this.pages.applyScales();
      if (!this.mode.isGeneration()) {
        this.scaling.fitToScreen();
      }
    }

    if (renderAllCanvases) {
      this.renderAllCanvases();
    }
  }

  public renderAllCanvases(): void {
    for (const [, page] of Object.entries(this.pages.pagesHashMap)) {
      page.fabricCanvas.requestRenderAll();
    }
  }

  public isGalleryTemplate(): boolean {
    return this.id === this.idGalleryTemplate;
  }

  public isSuperUserEditingOthersPoster(): boolean {
    if (window.PMW.currentUserOnPageLoad === undefined) {
      return false;
    }

    return window.PMW.currentUserOnPageLoad.isSuper && window.PMW.currentUserOnPageLoad.id !== this.creator?.id;
  }

  private validateValues(posterObject: DeepPartial<PosterObject>, undoable: boolean): void {
    if (undoable) {
      if (posterObject.name === '') {
        void this.updateFromObject(
          {
            name: 'Untitled',
          },
          {
            undoable: false,
          }
        );
      }
    }
  }

  public isVideo(): boolean {
    return this.pages.hasVideoPage() || this.audioClips.hasAudio();
  }

  public getSelectedItems(): ItemType[] {
    return this.getCurrentPage().getSelectedItems();
  }

  public hasActiveSelection(): boolean {
    return this.getSelectedItems().length !== 0;
  }

  public getSelectedViews(): FabricObject[] {
    return this.getCurrentPage().activeSelection.getActiveObjects();
  }

  public getItemForId(uid: string): ItemType | undefined {
    return this.pages.getItemForId(uid);
  }

  private onPosterEnd(): void {
    if (this.mode.isWebpage()) {
      void this.loopPoster();
    } else {
      void this.pause();
      void this.audioClips.seekToPosterTime(0);
    }
  }

  private async loopPoster(): Promise<void> {
    await this.seek(0);
    await this.play();
  }

  private onTimeUpdated(time: number): void {
    this.fire('time:updated', time);
  }

  public getGettyImageCount(): number {
    let count = 0;

    for (const page of this.pages.getPagesInOrder()) {
      if (
        page.background.details instanceof ImageBackground &&
        page.background.details.imageBackgroundItem.isNonPurchasedGettyImage() &&
        !page.background.details.imageBackgroundItem.isRemoved
      ) {
        count += 1;
      }

      count += page.items.getGettyImageCount();
    }

    return count;
  }

  public getExtractedGettyStickerCount(): number {
    let count = 0;

    for (const page of this.pages.getPagesInOrder()) {
      count += page.items.getExtractedGettyStickerCount();
    }

    return count;
  }

  public updateTitle(): void {
    document.title = `${this.name} | PosterMyWall`;
  }

  public getGettyVideoCount(): number {
    let count = 0;

    for (const page of this.pages.getPagesInOrder()) {
      count += page.items.getGettyVideoCount();
    }

    return count;
  }

  public isPlaying(): boolean {
    return this.clock.isPlaying();
  }

  public getCurrentTime(): number {
    return this.clock.getCurrentTime();
  }

  public convertTransparentBackgroundsToSolid(): void {
    this.pages.convertTransparentBackgroundsToSolid();
  }

  public async stop(): Promise<void> {
    this.clock.stop();
    this.audioClips.pause();
    await this.getCurrentPage().stopPage();
    this.redux.updateIsPlayingInRedux();
  }

  public async pause(): Promise<void> {
    this.invalidatePauseTimeout();

    this.clock.pause();
    this.audioClips.pause();
    await this.getCurrentPage().pausePage();
    this.redux.updateIsPlayingInRedux();
  }

  public async seek(time: number): Promise<void> {
    this.invalidatePauseTimeout();

    this.clock.setCurrentTime(time);
    await this.audioClips.seekToPosterTime(time);
    const currentPage = this.getCurrentPage();
    currentPage.onSeekPage();
    if (!this.isPlaying()) {
      currentPage.fabricCanvas.requestRenderAll();
    }
    this.fire('seeked');
  }

  public async seekPosterToSelectedAudioItem(): Promise<void> {
    const selectedAudioItem = this.audioClips.getSelectedAudioItem();
    if (selectedAudioItem) {
      await window.posterEditor?.whiteboard?.seek(this.audioClips.getTimeForAudioItem(selectedAudioItem.uid));
    }
  }

  public async pauseOrPlayBetweenStartAndEndTime(start: number, end: number): Promise<void> {
    if (this.isPlaying()) {
      await this.pause();
      return;
    }

    await this.seek(start);

    try {
      this.clock.play();
      this.audioClips.play();
      await this.getCurrentPage().playPage();
      this.redux.updateIsPlayingInRedux();

      const duration = (end - start) * 1000;
      this.pauseTimeoutId = setTimeout(() => {
        void (async (): Promise<void> => {
          await this.pause();
          this.pauseTimeoutId = null;
        })();
      }, duration);
    } catch (e) {
      console.error(e);
      await this.pause();
    }
  }

  public invalidatePauseTimeout(): void {
    if (this.pauseTimeoutId !== null) {
      clearTimeout(this.pauseTimeoutId);
      this.pauseTimeoutId = null;
    }
  }

  public async play(): Promise<void> {
    this.invalidatePauseTimeout();

    try {
      this.clock.play();
      this.audioClips.play();
      await this.getCurrentPage().playPage();
      this.redux.updateIsPlayingInRedux();
    } catch (e) {
      console.error(e);
      void this.pause();
    }
  }

  public async replayPoster(): Promise<void> {
    await this.stop();
    await this.play();
  }

  public hasMaxNumberofGettyVideoItems(): boolean {
    return this.getGettyVideoCount() >= POSTER_GETTY_LIMIT.VIDEOS;
  }

  public hasMaxNumberOfGettyImages(): boolean {
    return this.getGettyImageCount() >= POSTER_GETTY_LIMIT.IMAGES;
  }

  public hasMaxNumberOfExtractedGettyStickers(): boolean {
    return this.getExtractedGettyStickerCount() >= POSTER_GETTY_LIMIT.EXTRACTED_GETTY_STICKER;
  }

  public getFontsOnPoster(withVariation = false): string[] {
    const pages = Object.values(this.pages.pagesHashMap);
    const fonts: string[] = [];

    for (const eachPage of pages) {
      fonts.push(...eachPage.getFontsOnPage(withVariation));
    }

    return fonts;
  }

  private areDimensionsDifferentFromObject(posterObject: DeepPartial<PosterObject>): boolean {
    return (posterObject.width !== undefined && posterObject.width !== this.width) || (posterObject.height !== undefined && posterObject.height !== this.height);
  }

  public togglePlayPause(): void {
    if (this.isPlaying()) {
      void this.pause();
    } else {
      void this.play();
    }
  }

  public getCornerPointsArray(): CornerPointsArray {
    return [
      {
        x: 0,
        y: 0,
      },
      {
        x: this.width,
        y: 0,
      },
      {
        x: this.width,
        y: this.height,
      },
      {
        x: 0,
        y: this.height,
      },
    ];
  }

  public deleteItemsNotVisibleOnPoster(undoable = true): void {
    const itemsToDelete = [];
    for (const [, page] of Object.entries(this.pages.pagesHashMap)) {
      for (const [, item] of Object.entries(page.items.itemsHashMap)) {
        if (!item.isItemVisibleOnPoster() || (item.isSlideshow() && !item.isValid())) {
          itemsToDelete.push(item.uid);
        }
      }
    }

    this.deleteItemByIds(itemsToDelete, undoable);
  }

  public setMenuItemsWrappingInfo(): void {
    for (const [, page] of Object.entries(this.pages.pagesHashMap)) {
      for (const [, item] of Object.entries(page.items.itemsHashMap)) {
        if (item.isMenu()) {
          item.wrappingInfo = item.getWrappedLines();
        }
      }
    }
  }

  public isPosterPremium(): boolean {
    for (const [, page] of Object.entries(this.pages.pagesHashMap)) {
      if (page.isPremium()) {
        return true;
      }
    }
    return false;
  }

  public validatePagesIntroAnimation(): void {
    for (const [, page] of Object.entries(this.pages.pagesHashMap)) {
      page.items.checkForIntroAnimationOnNoItems();
    }
  }

  public hasClickableItem(): boolean {
    for (const page of this.pages.getPagesInOrder()) {
      if (page.items.hasClickableItem()) {
        return true;
      }
    }
    return false;
  }

  public getAllItems(): ItemType[] {
    let items: ItemType[] = [];
    for (const page of this.pages.getPagesInOrder()) {
      items = items.concat(page.items.getItems());
    }
    return items;
  }

  public getAllImageItems(): ImageItem[] {
    let items: ImageItem[] = [];
    for (const page of this.pages.getPagesInOrder()) {
      items = items.concat(page.items.getImageItems());
    }
    return items;
  }

  public addToUploadingImages(id: string, updateRedux = false): void {
    if (!this.uploadingImages.includes(id)) {
      this.uploadingImages.push(id);
      if (updateRedux) {
        this.redux.updateReduxData();
      }
    }
  }

  public removeFromUploadingImages(id: string, updateRedux = false): void {
    const index = this.uploadingImages.indexOf(id);
    if (index !== -1) {
      this.uploadingImages.splice(index, 1);
      if (updateRedux) {
        this.redux.updateReduxData();
      }
    }
  }

  public areAnyImagesUploading(): boolean {
    return this.uploadingImages.length > 0;
  }

  public getImageSrcOfUploadingImage(): string | undefined {
    const imageSrcItem = this.getCurrentPage().items.getItem(this.uploadingImages[0]);
    if (imageSrcItem && (imageSrcItem.isImage() || imageSrcItem.isImageSlide() || imageSrcItem.isImageBackground())) {
      return imageSrcItem.uploadingImageData?.dataUrl;
    }
    return undefined;
  }

  private initFabricDefaultValues(): void {
    config.textureSize = this.mode.isGeneration() ? GENERATION_WEBGL_TEXTURE_SIZE : WEBGL_TEXTURE_SIZE;
    config.enableGLFiltering = !(isSafari || isIOS);
    if (this.mode.isGeneration()) {
      config.perfLimitSizeTotal = 4194304;
    }
  }
}
