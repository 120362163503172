import {Item} from '@PosterWhiteboard/items/item/item.class';
import {Rect} from '@postermywall/fabricjs-2';
import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {FillObject, FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {RGB} from '@Utils/color.util';
import type {DeepPartial} from '@/global';

export interface RectangleItemObject extends BaseItemObject {
  rx: number;
  ry: number;
  fill: FillObject;
}

export class RectangleItem extends Item {
  declare fabricObject: Rect;
  public rx = 0;
  public ry = 0;
  public width = 300;
  public height = 300;
  public gitype: ITEM_TYPE.RECTANGLE = ITEM_TYPE.RECTANGLE;
  public fill: Fill;

  constructor(page: Page) {
    super(page);
    this.fill = new Fill();
  }

  public toObject(): RectangleItemObject {
    return {
      ...super.toObject(),
      fill: this.fill.toObject(),
      rx: this.rx,
      ry: this.ry,
    };
  }

  public async initFabricObject(): Promise<void> {
    await super.initFabricObject();
    await this.onItemInitialized();
  }

  protected async onItemInitialized(): Promise<void> {
    await this.updateFabricObject();
  }

  protected setControlsVisibility(): void {
    const shouldHideControlsOnSmallItem = this.shouldHideControlOnSmallItem();

    super.setControlsVisibility();
    this.fabricObject.setControlsVisibility({
      ml: !this.isLocked() && !shouldHideControlsOnSmallItem,
      mt: !this.isLocked(),
      mr: !this.isLocked() && !shouldHideControlsOnSmallItem,
      mb: !this.isLocked(),
    });
  }

  public updateFillColor(fillColor: RGB[], undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }

  public updateFillType(newType: FillTypes): void {
    void this.updateFromObject({
      fill: {
        fillType: newType,
        fillColor: this.fill.getColorForNewType(newType),
      },
    });
  }

  public updateFillColorOpacity(fillAlpha: number, undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillAlpha,
        },
      },
      {
        undoable,
      }
    );
  }

  protected async getFabricObjectForItem(): Promise<Rect> {
    const obj = new Rect({
      width: this.width,
      height: this.height,
      uniformScaling: false,
      uniformRoundness: true,
    });
    obj.set(this.getCommonOptions());
    return obj;
  }

  public copyVals(obj: DeepPartial<RectangleItemObject>): void {
    const {fill, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.fill.copyVals(fill);
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      rx: this.rx,
      ry: this.ry,
      width: this.width,
      height: this.height,
      fill: this.fill.getFill(this.fabricObject.width, this.fabricObject.height),
    });
  }
}
