import type {
  PMWStockBackendAnimatedSticker,
  PMWStockBackendExtractedGettySticker,
  PMWStockBackendIcon,
  PMWStockBackendShape,
  PMWStockBackendStillSticker,
} from '@Panels/manage-pmw-stock-panels/manage-pmw-stock-panels-types';
import type {GridAnimatedStickerItemStorage} from '@Components/base-grid/components/grid-animated-sticker-item';
import type {GridItemBaseStorage, GridItemStorage, GridStockMediaItemBackendData} from '@Components/base-grid/components/grid-item';
import {GRID_ITEM_TYPE} from '@Components/base-grid/components/grid-item';
import {v4 as uuidv4} from 'uuid';
import type {GridStockAudioItemBackEndData, GridStockAudioItemStorage} from '@Components/base-grid/components/grid-stock-audio-item';
import type {GridStockImageItemBackEndData, GridStockImageItemStorage} from '@Components/base-grid/components/grid-stock-image-item';
import {
  gettyImageUpload,
  gettyVideoUpload,
  pixabayImageUpload,
  pixabayVideoUpload,
  SOURCE,
  storyBlocksAudioUpload,
  storyBlocksImageUpload,
  storyBlocksVideoUpload,
} from '@Libraries/stock-graphic-library';
import type {AITextToImageBackendData, AudioCategoryBackendData, StockGetterOptions, StockOffsets} from '@Libraries/pmw-stock-media-library';
import {getPMWStockImageThumbURL} from '@Libraries/pmw-stock-media-library';
import {AspectRatio, StabilityAIStylePreset, StockSources} from '@Libraries/pmw-stock-media-library';
import type {GridStockVideoItemBackEndData, GridStockVideoItemStorage} from '@Components/base-grid/components/grid-stock-video-item';
import type {GridShapeItemStorage} from '@Components/base-grid/components/grid-shape-item';
import type {GridIconItemBackEndData, GridIconItemStorage} from '@Components/base-grid/components/grid-icon-item';
import type {GridClipartItemBackEndData, GridClipartItemStorage} from '@Components/base-grid/components/grid-clipart-item';
import type {AIImageFilters, GridItemBaseStorageBackendData, SelectedFilter, StockFilter, StockFilterID} from '@Panels/stock-panel/stock-panel.types';
import {
  AUDIO_CATEGORY_ID_TO_STOCK_BUTTON_FILTER,
  AUDIO_FILTER_ID_TO_ICON_MAPPING,
  DISABLE_PIXABY_SOURCES,
  ICON_FILTERS_TO_CATEGORY_MAPPING,
  IS_GETTY_ENABLED,
  PRICING_SUBFILTER_FREE,
  StockButtonFilters,
  StockCarouselType,
  StockFilterTypes,
  StockGridIDs,
  StockPanelTabs,
  UHD_APPLIED_VIDEO_FILTER_ID,
} from '@Panels/stock-panel/stock-panel.types';
import type {
  AnimatedStickerData,
  AudioData,
  ElementData,
  ImageData,
  PMWExtractedGettyStickerData,
  PMWIconData,
  PMWShapeData,
  PMWStillStickerData,
  TempImageData,
  VideoData,
} from '@Libraries/add-media-library';
import {
  ElementDataType,
  getAudioDataFromUserAudioVO,
  getGeneratedAiImageDataFromGridItemStorage,
  getImageDataForPMWStockImage,
  getImageDataFromUserImageVO,
  getStockImageDataFromGridStockImageItemStorage,
  getVideoDataFromUserVideoVO,
} from '@Libraries/add-media-library';
import {getUserId, getUserType, isUserPremium} from '@Libraries/user.library';
import type {PanelBottomPill} from '@Components/panel/components/panel-bottom-pills';
import type {CarouselItemData} from '@Components/carousel/carousel.types';
import type {TabItemContentStorage} from '@Components/tabs';
import type {UserImageVOResponse} from '@Libraries/user-image-library';
import type {UserVideoVOResponse} from '@Libraries/user-video-library';
import type {UserAudioVOResponse} from '@Libraries/user-audio-library';
import {isUserStudent} from '@Utils/user.util';
import type {GridMediaItemTag} from '@Components/base-grid/components/grid-media-item';
import {GridMediaItemTagType} from '@Components/base-grid/components/grid-media-item';
import {intersection} from 'lodash';
import type {GridStillStickerItemBackEndData, GridStillStickerItemStorage} from '@Components/base-grid/components/grid-still-sticker-item/grid-still-sticker-item';
import {getUrlForGeneratedAiImage, textToImageUpload} from '@Libraries/text-to-image-library';
import {capitalize} from '@Utils/string.util';
import type {AnimatedSprite} from '@Libraries/animated-sprite.library';
import {getAssetUrl} from '@Utils/s3.util';
import {MediaItemSizeType} from '@Libraries/s3-library';
import {getUrlForImageItemScreenTier, ScreenTier} from '@Libraries/image-item.library';
import {getCompatibleImageFileExtension, imageUrlToDataUrl} from '@Utils/image.util';
import {doesPublicFileExistAsync} from '@Utils/file.util';
import type {GridExtractedGettyStickerItemStorage} from '@Components/base-grid/components/grid-extracted-getty-sticker-item';

export const FALLBACK_GRID_ITEM_WIDTH = 170;
export const FALLBACK_GRID_ITEM_HEIGHT = 170;
export const STOCK_AUDIO_GRID_ITEM_WIDTH = 220;
export const GRID_SKELETON_LANDSCAPE_WIDTH = 122;
export const GRID_SKELETON_PORTRAIT_WIDTH = 40;
export const GRID_SKELETON_SQUARE_WIDTH = 70;
export const SHOULD_DISPLAY_AUDIO_ITEMS_IN_ALL_TAB_FOR_MOBILE = false;

const ANIMATED_STICKER_SOURCES = [StockSources.PMW_ANIMATED_STICKER];

const STILL_STICKER_SOURCES = [
  StockSources.GETTY_ILLUSTRATION,
  StockSources.STORYBLOCK_ILLUSTRATION,
  StockSources.PIXABY_ILLUSTRATION,
  StockSources.PMW_STILL_STICKER,
  StockSources.PMW_EXTRACTED_GETTY_STICKER,
];

export const getAnimatedStickerSources = (): StockSources[] => {
  return ANIMATED_STICKER_SOURCES;
};

export const animatedStickerDataToAnimatedStickerItem = (animatedStickerData: PMWStockBackendAnimatedSticker): GridAnimatedStickerItemStorage => {
  return {
    hashedFilename: animatedStickerData.hashedFilename,
    type: GRID_ITEM_TYPE.PMW_STOCK_ANIMATED_STICKER,
    id: animatedStickerData.idPMWStockAnimatedSticker,
    frameRate: Number(animatedStickerData.frameRate),
    duration: Number(animatedStickerData.duration),
    highResAnimatedSprite: animatedStickerData.highResAnimatedSprite,
    screenAnimatedSprite: animatedStickerData.screenAnimatedSprite,
  };
};

export const audioDataToAudioItem = (audioData: GridStockAudioItemBackEndData): GridStockAudioItemStorage => {
  return {
    id: audioData.id as string,
    type: GRID_ITEM_TYPE.STOCK_AUDIO,
    name: audioData.t as string,
    audioSrc: audioData.up as string,
    imgThumbSrc: audioData.u as string,
    duration: audioData.cl as string,
    source: SOURCE.STORYBLOCKS,
    data: {
      id: audioData.id as string,
      mood: audioData.m as string,
      genre: audioData.g as string,
    },
  };
};

export const clipartDataToClipartItem = (clipartData: GridClipartItemBackEndData, source: StockSources): GridClipartItemStorage => {
  let clipartSource: SOURCE;
  let data: Record<string, any>;
  let clipartThumbURL;

  switch (source) {
    case StockSources.GETTY_ILLUSTRATION:
      clipartThumbURL = clipartData.u;
      clipartSource = SOURCE.GETTY_ILLUSTRATIONS;
      data = {
        id: clipartData.id ?? uuidv4(),
        artist: clipartData.a as string,
        title: clipartData.t as string,
        col: clipartData.c as string,
        type: clipartData.ty as string,
      };
      break;
    case StockSources.PIXABY_ILLUSTRATION:
      clipartThumbURL = clipartData.u;
      clipartSource = SOURCE.PIXABAY_ILLUSTRATIONS;
      data = {
        id: clipartData.id ?? uuidv4(),
        hu: clipartData.hu as string,
      };
      break;
    case StockSources.STORYBLOCK_ILLUSTRATION:
      clipartThumbURL = clipartData.tu;
      clipartSource = SOURCE.STORYBLOCKS_ILLUSTRATIONS;
      data = {
        id: clipartData.id ?? uuidv4(),
        title: clipartData.t as string,
        format: clipartData.defaultFormat as string,
      };
      break;
    default:
      throw new Error(`Invalid clipart source passed: ${source}`);
  }

  return {
    type: GRID_ITEM_TYPE.CLIPART,
    source: clipartSource,
    id: clipartData.id ?? uuidv4(),
    thumbSrc: clipartThumbURL,
    data,
    width: clipartData.w as number,
    height: clipartData.h as number,
    tag: source === StockSources.GETTY_ILLUSTRATION ? getTagForGettyMedia() : undefined,
    hidden: clipartData.hidden ?? undefined,
  };
};

export const getTagForGettyMedia = (): Partial<GridMediaItemTag> => {
  if (isUserPremium()) {
    return {
      type: GridMediaItemTagType.SOURCE,
    };
  }
  return {
    type: GridMediaItemTagType.COST,
    price: window.posterEditor?.config?.gettyFormattedDownloadCostForNonPremiumUsers ?? '',
    icon: 'icon-coins',
  };
};

export const iconDataToIconItem = (iconData: GridIconItemBackEndData): GridIconItemStorage => {
  return {
    id: iconData.idPmwStockMedia,
    type: GRID_ITEM_TYPE.PMW_STOCK_ICON,
    hashedFilename: iconData.hashedFilename,
  };
};

export const stillStickerDataToStickerItem = (stillStickerData: GridStillStickerItemBackEndData): GridStillStickerItemStorage => {
  return {
    id: stillStickerData.idPmwStockMedia,
    type: GRID_ITEM_TYPE.PMW_STOCK_STILL_STICKER,
    hashedFilename: stillStickerData.hashedFilename,
  };
};

export const extractedGettyStickerDataToStickerItem = (ExtractedGettyStickerData: GridStockMediaItemBackendData): GridExtractedGettyStickerItemStorage => {
  return {
    id: ExtractedGettyStickerData.idPmwStockMedia,
    type: GRID_ITEM_TYPE.PMW_STOCK_EXTRACTED_GETTY_STICKER,
    hashedFilename: ExtractedGettyStickerData.hashedFilename,
  };
};

export const imageDataToImageItem = (backEndData: GridStockImageItemBackEndData, source: StockSources): GridStockImageItemStorage => {
  let thumbSrc;
  let id;
  let data = {};
  let src: SOURCE;

  switch (source) {
    case StockSources.GETTY_IMAGE:
      src = SOURCE.GETTY;
      thumbSrc = backEndData.u as string;
      id = backEndData.id as string;
      data = {
        id: backEndData.id as string,
        artist: backEndData.a as string,
        title: backEndData.t as string,
        col: backEndData.c as number,
        type: backEndData.ty as string,
      };
      break;

    case StockSources.PIXABY_IMAGE:
      src = SOURCE.PIXABAY;
      thumbSrc = backEndData.u as string;
      id = backEndData.id as string;
      data = {
        hu: backEndData.hu as string,
      };
      break;

    case StockSources.STORYBLOCK_IMAGE:
      src = SOURCE.STORYBLOCKS;
      thumbSrc = backEndData.tu as string;
      id = backEndData.id as string;
      data = {
        id: backEndData.id as string,
        title: backEndData.t as string,
        format: backEndData.defaultFormat as string,
      };
      break;

    case StockSources.PMW_STOCK_IMAGE:
      src = SOURCE.PMW_IMAGE;
      thumbSrc = getPMWStockImageThumbURL(backEndData.hashedFilename as string);
      id = backEndData.idPmwStockMedia as string;
      data = {
        hashedFilename: backEndData.hashedFilename as string,
        hasTransparency: !!backEndData.has_transparency,
      };
      break;

    default:
      throw new Error(`Unhandled source: ${source}`);
  }

  return {
    type: GRID_ITEM_TYPE.STOCK_IMAGE,
    id,
    thumbSrc,
    source: src,
    data,
    width: backEndData.w as number,
    height: backEndData.h as number,
    tag: source === StockSources.GETTY_IMAGE ? getTagForGettyMedia() : undefined,
  };
};

export const videoDataToVideoItem = (backEndData: GridStockVideoItemBackEndData, source: StockSources): GridStockVideoItemStorage | undefined => {
  if (source && backEndData.u && backEndData.up) {
    switch (source) {
      case StockSources.GETTY_VIDEO:
        return {
          id: backEndData.id as string,
          type: GRID_ITEM_TYPE.STOCK_VIDEO,
          source: SOURCE.GETTY,
          imgThumbSrc: backEndData.u as string,
          videoThumbSrc: backEndData.up as string,
          isUHD: !!backEndData.UHD,
          duration: backEndData.cl as string,
          data: {
            id: backEndData.id as string,
            artist: backEndData.a as string,
            title: backEndData.t as string,
            col: backEndData.c as number,
          },
          width: backEndData.w as number,
          height: backEndData.h as number,
          tag: getTagForGettyMedia(),
        };

      case StockSources.PIXABY_VIDEO:
        return {
          id: backEndData.id as string,
          type: GRID_ITEM_TYPE.STOCK_VIDEO,
          source: SOURCE.PIXABAY,
          imgThumbSrc: backEndData.u as string,
          videoThumbSrc: backEndData.up as string,
          isUHD: false, // pixabay doesn't provide 4k videos
          duration: backEndData.cl as string,
          data: {
            id: backEndData.id as string,
            highResSrc: backEndData.hr as string,
            smallResSrc: backEndData.sr as string,
          },
          width: backEndData.w as number,
          height: backEndData.h as number,
        };

      case StockSources.STORYBLOCK_VIDEO:
        return {
          id: backEndData.id as string,
          type: GRID_ITEM_TYPE.STOCK_VIDEO,
          source: SOURCE.STORYBLOCKS,
          imgThumbSrc: backEndData.u as string,
          videoThumbSrc: backEndData.up as string,
          isUHD: !!backEndData.UHD,
          duration: backEndData.cl as string,
          data: {
            id: backEndData.id as string,
            title: backEndData.t as string,
          },
          width: backEndData.w as number,
          height: backEndData.h as number,
        };

      default:
        throw new Error(`Unhandled source: ${source}`);
    }
  }
  return undefined;
};

export const shapeDataToShapeItem = (shapeData: PMWStockBackendShape): GridShapeItemStorage => {
  return {
    hashedFilename: shapeData.idPMWStockShape,
    type: GRID_ITEM_TYPE.PMW_STOCK_SHAPE,
    id: shapeData.idPMWStockShape,
  };
};

export const getStockSourcesForTab = (tab: StockPanelTabs): StockSources[] => {
  const sources: StockSources[] = [];
  const PIXABY_SOURCES: StockSources[] = [StockSources.PIXABY_VIDEO, StockSources.PIXABY_IMAGE, StockSources.PIXABY_ILLUSTRATION];
  if (tab === StockPanelTabs.ALL) {
    sources.push(
      ...[
        StockSources.PMW_ICON,
        StockSources.PMW_SHAPE,
        StockSources.PMW_ANIMATED_STICKER,
        StockSources.PMW_STILL_STICKER,
        StockSources.PMW_STOCK_IMAGE,
        StockSources.STORYBLOCK_VIDEO,
        StockSources.STORYBLOCK_IMAGE,
        StockSources.STORYBLOCK_AUDIO,
        StockSources.STORYBLOCK_ILLUSTRATION,
        StockSources.PMW_EXTRACTED_GETTY_STICKER,
      ]
    );
    if (!window.PMW.currentUserOnPageLoad?.isFreelancer) {
      sources.push(...PIXABY_SOURCES);
    }
    if (!isUserStudent(getUserType()) && IS_GETTY_ENABLED) {
      sources.push(...[StockSources.GETTY_VIDEO, StockSources.GETTY_ILLUSTRATION, StockSources.GETTY_IMAGE]);
    }
  }
  if (tab === StockPanelTabs.PHOTOS) {
    sources.push(StockSources.STORYBLOCK_IMAGE);
    sources.push(StockSources.PMW_STOCK_IMAGE);
    if (!isUserStudent(getUserType()) && IS_GETTY_ENABLED) {
      sources.push(StockSources.GETTY_IMAGE);
    }
    if (!window.PMW.currentUserOnPageLoad?.isFreelancer) {
      sources.push(StockSources.PIXABY_IMAGE);
    }
  }
  if (tab === StockPanelTabs.VIDEOS) {
    sources.push(StockSources.STORYBLOCK_VIDEO);
    if (!isUserStudent(getUserType()) && IS_GETTY_ENABLED) {
      sources.push(StockSources.GETTY_VIDEO);
    }
    if (!window.PMW.currentUserOnPageLoad?.isFreelancer) {
      sources.push(StockSources.PIXABY_VIDEO);
    }
  }
  if (tab === StockPanelTabs.AUDIOS) {
    sources.push(StockSources.STORYBLOCK_AUDIO);
  }
  if (tab === StockPanelTabs.SHAPES) {
    sources.push(StockSources.PMW_SHAPE);
  }
  if (tab === StockPanelTabs.ICONS) {
    sources.push(StockSources.PMW_ICON);
  }
  if (tab === StockPanelTabs.STICKERS) {
    sources.push(StockSources.PMW_ANIMATED_STICKER, StockSources.STORYBLOCK_ILLUSTRATION, StockSources.PMW_STILL_STICKER, StockSources.PMW_EXTRACTED_GETTY_STICKER);
    if (!isUserStudent(getUserType()) && IS_GETTY_ENABLED) {
      sources.push(StockSources.GETTY_ILLUSTRATION);
    }
    if (!window.PMW.currentUserOnPageLoad?.isFreelancer) {
      sources.push(StockSources.PIXABY_ILLUSTRATION);
    }
  }

  return DISABLE_PIXABY_SOURCES
    ? sources.filter((stockSource) => {
        return !PIXABY_SOURCES.includes(stockSource);
      })
    : sources;
};

export const FILTERED_SOURCES: Record<string, Record<string, Record<string, string[]>>> = {
  ALL: {
    Pricing: {
      '1': [
        ...getStockSourcesForTab(StockPanelTabs.ALL).filter((src) => {
          return ![StockSources.GETTY_VIDEO, StockSources.GETTY_ILLUSTRATION, StockSources.GETTY_IMAGE, StockSources.PMW_EXTRACTED_GETTY_STICKER].includes(src);
        }),
      ],
      '2': [StockSources.GETTY_VIDEO, StockSources.GETTY_ILLUSTRATION, StockSources.GETTY_IMAGE, StockSources.PMW_EXTRACTED_GETTY_STICKER],
    },
    Animation: {
      '3': [...ANIMATED_STICKER_SOURCES],
      '4': [...STILL_STICKER_SOURCES],
    },
  },
  PHOTOS: {
    Pricing: {
      '1': [StockSources.PIXABY_IMAGE, StockSources.STORYBLOCK_IMAGE, StockSources.PMW_STOCK_IMAGE],
      '2': [StockSources.GETTY_IMAGE],
    },
  },
  VIDEOS: {
    Quality: {
      '5': [StockSources.STORYBLOCK_VIDEO, StockSources.GETTY_VIDEO],
    },
    Pricing: {
      '1': [StockSources.PIXABY_VIDEO, StockSources.STORYBLOCK_VIDEO],
      '2': [StockSources.GETTY_VIDEO],
    },
  },
  ICONS: {
    Category: {
      colored: [StockSources.PMW_ICON],
      fill: [StockSources.PMW_ICON],
      outline: [StockSources.PMW_ICON],
      hand_drawn: [StockSources.PMW_ICON],
    },
  },
  SHAPES: {},
  STICKERS: {
    Pricing: {
      '1': [StockSources.STORYBLOCK_ILLUSTRATION, StockSources.PIXABY_ILLUSTRATION, StockSources.PMW_ANIMATED_STICKER, StockSources.PMW_STILL_STICKER],
      '2': [StockSources.GETTY_ILLUSTRATION, StockSources.PMW_EXTRACTED_GETTY_STICKER],
    },
    Animation: {
      '3': [...ANIMATED_STICKER_SOURCES],
      '4': [...STILL_STICKER_SOURCES],
    },
  },
  AUDIOS: {
    Mood: {
      '9': [StockSources.STORYBLOCK_AUDIO],
      '1': [StockSources.STORYBLOCK_AUDIO],
    },
    Genre: {
      '9': [StockSources.STORYBLOCK_AUDIO],
      '10': [StockSources.STORYBLOCK_AUDIO],
    },
    Instruments: {
      '10': [StockSources.STORYBLOCK_AUDIO],
      '11': [StockSources.STORYBLOCK_AUDIO],
    },
    Sfx: {},
  },
};

const handleImageTypeUpload = async (gridItem: GridStockImageItemStorage, shouldLoadDataUrlImage = false): Promise<ElementData> => {
  switch (gridItem.source) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    case SOURCE.PMW_IMAGE:
      return getImageDataForPMWStockImage(gridItem);
    default:
      return doUploadStockImageGridItem(gridItem, shouldLoadDataUrlImage);
  }
};

export const uploadSelectedItem = async (gridItem: GridItemStorage, shouldLoadDataUrlImage = false): Promise<ElementData> => {
  switch (gridItem.type) {
    case GRID_ITEM_TYPE.STOCK_IMAGE:
      return handleImageTypeUpload(gridItem as GridStockImageItemStorage, shouldLoadDataUrlImage);
    case GRID_ITEM_TYPE.STOCK_VIDEO:
      return uploadStockVideoGridItem(gridItem as GridStockVideoItemStorage);
    case GRID_ITEM_TYPE.STOCK_AUDIO:
      return uploadStockAudioGridItem(gridItem as GridStockAudioItemStorage);
    case GRID_ITEM_TYPE.PMW_STOCK_ICON:
      return uploadIconGridItem(gridItem as GridIconItemStorage);
    case GRID_ITEM_TYPE.PMW_STOCK_STILL_STICKER:
      return uploadStillStickerGridItem(gridItem as GridStillStickerItemStorage);
    case GRID_ITEM_TYPE.PMW_STOCK_EXTRACTED_GETTY_STICKER:
      return uploadExtractedGettyStickerGridItem(gridItem as GridExtractedGettyStickerItemStorage);
    case GRID_ITEM_TYPE.PMW_STOCK_SHAPE:
      return uploadShapeGridItem(gridItem as GridShapeItemStorage);
    case GRID_ITEM_TYPE.PMW_STOCK_ANIMATED_STICKER:
      return uploadAnimatedStickerGridItem(gridItem as GridAnimatedStickerItemStorage);
    case GRID_ITEM_TYPE.CLIPART:
      return uploadClipartGridItem(gridItem as GridClipartItemStorage);
    default:
      throw new Error(`Unhandled grid item: ${gridItem.type}`);
  }
};

const doUploadStockImageGridItem = async (gridItem: GridItemStorage, shouldLoadDataUrlImage = false): Promise<ElementData> => {
  if (shouldLoadDataUrlImage) {
    let itemToAddData;
    let doesFileExist;
    if (gridItem.source === SOURCE.AI_TEXT_TO_IMAGE) {
      itemToAddData = getGeneratedAiImageDataFromGridItemStorage(gridItem as GridStockImageItemStorage);
      const userImageHashedFilename = await getUserImageHashedFilenameForAiImage(Number(gridItem.id));
      doesFileExist =
        !!userImageHashedFilename && (await doesPublicFileExistAsync(getUrlForImageItemScreenTier(userImageHashedFilename, itemToAddData.source, ScreenTier.SMALL, false)));
    } else {
      itemToAddData = getStockImageDataFromGridStockImageItemStorage(gridItem as GridStockImageItemStorage);
      doesFileExist = await doesPublicFileExistAsync(getUrlForImageItemScreenTier(itemToAddData.hashedFilename, itemToAddData.source, ScreenTier.SMALL, false));
    }
    if (!doesFileExist) {
      const tempUploadingImageUID = uuidv4();

      const imageURL = gridItem.thumbSrc as string;
      const dataurl = await imageUrlToDataUrl(imageURL);
      if (dataurl) {
        void uploadStockImageGridItem(gridItem as GridStockImageItemStorage, tempUploadingImageUID);

        return {
          uid: tempUploadingImageUID,
          dataUrl: dataurl,
          type: itemToAddData.type,
          source: itemToAddData.source,
        } as TempImageData;
      }
      return uploadStockImageGridItem(gridItem as GridStockImageItemStorage);
    }
    return uploadStockImageGridItem(gridItem as GridStockImageItemStorage);
  }
  return uploadStockImageGridItem(gridItem as GridStockImageItemStorage);
};

export const getUserImageHashedFilenameForAiImage = async (aiGeneratedImageId: number): Promise<string> => {
  return (await window.PMW.readLocal('stockmedia/getUserImageHashedFilenameForAiImage', {
    aiGeneratedImageId,
  })) as Promise<string>;
};

const uploadStockImageGridItem = async (gridItem: GridStockImageItemStorage, tempUploadingImageUID?: string): Promise<ImageData> => {
  const {source} = gridItem;
  let data: UserImageVOResponse;

  switch (source as SOURCE) {
    case SOURCE.GETTY:
      data = (await window.PMW.pollingAjaxCallAsync(
        gettyImageUpload.bind(null, {
          type: gridItem.data.type as string,
          imageid: gridItem.data.id as string,
          title: gridItem.data.title as string,
          artist: gridItem.data.artist as string,
          collection: gridItem.data.col as number,
          tempUploadingImageUID,
        }),
        checkStockImageUploadStatus
      )) as UserImageVOResponse;
      break;

    case SOURCE.PIXABAY:
      data = (await window.PMW.pollingAjaxCallAsync(
        pixabayImageUpload.bind(null, {
          url: gridItem.data.hu as string,
          tempUploadingImageUID,
        }),
        checkStockImageUploadStatus
      )) as UserImageVOResponse;
      break;

    case SOURCE.STORYBLOCKS:
      data = (await window.PMW.pollingAjaxCallAsync(
        storyBlocksImageUpload.bind(null, {
          userid: getUserId(),
          imageid: gridItem.data.id as string,
          format: gridItem.data.format as string,
          tempUploadingImageUID,
        }),
        checkStockImageUploadStatus
      )) as UserImageVOResponse;
      break;

    case SOURCE.AI_TEXT_TO_IMAGE:
      data = (await window.PMW.pollingAjaxCallAsync(
        textToImageUpload.bind(null, {
          userId: gridItem.data.uploaderId ? Number(gridItem.data.uploaderId) : undefined,
          aiGeneratedImageId: gridItem.data.id as number,
          tempUploadingImageUID,
        }),
        checkGeneratedImageUploadStatus
      )) as UserImageVOResponse;
      break;

    case SOURCE.PMW_IMAGE:
      return getImageDataForPMWStockImage(gridItem);

    default:
      throw new Error(`Unhandled source: ${source}`);
  }

  return getImageDataFromUserImageVO(data);
};

const checkGeneratedImageUploadStatus = async (aiGeneratedImageId: number): Promise<any> => {
  return window.PMW.readLocal('stockmedia/getGeneratedImageStatus', {
    aiGeneratedImageId,
  });
};

const checkStockImageUploadStatus = async (itemHashedId: string): Promise<any> => {
  return window.PMW.readLocal('posterimage/getUserImageStatus', {
    hashedId: itemHashedId,
  });
};

const uploadStockVideoGridItem = async (gridItem: GridStockVideoItemStorage): Promise<VideoData> => {
  const {source} = gridItem;
  let data: UserVideoVOResponse;

  switch (source as SOURCE) {
    case SOURCE.GETTY:
      data = (await window.PMW.pollingAjaxCallAsync(
        gettyVideoUpload.bind(null, {
          videoId: gridItem.data.id as string,
          title: gridItem.data.title as string,
          artist: gridItem.data.artist as string,
          collection: gridItem.data.col as number,
        }),
        checkStockVideoUploadStatus
      )) as UserVideoVOResponse;
      break;

    case SOURCE.PIXABAY:
      data = (await window.PMW.pollingAjaxCallAsync(
        pixabayVideoUpload.bind(null, {
          videoId: gridItem.data.id as string,
          videoHighRes: gridItem.data.highResSrc as string,
          videoSmallRes: gridItem.data.smallResSrc as string,
        }),
        checkStockVideoUploadStatus
      )) as UserVideoVOResponse;
      break;

    case SOURCE.STORYBLOCKS:
      data = (await window.PMW.pollingAjaxCallAsync(
        storyBlocksVideoUpload.bind(null, {
          videoId: gridItem.data.id as string,
        }),
        checkStockVideoUploadStatus
      )) as UserVideoVOResponse;
      break;

    default:
      throw new Error(`Unhandled source: ${source}`);
  }

  return getVideoDataFromUserVideoVO(data);
};

const checkStockVideoUploadStatus = async (itemHashedId: string): Promise<any> => {
  return window.PMW.readLocal('postervideo/getUserVideoStatus', {
    hashedId: itemHashedId,
  });
};

const uploadStockAudioGridItem = async (gridItem: GridStockAudioItemStorage): Promise<AudioData> => {
  const data = (await window.PMW.pollingAjaxCallAsync(
    storyBlocksAudioUpload.bind(null, {
      audioId: gridItem.data.id as string,
      title: gridItem.name,
    }),
    checkStockAudioUploadStatus
  )) as UserAudioVOResponse;

  return getAudioDataFromUserAudioVO(data);
};

const checkStockAudioUploadStatus = async (itemHashedId: string): Promise<any> => {
  return window.PMW.readLocal('posteraudio/getUserAudioStatus', {
    hashedId: itemHashedId,
  });
};

const uploadIconGridItem = (gridItem: GridIconItemStorage): PMWIconData => {
  return {
    type: ElementDataType.ICON,
    hashedFilename: gridItem.hashedFilename,
  };
};

const uploadStillStickerGridItem = (gridItem: GridStillStickerItemStorage): PMWStillStickerData => {
  return {
    type: ElementDataType.STILL_STICKER,
    hashedFilename: gridItem.hashedFilename,
  };
};

const uploadExtractedGettyStickerGridItem = (gridItem: GridExtractedGettyStickerItemStorage): PMWExtractedGettyStickerData => {
  return {
    type: ElementDataType.EXTRACTED_GETTY_STICKER,
    hashedFilename: gridItem.hashedFilename,
  };
};

const uploadShapeGridItem = (gridItem: GridShapeItemStorage): PMWShapeData => {
  return {
    type: ElementDataType.PMW_SHAPE,
    hashedFilename: gridItem.hashedFilename,
  };
};

const uploadAnimatedStickerGridItem = (gridItem: GridAnimatedStickerItemStorage): AnimatedStickerData => {
  return {
    type: ElementDataType.ANIMATED_STICKER,
    hashedFilename: gridItem.hashedFilename,
    frameRate: gridItem.frameRate,
    duration: gridItem.duration,
    highResAnimatedSprite: gridItem.highResAnimatedSprite,
    screenAnimatedSprite: gridItem.screenAnimatedSprite,
  };
};

export const getFreeSelectedFilter = (): SelectedFilter => {
  return {filter: StockButtonFilters.PRICING, subFilter: PRICING_SUBFILTER_FREE};
};

export const getDefaultSelectedFilterForTab = (tab: StockPanelTabs): SelectedFilter[] => {
  switch (tab) {
    case StockPanelTabs.VIDEOS:
    case StockPanelTabs.PHOTOS:
    case StockPanelTabs.ALL:
    case StockPanelTabs.STICKERS:
      return window.PMW.isPAYGUser() && IS_GETTY_ENABLED ? [getFreeSelectedFilter()] : [];
    case StockPanelTabs.AUDIOS:
    case StockPanelTabs.ICONS:
    case StockPanelTabs.TEXT_TO_IMAGE:
    case StockPanelTabs.SHAPES:
      return [];
    default:
      throw new Error(`Unhandled tab: ${tab}`);
  }
};

const uploadClipartGridItem = async (gridItem: GridClipartItemStorage): Promise<ImageData> => {
  const {source} = gridItem;
  let data: UserImageVOResponse;

  switch (source) {
    case SOURCE.PIXABAY_ILLUSTRATIONS:
      data = (await window.PMW.pollingAjaxCallAsync(
        pixabayImageUpload.bind(null, {
          url: gridItem.data.hu as string,
        }),
        checkStockImageUploadStatus
      )) as UserImageVOResponse;
      break;

    case SOURCE.STORYBLOCKS_ILLUSTRATIONS:
      data = (await window.PMW.pollingAjaxCallAsync(
        storyBlocksImageUpload.bind(null, {
          userid: getUserId(),
          imageid: gridItem.data.id as string,
          format: gridItem.data.format as string,
        }),
        checkStockImageUploadStatus
      )) as UserImageVOResponse;
      break;

    case SOURCE.GETTY_ILLUSTRATIONS:
      data = (await window.PMW.pollingAjaxCallAsync(
        gettyImageUpload.bind(null, {
          type: gridItem.data.type as string,
          imageid: gridItem.data.id as string,
          title: gridItem.data.title as string,
          artist: gridItem.data.artist as string,
          collection: gridItem.data.col as number,
        }),
        checkStockImageUploadStatus
      )) as UserImageVOResponse;
      break;

    default:
      throw new Error(`Unhandled source: ${source}`);
  }

  return getImageDataFromUserImageVO(data);
};

export const getInitialOffsetsForInitialCarouselRequest = (tabs: StockPanelTabs[]): Partial<StockOffsets> => {
  const offsets: Partial<StockOffsets> = {};
  tabs.forEach((tab) => {
    switch (tab) {
      case StockPanelTabs.AUDIOS:
        offsets[StockSources.STORYBLOCK_AUDIO] = 0;
        break;
      case StockPanelTabs.SHAPES:
        offsets[StockSources.PMW_SHAPE] = 0;
        break;
      case StockPanelTabs.ICONS:
        offsets[StockSources.PMW_ICON] = 0;
        break;
      case StockPanelTabs.STICKERS:
        offsets[StockSources.PMW_ANIMATED_STICKER] = 0;
        offsets[StockSources.PMW_STILL_STICKER] = 0;
        offsets[StockSources.PMW_EXTRACTED_GETTY_STICKER] = 0;
        break;
      case StockPanelTabs.PHOTOS:
        if (!isUserStudent(getUserType())) {
          offsets[StockSources.GETTY_IMAGE] = 0;
        }
        offsets[StockSources.STORYBLOCK_IMAGE] = 0;
        if (!window.PMW.currentUserOnPageLoad?.isFreelancer) {
          offsets[StockSources.PIXABY_IMAGE] = 0;
        }
        break;
      case StockPanelTabs.VIDEOS:
        if (!isUserStudent(getUserType())) {
          offsets[StockSources.GETTY_VIDEO] = 0;
        }
        offsets[StockSources.STORYBLOCK_VIDEO] = 0;
        if (!window.PMW.currentUserOnPageLoad?.isFreelancer) {
          offsets[StockSources.PIXABY_VIDEO] = 0;
        }
        break;
      default:
        break;
    }
  });
  return offsets;
};

export const getSearchBarPlaceholderText = (selectedTab?: StockPanelTabs): string => {
  if (selectedTab) {
    switch (selectedTab) {
      case StockPanelTabs.ALL:
        return window.i18next.t('pmwjs_explore_photos_videos_audio_and_more');
      case StockPanelTabs.PHOTOS:
        return window.i18next.t('pmwjs_explore_stock_photos');
      case StockPanelTabs.VIDEOS:
        return window.i18next.t('pmwjs_explore_stock_videos');
      case StockPanelTabs.SHAPES:
        return window.i18next.t('pmwjs_explore_stock_shapes');
      case StockPanelTabs.STICKERS:
        return window.i18next.t('pmwjs_explore_stock_stickers');
      case StockPanelTabs.ICONS:
        return window.i18next.t('pmwjs_explore_stock_icons');
      case StockPanelTabs.AUDIOS:
        return window.i18next.t('pmwjs_explore_stock_audios');
      case StockPanelTabs.TEXT_TO_IMAGE:
        return window.i18next.t('pmwjs_prompt');
      default:
        throw new Error(`Unhandled stock tab: ${selectedTab}`);
    }
  }
  return window.i18next.t('pmwjs_explore_photos_videos_audio_and_more');
};

export const DEFAULT_TABS = [
  StockPanelTabs.ALL,
  StockPanelTabs.PHOTOS,
  StockPanelTabs.SHAPES,
  StockPanelTabs.STICKERS,
  StockPanelTabs.ICONS,
  StockPanelTabs.VIDEOS,
  StockPanelTabs.AUDIOS,
];

export const getStockTabsOrder = (isTextToImageAvailable: boolean): StockPanelTabs[] => {
  if (isTextToImageAvailable) {
    return [
      StockPanelTabs.ALL,
      StockPanelTabs.PHOTOS,
      StockPanelTabs.TEXT_TO_IMAGE,
      StockPanelTabs.SHAPES,
      StockPanelTabs.STICKERS,
      StockPanelTabs.ICONS,
      StockPanelTabs.VIDEOS,
      StockPanelTabs.AUDIOS,
    ];
  }

  return DEFAULT_TABS;
};

export const getPanelTitle = (selectedTab: string, searchTerm?: string): string => {
  // CodeReviewUsama: This is bad logic. If search term is defined but empty it means it's the tab view. Logically searchTerm should never be undefined.
  if (typeof searchTerm !== 'undefined') {
    if (selectedTab === StockPanelTabs.TEXT_TO_IMAGE) {
      return window.i18next.t('pmwjs_ai_images');
    }

    if (searchTerm !== '') {
      return window.i18next.t('pmwjs_search_x', {
        searchTerm,
      });
    }

    return window.i18next.t('pmwjs_explore_x', {
      x: selectedTab.toLowerCase(),
    });
  }

  return window.i18next.t('pmwjs_explore_media');
};

export const getBottomPills = (selectedTab: string, callback: (id: string) => void): PanelBottomPill[] => {
  return [
    {
      id: StockPanelTabs.ALL,
      text: window.i18next.t('pmwjs_all'),
      icon: 'icon-media',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.ALL,
      onClick(id: string): void {
        callback(id);
      },
    },
    {
      id: StockPanelTabs.PHOTOS,
      text: window.i18next.t('pmwjs_photos_2'),
      icon: 'icon-image',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.PHOTOS,
      onClick(id: string): void {
        callback(id);
      },
    },
    {
      id: StockPanelTabs.TEXT_TO_IMAGE,
      text: window.i18next.t('pmwjs_ai_images'),
      icon: 'icon-ai-image',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.TEXT_TO_IMAGE,
      onClick(id: string): void {
        callback(id);
      },
    },
    {
      id: StockPanelTabs.SHAPES,
      text: window.i18next.t('pmwjs_shapes'),
      icon: 'icon-shapes',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.SHAPES,
      onClick(id: string): void {
        callback(id);
      },
    },
    {
      id: StockPanelTabs.STICKERS,
      text: window.i18next.t('pmwjs_stickers'),
      icon: 'icon-sticker',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.STICKERS,
      onClick(id: string): void {
        callback(id);
      },
    },
    {
      id: StockPanelTabs.ICONS,
      text: window.i18next.t('pmwjs_icons'),
      icon: 'icon-icons',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.ICONS,
      onClick(id: string): void {
        callback(id);
      },
    },
    {
      id: StockPanelTabs.VIDEOS,
      text: window.i18next.t('pmwjs_videos_2'),
      icon: 'icon-videocam',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.VIDEOS,
      onClick(id: string): void {
        callback(id);
      },
    },
    {
      id: StockPanelTabs.AUDIOS,
      text: window.i18next.t('pmwjs_audio'),
      icon: 'icon-audio',
      isSelected: (selectedTab as StockPanelTabs) === StockPanelTabs.AUDIOS,
      onClick(id: string): void {
        callback(id);
      },
    },
  ];
};

export const getRequiredSourcesForSelectedTab = (selectedTab: string, selectedFilters: SelectedFilter[]): StockSources[] => {
  const sources = getStockSourcesForTab(selectedTab as StockPanelTabs);
  if (selectedFilters.length > 0 && (selectedTab as StockPanelTabs) !== StockPanelTabs.AUDIOS) {
    const allSources: string[][] = [];
    selectedFilters.forEach((filter) => {
      const src = FILTERED_SOURCES[selectedTab][filter.filter][filter.subFilter ?? '0'];
      allSources.push(src);
    });
    allSources.push(sources);
    return intersection(...allSources) as StockSources[];
  }
  return sources;
};

export const getOptionsForStockGetter = (selectedFilters: SelectedFilter[], selectedTab: string, audioFilterIDs: (StockButtonFilters | string)[]): StockGetterOptions => {
  const stockGetterParam: StockGetterOptions = {};
  if (selectedFilters.length > 0) {
    if ((selectedTab as StockPanelTabs) === StockPanelTabs.AUDIOS) {
      const audioFilters: Record<StockButtonFilters, string[]> | Record<string, string[]> = {};
      selectedFilters.forEach((selectedFilter) => {
        const isPresent = audioFilterIDs.includes(selectedFilter.filter);
        if (isPresent && selectedFilter.subFilter) {
          audioFilters[selectedFilter.filter as StockButtonFilters] = [selectedFilter.subFilter];
        }
      });
      stockGetterParam.audioFilters = audioFilters;
    } else if ((selectedTab as StockPanelTabs) === StockPanelTabs.VIDEOS) {
      stockGetterParam.only4KVideos = selectedFilters.some((filters) => {
        return (filters.filter as StockButtonFilters) === StockButtonFilters.QUALITY && filters.subFilter === UHD_APPLIED_VIDEO_FILTER_ID;
      })
        ? true
        : undefined;
    } else if ((selectedTab as StockPanelTabs) === StockPanelTabs.ICONS) {
      selectedFilters.forEach((selectedFilter) => {
        if (selectedFilter.subFilter) {
          stockGetterParam.iconCategories = ICON_FILTERS_TO_CATEGORY_MAPPING[selectedFilter.subFilter];
        }
      });
    }
  }
  return stockGetterParam;
};

export const getCarouselDataForShapeItem = (shapeData: PMWStockBackendShape): CarouselItemData => {
  return {
    gridItemData: shapeDataToShapeItem(shapeData),
    height: FALLBACK_GRID_ITEM_HEIGHT,
    width: FALLBACK_GRID_ITEM_WIDTH,
  };
};

export const getCarouselDataForIconItem = (iconData: PMWStockBackendIcon): CarouselItemData => {
  return {
    gridItemData: iconDataToIconItem({...iconData, sortOrder: iconData.adminScore, isHidden: iconData.isHidden !== 1}),
    height: FALLBACK_GRID_ITEM_HEIGHT,
    width: FALLBACK_GRID_ITEM_WIDTH,
  };
};

export const getCarouselDataForStillStickerItem = (iconData: PMWStockBackendStillSticker): CarouselItemData => {
  return {
    gridItemData: stillStickerDataToStickerItem({...iconData, sortOrder: iconData.adminScore, isHidden: iconData.isHidden !== 1}),
    height: FALLBACK_GRID_ITEM_HEIGHT,
    width: FALLBACK_GRID_ITEM_WIDTH,
  };
};

export const getCarouselDataForExtractedGettyStickerItem = (extractedGettyStickerData: PMWStockBackendExtractedGettySticker): CarouselItemData => {
  return {
    gridItemData: extractedGettyStickerDataToStickerItem({
      ...extractedGettyStickerData,
      sortOrder: extractedGettyStickerData.adminScore,
      isHidden: extractedGettyStickerData.isHidden !== 1,
    }),
    height: FALLBACK_GRID_ITEM_HEIGHT,
    width: FALLBACK_GRID_ITEM_WIDTH,
  };
};

export const getCarouselDataForStickerItem = (stickerData: PMWStockBackendAnimatedSticker): CarouselItemData => {
  return {
    gridItemData: animatedStickerDataToAnimatedStickerItem(stickerData),
    height: FALLBACK_GRID_ITEM_HEIGHT,
    width: FALLBACK_GRID_ITEM_WIDTH,
  };
};

export const getCarouselDataForAudioItem = (audioData: GridStockAudioItemBackEndData, height: number): CarouselItemData => {
  return {
    gridItemData: {
      ...audioDataToAudioItem(audioData),
      gridItemHeight: height,
    } as GridStockAudioItemStorage,
    width: STOCK_AUDIO_GRID_ITEM_WIDTH,
    height,
  };
};

export const getCarouselDataForImageItem = (imageData: GridStockImageItemBackEndData, source: StockSources): CarouselItemData => {
  return {
    gridItemData: imageDataToImageItem(imageData, source),
    width: Number(imageData.w),
    height: Number(imageData.h),
  };
};

export const getCarouselDataForVideoItem = (videoData: GridStockVideoItemBackEndData, source: StockSources): CarouselItemData | undefined => {
  const gridItem = videoDataToVideoItem(videoData, source);

  if (gridItem) {
    return {
      gridItemData: gridItem,
      width: Number(videoData.w),
      height: Number(videoData.h),
    };
  }

  return undefined;
};

export const getGridID = (tab: StockPanelTabs): StockGridIDs => {
  switch (tab) {
    case StockPanelTabs.ALL:
      return StockGridIDs.ALL;
    case StockPanelTabs.PHOTOS:
      return StockGridIDs.PHOTOS;
    case StockPanelTabs.VIDEOS:
      return StockGridIDs.VIDEOS;
    case StockPanelTabs.SHAPES:
      return StockGridIDs.SHAPES;
    case StockPanelTabs.STICKERS:
      return StockGridIDs.STICKERS;
    case StockPanelTabs.ICONS:
      return StockGridIDs.ICONS;
    case StockPanelTabs.AUDIOS:
      return StockGridIDs.AUDIOS;
    case StockPanelTabs.TEXT_TO_IMAGE:
      return StockGridIDs.TEXT_TO_IMAGE;
    default:
      throw new Error(`Unhandled selected tab: ${tab} for getting grid id`);
  }
};

// CodeReviewUsamaAsk: Move this function back to stock-panel. Alot of functions don't make sense in this file. Why was this file even created
export const getTabItem = (tab: StockPanelTabs, isDisabled: boolean, textClasses?: string): TabItemContentStorage => {
  return {
    tabKey: tab,
    text: getTabDisplayText(tab),
    disabled: isDisabled,
    textClassName: textClasses,
  };
};

const getTabDisplayText = (tab: StockPanelTabs): string => {
  switch (tab) {
    case StockPanelTabs.ALL:
      return window.i18next.t('pmwjs_all');
    case StockPanelTabs.PHOTOS:
      return window.i18next.t('pmwjs_photos_2');
    case StockPanelTabs.SHAPES:
      return window.i18next.t('pmwjs_shapes');
    case StockPanelTabs.STICKERS:
      return window.i18next.t('pmwjs_stickers');
    case StockPanelTabs.ICONS:
      return window.i18next.t('pmwjs_icons');
    case StockPanelTabs.VIDEOS:
      return window.i18next.t('pmwjs_videos_2');
    case StockPanelTabs.AUDIOS:
      return window.i18next.t('pmwjs_audio');
    case StockPanelTabs.TEXT_TO_IMAGE:
      return window.i18next.t('pmwjs_ai_images');
    default:
      throw new Error(`Unhandled tab type for getTabDisplayText: ${tab}`);
  }
};

export const getStockFilterForAudioFilterBackendData = (backendData: AudioCategoryBackendData): StockFilter => {
  return {
    id: backendData.id as StockFilterID,
    type: StockFilterTypes.DROPDOWN,
    name: AUDIO_CATEGORY_ID_TO_STOCK_BUTTON_FILTER[backendData.id],
    icon: AUDIO_FILTER_ID_TO_ICON_MAPPING[backendData.id],
    subFilters: backendData.subcategories.map((subcategoryData) => {
      return {
        id: `${subcategoryData.id}`,
        text: subcategoryData.displayName,
      };
    }),
  };
};

export const getSelectedSubfilterIDForStockFilter = (stockFilter: StockFilter, selectedFilters: SelectedFilter[]): string | undefined => {
  if (selectedFilters.length > 0) {
    const selectedFilter = selectedFilters.find((filterSelected) => {
      return (filterSelected.filter as StockFilterID) === stockFilter.id;
    });
    if (selectedFilter) {
      const selectedSubfilter = stockFilter.subFilters.find((subfilter) => {
        return subfilter.id === selectedFilter.subFilter;
      });
      return selectedSubfilter?.id;
    }
  }
  return undefined;
};

const getTagForMediaItem = (source: SOURCE): Partial<GridMediaItemTag> | undefined => {
  switch (source) {
    case SOURCE.GETTY_ILLUSTRATIONS:
    case SOURCE.GETTY:
      return getTagForGettyMedia();
    default:
      return undefined;
  }
};

const gridAudioItemBackendDataToAudioItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridStockAudioItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.STOCK_AUDIO,
    name: gridItemBackendData.name as string,
    data: gridItemBackendData.data as Record<string, any>,
    id: gridItemBackendData.id as string,
  };
};

const gridShapeItemBackendDataToShapeItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridShapeItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.PMW_STOCK_SHAPE,
    hashedFilename: gridItemBackendData.hashedFilename as string,
    id: gridItemBackendData.id as string,
  };
};

const gridClipartItemBackendDataToClipartItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridClipartItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.CLIPART,
    thumbSrc: gridItemBackendData.thumbSrc as string,
    source: gridItemBackendData.source as SOURCE,
    id: gridItemBackendData.id as string,
    data: gridItemBackendData.data as Record<string, any>,
    tag: getTagForMediaItem(gridItemBackendData.source as SOURCE),
  };
};

const gridStockVideoItemBackendDataToStockVideoItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridStockVideoItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.STOCK_VIDEO,
    source: gridItemBackendData.source as string,
    data: gridItemBackendData.data as Record<string, any>,
    id: gridItemBackendData.id as string,
    isUHD: !!gridItemBackendData.isUHD,
    tag: getTagForMediaItem(gridItemBackendData.source as SOURCE),
  };
};

const gridAnimatedStickerItemBackendDataToAnimatedStickerItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridAnimatedStickerItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.PMW_STOCK_ANIMATED_STICKER,
    hashedFilename: gridItemBackendData.hashedFilename as string,
    duration: Number(gridItemBackendData.duration),
    frameRate: Number(gridItemBackendData.frameRate),
    id: gridItemBackendData.id as string,
    highResAnimatedSprite: gridItemBackendData.highResAnimatedSprite as AnimatedSprite,
    screenAnimatedSprite: gridItemBackendData.screenAnimatedSprite as AnimatedSprite,
  };
};

const gridStockImageItemBackendDataToStockImageItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridStockImageItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.STOCK_IMAGE,
    thumbSrc: gridItemBackendData.thumbSrc as string,
    source: gridItemBackendData.source as SOURCE,
    id: gridItemBackendData.id as string,
    data: gridItemBackendData.data as Record<string, any>,
    tag: getTagForMediaItem(gridItemBackendData.source as SOURCE),
  };
};

const gridIconItemBackendDataToStockIconItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridIconItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.PMW_STOCK_ICON,
    hashedFilename: gridItemBackendData.hashedFilename as string,
    id: gridItemBackendData.id as string,
  };
};

const gridStillStickerItemBackendDataToStockStillStickerItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridStillStickerItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.PMW_STOCK_STILL_STICKER,
    hashedFilename: gridItemBackendData.hashedFilename as string,
    id: gridItemBackendData.id as string,
  };
};

const gridExtractedGettyStickerItemBackendDataToStockExtractedGettyStickerItem = (gridItemBackendData: GridItemBaseStorageBackendData): GridExtractedGettyStickerItemStorage => {
  return {
    ...gridItemBackendData,
    type: GRID_ITEM_TYPE.PMW_STOCK_EXTRACTED_GETTY_STICKER,
    hashedFilename: gridItemBackendData.hashedFilename as string,
    id: gridItemBackendData.id as string,
  };
};

export const getGridItemForBackendData = (gridItemBackendData: GridItemBaseStorageBackendData): GridItemBaseStorage => {
  switch (gridItemBackendData.type as GRID_ITEM_TYPE) {
    case GRID_ITEM_TYPE.STOCK_AUDIO:
      return gridAudioItemBackendDataToAudioItem(gridItemBackendData);
    case GRID_ITEM_TYPE.PMW_STOCK_SHAPE:
    case GRID_ITEM_TYPE.SHAPE:
      return gridShapeItemBackendDataToShapeItem(gridItemBackendData);
    case GRID_ITEM_TYPE.CLIPART:
      return gridClipartItemBackendDataToClipartItem(gridItemBackendData);
    case GRID_ITEM_TYPE.STOCK_VIDEO:
      return gridStockVideoItemBackendDataToStockVideoItem(gridItemBackendData);
    case GRID_ITEM_TYPE.STICKER: // todo: delete this once GRID_ITEM_TYPE.STICKER is deleted (using GRID_ITEM_TYPE.PMW_STOCK_ANIMATED_STICKER instead from now on)
    case GRID_ITEM_TYPE.PMW_STOCK_ANIMATED_STICKER:
    case GRID_ITEM_TYPE.ANIMATED_STICKER:
      return gridAnimatedStickerItemBackendDataToAnimatedStickerItem(gridItemBackendData);
    case GRID_ITEM_TYPE.STOCK_IMAGE:
      return gridStockImageItemBackendDataToStockImageItem(gridItemBackendData);
    case GRID_ITEM_TYPE.PMW_STOCK_ICON:
    case GRID_ITEM_TYPE.ICON:
      return gridIconItemBackendDataToStockIconItem(gridItemBackendData);
    case GRID_ITEM_TYPE.PMW_STOCK_STILL_STICKER:
    case GRID_ITEM_TYPE.STILL_STICKER:
      return gridStillStickerItemBackendDataToStockStillStickerItem(gridItemBackendData);
    case GRID_ITEM_TYPE.PMW_STOCK_EXTRACTED_GETTY_STICKER:
      return gridExtractedGettyStickerItemBackendDataToStockExtractedGettyStickerItem(gridItemBackendData);
    default:
      throw new Error(`Unhandled type: ${gridItemBackendData.type as string}`);
  }
};

export const getGATrackingSourceFromMediaSource = (src: SOURCE): string => {
  switch (src) {
    case SOURCE.GETTY:
    case SOURCE.GETTY_ILLUSTRATIONS:
      return capitalize(SOURCE.GETTY);
    case SOURCE.STORYBLOCKS:
    case SOURCE.STORYBLOCKS_ILLUSTRATIONS:
      return capitalize(SOURCE.STORYBLOCKS);
    case SOURCE.PIXABAY:
    case SOURCE.PIXABAY_ILLUSTRATIONS:
      return capitalize(SOURCE.PIXABAY);
    case SOURCE.PMW_IMAGE:
      return capitalize(SOURCE.PMW_IMAGE);
    default:
      return SOURCE.PMW.toUpperCase();
  }
};

export const getGATrackingTypeFromMediaType = (gridItemType: GRID_ITEM_TYPE): string => {
  switch (gridItemType) {
    case GRID_ITEM_TYPE.STOCK_IMAGE:
      return 'Photo';
    case GRID_ITEM_TYPE.STOCK_VIDEO:
      return 'Video';
    case GRID_ITEM_TYPE.STOCK_AUDIO:
      return 'Audio';
    case GRID_ITEM_TYPE.CLIPART:
    case GRID_ITEM_TYPE.PMW_STOCK_STILL_STICKER:
      return 'StillSticker';
    case GRID_ITEM_TYPE.PMW_STOCK_ANIMATED_STICKER:
      return 'AnimatedSticker';
    case GRID_ITEM_TYPE.PMW_STOCK_SHAPE:
      return 'Shape';
    case GRID_ITEM_TYPE.PMW_STOCK_ICON:
      return 'Icon';
    default:
      return '';
  }
};

export const getGATrackingSearchTerm = (tab: StockPanelTabs): string => {
  switch (tab) {
    case StockPanelTabs.ICONS:
      return 'Icon';
    case StockPanelTabs.PHOTOS:
      return 'Photo';
    case StockPanelTabs.VIDEOS:
      return 'Video';
    case StockPanelTabs.AUDIOS:
      return 'Audio';
    case StockPanelTabs.SHAPES:
      return 'Shape';
    case StockPanelTabs.STICKERS:
      return 'Sticker';
    case StockPanelTabs.ALL:
    default:
      return capitalize(tab);
  }
};

export const isImageSource = (source: StockSources): boolean => {
  const sources = getStockSourcesForTab(StockPanelTabs.PHOTOS);
  return sources.includes(source);
};

export const getGATrackingFilterTerm = (filters: SelectedFilter[]): string => {
  for (const filter of filters) {
    if (filter.filter === StockButtonFilters.ANIMATION && filter.subFilter === '3') {
      return 'PMWAnimated';
    }
    if (filter.filter === StockButtonFilters.ANIMATION && filter.subFilter === '4') {
      return 'Still';
    }
  }
  return '';
};

export const getAiImageFilterForStylePreset = (stylePreset: StabilityAIStylePreset): AIImageFilters => {
  const extension = getCompatibleImageFileExtension('webp');
  switch (stylePreset) {
    case StabilityAIStylePreset.FANTASY_ART:
      return {
        text: window.i18next.t('pmwjs_fantasy'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/fantasy.${extension}`),
      };
    case StabilityAIStylePreset.PHOTOGRAPHIC:
      return {
        text: window.i18next.t('pmwjs_photographic'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/photo.${extension}`),
      };
    case StabilityAIStylePreset.COMIC_BOOK:
      return {
        text: window.i18next.t('pmwjs_comic'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/comic.${extension}`),
      };
    case StabilityAIStylePreset.ANIME:
      return {
        text: window.i18next.t('pmwjs_anime'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/anime.${extension}`),
      };
    case StabilityAIStylePreset.CINEMATIC:
      return {
        text: window.i18next.t('pmwjs_cinematic'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/cinematic.${extension}`),
      };
    case StabilityAIStylePreset.THREE_D_MODEL:
      return {
        text: window.i18next.t('pmwjs_three_d_model'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/3d-model.${extension}`),
      };
    case StabilityAIStylePreset.ANALOG_FILM:
      return {
        text: window.i18next.t('pmwjs_vintage'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/vintage.${extension}`),
      };
    case StabilityAIStylePreset.DIGITAL_ART:
      return {
        text: window.i18next.t('pmwjs_digital_art'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/digital.${extension}`),
      };
    case StabilityAIStylePreset.LINE_ART:
      return {
        text: window.i18next.t('pmwjs_line_art'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/line-art.${extension}`),
      };
    case StabilityAIStylePreset.NEON_PUNK:
      return {
        text: window.i18next.t('pmwjs_futuristic'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/futuristic.${extension}`),
      };
    case StabilityAIStylePreset.PIXEL_ART:
      return {
        text: window.i18next.t('pmwjs_pixel_art'),
        id: stylePreset,
        thumbnailImage: getAssetUrl(`stabilityaistylepresets/images.${extension}`),
      };
    default:
      throw new Error(`Unhandled style preset: ${stylePreset}`);
  }
};

export const getAiImageFilterForAspectRatio = (aspectRatio: AspectRatio): AIImageFilters => {
  const extension = getCompatibleImageFileExtension('webp');
  switch (aspectRatio) {
    case AspectRatio.SQUARE:
      return {text: window.i18next.t('pmwjs_square'), id: aspectRatio, thumbnailImage: getAssetUrl(`aspectratios/square.${extension}`)};
    case AspectRatio.LANDSCAPE:
      return {text: window.i18next.t('pmwjs_landscape'), id: aspectRatio, thumbnailImage: getAssetUrl(`aspectratios/rectangle.${extension}`)};
    case AspectRatio.PORTRAIT:
      return {text: window.i18next.t('pmwjs_portrait'), id: aspectRatio, thumbnailImage: getAssetUrl(`aspectratios/portrait.${extension}`)};
    default:
      throw new Error(`Unhandled aspect ratio: ${aspectRatio}`);
  }
};

export const getDropdownItemsForStyleFilterForAIImage = (): AIImageFilters[] => {
  return Object.values(StabilityAIStylePreset).map(getAiImageFilterForStylePreset);
};

export const getDropdownItemsForAspectRatioFilterForAIImage = (): AIImageFilters[] => {
  return Object.values(AspectRatio).map(getAiImageFilterForAspectRatio);
};

export const getHeightForCarouselType = (stockCarouselType: StockCarouselType, isMobile: boolean): number => {
  if (isMobile) return 72;
  return stockCarouselType === StockCarouselType.AUDIOS ? 80 : 100;
};

export const getGridItemForAIGeneratedImageV2 = (aiGeneratedImageBackendData: AITextToImageBackendData): GridStockImageItemStorage => {
  const thumbSizeURL = getUrlForGeneratedAiImage(aiGeneratedImageBackendData.hashedFilename, aiGeneratedImageBackendData.imageProps.ext, MediaItemSizeType.THUMB);

  return {
    type: GRID_ITEM_TYPE.STOCK_IMAGE,
    id: aiGeneratedImageBackendData.id,
    thumbSrc: thumbSizeURL,
    source: SOURCE.AI_TEXT_TO_IMAGE,
    width: Number(aiGeneratedImageBackendData.imageProps.width),
    height: Number(aiGeneratedImageBackendData.imageProps.height),
    data: {
      uploaderId: aiGeneratedImageBackendData.uploaderId,
      id: aiGeneratedImageBackendData.id,
    },
  };
};

export const createKeepFiltersHiddenForTab = (trueTabs: StockPanelTabs[] = []): Record<StockPanelTabs, boolean> => {
  return Object.values(StockPanelTabs).reduce<Record<StockPanelTabs, boolean>>(
    (acc, tab) => {
      acc[tab] = trueTabs.includes(tab);
      return acc;
    },
    {} as Record<StockPanelTabs, boolean>
  );
};
