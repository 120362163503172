import React from "react";
import "./modal-container.scss";
import {ErrorModal} from "../error-modal";
import Emitter from '@/services/event-emitter';
import {hideLoading, showLoading} from "@Libraries/loading-toast-library";

export const MODAL_IDS = {
    //***** Not Used ********//
    REMOVE_BG_PURCHASE_MODAL_ID: "remove-bg-purchase-modal-react",

  //***** To Migrate ********//
  SENDER_PROFILE_FORM_MODAL_ID: "sender-profile-form-modal",
  MY_STUFF_LEFT_SIDEBAR_MORE_OPTS: "left-sidebar-more-options",
  REMOVE_INVALID_EMAILS_MODAL_ID: "remove-invalid-emails",
  MENU_LIST_MODAL: "menu-list-modal",
  BRAND_ACTIVITY_MODAL: "brand-activity-modal",
  BRAND_COLOR_MODAL: "brand-color-modal",
  BRAND_PREVIEW_MODAL: "brand-preview-modal",
  UPLOAD_FONT_MODAL: "upload-font-modal",
  ADD_BRAND_FONT_MODAL_ID: "add-brand-font-modal",
  ERROR_MODAL_ID: "error-modal",
  SETTINGS_MODAL_ID: "settings-modal-react",
  PREMIUM_AGREEMENT_MODAL_ID: "premium-license-agreement-modal"
};
export default class ModalContainer extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            modals: {}
        };
    }

    render = () => {
        return <div id="react-modal-widget-container">
            {Object.values(this.state.modals)}
        </div>;
    };
    componentDidMount = () => {
        Emitter.on('modal:open', this.#openModal);
        Emitter.on('modal:close', this.#closeModal);
    };

    componentWillUnmount() {
        Emitter.off('modal:open');
    }

    /**
     * Closes the modal
     * @param {string} id
     * @return {Promise<void>}
     */
    #closeModal = async (id) => {
        if (!this.state.modals[id]) {
            return;
        }
        let newModals = {...this.state.modals};
        const newModal = React.cloneElement(newModals[id], {open: false});
        this.setState({
            modals: {
                ...this.state.modals,
                [id]: newModal
            }
        });
    };
    /**
     * Loads and opens the given modal
     * @param {Object} data
     * @return {Promise<void>}
     */
    #openModal = async (data) => {
        const newModal = await getModal(data.id, data.props);
        this.setState({
            modals: {
                ...this.state.modals,
                [data.id]: newModal
            }
        });
    };
}
export const closeModal = (id) => {
    Emitter.emit('modal:close', id);
};
export const openModal = (id, props = {}) => {
    Emitter.emit('modal:open', {
        id: id,
        props: props
    });
};
/**
 * Returns modal for modal type and params
 * @param {string} modalId
 * @param {Object} modalProps
 * @return {JSX.Element}
 */
const getModal = async (modalId, modalProps = {}) => {
    if (!modalId) {
        throw "Modal id not specified";
    }
    let props = {...modalProps},
        module = null;
    if (!Object.values(MODAL_IDS).includes(modalId)) {
        throw "Modal id not handled: " + modalId;
    }
    let modalJsx,
        loadingKey = "getModal-" + modalId;
    showLoading(loadingKey, {
        delay: 600
    });
    props.key = props.id = modalId;
    switch (modalId) {
        case MODAL_IDS.ERROR_MODAL_ID:
            modalJsx = <ErrorModal {...props} />;
            break;
        case MODAL_IDS.REMOVE_BG_PURCHASE_MODAL_ID:
            module = await import("../remove-bg-purchase-modal");
            modalJsx = <module.RemoveBgPurchaseModal {...props} />;
            break;
        case MODAL_IDS.PREMIUM_AGREEMENT_MODAL_ID:
            module = await import("../premium-agreement-modal");
            modalJsx = <module.PremiumAgreementModal {...props} />;
            break;
        case MODAL_IDS.SETTINGS_MODAL_ID:
            module = await import("../settings-modal");
            modalJsx = <module.SettingsModal {...props} />;
            break;
        case MODAL_IDS.ADD_BRAND_FONT_MODAL_ID:
            module = await import ("../add-brand-font-modal");
            modalJsx = <module.AddBrandFontModal {...props} />;
            break;
        case MODAL_IDS.UPLOAD_FONT_MODAL:
            module = await import ("../upload-font-modal");
            modalJsx = <module.UploadFontModal {...props} />;
            break;
        case MODAL_IDS.BRAND_ACTIVITY_MODAL:
            module = await import ("../brand-activity-log-modal");
            modalJsx = <module.BrandActivityLogModal {...props} />;
            break;
        case MODAL_IDS.BRAND_COLOR_MODAL:
            module = await import ("../brand-color-modal");
            modalJsx = <module.BrandColorModal {...props} />;
            break;
        case MODAL_IDS.BRAND_PREVIEW_MODAL:
            module = await import("../brand-preview-modal");
            modalJsx = <module.BrandPreviewModal {...props} />;
            break;
        case MODAL_IDS.MENU_LIST_MODAL:
            module = await import ("../menu-list-modal");
            modalJsx = <module.MenuListModal {...props} />;
            break;
        case MODAL_IDS.REMOVE_INVALID_EMAILS_MODAL_ID:
            module = await import ("../remove-invalid-emails-modal");
            modalJsx = <module.RemoveInvalidEmailsModal {...props} />;
            break;
        case MODAL_IDS.SENDER_PROFILE_FORM_MODAL_ID:
            module = await import ("../sender-profile-form-modal");
            modalJsx = <module.SenderProfileFormModal {...props} />;
            break;
        case MODAL_IDS.MY_STUFF_LEFT_SIDEBAR_MORE_OPTS:
            module = await import ("../mystuff-left-sidebar/components/more-options-modal");
            modalJsx = <module.MoreOptionsModal {...props} />;
            break;
        default:
            throw "Modal id not handled: " + modalId;
    }
    hideLoading(loadingKey);
    return modalJsx;
};
