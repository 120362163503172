import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {FillObject} from '@PosterWhiteboard/classes/fill.class';
import {Fill, FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {isClipboardAPISupported, readFromClipboard} from '@Utils/clipboard.util';
import type {DeepPartial} from '@/global';

export enum LIST_TYPES {
  NONE = 0,
  UNORDERED = 1,
  ORDERED = 2,
}

export type LIST_STYLE = ORDERED_LIST_STYLE | UNORDERED_LIST_STYLE;

export enum ORDERED_LIST_STYLE {
  NUMBERED_DECIMAL = 1,
  NUMBERED = 2,
  ALPHA_UPPER = 3,
  ALPHA_LOWER = 4,
  ROMAN_UPPER = 5,
  ROMAN_LOWER = 6,
}

export enum UNORDERED_LIST_STYLE {
  CIRCLE_1 = 1,
  CIRCLE_2 = 2,
  CIRCLE_3 = 3,
  SQUARE_1 = 4,
  SQUARE_2 = 5,
  SQUARE_3 = 6,
  SQUARE_4 = 7,
  ARROW_1 = 8,
  ARROW_2 = 9,
  ARROW_3 = 10,
  TICK_1 = 11,
  TICK_2 = 12,
  AESTERISK = 13,
  LEAF = 14,
  FLOWER = 15,
  EDIT = 16,
  STAR = 17,
}

export const UNORDERED_BULLETS_ASCII_MAP = new Map<number, string>([
  [UNORDERED_LIST_STYLE.CIRCLE_1, '41'],
  [UNORDERED_LIST_STYLE.CIRCLE_3, '42'],
  [UNORDERED_LIST_STYLE.CIRCLE_2, '43'],
  [UNORDERED_LIST_STYLE.AESTERISK, '44'],
  [UNORDERED_LIST_STYLE.LEAF, '45'],
  [UNORDERED_LIST_STYLE.ARROW_1, '46'],
  [UNORDERED_LIST_STYLE.ARROW_3, '47'],
  [UNORDERED_LIST_STYLE.ARROW_2, '48'],
  [UNORDERED_LIST_STYLE.EDIT, '49'],
  [UNORDERED_LIST_STYLE.FLOWER, '4A'],
  [UNORDERED_LIST_STYLE.SQUARE_1, '4B'],
  [UNORDERED_LIST_STYLE.SQUARE_2, '4C'],
  [UNORDERED_LIST_STYLE.SQUARE_3, '4D'],
  [UNORDERED_LIST_STYLE.SQUARE_4, '4E'],
  [UNORDERED_LIST_STYLE.STAR, '4F'],
  [UNORDERED_LIST_STYLE.TICK_1, '50'],
  [UNORDERED_LIST_STYLE.TICK_2, '51'],
]);

export interface TextListObject {
  fill: FillObject;
  type: LIST_TYPES;
  style: LIST_STYLE;
  width: number;
}

export class TextList extends CommonMethods {
  public type: LIST_TYPES = LIST_TYPES.NONE;
  public style: LIST_STYLE = ORDERED_LIST_STYLE.NUMBERED_DECIMAL;
  public width = 0;
  public fill: Fill = new Fill();

  constructor(object: DeepPartial<TextListObject>) {
    super();
    this.copyVals(object);
  }

  public copyVals(obj: DeepPartial<TextListObject> = {}): void {
    const {fill, ...listProperties} = obj;
    super.copyVals(listProperties);
    this.fill.copyVals(fill);
  }

  public toObject(): TextListObject {
    return {
      type: this.type,
      style: this.style,
      width: this.width,
      fill: this.fill.toObject(),
    };
  }

  public hasSolidBulletFill(): boolean {
    return this.fill.fillType === FillTypes.SOLID;
  }

  public hasLinearGradientBulletFill(): boolean {
    return this.fill.fillType === FillTypes.LINEAR_GRADIENT;
  }

  public hasRadialGradientBulletFill(): boolean {
    return this.fill.fillType === FillTypes.RADIAL_GRADIENT;
  }

  public hasGradientBulletFill(): boolean {
    return this.hasLinearGradientBulletFill() || this.hasRadialGradientBulletFill();
  }

  public isOrdered(): boolean {
    return this.type === LIST_TYPES.ORDERED;
  }

  public isUnordered(): boolean {
    return this.type === LIST_TYPES.UNORDERED;
  }

  public hasList(): boolean {
    return this.type !== LIST_TYPES.NONE;
  }

  public getDefaultListStyleFromType(): LIST_STYLE {
    if (this.isOrdered()) {
      return ORDERED_LIST_STYLE.NUMBERED_DECIMAL;
    }

    return UNORDERED_LIST_STYLE.CIRCLE_1;
  }

  public getDefaultStyleForType(type: LIST_TYPES): LIST_STYLE {
    if (type === LIST_TYPES.ORDERED) {
      return ORDERED_LIST_STYLE.NUMBERED_DECIMAL;
    }
    return UNORDERED_LIST_STYLE.CIRCLE_1;
  }
}

export const getListTypeFromPastedText = async (): Promise<LIST_TYPES> => {
  if (isClipboardAPISupported()) {
    const clipboardContents = await navigator.clipboard.read();
    return getListTypeFromPastedTextFromClipboardContents(clipboardContents);
  }

  const html = await readFromClipboard();
  return getListTypeFromHTML(html);
};

const getListTypeFromPastedTextFromClipboardContents = async (clipboardContents: ClipboardItems): Promise<LIST_TYPES> => {
  for (const item of clipboardContents) {
    if (item.types.includes('text/html')) {
      const blobHtml = await item.getType('text/html');
      const html = await blobHtml.text();
      const listType = getListTypeFromHTML(html);

      if (listType !== LIST_TYPES.NONE) {
        return listType;
      }
    }
  }

  return LIST_TYPES.NONE;
};

const getListTypeFromHTML = (html: string): LIST_TYPES => {
  if (html.includes('<ol') && html.includes('<li')) {
    return LIST_TYPES.ORDERED;
  }
  if (html.includes('<ul') && html.includes('<li')) {
    return LIST_TYPES.UNORDERED;
  }

  return LIST_TYPES.NONE;
};
