import {isFiletypeVideo} from '@Utils/video.util';
import {imageFileToDataUrl, isFiletypeImage} from '@Utils/image.util';
import type {GridUserImageItemStorage} from '@Components/base-grid/components/grid-user-image-item';
import {getGridUserImageItemStorageForUserImageVO} from '@Components/base-grid/components/grid-user-image-item';
import type {GridUserVideoItemStorage} from '@Components/base-grid/components/grid-user-video-item';
import {getGridUserVideoItemStorageForUserVideoVO} from '@Components/base-grid/components/grid-user-video-item';
import type {GridUserAudioItemStorage} from '@Components/base-grid/components/grid-user-audio-item';
import {getGridUserAudioItemStorageForUserAudioVO} from '@Components/base-grid/components/grid-user-audio-item';
import type {AudioData, ElementData, ImageData, TempImageData, VideoData} from '@Libraries/add-media-library';
import {
  ElementDataType,
  getAudioDataFromGridUserAudioItemStorage,
  getImageDataFromGridUserImageItemStorage,
  getVideoDataFromGridUserVideoItemStorage,
} from '@Libraries/add-media-library';
import {openErrorModal} from '@Modals/error-modal';
import {GROWL_TYPE, showMessageGrowl} from '@Components/message-growl';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import {GRID_ITEM_TYPE} from '@Components/base-grid/components/grid-item';
import {getUserId} from '@Libraries/user.library';
import {MODAL_IDS, openModal} from '@Components/modal-container-deprecated/modal-container';
import {updateUnlinkedMediaState} from '@Panels/user-media-panel/user-media-panel-reducer';
import type {GridStockImageItemStorage} from '@Components/base-grid/components/grid-stock-image-item';
import type {AIGenerateImageBackendVO} from '@Libraries/pmw-stock-media-library';
import {v4 as uuidv4} from 'uuid';
import {ImageItemSource} from '@Libraries/image-item.library';
import {isHeicImageFile, isSvgFile} from '@Utils/file.util';
import type {UserVideoVOResponse} from './user-video-library';
import {filterValidVideoFiles, isVideoFileValid} from './user-video-library';
import type {UserImageVOResponse} from './user-image-library';
import {filterValidImageFiles, isGifFile, isImageFileValid, isMultiFrameGIF} from './user-image-library';
import type {UserAudioVOResponse} from './user-audio-library';
import {filterValidAudioFiles, isAudioFileValid} from './user-audio-library';
import Emitter from '../services/event-emitter';
import {hideLoading, showLoading, updateLoadingProgress, updateLoadingText} from './loading-toast-library';
import {initUpload, progressHandler} from './upload-library';

let fileUploadQueue: Array<UploadingFilesData> = [];
let uploading = false;
// Whether to add all uploaded files together (send all files together to the onUpload function)
let addInBulk = false;
let currentUploadFileIndex = 0;

const UPLOAD_LOADING_KEY = 'uploadingUserMedia';

export const MULTI_MEDIA_SOURCE_GETTY = 'getty';
export const MULTI_MEDIA_SOURCE_UPLOAD = 'upload';
export const MULTI_MEDIA_SOURCE_DRAWING = 'drawing';
export const MULTI_MEDIA_SOURCE_EMAIL_UPLOAD = 'email_upload';
export const MULTI_MEDIA_UPLOAD_SOURCES = [MULTI_MEDIA_SOURCE_UPLOAD, MULTI_MEDIA_SOURCE_EMAIL_UPLOAD];

export const MAX_GETTY_IMAGES_PER_EMAIL = 6;
export const MAX_GETTY_VIDEOS_PER_EMAIL = 3;

export type UploadFileDataTypes = UserMediaVOResponse | TempImageData | Array<UserMediaVOResponse | TempImageData>;
export enum MultiMediaUploadSource {
  UPLOAD = MULTI_MEDIA_SOURCE_UPLOAD,
  EMAIL_UPLOAD = MULTI_MEDIA_SOURCE_EMAIL_UPLOAD,
}

export enum UserMediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE_BACKGROUND = 'IMAGE_BACKGROUND',
  DRAWING = 'SVG',
}

interface UploadImagesOpts {
  collectionId: string;
  uploadSrc: MultiMediaUploadSource;
  addInBulk: boolean;
  uploadVectors: boolean;
  mirror?: boolean;
  isUploadedFromImageTabInEmailMaker?: boolean;
  shouldLoadDataUrlImage?: boolean;
}

interface UploadVideosOpts {
  collectionId: string;
  uploadSrc: MultiMediaUploadSource;
  addInBulk: boolean;
  mirror?: boolean;
}

interface UploadingFilesData {
  type: UserMediaType;
  file: File;
  onUpload: (type: UserMediaType, data: UploadFileDataTypes, currentUploadIndex?: number, totalItemsToUpload?: number) => void;
  onAfterUpload: (userMediaType: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse) => void;
  onError?: (file: File, currentUploadIndex?: number, totalItemsToUpload?: number) => void;
  source: string;
  collectionId: string;
  mirror?: boolean;
  shouldLoadDataUrlImage?: boolean;
}

interface UserCollectionsVOResponse {
  i: string;
  n: string;
  e: string;
  t: string;
}

interface UploadFileOpts {
  collectionId: string;
  uploadSrc: MultiMediaUploadSource;
  addInBulk: boolean;
  isUploadedFromImageTabInEmailMaker: boolean;
  shouldLoadDataUrlImage: boolean;
  onAfterUpload?: (type: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse) => void;
}

export interface FileUploadMethods {
  onFileUpload: (type: UserMediaType, data: UploadFileDataTypes, currentUploadIndex?: number, totalUploadsCount?: number) => void;
  onUploadError: (file: File, currentUploadIndex?: number, totalItemsToUpload?: number) => void;
  onAfterUpload?: (type: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse) => void;
}

export interface UserCollectionsStorage {
  name: string;
  id: string;
  type: string;
}

export const attachMediaDrop = (
  elementSelector: string,
  onFileUpload: (type: UserMediaType, data: Array<UserMediaVOResponse | TempImageData> | UserMediaVOResponse | TempImageData) => void,
  onAfterUpload: (userMediaType: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse) => void,
  onUploadError: () => void
): void => {
  initUpload(
    null,
    uploadFilesOnMediaDrop.bind(this, onFileUpload, onAfterUpload, onUploadError),
    openErrorModal.bind({message: window.i18next.t('pmwjs_cannot_upload_error_msg')}),
    elementSelector,
    window.i18next.t('pmwjs_drag_drop_message')
  );
};

const uploadFilesOnMediaDrop = (
  onFileUpload: (
    type: UserMediaType,
    data: Array<UserMediaVOResponse | TempImageData> | UserMediaVOResponse | TempImageData,
    currentUploadIndex?: number,
    totalUploadsCount?: number
  ) => void,
  onAfterUpload: (userMediaType: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse) => void,
  onUploadError: (file: File, currentUploadIndex?: number, totalItemsToUpload?: number) => void,
  files: Array<File>
): void => {
  void uploadFiles({onFileUpload, onUploadError, onAfterUpload}, files, {shouldLoadDataUrlImage: true});
};

/**
 * Load and display all user collections
 * @param {string} collectionType
 * @returns {Promise<Array>}
 * @throws Exception
 */
export const getUserCollections = async (collectionType: UserMediaType): Promise<Array<UserCollectionsStorage>> => {
  const data = (await window.PMW.readLocal('collection/getUserCollections', {
    collectionType,
  })) as Array<UserCollectionsVOResponse>;

  const collections = [];
  for (const collectionData of data) {
    collections.push({
      id: collectionData.i,
      name: collectionData.n,
      type: collectionType,
    });
  }
  return collections;
};

/**
 * @param {string} contributorID
 * @param {string} collectionID
 * @param {string} collectionType
 * @return {Promise<*>}
 */
export const removeContributor = (contributorID: string, collectionID: string, collectionType: UserMediaType): void => {
  void window.PMW.writeLocal('collection/removeContributorToCollection', {
    contributorId: contributorID,
    collectionId: collectionID,
    collectionType,
  });
};

/**
 * Returns the contributors to a collection
 * @param {string} collectionID
 * @param {string} collectionType
 * @return {Promise<*>}
 */
export const getContributorsToCollection = async (collectionID: string, collectionType: UserMediaType): Promise<Array<UserCollectionsVOResponse>> => {
  return (await window.PMW.readLocal('collection/getContributorsToCollection', {
    collectionId: collectionID,
    collectionType,
  })) as Array<UserCollectionsVOResponse>;
};

/**
 * Removes a user image
 * @param {string} itemHashedFilename
 * @param {string} userId
 * @param {string} multimediaType
 * @return {Promise<*>}
 */
export const removeUserItem = async (itemHashedFilename: string, userId: number, multimediaType: UserMediaType): Promise<void> => {
  await window.PMW.writeLocal('user/removeUserMultiMediaItem', {
    userId,
    hash: itemHashedFilename,
    multiMediaType: multimediaType,
  });
};

/**
 * Filter media files
 */
const filterValidFiles = async (files: Array<File>): Promise<Array<File>> => {
  const filteredFiles = [];
  const invalidExtensions: Array<string> = [];
  let invalidFilesCount = 0;
  for (let i = 0; i < files.length; i++) {
    // eslint-disable-next-line no-await-in-loop
    if (isImageFileValid(files[i]) || isAudioFileValid(files[i]) || (await isVideoFileValid(files[i]))) {
      filteredFiles.push(files[i]);
    } else {
      invalidFilesCount += 1;
      const extension = files[i].name.split('.').pop();
      if (extension && !invalidExtensions.includes(`.${extension}`)) {
        invalidExtensions.push(`.${extension}`);
      }
    }
  }
  showMessageForInvalidFiles(invalidFilesCount, invalidExtensions);
  return filteredFiles;
};

/**
 * upload media files
 */
export const uploadFiles = async (fileUploadMethods: FileUploadMethods, files: Array<File>, options: Partial<UploadFileOpts>): Promise<void> => {
  const opts: UploadFileOpts = {
    collectionId: '',
    uploadSrc: MultiMediaUploadSource.UPLOAD,
    addInBulk: false,
    isUploadedFromImageTabInEmailMaker: false,
    shouldLoadDataUrlImage: false,
    ...options,
  };
  const filteredFiles = await filterValidFiles(files);
  addInBulk = opts.addInBulk;
  await updateFileUploadQueue(filteredFiles, fileUploadMethods.onFileUpload, fileUploadMethods.onUploadError, {
    collectionId: opts.collectionId,
    source: opts.uploadSrc,
    isUploadedFromImageTabInEmailMaker: opts.isUploadedFromImageTabInEmailMaker,
    shouldLoadDataUrlImage: opts.shouldLoadDataUrlImage,
    onAfterUpload: fileUploadMethods.onAfterUpload,
  });
  await startUpload();
};

/**
 * Filter and upload image files
 */
export const uploadImages = async (
  files: Array<File>,
  fileUploadMethods: FileUploadMethods,
  opts: UploadImagesOpts = {
    collectionId: '',
    uploadSrc: MultiMediaUploadSource.UPLOAD,
    addInBulk: false,
    uploadVectors: true,
    mirror: false,
    isUploadedFromImageTabInEmailMaker: false,
    shouldLoadDataUrlImage: false,
  }
): Promise<void> => {
  const filteredFiles = filterValidImageFiles(files, opts.uploadVectors);
  addInBulk = opts.addInBulk;
  await updateFileUploadQueue(
    filteredFiles,
    fileUploadMethods.onFileUpload,
    fileUploadMethods.onUploadError,
    {
      collectionId: opts.collectionId,
      source: opts.uploadSrc,
      mirror: opts.mirror,
      isUploadedFromImageTabInEmailMaker: opts.isUploadedFromImageTabInEmailMaker,
      shouldLoadDataUrlImage: opts.shouldLoadDataUrlImage,
      onAfterUpload: fileUploadMethods.onAfterUpload,
    },
    UserMediaType.IMAGE
  );
  await startUpload();
};

/**
 * Filter and upload image background files
 */
export const uploadBackground = async (
  files: Array<File>,
  fileUploadMethods: FileUploadMethods,
  opts = {collectionId: '', addInBulk: false, shouldLoadDataUrlImage: false}
): Promise<void> => {
  const filteredFiles = filterValidImageFiles(files, false);
  await updateFileUploadQueue(
    filteredFiles,
    fileUploadMethods.onFileUpload,
    fileUploadMethods.onUploadError,
    {collectionId: opts.collectionId, shouldLoadDataUrlImage: opts.shouldLoadDataUrlImage, onAfterUpload: fileUploadMethods.onAfterUpload},
    UserMediaType.IMAGE_BACKGROUND
  );
  await startUpload();
};

/**
 * Filter and upload video files
 */
export const uploadVideos = async (
  files: Array<File>,
  onFileUpload: (
    type: UserMediaType,
    data: Array<UserMediaVOResponse | TempImageData> | UserMediaVOResponse | TempImageData,
    currentUploadIndex?: number,
    totalUploadsCount?: number
  ) => void,
  onUploadError: (file: File, currentUploadIndex?: number, totalItemsToUpload?: number) => void,
  opts: UploadVideosOpts = {collectionId: '', uploadSrc: MultiMediaUploadSource.UPLOAD, addInBulk: false, mirror: false}
): Promise<void> => {
  const filteredFiles = await filterValidVideoFiles(files);
  addInBulk = opts.addInBulk;
  await updateFileUploadQueue(filteredFiles, onFileUpload, onUploadError, {collectionId: opts.collectionId, source: opts.uploadSrc, mirror: opts.mirror}, UserMediaType.VIDEO);
  await startUpload();
};
/**
 * Filter and upload audio files
 */
export const uploadAudios = async (
  files: Array<File>,
  onFileUpload: (type: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse, currentUploadIndex?: number, totalUploadsCount?: number) => void,
  onUploadError: (file: File, currentUploadIndex?: number, totalItemsToUpload?: number) => void,
  opts = {collectionId: '', uploadSrc: MultiMediaUploadSource.UPLOAD}
): Promise<void> => {
  const filteredFiles = filterValidAudioFiles(files);
  await updateFileUploadQueue(
    filteredFiles,
    onFileUpload as (type: UserMediaType, data: UploadFileDataTypes) => void,
    onUploadError,
    {collectionId: opts.collectionId, source: opts.uploadSrc},
    UserMediaType.AUDIO
  );
  await startUpload();
};

const getFileType = async (file: File, uploadSource?: MultiMediaUploadSource, isUploadedFromImageTabInEmailMaker: boolean = false): Promise<UserMediaType> => {
  if (isGifFile(file)) {
    if (await isMultiFrameGIF(file)) {
      if (uploadSource === MultiMediaUploadSource.EMAIL_UPLOAD && isUploadedFromImageTabInEmailMaker) {
        return UserMediaType.IMAGE;
      }
      return UserMediaType.VIDEO;
    }
    return UserMediaType.IMAGE;
  }
  if (isFiletypeVideo(file.type)) {
    return UserMediaType.VIDEO;
  }
  if (isFiletypeImage(file.type)) {
    return UserMediaType.IMAGE;
  }
  if (isAudioFileValid(file)) {
    return UserMediaType.AUDIO;
  }
  throw new Error(`Invalid file type: ${file.type} , Media type: ${file.type}`);
};

/**
 * Updates the file upload queue with new files
 * @param {Array} filteredFiles
 * @param {string} onFileUpload callback to one each file uploads
 * @param opts
 * @param type
 * @param onUploadError
 */
const updateFileUploadQueue = async (
  filteredFiles: Array<File>,
  onFileUpload: (
    type: UserMediaType,
    data: Array<UserMediaVOResponse | TempImageData> | UserMediaVOResponse | TempImageData,
    currentUploadIndex?: number,
    totalUploadsCount?: number
  ) => void,
  onUploadError: (file: File, currentUploadIndex?: number, totalItemsToUpload?: number) => void,
  opts: {
    collectionId: string;
    source?: MultiMediaUploadSource;
    mirror?: boolean;
    isUploadedFromImageTabInEmailMaker?: boolean;
    shouldLoadDataUrlImage?: boolean;
    onAfterUpload?: (type: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse) => void;
  },
  type?: UserMediaType
): Promise<void> => {
  if (filteredFiles.length > 0) {
    const queueData = [];
    for (let i = 0; i < filteredFiles.length; i++) {
      const fileType = type ?? (await getFileType(filteredFiles[i], opts.source, opts.isUploadedFromImageTabInEmailMaker));
      queueData.push({
        type: fileType,
        onUpload: onFileUpload,
        onAfterUpload: opts.onAfterUpload ? opts.onAfterUpload : (userMediaType: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse): void => {},
        onError: onUploadError,
        collectionId: opts.collectionId,
        file: filteredFiles[i],
        source: opts.source ?? '',
        mirror: opts.mirror !== undefined && opts.mirror,
        shouldLoadDataUrlImage: opts.shouldLoadDataUrlImage,
      });
      Emitter.emit('userMedia:upload:start', {type: fileType, file: filteredFiles[i], collectionId: opts.collectionId, mirror: opts.mirror});
    }

    fileUploadQueue.push(...queueData);
    updateLoadingText(UPLOAD_LOADING_KEY, getLoadingText());
  }
};

/**
 * Starts uploading the files in the queue and makes sure only one instants of this function is running at a time
 * @return {Promise<void>}
 */
const startUpload = async (): Promise<void> => {
  if (!onStartUploads()) {
    return;
  }
  const filesDataToUploadInBulk: Array<UserMediaVOResponse | TempImageData> = [];

  for (let i = 0; i < fileUploadQueue.length; i++) {
    const uploadData = fileUploadQueue[i];

    currentUploadFileIndex = i;
    updateLoadingText(UPLOAD_LOADING_KEY, getLoadingText());
    updateLoadingProgress(UPLOAD_LOADING_KEY, 0);

    try {
      const fd = new FormData();
      fd.append('Filedata', uploadData.file);
      fd.append('type', uploadData.type);
      fd.append('mirror', uploadData.mirror !== undefined && uploadData.mirror ? 'true' : 'false');
      let data: UploadFileDataTypes;
      if (
        (uploadData.type === UserMediaType.IMAGE || uploadData.type === UserMediaType.IMAGE_BACKGROUND) &&
        uploadData.shouldLoadDataUrlImage &&
        !isSvgFile(uploadData.file) &&
        !isHeicImageFile(uploadData.file)
      ) {
        // eslint-disable-next-line no-await-in-loop
        const dataurl = await imageFileToDataUrl(uploadData.file);
        if (dataurl) {
          const tempUploadingImageUID = uuidv4();
          fd.append('tempUploadingImageUID', tempUploadingImageUID);

          data = {
            uid: tempUploadingImageUID,
            dataUrl: dataurl,
            type: ElementDataType.IMAGE,
            source: uploadData.type === UserMediaType.IMAGE_BACKGROUND ? ImageItemSource.BACKGROUND_UPLOAD : ImageItemSource.UPLOAD,
          };

          void uploadFile(fd, UPLOAD_LOADING_KEY, uploadData.type, uploadData.source).then((response: UserMediaVOResponse) => {
            void onFileUploaded(response, uploadData).then(() => {
              uploadData.onAfterUpload(uploadData.type, response);
            });
          });
        } else {
          // eslint-disable-next-line no-await-in-loop
          data = (await uploadFile(fd, UPLOAD_LOADING_KEY, uploadData.type, uploadData.source)) as UserMediaVOResponse;
          // eslint-disable-next-line no-await-in-loop
          await onFileUploaded(data, uploadData);
        }
      } else {
        // eslint-disable-next-line no-await-in-loop
        data = (await uploadFile(fd, UPLOAD_LOADING_KEY, uploadData.type, uploadData.source)) as UserMediaVOResponse;
        // eslint-disable-next-line no-await-in-loop
        await onFileUploaded(data, uploadData);
      }
      if (addInBulk) {
        filesDataToUploadInBulk.push(data);
      } else {
        uploadData.onUpload(uploadData.type, data, i + 1, fileUploadQueue.length);
      }
    } catch (e: any) {
      console.error(e);
      const errorMessageText = typeof e.message === 'string' && e.message.length ? (e.message as string) : window.i18next.t('pmwjs_upload_error');

      if (uploadData.onError) {
        uploadData.onError(uploadData.file, i + 1, fileUploadQueue.length);
      }

      Emitter.emit('userMedia:upload:fail', {type: uploadData.type, file: uploadData.file, collectionId: uploadData.collectionId});
      showMessageGrowl({
        text: errorMessageText,
        type: GROWL_TYPE.DANGER,
      });
    }
  }
  if (filesDataToUploadInBulk.length) {
    fileUploadQueue[0].onUpload(fileUploadQueue[0].type, filesDataToUploadInBulk);
  }

  onEndUploads();
};

/**
 * Move item to collection if collection is not null
 * @param {string} type one of the MEDIA_TYPE_* constants
 * @param {string} itemHashId
 * @param {string|null} collectionId
 */
const moveItemToCollection = async (type: UserMediaType, itemHashId: string, collectionId: string): Promise<void> => {
  if (collectionId) {
    await window.PMW.writeLocal('collection/moveUserItem', {
      h: itemHashId,
      ch: collectionId,
      collectionType: type,
    });
  }
};

const getLoadingText = (): string => {
  return `${window.i18next.t('pmwjs_uploading')} (${currentUploadFileIndex + 1} of ${fileUploadQueue.length})`;
};

const onStartUploads = (): boolean => {
  if (uploading) {
    return false;
  }
  uploading = true;
  currentUploadFileIndex = 0;
  showLoading(UPLOAD_LOADING_KEY, {
    text: getLoadingText(),
    priority: 10,
    progress: 0,
  });
  return true;
};

const onEndUploads = (): void => {
  uploading = false;
  fileUploadQueue = [];
  hideLoading(UPLOAD_LOADING_KEY);
};

/**
 * Uploads the file to our server
 * @param {any} fd
 * @param {string} loadingKey loading key for handling progress bar
 * @param {string} type one of the MEDIA_TYPE_* constants
 * @param {string} src one of the MULTI_MEDIA_SOURCE_* constants
 * @return {Promise<*>}
 */
export const uploadFile = async (fd: FormData, loadingKey: string, type: UserMediaType, src: string): Promise<any> => {
  if (type === UserMediaType.DRAWING) {
    window.PMW.gtm.trackGA4CustomEvent('EndDrawing');
  }

  return (await window.PMW.pollingAjaxCallAsync(getUploadFileAjaxFunction.bind(null, fd, loadingKey, type, src), checkUploadStatus.bind(null, type))) as Promise<any>;
};

const onFileUploaded = async (data: UserMediaVOResponse, uploadData: UploadingFilesData): Promise<void> => {
  await moveItemToCollection(uploadData.type, data.filename, uploadData.collectionId);
  Emitter.emit('userMedia:upload:success', {type: uploadData.type, file: uploadData.file, collectionId: uploadData.collectionId, data});
};

export const checkUploadStatus = (type: UserMediaType, itemHashedId: string): any => {
  return window.PMW.readLocal(getCheckUploadStatusUriForMediaType(type), {
    hashedId: itemHashedId,
  });
};

const getUploadFileAjaxFunction = (fd: FormData, loadingKey: string, type: UserMediaType, src: string) => {
  return window.PMW.writeLocal(getUploadUriForMediaType(type, src), fd, 'json', {
    timeout: 7200000,
    xhr: progressHandler.bind(null, loadingKey),
    cache: false,
    processData: false, // Don't process the files
    contentType: false, // Set content type to false as jQuery will tell the server its a query string request
  });
};

/**
 * Returns server side controller function for type of uploaded file
 * @param {string} type one of the MEDIA_TYPE_* constants
 * @param {string} src one of the MULTI_MEDIA_SOURCE_* constants
 * @return {string}
 */
export const getUploadUriForMediaType = (type: UserMediaType, src: string): string => {
  let uriFunction;

  switch (src) {
    case MULTI_MEDIA_SOURCE_EMAIL_UPLOAD:
      uriFunction = 'emailUpload';
      break;

    case MULTI_MEDIA_SOURCE_DRAWING:
      uriFunction = 'uploadDrawingImage';
      break;

    case MULTI_MEDIA_SOURCE_UPLOAD:
    default:
      uriFunction = 'upload';
  }

  switch (type) {
    case UserMediaType.IMAGE:
    case UserMediaType.IMAGE_BACKGROUND:
    case UserMediaType.DRAWING:
      return `posterimage/${uriFunction}`;
    case UserMediaType.VIDEO:
      return `postervideo/${uriFunction}`;
    case UserMediaType.AUDIO:
      return `posteraudio/${uriFunction}`;
    default:
      throw new Error(`Unhandled media type: ${type}`);
  }
};
/**
 * Returns server side controller function for type of uploaded file
 * @param {string} type one of the MEDIA_TYPE_* constants
 * @return {string}
 */
const getCheckUploadStatusUriForMediaType = (type: UserMediaType): string => {
  switch (type) {
    case UserMediaType.VIDEO:
      return 'postervideo/getUserVideoStatus';
    case UserMediaType.AUDIO:
      return 'posteraudio/getUserAudioStatus';
    case UserMediaType.DRAWING:
      return 'posterimage/getUserDrawingStatus';
    case UserMediaType.IMAGE_BACKGROUND:
    case UserMediaType.IMAGE:
      return 'posterimage/getUserImageStatus';

    default:
      throw new Error(`Unhandled media type: ${type}`);
  }
};

const showMessageForInvalidFiles = (invalidFilesCount: number, invalidExtensions: Array<string>): void => {
  if (!invalidExtensions.length) {
    return;
  }

  if (invalidExtensions.length === 1) {
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_some_invalid_media_file', {
        extension: invalidExtensions,
      }),
    });
  } else {
    const last = invalidExtensions.pop();
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_some_invalid_media_files', {
        numfiles: invalidFilesCount,
        extensions: `${invalidExtensions.join(', ')} & ${last ?? ''}`,
      }),
    });
  }
};

export const getTextForMyMultiMedia = (multiMediaType: UserMediaType): string => {
  switch (multiMediaType) {
    case UserMediaType.IMAGE_BACKGROUND:
      return window.i18next.t('pmwjs_my_backgrounds');

    case UserMediaType.VIDEO:
      return window.i18next.t('pmwjs_my_videos');

    case UserMediaType.AUDIO:
      return window.i18next.t('pmwjs_my_audios');

    case UserMediaType.DRAWING:
      return window.i18next.t('pmwjs_my_drawings');

    case UserMediaType.IMAGE:
    default:
      return window.i18next.t('pmwjs_my_photos');
  }
};

export type GridUserMediaItemStorage = GridUserImageItemStorage | GridUserVideoItemStorage | GridUserAudioItemStorage | GridStockImageItemStorage;
export type UserMediaVOResponse = UserImageVOResponse | UserVideoVOResponse | UserAudioVOResponse | AIGenerateImageBackendVO;
export type MediaData = ImageData | VideoData | AudioData;

export const getMediaStorageForbackendData = (itemData: UserMediaVOResponse, type: UserMediaType): GridUserMediaItemStorage => {
  switch (type) {
    case UserMediaType.AUDIO:
      return getGridUserAudioItemStorageForUserAudioVO(itemData as UserAudioVOResponse);
    case UserMediaType.VIDEO:
      return getGridUserVideoItemStorageForUserVideoVO(itemData as UserVideoVOResponse);
    case UserMediaType.IMAGE:
    case UserMediaType.IMAGE_BACKGROUND:
      return getGridUserImageItemStorageForUserImageVO(itemData as UserImageVOResponse);
    default:
      throw new Error(`Invalid media type: ${type}`);
  }
};

export const gridUserMediaItemStorageToMediaData = (gridItem: GridItemStorage): MediaData => {
  switch (gridItem.type) {
    case GRID_ITEM_TYPE.USER_IMAGE:
      return getImageDataFromGridUserImageItemStorage(gridItem as GridUserImageItemStorage);
    case GRID_ITEM_TYPE.USER_VIDEO:
      return getVideoDataFromGridUserVideoItemStorage(gridItem as GridUserVideoItemStorage);
    case GRID_ITEM_TYPE.USER_AUDIO:
      return getAudioDataFromGridUserAudioItemStorage(gridItem as GridUserAudioItemStorage);
    default:
      throw new Error(`Invalid media type: ${gridItem.type}`);
  }
};

export const linkMediaItems = async (): Promise<void> => {
  const unlinkedImages: Array<string> = [];
  const unlinkedVideos: Array<string> = [];
  const unlinkedAudios: Array<string> = [];

  window.PMW.redux.store.getState().mediaUploads.unlinkedMedia.forEach((item) => {
    if (item.type === GRID_ITEM_TYPE.USER_IMAGE) {
      unlinkedImages.push(item.id);
    }
    if (item.type === GRID_ITEM_TYPE.USER_VIDEO) {
      unlinkedVideos.push(item.id);
    }
    if (item.type === GRID_ITEM_TYPE.USER_AUDIO) {
      unlinkedAudios.push(item.id);
    }
  });

  if (unlinkedImages.length) {
    try {
      await window.PMW.writeLocal('posterimage/linkUserImages', {
        uids: unlinkedImages,
        userId: getUserId(),
      });
    } catch (e) {
      console.error(e);
      openModal(MODAL_IDS.ERROR_MODAL_ID, {
        message: window.i18next.t('pmwjs_general_ajax_error'),
      });
    }
  }
  if (unlinkedVideos.length) {
    try {
      await window.PMW.writeLocal('postervideo/linkUserVideos', {
        uids: unlinkedVideos,
        userId: getUserId(),
      });
    } catch (e) {
      console.error(e);
      openModal(MODAL_IDS.ERROR_MODAL_ID, {
        message: window.i18next.t('pmwjs_general_ajax_error'),
      });
    }
  }
  if (unlinkedAudios.length) {
    try {
      await window.PMW.writeLocal('posteraudio/linkIds', {
        uids: unlinkedAudios,
        userId: getUserId(),
      });
    } catch (e) {
      console.error(e);
      openModal(MODAL_IDS.ERROR_MODAL_ID, {
        message: window.i18next.t('pmwjs_general_ajax_error'),
      });
    }
  }
  window.PMW.redux.store.dispatch(updateUnlinkedMediaState([]));
};

export const userMediaVOToElementData = (type: UserMediaType, data: UserMediaVOResponse): ElementData => {
  switch (type) {
    case UserMediaType.AUDIO:
      const audioData = data as UserAudioVOResponse;
      return {
        filename: audioData.filename,
        extension: audioData.ext,
        duration: audioData.duration,
        src: audioData.src,
        name: audioData.name,
        type: ElementDataType.AUDIO,
      };
    case UserMediaType.VIDEO:
      const videoData = data as UserVideoVOResponse;
      return {
        hashedFilename: videoData.filename,
        extension: videoData.ext,
        source: videoData.src,
        width: videoData.width,
        height: videoData.height,
        duration: videoData.duration,
        frameRate: videoData.frameRate,
        hasTransparency: videoData.hasTransparency,
        type: ElementDataType.VIDEO,
      };
    case UserMediaType.IMAGE:
      const imageData = data as UserImageVOResponse;
      return {
        hashedFilename: imageData.filename,
        extension: imageData.ext,
        hasTransparency: imageData.hasTransparency,
        uploaderId: Number(imageData.uploaderId),
        width: imageData.width,
        source: imageData.src,
        height: imageData.height,
        type: ElementDataType.IMAGE,
      };
    default:
      throw new Error('cannot convert this type to user media vo response');
  }
};
