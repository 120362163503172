import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type { MaskingShapeObject} from '@PosterWhiteboard/classes/masking/masking-shape.class';
import {MaskingShape} from '@PosterWhiteboard/classes/masking/masking-shape.class';
import {MaskingType} from '@PosterWhiteboard/classes/masking/masking.class';
import type { MaskingTextObject} from '@PosterWhiteboard/classes/masking/masking-text.class';
import {MaskingText} from '@PosterWhiteboard/classes/masking/masking-text.class';
import type { MaskingFreehandObject} from '@PosterWhiteboard/classes/masking/masking-freehand.class';
import {MaskingFreehand} from '@PosterWhiteboard/classes/masking/masking-freehand.class';
import type {DeepPartial} from '@/global';

export type MaskingItemType = MaskingShape | MaskingText | MaskingFreehand;
export type MaskingItemObject = MaskingShapeObject | MaskingTextObject | MaskingFreehandObject;

export interface ItemMaskingObject {
  maskingItem?: MaskingItemObject;
}

export class ItemMasking extends CommonMethods {
  public maskingItem?: MaskingItemType;

  public copyVals(obj: DeepPartial<ItemMaskingObject> = {}): void {
    if (obj.maskingItem?.type && obj.maskingItem.type !== this.maskingItem?.type) {
      this.maskingItem = getMaskingItemForType(obj.maskingItem.type);
    }
    if ('maskingItem' in obj && obj.maskingItem === undefined) {
      this.maskingItem = undefined;
    }

    this.maskingItem?.copyVals(obj.maskingItem);
  }

  public async applyMaskingToImage(img: HTMLImageElement): Promise<HTMLImageElement> {
    if (!this.maskingItem) {
      return img;
    }
    return this.maskingItem.applyMaskingToImage(img);
  }

  public toObject(): ItemMaskingObject {
    return {
      maskingItem: this.maskingItem?.toObject(),
    };
  }
}

const getMaskingItemForType = (type: MaskingType): MaskingItemType => {
  switch (type) {
    case MaskingType.SHAPE:
      return new MaskingShape();
    case MaskingType.TEXT:
      return new MaskingText();
    case MaskingType.FREEHAND:
      return new MaskingFreehand();
    default:
      throw new Error(`Unhandled maskingtype ${type}`);
  }
};
