import {
  BusinessProfileType,
  BusinessHoursDay,
  BusinessProfileState,
  BusinessProfilesWizardMode,
  BusinessProfileWizardStep,
} from '@Components/business-profiles-wizard/business-profiles-wizard.types';
import type {
  BusinessAddress,
  BusinessHours,
  BusinessHoursMap,
  BusinessHoursTimeParts,
  BusinessProfile,
  BusinessProfileCategory,
  BusinessProfilesWizardContainerQuery,
  BusinessProfilesWizardReducerState,
} from '@Components/business-profiles-wizard/business-profiles-wizard.types';
import {getUserHashedId} from '@Libraries/user.library';
import {initSocialMediaProfileLinks} from '@Components/social-media-profile-links/social-media-profile-links-library';
import {LoadingStates} from '@Utils/loading.util';
import {showMessageGrowl} from '@Components/message-growl';
import {defaultCountries} from 'react-international-phone';
import {hasInvalidCharacters, isValidURL} from '@Utils/url.util';
import {repoURL} from '@Libraries/s3-library';
import {getReadBucket} from '@Utils/s3.util';

export const initialState = (): BusinessProfilesWizardReducerState => {
  return {
    businessProfile: getInitialBusinessProfile(),
    currentStep: BusinessProfileWizardStep.BASIC_INFO,
    isSavingChanges: false,
    loadingState: LoadingStates.NOT_LOADED,
    mode: BusinessProfilesWizardMode.NONE,
    googleMapAPIKey: '',
    isFieldsErrorState: false,
    isImportingProfile: false,
    isImportedProfile: false,
    activityLogs: [],
    allCategories: {},
  };
};

export const getInitialBusinessProfile = (): BusinessProfile => {
  return {
    idBusinessProfile: -1,
    hashedId: '',
    name: '',
    types: {},
    categories: {},
    socialMediaProfileLinks: initSocialMediaProfileLinks(),
    address: {idAddress: -1},
    displayableBusinessHours: '',
    idBrand: '',
    state: BusinessProfileState.USER_CREATED_UNSAVED,
    businessHours: undefined,
    contactEmail: '',
    autoFilledEmail: '',
  };
};

export const getNextButtonText = (currentStep: BusinessProfileWizardStep): string => {
  if (currentStep === BusinessProfileWizardStep.BRAND_KIT) {
    return window.i18next.t('pmwjs_save_changes');
  }
  return window.i18next.t('pmwjs_next');
};

export const getNextButtonIcon = (currentStep: BusinessProfileWizardStep): string => {
  if (currentStep === BusinessProfileWizardStep.BRAND_KIT) {
    return '';
  }
  return 'icon-arrow-right';
};

export const getBackButtonText = (currentStep: BusinessProfileWizardStep): string => {
  if (currentStep === BusinessProfileWizardStep.BASIC_INFO) {
    return window.i18next.t('pmwjs_cancel');
  }
  return window.i18next.t('pmwjs_back');
};

export const getBackButtonIcon = (currentStep: BusinessProfileWizardStep): string => {
  if (currentStep === BusinessProfileWizardStep.BASIC_INFO) {
    return '';
  }
  return 'icon-arrow-left';
};

export const getDisplayableTimeFormat = (timeParts: BusinessHoursTimeParts): string => {
  let hourValue: number;
  const timePartsHour = Number(timeParts.hour);
  const timePartsMinutes = Number(timeParts.minutes);
  if (timePartsHour === 0) {
    hourValue = timePartsHour + 12;
  } else if (timePartsHour > 12) {
    hourValue = timePartsHour - 12;
  } else {
    hourValue = timePartsHour;
  }

  const hours = Number(hourValue) === 0 ? `${hourValue}0` : `${hourValue}`;
  const minutes = timePartsMinutes === 0 ? `${timePartsMinutes}0` : `${timePartsMinutes}`;
  const meridiem = timePartsHour >= 12 ? 'pm' : 'am';

  return `${hours}:${minutes}${meridiem}`;
};

export const getDefaultDaysShortHandValues = (): string[] => {
  return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
};

export const getDefaultDaysShortDisplayValues = (): string[] => {
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
};

export const getDefaultDaysLonghandValues = (): string[] => {
  return [
    window.i18next.t('pmwjs_calendar_sunday'),
    window.i18next.t('pmwjs_calendar_monday'),
    window.i18next.t('pmwjs_calendar_tuesday'),
    window.i18next.t('pmwjs_calendar_wednesday'),
    window.i18next.t('pmwjs_calendar_thursday'),
    window.i18next.t('pmwjs_calendar_friday'),
    window.i18next.t('pmwjs_calendar_saturday'),
  ];
};

export const onClickImportFromGoogleCTA = (): void => {
  openOAuthWindow('mybusiness/googleBusinessProfileOAuth');
};

export const openOAuthWindow = (oAuthURI: string): void => {
  window.open(window.PMW.util.site_url(oAuthURI), '_blank');
};

export const addGoogleBusinessProfileImportedBroadcastChannel = (onSuccessMessageReceived: (eventData: MessageEvent) => void): BroadcastChannel | undefined => {
  const profileImportBroadcastName = getProfileImportedBroadcastName();

  if (!profileImportBroadcastName) {
    return undefined;
  }

  const googleBusinessProfileImportedBroadcastChannel = new BroadcastChannel(profileImportBroadcastName);
  googleBusinessProfileImportedBroadcastChannel.addEventListener('message', onSuccessMessageReceived);

  return googleBusinessProfileImportedBroadcastChannel;
};

export const removeGoogleBusinessProfileImportedBroadcastChannel = (googleBusinessProfileImportedBroadcastChannel?: BroadcastChannel): void => {
  if (!googleBusinessProfileImportedBroadcastChannel) {
    return;
  }

  googleBusinessProfileImportedBroadcastChannel.close();
};

export const getProfileImportedBroadcastName = (): string => {
  const hashedUserId = getUserHashedId();
  if (hashedUserId) {
    return `gbp_${hashedUserId}`;
  }

  return '';
};

export const getGoogleImportedBusinessProfiles = async (): Promise<BusinessProfile[]> => {
  return (await window.PMW.readLocal('mybusiness/getGoogleImportedBusinessProfiles')) as Promise<BusinessProfile[]>;
};

export const joinBusinessProfileDisplayableAddress = (
  businessAddressStreetAddress: string[],
  businessAddressSector: string,
  businessAddressCity: string,
  businessAddressPostalCode: string,
  businessAddressCountry: string
): string => {
  const formattedStreetAddress = businessAddressStreetAddress
    .filter((line) => {
      return line.trim() !== '';
    })
    .join(', ');
  const parts = [formattedStreetAddress, businessAddressSector, businessAddressCity, businessAddressPostalCode, businessAddressCountry].filter((part) => {
    return part.trim() !== '';
  });
  return parts.join(', ');
};

export const convertToValue = (key: string): BusinessHoursDay => {
  return parseInt(key, 10) as BusinessHoursDay;
};

export const cloneBusinessHours = (businessHoursToClone?: BusinessHours): BusinessHours | undefined => {
  if (!businessHoursToClone) {
    return undefined;
  }

  const from = {hour: Number(businessHoursToClone.from.hour), minutes: Number(businessHoursToClone.from.minutes)};
  const to = {hour: Number(businessHoursToClone.to.hour), minutes: Number(businessHoursToClone.to.minutes)};
  return {from, to};
};

export const cloneBusinessHoursMap = (businessHoursMapToClone: BusinessHoursMap): BusinessHoursMap => {
  if (!businessHoursMapToClone) {
    return getLocalStoreBusinessHours();
  }

  const clonedBusinessHours: BusinessHoursMap = {};

  Object.keys(businessHoursMapToClone).forEach((key) => {
    const updatedKey = convertToValue(key);
    const updatedValue = businessHoursMapToClone[updatedKey];
    if (updatedValue) {
      clonedBusinessHours[updatedKey] = cloneBusinessHours(updatedValue);
    }
  });

  return clonedBusinessHours;
};

export const cloneTimeParts = (timePartsToClone: BusinessHoursTimeParts): BusinessHoursTimeParts => {
  return {hour: Number(timePartsToClone.hour), minutes: Number(timePartsToClone.minutes)};
};

export const getDefaultBusinessHours = (businessTypes: Partial<Record<BusinessProfileType, string>> | undefined, displayableBusinessHours: string): BusinessHoursMap => {
  if ((!displayableBusinessHours || displayableBusinessHours === '') && businessTypes) {
    if (businessTypes[BusinessProfileType.LOCAL_STORE]) {
      return getLocalStoreBusinessHours();
    }

    if (businessTypes[BusinessProfileType.SERVICE_BUSINESS]) {
      return getServiceBusinessHours();
    }

    if (businessTypes[BusinessProfileType.ONLINE_RETAIL]) {
      return getOnlineBusinessHours();
    }
  }
  return getLocalStoreBusinessHours();
};

export const getLocalStoreBusinessHours = (): BusinessHoursMap => {
  return {
    [BusinessHoursDay.SUNDAY]: {from: {hour: 10, minutes: 0}, to: {hour: 17, minutes: 0}},
    [BusinessHoursDay.MONDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.TUESDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.WEDNESDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.THURSDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.FRIDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.SATURDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
  };
};

export const getServiceBusinessHours = (): BusinessHoursMap => {
  return {
    [BusinessHoursDay.MONDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.TUESDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.WEDNESDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.THURSDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.FRIDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
    [BusinessHoursDay.SATURDAY]: {from: {hour: 9, minutes: 0}, to: {hour: 19, minutes: 0}},
  };
};

export const getOnlineBusinessHours = (): BusinessHoursMap => {
  return {
    [BusinessHoursDay.SUNDAY]: {from: {hour: 12, minutes: 0}, to: {hour: 0, minutes: 0}},
    [BusinessHoursDay.MONDAY]: {from: {hour: 12, minutes: 0}, to: {hour: 0, minutes: 0}},
    [BusinessHoursDay.TUESDAY]: {from: {hour: 12, minutes: 0}, to: {hour: 0, minutes: 0}},
    [BusinessHoursDay.WEDNESDAY]: {from: {hour: 12, minutes: 0}, to: {hour: 0, minutes: 0}},
    [BusinessHoursDay.THURSDAY]: {from: {hour: 12, minutes: 0}, to: {hour: 0, minutes: 0}},
    [BusinessHoursDay.FRIDAY]: {from: {hour: 12, minutes: 0}, to: {hour: 0, minutes: 0}},
    [BusinessHoursDay.SATURDAY]: {from: {hour: 12, minutes: 0}, to: {hour: 0, minutes: 0}},
  };
};

export const getDefaultFromBusinessHours = (businessTypes: Partial<Record<BusinessProfileType, string>> | undefined): BusinessHoursTimeParts => {
  if (businessTypes) {
    if (businessTypes[BusinessProfileType.LOCAL_STORE]) {
      return {hour: 9, minutes: 0};
    }

    if (businessTypes[BusinessProfileType.SERVICE_BUSINESS]) {
      return {hour: 9, minutes: 0};
    }

    if (businessTypes[BusinessProfileType.ONLINE_RETAIL]) {
      return {hour: 12, minutes: 0};
    }
  }

  return {hour: 9, minutes: 0};
};

export const getDefaultToBusinessHours = (businessTypes: Partial<Record<BusinessProfileType, string>> | undefined): BusinessHoursTimeParts => {
  if (businessTypes) {
    if (businessTypes[BusinessProfileType.LOCAL_STORE]) {
      return {hour: 19, minutes: 0};
    }

    if (businessTypes[BusinessProfileType.SERVICE_BUSINESS]) {
      return {hour: 19, minutes: 0};
    }

    if (businessTypes[BusinessProfileType.ONLINE_RETAIL]) {
      return {hour: 0, minutes: 0};
    }
  }

  return {hour: 9, minutes: 0};
};

export const getDisplayableBusinessHours = (currentBusinessHours?: BusinessHoursMap): string => {
  if (!currentBusinessHours) {
    return '';
  }

  const keys = Object.keys(currentBusinessHours);
  const values = Object.values(currentBusinessHours);

  if (keys.length === 0) {
    return 'None';
  }

  let displayableHours = keys.length === 1 ? '' : ` and ${keys.length - 1} more...`;

  if (keys.length > 0) {
    const index = parseInt(keys[0], 10);
    displayableHours = `${getDefaultDaysLonghandValues()[index]} (${getDisplayableTimeFormat(values[0].from)} to ${getDisplayableTimeFormat(values[0].to)}) ${displayableHours}`;
  }

  return displayableHours;
};

export const isLargeScreen = (containerQuery: BusinessProfilesWizardContainerQuery): boolean => {
  return !!(containerQuery.isRegularDesktopScreen || containerQuery.isLargeDesktopScreen);
};

export const isMobileScreen = (containerQuery: BusinessProfilesWizardContainerQuery): boolean => {
  return containerQuery.isMobileScreen as boolean;
};

export const isRegularScreen = (containerQuery: BusinessProfilesWizardContainerQuery): boolean => {
  return containerQuery.isRegularDesktopScreen as boolean;
};

export const isTabletScreen = (containerQuery: BusinessProfilesWizardContainerQuery): boolean => {
  return containerQuery.isTabletScreen as boolean;
};

export const isSmallScreen = (containerQuery: BusinessProfilesWizardContainerQuery): boolean => {
  return containerQuery.isMobileScreen || (containerQuery.isTabletScreen as boolean);
};

export const showImportSuccessMessage = (): void => {
  showMessageGrowl({text: window.i18next.t('pmwjs_profile_imported_successfully')});
};

export const getValidCountryDialCodeForGivenCountry = (country: string): string => {
  const allCountries = defaultCountries;
  const countryMap = new Map();

  allCountries.forEach(([name, code, dialCode]) => {
    countryMap.set(name.toLowerCase(), dialCode);
    countryMap.set(code, dialCode);
  });

  const normalizedCountryName = country.toLowerCase();
  return (countryMap.get(normalizedCountryName) as string) || '';
};

export const getAllCountryNames = (): string[] => {
  const allCountriesData = defaultCountries;
  const allCountries: string[] = [];

  allCountriesData.forEach(([name]) => {
    allCountries.push(name);
  });

  return allCountries;
};

export const getFormattedPhoneNumberWithDialCode = (phoneNumber: string, dialCode: string): string => {
  return `+${dialCode}${phoneNumber}`;
};

const doesPhoneNumberHaveDialCode = (phoneNumber: string): boolean => {
  return phoneNumber.startsWith('+');
};

export const getValidPhoneNumber = (businessProfile: BusinessProfile): string => {
  const {phoneNumber} = businessProfile;
  const countryCode = businessProfile.address?.country;

  if (!phoneNumber) {
    return '';
  }

  if (doesPhoneNumberHaveDialCode(phoneNumber)) {
    return phoneNumber;
  }

  if (phoneNumber.startsWith('0')) {
    if (countryCode) {
      const dialCode = getValidCountryDialCodeForGivenCountry(countryCode);
      return getFormattedPhoneNumberWithDialCode(phoneNumber.slice(1), dialCode);
    }
  }

  return phoneNumber;
};

export const areBusinessHoursIdentical = (businessHoursMap?: BusinessHoursMap): boolean => {
  if (!businessHoursMap) {
    return false;
  }

  const values = Object.values(businessHoursMap);
  if (values.length === 0) {
    return false;
  }

  const firstValue = values[0];
  return values.every((value) => {
    return (
      value.from.hour === firstValue.from.hour &&
      value.from.minutes === firstValue.from.minutes &&
      value.to.hour === firstValue.to.hour &&
      value.to.minutes === firstValue.to.minutes
    );
  });
};

export const getFilteredCategories = (input: string, allCategories: Record<string, BusinessProfileCategory>): Record<string, BusinessProfileCategory> => {
  const inputWords = input.toLowerCase().trim().split(' ');
  return Object.entries(allCategories).reduce(
    (acc, [key, category]) => {
      const categoryWords = category.displayName.toLowerCase().split(' ');
      const matchesInput = inputWords.every((inputWord) => {
        return categoryWords.some((categoryWord) => {
          return categoryWord.startsWith(inputWord);
        });
      });
      if (matchesInput) {
        acc[key] = category;
      }
      return acc;
    },
    {} as Record<string, BusinessProfileCategory>
  );
};

export const scrollToTop = (isFieldsErrorState: boolean): void => {
  if (!isFieldsErrorState) {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
};

export const getFormattedHoursMapForPreview = (businessHours?: BusinessHoursMap): Record<BusinessHoursDay, string> => {
  const formattedMap: Record<BusinessHoursDay, string> = {} as Record<BusinessHoursDay, string>;

  if (businessHours) {
    Object.values(BusinessHoursDay)
      .filter((key) => {
        return !isNaN(Number(key));
      })
      .forEach((day) => {
        formattedMap[day as BusinessHoursDay] = day in businessHours ? getFormattedHoursForPreview(businessHours[day as BusinessHoursDay] as BusinessHours) : '';
      });
  }

  return formattedMap;
};

const getFormattedHoursForPreview = (hours: BusinessHours): string => {
  return ` (${getDisplayableTimeFormat(hours.from)} to ${getDisplayableTimeFormat(hours.to)}) `;
};

export const getFormattedAddressForPreview = (businessProfileAddress: BusinessAddress): string | undefined => {
  const parts = [businessProfileAddress.sector ?? '', businessProfileAddress.city ?? '', businessProfileAddress.postalCode ?? '', businessProfileAddress.country ?? ''].filter(
    (part) => {
      return part.trim() !== '';
    }
  );
  return parts && parts.length > 0 ? parts.join(', ') : undefined;
};

export const trackBusinessProfileEvent = (eventName: string): void => {
  window.PMW.gtm.trackGA4CustomEvent(eventName);
};

export const isInvalidUrl = (url: string | undefined | null): boolean => {
  return url !== undefined && url !== null && url !== '' && (!isValidURL(url) || hasInvalidCharacters(url));
};

export const getCustomIconForGoogleUri = (): string => {
  return repoURL('assets/google-business-profile-logo-thumb-circle.png', getReadBucket());
};

export const getFormattedWebsiteUrl = (businessProfile: BusinessProfile | undefined): string | undefined => {
  if (businessProfile?.website) {
    if (!businessProfile.website.startsWith('https://')) {
      return `https://${businessProfile.website}`;
    }
    return businessProfile?.website;
  }
  return businessProfile?.website;
};

export const getCountryCodeForCountry = (countryToFind: string): string => {
  if (countryToFind === '') {
    return '';
  }

  const allCountries = defaultCountries;
  const COUNTRY_NAME_INDEX = 0;
  const COUNTRY_CODE_INDEX = 1;

  const countryCode = allCountries.find((country) => (country.length > 0 ? country[COUNTRY_CODE_INDEX].toLowerCase() === countryToFind.toLowerCase() : undefined));

  if (countryCode) {
    return countryCode[COUNTRY_CODE_INDEX];
  }

  const countryName = allCountries.find((country) => (country.length > 0 ? country[COUNTRY_NAME_INDEX].toLowerCase() === countryToFind.toLowerCase() : undefined));

  if (countryName) {
    return countryName[COUNTRY_CODE_INDEX];
  }

  return 'us';
};
