import type {PlatformMediaType, PostState, SocialMediaAccountId, SocialPost} from '@Components/social-media/post.vo';
import type {AccountState} from '@Components/social-media/account.vo';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import type {AccountResizeStatus, ResizeOptionsConfig} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import type {CaptionErrorTypes} from '@Components/social-media-wizard/social-media-wizard.types';
import type {PosterHashedId, RetryStatus} from '@Components/social-media/publish-params.types';
import type {Ref} from 'react';
import type {PopupType, PromptSuggestion} from '@Components/ai-text-prompt-box/ai-text-prompt-box.types';

export enum SocialPlatform {
  FACEBOOK = 'facebook',
  FACEBOOK_PAGE = 'fb_page',
  FACEBOOK_PROFILE = 'fb_profile',
  FACEBOOK_GROUP = 'fb_group',
  INSTAGRAM = 'ig-business',
  YOUTUBE = 'youtube-channel',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  LINKEDIN_PROFILE = 'linkedin-profile',
  LINKEDIN_PAGE = 'linkedin-page',
  TIKTOK = 'tiktok-profile',
  PINTEREST = 'pinterest',
  GOOGLE_BUSINESS_PROFILE = 'google-business-profile',
  THREADS = 'threads',
}

export const getReadableAccounts = (platform: SocialPlatform): string => {
  switch (platform) {
    case SocialPlatform.TIKTOK:
      return 'TikTok';
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PROFILE:
    case SocialPlatform.LINKEDIN_PAGE:
      return 'LinkedIn';
    case SocialPlatform.YOUTUBE:
      return 'YouTube';
    case SocialPlatform.FACEBOOK_GROUP:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK:
      return 'Facebook';
    case SocialPlatform.TWITTER:
      return 'X (formerly Twitter)';
    case SocialPlatform.INSTAGRAM:
      return 'Instagram';
    case SocialPlatform.PINTEREST:
      return 'Pinterest';
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return 'Google Business Profile';
    case SocialPlatform.THREADS:
      return 'Threads';
    default:
      return '';
  }
};

export const SocialUniquePlatforms: SocialPlatform[] = [
  SocialPlatform.FACEBOOK,
  SocialPlatform.INSTAGRAM,
  SocialPlatform.TWITTER,
  SocialPlatform.TIKTOK,
  SocialPlatform.LINKEDIN,
  SocialPlatform.YOUTUBE,
  SocialPlatform.PINTEREST,
  SocialPlatform.GOOGLE_BUSINESS_PROFILE,
  SocialPlatform.THREADS,
];

export type PostStateFilter = Record<PostState, boolean>;
export type AccountStateFilter = Record<AccountState, boolean>;
export type CaptionErrorByAccountIds = Record<SocialMediaAccountId, CaptionErrorTypes>;
export type TitleErrorByAccountIds = Record<SocialMediaAccountId, string | null>;

export enum PostSortingMetric {
  LAST_MODIFIED = 'last_modified',
  DATE_PUBLISHED = 'publish_on',
}

export enum AccountSortingMetric {
  DATE_CREATED = 'DATE_CREATED',
  BY_PLATFORM = 'BY_PLATFORM',
}

export enum SocialPostEvent {
  PUBLISHED = 'post:published',
  CREATED = 'post:created',
  DELETED = 'post:delete',
  RESET_GRID = 'post:delete_all',
  SCHEDULED = 'post:scheduled',
  UNSCHEDULED = 'post:unscheduled',
  ERROR = 'post:error',
  UPDATED = 'post:updated',
}

export const SocialPostCaptionOverwriteEvent = 'caption::overwrite';
export const SocialPostPlatformSelectionTransitionEvent = 'socialpost::platform-selection-transition';

export interface SocialPostPlatformSelectionTransitionEventDetail {
  accountId: number;
  platformSelection: PlatformMediaType;
}

export const socialGridContainerQuery: ContainerQueryObject = {
  xsmall: {
    maxWidth: 460,
  },
  small: {
    maxWidth: 700,
  },
  medium: {
    minWidth: 461,
    maxWidth: 884,
  },
  large: {
    minWidth: 885,
  },
  xlarge: {
    minWidth: 1020,
  },
  xxlarge: {
    minWidth: 1420,
  },
};

export interface SocialPostGridContainerQuery {
  xsmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  xxlarge?: boolean;
}

export interface ContainerQueryParams {
  containerQueryParamProps: SocialPostGridContainerQuery;
  containerRef: Ref<HTMLDivElement>;
  hasContainerQueryInitialized: boolean;
}

export interface PosterNeedsResizeInformation {
  needsResizing: PosterResizeRequiredStatus;
  reelNeedsResizing?: PosterResizeRequiredStatus;
  notSupportedCustomMessage?: string;
  storyNeedsResizing?: PosterResizeRequiredStatus;
}

export interface AccountNeedsResizeMapForDesigns {
  [accountId: number]: PosterResizeInformationHashmap;
}

export type PosterResizeInformationHashmap = Record<PosterHashedId, PosterNeedsResizeInformation>;

export type PublishableUrlForDesign = Record<PosterHashedId, string>;

export interface AccountPublishableUrlsForMediaType {
  [accountId: number]: Record<PlatformMediaType, PublishableUrlForDesign>;
}

export interface PublishableUrlUpdatePayload {
  accountId: number;
  posterId: PosterHashedId;
  platformMediaType: PlatformMediaType;
  graphicUrl: string;
}

export interface RetryStatusUpdatePayload {
  accountId: number;
  platformMediaType: PlatformMediaType;
}

export type PosterResizeInformationHashMap = Record<PosterHashedId, PosterNeedsResizeInformation>;

export interface PublishedUrlUpdatePayload {
  accountId: number;
  mediaType: PlatformMediaType;
  url: string;
}

export type AccountResizeStatusForDesign = Record<PosterHashedId, AccountResizeStatus>;

export interface AccountResizeStatusForMediaType {
  [accountId: number]: Record<PlatformMediaType, AccountResizeStatusForDesign>;
}

export type DesignHasDefaultResizedMap = Record<PosterHashedId, boolean>;

export interface DesignsHaveDefaultResizedForAccountMap {
  [accountId: number]: Record<PlatformMediaType, DesignHasDefaultResizedMap>;
}

export interface PublishedAccountUrlMap {
  [accountId: number]: string;
}

export enum PosterResizeRequiredStatus {
  RESIZE_NEEDED = 'RESIZE_NEEDED',
  BACKEND_OPTIMIZATION_NEEDED = 'BACKEND_OPTIMIZATION_NEEDED',
  RESIZE_NOT_NEEDED = 'RESIZE_NOT_NEEDED',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export type PublishedUrlByMediaType = Record<PlatformMediaType, string | null>;

export interface PublishedUrlMapForAccount {
  [accountId: number]: PublishedUrlByMediaType;
}

export type RetryStatusForMediaType = Record<PlatformMediaType, RetryStatus | null>;

export interface SocialMediaResizeAjaxParams {
  posterId: string;
  resizeType?: ResizeOptionsConfig;
  socialMediaPlatform: SocialPlatform;
  socialAccountId: SocialMediaAccountId;
  idPost: string;
  platformMediaType: PlatformMediaType;
  isVideo: boolean;
  fillColor?: string;
  isPreview?: boolean;
}

export interface SocialMediaResizeResponse {
  s3FilePath?: string;
  videoS3FileName?: string;
}

export type MultiGraphicDesignSelectorModeType = MultiGraphicDesignSelectorModes.Select | MultiGraphicDesignSelectorModes.Resize;

export enum MultiGraphicDesignSelectorModes {
  Select = 'select',
  Resize = 'resize',
}

export interface DesignIsVideoInformation {
  designIsVideo: boolean;
  designName: string;
}

export interface MultipleDesignMetaForPublishing {
  designsIsVideo: boolean[];
  designsNames: string[];
  post: SocialPost;
  accountNeedsResizeMap?: object;
  accountNeedsResizeMapForDesigns?: object;
}

export interface RefreshAccountResponse {
  url?: string;
}

export interface UserHasSeenResponse {
  hasSeen: number;
}

export enum SocialAccountConnectionResult {
  ACCOUNT_ADDED_SUCCESS = 'account-added-success',
  ACCOUNT_CONNECTION_ERROR = 'account-connection-error',
  USER_DENIED_CONNECTION = 'user-denied-connection',
  NO_BUSINESS_PROFILES = 'no-business-profiles',
  NOTHING_HAPPENED = 'nothing-happened',
}

export interface SocialAccountConnectionResponse {
  socialMediaPlatform?: SocialPlatform;
  socialAccountConnectionResult: SocialAccountConnectionResult;
  userHashedId?: string;
  hideExistingModal?: boolean;
}

export const HAS_SEEN_SOCIAL_MEDIA_POPUP = 'pmwsocialmediaintromultiposting';

export interface HasSeenReponse {
  [PopupType.INTRO]: boolean;
  [PopupType.REMINDER]: boolean;
}

export interface InAutoCaptionModeType {
  accountId: number | null;
}

export interface AutoCaptionReduxStateType {
  inAutoCaptionMode: InAutoCaptionModeType;
  hasSeenIntroPopup: boolean;
  hasSeenReminderPopup: boolean;
}

export const SMP_AI_CAPTION_PROMPT_SUGGESTIONS: PromptSuggestion[] = [
  {title: 'pmwjs_online_invite', subtitle: 'pmwjs_to_my_first_book_launch'},
  {title: 'pmwjs_book_signing', subtitle: 'pmwjs_announcement_for_my_readers'},
  {title: 'pmwjs_fight_for_animal_rights', subtitle: 'pmwjs_with_our_local_shelter'},
  {title: 'pmwjs_waste_no_water', subtitle: 'pmwjs_campaign_announcement'},
  {title: 'pmwjs_community_cleanup_drive', subtitle: 'pmwjs_hosted_by_our_small_business'},
  {title: 'pmwjs_recycle_for_the_planet', subtitle: 'pmwjs_announcement_for_our_community'},
  {title: 'pmwjs_save_the_date', subtitle: 'pmwjs_for_our_engagement'},
  {title: 'pmwjs_baby_announcement', subtitle: 'pmwjs_for_our_family'},
  {title: 'pmwjs_in_loving_memory', subtitle: 'pmwjs_of_a_loved_one'},
  {title: 'pmwjs_invitation', subtitle: 'pmwjs_to_our_wedding'},
  {title: 'pmwjs_volunteer_program', subtitle: 'pmwjs_for_our_charity_drive'},
  {title: 'pmwjs_sign_up_sheet', subtitle: 'pmwjs_for_an_annual_marathon'},
  {title: 'pmwjs_coat_drive', subtitle: 'pmwjs_announcement'},
  {title: 'pmwjs_food_drive', subtitle: 'pmwjs_announcement'},
  {title: 'pmwjs_toy_drive', subtitle: 'pmwjs_at_local_children_s_shelter'},
  {title: 'pmwjs_blood_drive', subtitle: 'pmwjs_for_our_local_health_clinic'},
  {title: 'pmwjs_bake_sale', subtitle: 'pmwjs_to_raise_funds'},
  {title: 'pmwjs_school_supply_drive', subtitle: 'pmwjs_for_the_local_adoption_center'},
  {title: 'pmwjs_earthquake_relief_fund', subtitle: 'pmwjs_for_our_community'},
  {title: 'pmwjs_new_album', subtitle: 'pmwjs_announcement'},
  {title: 'pmwjs_ticket_on_sale', subtitle: 'pmwjs_for_our_live_performance'},
  {title: 'pmwjs_happy_weekend', subtitle: 'pmwjs_to_our_employees'},
  {title: 'pmwjs_family_appreciation', subtitle: 'pmwjs_for_our_loved_ones'},
  {title: 'pmwjs_a_heartfelt_confession', subtitle: 'pmwjs_to_my_beloved'},
  {title: 'pmwjs_announcement', subtitle: 'pmwjs_for_our_summer_camp'},
  {title: 'pmwjs_recruitment', subtitle: 'pmwjs_for_an_after_school_program'},
  {title: 'pmwjs_online_invitation', subtitle: 'pmwjs_for_our_college_fair'},
  {title: 'pmwjs_sign_up_announcement', subtitle: 'pmwjs_for_our_annual_school_contest'},
  {title: 'pmwjs_announcement', subtitle: 'pmwjs_for_extra_online_classes'},
  {title: 'pmwjs_campaign_posters', subtitle: 'pmwjs_for_my_student_council_campaign'},
  {title: 'pmwjs_invitation', subtitle: 'pmwjs_for_our_school_pto_meeting'},
  {title: 'pmwjs_weekly_update', subtitle: 'pmwjs_for_our_sunday_service'},
  {title: 'pmwjs_invitation', subtitle: 'pmwjs_to_join_our_bible_study_group'},
  {title: 'pmwjs_sunday_service', subtitle: 'pmwjs_announcement_for_our_church'},
  {title: 'pmwjs_youth_ministry_meeting', subtitle: 'pmwjs_at_our_local_church'},
  {title: 'pmwjs_sign_up_announcement', subtitle: 'pmwjs_for_our_community_church_event'},
  {title: 'pmwjs_monthly_conference', subtitle: 'pmwjs_announcement_for_our_church'},
  {title: 'pmwjs_new_menu', subtitle: 'pmwjs_for_my_italian_restaurant'},
  {title: 'pmwjs_delivery_hours_announcement', subtitle: 'pmwjs_for_my_fast_food_restaurant'},
  {title: 'pmwjs_daily_specials', subtitle: 'pmwjs_announcement_for_my_restaurant'},
  {title: 'pmwjs_opening_time', subtitle: 'pmwjs_update_for_my_restaurant'},
  {title: 'pmwjs_happy_hour_menu', subtitle: 'pmwjs_for_my_new_bar'},
  {title: 'pmwjs_a_fun_tastic_pun', subtitle: 'pmwjs_to_promote_my_new_cafe'},
  {title: 'pmwjs_birthday_wish', subtitle: 'pmwjs_for_a_loved_one'},
  {title: 'pmwjs_get_well_soon_wish', subtitle: 'pmwjs_for_a_loved_one'},
  {title: 'pmwjs_good_luck_wish', subtitle: 'pmwjs_for_a_colleague'},
  {title: 'pmwjs_thank_you', subtitle: 'pmwjs_to_our_customers'},
  {title: 'pmwjs_farewell', subtitle: 'pmwjs_to_a_cherished_employee'},
  {title: 'pmwjs_congratulations', subtitle: 'pmwjs_on_a_new_venture'},
  {title: 'pmwjs_happy_anniversary', subtitle: 'pmwjs_to_someone_special'},
  {title: 'pmwjs_in_memory_of', subtitle: 'pmwjs_a_loved_one'},
  {title: 'pmwjs_business_hours', subtitle: 'pmwjs_for_my_retail_store'},
  {title: 'pmwjs_new_arrivals', subtitle: 'pmwjs_in_my_clothing_store'},
  {title: 'pmwjs_job_openings', subtitle: 'pmwjs_for_my_small_business'},
  {title: 'pmwjs_coming_soon_announcement', subtitle: 'pmwjs_for_my_new_product'},
  {title: 'pmwjs_online_invite', subtitle: 'pmwjs_for_a_business_conference'},
  {title: 'pmwjs_online_invite', subtitle: 'pmwjs_for_a_virtual_conference'},
  {title: 'pmwjs_event_announcement', subtitle: 'pmwjs_for_a_grand_opening'},
  {title: 'pmwjs_event_page', subtitle: 'pmwjs_for_my_upcoming_concert'},
  {title: 'pmwjs_upcoming_event', subtitle: 'pmwjs_for_my_boutique_sale'},
  {title: 'pmwjs_online_invitation', subtitle: 'pmwjs_for_my_charity_fundraiser'},
  {title: 'pmwjs_event_schedule', subtitle: 'pmwjs_for_my_music_festival'},
  {title: 'pmwjs_grand_opening', subtitle: 'pmwjs_of_my_new_restaurant'},
];
