import type {SocialAccountConnectionResponseModalProps} from '@Modals/social-account-connection-response-modal/social-account-connection-response-modal';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';

export * from './social-account-connection-response-modal';
export const PANEL_ID = 'social-account-connection-response-panel';

export const openSocialAccountConnectionResponseModal = (props: SocialAccountConnectionResponseModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      panelProps: props,
      modalId: MODALS_IDS.SOCIAL_ACCOUNT_CONNECTION_RESPONSE_MODAL,
    })
  );
};

export const closeSocialAccountConnectionResponseModal = (): void => {
  window.PMW.redux.store.dispatch(closeModal(MODALS_IDS.SOCIAL_ACCOUNT_CONNECTION_RESPONSE_MODAL));
};
