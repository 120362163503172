import {CommonMethods} from '@PosterWhiteboard/common-methods';

export type TEventCallback<T = any> = (options: T) => any;
type VoidFunction = () => void;

export class Observable<EventSpec> extends CommonMethods {
  private eventListeners: Partial<Record<keyof EventSpec, TEventCallback[]>> = {};

  public constructor() {
    super();
  }

  public on<K extends keyof EventSpec, E extends EventSpec[K]>(eventName: K, handler: TEventCallback<E>): VoidFunction {
    if (this.eventListeners[eventName] === undefined) {
      this.eventListeners[eventName] = [];
    }

    this.eventListeners[eventName].push(handler);
    return () => {
      this.off(eventName, handler);
    };
  }

  public off(eventName: keyof EventSpec, handler?: TEventCallback): void {
    if (!this.eventListeners[eventName]) {
      return;
    }

    if (handler) {
      const eventListener = this.eventListeners[eventName];
      const index = eventListener.indexOf(handler);
      if (index > -1) {
        eventListener.splice(index, 1);
      }
    } else {
      this.eventListeners[eventName] = [];
    }
  }

  public fire<K extends keyof EventSpec>(eventName: K, options?: EventSpec[K]): void {
    const listenersForEvent = this.eventListeners[eventName] ? [...this.eventListeners[eventName]] : [];
    for (const item of listenersForEvent) {
      item.call(this, options ?? {});
    }
  }
}
