import {createSlice} from '@reduxjs/toolkit';
import {LOADING_STATES} from "@Libraries/email-marketing-campaigns-library.js";
import {emailMarketingCampaignSettingsExtraReducers} from "./email-marketing-campaign-settings-extra-reducers.js";

const initialState = {
    settingsLoadingState: LOADING_STATES.NOT_LOADED,
    senderProfiles: {},
    emailSends: 0,
    credits: null,
    defaultSenderProfileId: null
};

const emailCampaignSettingsSlice = createSlice({
    name: 'emailCampaignSettings',
    initialState,
    reducers: {
        updateEmailSends: (state, action) => {
            state.emailSends = action.payload;
        },
        updateCredits: (state, action) => {
            state.credits = action.payload;
        },
        updateActiveSenderProfile: (state, action) => {
            for (const senderProfileID in state.senderProfiles) {
                if (state.senderProfiles[senderProfileID].isDefault) {
                    state.senderProfiles[senderProfileID].isDefault = false;
                }
            }
            state.senderProfiles[action.payload.id].isDefault = action.payload.isDefault;
        },
        updateSettingsLoadingState: (state, action) => {
            state.settingsLoadingState = action.payload;
        }
    },

    extraReducers: {...emailMarketingCampaignSettingsExtraReducers}
});

export const {updateCredits, updateEmailSends, updateActiveSenderProfile, updateSettingsLoadingState} = emailCampaignSettingsSlice.actions;
export const emailMarketingCampaignSettingsReducer = emailCampaignSettingsSlice.reducer;
