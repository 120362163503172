import type {BaseBackgroundObject} from '@PosterWhiteboard/page/background/background.class';
import {Background, BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {FillObject} from '@PosterWhiteboard/classes/fill.class';
import {Fill, FillTypes} from '@PosterWhiteboard/classes/fill.class';
import type {RGB} from '@Utils/color.util';
import {hexToRgb, rgbToRGBString} from '@Utils/color.util';
import {PageBackgroundDropDownItem} from '@Components/poster-editor/components/poster-editing-side-panel/components/page-background-option/page-background-option.types';
import type * as Fabric from '@postermywall/fabricjs-2';
import {DEFAULT_BACKGROUND_COLOR, DEFAULT_SECONDARY_BACKGROUND_COLOR} from '@PosterWhiteboard/page/page.types';
import type {DeepPartial} from '@/global';

export interface ColorBackgroundObject extends BaseBackgroundObject {
  type: BackgroundTypeName.COLOR;
  fill: FillObject;
}

export class ColorBackground extends Background {
  public type: BackgroundTypeName.COLOR = BackgroundTypeName.COLOR;
  public fill: Fill;

  constructor(page: Page) {
    super(page);
    this.fill = new Fill({
      getLinearGradientOpts: getLinearGradientOptsForBackground,
      getRadialGradientOpts: getRadialGradientOptsForBackground,
    });
  }

  public copyVals(obj: DeepPartial<ColorBackgroundObject>): void {
    const {fill, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.fill.copyVals(fill);
  }

  public async invalidate(): Promise<void> {
    this.page.setBackgroundColor(
      this.fill.getFill(this.page.fabricCanvas.width / this.page.fabricCanvas.getZoom(), this.page.fabricCanvas.height / this.page.fabricCanvas.getZoom())
    );
    this.page.setBackgroundImage(undefined);
  }

  public toObject(): ColorBackgroundObject {
    return {
      type: this.type,
      fill: this.fill.toObject(),
    };
  }
}

export const createColorBackgroundFromObject = async (page: Page, opts: DeepPartial<ColorBackgroundObject> = {}): Promise<ColorBackground> => {
  const background = new ColorBackground(page);
  await background.updateFromObject(opts, {undoable: false});
  return background;
};

export const getColorFillTypeForBackgroundDropdownItem = (backgroundDropDownItem: PageBackgroundDropDownItem): FillTypes => {
  switch (backgroundDropDownItem) {
    case PageBackgroundDropDownItem.SOLID:
      return FillTypes.SOLID;
    case PageBackgroundDropDownItem.GRADIENT:
      return FillTypes.LINEAR_GRADIENT;
    case PageBackgroundDropDownItem.TRANSPARENT:
      return FillTypes.NONE;
    default:
      throw new Error(`backgroundDropDownItem is not a color background type`);
  }
};

export const getBackgroundDropdownItemForColorFillType = (type: FillTypes): PageBackgroundDropDownItem => {
  switch (type) {
    case FillTypes.SOLID:
      return PageBackgroundDropDownItem.SOLID;
    case FillTypes.LINEAR_GRADIENT:
    case FillTypes.RADIAL_GRADIENT:
      return PageBackgroundDropDownItem.GRADIENT;
    case FillTypes.NONE:
      return PageBackgroundDropDownItem.TRANSPARENT;
    default:
      throw new Error(`backgroundDropDownItem is not a color background type`);
  }
};

export const getFillColorForBackgroundDropdownItem = (backgroundDropDownItem: PageBackgroundDropDownItem): RGB[] => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    throw new Error('current page is undefined');
  }
  if (currentPage.background.details.isColor()) {
    return currentPage.background.details.fill.getColorForNewType(getColorFillTypeForBackgroundDropdownItem(backgroundDropDownItem));
  }

  const primaryDefaultColor = hexToRgb(DEFAULT_BACKGROUND_COLOR);
  const secondaryDefaultColor = hexToRgb(DEFAULT_SECONDARY_BACKGROUND_COLOR);

  if (backgroundDropDownItem === PageBackgroundDropDownItem.SOLID || backgroundDropDownItem === PageBackgroundDropDownItem.TRANSPARENT) {
    return [primaryDefaultColor];
  }
  if (backgroundDropDownItem === PageBackgroundDropDownItem.GRADIENT) {
    return [primaryDefaultColor, secondaryDefaultColor];
  }

  throw new Error('cannot get color for background type image');
};

export const getLinearGradientOptsForBackground = (fillHeight: number, gradientFillColors: RGB[], alpha = 1): Fabric.GradientOptions<'linear'> => {
  const x = 0;
  const y = fillHeight;

  const colorStops = [];
  for (let i = 0; i < gradientFillColors.length; i++) {
    colorStops.push({offset: (i + 1) / gradientFillColors.length, color: rgbToRGBString(gradientFillColors[i], alpha)});
  }

  return {
    colorStops,
    coords: {
      x1: x,
      x2: x,
      y1: -y,
      y2: y,
    },
    type: 'linear',
  };
};

const getRadialGradientOptsForBackground = (fillWidth: number, fillHeight: number, gradientFillColors: RGB[], alpha = 1): Fabric.GradientOptions<'radial'> => {
  const centerX = fillWidth / 2;
  const centerY = fillHeight / 2;

  return {
    colorStops: [
      {offset: 0, color: rgbToRGBString(gradientFillColors[0], alpha)},
      {offset: 1, color: rgbToRGBString(gradientFillColors[1], alpha)},
    ],
    type: 'radial',
    coords: {
      x1: centerX,
      y1: centerY,
      r1: 0,
      x2: centerX,
      y2: centerY,
      r2: Math.max(fillWidth, fillHeight),
    },
  };
};
