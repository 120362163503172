import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {Point} from '@Utils/math.util';
import type {LayoutType} from '@PosterWhiteboard/items/layouts/layout.types';
import {LayoutTypes, X_SPACING_DEFAULT_VALUE1, X_SPACING_DEFAULT_VALUE2, X_SPACING_DEFAULT_VALUE3, Y_SPACING_DEFAULT_VALUE} from '@PosterWhiteboard/items/layouts/layout.types';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import {SlantedSportsLayout} from '@PosterWhiteboard/items/layouts/slanted-sports-layout';
import {TableLayout} from '@PosterWhiteboard/items/layouts/table-layout';
import {OffsetSportsLayout} from '@PosterWhiteboard/items/layouts/offset-sports-layout';
import {SportsLayout} from '@PosterWhiteboard/items/layouts/sports-layout';
import {BandLayout} from '@PosterWhiteboard/items/layouts/band-layout';
import {MenuLayout1} from '@PosterWhiteboard/items/layouts/menu-layout-1';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {MenuLayout2} from '@PosterWhiteboard/items/layouts/menu-layout-2';
import {MenuLayout3} from '@PosterWhiteboard/items/layouts/menu-layout-3';
import {MenuLayout4} from '@PosterWhiteboard/items/layouts/menu-layout-4';
import {MenuLayout5} from '@PosterWhiteboard/items/layouts/menu-layout-5';
import {MenuLayout6} from '@PosterWhiteboard/items/layouts/menu-layout-6';

export const getLayoutHelperClass = (item: TableItem | MenuItem, layoutName: LayoutTypes): LayoutType => {
  switch (layoutName) {
    case LayoutTypes.TABLE_LAYOUT:
      return new TableLayout(item, false);
    case LayoutTypes.BAND_LAYOUT_LEFT_ALIGN:
      return new BandLayout(item, layoutName);
    case LayoutTypes.BAND_LAYOUT_RIGHT_ALIGN:
      return new BandLayout(item, layoutName);
    case LayoutTypes.BAND_LAYOUT_CENTER_ALIGN:
      return new BandLayout(item, layoutName);
    case LayoutTypes.SPORTS_LAYOUT:
      return new SportsLayout(item);
    case LayoutTypes.SLANTED_SPORTS_LAYOUT:
      return new SlantedSportsLayout(item, true);
    case LayoutTypes.STRAIGHT_SPORTS_LAYOUT:
      return new SlantedSportsLayout(item, false);
    case LayoutTypes.OFFSET_SPORTS_LAYOUT:
      return new OffsetSportsLayout(item);
    case LayoutTypes.CUSTOM_TABLE_LAYOUT:
      return new TableLayout(item, true);
    case LayoutTypes.MENU_LAYOUT_1:
      return new MenuLayout1(item as MenuItem);
    case LayoutTypes.MENU_LAYOUT_2:
      return new MenuLayout2(item as MenuItem);
    case LayoutTypes.MENU_LAYOUT_3:
      return new MenuLayout3(item as MenuItem);
    case LayoutTypes.MENU_LAYOUT_4:
      return new MenuLayout4(item as MenuItem);
    case LayoutTypes.MENU_LAYOUT_5:
      return new MenuLayout5(item as MenuItem);
    case LayoutTypes.MENU_LAYOUT_6:
      return new MenuLayout6(item as MenuItem);
    default:
      return new TableLayout(item, false);
  }
};

/**
 * Returns true if the layout passed is using the second font family, false otherwise
 */
export const isSecondFontFamilyUsed = (layout: LayoutTypes): boolean => {
  if (typeof layout === 'undefined') {
    return false;
  }
  return (
    layout === LayoutTypes.BAND_LAYOUT_LEFT_ALIGN ||
    layout === LayoutTypes.BAND_LAYOUT_RIGHT_ALIGN ||
    layout === LayoutTypes.BAND_LAYOUT_CENTER_ALIGN ||
    layout === LayoutTypes.MENU_LAYOUT_1 ||
    layout === LayoutTypes.MENU_LAYOUT_2 ||
    layout === LayoutTypes.MENU_LAYOUT_3 ||
    layout === LayoutTypes.MENU_LAYOUT_4 ||
    layout === LayoutTypes.MENU_LAYOUT_5 ||
    layout === LayoutTypes.MENU_LAYOUT_6 ||
    layout === LayoutTypes.OFFSET_SPORTS_LAYOUT
  );
};

export const getColumnsForLayout = (layout: LayoutTypes): Partial<Record<CellType, any[]>> => {
  switch (layout) {
    case LayoutTypes.TABLE_LAYOUT:
      return {
        [CellType.DATE]: [],
        [CellType.TIME]: [],
        [CellType.TEAM]: [],
        [CellType.VENUE]: [],
        [CellType.HIGHLIGHT]: [],
      };
    case LayoutTypes.BAND_LAYOUT_LEFT_ALIGN:
    case LayoutTypes.BAND_LAYOUT_RIGHT_ALIGN:
    case LayoutTypes.BAND_LAYOUT_CENTER_ALIGN:
      return {
        [CellType.DATE]: [],
        [CellType.VENUE]: [],
        [CellType.HIGHLIGHT]: [],
      };

    case LayoutTypes.SPORTS_LAYOUT:
      return {
        [CellType.DATE]: [],
        [CellType.TEAM]: [],
        [CellType.VENUE]: [],
        [CellType.HIGHLIGHT]: [],
      };

    case LayoutTypes.SLANTED_SPORTS_LAYOUT:
    case LayoutTypes.STRAIGHT_SPORTS_LAYOUT:
      return {
        [CellType.DATE]: [],
        [CellType.TIME]: [],
        [CellType.TEAM]: [],
        [CellType.VENUE]: [],
        [CellType.HIGHLIGHT]: [],
      };

    case LayoutTypes.OFFSET_SPORTS_LAYOUT:
      return {
        [CellType.DATE]: [],
        [CellType.VENUE]: [],
        [CellType.TEAM]: [],
        [CellType.HIGHLIGHT]: [],
      };

    case LayoutTypes.CUSTOM_TABLE_LAYOUT:
      return {
        [CellType.COLUMN1]: [],
        [CellType.COLUMN2]: [],
        [CellType.COLUMN3]: [],
        [CellType.HIGHLIGHT]: [],
      };

    case LayoutTypes.MENU_LAYOUT_1:
    case LayoutTypes.MENU_LAYOUT_2:
    case LayoutTypes.MENU_LAYOUT_3:
    case LayoutTypes.MENU_LAYOUT_4:
    case LayoutTypes.MENU_LAYOUT_5:
    case LayoutTypes.MENU_LAYOUT_6:
      return {
        [CellType.NAME]: [],
        [CellType.DESCRIPTION]: [],
        [CellType.PRICE]: [],
        [CellType.ICONS]: [],
        [CellType.VARIATION]: [],
        [CellType.ADDON]: [],
        [CellType.HIGHLIGHT]: [],
      };

    default:
      throw new Error(`Unhandled layout type ${layout}`);
  }
};

/**
 * Returns number of columns for a layout passed to this function,
 * 'highlight' property is not a column, it is used for table/schedule styling only hence always subtract 1 from column count
 */
export const getColumnCountForLayout = (layout: LayoutTypes): number => {
  return Object.keys(getColumnsForLayout(layout)).length - 1;
};

/**
 * Returns x and y spacing values to use for a given layout
 * @param {String} layoutName
 * @returns {{x: *, y: *}}
 */
export const getSpacingValuesForMenuLayouts = (layoutName: LayoutTypes): Point => {
  switch (layoutName) {
    case LayoutTypes.MENU_LAYOUT_1:
      return {
        x: X_SPACING_DEFAULT_VALUE1,
        y: Y_SPACING_DEFAULT_VALUE,
      };
    case LayoutTypes.MENU_LAYOUT_3:
      return {
        x: X_SPACING_DEFAULT_VALUE2,
        y: Y_SPACING_DEFAULT_VALUE,
      };
    case LayoutTypes.MENU_LAYOUT_2:
    case LayoutTypes.MENU_LAYOUT_4:
    case LayoutTypes.MENU_LAYOUT_5:
    case LayoutTypes.MENU_LAYOUT_6:
      return {
        x: X_SPACING_DEFAULT_VALUE3,
        y: Y_SPACING_DEFAULT_VALUE,
      };
    default:
      return {
        x: X_SPACING_DEFAULT_VALUE3,
        y: Y_SPACING_DEFAULT_VALUE,
      };
  }
};
