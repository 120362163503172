import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  isLoading: boolean
  showCoverGenerationError: boolean;
}

const initialState: StateProps = {
  isLoading: true,
  showCoverGenerationError: false,
};

export const socialMediaSelectCoverPanelSlice = createSlice({
  name: 'selectCoverPanel',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setShowCoverGenerationError(state, action: PayloadAction<boolean>) {
      state.showCoverGenerationError = action.payload;
    },
  },
});

export const {setIsLoading, setShowCoverGenerationError} = socialMediaSelectCoverPanelSlice.actions;
export const socialMediaSelectCoverPanelReducer = socialMediaSelectCoverPanelSlice.reducer;