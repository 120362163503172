import {createSlice} from '@reduxjs/toolkit';

interface InitalPosterItemsLoadErrorNotificationProps {
  show: boolean;
}

const initialState: InitalPosterItemsLoadErrorNotificationProps = {
  show: false,
};

const initalPosterItemsLoadErrorSlice = createSlice({
  name: 'initalPosterItemsLoadErrorNotification',
  initialState,
  reducers: {
    showInitalPosterItemsLoadError: (state) => {
      state.show = true;
    },
    hideInitalPosterItemsLoadError: (state) => {
      state.show = false;
    },
  },
});

export const {showInitalPosterItemsLoadError, hideInitalPosterItemsLoadError} = initalPosterItemsLoadErrorSlice.actions;
export const initalPosterItemsLoadErrorReducer = initalPosterItemsLoadErrorSlice.reducer;
