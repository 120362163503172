import type {UserImageVOResponse} from '@Libraries/user-image-library';
import type {MediaItemSizeType} from '@Libraries/s3-library';
import {repoURL, getDirForMediaSizeType} from '@Libraries/s3-library';
import {getWriteBucket} from '@Utils/s3.util';

const BASE_DIR = 'aiimages';

export interface TextToImageUploadProps {
  userId?: number;
  aiGeneratedImageId: number;
}

export const textToImageUpload = async (p: TextToImageUploadProps): Promise<UserImageVOResponse> => {
  return (await window.PMW.writeLocal('stockmedia/uploadGeneratedImage', p)) as UserImageVOResponse;
};

export const showTextToImageUpsellDialog = async (): Promise<void> => {
  window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_TEXT_TO_IMAGE);
};

export const getDirPathForGeneratedAiImage = (sizeType: MediaItemSizeType): string => {
  const subDirForSize = getDirForMediaSizeType(sizeType);
  return `${BASE_DIR}${subDirForSize ? `/${subDirForSize}` : ''}`;
};

export const getUrlForGeneratedAiImage = (hashedFilename: string, ext: string, sizeType: MediaItemSizeType): string => {
  return repoURL(`${getDirPathForGeneratedAiImage(sizeType)}/${hashedFilename}.${ext}`, getWriteBucket());
};
