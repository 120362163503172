import type {GridMediaItemStorage} from '@Components/base-grid/components/grid-media-item';
import {GridMediaItem} from '@Components/base-grid/components/grid-media-item';
import type {GridItemProps} from '@Components/base-grid/components/grid-item';
import type {ReactElement} from 'react';
import React, {useState} from 'react';

export interface GridExtractedGettyStickerItemStorage extends GridMediaItemStorage {
  hashedFilename: string;
}

interface GridExtractedGettyStickerItemProps extends GridItemProps {
  thumbSrc: string;
}

export function GridExtractedGettyStickerItem({...props}: GridExtractedGettyStickerItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <img
          onLoad={(): void => {
            return setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          className="radius-12"
          alt={window.i18next.t('pmwjs_image')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
