/**
 * Library with utility functions and business logic for the 'Brand Kits' feature
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
import {escapeHTML, makeStringUnique} from '@Utils/string.util.ts';
import {colorToHexString, generateLighterToneColorPaletteForColor} from '@Utils/color.util.ts';
import {repoImageThumbURL} from './graphic-item-image-library.ts';
import {hideLoading, PROGRESS_ALMOST_COMPLETE, showLoading} from './loading-toast-library.js';
import {getUserPremiumLevel, isUserPremium, isUserPremiumPlus} from './user.library.ts';
import {moveArrayItemToStart, objectArrayToHashmap} from "@Utils/array.util.ts";
import {getWriteBucket} from "@Utils/s3.util.ts";
import {isPAYGUser} from "@Utils/user.util.ts";

/**
 * Constant for setting an active brand in My Stuff
 * @type {string}
 */
export const BRANDS_ACTIVE_CONTEXT_MYSTUFF = 'mystuff';

/**
 * constant for setting an active brand in the Editor
 * @type {string}
 */
export const BRANDS_ACTIVE_CONTEXT_EDITOR = 'editor';

/**
 * constant for setting a brand as 'active' in both my stuff and the design editor
 * @type {string}
 */
export const BRANDS_ACTIVE_CONTEXT_MYSTUFF_AND_EDITOR = 'mystuffandeditor';

/**
 * A temporary ID to assign to a brand for the UI until a response is received from the backend
 * @type {string}
 */
export const TEMP_BRAND_ID = 'tempBrandId';

export const PREMIUM_USER_BRANDS_LIMIT = 1;

/**
 *
 * @type {{NOT_LOADED: number, LOADING: number, LOADED: number}}
 */
export const BRANDS_LOADING_STATES = {
  NOT_LOADED: 1,
  LOADING: 2,
  LOADED: 3,
};

const BRAND_CONTENT_TYPE_ID_KEYS = {
  BRAND_IMAGE: 'idbrandImage',
  BRAND_COLOR: 'idbrandColor',
  BRAND_FONT: 'idbrandFont',
  BRAND_ACTIVITY: 'idbrandActivity',
};

/**
 *
 * @type {Readonly<{BRAND_LOGOS: number, BRAND_COLORS: number, BRAND_FONTS: number}>}
 */
export const BRAND_ASSETS = {
  BRAND_LOGOS: 1,
  BRAND_COLORS: 2,
  BRAND_FONTS: 3,
};

/**
 *
 * @type {Readonly<{BRAND_LOGOS: string, BRAND_COLORS: string, BRAND_FONTS: string}>}
 */
export const BRAND_ASSET_NAME = {
  BRAND_LOGOS: 'logos',
  BRAND_COLORS: 'colors',
  BRAND_FONTS: 'fonts',
};

/**
 *
 * @type {string}
 */
export const BRAND_CONTENT_TYPE_ACTIVITY_LOG = 'Activity';

/**
 * the activity types for a brand corresponding with BrandActivityVO in PHP
 * @type {Readonly<Object>}
 */
export const BRAND_ACTIVITY_TYPES = {
  ADDED: 1,
  UPDATED: 2,
  DELETED: 3,
};

/**
 *
 * @type {Object}
 */
export const BRAND_IMAGE_TYPES = {
  TYPE_BRAND_LOGO: 1,
  TYPE_BRAND_LOGO_PRIMARY: 2,
  TYPE_BRAND_IMAGE: 3,
};

/**
 *
 * @type {Readonly<{BRAND_FONT_TYPE_HEADING: number, BRAND_FONT_TYPE_NORMAL: number}>}
 */
export const BRAND_FONTS_TYPES = {
  BRAND_FONT_TYPE_HEADING: 1,
  BRAND_FONT_TYPE_NORMAL: 2,
};

/**
 *
 * @type {string}
 */
const BRAND_FONT_PREVIEW_URL_PREFIX = 'brandfont';

const ADD_UPDATE_BRAND_FONT_LOADING_KEY = 'addOrUpdateBrandFont';

/**
 * enums for brand color types
 * @type {Readonly<{OTHER: number, SECONDARY: number, PRIMARY: number}>}
 */
export const BRAND_COLOR_TYPES = {
  PRIMARY: 1,
  SECONDARY: 2,
  OTHER: 3,
};

export const BRAND_CREATION_SOURCES = {
  EMPTY_STATE: 'EmptyState',
  DROPDOWN: 'Dropdown',
  TOP_BAR: 'TopBar',
};

/**
 *
 * @type {{small: {maxWidth: number}}}
 */
export const brandKitsContainerQueryParams = {
  small: {
    maxWidth: 380
  }
}

/**
 *
 * @type {string}
 */
export const BRAND_KITS_ANALYTICS_CATEGORY = 'BrandKits';
/**
 *
 * @type {Object}
 */
export const BRANDS_ANALYTICS_ACTIONS = {
  NEW_BRAND: 'BrandKits_NewBrandKit',
  DELETE_BRAND: 'BrandKits_DeleteBrandKit',
  ADD_LOGO: 'BrandKits_AddLogo',
  DELETE_LOGO: 'BrandKits_DeleteLogo',
  MAKE_PRIMARY_LOGO: 'BrandKits_MakePrimaryLogo',
  ADD_COLOR: 'BrandKits_AddColor',
  DELETE_COLOR: 'BrandKits_DeleteColor',
  UPDATE_COLOR: 'BrandKits_UpdateColor',
  ADD_FONT: 'BrandKits_AddFont',
  ADD_FONT_TYPE: 'BrandKits_AddFontType',
  ADD_FONT_SIZE: 'BrandKits_AddFontSize',
  DELETE_FONT: 'BrandKits_DeleteFont',
  USE_FONT: 'BrandKits_UseFont',
  USE_COLOR: 'BrandKits_UseColor',
  USE_LOGO: 'BrandKits_UseLogo',
};

/**
 *
 * @type {Object}
 */
export const BRANDS_ANALYTICS_ACTION_LABELS = {
  PRIMARY_COLOR: 'Primary',
  SECONDARY_COLOR: 'Secondary',
  OTHER_COLOR: 'Other',
  PMW_FONT: 'PosterMyWall',
  USER_FONT: 'Uploaded',
  FONT_TYPE_HEADING: 'Heading',
  FONT_TYPE_NORMAL: 'Normal',
};

/**
 * General Brands-related functions START
 */

export const getBrandsStateFromStore = () => {
  return PMW.redux.store.getState().brands;
};

export const getBrandsFromStore = () => {
  return getBrandsStateFromStore().brands;
};

export const getBrandContentFromStore = () => {
  return getBrandsStateFromStore().brandContent;
};

export const getActiveBrandsFromStore = () => {
  return getBrandsStateFromStore().activeBrands;
};

/**
 * @param {string} brandId
 * @param brandsSlice
 * @returns {Object}
 */
export const getBrandColorIdsAndColorsFromStore = (brandId, brandsSlice = getBrandsStateFromStore()) => {
  const brandColorIds = brandsSlice.brandContent[brandId].colors.ids,
    colors = brandsSlice.colors;

  return {colorIds: brandColorIds, brandColors: colors};
};
/**
 * Finds and returns a brand from a list of brands
 * @param {string} brandId
 * @param {Array.<Object>} brands
 * @returns {Object}
 */
export const getBrandFromList = (brandId, brands) => {
  return brands.find((brand) => brand.brandId === brandId);
};

/**
 *
 * @param {string} brandId
 * @param {Array.<Object>}brands
 * @returns {string}
 */
export const getBrandName = (brandId, brands) => {
  return getBrandFromList(brandId, brands)?.name ?? '';
};

/**
 *
 * @param {string} brandName
 * @return {string}
 */
export const getEscapedBrandName = (brandName) => {
  return escapeHTML(brandName);
};

/**
 *
 * @param {Object} [brand] a brand object containing brand metadat
 * @param {string} [brand.name] the brand's name
 * @param name
 * @returns {boolean}
 */
export const isNameDifferentFromBrandName = (brand, name) => {
  return brand && brand.name !== name;
};

/**
 * Checks the given brand names and creates a unique string starting with 'New Brand'
 * @param {Array.<Object>} brands
 * @returns {string}
 */
export const getUniqueNameForNewBrand = (brands) => {
  const brandsNameMap = {};
  brands.forEach((brand) => {
    brandsNameMap[brand.name] = 1;
  });

  return makeStringUnique(i18next.t('pmwjs_new_brand'), brandsNameMap);
};

/**
 *
 * @param {Object} [brand]
 * @param {string} [brand.brandId]
 * @returns {boolean}
 */
export const doesBrandHaveTemporaryId = (brand) => {
  return brand.brandId.includes(TEMP_BRAND_ID);
};

/**
 * @param {Array.<Object>} brands
 * @param {number} brandsLoadingState
 * @returns {boolean}
 */
export const areThereNoBrandsToShow = (brands, brandsLoadingState = BRANDS_LOADING_STATES.LOADED) => {
  return areBrandsInEmptyState(brands, brandsLoadingState) || areBrandsLoading(brandsLoadingState);
};

/**
 * @param {Array.<Object>} brands
 * @returns {boolean}
 */
export const doesUserHaveSingleBrand = (brands) => {
  return getBrandsWithoutTemporaryIds(brands).length === 1;
};

/**
 * @param {Array.<Object>} brands
 * @returns {boolean}
 */
export const doesUserHaveMultipleBrands = (brands) => {
  return getBrandsWithoutTemporaryIds(brands).length > 1;
};

/**
 * @param {Array.<Object>} brands
 * @returns {boolean}
 */
export const doesUserHaveBrands = (brands = getBrandsStateFromStore().brands) => {
  return doesUserHaveSingleBrand(brands) || doesUserHaveMultipleBrands(brands);
};

/**
 * checks if brands have loaded from backend and
 * the resulting list is empty in which case there are no brands currently and we are in empty state.
 * @param {Array.<Object>} brands
 * @param {number} brandsLoadingState
 * @returns {boolean}
 */
export const areBrandsInEmptyState = (brands, brandsLoadingState = BRANDS_LOADING_STATES.LOADED) => {
  const brandsWithoutTempIds = getBrandsWithoutTemporaryIds(brands);
  return brandsLoadingState === BRANDS_LOADING_STATES.LOADED && brandsWithoutTempIds.length === 0;
};

/**
 *
 * @param {number} brandsLoadingState one of BRANDS_LOADING_STATES.* constants
 * @return {boolean}
 */
export const areBrandsLoading = (brandsLoadingState) => {
  return brandsLoadingState !== BRANDS_LOADING_STATES.LOADED;
};

/**
 *
 * @param {number} brandsLoadingState one of BRANDS_LOADING_STATES.* constants
 * @return {boolean}
 */
export const areBrandsLoaded = (brandsLoadingState) => {
  return brandsLoadingState === BRANDS_LOADING_STATES.LOADED;
};
/**
 * @param activeBrandId
 * @param brandContent
 * @param contentType
 * @returns {boolean}
 */
export const isBrandContentLoadingForContentType = (activeBrandId, brandContent, contentType) => {
  return isUserPremium() && brandContent[activeBrandId] && brandContent[activeBrandId][contentType].loaded !== BRANDS_LOADING_STATES.LOADED;
};

/**
 * @param activeBrandId
 * @param brandContent
 * @param contentType
 * @returns {boolean}
 */
export const isThereNoBrandContent = (activeBrandId, brandContent, contentType) => {
  return (
    brandContent[activeBrandId] &&
    !Object.keys(brandContent[activeBrandId][contentType].ids).length &&
    !isBrandContentLoadingForContentType(activeBrandId, brandContent, contentType)
  );
};

/**
 * @param activeBrandId
 * @param brandContent
 * @param contentType
 * @returns {boolean}
 */
export const isThereBrandWithContent = (activeBrandId, brandContent, contentType) => {
  return (
    !isBrandContentLoadingForContentType(activeBrandId, brandContent, contentType) &&
    brandContent[activeBrandId] &&
    Object.keys(brandContent[activeBrandId][contentType].ids).length > 0
  );
};

/**
 * Checks whether a brand has at least one of all types of brand assets (logos, colors, fonts)
 * For colors: we only count primary/secondary colors as they are more important for a Brand Kit
 * @param {string} brandId
 * @param brandsSlice
 * @return {boolean}
 */
export const doesBrandHaveAllAssetTypes = (brandId, brandsSlice = getBrandsStateFromStore()) => {
  return doesBrandHaveLogos(brandId, brandsSlice) && doesBrandHavePrimaryOrSecondaryColor(brandId, brandsSlice) && doesBrandHaveFonts(brandId, brandsSlice);
};

/**
 * Checks whether a brand has at least one of any type of brand assets (logos, colors, fonts)
 * For colors: we only count primary/secondary colors as they are more important for a Brand Kit
 * @param {string} brandId
 * @param brandsSlice
 * @return {boolean}
 */
export const doesBrandHaveAnyAssetType = (brandId, brandsSlice = getBrandsStateFromStore()) => {
  return doesBrandHaveLogos(brandId, brandsSlice) || doesBrandHavePrimaryOrSecondaryColor(brandId, brandsSlice) || doesBrandHaveFonts(brandId, brandsSlice);
}


/**
 * Checks whether a brand has at least a color or a font
 * For colors: we only count primary/secondary colors as they are more important for a Brand Kit
 * @param {string} brandId
 * @param brandsSlice
 * @return {boolean}
 */
export const doesBrandHaveColorsOrFonts = (brandId, brandsSlice = getBrandsStateFromStore()) => {
  return doesBrandHavePrimaryOrSecondaryColor(brandId, brandsSlice) || doesBrandHaveFonts(brandId, brandsSlice);
}


/**
 *
 * @param {Object} brandsSlice
 * @return {Object}
 */
export const getBrandMetaDataFromStore = (brandsSlice) => {
  return {
    brands: brandsSlice.brands,
    activeBrands: brandsSlice.activeBrands,
    brandsLoadingState: brandsSlice.brandsLoadingState,
    brandContent: brandsSlice.brandContent,
  };
};

/**
 *
 * @param {Array} brands
 * @returns {Array}
 */
export const getBrandsWithoutTemporaryIds = (brands) => {
  return brands.filter((brand) => !brand.brandId.includes(TEMP_BRAND_ID));
};
/**
 * creates an object with the given properties
 * @param {string} brandId
 * @param {string} brandName
 * @param {number} creatorId the USER ID of the creator of the brand
 * @returns {{brandId, name, creatorId}}
 */
export const prepareBrandObject = (brandId, brandName, creatorId) => {
  return {brandId: brandId, name: brandName, creatorId: creatorId, doesBrandHaveAllAssetTypes: false};
};

/**
 * Creates a temporary brand object that for the purposes of adding to the state and showing it in the UI
 * before the ajax request is sent to the server
 * a TEMP_BRAND_ID is prepended to the id passed to keep track of all 'temporary' brand objects
 * @param {string} temporaryId a uniqueId to append to the 'temp brand' constant
 * @param {string} brandName the brand name
 * @param {number} creatorId the user ID of the brand creator
 * @returns {{brandId: string, name, creatorId}}
 */
export const getTemporaryBrandObject = (temporaryId, brandName, creatorId) => {
  return {
    brandId: TEMP_BRAND_ID + temporaryId,
    name: brandName,
    creatorId: creatorId,
    ajaxSent: false,
  };
};

/**
 *
 * @param {string} newBrandName
 * @param {string|null} temporaryBrandId a temporary brand ID given to a brand until response is received from the server
 * @returns {Object}
 */
export const buildAjaxOptsForNewBrand = (newBrandName, temporaryBrandId = null) => {
  return {brandName: newBrandName, temporaryBrandId: temporaryBrandId};
};

/**
 * removes the temporary brand id from a brand and sets the correct one returned from the server
 * @param state The mystuff-brands reducer slice
 * @param temporaryBrandId
 * @param correctBrandId
 */
export const setCorrectBrandIdForBrand = (state, temporaryBrandId, correctBrandId) => {
  const brand = state.brands.find((brand) => brand.brandId === temporaryBrandId);
  brand.brandId = correctBrandId;
};

/**
 * creates a template object for brand content such as fonts,colors,logos, activity
 * Each brand content type has a loading state indicating whether it is being currently fetched from server or not
 * and the IDS contain the ids of the brand content type that can be used to fetch the actual data from a map in the store
 * @param {number} initialLoadingState one of BRANDS_LOADING_STATES_* constants
 * @return {Object}
 */
export const getBrandContentTemplate = (initialLoadingState = BRANDS_LOADING_STATES.NOT_LOADED) => {
  return {
    loaded: initialLoadingState,
    ids: [],
  };
};
/**
 * prepares and returns an object structured according to how data for a brand's content will be stored.
 * Sets the loading state for each brand asset section according to the given loading state
 *
 * Each brand would have:
 * {
 *  fonts: {},
 *  colors: {},
 *  logos: {}
 * }
 * @param {number} initialLoadingState the loading state to initially give to the brand sections. By default it will be 1 (BRANDS_LOADING_STATES.NOT_LOADED)
 * @returns {Object}
 */
export const getBrandPageContentTemplate = (initialLoadingState = BRANDS_LOADING_STATES.NOT_LOADED) => {
  return {
    fonts: getBrandContentTemplate(initialLoadingState),
    colors: getBrandContentTemplate(initialLoadingState),
    logos: getBrandContentTemplate(initialLoadingState),
  };
};

/**
 * checks whether the resource is not loaded and calls the provided fetching function accordingly.
 * @param {number} currentState the current loading state, one of BRANDS_LOADING_STATE constants
 * @param {function} fetchFunction the function that performs the fetch operation for the resource.
 * Typical usage: () => actualFunc(...funcArgs)
 */
export const fetchBrandsResourceIfNotLoaded = async (currentState, fetchFunction) => {
  if (currentState === BRANDS_LOADING_STATES.NOT_LOADED) {
    await fetchFunction();
  }
};

/**
 * checks whether user has permission to delete a given brand in My Stuff.
 * A user can delete a brand If they are either: the creator of the brand or team admin
 * @param {number} iduser
 * @param {string} brandId
 * @return {boolean}
 */
export const canUserDeleteBrandInMyStuff = (iduser, brandId) => {
  const brands = getBrandsStateFromStore(),
    brand = getBrandFromList(brandId, brands.brands);

  return brands.isUserTeamAdmin || brand.creatorId === iduser;
};

/**
 * General Brands-related functions END
 */

/**
 * BRAND CONTENT START
 */

/**
 * checks whether brand assets are loading or not given the loading state
 * BRANDS_LOADING_STATES.NOT_LOADED is also considered as "loading" because in this case an AJAX call will be fired to load the content
 * @param {number} loadingState the current loading state, must be a value from BRANDS_LOADING_STATE
 * @returns {boolean}
 */
export const isBrandContentTypeLoading = (loadingState) => {
  return loadingState !== BRANDS_LOADING_STATES.LOADED;
};

/**
 *
 * @param state The mystuff-brands reducer slice
 * @param brandId
 * @param brandAsset
 */
export const setLoadingForBrandContentType = (state, brandId, brandAsset) => {
  switch (brandAsset) {
    case BRAND_ASSETS.BRAND_LOGOS:
      state.brandContent[brandId].logos.loaded = BRANDS_LOADING_STATES.LOADING;
      break;

    case BRAND_ASSETS.BRAND_COLORS:
      state.brandContent[brandId].colors.loaded = BRANDS_LOADING_STATES.LOADING;
      break;
    case BRAND_ASSETS.BRAND_FONTS:
      state.brandContent[brandId].fonts.loaded = BRANDS_LOADING_STATES.LOADING;
      break;

    case BRAND_CONTENT_TYPE_ACTIVITY_LOG:
      state.brandActivityMap[brandId].loaded = BRANDS_LOADING_STATES.LOADING;
      break;
  }
};

/**
 * adds the data from the backend to the store for the provided brand content type (logos, fonts, colors, brand activity)
 * @param state The mystuff-brands reducer slice
 * @param brandContentType one of BRAND_ASSETS.* constants
 * @param {Object} [dataFromServer]
 * @param {string} [dataFromServer.brandId} the brand for which the data for the provided brand content type needs to be loaded
 * @param {Array} [dataFromServer.payload} the response from the server which could be brand color, logo, font, or activity items
 */
export const mapDataFromBackendToStateForBrandContentType = (state, brandContentType, dataFromServer) => {
  const {brandId, payload} = dataFromServer;
  switch (brandContentType) {
    case BRAND_ASSETS.BRAND_LOGOS:
      addBrandLogoDataFromBackendToStore(state, payload, brandId);
      break;

    case BRAND_ASSETS.BRAND_COLORS:
      addBrandColorDataFromBackendToStore(state, payload, brandId);
      break;
    case BRAND_ASSETS.BRAND_FONTS:
      addBrandFontsDataFromBackendToStore(state, payload, brandId);
      break;

    case BRAND_CONTENT_TYPE_ACTIVITY_LOG:
      addBrandActivityLogDataFromBackendToStore(state, payload, brandId);
      break;
  }
};

/**
 *
 * @param {string} brandId
 * @param {Object} [brandContent]
 * @param {Object} [brandContent.logos]
 * @param {Object} [brandContent.colors]
 * @param {Object} [brandContent.fonts]
 * @returns {boolean}
 */
export const isBrandContentLoading = (brandId, brandContent) => {
  const {fonts, colors, logos} = brandContent[brandId];
  return fonts.loaded !== BRANDS_LOADING_STATES.LOADED && colors.loaded !== BRANDS_LOADING_STATES.LOADED && logos.loaded !== BRANDS_LOADING_STATES.LOADED;
};

/**
 *
 * @param state The mystuff-brands reducer slice
 * @param {string} brandId
 * @returns {boolean}
 */
const doesBrandContentExistForBrandId = (state, brandId) => {
  return !!state.brandContent[brandId];
};

/**
 * checks whether activity log and brand content have already been set for this brand
 * if they have not been set, then initialize a skeleton/template object which will get populated later
 * @param state The mystuff-brands reducer slice
 * @param {string} brandId
 */
export const setBrandContentTemplatesIfNotExist = (state, brandId) => {
  if (!doesBrandContentExistForBrandId(state, brandId)) {
    state.brandContent[brandId] = getBrandPageContentTemplate();
  }

  if (!doesBrandActivityContentExistForBrandId(state, brandId)) {
    state.brandActivityMap[brandId] = getBrandContentTemplate();
  }
};
/**
 *
 * @param state The brands reducer slice
 * @param {string} activeBrandId
 */
export const setActiveBrandId = (state, activeBrandId) => {
  state.activeBrandId = activeBrandId;
};

/**
 * gets the brand according to the active brand of a user
 * if there is no active brand, just show the first brand
 * @param brandsSlice the brands reducer slice
 * @return {Object}
 */
export const getActiveBrand = (brandsSlice = getBrandsStateFromStore()) => {
  const {brands} = brandsSlice,
    activeBrandId = getActiveBrandId(brandsSlice);

  return activeBrandId ? getBrandFromList(activeBrandId, brands) : getBrandFromList(brands[0].brandId, brands);
};

/**
 *
 * @param brandsSlice
 * @return {null|string}
 */
export const getActiveBrandId = (brandsSlice = getBrandsStateFromStore()) => {
  return brandsSlice.activeBrandId;
};

/**
 *
 * @param brands
 * @param {string} activeBrandId the ID of the currently active brand
 * @returns {string}
 */
export const getActiveBrandName = (brands, activeBrandId) => {
  return getBrandName(activeBrandId, brands);
};

/**
 *
 * @param {number} brandImageType
 * @return {boolean}
 */
export const isBrandLogoPrimary = (brandImageType) => {
  return brandImageType === BRAND_IMAGE_TYPES.TYPE_BRAND_LOGO_PRIMARY;
};

/**
 * given a list of ids and brand logos, get the first primary brand logo found
 * @param {Array.<number>} brandLogoIds
 * @param {Object} [brandLogos]
 * @param {number} [brandLogos.brandImageType]
 * @returns {null|Object}
 */
export const getPrimaryBrandLogo = (brandLogoIds, brandLogos) => {
  for (let logoId of brandLogoIds) {
    if (isBrandLogoPrimary(brandLogos[logoId].brandImageType)) {
      return brandLogos[logoId];
    }
  }

  return null;
};

/**
 *
 * @param {string} brandId
 * @param brandsSlice
 * @return {boolean}
 */
export const doesBrandHaveLogos = (brandId, brandsSlice = getBrandsStateFromStore()) => {
  return brandsSlice.brandContent[brandId].logos.ids.length > 0;
};

/**
 *
 * @param {Array.<number>} brandLogoIds
 * @param {Object} [brandLogos]
 * @param {number} [brandLogos.brandImageType]
 * @param {string} [brandLogos.filename]
 * @param {string} [brandLogos.ext]
 * @returns {String}
 */
export const getPrimaryBrandLogoImageUrl = (brandLogoIds, brandLogos) => {
  const primaryBrandLogo = getPrimaryBrandLogo(brandLogoIds, brandLogos);
  return primaryBrandLogo ? repoImageThumbURL(primaryBrandLogo.filename, primaryBrandLogo.ext) : '';
};

/**
 *
 * @param state The mystuff-brands reducer slice
 * @param {Array} dataFromServer
 * @param {string} brandId
 */
const addBrandLogoDataFromBackendToStore = (state, dataFromServer, brandId) => {
  state.brandContent[brandId].logos.loaded = BRANDS_LOADING_STATES.LOADED;
  state.brandContent[brandId].logos.ids = dataFromServer.map((logo) => logo.idbrandImage);
  state.logos = {...state.logos, ...objectArrayToHashmap(dataFromServer, BRAND_CONTENT_TYPE_ID_KEYS.BRAND_IMAGE)};
  movePrimaryLogoToStart(state, brandId);
};

/**
 *
 * @param state the brands slice from store
 * @param {string} brandId
 */
export const movePrimaryLogoToStart = (state, brandId) => {
  const brandLogoIds = state.brandContent[brandId].logos.ids;
  let primaryLogoIndex = brandLogoIds.findIndex((logoId) => state.logos[logoId].brandImageType === BRAND_IMAGE_TYPES.TYPE_BRAND_LOGO_PRIMARY);
  state.brandContent[brandId].logos.ids = primaryLogoIndex >= 0 ? moveArrayItemToStart(brandLogoIds, primaryLogoIndex) : brandLogoIds;
};
/**
 *
 * @param {Object} brandLogos Object containing all the brand logos in the store
 * @param {Array.<number>} brandLogoIds A list of IDs whose type needs to be changed
 * @param {number} logoIdToExclude any logo id to exclude from changing its image type
 */
export const changeAllBrandImageTypesToLogoForBrand = (brandLogos, brandLogoIds, logoIdToExclude) => {
  brandLogoIds.forEach((id) => {
    if (id !== logoIdToExclude && brandLogos[id].brandImageType === BRAND_IMAGE_TYPES.TYPE_BRAND_LOGO_PRIMARY) {
      brandLogos[id].brandImageType = BRAND_IMAGE_TYPES.TYPE_BRAND_LOGO;
    }
  });
};

/**
 *
 * @param state The mystuff-brands reducer slice
 * @param {Array.<Object>} dataFromServer
 * @param {string} brandId
 */
const addBrandColorDataFromBackendToStore = (state, dataFromServer, brandId) => {
  state.brandContent[brandId].colors.loaded = BRANDS_LOADING_STATES.LOADED;
  state.brandContent[brandId].colors.ids = dataFromServer.map((color) => color.idbrandColor);
  state.colors = {...state.colors, ...objectArrayToHashmap(dataFromServer, BRAND_CONTENT_TYPE_ID_KEYS.BRAND_COLOR)};
};
/**
 * returns the first brand color object found for the provided color type
 * @param {Array.<number>} colorIds
 * @param {Object} colors the colors object mapping colorIds to colorVO/Objects
 * @param colorType one of BRAND_COLOR_TYPES.* constants
 * @returns {null|Object}
 */
const getBrandColorForType = (colorIds, colors, colorType) => {
  for (let colorId of colorIds) {
    if (colors[colorId].type === colorType) {
      return colors[colorId];
    }
  }

  return null;
};

/**
 *
 * @param {string} brandId
 * @param {number} colorType one of BRAND_COLOR_TYPES.* constants
 * @param brandsSlice
 * @returns {null|Object}
 */
export const getBrandColorForTypeForBrand = (brandId, colorType, brandsSlice = getBrandsStateFromStore()) => {
  const {colorIds, brandColors} = getBrandColorIdsAndColorsFromStore(brandId, brandsSlice);
  return getBrandColorForType(colorIds, brandColors, colorType);
};

/**
 *
 * @param {string} brandId
 * @return {null|Object}
 */
export const getPrimaryBrandColorForBrand = (brandId) => {
  const {colorIds, brandColors} = getBrandColorIdsAndColorsFromStore(brandId);
  return getBrandColorForType(colorIds, brandColors, BRAND_COLOR_TYPES.PRIMARY);
};

/**
 *
 * @param {string} brandId
 * @return {null|Object}
 */
export const getSecondaryBrandColorForBrand = (brandId) => {
  const {colorIds, brandColors} = getBrandColorIdsAndColorsFromStore(brandId);
  return getBrandColorForType(colorIds, brandColors, BRAND_COLOR_TYPES.SECONDARY);
};

/**
 * returns the all brand colors found for the provided color type
 * @param {Array.<number>} colorIds
 * @param {Object} colors the colos object mapping colorIds to colorVO/Objects
 * @param colorType one of BRAND_COLOR_TYPES.* constants
 * @returns {Array}
 */
export const getAllBrandColorsForType = (colorIds, colors, colorType) => {
  const brandColorsForType = [];
  for (let colorId of colorIds) {
    if (colors[colorId].type === colorType) {
      brandColorsForType.push(colors[colorId]);
    }
  }

  return brandColorsForType;
};

/**
 *
 * @param {string} brandId
 * @param brandsSlice
 * @return {boolean}
 */
export const doesBrandHavePrimaryOrSecondaryColor = (brandId, brandsSlice = getBrandsStateFromStore()) => {
  return (
    getBrandColorForTypeForBrand(brandId, BRAND_COLOR_TYPES.PRIMARY, brandsSlice) !== null ||
    getBrandColorForTypeForBrand(brandId, BRAND_COLOR_TYPES.SECONDARY, brandsSlice) !== null
  );
};

/**
 *
 * @param brands the brands state slice from brands-reducer
 * @param {string} brandId the brand ID for which to get colors for
 * @return {Array}
 */
const getAllBrandColorsForBrand = (brands, brandId) => {
  const colorIds = brands.brandContent[brandId].colors.ids,
    brandColors = brands.colors;

  return colorIds.map((colorId) => brandColors[colorId]);
};

/**
 *
 * @param brands the brands state slice from brands-reducer
 * @param {string} brandId the brand ID for which to get colors for
 * @return {Array}
 */
export const getAllHexColorsInBrand = (brands, brandId) => {
  const colorIds = brands.brandContent[brandId].colors.ids,
    brandColors = brands.colors,
    brandColorHexCodes = [];

  for (let colorId of colorIds) {
    if (brandColors[colorId]) {
      brandColorHexCodes.push(brandColors[colorId].hexCode);
    }
  }

  return brandColorHexCodes;
};

/**
 *
 * @param {null|Object} brandColor The brand color object to extract the color from
 * @returns {string|string}
 */
const getColorHexStringFromBrandColor = (brandColor) => {
  return brandColor ? colorToHexString(brandColor.hexCode) : '';
};

/**
 *
 * @param {Array} colorIds
 * @param {Object} colors
 * @returns {string}
 */
export const getPrimaryTypeBrandColorHex = (colorIds, colors) => {
  let primaryColor = getBrandColorForType(colorIds, colors, BRAND_COLOR_TYPES.PRIMARY);
  return getColorHexStringFromBrandColor(primaryColor);
};

/**
 *
 * @param {Array} colorIds
 * @param {Object} colors
 * @returns {string}
 */
export const getSecondaryTypeBrandColorHex = (colorIds, colors) => {
  let secondaryColor = getBrandColorForType(colorIds, colors, BRAND_COLOR_TYPES.SECONDARY);
  return getColorHexStringFromBrandColor(secondaryColor);
};

/**
 *
 * @param {Array} colorIds
 * @param {Object} colors
 * @returns {string}
 */
export const getOtherTypeBrandColorHex = (colorIds, colors) => {
  let otherColor = getBrandColorForType(colorIds, colors, BRAND_COLOR_TYPES.OTHER);
  return getColorHexStringFromBrandColor(otherColor);
};

/**
 * given a valid (non empty string) color, generate a palette with lighter tones based on the input color
 * @param {string} brandColor
 * @returns {Array|null}
 */
const getColorPaletteForBrandColor = (brandColor) => {
  return brandColor !== '' ? generateLighterToneColorPaletteForColor(brandColor) : null;
};

/**
 * creates a color palette by mixing both the primary and secondary color palettes along with both original input colors.
 * Three primary colors (original + 2 lightest from palette) are taken and two secondary colors (original + lightest color in the palette)
 * and the resulting array is sorted.
 * @param {Object} [brandColorsAndPalettes]
 * @param {string} [brandColorsAndPalettes.primaryColor] the hex string for the primary brand color
 * @param {string} [brandColorsAndPalettes.secondaryColor] the hex string for the secondary brand color
 * @param {Array.<string>} [brandColorsAndPalettes.primaryColorPalette] A color-sorted array (dark to lighter) of lighter tones of the primary brand color
 * @param {Array.<string>} [brandColorsAndPalettes.secondaryColorPalette] A color-sorted array (dark to lighter) of lighter tones of the secondary brand color
 * @returns {Array.<string>}
 */
const createBrandColorPaletteFromBothPrimaryAndSecondaryColorPalettes = (brandColorsAndPalettes) => [
  brandColorsAndPalettes.primaryColor,
  ...brandColorsAndPalettes.primaryColorPalette.slice(-2),
  brandColorsAndPalettes.secondaryColor,
  brandColorsAndPalettes.secondaryColorPalette[3],
];

/**
 * returns an array of colors with the original primary color and its generated lighter tones.
 * @param {string} primaryColor the hex string for the primary brand color
 * @param {Array.<string>} primaryColorPalette A color-sorted array (dark to lighter) of lighter tones of the primary brand color
 * @returns {Array.<string>}
 */
const createBrandColorPaletteFromPrimaryBrandColor = (primaryColor, primaryColorPalette) => [primaryColor, ...primaryColorPalette];

/**
 * Given the colors for the currently active brand, generate a palette of 5 colors (including original colors)
 * using the primary and secondary colors (if they exist)
 * If there are no primary or secondary colors: do nothing
 * If we have primary colors and no secondary, then the generate a color palette from primary color only.
 * if both primary and secondary colors exist, then take 3 from the primary color palette and 2 from the secondary color palette
 * @param {Array.<number>} activeBrandColorIds
 * @param {Object} brandColors
 * @returns {null|Array}
 */
export const getColorPaletteForBrands = (activeBrandColorIds, brandColors) => {
  let colors = null,
    primaryColor = getPrimaryTypeBrandColorHex(activeBrandColorIds, brandColors),
    secondaryColor = getSecondaryTypeBrandColorHex(activeBrandColorIds, brandColors),
    primaryColorPalette = getColorPaletteForBrandColor(primaryColor),
    secondaryColorPalette = getColorPaletteForBrandColor(secondaryColor);

  if (primaryColorPalette && secondaryColorPalette) {
    colors = createBrandColorPaletteFromBothPrimaryAndSecondaryColorPalettes({
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      primaryColorPalette: primaryColorPalette,
      secondaryColorPalette: secondaryColorPalette,
    });
  } else if (primaryColorPalette) {
    colors = createBrandColorPaletteFromPrimaryBrandColor(primaryColor, primaryColorPalette);
    colors = [primaryColor, ...primaryColorPalette];
  }

  return colors;
};

/**
 * Adds the data received from server for brands fonts into the brands reducer slice
 * @param state The mystuff-brands reducer slice The mystuff-brands reducer slice
 * @param {Array} dataFromServer
 * @param {string} brandId
 */
const addBrandFontsDataFromBackendToStore = (state, dataFromServer, brandId) => {
  state.brandContent[brandId].fonts.loaded = BRANDS_LOADING_STATES.LOADED;
  state.brandContent[brandId].fonts.ids = dataFromServer.map((font) => font.idbrandFont);
  state.fonts = {...state.fonts, ...objectArrayToHashmap(dataFromServer, BRAND_CONTENT_TYPE_ID_KEYS.BRAND_FONT)};
};

/**
 *
 * @param {Object} brandFontNamesMap the map which contains the font names as key
 * @param {Array} fontNamesToRemove a list of names that need to be excluded from the map
 */
const removeNamesFromFontNameMap = (brandFontNamesMap, fontNamesToRemove) => {
  for (let name of fontNamesToRemove) {
    if (brandFontNamesMap[name]) {
      delete brandFontNamesMap[name];
    }
  }
};

/**
 * creates a unique brand font name based on the given font name
 * Optionally, some names can be provided so they can be excluded from the unique check
 * @param {string} brandId
 * @param {string} defaultName a name to give by default which will be the basis for the new string
 * @param {Array.<string>} fontNamesToExclude a list of names that should be excluded from the uniqueness check
 * @returns {string}
 * Unused function, keeping in case brand font names are introduced back in later releases
 */
export const getUniqueNameForBrandFont = (brandId, defaultName, fontNamesToExclude = []) => {
  const brands = getBrandsStateFromStore(),
    brandFontIds = brands.brandContent[brandId].fonts.ids,
    brandFonts = brands.fonts,
    brandFontNamesMap = buildBrandFontNamesMap(brandFontIds, brandFonts);

  removeNamesFromFontNameMap(brandFontNamesMap, fontNamesToExclude);
  return makeStringUnique(defaultName, brandFontNamesMap);
};

/**
 * given a set of brand font Ids, create a hashmap with the key being the name of each brand inside the Ids array
 * @param {Array} brandFontIds
 * @param {Object} brandFonts
 * @returns {Object}
 */
const buildBrandFontNamesMap = (brandFontIds, brandFonts) => {
  let brandFontName = null,
    brandFontNamesMap = {};

  for (let id of brandFontIds) {
    brandFontName = brandFonts[id].brandFontName;
    brandFontNamesMap[brandFontName] = 1;
  }

  return brandFontNamesMap;
};

/**
 *
 * @param {string} brandId
 * @param brandsSlice
 * @return {boolean}
 */
export const doesBrandHaveFonts = (brandId, brandsSlice = getBrandsStateFromStore()) => {
  return brandsSlice.brandContent[brandId].fonts.ids.length > 0;
};

export const showAddOrUpdateFontLoading = (loadingText) => {
  showLoading(ADD_UPDATE_BRAND_FONT_LOADING_KEY, {
    text: loadingText,
    progress: PROGRESS_ALMOST_COMPLETE,
  });
};

export const hideAddOrUpdateFontLoading = () => {
  hideLoading(ADD_UPDATE_BRAND_FONT_LOADING_KEY);
};

/**
 *
 * @param {number} idbrandFont
 * @param {string} variation
 * @return {String}
 */
export const getBrandFontPreviewImageUrl = (idbrandFont, variation = '') => {
  return PMW.util.repoURL('brandfonts/' + BRAND_FONT_PREVIEW_URL_PREFIX + idbrandFont + variation + '.png', getWriteBucket()) + `ts=${new Date().getTime()}`;
};

/**
 * Adds the data received from server for a brand's activity into the brands reducer slice
 * @param state The brands reducer slice
 * @param {Array} dataFromServer
 * @param {string} brandId
 */
const addBrandActivityLogDataFromBackendToStore = (state, dataFromServer, brandId) => {
  state.brandActivityMap[brandId].loaded = BRANDS_LOADING_STATES.LOADED;
  state.brandActivityMap[brandId].ids = dataFromServer.map((brandActivity) => brandActivity.idbrandActivity);
  state.brandActivity = {...state.brandActivity, ...objectArrayToHashmap(dataFromServer, BRAND_CONTENT_TYPE_ID_KEYS.BRAND_ACTIVITY)};
  populateMetaDataForActivitiesInStore(state, dataFromServer);
};

/**
 * parses and populates the JSON data stored for a brand activity
 * which contains relevant details about the brand item that was changed in the brand activity
 * @param state The brands reducer slice
 * @param {Array.<Object>} dataFromServer
 */
const populateMetaDataForActivitiesInStore = (state, dataFromServer) => {
  dataFromServer.forEach((brandActivity) => {
    state.brandActivityDetails[brandActivity.idbrandActivity] = JSON.parse(brandActivity.activityMetaData);
  });
};

/**
 *
 * @param state The mystuff-brands reducer slice
 * @param {string} brandId
 * @returns {boolean}
 */
const doesBrandActivityContentExistForBrandId = (state, brandId) => {
  return !!state.brandActivityMap[brandId];
};

/**
 *
 * @param {string} brandCreationSource
 */
export const trackGAEventForNewBrand = (brandCreationSource) => {
  PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.NEW_BRAND, {[PMW.gtm.GA4_EVENT_PARAM_NAMES.SOURCE]: brandCreationSource});
};

export const trackGAEventForDeleteBrand = () => {
  PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.DELETE_BRAND);
};

export const trackGAEventForNewBrandLogo = () => {
  PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.ADD_LOGO);
};

/**
 *
 * @param {number} colorType the type of the brand color that was created. One of the BRAND_COLOR_TYPES.* constants
 */
export const trackGAEventForNewBrandColor = (colorType) => {
  let colorTypeName = null;
  switch (colorType) {
    case BRAND_COLOR_TYPES.PRIMARY:
      colorTypeName = BRANDS_ANALYTICS_ACTION_LABELS.PRIMARY_COLOR;
      break;
    case BRAND_COLOR_TYPES.SECONDARY:
      colorTypeName = BRANDS_ANALYTICS_ACTION_LABELS.SECONDARY_COLOR;
      break;
    case BRAND_COLOR_TYPES.OTHER:
      colorTypeName = BRANDS_ANALYTICS_ACTION_LABELS.OTHER_COLOR;
      break;
  }

  PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.ADD_COLOR, {[PMW.gtm.GA4_EVENT_PARAM_NAMES.TYPE]: colorTypeName});
};

/**
 *
 * @param {Object} [newBrandFont]
 * @param {boolean} [newBrandFont.isInternalFont]
 * @param {number} [newBrandFont.fontType]
 * @param {number} [newBrandFont.fontSize]
 */
export const trackGAEventForNewBrandFont = (newBrandFont) => {
  const addFontEventSource = newBrandFont.isInternalFont ? BRANDS_ANALYTICS_ACTION_LABELS.PMW_FONT : BRANDS_ANALYTICS_ACTION_LABELS.USER_FONT;
  PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.ADD_FONT, {[PMW.gtm.GA4_EVENT_PARAM_NAMES.SOURCE]: addFontEventSource});
};

export const trackGAEventForBrandColorUpdated = () => {
  PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.UPDATE_COLOR);
};

export const trackGAEventForPrimaryLogoChanged = () => {
  PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.MAKE_PRIMARY_LOGO);
};

export const trackGAEventForBrandAssetDeleted = (brandAssetType) => {
  switch (brandAssetType) {
    case BRAND_ASSETS.BRAND_LOGOS:
      PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.DELETE_LOGO);
      break;

    case BRAND_ASSETS.BRAND_COLORS:
      PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.DELETE_COLOR);
      break;

    case BRAND_ASSETS.BRAND_FONTS:
      PMW.gtm.trackGA4CustomEvent(BRANDS_ANALYTICS_ACTIONS.DELETE_FONT);
      break;
  }
};

/**
 * Sends an ajax request to the server to verify if the brand is accessible by the currently logged-in user
 */
export const validateActiveBrandForCurrentUser = async () => {
  const activeBrandId = getBrandsStateFromStore().activeBrandId;

  if (!activeBrandId) {
    return;
  }

  return await PMW.readLocal('brand/doesUserHaveAccessToBrand', {brandId: activeBrandId});
};

export const openUpsellingDialogForBrands = () => {
  PMW.showPremiumOnlyFeatureDialog(PMW.PREMIUM_ONLY_FEATURE_NAME_BRANDS);
};

export const openPremiumPlusUpsellingDialogForBrands = () => {
  PMW.showPremiumOnlyFeatureDialog(PMW.PREMIUM_PLUS_ONLY_FEATURE_NAME_MORE_BRANDS);
}

export const canUserAccessBrandKits = () => {
  return isUserPremium();
}

/**
 *
 *
 * @return {boolean}
 */
export const doesUserNeedUpsellingForBrandKits = () => {
  const premiumLevel = getUserPremiumLevel();

  if (isPAYGUser(premiumLevel)) {
    return true;
  }

  if (isUserPremiumPlus()) {
    return false;
  }

  return getBrandsFromStore().length >= PREMIUM_USER_BRANDS_LIMIT;
}


export const onNewBrandKitBtnClick = (onCreateCallback) => {
  if (doesUserNeedUpsellingForBrandKits()) {
    openPremiumPlusUpsellingDialogForBrands();
    return;
  }

  if (typeof onCreateCallback === 'function') {
    onCreateCallback();
  }
}