import React, {ReactElement, useState} from 'react';
import type {GridEmailSignupFormItemStorage} from '@Components/email-signup-form-grid/components/email-signup-form-grid';
import {Icon} from '@Components/icon-v2';
import {FlatIconColor, FlatIconColorType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Tooltip, TOOLTIP_POSITION} from '@Components/tooltip';
import {Text, TextSize} from '@Components/text';
import {pollForEmailSignupFormPreview} from '@Libraries/email-signup-form-grid-library';
import styles from './email-signup-form-grid-item-preview.module.scss';

/**
 * @author < Haseeb Chaudhry haseebch@250mils.com >
 */

interface SignupFormGridItemPreviewProps {
  gridItem: GridEmailSignupFormItemStorage;
}

export function EmailSignupFormGridItemPreview({gridItem}: SignupFormGridItemPreviewProps) {
  const [showToolTip, setShowToolTip] = useState(false);

  const pollForEmailSignupPreview = async () => {
    await pollForEmailSignupFormPreview(gridItem.id);
  };

  const getPreviewSignupFormDiv = () => {
    return (
      <div className={`hover-transition-all ${styles.imageContainer} flex-align-items-center`}>
        <progress className={`${styles.iconCircularLoader} icon-circular-loader js-icon-circular-loader _hidden`} />
        <img
          className={`${styles.previewImage} js-signup-image-preview`}
          title={window.i18next.t('pmwjs_signup_form')}
          src={`${gridItem.imageUrl}`}
          onError={pollForEmailSignupPreview}
          alt={window.i18next.t('pmwjs_signup_form')}
        />
      </div>
    );
  };

  const redirectToEditPage = (id: string) => {
    window.location.href = window.PMW.util.site_url(`formmaker/load/${id}`);
  };

  const getEmailCollectedDiv = (): ReactElement => {
    const pillClasses = 'flex-row-align-center spacing-p-l-2 bg-neutral-0 spacing-p-r-2 spacing-p-b-1 spacing-p-t-1 flex-content-center radius-round';
    return (
      <div onMouseOver={onMouseOver} onMouseOut={onMouseLeave} className={`${pillClasses} ${styles.emailsCollected}`}>
        <Icon icon="icon-group" size={IconSize.SIZE_ICON_16} className="content-body" />
        <Text val={gridItem.subscribedEmailsCount.toString()} className="js-emails-collected spacing-m-l-1" size={TextSize.XXSMALL} bold />
      </div>
    );
  };

  const renderOverlay = () => {
    return (
      <a className={styles.overlay}>
        <Icon
          icon="icon-pencil"
          className={styles.overlayIcon}
          size={IconSize.SIZE_ICON_24}
          shape={IconShape.CIRCLE}
          type={IconType.FLAT}
          flatIconType={{color: FlatIconColor.NEUTRAL, type: FlatIconColorType.DEFAULT}}
        />
      </a>
    );
  };

  const onMouseOver = () => {
    setShowToolTip(true);
  };

  const onMouseLeave = () => {
    setShowToolTip(false);
  };

  const getToolTip = () => {
    if (showToolTip) {
      return (
        <Tooltip
          id="email-collected"
          className={styles.tooltip}
          position={TOOLTIP_POSITION.BOTTOM_RIGHT}
          text={
            <Text
              val={window.i18next.t('pmwjs_emails_collected', {
                emailsCount: gridItem.subscribedEmailsCount,
              })}
              size={TextSize.XXSMALL}
            />
          }
        />
      );
    }
    return undefined;
  };

  return (
    <div
      className={`${styles.formPreview} flex-1 hover-transition-all signup-form-grid-item`}
      data-id-signup-form={gridItem.id}
      onClick={redirectToEditPage.bind(null, gridItem.id)}
    >
      {getToolTip()}
      {renderOverlay()}
      {getEmailCollectedDiv()}
      {getPreviewSignupFormDiv()}
    </div>
  );
}
