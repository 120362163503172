import type {CSSProperties} from 'react';
import React, {useState} from 'react';
import {escapeHTML} from '@Utils/string.util';
import type {UserImageSourceType, UserImageVOResponse} from '@Libraries/user-image-library';
import type {GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';
import type {GridItemProps} from '../grid-item';
import {GRID_ITEM_TYPE} from '../grid-item';

export interface GridUserImageItemStorage extends GridMediaItemStorage {
  type: GRID_ITEM_TYPE.USER_IMAGE;
  hashedFilename: string;
  extension: string;
  uploaderName: string;
  uploaderId: number;
  source: UserImageSourceType;
  hasTransparency: boolean;
  gridItemWidth?: number;
  isHidden?: boolean;
}

interface GridUserImageItemProps extends GridItemProps {
  thumbSrc: string;
  uploaderName: string;
  gridItemWidth?: number;
  isHidden?: boolean;
}

export const getGridUserImageItemStorageForUserImageVO = (userImageVO: UserImageVOResponse): GridUserImageItemStorage => {
  return {
    type: GRID_ITEM_TYPE.USER_IMAGE,
    id: userImageVO.filename,
    hashedFilename: userImageVO.filename,
    extension: userImageVO.ext,
    uploaderName: userImageVO.uploaderName,
    uploaderId: parseInt(userImageVO.uploaderId, 10),
    hasTransparency: userImageVO.hasTransparency,
    source: userImageVO.src,
    isHidden: false,
    width: userImageVO.width,
    height: userImageVO.height,
  };
};

export function GridUserImageItem({uploaderName = '', ...props}: GridUserImageItemProps): React.ReactElement | null {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  if (props.isHidden) {
    return null;
  }

  const inlineStyles: CSSProperties = {...props.style};
  if (props.gridItemWidth) {
    inlineStyles.width = props.gridItemWidth;
  }

  return (
    <GridMediaItem
      {...props}
      style={inlineStyles}
      isImageLoaded={isImageLoaded}
      className={`${props.className ?? ''}`}
      title={uploaderName ? `${window.i18next.t('pmwjs_added_by')} ${escapeHTML(uploaderName)}` : ''}
      paddedThumb={false}
      thumb={
        <img
          className="radius-12"
          onLoad={(): void => {
            return setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          alt={window.i18next.t('pmwjs_image')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
