import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {GammaEffectObject} from '@PosterWhiteboard/items/image-item/gamma-effect.class';
import {GammaEffect} from '@PosterWhiteboard/items/image-item/gamma-effect.class';
import type {RGB} from '@Utils/color.util';
import {rgbToHexString} from '@Utils/color.util';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {loadImageAsync} from '@Utils/image.util';
import type * as Fabric from '@postermywall/fabricjs-2';
import {mapNumberToRange} from '@Utils/math.util';
import {fabricObjectToDataUrl} from '@Utils/fabric.util';
import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import {GammaColorType} from '@Components/poster-editor/components/poster-editing-side-panel/components/base-gamma-option/base-gamma-option.types';
import {FabricImage, filters, Path} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';

export const MIN_GRADIENT_EDGE_THICKNESS = 1;
export const MAX_GRADIENT_EDGE_THICKNESS = 150;

export const DEFAULT_CURVED_ROUNDESS = 7;

export enum EdgeType {
  NONE = 0,
  OVAL = 1,
  RECTANGLE = 2,
  CURVED = 3,
  SCRATCHED = 4,
  TORN_PAPER = 5,
  CIRCULAR = 6,
  HEART = 7,
  GRADIENT = 8,
}

export enum FilterOrders {
  DEFAULT,
  IMAGEBACKGROUND,
}

export interface ItemEffectsObject {
  brightness: number;
  blackAndWhite: boolean;
  blur: number;
  contrast: number;
  edgeType: EdgeType;
  edgeThickness: number;
  gamma: GammaEffectObject;
  invert: boolean;
  multiply: boolean;
  multiplyColor: RGB;
  multiplyOpacity: number;
  removeColor: boolean;
  removeColorValue: RGB;
  removeColorThickness: number;
  pixelate: number;
  saturation: number;
  sepia: boolean;
  tint: boolean;
  tintColor: RGB;
  tintOpacity: number;
  vibrance: number;
}

export class ItemEffects extends CommonMethods {
  private item: ItemType;
  private readonly filterOrderType: FilterOrders = FilterOrders.DEFAULT;

  public brightness = 0;
  public blackAndWhite = false;
  public blur = 0;
  public contrast = 0;
  public edgeType = EdgeType.NONE;
  public edgeThickness = 7;
  public gamma: GammaEffect;
  public invert = false;
  public multiply = false;
  public multiplyColor: RGB = [140, 133, 140];
  public multiplyOpacity = 0;
  public removeColor = false;
  public removeColorValue: RGB = [255, 255, 255];
  public removeColorThickness = 0.3;
  public pixelate = 0;
  public saturation = 0;
  public sepia = false;
  public tint = false;
  public tintColor: RGB = [0, 0, 0];
  public tintOpacity = 0.5;
  public vibrance = 0;

  constructor(item: ItemType, filterOrderType = FilterOrders.DEFAULT) {
    super();
    this.gamma = new GammaEffect();
    this.item = item;
    this.filterOrderType = filterOrderType;
  }

  public toObject(): ItemEffectsObject {
    return {
      brightness: this.brightness,
      blackAndWhite: this.blackAndWhite,
      blur: this.blur,
      contrast: this.contrast,
      edgeType: this.edgeType,
      edgeThickness: this.edgeThickness,
      gamma: this.gamma.toObject(),
      invert: this.invert,
      multiply: this.multiply,
      multiplyColor: this.multiplyColor,
      multiplyOpacity: this.multiplyOpacity,
      removeColor: this.removeColor,
      removeColorValue: this.removeColorValue,
      removeColorThickness: this.removeColorThickness,
      pixelate: this.pixelate,
      saturation: this.saturation,
      sepia: this.sepia,
      tint: this.tint,
      tintColor: this.tintColor,
      tintOpacity: this.tintOpacity,
      vibrance: this.vibrance,
    };
  }

  public copyVals(obj: DeepPartial<ItemEffectsObject> = {}): void {
    const {gamma, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.gamma.copyVals(gamma);
  }

  public async applyBorderEffects(): Promise<void> {
    if (!(this.item.fabricObject instanceof FabricImage)) {
      console.error('Border Effects can only be applied on fabric object of type Image');
      return;
    }

    await this.applyBorderEffect();
    this.item.fabricObject.applyFilters();
  }

  public applyItemEffects(): void {
    if (!(this.item.fabricObject instanceof FabricImage)) {
      console.error('Effects can only be applied on fabric object of type Image');
      return;
    }

    if (this.filterOrderType === FilterOrders.DEFAULT) {
      this.applyEffectsInDefaultOrder();
    } else if (this.filterOrderType === FilterOrders.IMAGEBACKGROUND) {
      this.applyEffectsInImageBackgroundOrder();
    } else {
      console.error(`Unknown filterOrderType ${this.filterOrderType}`);
    }

    this.item.fabricObject.applyFilters();
  }

  protected applyEffectsInDefaultOrder(): void {
    if (!(this.item.fabricObject instanceof FabricImage)) {
      console.error('Effects can only be applied on fabric object of type Image');
      return;
    }

    // TODO: Ask afifa how did she make this work for edge effects? Commit 30421
    // if (this.item.fabricObject.filters.length) {
    //   this.item.fabricObject.filters = this.item.fabricObject.filters.filter((effect) => {
    //     return effect.image || effect.threshold;
    //   });
    // }
    while (this.item.fabricObject.filters.length) {
      this.item.fabricObject.filters.pop();
    }

    if (this.blackAndWhite) {
      this.item.fabricObject.filters.push(new filters.Grayscale());
    }

    if (this.sepia) {
      this.item.fabricObject.filters.push(new filters.Sepia());
    }

    if (this.invert) {
      this.item.fabricObject.filters.push(new filters.Invert());
    }

    if (this.blur) {
      this.item.fabricObject.filters.push(
        new filters.Blur({
          blur: this.blur,
        })
      );
    }

    if (this.pixelate) {
      this.item.fabricObject.filters.push(
        new filters.Pixelate({
          blocksize: this.pixelate,
        })
      );
    }

    if (this.contrast) {
      this.item.fabricObject.filters.push(
        new filters.Contrast({
          contrast: this.contrast,
        })
      );
    }

    if (this.vibrance) {
      this.item.fabricObject.filters.push(
        new filters.Vibrance({
          vibrance: this.vibrance,
        })
      );
    }

    if (this.saturation) {
      this.item.fabricObject.filters.push(
        new filters.Saturation({
          saturation: this.saturation,
        })
      );
    }
    if (this.gamma) {
      if (this.gamma.isEnabled) {
        this.item.fabricObject.filters.push(
          new filters.Gamma({
            gamma: [this.gamma.red, this.gamma.green, this.gamma.blue],
          })
        );
      }
    }

    if (this.tint) {
      this.item.fabricObject.filters.push(
        new filters.BlendColor({
          color: rgbToHexString(this.tintColor),
          mode: 'tint',
          alpha: this.tintOpacity,
        })
      );
    }

    if (this.removeColor) {
      this.item.fabricObject.filters.push(
        new filters.RemoveColor({
          distance: this.removeColorThickness,
          color: rgbToHexString(this.removeColorValue),
        })
      );
    }

    if (this.multiply) {
      this.item.fabricObject.filters.push(
        new filters.BlendColor({
          color: rgbToHexString(this.multiplyColor),
          mode: 'multiply',
          alpha: 1 - this.multiplyOpacity,
        })
      );
    }

    if (this.brightness !== 0) {
      this.item.fabricObject.filters.push(
        new filters.Brightness({
          brightness: this.brightness,
        })
      );
    }
  }

  private applyEffectsInImageBackgroundOrder(): void {
    if (!(this.item.fabricObject instanceof FabricImage)) {
      console.error('Effects can only be applied on fabric object of type Image');
      return;
    }

    while (this.item.fabricObject.filters.length) {
      this.item.fabricObject.filters.pop();
    }

    if (this.blackAndWhite) {
      this.item.fabricObject.filters.push(new filters.Grayscale());
    }

    if (this.sepia) {
      this.item.fabricObject.filters.push(new filters.Sepia());
    }

    if (this.invert) {
      this.item.fabricObject.filters.push(new filters.Invert());
    }

    if (this.tint) {
      this.item.fabricObject.filters.push(
        new filters.BlendColor({
          color: rgbToHexString(this.tintColor),
          mode: 'tint',
          alpha: this.tintOpacity,
        })
      );
    }

    if (this.multiply) {
      this.item.fabricObject.filters.push(
        new filters.BlendColor({
          color: rgbToHexString(this.multiplyColor),
          mode: 'multiply',
          alpha: 1 - this.multiplyOpacity,
        })
      );
    }

    if (this.brightness !== 0) {
      this.item.fabricObject.filters.push(
        new filters.Brightness({
          brightness: this.brightness,
        })
      );
    }

    if (this.contrast) {
      this.item.fabricObject.filters.push(
        new filters.Contrast({
          contrast: this.contrast,
        })
      );
    }

    if (this.saturation) {
      this.item.fabricObject.filters.push(
        new filters.Saturation({
          saturation: this.saturation,
        })
      );
    }

    if (this.vibrance) {
      this.item.fabricObject.filters.push(
        new filters.Vibrance({
          vibrance: this.vibrance,
        })
      );
    }

    if (this.blur) {
      this.item.fabricObject.filters.push(
        new filters.Blur({
          blur: this.blur,
        })
      );
    }

    if (this.pixelate) {
      this.item.fabricObject.filters.push(
        new filters.Pixelate({
          blocksize: this.pixelate,
        })
      );
    }

    if (this.gamma) {
      if (this.gamma.isEnabled) {
        this.item.fabricObject.filters.push(
          new filters.Gamma({
            gamma: [this.gamma.red, this.gamma.green, this.gamma.blue],
          })
        );
      }
    }

    if (this.removeColor) {
      this.item.fabricObject.filters.push(
        new filters.RemoveColor({
          distance: this.removeColorThickness,
          color: rgbToHexString(this.removeColorValue),
        })
      );
    }
  }

  public async applyEdgeEffectsToImageElement(img: HTMLImageElement): Promise<HTMLImageElement> {
    if (this.doesEdgeEffectNeedClipping()) {
      return this.applyClippingToImage(img);
    }

    if (this.edgeType !== EdgeType.NONE) {
      return this.applyBitmapBasedBorderEffectToImage(img);
    }
    return img;
  }

  private async applyClippingToImage(img: HTMLImageElement): Promise<HTMLImageElement> {
    const imageObject = new FabricImage(img, {});
    imageObject.set({
      clipPath: this.getCurvedBorder(imageObject),
    });
    return loadImageAsync(fabricObjectToDataUrl(imageObject));
  }

  private async applyBitmapBasedBorderEffectToImage(img: HTMLImageElement): Promise<HTMLImageElement> {
    if (this.isBitmapBasedEffect()) {
      const imageObject = new FabricImage(img, {});
      const edgeEffectImageElement = await loadImageAsync(this.getEdgeEffectImageURL());
      let fImage = new FabricImage(edgeEffectImageElement, {});

      if (this.edgeType === EdgeType.GRADIENT) {
        if (MAX_GRADIENT_EDGE_THICKNESS !== this.edgeThickness) {
          fImage = fImage.cloneAsImage({
            height: fImage.height * ((MAX_GRADIENT_EDGE_THICKNESS - this.edgeThickness) / MAX_GRADIENT_EDGE_THICKNESS),
          });
          imageObject.filters.push(
            new filters.BlendImage({
              image: fImage,
            })
          );
        }
      } else {
        imageObject.filters.push(
          new filters.BlendImage({
            image: fImage,
          })
        );
      }
      imageObject.applyFilters();
      return loadImageAsync(fabricObjectToDataUrl(imageObject));
    }

    return img;
  }

  // TODO: This code is duplicated
  public async applyBorderEffect(): Promise<void> {
    if (!(this.item.fabricObject instanceof FabricImage)) {
      console.error('Effects can only be applied on fabric object of type Image');
      return;
    }

    if (this.isBitmapBasedEffect()) {
      const edgeEffectImageElement = await loadImageAsync(this.getEdgeEffectImageURL());
      let fImage = new FabricImage(edgeEffectImageElement, {});

      if (this.edgeType === EdgeType.GRADIENT) {
        if (MAX_GRADIENT_EDGE_THICKNESS !== this.edgeThickness) {
          fImage = fImage.cloneAsImage({
            height: fImage.height * ((MAX_GRADIENT_EDGE_THICKNESS - this.edgeThickness) / MAX_GRADIENT_EDGE_THICKNESS),
          });
          this.item.fabricObject.filters.push(
            new filters.BlendImage({
              image: fImage,
            })
          );
        }
      } else {
        this.item.fabricObject.filters.push(
          new filters.BlendImage({
            image: fImage,
          })
        );
      }
    }
  }

  private isBitmapBasedEffect(): boolean {
    return this.edgeType !== EdgeType.NONE && !this.doesEdgeEffectNeedClipping();
  }

  private getEdgeEffectImageURL(): string {
    let borderSuffix = '-small';
    if (this.item.page.poster.isHighRes) {
      borderSuffix = '';
    }

    return window.PMW.util.asset_url(`postermaker/imageborders/${this.edgeType.toString() + borderSuffix}.png`);
  }

  /**
   * Whether the edge effect is applied by clipTo function or not
   * @return {boolean}
   */
  public doesEdgeEffectNeedClipping(): boolean {
    return this.edgeType === EdgeType.CURVED;
  }

  public hasEdgeTypeForStroke(): boolean {
    return this.edgeType === EdgeType.CIRCULAR || this.edgeType === EdgeType.HEART;
  }

  public getBorderForEdgeEffect(edgeType: EdgeType): BorderType {
    if (this.item.gitype !== ITEM_TYPE.IMAGE) {
      return this.item.border.solidBorderType;
    }

    if (!this.item.border.hasBorder()) {
      return BorderType.NONE;
    }

    if (edgeType === EdgeType.CIRCULAR || edgeType === EdgeType.HEART) {
      if (this.edgeType === EdgeType.HEART || this.edgeType === EdgeType.CIRCULAR) {
        return this.item.border.solidBorderType;
      }
      return BorderType.STROKE_BORDER;
    }

    if (this.item.border.solidBorderType === BorderType.STROKE_BORDER) {
      return BorderType.RECTANGLE_BORDER;
    }

    return this.item.border.solidBorderType;
  }

  /**
   * Uses the Canvas' clipping feature to draw a rounded rectangle over the image which results in a final image that
   * has curved edges. Curved edge values stored in the GraphicItemImageVO range from 7 to 28 due to legacy support.
   * In the implementation in this function, we map this range onto values from 2 to 54, so that the resulting value
   * is always between 0.1 and 0.5. We multiple this value with the smaller dimension of the image to get a curve
   * radius that is always between 10% and 50% of the smaller dimension of the image. This ensures that there are no
   * rendering bugs which can occur when using quadraticCurveTo() to draw curves; this technique doesn't work if the
   * radius of the curve is greater than half of the smaller dimension of the image.
   * @private
   */
  private getCurvedBorder(fabricObject: Fabric.FabricImage): Fabric.Path {
    // radius
    const w = fabricObject.width;
    const h = fabricObject.height;
    const x = -1 * (w / 2);
    const y = -1 * (h / 2);

    const r = mapNumberToRange(this.edgeThickness, 2, 54, 0, 1) * Math.min(w, h);

    const path = `M${x + r},${y}L${x + w - r},${y}Q${x + w},${y},${x + w},${y + r}L${x + w},${y + h - r}Q${x + w},${y + h},${x + w - r},${y + h}L${x + r},${y + h}Q${x},${
      y + h
    },${x},${y + h - r}L${x},${y + r}Q${x},${y},${x + r},${y}`;

    return new Path(path);
  }

  public getPartialUpdatedGammaObjectFromParams(value: number, type: GammaColorType): DeepPartial<GammaEffectObject> {
    switch (type) {
      case GammaColorType.BLUE:
        return {
          blue: value,
        };
      case GammaColorType.GREEN:
        return {
          green: value,
        };
      case GammaColorType.RED:
        return {
          red: value,
        };
      default:
        return {};
    }
  }
}
