import type {Point} from '@Utils/math.util';
import {getDistanceBetweenPoints, getVectorAvg} from '@Utils/math.util';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';
import {ZOOM_MAX} from '@PosterWhiteboard/poster/poster-scaling.class';
import {MARGIN_RIGHT} from '@Components/poster-whiteboard/components/poster-horizontal-scroll';
import {getMarginBottom} from '@Components/poster-whiteboard/components/poster-vertical-scroll';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';
import type {CanvasEvents, FabricObject, Canvas} from '@postermywall/fabricjs-2';
import {Point as FabricPoint, config, util} from '@postermywall/fabricjs-2';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

enum Interactions {
  DRAG = 'drag',
  ZOOM = 'zoom',
}

enum PanningDirections {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

const ANIMATION_DURATION = 150;
const TOUCH_POINTS_DISTANCE_THRESHOLD = 15;

let lastPanPosition: Point | undefined;
let isPanning: NodeJS.Timeout;
let firstTouch = false;
let startTouches: Point[] | undefined;
let initialScale!: number;
let lastZoomCenter: Point | undefined;
let targetOnTouchStart: FabricObject | undefined;
let interaction: Interactions | undefined;
let isGesturing: NodeJS.Timeout;
let nthZoom = 0;

export class CanvasPanZoom extends CommonMethods {
  public page: Page;
  public animationStartedOn = 0;
  public isAnimationPlaying = false;
  public initialZoomValue = 0;
  public isTouchGestureBeingApplied = false;
  public isTwoFingerZooming = false;
  public startDistance = 0;
  public lastTouches: Point[] = [];

  public constructor(page: Page) {
    super();
    this.page = page;
  }

  public onMouseUp(): void {
    this.updateInteraction(undefined);
  }

  private updateInteraction(newInteraction: Interactions | undefined): void {
    if (interaction !== newInteraction && interaction === Interactions.ZOOM) {
      this.handleZoomEnd();
    }
    interaction = newInteraction;
  }

  private handleZoomEnd(): void {
    this.isTouchGestureBeingApplied = false;
    this.lastTouches = [];
    if (this.page.poster.scaling.scale < this.page.poster.scaling.getFitToScreenScale() && !this.isAnimationPlaying) {
      this.initialZoomValue = this.page.poster.scaling.scale;
      this.animationStartedOn = Date.now();
      this.isAnimationPlaying = true;
      util.requestAnimFrame(this.applyZoomAnimation.bind(this));
    } else {
      this.page.poster.scaling.updateIsFitToScreenByScale(this.page.poster.scaling.scale);
      this.page.poster.redux.updateReduxData();
    }
  }

  public onCanvasSingleFingerPan(gestureData: HammerInput): void {
    if (!isEditorMobileVariant()) {
      return;
    }
    if (firstTouch) {
      this.updateInteraction(Interactions.DRAG);
    }
    clearTimeout(isPanning);

    isPanning = setTimeout(() => {
      lastPanPosition = undefined;
    }, 100);
    if (this.page.poster.drawing.isDrawModeOn || config.isCanvasTwoFingerPanning || !gestureData.pointers) {
      return;
    }

    if (this.page.poster.scaling.scale <= this.page.poster.scaling.getFitToScreenScale()) {
      return;
    }
    const touch = {x: gestureData.pointers[0].pageX as number, y: gestureData.pointers[0].pageY as number};
    if (lastPanPosition && this.canStartPan()) {
      this.page.poster.pan.panCanvas(touch, lastPanPosition);
    }
    lastPanPosition = touch;
  }

  private canStartPan(): boolean {
    if (targetOnTouchStart) {
      const canvas = this.page.fabricCanvas as Canvas;
      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length > 0) {
        for (const activeObject of activeObjects) {
          if (activeObject === targetOnTouchStart) {
            return false;
          }
        }
      }
    }
    return true;
  }

  public onCanvasTouchGesture(gestureData: HammerInput): void {
    this.isTouchGestureBeingApplied = true;
    if (firstTouch) {
      this.updateInteraction(Interactions.ZOOM);
    }
    config.isCanvasTwoFingerPanning = true;

    clearTimeout(isGesturing);

    isGesturing = setTimeout(() => {
      nthZoom = 0;
      lastZoomCenter = undefined;
      this.isTwoFingerZooming = false;
    }, 100);

    if (!gestureData.pointers) {
      return;
    }

    const canvasContainer = this.page.getCanvasContainer();
    if (!canvasContainer) {
      return;
    }

    nthZoom += 1;

    const currentTouches = this.targetTouches(gestureData.pointers as Touch[]);

    if (firstTouch) {
      startTouches = [...currentTouches];
      this.isTwoFingerZooming = false;
      this.startDistance = getDistanceBetweenPoints(startTouches[0], startTouches[1]);
      this.lastTouches = [...startTouches];
    }

    // gestureData.self is the inner eventjs event, and the library, and hence types, aren't available in ts.
    // eslint-disable-next-line
    const touchesOnCanvas = gestureData.pointers as Array<Point>;
    if (!touchesOnCanvas) {
      return;
    }
    const centerOfTouchesOnCanvas = this.getTouchCenter(touchesOnCanvas);

    if (lastZoomCenter === undefined) {
      lastZoomCenter = centerOfTouchesOnCanvas;
    }

    // the first touch events are thrown away since they are not precise
    if (nthZoom > 3 && touchesOnCanvas && startTouches) {
      // Determining if the user is two-finger panning or zooming
      if (
        !this.isTwoFingerZooming &&
        Math.abs(getDistanceBetweenPoints(touchesOnCanvas[0], touchesOnCanvas[1]) - this.startDistance) > TOUCH_POINTS_DISTANCE_THRESHOLD &&
        !this.isMoveDirectionSame(currentTouches)
      ) {
        this.isTwoFingerZooming = true;
      }
      const scale = this.calculateScale(startTouches, this.targetTouches(gestureData.pointers as Touch[]));
      let zoom = initialScale * scale;

      if (zoom > ZOOM_MAX) {
        zoom = ZOOM_MAX;
      }

      const whiteboardContainer = window.document.getElementById('poster-whiteboard-container');
      if (!whiteboardContainer) {
        return;
      }

      let newCanvasTotalWdith = this.page.poster.width * zoom;
      let newCanvasTotalHeight = this.page.poster.height * zoom;

      const newCanvasVisibleWidth = newCanvasTotalWdith < whiteboardContainer.clientWidth ? newCanvasTotalWdith : whiteboardContainer.clientWidth;
      const newCanvasVisibleHeigth = newCanvasTotalHeight < whiteboardContainer.clientHeight ? newCanvasTotalHeight : whiteboardContainer.clientHeight;

      if (this.isTwoFingerZooming) {
        if (this.page.fabricCanvas.width !== newCanvasVisibleWidth || this.page.fabricCanvas.height !== newCanvasVisibleHeigth) {
          this.page.fabricCanvas.setDimensions({
            width: newCanvasVisibleWidth,
            height: newCanvasVisibleHeigth,
          });
        }

        const zoomPoint = {
          x: centerOfTouchesOnCanvas.x,
          y: centerOfTouchesOnCanvas.y,
        };
        this.page.fabricCanvas.zoomToPoint(new FabricPoint(zoomPoint.x, zoomPoint.y), zoom);
      }

      newCanvasTotalWdith = this.page.poster.width * (this.isTwoFingerZooming ? zoom : this.page.poster.scaling.scale);
      newCanvasTotalHeight = this.page.poster.height * (this.isTwoFingerZooming ? zoom : this.page.poster.scaling.scale);

      const vpt = this.page.fabricCanvas.viewportTransform;
      const fitToScreenScale = this.page.poster.scaling.getFitToScreenScale();
      const maxTransformDisplacement = {
        x: this.page.fabricCanvas.getWidth() - this.page.poster.width * (this.isTwoFingerZooming ? zoom : this.page.poster.scaling.scale),
        y: this.page.fabricCanvas.getHeight() - this.page.poster.height * (this.isTwoFingerZooming ? zoom : this.page.poster.scaling.scale),
      };

      if (zoom < fitToScreenScale) {
        vpt[4] = 0;
        vpt[5] = 0;
      } else {
        const panningValues = this.page.poster.pan.getPanningValues(centerOfTouchesOnCanvas, lastZoomCenter);
        vpt[4] = Math.max(vpt[4] - panningValues.x, maxTransformDisplacement.x);
        vpt[5] = Math.max(vpt[5] - panningValues.y, maxTransformDisplacement.y);

        if (vpt[4] >= 0) {
          vpt[4] = 0;
        } else if (vpt[4] < maxTransformDisplacement.x) {
          vpt[4] = maxTransformDisplacement.x;
        }
        if (vpt[5] >= 0) {
          vpt[5] = 0;
        } else if (vpt[5] < maxTransformDisplacement.y) {
          vpt[5] = maxTransformDisplacement.y;
        }
      }

      this.page.fabricCanvas.requestRenderAll();

      const horizontalScrollContainerHtmlElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_HORIZONTAL_SCROLL]?.get(0);
      const verticalScrollContainerHtmlElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_VERTICAL_SCROLL]?.get(0);
      if (
        !horizontalScrollContainerHtmlElement ||
        !verticalScrollContainerHtmlElement ||
        !horizontalScrollContainerHtmlElement.children[0] ||
        !verticalScrollContainerHtmlElement.children[0]
      ) {
        return;
      }

      const newCanvasTotalWidthWithScrollMargin = newCanvasTotalWdith + MARGIN_RIGHT;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      horizontalScrollContainerHtmlElement.children[0].style.width = `${newCanvasTotalWidthWithScrollMargin}px`;

      const newCanvasTotalHeightWithScrollMargin = newCanvasTotalHeight + getMarginBottom();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      verticalScrollContainerHtmlElement.children[0].style.height = `${newCanvasTotalHeightWithScrollMargin}px`;

      horizontalScrollContainerHtmlElement.scrollLeft = -vpt[4];
      verticalScrollContainerHtmlElement.scrollTop = -vpt[5];

      if (this.isTwoFingerZooming) {
        this.page.poster.scaling.scale = zoom;
      }
    }

    this.lastTouches = [...currentTouches];
    lastZoomCenter = centerOfTouchesOnCanvas;
    firstTouch = false;
  }

  /**
   * Calculates the touch center of multiple touches
   */
  private getTouchCenter(touches: Point[]): Point {
    return getVectorAvg(touches);
  }

  private targetTouches(touches: Touch[]): Point[] {
    return Array.from(touches).map((touch) => {
      return {
        x: touch.pageX,
        y: touch.pageY,
      };
    });
  }

  private calculateScale(startTouchPoints: Point[], endTouchPoints: Point[]): number {
    const startDistance = getDistanceBetweenPoints(startTouchPoints[0], startTouchPoints[1]);
    const endDistance = getDistanceBetweenPoints(endTouchPoints[0], endTouchPoints[1]);
    return endDistance / startDistance;
  }

  public onCanvasAfterTouchStart(e: CanvasEvents['after:touchstart']): void {
    firstTouch = true;
    initialScale = this.page.poster.scaling.scale;
    lastPanPosition = undefined;
    lastZoomCenter = undefined;

    targetOnTouchStart = e.target;
  }

  private isMoveDirectionSame(touches: Point[]): boolean {
    const direction1 = this.getDirection(this.lastTouches[0], touches[0]);
    const direction2 = this.getDirection(this.lastTouches[1], touches[1]);
    return direction1 === direction2;
  }

  private getDirection(startTouch: Point, currentTouch: Point): string {
    const dx = currentTouch.x - startTouch.x;
    const dy = currentTouch.y - startTouch.y;

    if (Math.abs(dx) > Math.abs(dy)) {
      return dx > 0 ? PanningDirections.RIGHT : PanningDirections.LEFT;
    }
    return dy > 0 ? PanningDirections.DOWN : PanningDirections.UP;
  }

  private applyZoomAnimation(): void {
    const currentAnimationTime = Date.now() - this.animationStartedOn;
    const fitToScreenScale = this.page.poster.scaling.getFitToScreenScale();

    if (currentAnimationTime <= ANIMATION_DURATION && this.isAnimationPlaying) {
      const finalValue = fitToScreenScale;
      const zoomValue = !this.isAnimationPlaying
        ? finalValue
        : util.ease.easeOutQuad(currentAnimationTime, this.initialZoomValue, finalValue - this.initialZoomValue, ANIMATION_DURATION);

      this.updateCanvasZoomAndDimensions(zoomValue);
      this.page.fabricCanvas.renderAll();
      util.requestAnimFrame(this.applyZoomAnimation.bind(this));
    } else if (this.isAnimationPlaying) {
      this.updateCanvasZoomAndDimensions(fitToScreenScale);
      this.page.fabricCanvas.renderAll();
      this.isAnimationPlaying = false;
      this.page.poster.scaling.scale = fitToScreenScale;
      this.page.poster.scaling.updateIsFitToScreenByScale(fitToScreenScale);
      this.page.poster.redux.updateReduxData();
    }
  }

  private updateCanvasZoomAndDimensions(scale: number): void {
    this.page.fabricCanvas.setDimensions({
      width: this.page.poster.width * scale,
      height: this.page.poster.height * scale,
    });
    this.page.fabricCanvas.setZoom(scale);
  }
}
