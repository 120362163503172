import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import type {MergeTag, MergeTagsReduxState} from '@Components/merge-tags/merge-tags.types';

interface AddTagPayload {
  ccid: string;
  mergeTagKey: MergeTag['mergeTagKey'];
}

interface PopulateTagsPayload {
  ccid: string;
  mergeTagKeys: MergeTag['mergeTagKey'][];
  defaultValueHashmap: Record<string, string>;
}

const initialState: MergeTagsReduxState = {
  mergeTagsHashMap: {},
  defaultValueHashMap: {},
};

interface RemoveTagPayload {
  ccid: string;
  mergeTagKey: string;
}

interface UpdateDefaultValuePayload {
  mergeTagKey: string;
  value: string;
}

export const mergeTagsSlice = createSlice({
  name: 'mergeTags',
  initialState,
  reducers: {
    addTagToMap: (state, action: PayloadAction<AddTagPayload>) => {
      const {ccid, mergeTagKey} = action.payload;

      if (!state.mergeTagsHashMap[ccid]) {
        state.mergeTagsHashMap[ccid] = [];
      }

      if (!state.mergeTagsHashMap[ccid].includes(mergeTagKey)) {
        state.mergeTagsHashMap[ccid].push(mergeTagKey);
      }
    },
    populateTagsForComponent: (state, action: PayloadAction<PopulateTagsPayload>) => {
      const {ccid, mergeTagKeys} = action.payload;
      state.mergeTagsHashMap[ccid] = [...new Set(mergeTagKeys)];
    },
    removeTagFromMap: (state, action: PayloadAction<RemoveTagPayload>) => {
      const {ccid, mergeTagKey} = action.payload;

      state.mergeTagsHashMap[ccid] = state.mergeTagsHashMap[ccid].filter((tag) => {
        return tag !== mergeTagKey;
      });

      if (state.mergeTagsHashMap[ccid].length === 0) {
        const {[ccid]: _, ...nonCurrCCIDMap} = state.mergeTagsHashMap;
        state.mergeTagsHashMap = nonCurrCCIDMap;
      }
    },
    updateDefaultValueForTag: (state, action: PayloadAction<UpdateDefaultValuePayload>) => {
      const {mergeTagKey, value} = action.payload;
      state.defaultValueHashMap[mergeTagKey] = value;
    },
    removeAllTags: (state) => {
      state.mergeTagsHashMap = {};
      state.defaultValueHashMap = {};
    },
    removeAllTagsForComponent: (state, action: PayloadAction<{ccid: string}>) => {
      const {ccid} = action.payload;
      if (state.mergeTagsHashMap[ccid]) {
        const {[ccid]: _, ...remainingTags} = state.mergeTagsHashMap;
        state.mergeTagsHashMap = remainingTags;
      }
    },
  },
});

export const {populateTagsForComponent, addTagToMap, removeTagFromMap, updateDefaultValueForTag, removeAllTagsForComponent, removeAllTags} = mergeTagsSlice.actions;
export default mergeTagsSlice.reducer;
