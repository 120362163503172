import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR, TextStyles} from '@PosterWhiteboard/classes/text-styles.class';
import {SyncSubtitleToPosterClock} from '@PosterWhiteboard/items/transcript-item/sync-subtitle-to-poster-clock';
import {rgbToHexString} from '@Utils/color.util';
import type {StartAndEndIndicesOfWord, SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import {FixedLayout, Group, IText, LayoutManager, Point, Rect, type Shadow, Textbox} from '@postermywall/fabricjs-2';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import {ItemAura} from '@PosterWhiteboard/classes/item-aura.class';
import {addFontsAsync} from '@Libraries/font-library';
import type {Word} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word';
import {createAndAddWordFromObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word';
import type {WordObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word.types';
import {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import {SHADOW_REFERENCE_DIMENSION} from '@PosterWhiteboard/items/item/item.types';
import {getSubtitlesWordArrayAfterTextEdited} from '@Libraries/ai-transcript.library';
import {getDefaultSelectedSubtitleTemplateProperties} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles';
import type {DeepPartial} from '@/global';
import type {TranscriptUpdateFromObjectOpts} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {MIN_TIME_GAP_FOR_TRANSCRIPT} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {noop} from '@Utils/general.util';

export const BACKGROUND_PADDING = 12;

export class Subtitle extends CommonMethods {
  public transcriptItem: TranscriptItem;
  public subtitleUID = '';
  public text = '';
  public words: Word[] = [];
  public startTime = 0;
  public endTime = 0;
  public isInitialzed = false;
  public hasUserEdited = false;
  public selectedTemplateId: string = getDefaultSelectedSubtitleTemplateProperties().id;
  public animationStyle: SubtitleTemplateType = getDefaultSelectedSubtitleTemplateProperties().type;
  public sentenceTextStyles: TextStyles = new TextStyles();
  public activeWordTextStyles: TextStyles = new TextStyles();
  public backgroundFill: Fill = new Fill();
  public aura: ItemAura = new ItemAura();
  public backgroundBorderRadius = 0;

  public isCurrentlyActiveSubtitle = false;

  public currentlyActiveWord: Word | undefined;

  public backgroundFabricObject!: Rect;
  public textFabricObject!: Textbox;
  public fabricObject!: Group;
  private readonly syncToPosterClock: SyncSubtitleToPosterClock;

  // CodeReviewTaimurDone: Why do we need this? Would it be a better UX if we pause/play poster on text editing
  // i don't update the words array until after the editing is finished (so that the timestamps don't abruptly change during on canvas editing), and for that purpose,
  // i need the previous word that existed
  private previousWordDuringOnCanvasEditing?: string;

  public constructor(transcriptItem: TranscriptItem) {
    super();
    this.transcriptItem = transcriptItem;
    this.syncToPosterClock = new SyncSubtitleToPosterClock(this);
  }

  public toObject(): SubtitleObject {
    const wordObjects: WordObject[] = [];

    for (const word of this.words) {
      wordObjects.push(word.toObject());
    }

    return {
      subtitleUID: this.subtitleUID,
      text: this.text,
      startTime: this.startTime,
      endTime: this.endTime,
      hasUserEdited: this.hasUserEdited,
      sentenceTextStyles: this.sentenceTextStyles.toObject(),
      activeWordTextStyles: this.activeWordTextStyles.toObject(),
      backgroundBorderRadius: this.backgroundBorderRadius,
      backgroundFill: this.backgroundFill.toObject(),
      aura: this.aura.toObject(),
      selectedTemplateId: this.selectedTemplateId,
      animationStyle: this.animationStyle,
      words: wordObjects,
    };
  }

  public init(): void {
    if (!this.isInitialzed) {
      this.isInitialzed = true;
      this.fabricObject = this.getFabricObject();
      this.initEvents();
    }
  }

  public copyVals(obj: DeepPartial<SubtitleObject>): void {
    const {sentenceTextStyles, activeWordTextStyles, aura, backgroundFill, ...plainObj} = obj;
    super.copyVals(plainObj);
    this.sentenceTextStyles.copyVals(sentenceTextStyles);
    this.activeWordTextStyles.copyVals(activeWordTextStyles);
    this.aura.copyVals(aura);
    this.backgroundFill.copyVals(backgroundFill);
  }

  public onAddedToTranscript(): void {
    this.syncToPosterClock.initSyncToPosterClock();
  }

  public async updateFromObject(
    subtitleObject: DeepPartial<SubtitleObject>,
    {undoable = true, updateRedux = true, onError = noop}: TranscriptUpdateFromObjectOpts = {}
  ): Promise<void> {
    const {words, ...otherSubtitleObject} = subtitleObject;
    this.copyVals({
      ...otherSubtitleObject,
    });

    this.transcriptItem.smallestWidthNeeded = undefined;
    this.currentlyActiveWord = undefined;
    this.transcriptItem.areFontsLoaded = false;
    await this.ensureFontsAreLoaded(onError);
    this.transcriptItem.areFontsLoaded = true;

    if (otherSubtitleObject.animationStyle) {
      if (otherSubtitleObject.animationStyle !== SubtitleTemplateType.ONE_WORD) {
        this.previousWordDuringOnCanvasEditing = undefined;
      }

      if (this.isInitialzed && this.isCurrentlyActiveSubtitle) {
        this.resetTextFabricObjectSelectionStyles();
      }
    }

    this.init();

    // CodeReviewTaimurDone: The value has already being copied to the class variable, so i don't think this function needs any params
    if (this.doWordTimesNeedEqualTimeDivision()) {
      const durationOfEachWord =
        ((subtitleObject.endTime ?? this.endTime) - (subtitleObject.startTime ?? this.startTime)) / (subtitleObject.text ?? this.text).trim().split(' ').length;

      let startTime = subtitleObject.startTime ?? this.startTime;
      for (const word of this.words) {
        word.startTime = startTime;
        word.endTime = startTime + durationOfEachWord;

        startTime = startTime + durationOfEachWord + MIN_TIME_GAP_FOR_TRANSCRIPT;
      }
    }

    if (words) {
      //CodeReviewTaimurDone: Move this to a private class function call removeAllWords
      this.reIntializeWords(words);
    }

    this.updateFabricObject();

    if (undoable) {
      this.transcriptItem.page.poster.history.addPosterHistory();
    }

    if (updateRedux) {
      this.transcriptItem.page.poster.redux.updateReduxData();
    }

    if (this.isAddedToTranscript()) {
      // this.syncSubtitle();
    }
  }

  public getSmallestWidthNeededByFabricTextBox(): number {
    return this.textFabricObject.getMinWidth() + BACKGROUND_PADDING * 2;
  }

  public syncSubtitle(): void {
    this.syncToPosterClock.syncToPage(window.posterEditor?.whiteboard?.getCurrentTime() ?? 0);
  }

  public onRemove(): void {
    this.syncToPosterClock.unload();
  }

  public getFabricObject(): Group {
    this.textFabricObject = new Textbox(this.text, {
      lockMovementY: true,
      lockMovementX: true,
      lockScalingX: true,
      lockScalingY: true,
      __PMWID: this.transcriptItem.uid,
      hasControls: false,
      left: BACKGROUND_PADDING,
      top: BACKGROUND_PADDING,
    });

    this.backgroundFabricObject = new Rect({
      width: this.textFabricObject.width + BACKGROUND_PADDING * 2,
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
      strokeWidth: 0,
      __PMWID: this.transcriptItem.uid,
      evented: false,
      selectable: false,
    });

    return new Group([this.backgroundFabricObject, this.textFabricObject], {
      subTargetCheck: true,
      interactive: true,
      __PMWID: this.transcriptItem.uid,
      layoutManager: new LayoutManager(new FixedLayout()),
      lockMovementY: true,
      lockMovementX: true,
      hasControls: false,
      selectable: false,
    });
  }

  public setSubtitleFabricGroupItemsDimensionsAndAlignment(width: number): void {
    const maxPossibleWidthNeededByTextFabricObject = this.getMaxPossibleWidthNeededByTextFabricObject();

    const newWidth =
      width > maxPossibleWidthNeededByTextFabricObject
        ? maxPossibleWidthNeededByTextFabricObject - this.backgroundFabricObject.strokeWidth
        : width - this.backgroundFabricObject.strokeWidth;

    if (this.backgroundFabricObject.width !== newWidth || this.backgroundFabricObject.width <= this.textFabricObject.width) {
      this.backgroundFabricObject.set({
        width: newWidth,
      });
      this.textFabricObject.set({
        width: this.backgroundFabricObject.width - BACKGROUND_PADDING * 2,
      });
      this.backgroundFabricObject.set({
        height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
      });

      this.updateSubtitleFabricObjectDimensions();
    }

    this.centerAlignSubtitleFabricGroupItems();
  }

  public getCurrentWord(): Word | undefined {
    return this.currentlyActiveWord;
  }

  public getMaxPossibleWidthNeededByTextFabricObject(): number {
    const text = new IText(this.textFabricObject.text, {
      shadow: this.transcriptItem.aura.getItemAura(this.getScaleForShadow()),
      ...this.sentenceTextStyles.getTextStyles(this.textFabricObject.width, this.textFabricObject.height),
    });

    return text.width + BACKGROUND_PADDING * 2;
  }

  public getWordAtTime(time: number): Word | undefined {
    for (const word of this.words) {
      if (time >= word.startTime && time <= word.endTime) {
        return word;
      }
    }

    return undefined;
  }

  public handleCurrentWordAtTime(time: number): void {
    if (this.animationStyle === SubtitleTemplateType.SIMPLE) {
      return;
    }

    const currentWord = this.getWordAtTime(time);

    if (!currentWord) {
      if (this.animationStyle === SubtitleTemplateType.ONE_WORD) {
        this.hide();
      }

      this.currentlyActiveWord = undefined;
      return;
    }

    if (currentWord.id === this.currentlyActiveWord?.id) {
      return;
    }

    this.currentlyActiveWord = currentWord;

    if (this.animationStyle === SubtitleTemplateType.ONE_WORD) {
      this.showCurrentWordOnly(currentWord);
    }

    this.resetTextFabricObjectSelectionStyles();

    this.applyActiveWordStyles(currentWord);

    this.transcriptItem.page.fabricCanvas.requestRenderAll();
  }

  public show(): void {
    if (!this.fabricObject.visible) {
      this.fabricObject.set({
        visible: true,
        evented: true,

        subTargetCheck: true,
        interactive: true,
      });
      this.transcriptItem.page.fabricCanvas.requestRenderAll();
    }
  }

  public hide(): void {
    if (this.fabricObject.visible) {
      this.fabricObject.set({
        visible: false,
        evented: false,

        subTargetCheck: false,
        interactive: false,
      });
      this.transcriptItem.page.fabricCanvas.requestRenderAll();
    }
  }

  public updateFabricObject(): void {
    this.resetTextFabricObjectSelectionStyles();

    this.textFabricObject.set({
      ...this.sentenceTextStyles.getTextStyles(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
      shadow: this.getShadow(),
      fontFamily: this.sentenceTextStyles.fontFamily,
    });

    this.applyTextStroke();

    this.backgroundFabricObject.set({
      rx: this.backgroundBorderRadius,
      ry: this.backgroundBorderRadius,
      fill: this.backgroundFill.getFill(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
    });

    this.updateBackgroundDimensions();
    this.updateSubtitleFabricObjectDimensions();
  }

  public addSubtitleToTranscript(): void {
    this.transcriptItem.addSubtitle(this);
    this.onAddedToTranscript();
    this.syncSubtitle();
  }

  public getScaleForShadow(): number {
    const maxDimension = Math.max(this.transcriptItem.height, this.transcriptItem.width);
    return maxDimension / SHADOW_REFERENCE_DIMENSION;
  }

  protected getShadow(): Shadow | null {
    return this.aura.getItemAura(this.getScaleForShadow());
  }

  private resetTextFabricObjectSelectionStyles(): void {
    this.transcriptItem.fabricObject.set('dirty', true);

    if (this.animationStyle !== SubtitleTemplateType.ONE_WORD) {
      this.textFabricObject.set('text', this.text);
    }

    this.textFabricObject.setSelectionStyles(
      {
        shadow: this.getShadow(),
        ...this.sentenceTextStyles.getTextStyles(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
        fontFamily: this.sentenceTextStyles.fontFamily,
      },
      0,
      this.text.length
    );

    this.resetSentenceTextStokeBetweenIndices(0, this.text.length);
  }

  private resetSentenceTextStokeBetweenIndices(start: number, end: number): void {
    if (this.sentenceTextStyles.stroke) {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: this.sentenceTextStyles.fontSize * this.sentenceTextStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
          strokeLineJoin: 'round',
          paintFirst: 'stroke',
          stroke: rgbToHexString(this.sentenceTextStyles.strokeColor, 1),
        },
        start,
        end
      );
    } else {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: 0,
          strokeLineJoin: 'miter',
          paintFirst: 'fill',
          stroke: undefined,
        },
        start,
        end
      );
    }
  }

  private applyActiveTextStrokeBetweenIndices(start: number, end: number): void {
    let styles = this.activeWordTextStyles;
    if (this.animationStyle === SubtitleTemplateType.ONE_WORD) {
      styles = this.sentenceTextStyles;
    }

    if (styles.stroke) {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: styles.fontSize * styles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
          strokeLineJoin: 'round',
          paintFirst: 'stroke',
          stroke: rgbToHexString(styles.strokeColor, 1),
        },
        start,
        end
      );
    } else {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: 0,
          strokeLineJoin: 'miter',
          paintFirst: 'fill',
          stroke: undefined,
        },
        start,
        end
      );
    }
  }

  private reIntializeWords(newWords: WordObject[]): void {
    this.words = [];

    for (const word of newWords) {
      createAndAddWordFromObject(this, word);
    }
  }

  private applyTextStroke(): void {
    if (this.sentenceTextStyles.stroke) {
      this.textFabricObject.set({
        strokeWidth: this.sentenceTextStyles.fontSize * this.sentenceTextStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
        strokeLineJoin: 'round',
        paintFirst: 'stroke',
        stroke: rgbToHexString(this.sentenceTextStyles.strokeColor, 1),
      });
    } else {
      this.textFabricObject.set({
        strokeWidth: 0,
        strokeLineJoin: 'miter',
        paintFirst: 'fill',
        stroke: undefined,
      });
    }
  }

  private updateSubtitleFabricObjectDimensions(): void {
    this.fabricObject.set({
      width: this.backgroundFabricObject.width,
      height: this.backgroundFabricObject.height,
    });
  }

  private updateBackgroundDimensions(): void {
    this.backgroundFabricObject.set({
      width: this.textFabricObject.width + BACKGROUND_PADDING * 2,
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
    });
  }

  private centerAlignSubtitleFabricGroupItems(): void {
    this.backgroundFabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');
    this.textFabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');
  }

  private async ensureFontsAreLoaded(onError?: () => void): Promise<void> {
    await addFontsAsync([this.sentenceTextStyles.getFontFamilyToLoad()], () => {
      if (onError) {
        onError();
      }
      this.transcriptItem.areFontsLoaded = true;
    });
  }

  private initEvents(): void {
    this.textFabricObject.on('editing:entered', this.onTextEditingEnter.bind(this));
    this.textFabricObject.on('editing:exited', this.onTextEditingExit.bind(this));
    this.textFabricObject.on('changed', this.onTextChanged.bind(this));
  }

  private async onTextEditingExit(): Promise<void> {
    if (this.hasUserEdited) {
      this.previousWordDuringOnCanvasEditing = undefined;
      await this.updateFromObject({words: getSubtitlesWordArrayAfterTextEdited(this.text, this.startTime, this.endTime)});
    }

    await this.transcriptItem.onTextEditingExit();
  }

  private async onTextEditingEnter(): Promise<void> {
    await this.transcriptItem.onTextEditingEnter();
  }

  private async onTextChanged(): Promise<void> {
    if (this.transcriptItem.page.poster.isPlaying()) {
      await this.transcriptItem.page.poster.pause();
    }

    let editedSubtitleText = this.textFabricObject.text;

    if (this.animationStyle === SubtitleTemplateType.ONE_WORD) {
      const currentWord = this.getCurrentWord();
      if (!currentWord) {
        throw new Error(`No word active at time ${this.transcriptItem.page.poster.getCurrentTime()}`);
      }

      if (!this.previousWordDuringOnCanvasEditing) {
        this.previousWordDuringOnCanvasEditing = currentWord.text;
      }

      const {start} = this.getStartAndEndIndicesOfCurrentWordInSubtitle(currentWord, true);

      const textBeforeCurrentWord = this.text.slice(0, start);

      const end = start + this.previousWordDuringOnCanvasEditing.length;

      let textAfterCurrentWord = this.text.slice(end);

      if (editedSubtitleText === '') {
        textAfterCurrentWord = textAfterCurrentWord.trim();
      }

      editedSubtitleText = `${textBeforeCurrentWord}${this.textFabricObject.text}${textAfterCurrentWord}`;

      this.previousWordDuringOnCanvasEditing = this.textFabricObject.text;
    }

    await this.updateFromObject({text: editedSubtitleText, hasUserEdited: true}, {undoable: false});

    this.transcriptItem.onTextChanged();
  }

  private isAddedToTranscript(): boolean {
    return !!this.transcriptItem.subtitlesHashmap[this.subtitleUID];
  }

  private doWordTimesNeedEqualTimeDivision(): boolean {
    return this.hasUserEdited && !this.textFabricObject.isEditing;
  }

  public getStartAndEndIndicesOfCurrentWordInSubtitle(currentWord: Word, isEditing = false): StartAndEndIndicesOfWord {
    if (!isEditing && this.animationStyle === SubtitleTemplateType.ONE_WORD) {
      return {start: 0, end: [...currentWord.text].length};
    }

    let indexOfCurrentWordInWordsArray = -1;

    Object.values(this.words).forEach((word, index) => {
      if (word.text === currentWord.text && word.startTime === currentWord.startTime && word.endTime === currentWord.endTime) {
        indexOfCurrentWordInWordsArray = index;
      }
    });

    if (indexOfCurrentWordInWordsArray === -1) {
      throw new Error('Current word not found in subtitle words.');
    }

    let startIndexOfCurrentWordInSentence = 0;

    // Split the text into graphemes to ensure proper handling of characters like 🎵
    const words = this.text.split(' ').map((word) => [...word]);

    for (let i = 0; i < indexOfCurrentWordInWordsArray; i++) {
      startIndexOfCurrentWordInSentence += words[i].length + 1; // +1 for the space
    }

    const currentWordGraphemes = words[indexOfCurrentWordInWordsArray]; // once a subtitle text is edited, its words only have alpha-numerical characters (because of a removePunctuationFromWord call
    // in getSubtitlesWordArrayAfterTextEdited), so it won't have any characters like 🎵 or any punctuation anymore, so this is done to make sure that the end index is calculated correctly
    return {
      start: startIndexOfCurrentWordInSentence,
      end: startIndexOfCurrentWordInSentence + currentWordGraphemes.length,
    };
  }

  public applyActiveWordStyles(currentWord: Word): void {
    const {start, end} = this.getStartAndEndIndicesOfCurrentWordInSubtitle(currentWord);

    let styles = this.activeWordTextStyles;
    if (this.animationStyle === SubtitleTemplateType.ONE_WORD) {
      styles = this.sentenceTextStyles;
    }

    this.textFabricObject.setSelectionStyles(
      {
        ...styles.getTextStyles(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
        fontFamily: styles.fontFamily,
      },
      this.animationStyle === SubtitleTemplateType.PROGRESS ? 0 : start,
      end
    );

    this.applyActiveTextStrokeBetweenIndices(this.animationStyle === SubtitleTemplateType.PROGRESS ? 0 : start, end);
  }

  public updateSubtitleFabricObjectForCurrentWord(): void {
    this.textFabricObject.set('width', this.textFabricObject.getMinWidth());

    this.backgroundFabricObject.set({
      width: this.textFabricObject.width + BACKGROUND_PADDING * 2,
    });

    this.backgroundFabricObject.set({
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
    });

    this.updateSubtitleFabricObjectDimensions();
    this.centerAlignSubtitleFabricGroupItems();
    this.fabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');

    this.transcriptItem.fabricObject.set('dirty', true);
  }

  public showCurrentWordOnly(currentWord: Word): void {
    if (!this.textFabricObject.isEditing || this.transcriptItem.page.poster.isPlaying()) {
      this.textFabricObject.set('text', currentWord.text);
      this.updateSubtitleFabricObjectForCurrentWord();
    }
  }
}

export const createSubtitleFromObject = async (transcriptItem: TranscriptItem, subtitleObject: DeepPartial<SubtitleObject>): Promise<Subtitle> => {
  const subtitle = new Subtitle(transcriptItem);
  await subtitle.updateFromObject(subtitleObject, {
    updateRedux: false,
    undoable: false,
  });

  return subtitle;
};

export const createSubtitleFromObjectAndAddToTranscript = async (transcriptItem: TranscriptItem, subtitleObject: DeepPartial<SubtitleObject>): Promise<Subtitle> => {
  const subtitle = await createSubtitleFromObject(transcriptItem, subtitleObject);
  subtitle.addSubtitleToTranscript();
  return subtitle;
};
