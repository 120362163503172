import {USER_TYPES,USER_PREMIUM_LEVELS} from '@Utils/user.util';
import {openEmbeddedEditorConsentModal} from '@Modals/embedded-editor-consent-modal';
import {openAuthenticateModal} from '@Modals/authenticate-modal';

/**
 * Mirrored from Accounts.php
 * The minimum length that a password can be
 * Note: Amer said it should be at least 12, but Noooooooo Jaffer wants 8, in fact he wanted 6... if we have a security issue in the future, we all know who to blame
 */
export const MIN_PASSWORD_LENGTH = 9;

/**
 * Mirrored from Accounts.php
 * Minimum number of special characters a password must have
 */
export const MIN_SPECIAL_CHARS = 1;

/**
 * Cookie to test if browser supports partitioned cookies
 */
export const PARTITIONED_COOKIE_SUPPORTED = 'pmwpcs';

export interface HasUserSeenData {
  hasUserSeen: boolean;
}

let currentUserData: UserData | undefined;

export interface UserData {
  id: string;
  fbId: string;
  name: string;
  type: string;
  watermark: string;
  isSuper: boolean;
  isFreelancer: boolean;
  premiumLevel: USER_PREMIUM_LEVELS;
  preferredLanguage: string;
  verificationNeededStatus: boolean;
}

export interface UserDataWithFonts extends UserData {
  fonts: Array<any>;
}

interface IsSuperUserAjaxResponse {
  isSuper: boolean;
}

interface GetTeamCreditsResponse {
  credits: number;
}

/**
 * @return {number}
 */
export const getUserId = (): number | undefined => {
  const userId = window.PMW.getUserId();
  if (userId === 'notloggedin') {
    return undefined;
  }
  return userId;
};

export const getUserHashedId = (): string | null => {
  return window.PMW.getUserHashedId();
};

export const isUserLoggedIn = (): boolean => {
  return window.PMW.getUserId() !== 'notloggedin';
};

/**
 * @return {Promise<*>}
 */
export const getUserInfo = async (): Promise<UserDataWithFonts> => {
  return (await window.PMW.getRequest('user/getUserInfo')) as UserDataWithFonts;
};

export const getUserInfoAndUpdateCurrentUserData = async (): Promise<UserDataWithFonts> => {
  const userInfo = await getUserInfo();
  const {fonts, ...remainingUserData} = userInfo;
  currentUserData = remainingUserData;

  return userInfo;
};

export const geCurrentUserData = async (): Promise<UserData | undefined> => {
  const userId = getUserId();
  if (!userId) {
    return undefined;
  }

  if (!currentUserData || currentUserData.id !== userId.toString()) {
    const response = (await window.PMW.writeLocal('user/getUserData')) as UserData | null;
    if (response) {
      currentUserData = response;
    }
  }

  return currentUserData;
};

/**
 * @return {Promise<boolean>}
 */
export const isSuperUser = async (): Promise<boolean> => {
  try {
    const response = (await window.PMW.readLocal('user/isSuperUser')) as IsSuperUserAjaxResponse;
    return response.isSuper;
  } catch (e) {
    console.error('error checking super user', e);
  }

  return false;
};

/**
 * @return {Number}
 */
export const getUserPremiumLevel = (): USER_PREMIUM_LEVELS => {
  return window.PMW.getUserPremiumLevel();
};

/**
 * @return {boolean}
 */
export const isUserPremiumPlus = (): boolean => {
  return window.PMW.getUserPremiumLevel() === USER_PREMIUM_LEVELS.PREMIUM_PLUS;
};

/**
 * @return {boolean}
 */
export const isUserPremiumLevelOne = (): boolean => {
  return window.PMW.getUserPremiumLevel() === USER_PREMIUM_LEVELS.PREMIUM;
};

/**
 * @return {boolean}
 */
export const isUserPremium = (): boolean => {
  return window.PMW.getUserPremiumLevel() !== USER_PREMIUM_LEVELS.PAYG;
};

export const isUserAcademic = (): boolean => {
  return window.PMW.isEDU();
};

export const isUserStudent = (): boolean => {
  return window.PMW.getUserType() === USER_TYPES.STUDENT;
}

/**
 * @return {string}
 */
export const getUserType = (): USER_TYPES => {
  return window.PMW.getUserType();
};

export const isUserDesigner = (): boolean => {
  return window.PMW.getUserType() === 'designer' || window.PMW.getUserType() === 'teacher-designer';
};
/**
 * Checks if the current user is in a location where data is regulated
 * @returns {Promise<boolean>}
 * @throws Error
 */
export const isUserInRegulatedLocation = (): Promise<boolean> => {
  return window.PMW.readLocal('user/isinregloc') as Promise<boolean>;
};

/**
 * Shows the login modal if user is not logged in
 * @param {function} cb callback that fires if user is logged in or logs in from the login modal that appears
 * @param {function} onCloseCb callback that fires on modal close
 * @param {Object} additionalProps Additional props for the authentication modal
 * @returns {Object}
 */
export const authenticateUser = async (cb: () => void | Promise<void>, onCloseCb = (): void => {}, additionalProps = {}): Promise<void> => {
  try {
    await requestCookieStorageAccess();
  } catch (e) {
    openEmbeddedEditorConsentModal({
      title: window.i18next.t('pmwjs_login_to_save'),
      textClassName: 'content-body',
      text: window.i18next.t('pmwjs_login_to_save_progress'),
      primaryCta: {
        text: window.i18next.t('pmwjs_log_in'),
        icon: 'icon-sign-in',
      },
      secondaryCta: {
        text: window.i18next.t('pmwjs_cancel'),
      },
    });
    return Promise.resolve();
  }

  if (isUserLoggedIn()) {
    await cb();
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    openAuthenticateModal({
      ...additionalProps,
      onLoginSuccess: async () => {
        await cb();
        resolve();
      },
      onCloseCb,
    });
  });
};

export const logOutUser = async (): Promise<void> => {
  return window.PMW.write('authenticate/asyncLogout') as Promise<void>;
};

/**
 * validates a single password against our requirements
 * @param {string} password
 * @returns {boolean}
 */
export const validateUserPassword = (password: string): boolean => {
  const length = password?.length || 0;
  const isEmpty = !password;
  const isLongEnough = length >= MIN_PASSWORD_LENGTH;
  const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const numberRegex = /\d/;
  const hasSpecialCharacter = specialCharRegex.test(password) || numberRegex.test(password);

  return !isEmpty && hasSpecialCharacter && isLongEnough;
};

/**
 * Requests cookie storage from users
 * Needed for cookie access when the editor is loaded as an embedded iframe
 */
export const requestCookieStorageAccess = async (): Promise<void> => {
  if (!window.PMW.isEmbedded()) {
    return Promise.resolve();
  }

  document.cookie = `${PARTITIONED_COOKIE_SUPPORTED}=1; Partitioned; SameSite=None; Secure; Max-Age=86400`;
  if (document.cookie.includes(`${PARTITIONED_COOKIE_SUPPORTED}=1`)) {
    return Promise.resolve();
  }

  if ('hasStorageAccess' in document) {
    const hasAccess = await document.hasStorageAccess();
    if (hasAccess) {
      return Promise.resolve();
    }
  }

  if ('requestStorageAccess' in document) {
    return document.requestStorageAccess();
  }

  return Promise.resolve();
};

export const getTeamCreditsForUser = async (): Promise<number> => {
  if (isUserLoggedIn()) {
    const response = (await window.PMW.readLocal('user/getTeamCreditsV2ForUser')) as GetTeamCreditsResponse;
    return response.credits;
  }
  return 0;
};
