import {TextVersion, TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {getScaledManualHorizontalPadding} from '@PosterWhiteboard/items/item/item.library';
import type {RGB} from '@Utils/color.util';
import {DEFAULT_SLIDE_DURATION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import type {TextStylesObject} from '@PosterWhiteboard/classes/text-styles.class';
import {ElementDataType} from '@Libraries/add-media-library';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import type {ItemFabricObject} from '@PosterWhiteboard/items/item/item.class';
import type {FabricObject} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';

export interface TextSlideItemObject extends TextItemObject {
  slideDuration: number;
  isNewSlide: boolean;
}

export class TextSlideItem extends TextItem {
  public gitype = ITEM_TYPE.TEXTSLIDE;
  /**
   * This variable is used to ensure that a newly added text slide doesn't dictate the slideshow dimensions unless it's the 1st slide of slideshow.
   * Only used at client side.
   */
  public isNewSlide = false;

  public slideDuration = DEFAULT_SLIDE_DURATION;
  public slideshow: SlideshowItem;

  public constructor(page: Page, slideshow: SlideshowItem) {
    super(page);
    this.slideshow = slideshow;
  }

  public getParentFabricObject(): ItemFabricObject {
    return this.slideshow.fabricObject;
  }

  protected getCommonOptions(): Record<string, any> {
    const {left, top, opacity, ...filteredProps} = super.getCommonOptions();
    return filteredProps;
  }

  public applyFixForVersion3(): void {
    this.visible = true;
    if (this.isTextVersion2()) {
      const scale = this.slideshow.scaleX ?? this.scaleX;
      this.baseWidth = this.width - getScaledManualHorizontalPadding(scale) - this.getStrokeWidth() - this.list.width;
      this.version = TextVersion.TEXT_VERSION_3;
    }
  }

  public getAppliedSidebarProps(): DeepPartial<TextSlideItemObject> {
    const textStylesToCopy: DeepPartial<TextStylesObject> = this.textStyles.toObject();
    delete textStylesToCopy.script;
    return {
      textStyles: textStylesToCopy,
      aura: this.aura.toObject(),
      list: this.list.toObject(),
      background: this.background.toObject(),
      verticalAlign: this.verticalAlign,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    };
  }

  public toObject(): TextSlideItemObject {
    return {
      ...super.toObject(),
      slideDuration: this.slideDuration,
      isNewSlide: this.isNewSlide,
    };
  }

  /**
   * Getter for slideDuration property
   */
  public getSlideDuration(): number {
    return this.slideDuration;
  }

  /**
   * Setter for slideDuration property
   */
  public setSlideDuration(val: number): void {
    this.slideDuration = val;
  }

  public isChildItem(): boolean {
    return true;
  }

  public getColors(): RGB[] {
    return super.getColors();
  }

  addBulletToGroupWithOriginalScale(bulletFabricObject: FabricObject): void {
    const {scaleX, scaleY, angle} = this.slideshow.fabricObject;
    this.slideshow.fabricObject.set({
      scaleX: 1,
      scaleY: 1,
      angle: 0,
    });
    super.addBulletToGroupWithOriginalScale(bulletFabricObject);
    this.slideshow.fabricObject.set({
      scaleX,
      scaleY,
      angle,
    });
  }

  public toggleBold(): void {
    void this.slideshow.updateSlideFromObject(this.uid, {
      textStyles: {
        isBold: !this.textStyles.isBold,
      },
    });
  }

  public toggleItalic(): void {
    void this.slideshow.updateSlideFromObject(this.uid, {
      textStyles: {
        isItalic: !this.textStyles.isItalic,
      },
    });
  }

  public toggleLineThrough(): void {
    void this.slideshow.updateSlideFromObject(this.uid, {
      textStyles: {
        lineThrough: !this.textStyles.lineThrough,
      },
    });
  }

  public toggleUnderline(): void {
    void this.slideshow.updateSlideFromObject(this.uid, {
      textStyles: {
        underLine: !this.textStyles.underLine,
      },
    });
  }
}

export const addTextSlideshowToPoster = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addTextSlideshowItem({
    type: ElementDataType.SLIDESHOW,
  });
  trackPosterBuilderGA4Events(GA4EventName.ADD_SLIDESHOW);
};
