import type {Cell, TimeCellValue} from '@PosterWhiteboard/items/layouts/cells/cell';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import {rgbToHexString} from '@Utils/color.util';
import {TimeFormat} from '@Components/table/table.types';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {FabricObjectProps} from '@postermywall/fabricjs-2';
import {IText} from '@postermywall/fabricjs-2';
import {Layout} from './layout';

export class TableLayout extends Layout {
  public layoutType: LayoutTypes.TABLE_LAYOUT | LayoutTypes.CUSTOM_TABLE_LAYOUT;

  /**
   * 2D array containing table data
   * @type {Array}
   */
  private tableArray: IText[][] = [];
  /**
   * Number of columns of a table
   */
  private columns = 0;
  /**
   * Number of rows of a table
   */
  private rows = 0;
  /**
   * Distance between table columns
   */
  private xOffset = 0;
  /**
   * Distance between table rows
   */
  private yOffset = 0;

  public constructor(item: TableItem, isCustomTable: boolean) {
    super(item);
    this.layoutType = isCustomTable ? LayoutTypes.CUSTOM_TABLE_LAYOUT : LayoutTypes.TABLE_LAYOUT;
  }

  /**
   * This function gets the text items from the view and aligns them in rows and columns to form a table using the latest value from the tableItem.
   * Also calls the function which applies styles specific to this layout.
   */
  async doLayout(): Promise<void> {
    let x;
    let i;
    this.rows = this.item.rows;
    this.columns = this.item.columns;
    this.tableArray = [[]];
    this.yOffset = this.item.ySpacing;
    this.xOffset = this.item.xSpacing;

    const items = this.item.fabricObject.getObjects();

    for (const item of items) {
      if (!(item instanceof IText)) {
        throw new Error(`Unhandled fabric object in table layout: ${JSON.stringify(item)}`);
      }
      if (this.tableArray[item.column - 1] === undefined) {
        this.tableArray.push([]);
      }
      this.tableArray[item.column - 1].push(item);
    }

    // set the width and height of the table
    const minTableWidth = this.getMinimumTableWidth();
    const minTableHeight = this.getMinimumTableHeight();

    this.item.fabricObject.set({
      width: minTableWidth + this.columns * this.xOffset,
      height: minTableHeight + this.rows * this.yOffset,
      tableArray: this.tableArray,
      ySpacing: this.yOffset,
      xSpacing: this.xOffset,
    });

    // align items vertically in columns
    for (i = 0; i < this.tableArray.length; i++) {
      const left = this.getLeftPositionForColumn(i);
      const column = this.tableArray[i];
      for (x = 0; x < column.length; x++) {
        column[x].set({
          left,
          top: -this.item.fabricObject.height / 2 + this.yOffset / 2,
        });
      }
    }

    // aligns items horizontally in rows
    for (i = 1; i < this.rows; i++) {
      for (x = 0; x < this.columns; x++) {
        if (this.tableArray[x]) {
          this.tableArray[x][i].set({
            top: this.tableArray[x][i - 1].top + this.getHeightOfRow(i - 1) + this.yOffset,
          });
        }
      }
    }

    this.doAlignColumns(this.item.textStyles.textAlign);
    this.setStylesForHighlightedItems();
  }

  /**
   * Function of parent class, overridden in child class for insertion of data in view, specific to this layout.
   * @override
   */
  async setItems(): Promise<void> {
    const items: IText[] = [];
    let columnNo = 0;

    for (const [columnType, columnCells] of Object.entries(this.item.getColumnMap()) as [CellType, Cell[]][]) {
      if (this.showColumn(columnType, columnCells)) {
        for (const [rowNo, cell] of columnCells.entries()) {
          items.push(
            new IText(cell.getValue() as string, {
              editable: false,
              row: rowNo + 1,
              column: columnNo + 1,
            })
          );
        }
        columnNo += 1;
      }
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(items);
  }

  private showColumn(columnType: CellType, columnCells: Cell[]): boolean {
    return !(columnType === CellType.TIME && (columnCells[0].value as TimeCellValue).timeFormat === TimeFormat.DISABLE);
  }

  /**
   * Sets styles for highlighted items in the table
   * @override
   */
  setStylesForHighlightedItems(): void {
    const {highlightedRows} = this.item.fabricObject;
    const color = rgbToHexString(this.item.highlightedTextColor, 1);
    const options: Partial<FabricObjectProps> = {};

    if (highlightedRows.length > 0) {
      for (let i = 0; i < highlightedRows.length; i++) {
        for (let x = 0; x < this.columns; x++) {
          options.fill = color;
          if (this.tableArray[x]) {
            if (this.item.textStyles.isBold && this.tableArray[x][highlightedRows[i]].stroke) {
              options.stroke = color;
            }
            this.tableArray[x][highlightedRows[i]].set(options);
          }
        }
      }
    }
  }

  /**
   * Align the text items relative to column.
   * @param {String} val can be one of these 'left', 'center', 'right' or 'justify'
   * @private
   */
  doAlignColumns(val: string): void {
    // return if val is 'left' as columns are aligned to left by default
    if (val === 'left') {
      return;
    }

    let currentColumnWidth;
    let currentColumn;
    let c;
    let i;
    const noOfColumns = this.item.getNumberOfEnabledColumns();

    switch (val) {
      case 'center':
        for (c = 0; c < noOfColumns; c++) {
          currentColumnWidth = this.getWidthOfColumn(c);
          currentColumn = this.tableArray[c];
          for (i = 0; i < currentColumn.length; i++) {
            currentColumn[i].set({
              left: currentColumn[i].left + (currentColumnWidth - currentColumn[i].width) / 2,
            });
          }
        }
        break;
      case 'right':
        for (c = 0; c < noOfColumns; c++) {
          currentColumnWidth = this.getWidthOfColumn(c);
          currentColumn = this.tableArray[c];
          for (i = 0; i < currentColumn.length; i++) {
            currentColumn[i].set({
              left: currentColumn[i].left + currentColumnWidth - currentColumn[i].width,
            });
          }
        }
        break;
      case 'justify':
        for (c = 0; c < noOfColumns; c++) {
          currentColumnWidth = this.getWidthOfColumn(c);
          currentColumn = this.tableArray[c];
          for (i = 0; i < currentColumn.length; i++) {
            currentColumn[i].set({
              width: currentColumnWidth,
            });
          }
        }
        break;

      default:
        console.error(`Unknown align type value '${val}`);
    }
  }

  /**
   * Returns the height of a text item in a given row with max height,
   * this value is basically the minimum space in y-axis needed by this row in a table.
   * @private
   */
  getHeightOfRow(row: number): number {
    let height = 0;
    let h;
    for (let i = 0; i < this.columns; i++) {
      if (this.tableArray[i]) {
        h = this.tableArray[i][row].calcTextHeight();
        if (h > height) {
          height = h;
        }
      }
    }
    return height;
  }

  /**
   * Returns the width of an item in a given column with max width,
   * this value is basically the minimum space in x-axis needed by this column in a table.
   * @private
   */
  getWidthOfColumn(column: number): number {
    let width = 0;
    let w;

    for (let i = 0; i < this.rows; i++) {
      w = this.tableArray[column][i].calcTextWidth();
      if (w > width) {
        width = w;
      }
    }
    return width;
  }

  /**
   * Given the column number it returns the left position(x-coord) for all items in that column
   * @private
   */
  getLeftPositionForColumn(numColumn: number): number {
    if (numColumn === 0) {
      return -this.item.fabricObject.width / 2 + this.xOffset / 2;
    }
    return this.tableArray[numColumn - 1][0].left + this.getWidthOfColumn(numColumn - 1) + this.xOffset;
  }

  /**
   * Returns the minimum width required for the table.
   * @private
   */
  getMinimumTableWidth(): number {
    let minWidth = 0;
    for (let i = 0; i < this.tableArray.length; i++) {
      let maxTextWidth = 0;
      let w;
      const currentColumn = this.tableArray[i];
      for (let x = 0; x < currentColumn.length; x++) {
        w = currentColumn[x].calcTextWidth();
        if (w > maxTextWidth) {
          maxTextWidth = w;
        }
      }

      minWidth += maxTextWidth;
    }
    return minWidth;
  }

  /**
   * Returns the minimum height required for the table
   */
  getMinimumTableHeight(): number {
    let minHeight = 0;

    for (let i = 0; i < this.rows; i++) {
      let maxTextHeight = 0;
      let h;
      for (let x = 0; x < this.columns; x++) {
        if (this.tableArray[x]) {
          h = this.tableArray[x][i].calcTextHeight();
          if (h > maxTextHeight) {
            maxTextHeight = h;
          }
        }
      }
      minHeight += maxTextHeight;
    }

    return minHeight;
  }
}
