import type {MaskingObject} from '@PosterWhiteboard/classes/masking/masking.class';
import {MaskingType, Masking} from '@PosterWhiteboard/classes/masking/masking.class';
import {getPath} from '@Libraries/splines-library';
import {cloneDeep} from 'lodash';
import type {DeepPartial} from '@/global';

export interface MaskingFreehandPoint {
  x: number;
  y: number;
}

export interface MaskingFreehandObject extends MaskingObject {
  maskPoints: MaskingFreehandPoint[];
  isMaskCurved: boolean;
}

export class MaskingFreehand extends Masking {
  public type: MaskingType = MaskingType.FREEHAND;
  public maskPoints: MaskingFreehandPoint[] = [];
  public isMaskCurved = true;

  public toObject(): MaskingFreehandObject {
    return {
      ...super.toObject(),
      maskPoints: this.maskPoints,
      isMaskCurved: this.isMaskCurved,
    };
  }

  public copyVals(obj: DeepPartial<MaskingFreehandObject> = {}): void {
    const {maskPoints, ...maskObj} = obj;
    super.copyVals(maskObj);
    if (maskPoints) {
      this.maskPoints = cloneDeep(maskPoints);
    }
  }

  protected offsetItem(offsetX: number, offsetY: number): void {
    for (const item of this.maskPoints) {
      item.x += offsetX;
      item.y += offsetY;
    }
  }

  private getScaledMaskPoints(scaleX: number, scaleY: number): MaskingFreehandPoint[] {
    const maskPoints = [];
    for (const maskPoint of this.maskPoints) {
      maskPoints.push({
        x: maskPoint.x * scaleX,
        y: maskPoint.y * scaleY,
      });
    }
    return maskPoints;
  }

  public async applyMaskingToImage(img: HTMLImageElement): Promise<HTMLImageElement> {
    const scaleX = img.width / this.imageWidth;
    const scaleY = img.height / this.imageHeight;

    const fabricObject = getPath(this.getScaledMaskPoints(scaleX, scaleY), true, this.isMaskCurved);
    if (!fabricObject) {
      return img;
    }

    return this.applyMaskForFabricObject(img, fabricObject);
  }
}
