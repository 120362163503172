import type {ReactElement} from 'react';
import React from 'react';
import {PMW_COLORS_NEUTRAL, PMW_COLORS_PRIMARY} from '@Utils/color.util';
import {CircularProgressWithChildren} from '@Components/circular-progress-with-children/circular-progress-with-children';

interface AudioItemProgressbarProps {
  className?: string;
  value: number;
  children?: ReactElement | ReactElement[];
}

export function AudioItemProgressbar({className = '', ...props}: AudioItemProgressbarProps): ReactElement {
  const STROKE_WIDTH = 3;
  const CIRCLE_DIAMETER = 60;

  return (
    <CircularProgressWithChildren
      className={className}
      strokeWidth={STROKE_WIDTH}
      diameter={CIRCLE_DIAMETER}
      baseColor={PMW_COLORS_NEUTRAL.NEUTRAL_0}
      fillColor={PMW_COLORS_PRIMARY.PRIMARY_150}
      value={props.value}
    >
      {props.children}
    </CircularProgressWithChildren>
  );
}
