import {AUTH_MODE, AUTH_SUB_TYPE, AUTH_TYPE} from '@Libraries/login-signup-library';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {loginPageExtraReducers} from '@Components/login-page/login-page-extra-reducers';
import type {AuthTypes, ConfirmLoginState, ErrorNotificationData, LoginPageInitializeProps, LoginPageState} from '@Components/login-page/login-page.types';

const initialState: LoginPageState = {
  authType: AUTH_TYPE.LOGIN,
  authMode: AUTH_MODE.DEFAULT,
  errorMessage: '',
  showError: false,
  isInDataRegulatedLocation: false,
  areAuthButtonsDisabled: true,
  animateNotificationBanner: false,
  encryptedEmail: '',
  encryptedPassword: '',
  projectName: '',
  confirmLoginType: AUTH_TYPE.LOGIN,
  signupSource: 'default',
  signupActionType: '',
  loginActionType: '',
  showAuthForm: false,
  authSubType: AUTH_SUB_TYPE.LOGIN_WITHOUT_FORM,
  isLoading: false,
  redirectUrl: '',
  isLoginToProceedMessage: false,
  isConnectSSOAccountModal: false,
};

export const LoginPageSlice = createSlice({
  name: 'loginPage',
  initialState,
  reducers: {
    initializeLoginPageState: (state, action: PayloadAction<LoginPageInitializeProps>) => {
      state.errorMessage = action.payload.errorMessage;
      state.showError = action.payload.showError;
      state.isInDataRegulatedLocation = action.payload.isInDataRegulatedLocation;
      state.signupActionType = action.payload.signupActionType;
      state.loginActionType = action.payload.loginActionType;
      state.redirectUrl = action.payload.redirectUrl;
      state.isLoginToProceedMessage = action.payload.isLoginToProceedMessage;

      if (action.payload.isOAuthFlow) {
        state.authType = AUTH_TYPE.SIGNUP;
      }
    },
    updateAuthTypeState: (state, action: PayloadAction<AuthTypes>) => {
      state.authType = action.payload.type;
      if (action.payload.subType !== undefined) {
        state.authSubType = action.payload.subType;
      }
    },
    updateAuthMode: (state, action: PayloadAction<AUTH_MODE>) => {
      state.authMode = action.payload;
    },
    updateErrors: (state, action: PayloadAction<ErrorNotificationData>) => {
      if (state.isLoginToProceedMessage) {
        state.isLoginToProceedMessage = false;
      }
      state.errorMessage = action.payload.errorMessage;
      state.showError = action.payload.showError;
      state.animateNotificationBanner = action.payload.animateNotificationBanner;
    },
    updateSignupSource: (state, action: PayloadAction<string>) => {
      state.signupSource = action.payload;
    },
    enableAuthButtonsAccess: (state) => {
      state.areAuthButtonsDisabled = false;
    },
    resetNotificationBannerAnimation: (state) => {
      state.animateNotificationBanner = false;
    },
    enableLoadingState: (state) => {
      state.isLoading = true;
    },
    disableLoadingState: (state) => {
      state.isLoading = false;
    },
    switchToConfirmLogin: (state, action: PayloadAction<ConfirmLoginState>) => {
      state.confirmLoginType = action.payload.confirmLoginType;
      state.encryptedEmail = action.payload.encryptedEmail;
      state.encryptedPassword = action.payload.encryptedPassword;
      state.projectName = action.payload.projectName;
      state.authType = AUTH_TYPE.CONFIRM_LOGIN;
    },
    setIsConnectSSOAccountModal: (state) => {
      state.isConnectSSOAccountModal = true;
    },
    setIsNotConnectSSOAccountModal: (state) => {
      state.isConnectSSOAccountModal = false;
    },
  },

  extraReducers: loginPageExtraReducers,
});

export const {
  initializeLoginPageState,
  updateAuthTypeState,
  updateErrors,
  enableAuthButtonsAccess,
  resetNotificationBannerAnimation,
  switchToConfirmLogin,
  updateAuthMode,
  updateSignupSource,
  enableLoadingState,
  disableLoadingState,
  setIsConnectSSOAccountModal,
  setIsNotConnectSSOAccountModal,
} = LoginPageSlice.actions;
export const loginPageReducer = LoginPageSlice.reducer;
