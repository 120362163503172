import type {CommonAuthFormParams, ErrorNotificationData, LoginPageState, OauthFormBaseParams, RecaptchaTypes, VoidFunction} from '@Components/login-page/login-page.types';
import {PASSWORD_STATUS, PASSWORD_POLICY_ITEM_STATUS} from '@Components/signup-form/signup-form.types';

/**
 * Library containing helper functions for the login-signup page and auth-modal
 */
export enum AUTH_TYPE {
  LOGIN = 'login',
  SIGNUP = 'signup',
  STUDENT_LOGIN = 'student_login',
  CONFIRM_LOGIN = 'confirm_login',
  FORGOT_PASSWORD = 'forgot_password',
}

export enum AUTH_SUB_TYPE {
  LOGIN_WITH_FORM = 'login_with_form',
  LOGIN_WITHOUT_FORM = 'login_without_form',
  SIGNUP_WITH_FORM = 'signup_with_form',
  SIGNUP_WITHOUT_FORM = 'signup_without_form',
}

export enum AUTH_MODE {
  ASYNC = 'async',
  DEFAULT = 'default',
}

export enum LOGIN_OPTIONS {
  DEFAULT = '0',
  ALL = '1',
  OAUTH = '2',
}

export const CONFIRM_LOGIN_URL = 'authenticate/confirmlogin';

export const EMAIL_PLACEHOLDER = 'amy@postermywall.com';
export const PASSWORD_PLACEHOLDER = '•••••••';

export const MUSIC_PROMOTER_TESTIMONY_INDEX = 0;
export const BLOGGER_TESTIMONY_INDEX = 1;
export const PRINCIPAL_TESTIMONY_INDEX = 2;

const MOBILE_SCREEN_MAX_WIDTH = 768;

let authFormParams: CommonAuthFormParams = {};
let oauthFormParams: OauthFormBaseParams = {};
let recaptchaSelector: JQuery | null = null;

export const initializeAuthFormParams = (params: CommonAuthFormParams) => {
  authFormParams = params;
};

export const getCommonAuthFormParams = (): CommonAuthFormParams => {
  return authFormParams;
};

export const initializeoAuthFormParams = (params: OauthFormBaseParams) => {
  oauthFormParams = params;
};

export const getoAuthFormParams = (): OauthFormBaseParams => {
  return oauthFormParams;
};

export const setRecaptchaSelector = (selector: JQuery) => {
  recaptchaSelector = selector;
};

export const getRecaptchaSelector = () => {
  return recaptchaSelector;
};

export const getRecaptchaSelectorValue = (): RecaptchaTypes => {
  return recaptchaSelector ? recaptchaSelector.val() : '';
};

export const redirectToPage = (url?: string): void => {
  window.location.href = url || window.PMW.util.site_url('posters/mine');
};

export const showErrors = (state: LoginPageState, errorMessage: string) => {
  const errorData: ErrorNotificationData = {
    errorMessage,
    showError: true,
    animateNotificationBanner: true,
  };

  updateErrors(state, errorData);
};

export const hideErrors = (state: LoginPageState) => {
  const errorData: ErrorNotificationData = {
    errorMessage: '',
    showError: false,
    animateNotificationBanner: false,
  };

  updateErrors(state, errorData);
};

const updateErrors = (state: LoginPageState, updatedState: ErrorNotificationData) => {
  state.errorMessage = updatedState.errorMessage;
  state.showError = updatedState.showError;
  state.animateNotificationBanner = updatedState.animateNotificationBanner;
};

export const getPasswordFieldStatus = (password: string, confirmPassword: string, passwordHasFocus: boolean) => {
  let status = PASSWORD_STATUS.DEFAULT;

  if (password.length === 0 || passwordHasFocus) {
    return status;
  }

  if (isPasswordPolicySatisfied(password, passwordHasFocus)) {
    status = PASSWORD_STATUS.SUCCESS;
  } else {
    return PASSWORD_STATUS.ERROR;
  }

  if (confirmPassword.length > 0 && confirmPassword !== password) {
    status = PASSWORD_STATUS.ERROR;
  }
  return status;
};

export const getConfirmPasswordStatus = (password: string, confirmPassword: string, passwordHasFocus: boolean, confirmPasswordHasFocus: boolean) => {
  if (confirmPassword.length === 0 || confirmPasswordHasFocus) {
    return PASSWORD_STATUS.DEFAULT;
  }

  if (!isPasswordPolicySatisfied(password, passwordHasFocus) || password.length === 0) {
    return PASSWORD_STATUS.DEFAULT;
  }

  return confirmPassword === password ? PASSWORD_STATUS.SUCCESS : PASSWORD_STATUS.ERROR;
};

const isPasswordPolicySatisfied = (password: string, passwordHasFocus: boolean) => {
  return (
    getStatusForPasswordLength(password, passwordHasFocus) === PASSWORD_POLICY_ITEM_STATUS.COMPLETE &&
    getStatusForSpecialCharactersInPassword(password, passwordHasFocus) === PASSWORD_POLICY_ITEM_STATUS.COMPLETE
  );
};

export const getStatusForPasswordLength = (password: string, passwordHasFocus: boolean) => {
  if (password.length > 8) {
    return PASSWORD_POLICY_ITEM_STATUS.COMPLETE;
  }

  if (password.length === 0 || passwordHasFocus) {
    return PASSWORD_POLICY_ITEM_STATUS.IN_PROGRESS;
  }

  return PASSWORD_POLICY_ITEM_STATUS.INCOMPLETE;
};

export const getStatusForSpecialCharactersInPassword = (password: string, passwordHasFocus: boolean) => {
  const containsSpecialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const containsNumberRegex = /\d/;

  if (password.match(containsSpecialCharRegex) || password.match(containsNumberRegex)) {
    return PASSWORD_POLICY_ITEM_STATUS.COMPLETE;
  }

  if (password.length === 0 || passwordHasFocus) {
    return PASSWORD_POLICY_ITEM_STATUS.IN_PROGRESS;
  }

  return PASSWORD_POLICY_ITEM_STATUS.INCOMPLETE;
};

export const handleAuthFormSubmit = async (cb: VoidFunction, token: string) => {
  const inputRecaptcha = getRecaptchaSelector();
  if (inputRecaptcha) {
    inputRecaptcha.val(token);
    cb();
  }
};

export const doesAuthTypeNeedBackButton = (type: AUTH_TYPE): boolean => {
  return type === AUTH_TYPE.STUDENT_LOGIN || type === AUTH_TYPE.FORGOT_PASSWORD;
};

export const areThirdPartyButtonsNeededForType = (type: AUTH_TYPE): boolean => {
  return isLoginOrSignupAuthType(type);
};

export const isOAuthFlow = (loginOptionsMode: string): boolean => {
  return loginOptionsMode === LOGIN_OPTIONS.OAUTH;
};

export const onFacebookConnect = (redirectUrl: string, status?: boolean): void => {
  if (status) {
    let url = redirectUrl;
    if (window.PMW.USER_PREFERRED_LANGUAGE) {
      url = window.PMW.util.updateLanguageDomainForUrl(redirectUrl, window.PMW.USER_PREFERRED_LANGUAGE);
    }
    redirectToPage(url);
  }
};

export const getOauthWindowProps = (): string => {
  const LOGIN_WINDOW_WIDTH = 500;
  const LOGIN_WINDOW_HEIGHT = 570;
  const screenLeft = window.screenX || window.screenLeft;
  const screenTop = window.screenY || window.screenTop;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const left = screenLeft + (screenWidth - LOGIN_WINDOW_WIDTH) / 2;
  const top = screenTop + (screenHeight - LOGIN_WINDOW_HEIGHT) / 2;
  return `width=${LOGIN_WINDOW_WIDTH},height=${LOGIN_WINDOW_HEIGHT},left=${left},top=${top}`;
};

export const isMobileScreen = (width: number): boolean => {
  return width <= MOBILE_SCREEN_MAX_WIDTH;
};

export const isLoginAuthType = (type: AUTH_TYPE): boolean => {
  return type === AUTH_TYPE.LOGIN;
};

export const isSignupAuthType = (type: AUTH_TYPE): boolean => {
  return type === AUTH_TYPE.SIGNUP;
};

export const isLoginOrSignupAuthType = (type: AUTH_TYPE): boolean => {
  return type === AUTH_TYPE.LOGIN || type === AUTH_TYPE.SIGNUP;
};

export const isLoginOrSignupFormVisible = (subtype: AUTH_SUB_TYPE): boolean => {
  return subtype === AUTH_SUB_TYPE.LOGIN_WITH_FORM || subtype === AUTH_SUB_TYPE.SIGNUP_WITH_FORM;
};
