import {TextHorizontalAlignType, TextStyles} from '@PosterWhiteboard/classes/text-styles.class';
import {colorToRGB, hexToRgb} from '@Utils/color.util';
import {Fill, FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {AuraType, ItemAura} from '@PosterWhiteboard/classes/item-aura.class';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {SubtitleTemplateProperties, SubtitleTemplate} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import type {DeepPartial} from '@/global';

export const getDefaultTranscriptLayoutProperties = (): DeepPartial<SubtitleObject> => {
  const defaultTextStyles = new TextStyles();
  const defaultBackgroundFill = new Fill();
  const defaultAura = new ItemAura();

  const {fontSize, ...otherDefaultTextStyles} = defaultTextStyles.toObject();

  return {
    backgroundBorderRadius: 0,
    sentenceTextStyles: {
      ...otherDefaultTextStyles,
      textAlign: TextHorizontalAlignType.CENTER,
      stroke: false,
      fontWeight: 'normal',
      fontFamily: 'RalewayRegular',
      fill: {
        fillType: FillTypes.NONE,
      },
    },
    activeWordTextStyles: {
      ...otherDefaultTextStyles,
      textAlign: TextHorizontalAlignType.CENTER,
      stroke: false,
      fontWeight: 'normal',
      fontFamily: 'RalewayRegular',
      fill: {
        fillType: FillTypes.NONE,
      },
    },
    backgroundFill: {
      ...defaultBackgroundFill.toObject(),
      fillAlpha: 1,
      fillType: FillTypes.NONE,
    },
    aura: {
      ...defaultAura.toObject(),
      type: AuraType.NONE,
    },
  };
};

export const ALL_TEMPLATE_STYLES: Record<string, SubtitleTemplate> = {
  '1': {
    templateProperties: {
      id: '1',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 1,
    },
    templateStyles: {
      idNormalTemplate: '1',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontWeight: 'bold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontWeight: 'bold',
        },
      },
    },
  },
  '2': {
    templateProperties: {
      id: '2',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 3,
    },
    templateStyles: {
      idNormalTemplate: '2',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 0.75,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontWeight: 'bold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          stroke: true,
          strokeColor: [255, 255, 255],
          strokeWidth: 0.75,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontWeight: 'bold',
        },
      },
    },
  },
  '3': {
    templateProperties: {
      id: '3',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 6,
    },
    templateStyles: {
      idNormalTemplate: '3',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 0.6,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [colorToRGB([250, 221, 85])],
          },
          fontWeight: 'bold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          stroke: true,
          strokeColor: [255, 255, 255],
          strokeWidth: 0.6,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [colorToRGB([5, 34, 170])],
          },
          fontWeight: 'bold',
        },
      },
    },
  },
  '4': {
    templateProperties: {
      id: '4',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 5,
    },
    templateStyles: {
      idNormalTemplate: '4',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontWeight: 'bold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [colorToRGB([250, 221, 85])],
          },
          fontWeight: 'bold',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [colorToRGB([0, 0, 0])],
          fillAlpha: 0.57,
        },
        backgroundBorderRadius: 30,
      },
    },
  },
  '5': {
    templateProperties: {
      id: '5',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 14,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '1',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('CACACA')],
          },
          fontFamily: 'FjallaOneRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'FjallaOneRegular',
          fontWeight: 'bold',
          stroke: true,
          strokeWidth: 0.01,
          strokeColor: hexToRgb('CACACA'),
        },
      },
    },
  },
  '6': {
    templateProperties: {
      id: '6',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 20,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '2',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('CACACA')],
          },
          fontFamily: 'FjallaOneRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFF00')],
          },
          fontFamily: 'FjallaOneRegular',
          fontWeight: 'bold',
          stroke: true,
          strokeWidth: 0.01,
          strokeColor: hexToRgb('CACACA'),
        },
      },
    },
  },
  '7': {
    templateProperties: {
      id: '7',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 4,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '1',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'UbuntuMono',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'UbuntuMono',
        },
      },
    },
  },
  '8': {
    templateProperties: {
      id: '8',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 21,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '2',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFF00')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'UbuntuMono',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('0000FF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'UbuntuMono',
        },
      },
    },
  },
  '9': {
    templateProperties: {
      id: '9',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 16,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '3',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'BebasNeue',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'BebasNeue',
        },
      },
    },
  },
  '10': {
    templateProperties: {
      id: '10',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 2,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '4',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFF00')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'BebasNeue',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('0000FF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'BebasNeue',
        },
      },
    },
  },
  '11': {
    templateProperties: {
      id: '11',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 15,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '5',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFF00')],
          },
          stroke: true,
          strokeColor: [135, 63, 0],
          strokeWidth: 1,
          fontFamily: 'HoltwoodOneSC',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('0000FF')],
          },
          stroke: true,
          strokeColor: [135, 63, 0],
          strokeWidth: 1,
          fontFamily: 'HoltwoodOneSC',
        },
        aura: {
          ...getDefaultTranscriptLayoutProperties().aura,
          type: AuraType.CUSTOM_SHADOW,
          dropShadowAlpha: 1,
          dropShadowAngle: 70,
          dropShadowDistance: 2,
          dropShadowBlur: 0,
          dropShadowColor: [255, 255, 255],
        },
      },
    },
  },
  '12': {
    templateProperties: {
      id: '12',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 7,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '6',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'Bangers',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'Bangers',
        },
        aura: {
          ...getDefaultTranscriptLayoutProperties().aura,
          type: AuraType.CUSTOM_SHADOW,
          dropShadowAlpha: 1,
          dropShadowAngle: 0,
          dropShadowDistance: 0,
          dropShadowBlur: 10,
          dropShadowColor: [255, 255, 255],
        },
      },
    },
  },
  '13': {
    templateProperties: {
      id: '13',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 26,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '7',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFF00')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'Bangers',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('0000FF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'Bangers',
        },
        aura: {
          ...getDefaultTranscriptLayoutProperties().aura,
          type: AuraType.CUSTOM_SHADOW,
          dropShadowAlpha: 1,
          dropShadowAngle: 0,
          dropShadowDistance: 0,
          dropShadowBlur: 10,
          dropShadowColor: [255, 255, 255],
        },
      },
    },
  },
  '14': {
    templateProperties: {
      id: '14',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 32,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '8',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'RobotoBold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'RobotoBold',
        },
        aura: {
          ...getDefaultTranscriptLayoutProperties().aura,
          type: AuraType.CUSTOM_SHADOW,
          dropShadowAlpha: 1,
          dropShadowAngle: 45,
          dropShadowDistance: 4,
          dropShadowBlur: 3,
          dropShadowColor: [0, 0, 0],
        },
      },
    },
  },
  '15': {
    templateProperties: {
      id: '15',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 31,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '9',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.LINEAR_GRADIENT,
            fillColor: [hexToRgb('FFFFFF'), hexToRgb('FFAF00')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'RobotoBold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.LINEAR_GRADIENT,
            fillColor: [hexToRgb('FFFFFF'), hexToRgb('0050FF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 1,
          fontFamily: 'RobotoBold',
        },
        aura: {
          ...getDefaultTranscriptLayoutProperties().aura,
          type: AuraType.CUSTOM_SHADOW,
          dropShadowAlpha: 1,
          dropShadowAngle: 45,
          dropShadowDistance: 4,
          dropShadowBlur: 3,
          dropShadowColor: [0, 0, 0],
        },
      },
    },
  },
  '16': {
    templateProperties: {
      id: '16',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 23,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '10',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [colorToRGB([0, 0, 0])],
          fillAlpha: 0.57,
        },
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFF00')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 0.5,
          fontFamily: 'ArchivoBlackRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('0000FF')],
          },
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 0.5,
          fontFamily: 'ArchivoBlackRegular',
        },
        backgroundBorderRadius: 15,
      },
    },
  },
  '17': {
    templateProperties: {
      id: '17',
      type: SubtitleTemplateType.PROGRESS,
      sortOrder: 17,
    },
    templateStyles: {
      idProgressTemplateStyle: '1',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'BebasNeue',
          stroke: true,
          strokeColor: [255, 255, 255],
          strokeWidth: 0.1,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('00000')],
          },
          fontFamily: 'BebasNeue',
          stroke: true,
          strokeColor: [255, 255, 255],
          strokeWidth: 0.1,
          fontWeight: 'bold',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [colorToRGB([250, 221, 85])],
          fillAlpha: 0.8,
        },
        backgroundBorderRadius: 15,
      },
    },
  },
  '18': {
    templateProperties: {
      id: '18',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 9,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '3',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'BebasNeue',
          stroke: true,
          strokeColor: [255, 255, 255],
          strokeWidth: 0.1,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('00000')],
          },
          fontFamily: 'BebasNeue',
          fontWeight: 'bold',
          stroke: true,
          strokeColor: [255, 255, 255],
          strokeWidth: 0.1,
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('FF9400')],
          fillAlpha: 0.8,
        },
        backgroundBorderRadius: 15,
      },
    },
  },
  '19': {
    templateProperties: {
      id: '19',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 37,
    },
    templateStyles: {
      idNormalTemplate: '5',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'BebasNeue',
          fontWeight: 'bold',
          stroke: true,
          strokeColor: [0, 0, 0],
          strokeWidth: 0.2,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'BebasNeue',
          fontWeight: 'bold',
          stroke: true,
          strokeColor: [255, 255, 255],
          strokeWidth: 0.2,
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('FF9400')],
          fillAlpha: 0.8,
        },
        backgroundBorderRadius: 15,
      },
    },
  },
  '20': {
    templateProperties: {
      id: '20',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 10,
    },
    templateStyles: {
      idNormalTemplate: '6',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('FFFFFF')],
          fillAlpha: 1,
        },
        backgroundBorderRadius: 0,
      },
    },
  },
  '21': {
    templateProperties: {
      id: '21',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 35,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '4',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'QuestrialRegular',
          stroke: true,
          strokeColor: hexToRgb('000000'),
          strokeWidth: 0.75,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FF0000')],
          },
          fontFamily: 'QuestrialRegular',
          fontWeight: 'bold',
          stroke: true,
          strokeColor: hexToRgb('FFFFFF'),
          strokeWidth: 0.75,
        },
      },
    },
  },
  '22': {
    templateProperties: {
      id: '22',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 8,
    },
    templateStyles: {
      idNormalTemplate: '7',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFEBCF')],
          },
          fontFamily: 'IntroRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('E98E0F')],
          },
          fontFamily: 'IntroRegular',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('025B29')],
          fillAlpha: 1,
        },
        backgroundBorderRadius: 28,
      },
    },
  },
  '23': {
    templateProperties: {
      id: '23',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 41,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '11',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('FAD956')],
          fillAlpha: 1,
        },
        backgroundBorderRadius: 0,
      },
    },
  },
  '24': {
    templateProperties: {
      id: '24',
      type: SubtitleTemplateType.PROGRESS,
      sortOrder: 18,
    },
    templateStyles: {
      idProgressTemplateStyle: '2',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('A53AE2')],
          fillAlpha: 1,
        },
        backgroundBorderRadius: 0,
      },
    },
  },
  '25': {
    templateProperties: {
      id: '25',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 40,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '12',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'FuturaLTBook',
          stroke: true,
          strokeWidth: 1,
          strokeColor: hexToRgb('124E2C'),
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'FuturaLTBook',
          stroke: true,
          strokeWidth: 1,
          strokeColor: hexToRgb('124E2C'),
        },
      },
    },
  },
  '26': {
    templateProperties: {
      id: '26',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 22,
    },
    templateStyles: {
      idNormalTemplate: '8',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'SFSpeedwaystar',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'SFSpeedwaystar',
        },
        aura: {
          type: AuraType.CUSTOM_SHADOW,
          dropShadowColor: hexToRgb('32ED34'),
          dropShadowAlpha: 1,
          dropShadowDistance: 5,
          dropShadowAngle: 36,
          dropShadowBlur: 0,
        },
      },
    },
  },
  '27': {
    templateProperties: {
      id: '27',
      type: SubtitleTemplateType.PROGRESS,
      sortOrder: 29,
    },
    templateStyles: {
      idProgressTemplateStyle: '3',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'RasaRegular',
          stroke: true,
          strokeWidth: 1,
          strokeColor: hexToRgb('000000'),
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'RasaRegular',
          stroke: true,
          strokeWidth: 1,
          strokeColor: hexToRgb('000000'),
        },
        aura: {
          type: AuraType.CUSTOM_SHADOW,
          dropShadowColor: hexToRgb('000000'),
          dropShadowAlpha: 1,
          dropShadowDistance: 6,
          dropShadowAngle: 35,
          dropShadowBlur: 4,
        },
      },
    },
  },
  '28': {
    templateProperties: {
      id: '28',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 11,
    },
    templateStyles: {
      idNormalTemplate: '9',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'OutfitRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'OutfitRegular',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('2ECEFF')],
          fillAlpha: 1,
        },
        backgroundBorderRadius: 12,
      },
    },
  },
  '29': {
    templateProperties: {
      id: '29',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 30,
    },
    templateStyles: {
      idNormalTemplate: '10',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'CicleFina',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'CicleFina',
        },
      },
    },
  },
  '30': {
    templateProperties: {
      id: '30',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 19,
    },
    templateStyles: {
      idNormalTemplate: '11',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FDB953')],
          },
          fontFamily: 'LeagueSpartanBold',
          stroke: true,
          strokeColor: hexToRgb('000000'),
          strokeWidth: 0.56,
          letterSpacing: 1,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FF9900')],
          },
          fontFamily: 'LeagueSpartanBold',
          stroke: true,
          strokeColor: hexToRgb('000000'),
          strokeWidth: 0.56,
          letterSpacing: 1,
        },
      },
    },
  },
  '31': {
    templateProperties: {
      id: '31',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 24,
    },
    templateStyles: {
      idNormalTemplate: '12',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'BebasNeueRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FDB953')],
          },
          fontFamily: 'BebasNeueRegular',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('1B5D6A')],
          fillAlpha: 0.57,
        },
        backgroundBorderRadius: 30,
      },
    },
  },
  '32': {
    templateProperties: {
      id: '32',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 13,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '5',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('CACACA')],
          },
          fontFamily: 'FuturaLTRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('B24DCE')],
          },
          fontFamily: 'FuturaLTRegular',
        },
      },
    },
  },
  '33': {
    templateProperties: {
      id: '33',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 25,
    },
    templateStyles: {
      idNormalTemplate: '13',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'SFSpeedwaystar',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('B24DCE')],
          },
          fontFamily: 'SFSpeedwaystar',
        },
        aura: {
          ...getDefaultTranscriptLayoutProperties().aura,
          type: AuraType.CUSTOM_SHADOW,
          dropShadowAlpha: 1,
          dropShadowAngle: 36,
          dropShadowDistance: 5,
          dropShadowBlur: 0,
          dropShadowColor: hexToRgb('FFC882'),
        },
      },
    },
  },
  '34': {
    templateProperties: {
      id: '34',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 27,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '6',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'LiberationSerif',
          stroke: true,
          strokeColor: hexToRgb('000000'),
          strokeWidth: 0.51,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('4FE4EA')],
          },
          fontFamily: 'LiberationSerif',
          stroke: true,
          strokeColor: hexToRgb('000000'),
          strokeWidth: 0.51,
        },
      },
    },
  },
  '35': {
    templateProperties: {
      id: '35',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 38,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '7',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'SourceSansProBlackRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'SourceSansProBlackRegular',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('FFDA2E')],
          fillAlpha: 0.8,
        },
        backgroundBorderRadius: 15,
      },
    },
  },
  '36': {
    templateProperties: {
      id: '36',
      type: SubtitleTemplateType.HIGHLIGHT_WORD,
      sortOrder: 33,
    },
    templateStyles: {
      idHighlighCurrentWordTemplate: '8',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('CACACA')],
          },
          fontFamily: 'Sveningsson',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'Sveningsson',
          stroke: true,
          strokeWidth: 0.15,
          strokeColor: hexToRgb('CACACA'),
        },
      },
    },
  },
  '37': {
    templateProperties: {
      id: '37',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 12,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '13',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('B24DCE')],
          },
          fontFamily: 'LeagueSpartanBold',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('FAD956')],
          fillAlpha: 1,
        },
        backgroundBorderRadius: 0,
      },
    },
  },
  '38': {
    templateProperties: {
      id: '38',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 39,
      isHidden: true,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '14',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'FuturaLTBook',
          stroke: true,
          strokeColor: hexToRgb('124E2C'),
          strokeWidth: 1,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'FuturaLTBook',
          stroke: true,
          strokeColor: hexToRgb('124E2C'),
          strokeWidth: 1,
        },
      },
    },
  },
  '39': {
    templateProperties: {
      id: '39',
      type: SubtitleTemplateType.PROGRESS,
      sortOrder: 42,
    },
    templateStyles: {
      idProgressTemplateStyle: '4',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'Homestead',
          stroke: true,
          strokeColor: hexToRgb('000000'),
          strokeWidth: 1,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'Homestead',
          stroke: true,
          strokeColor: hexToRgb('000000'),
          strokeWidth: 1,
        },
        aura: {
          ...getDefaultTranscriptLayoutProperties().aura,
          type: AuraType.CUSTOM_SHADOW,
          dropShadowAlpha: 0.4,
          dropShadowAngle: 35,
          dropShadowDistance: 6,
          dropShadowBlur: 4,
          dropShadowColor: hexToRgb('000000'),
        },
      },
    },
  },
  '40': {
    templateProperties: {
      id: '40',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 34,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '15',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FAD956')],
          },
          fontFamily: 'chicleregular',
          stroke: true,
          strokeColor: hexToRgb('38500D'),
          strokeWidth: 0.76,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('83CC45')],
          },
          fontFamily: 'chicleregular',
          stroke: true,
          strokeColor: hexToRgb('38500D'),
          strokeWidth: 0.76,
        },
      },
    },
  },
  '41': {
    templateProperties: {
      id: '41',
      type: SubtitleTemplateType.ONE_WORD,
      sortOrder: 28,
    },
    templateStyles: {
      idCurrentWordOnlyTemplate: '16',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'chicleregular',
          stroke: true,
          strokeColor: hexToRgb('CC6400'),
          strokeWidth: 1,
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'chicleregular',
          stroke: true,
          strokeColor: hexToRgb('CC6400'),
          strokeWidth: 1,
        },
      },
    },
  },
  '42': {
    templateProperties: {
      id: '42',
      type: SubtitleTemplateType.SIMPLE,
      sortOrder: 36,
    },
    templateStyles: {
      idNormalTemplate: '14',
      stylesToApply: {
        ...getDefaultTranscriptLayoutProperties(),
        sentenceTextStyles: {
          ...getDefaultTranscriptLayoutProperties().sentenceTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('FFFFFF')],
          },
          fontFamily: 'PressStart2PRegular',
        },
        activeWordTextStyles: {
          ...getDefaultTranscriptLayoutProperties().activeWordTextStyles,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb('000000')],
          },
          fontFamily: 'PressStart2PRegular',
        },
        backgroundFill: {
          ...getDefaultTranscriptLayoutProperties().backgroundFill,
          fillType: FillTypes.SOLID,
          fillColor: [hexToRgb('BF1F4A')],
          fillAlpha: 1,
        },
        backgroundBorderRadius: 12,
      },
    },
  },
};

export const getDefaultSelectedSubtitleTemplateProperties = (): SubtitleTemplateProperties => {
  return ALL_TEMPLATE_STYLES['1'].templateProperties;
};
