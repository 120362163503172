import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  doCloseDropdown: boolean;
  colorForColorPicker: string;
}

const initialState: StateProps = {
  doCloseDropdown: false,
  colorForColorPicker: '',
};

export const emailMakerColorPickerDropdownSlice = createSlice({
  name: 'emailMakerColorPickerDropdown',
  initialState,
  reducers: {
    updateDoCloseDropdown(state, action: PayloadAction<boolean>) {
      state.doCloseDropdown = action.payload;
    },
    updateColorForColorPicker(state, action: PayloadAction<string>) {
      state.colorForColorPicker = action.payload;
    },
  },
});

export const {updateDoCloseDropdown, updateColorForColorPicker} = emailMakerColorPickerDropdownSlice.actions;
export const emailMakerColorPickerDropdownReducer = emailMakerColorPickerDropdownSlice.reducer;
