import {initAligningGuidelines} from '@ThirdParty/aligning_guidelines';
import {noop} from '@Utils/general.util';
import {ActiveSelection, Canvas, type FabricObject} from '@postermywall/fabricjs-2';
import type {Page} from './page.class';

const ALIGNING_LINE_COLOR = '#FF5177';

export class RenderAlignmentGuidelines {
  public page: Page;
  public disableAlignmentGuides = noop;

  constructor(page: Page) {
    this.page = page;
    if (this.page.poster.alignmentGuides.enabled) {
      this.enableAligningGuidelines();
    }
  }

  public enableAligningGuidelines(): void {
    if (this.page.fabricCanvas instanceof Canvas) {
      this.disableAlignmentGuides = initAligningGuidelines(this.page.fabricCanvas, {
        color: ALIGNING_LINE_COLOR,
        margin: 3,
        getObjectsByTarget: this.getObjectsByTarget.bind(this),
      });
    }
  }

  private getObjectsByTarget(target: FabricObject): Set<FabricObject> {
    const objects = new Set<FabricObject>();
    const canvas = target.canvas;
    if (!canvas) return objects;
    const children = target instanceof ActiveSelection ? target.getObjects() : [target];

    canvas.forEachObject((o) => {
      if (!o.isOnScreen()) return;
      if (!o.visible) return;
      if (!o.__PMWID) return;
      objects.add(o);
    });

    this.deleteObjectsByList(objects, children);
    return objects;
  }

  private deleteObjectsByList(objects: Set<FabricObject>, list: FabricObject[]): void {
    for (const target of list) {
      objects.delete(target);
    }
  }

  public disableAligningGuidelines(): void {
    this.disableAlignmentGuides();
    this.disableAlignmentGuides = noop;
  }
}
