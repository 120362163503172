import type {SocialMediaProfileLinksMap, SocialMediaBaseURLsMap, SocialMediaLinksValidationResult} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {SocialMediaPlatformBaseURL, SocialMediaProfile} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {hasInvalidCharacters, isValidURL} from '@Utils/url.util';
import {repoURL} from '@Libraries/s3-library';
import {getReadBucket} from '@Utils/s3.util';

export const initSocialMediaProfileLinks = (): SocialMediaProfileLinksMap => {
  return {
    [SocialMediaProfile.INSTAGRAM]: '',
    [SocialMediaProfile.FACEBOOK]: '',
    [SocialMediaProfile.X]: '',
    [SocialMediaProfile.YOUTUBE]: '',
    [SocialMediaProfile.TIKTOK]: '',
    [SocialMediaProfile.PINTEREST]: '',
    [SocialMediaProfile.LINKEDIN]: '',
  };
};

export const initSocialMediaLinksErrorStates = (): Record<SocialMediaProfile, boolean> => {
  return {
    [SocialMediaProfile.INSTAGRAM]: false,
    [SocialMediaProfile.FACEBOOK]: false,
    [SocialMediaProfile.X]: false,
    [SocialMediaProfile.YOUTUBE]: false,
    [SocialMediaProfile.TIKTOK]: false,
    [SocialMediaProfile.PINTEREST]: false,
    [SocialMediaProfile.LINKEDIN]: false,
  };
};

export const initSocialMediaLinksVisibilityStates = (socialMediaProfileLinks: SocialMediaProfileLinksMap): Record<SocialMediaProfile, boolean> => {
  return {
    [SocialMediaProfile.INSTAGRAM]: true,
    [SocialMediaProfile.FACEBOOK]: true,
    [SocialMediaProfile.X]: socialMediaProfileLinks[SocialMediaProfile.X] !== '',
    [SocialMediaProfile.YOUTUBE]: socialMediaProfileLinks[SocialMediaProfile.YOUTUBE] !== '',
    [SocialMediaProfile.TIKTOK]: socialMediaProfileLinks[SocialMediaProfile.TIKTOK] !== '',
    [SocialMediaProfile.PINTEREST]: socialMediaProfileLinks[SocialMediaProfile.PINTEREST] !== '',
    [SocialMediaProfile.LINKEDIN]: socialMediaProfileLinks[SocialMediaProfile.LINKEDIN] !== '',
  };
};

export const getSocialMediaBaseURLs = (): SocialMediaBaseURLsMap => {
  return {
    [SocialMediaProfile.INSTAGRAM]: SocialMediaPlatformBaseURL.INSTAGRAM,
    [SocialMediaProfile.FACEBOOK]: SocialMediaPlatformBaseURL.FACEBOOK,
    [SocialMediaProfile.X]: SocialMediaPlatformBaseURL.X,
    [SocialMediaProfile.YOUTUBE]: SocialMediaPlatformBaseURL.YOUTUBE,
    [SocialMediaProfile.TIKTOK]: SocialMediaPlatformBaseURL.TIKTOK,
    [SocialMediaProfile.PINTEREST]: SocialMediaPlatformBaseURL.PINTEREST,
    [SocialMediaProfile.LINKEDIN]: SocialMediaPlatformBaseURL.LINKEDIN,
  };
};

export const initSocialMediaAccountsUsernames = (): Record<SocialMediaProfile, string[]> => {
  return {
    [SocialMediaProfile.INSTAGRAM]: [],
    [SocialMediaProfile.FACEBOOK]: [],
    [SocialMediaProfile.X]: [],
    [SocialMediaProfile.YOUTUBE]: [],
    [SocialMediaProfile.TIKTOK]: [],
    [SocialMediaProfile.PINTEREST]: [],
    [SocialMediaProfile.LINKEDIN]: [],
  };
};

export const isValidSocialMediaUsername = (platform: string, input: string): boolean => {
  const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
  return usernameRegex.test(input) && !input.includes(platform);
};

export const formatSocialMediaLinks = (socialMediaLinks: Record<SocialMediaProfile, string>): Record<SocialMediaProfile, string> => {
  const formattedUrls: Record<SocialMediaProfile, string> = initSocialMediaProfileLinks();

  Object.entries(socialMediaLinks).forEach(([platform, socialMediaUrl]) => {
    formattedUrls[platform as SocialMediaProfile] = formatSocialMediaURL(socialMediaUrl);
  });

  return formattedUrls;
};

export const validateSocialMediaLinks = (socialMediaLinks: SocialMediaProfileLinksMap): SocialMediaLinksValidationResult => {
  const errorMap = initSocialMediaLinksErrorStates();
  const socialMediaBaseUrls = getSocialMediaBaseURLs();
  const validatedUrls: Record<SocialMediaProfile, string> = initSocialMediaProfileLinks();

  let allValid = true;

  Object.entries(socialMediaLinks).forEach(([platform, inputValue]) => {
    const socialPlatform = platform as SocialMediaProfile;
    const validatedURL = getValidatedURLForSocialPlatform(socialMediaBaseUrls, socialPlatform, inputValue);

    if (validatedURL !== null) {
      validatedUrls[socialPlatform] = validatedURL;
    } else {
      errorMap[socialPlatform] = true;
      allValid = false;
    }
  });

  return {
    isValid: allValid,
    validatedUrls,
    errorMap,
  };
};

export const getValidatedURLForSocialPlatform = (socialMediaBaseUrls: SocialMediaProfileLinksMap, platform: SocialMediaProfile, inputValue: string): string | null => {
  let normalizedInput = inputValue.trim();
  normalizedInput = normalizedInput.startsWith('@') ? normalizedInput.slice(1) : normalizedInput;
  const socialPlatformName = platform.split('-')[0];

  if (normalizedInput === '') {
    return '';
  }

  if (isValidSocialMediaUsername(socialPlatformName, normalizedInput)) {
    return getValidatedUsernameForPlatform(socialMediaBaseUrls, platform, normalizedInput);
  }

  if (isValidSocialMediaUrl(socialPlatformName, normalizedInput)) {
    return normalizedInput;
  }

  return null;
};

export const getValidFormattedURLForSocialPlatform = (socialPlatform: SocialMediaProfile, url: string): string => {
  const validatedURL = getValidatedURLForSocialPlatform(getSocialMediaBaseURLs(), socialPlatform, url);
  return formatSocialMediaURL(validatedURL ?? '');
};

export const formatSocialMediaURL = (socialMediaUrl: string): string => {
  if (socialMediaUrl.startsWith('http')) {
    return socialMediaUrl;
  }

  return socialMediaUrl !== '' ? `https://${socialMediaUrl}` : '';
};

export const isValidSocialMediaUrl = (platform: string, url: string): boolean => {
  const urlParts = url.split('/');
  if (!urlParts[urlParts.length - 1] || urlParts[urlParts.length - 1] === '') {
    return false;
  }
  return url.includes(`${platform}.com`) && isValidURL(url) && !hasInvalidCharacters(url);
};

export const getSocialMediaTypeMap = (): Record<string, SocialMediaProfile> => {
  return {
    instagram: SocialMediaProfile.INSTAGRAM,
    'ig-business': SocialMediaProfile.INSTAGRAM,
    facebook: SocialMediaProfile.FACEBOOK,
    fb_profile: SocialMediaProfile.FACEBOOK,
    fb_page: SocialMediaProfile.FACEBOOK,
    fb_group: SocialMediaProfile.FACEBOOK,
    x: SocialMediaProfile.X,
    twitter: SocialMediaProfile.X,
    youtube: SocialMediaProfile.YOUTUBE,
    'youtube-channel': SocialMediaProfile.YOUTUBE,
    tiktok: SocialMediaProfile.TIKTOK,
    'tiktok-profile': SocialMediaProfile.TIKTOK,
    pinterest: SocialMediaProfile.PINTEREST,
    'linkedin-page': SocialMediaProfile.LINKEDIN,
    'linkedin-profile': SocialMediaProfile.LINKEDIN,
  };
};

const getValidatedUsernameForPlatform = (socialMediaBaseUrls: SocialMediaProfileLinksMap, platform: SocialMediaProfile, username: string): string => {
  return `${socialMediaBaseUrls[platform]}/${platform === SocialMediaProfile.TIKTOK ? `@${username}` : username}`;
};

export const getSocialIconPath = (iconName: string): string => {
  return repoURL(`assets/social-icons/original/${iconName}.png`, getReadBucket());
};
