import {Page} from '@PosterWhiteboard/page/page.class';
import type {PosterPageObject} from '@PosterWhiteboard/poster/poster.types';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import type {PageObject} from '@PosterWhiteboard/page/page.types';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {DeepPartial} from '@/global';
import {noop} from 'lodash';

interface AddPageOpts {
  undoable?: boolean;
  updateRedux?: boolean;
  pageOrder?: number;
  onItemAddFail?: () => void;
}

export class PosterPages {
  public pagesHashMap: Record<string, Page> = {};
  public pageOrder: string[] = [];
  public poster: Poster;

  public constructor(poster: Poster) {
    this.poster = poster;
  }

  public async addPage(pageObject: DeepPartial<PageObject> = {}, {updateRedux = true, undoable = true, pageOrder, onItemAddFail = noop}: AddPageOpts = {}): Promise<Page> {
    const page = new Page(this.poster, pageObject.hashedID);
    page.applyScale();
    this.pagesHashMap[page.hashedID] = page;
    // TODO add possible race for initial mutliple pages not loading in order
    if (pageOrder && pageOrder < this.pageOrder.length) {
      this.pageOrder.splice(pageOrder, 0, page.hashedID);
    } else {
      this.pageOrder = [...this.pageOrder, page.hashedID];
    }
    page.poster.setCurrentPage(page.hashedID);

    await page.updateFromObject(pageObject, {undoable: false, updateRedux: false, onItemAddFail});

    if (undoable) {
      this.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.poster.redux.updateReduxData();
    }
    return page;
  }

  public toObject(): PosterPageObject {
    const pageObjects: Record<string, PageObject> = {};

    for (const [key, page] of Object.entries(this.pagesHashMap)) {
      pageObjects[key] = page.toObject();
    }

    return {
      pagesHashMap: pageObjects,
      pageOrder: this.pageOrder,
    };
  }

  public getTotalDuration(): number {
    let totalDuration = 0;
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      totalDuration += page.getDuration();
    }
    return totalDuration;
  }

  public async updateFromObject(
    posterPagesObject: DeepPartial<PosterPageObject>,
    {updateRedux = true, undoable = true, refreshActiveSelection = false, checkForDurationUpdate = false, onItemAddFail = noop}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    if (!posterPagesObject.pagesHashMap) {
      return;
    }

    // Update/Add pages from pagesObject
    const promises = [];
    for (const [hashId, pageObject] of Object.entries(posterPagesObject.pagesHashMap)) {
      if (this.pagesHashMap[hashId] !== undefined) {
        promises.push(this.pagesHashMap[hashId].updateFromObject(pageObject, {updateRedux: false, undoable: false, refreshActiveSelection, onItemAddFail, checkForDurationUpdate}));
      } else {
        promises.push(this.addPage(pageObject, {updateRedux: false, undoable: false, onItemAddFail}));
      }
    }

    // delete pages that are in the poster but not in the pagesObject
    for (const [hashId] of Object.entries(this.pagesHashMap)) {
      if (posterPagesObject.pagesHashMap[hashId] === undefined) {
        promises.push(this.doRemovePage(hashId));
      }
    }
    await Promise.all(promises);

    if (undoable) {
      this.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.poster.redux.updateReduxData();
    }
  }

  public async removePage(itemUid: string): Promise<void> {
    if (this.hasPage(itemUid)) {
      await this.doRemovePage(itemUid);
      this.poster.history.addPosterHistory();
      this.poster.redux.updateReduxData();
    }
  }

  public hasPage(hashId: string): boolean {
    return this.pagesHashMap[hashId] !== undefined;
  }

  public getPage(hashId: string): Page | undefined {
    if (!this.hasPage(hashId)) {
      return undefined;
    }

    return this.pagesHashMap[hashId];
  }

  public applyScales(): void {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      page.applyScale();
    }
  }

  public convertTransparentBackgroundsToSolid(): void {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      if (page.background.isTransparentBackground()) {
        page.background.updateBackgroundToBeNonTransparent();
      }
    }
  }

  public getItemForId(uid: string): ItemType | undefined {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      if (page.items.hasItem(uid)) {
        return page.items.getItem(uid);
      }
    }
    return undefined;
  }

  public getPageWithItemId(uid: string): Page | undefined {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      if (page.items.hasItem(uid)) {
        return page;
      }
    }
    return undefined;
  }

  public hasMultiplePages(): boolean {
    return Object.keys(this.pagesHashMap).length > 1;
  }

  public hasVideoPage(): boolean {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      if (page.isVideo()) {
        return true;
      }
    }

    return false;
  }

  public isPremium(): boolean {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      if (page.isPremium()) {
        return true;
      }
    }

    return false;
  }

  public clearSelection(): void {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      page.clearSelection();
    }
  }

  public disposeCanvas(): void {
    for (const [, page] of Object.entries(this.pagesHashMap)) {
      page.dispose();
    }
  }

  private async doRemovePage(hashId: string): Promise<void> {
    if (this.hasPage(hashId)) {
      await this.pagesHashMap[hashId].fabricCanvas.dispose();
      // this.page.fabricCanvas.remove(this.pagesHashMap[itemUid].fabricObject);
      delete this.pagesHashMap[hashId];
      this.pageOrder = this.pageOrder.filter((id) => {
        return id !== hashId;
      });
    }
  }

  public getPageOrder(pageHashId: string): number | undefined {
    const pageOrder = this.pageOrder.indexOf(pageHashId);
    return pageOrder !== -1 ? pageOrder : undefined;
  }

  public getPagesInOrder(): Page[] {
    const pages = [];
    for (const pageId of this.pageOrder) {
      pages.push(this.pagesHashMap[pageId]);
    }

    return pages;
  }
}
