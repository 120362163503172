import {getCompatibleImageFileExtension} from '@Utils/image.util';
import {repoURL} from '@Libraries/s3-library';
import {getWriteBucket} from '@Utils/s3.util';

// TODO: Remove this and only have user-image-library.ts. Reanme that to a better name too
/**
 * S3 directory names for images
 * @type {string}
 */
const IMAGE_BUCKET = 'uploads';
const IMAGE_THUMB_SIZE_BUCKET = `${IMAGE_BUCKET}/thumbs`;

export const repoImageThumbURL = (hashedFilename: string, fileExtension: string) => {
  if (hashedFilename && fileExtension) {
    if (fileExtension === 'svg') {
      fileExtension = 'png';
    }

    return repoURL(`${IMAGE_THUMB_SIZE_BUCKET}/${hashedFilename}.${getCompatibleImageFileExtension(fileExtension)}`, getWriteBucket());
  }
  return '';
};
