import type {ReactElement} from 'react';
import React from 'react';
import {Circle} from '@Components/circular-progress/components/circle/circle';
import {cleanPercentage} from '@Components/circular-progress/components/circle/circle.helper';

interface CircularProgressWithChildrenProps {
  className?: string;
  strokeWidth: number;
  diameter: number;
  children?: ReactElement | ReactElement[];
  baseColor: string;
  fillColor: string;
  value: number;
  fill?: string;
}

export function CircularProgressWithChildren({className = '', ...props}: CircularProgressWithChildrenProps): ReactElement {
  return (
    <div
      style={{
        width: `${props.diameter}px`,
        height: `${props.diameter}px`,
      }}
      className={'_position-relative'}
    >
      <svg className={className} width={props.diameter} height={props.diameter}>
        <g transform={`rotate(-90 ${props.diameter / 2} ${props.diameter / 2})`}>
          <Circle fill={props.fill} percentage={cleanPercentage(100)} strokeWidth={props.strokeWidth} circleDiameter={props.diameter} color={props.baseColor} />
          <Circle percentage={cleanPercentage(props.value)} strokeWidth={props.strokeWidth} circleDiameter={props.diameter} color={props.fillColor} />
        </g>
      </svg>
      {props.children ? (
        <div
          style={{
            left: 0,
            top: 0,
          }}
          className={`_position-absolute _full-width _full-height flex-center`}
        >
          {props.children}
        </div>
      ) : null}
    </div>
  );
}
