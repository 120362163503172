import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE, ItemLoadingProgressType} from '@PosterWhiteboard/items/item/item.types';
import type {RGB} from '@Utils/color.util';
import {rgbToHex} from '@Utils/color.util';
import {getDefaultFontSizeForFontFamily, getFancyTextEffectForId, getFancyTextUrl, MorphType} from '@Libraries/fancy-text-library';
import {degToRad} from '@Utils/math.util';
import {openPosterEditorEditFancyTextModal} from '@Modals/poster-editor-fancy-text-modal';
import {loadImageAsync} from '@Utils/image.util';
import {FabricImage} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';

const SCREEN_SIZE_SCALE = 0.7;
/**
 * Min scale for highres. Equal to the screen size scale
 * as image for that would be cached and loaded instantly
 */
const MIN_HR_SCALE = SCREEN_SIZE_SCALE;
const MAX_HR_SCALE = 2.2;
const VERSION_2 = 2;
const DEFAULT_HEX = '000000';
const MAX_COLORS_AMOUNT = 3;

export interface FancyTextItemObject extends BaseItemObject {
  idFancyText: string;
  text: string;
  fontFamily: string;
  colors: RGB[];
  morphType: MorphType;
  morphAmount: number;
}

export class FancyTextItem extends Item {
  declare fabricObject: FabricImage;
  public idFancyText!: string;
  public text!: string;
  public fontFamily!: string;
  public colors: RGB[] = [];
  public morphType = MorphType.NONE;
  public morphAmount = 0;
  public gitype = ITEM_TYPE.FANCY_TEXT;
  public version = VERSION_2;
  public imageElement!: HTMLImageElement;

  private initialDimensions = {
    width: 0,
    height: 0,
  };

  protected fixChanges(): void {
    super.fixChanges();
    this.applyFixForVersion2();
  }

  public toObject(): FancyTextItemObject {
    return {
      ...super.toObject(),
      idFancyText: this.idFancyText,
      text: this.text,
      fontFamily: this.fontFamily,
      colors: this.colors,
      morphType: this.morphType,
      morphAmount: this.morphAmount,
    };
  }

  public async init(): Promise<void> {
    if (!this.isInitialzed) {
      this.fixChanges();
      this.initialDimensions = {
        width: this.width,
        height: this.height,
      };
      this.beforeInitFabricObject();
      await this.initFabricObject();
      this.isInitialzed = true;
    }
  }

  protected async reInitFabricObject(): Promise<void> {
    this.loading.startLoading({
      langTextKeys: ['pmwjs_updating_percentage'],
      progress: {
        type: ItemLoadingProgressType.FAKE,
        expectedTotalTime: 1,
      },
    });
    try {
      await super.reInitFabricObject();
    } finally {
      this.loading.removeLoading();
    }
  }

  public async formatItemObjectWithDefaultValues(obj: DeepPartial<FancyTextItemObject>): Promise<DeepPartial<FancyTextItemObject>> {
    if (!obj.idFancyText) {
      throw new Error('no idFancyText for new fancy text item');
    }

    const fancyTextEffect = await getFancyTextEffectForId(obj.idFancyText);
    return {
      colors: fancyTextEffect.defaultColors,
      fontFamily: fancyTextEffect.defaultFamilyName,
      ...obj,
    };
  }

  protected itemObjectHasDestructiveChanges(oldItemObject: FancyTextItemObject): boolean {
    return (
      oldItemObject.idFancyText !== this.idFancyText ||
      oldItemObject.text !== this.text ||
      oldItemObject.fontFamily !== this.fontFamily ||
      oldItemObject.colors !== this.colors ||
      oldItemObject.morphType !== this.morphType ||
      oldItemObject.morphAmount !== this.morphAmount
    );
  }

  protected async getFabricObjectForItem(): Promise<FabricImage> {
    await this.loadImageItem();
    return new FabricImage(this.imageElement, {
      ...super.getCommonOptions(),
    });
  }

  private async loadImageItem(): Promise<void> {
    this.imageElement = await loadImageAsync(this.getImageUrl());
    this.scaleLoadedImage();
  }

  private scaleLoadedImage(): void {
    if (this.width && this.height && this.doesLoadedImageHaveDifferentDimensions()) {
      const scale = (this.scaleX * this.width) / this.getImageDisplayWidth();
      this.scaleX = scale;
      this.scaleY = scale;
    }

    this.width = this.getImageDisplayWidth();
    this.height = this.getImageDisplayHeight();
  }

  private doesLoadedImageHaveDifferentDimensions(): boolean {
    return this.width !== this.getImageDisplayHeight() || this.height !== this.getImageDisplayHeight();
  }

  protected getImageDisplayHeight(): number {
    return this.imageElement.height;
  }

  protected getImageDisplayWidth(): number {
    return this.imageElement.width;
  }

  private applyFixForVersion2(): void {
    if (this.version < VERSION_2) {
      const changeInHeightForVersion2 = 8;
      const deltaY = changeInHeightForVersion2 * this.scaleY * this.getScaleForImageGeneration();
      const cosOfAngle = Math.cos(degToRad(this.rotation));
      const sinOfAngle = Math.sin(degToRad(this.rotation));

      this.x += sinOfAngle * deltaY;
      this.y -= cosOfAngle * deltaY;
      this.version = VERSION_2;
    }
  }

  private getImageUrl(): string {
    return getFancyTextUrl(
      this.idFancyText,
      this.fontFamily,
      getDefaultFontSizeForFontFamily(this.fontFamily),
      this.getScaleForImageGeneration(),
      this.text,
      this.getColorHexes(),
      this.morphType,
      this.morphAmount,
      this.version
    );
  }

  private getScaleForImageGeneration(): number {
    if (this.page.poster.isHighRes) {
      return this.getScaleForHrImageGeneration();
    }

    return SCREEN_SIZE_SCALE;
  }

  private getColorHexes(): string[] {
    const colorHexes = [];
    for (const color of this.colors) {
      colorHexes.push(rgbToHex(color));
    }

    const lengthOfColorHexes = colorHexes.length;

    for (let index = 0; index < MAX_COLORS_AMOUNT - lengthOfColorHexes; index += 1) {
      colorHexes.push(DEFAULT_HEX);
    }

    return colorHexes;
  }

  /**
   * Calculates the scale for highres by seeing the original width of screen size image and the footprint
   * of item on canvas. The scale is maxed out for the value of MAX_HR_SCALE constant
   * @return {number}
   * @private
   */
  private getScaleForHrImageGeneration(): number {
    const itemHrWidth = this.getScaledWidth() * this.page.poster.scaling.scale;
    return Math.max(MIN_HR_SCALE, Math.min(MAX_HR_SCALE, Math.round((SCREEN_SIZE_SCALE / this.initialDimensions.width) * itemHrWidth * 100) / 100));
  }

  protected onItemDoubleTapped(): void {
    openPosterEditorEditFancyTextModal();
  }

  public updateFancyTextEffect(id: string, colors: RGB[]): Promise<void> {
    return this.updateFromObject({
      idFancyText: id,
      colors,
    });
  }

  public updateFancyTextFont(fontFamily: string): Promise<void> {
    return this.updateFromObject({
      fontFamily,
    });
  }

  public updateTextContent(value: string, undoable = true): void {
    void this.updateFromObject(
      {
        text: value,
      },
      {
        undoable,
      }
    );
  }

  public isMorphAmountApplicable(): boolean {
    return this.morphType === MorphType.CURVE_UP || this.morphType === MorphType.CURVE_DOWN || this.morphType === MorphType.WAVE;
  }
}
