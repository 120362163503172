import type {MouseEvent, ReactElement, ReactNode} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import type {Poster, PosterOpts} from '@PosterWhiteboard/poster/poster.class';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';
import {PageOptions} from '@Components/poster-editor/components/page-options';
import {AddNewPageButton} from '@Components/poster-editor/components/add-new-page-button';
import {PosterPopupMenu} from '@Components/poster-editor/components/poster-popup-menu';
import {getChildElementWithMaxVisibleHeight} from '@Utils/dom.util';
import {TextItemPopup} from '@Components/poster-editor/components/text-item-popup';
import './poster-whiteboard.scss';
import {createPoster, loadPoster} from '@PosterWhiteboard/poster/poster-init';
import {PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import type {PosterTypeBackendObject} from '@PosterWhiteboard/poster/poster-backend.types';
import {ClickableDiv} from '@Components/clickable-div';
import {
  updateCanvasDimensions,
  updatePosterWhiteboardContainerDimensions,
  updatePosterWhiteboardDimensions,
  updateSpellCheckSettingsState,
} from '@Components/poster-editor/poster-editor-reducer';
import DragDropOverlay from '@Components/poster-editor/components/drag-drop-overlay';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {PosterWhiteboardLoader} from '@Components/poster-whiteboard/components/poster-whiteboard-loader';
import {watchIsAudioPlayable} from '@Utils/audio.util';
import {initFontsAndCategoriesAndScript} from '@Libraries/font-library';
import {checkClipboardReadPermissions} from '@Libraries/clipboard-library';
import {ImageItemPopup} from '@Components/poster-editor/components/image-item-popup';
import {PosterVerticalScroll, scrollPosterVerticalWithDelta} from '@Components/poster-whiteboard/components/poster-vertical-scroll';
import {PosterHorizontalScroll, scrollPosterHorizontalWithDelta} from '@Components/poster-whiteboard/components/poster-horizontal-scroll';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';
import {debounce} from '@Utils/misc.util';
import {config} from '@postermywall/fabricjs-2';
import {addElementToNotifyTest, TestNotificationId} from '@Libraries/test.library';
import styles from './poster-whiteboard.module.scss';
import {useAppDispatch, useAppSelector} from '@/hooks';

// TODO: Move this to a better place
window.isPosterMaker = true;
window.posterEditor = {
  whiteboard: undefined,
  config: undefined,
  elements: {},
};

interface PosterWhiteboardProps {
  className?: string;
  isCenterAligned?: boolean;
  isMobileDisplay?: boolean;
  whiteboardClassName?: string;
  postWhiteboardElement?: ReactNode;
  postWhiteboardContainerElement?: ReactNode;
  posterOpts: PosterOpts;

  onInit?(): void;

  onError?(): void;
}

export type InitPosterOpts = LoadPosterOpts | CreatePosterOpts;

export interface LoadPosterOpts {
  posterHashId: string;
}

export interface CreatePosterOpts {
  posterType: PosterTypeBackendObject;
}

export function PosterWhiteboard({
  className = '',
  isCenterAligned = true,
  isMobileDisplay = false,
  whiteboardClassName = '',
  onInit = (): void => {},
  onError = (): void => {},
  ...props
}: PosterWhiteboardProps): ReactElement {
  const posterEl = useRef<HTMLDivElement>(null);
  const postPosterElement = useRef<HTMLDivElement>(null);
  const whiteboardContainerRef = useRef<HTMLDivElement>(null);
  const whiteboardRef = useRef<HTMLDivElement>(null);
  const [isPosterLoading, setIsPosterLoading] = useState(false);
  const isPickingModeEnabled = useAppSelector((state) => {
    return state.posterEditor.isPickingModeEnabled;
  });
  const showFakeScrollBars = useAppSelector((state) => {
    return state.posterEditor.posterObject?.mode.details.type !== PosterModeType.GENERATE;
  });
  const isDrawModeEnabled = useAppSelector((state) => {
    return state.posterEditor.posterObject?.drawing.isDrawModeOn;
  });
  const dispatch = useAppDispatch();
  const posterRef = useRef<Poster | undefined>();

  useEffect(() => {
    watchIsAudioPlayable();
    let mountedRef = true;
    let resizeObserverWhiteboard: ResizeObserver | undefined;
    let resizeObserverWhiteboardContainer: ResizeObserver | undefined;
    let resizeObserverCanvas: ResizeObserver | undefined;
    if (whiteboardContainerRef.current && whiteboardRef.current && posterEl.current && postPosterElement.current && window.posterEditor) {
      window.posterEditor.elements[GlobalPosterEditorJqueryElement.WHITEBOARD_CONTAINER] = $(whiteboardContainerRef.current);
      window.posterEditor.elements[GlobalPosterEditorJqueryElement.POST_POSTER_ELEMENT] = $(postPosterElement.current);
      window.posterEditor.elements[GlobalPosterEditorJqueryElement.WHITEBOARD] = $(whiteboardRef.current);

      resizeObserverWhiteboard = new ResizeObserver(debounce(onWhiteboardResize, 100));
      resizeObserverWhiteboardContainer = new ResizeObserver(debounce(onWhiteboardContainerResize, 100));
      resizeObserverCanvas = new ResizeObserver(debounce(onCanvasResize, 100));

      resizeObserverWhiteboard.observe(whiteboardRef.current);
      resizeObserverWhiteboardContainer.observe(whiteboardContainerRef.current);
      resizeObserverCanvas.observe(posterEl.current);
    }
    whiteboardContainerRef.current?.addEventListener('wheel', onPosterScroll);

    initPoster()
      .then((p) => {
        posterRef.current = p;
        if (mountedRef) {
          setGlobalVariables();
        } else {
          p.dispose();
        }
        onInit();
      })
      .catch((e) => {
        // TODO: Show error in UI
        console.error(e);
        onError();
      })
      .finally(() => {
        addElementToNotifyTest(TestNotificationId.POSTER_INITIALIZED);
      });

    return (): void => {
      resizeObserverWhiteboard?.disconnect();
      resizeObserverWhiteboardContainer?.disconnect();
      resizeObserverCanvas?.disconnect();
      mountedRef = false;
      whiteboardContainerRef.current?.removeEventListener('wheel', onPosterScroll);
      posterRef.current?.dispose();
    };
  }, []);

  const onWhiteboardResize = (): void => {
    if (whiteboardRef.current) {
      const rect = whiteboardRef.current.getBoundingClientRect();
      dispatch(
        updatePosterWhiteboardDimensions({
          width: rect.width,
          height: rect.height,
        })
      );
    }
  };

  const onWhiteboardContainerResize = (): void => {
    if (whiteboardContainerRef.current) {
      const rect = whiteboardContainerRef.current.getBoundingClientRect();
      dispatch(
        updatePosterWhiteboardContainerDimensions({
          width: rect.width,
          height: rect.height,
        })
      );
    }
  };

  const onCanvasResize = (): void => {
    if (posterEl.current) {
      const rect = posterEl.current.getBoundingClientRect();
      dispatch(
        updateCanvasDimensions({
          width: rect.width,
          height: rect.height,
        })
      );
    }
  };

  const onPosterScroll = (e: WheelEvent): void => {
    if (window.posterEditor?.whiteboard?.hasFakeScrollbars()) {
      e.stopPropagation();
      e.preventDefault();
      scrollPosterVerticalWithDelta(e.deltaY);
      scrollPosterHorizontalWithDelta(e.deltaX);
    }
    window.posterEditor?.whiteboard?.scaling.zoomOnWheelEvent(e);
  };

  const initPoster = async (): Promise<Poster> => {
    await initFontsAndCategoriesAndScript();
    await checkClipboardReadPermissions();
    if (isEditorMobileVariant()) {
      config.disableGroupSelector = true;
    }
    let poster;
    if (props.posterOpts.mode.details.type === PosterModeType.CREATE) {
      poster = await createNewPoster();
    } else {
      poster = await loadExistingPoster();
    }
    poster.updateTitle();
    if (!poster.mode.isGeneration()) {
      void poster.play();
    }
    return poster;
  };

  const createNewPoster = async (): Promise<Poster> => {
    if (!posterEl.current) {
      throw new Error('No html element to init poster with');
    }

    setIsPosterLoading(true);
    const poster = await createPoster(posterEl.current, props.posterOpts);
    setIsPosterLoading(false);
    setGlobalVariables();
    trackPosterBuilderGA4Events(GA4EventName.CREATE_POSTER);
    return poster;
  };

  const loadExistingPoster = async (): Promise<Poster> => {
    if (!posterEl.current) {
      throw new Error('No html element to init poster with');
    }

    setIsPosterLoading(true);
    const poster = await loadPoster(
      posterEl.current,
      {
        onBackendObjectLaoded: () => {
          setIsPosterLoading(false);
        },
      },
      props.posterOpts
    );
    setGlobalVariables();
    return poster;
  };

  const setGlobalVariables = (): void => {
    if (whiteboardContainerRef.current && window.posterEditor) {
      let isScrolling: NodeJS.Timeout;
      const whiteboardContainerElement = whiteboardContainerRef.current;

      // CodeReviewDany: Dude this listener isn't suppose to be in setGlobalVariables
      whiteboardContainerElement.addEventListener('scroll', () => {
        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
          const pageContainerElementHeight = $('.pmw-page-container').first().outerHeight(true) ?? 0;
          const canvasContainers = Array.from(document.getElementsByClassName('canvas-container')) as Array<HTMLElement>;

          const startPageCheckFrom = pageContainerElementHeight > 0 ? Math.floor(whiteboardContainerElement.scrollTop / pageContainerElementHeight) : 0;
          const activePageElement = getChildElementWithMaxVisibleHeight(canvasContainers.slice(startPageCheckFrom), whiteboardContainerElement);

          const newActivePageId = activePageElement.closest('.pmw-page-container')?.getAttribute('data-id');

          if (newActivePageId) {
            window.posterEditor?.whiteboard?.setCurrentPage(newActivePageId);
          }
        }, 500);
      });
    }
  };

  const onWhiteboardContainerClick = (e: MouseEvent): void => {
    if (e.target === whiteboardRef.current || e.target === whiteboardContainerRef.current) {
      dispatch(updateSpellCheckSettingsState(false));
      window.posterEditor?.whiteboard?.clearSelection();
      exitDrawModeIfEnabled();
    }
  };

  const exitDrawModeIfEnabled = (): void => {
    if (isDrawModeEnabled) {
      void window.posterEditor?.whiteboard?.drawing.updateFromObject({
        isDrawModeOn: false,
      });
    }
  };

  return (
    <ClickableDiv
      id="poster-whiteboard-container"
      className={`flex-v-row whiteboard-container ${isMobileDisplay ? '' : styles.containerForDesktop} ${styles.container} ${className} ${
        showFakeScrollBars ? styles.hideScroll : styles.showScroll
      }`}
      enableFocusOutline={false}
      ref={whiteboardContainerRef}
      onMouseDown={onWhiteboardContainerClick}
      onClick={() => {}}
    >
      <DragDropOverlay />
      {isPosterLoading ? <PosterWhiteboardLoader isCenterAligned={isCenterAligned} posterMode={props.posterOpts.mode} /> : null}
      <div
        ref={whiteboardRef}
        className={`${styles.whiteboard} ${isPosterLoading ? '_hidden' : ''} ${
          isCenterAligned ? `flex-items-center ${styles.centerAlignedWhiteboard}` : ''
        } flex-v-row ${whiteboardClassName}`}
      >
        <div ref={posterEl} className={`flex-v-row ${styles.posterElement} ${isPickingModeEnabled ? styles.containerWhilePickingModeEnabled : ''}`}>
          <TextItemPopup />
          <ImageItemPopup />
        </div>
        <div ref={postPosterElement} className={styles.postPosterElement}>
          <AddNewPageButton />
          {props.postWhiteboardElement ?? null}
        </div>
      </div>
      <PageOptions />
      <PosterPopupMenu />
      <div id="buffer" className={styles.buffer}>
        <canvas id="bufferCanvas" width="1" height="1" />
      </div>
      {props.postWhiteboardContainerElement ?? null}
      <PosterVerticalScroll />
      <PosterHorizontalScroll />
    </ClickableDiv>
  );
}
