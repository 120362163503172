export interface SocialMediaProfileLinksProps {
  socialMediaProfileLinks: SocialMediaProfileLinksMap;
  onInputChangeCallback: (socialMediaProfileName: SocialMediaProfile, url: string) => void;
  errorStates?: Record<SocialMediaProfile, boolean>;
  onInputBlurCallback?: (socialMediaProfileName: SocialMediaProfile) => void;
  containerClassName?: string;
}

export enum SocialMediaProfile {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  X = 'x',
  YOUTUBE = 'youtube-channel',
  TIKTOK = 'tiktok-profile',
  PINTEREST = 'pinterest',
  LINKEDIN = 'linkedin',
}

export enum SocialMediaProfileDisplayName {
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  X = 'X (formerly Twitter)',
  YOUTUBE = 'YouTube',
  TIKTOK = 'TikTok',
  PINTEREST = 'Pinterest',
  LINKEDIN = 'LinkedIn',
}

export enum SocialMediaPlatformBaseURL {
  INSTAGRAM = 'instagram.com',
  FACEBOOK = 'facebook.com',
  X = 'x.com',
  YOUTUBE = 'youtube.com',
  TIKTOK = 'tiktok.com',
  PINTEREST = 'pinterest.com',
  LINKEDIN = 'linkedin.com',
}

export interface ValidFormattedSocialMediaLinks {
  isValid: boolean;
  formattedUrls: SocialMediaProfileLinksMap;
  errorMap: Record<SocialMediaProfile, boolean>;
}

export interface SocialMediaLinksValidationResult extends Omit<ValidFormattedSocialMediaLinks, 'formattedUrls'> {
  validatedUrls: SocialMediaProfileLinksMap;
}

export type SocialMediaProfileLinksMap = Record<SocialMediaProfile, string>;
export type SocialMediaBaseURLsMap = Record<SocialMediaProfile, SocialMediaPlatformBaseURL>;
