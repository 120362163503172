import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Subtitle} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';

export class SyncSubtitleToPosterClock extends CommonMethods {
  public subtitle: Subtitle;
  private syncing = false;
  private readonly boundSyncToPage;

  constructor(subtitle: Subtitle) {
    super();
    this.subtitle = subtitle;
    this.boundSyncToPage = this.syncToPage.bind(this);
  }

  public initSyncToPosterClock(): void {
    this.subtitle.transcriptItem.page.poster.on('time:updated', this.boundSyncToPage);
  }

  public unload(): void {
    this.subtitle.transcriptItem.page.poster.off('time:updated', this.boundSyncToPage);
  }

  public syncToPage(time: number): void {
    if (this.syncing) {
      return;
    }

    this.syncing = true;
    this.syncSubtitleState(time);
    this.syncing = false;
  }

  private syncSubtitleState(posterTime: number): void {
    if (posterTime >= this.subtitle.startTime && posterTime <= this.subtitle.endTime) {
      this.subtitle.show();
      this.subtitle.isCurrentlyActiveSubtitle = true;

      this.subtitle.handleCurrentWordAtTime(posterTime);
    } else {
      this.subtitle.hide();
      this.subtitle.isCurrentlyActiveSubtitle = false;
      this.subtitle.currentlyActiveWord = undefined;
    }
  }
}
