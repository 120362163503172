import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {AudioItem} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';

/**
 * Max time difference in seconds that the audio and video can be out of sync
 */
const MAXIMUM_SYNC_THRESHOLD = 0.5;

export class SyncAudioToPosterClock extends CommonMethods {
  public audioItem: AudioItem;
  private syncing = false;
  private readonly boundSyncToPage;

  constructor(audioItem: AudioItem) {
    super();
    this.audioItem = audioItem;
    this.boundSyncToPage = this.syncToPage.bind(this);
  }

  public initSyncToPosterClick(): void {
    this.audioItem.audioClips.poster.on('time:updated', this.boundSyncToPage);
  }

  public unload(): void {
    this.audioItem.audioClips.poster.off('time:updated', this.boundSyncToPage);
  }

  public async syncToPage(time: number): Promise<void> {
    if (this.syncing) {
      return;
    }

    this.syncing = true;
    try {
      await this.syncItemToTime(time);
      this.syncAudioPlaybackState();
    } finally {
      this.syncing = false;
    }
  }

  private async syncItemToTime(posterTime: number): Promise<void> {
    if (posterTime < this.audioItem.onPosterStartTime || posterTime > this.audioItem.getOnPosterEndTime()) {
      return;
    }

    const audioCurrentPlaybackTimeResptiveToPoster = this.audioItem.audioPlayer.getPlaybackCurrentTime() + this.audioItem.onPosterStartTime;

    if (Math.abs(posterTime - audioCurrentPlaybackTimeResptiveToPoster) > MAXIMUM_SYNC_THRESHOLD) {
      await this.audioItem.seekToPosterTime(posterTime);
    }
  }

  private syncAudioPlaybackState(): void {
    if (this.audioItem.shouldBePlaying() && !this.audioItem.audioPlayer.isPlaying()) {
      this.audioItem.play();
    } else if (!this.audioItem.shouldBePlaying() && this.audioItem.audioPlayer.isPlaying()) {
      this.audioItem.audioPlayer.pause();
    }
  }
}
