export type MenuItemData = UserMenuItem | PosMenuItem;
export type MenuItemVariation = UserMenuItemVariation | PosMenuItemVariation;

export const USER_MENU_LIST_ID = 'User-menu-items';
export const POS_MENU_LIST_ID = 'Pos-menu-items';
export const SELECTED_MENU_LIST_ID = 'Selected-menu-items';
export const SEARCH_MENU_LIST_ID = 'Search-menu-items';
export const USER_MENU_MODAL_TABS_ID = 'user-menu-modal-tabs';
export const POLLING_EVENT_ID = 'pos-loging-in';
export const BATCH_LIMIT = 200;
export enum CharacterLimit {
  NAME = 100,
  DESCRIPTION = 400,
  PRICE = 50,
}

export enum ImageSrc {
  SQUARE = 'images/postermaker/pos-square-logo.png',
}

export enum PosLoadingStates {
  DEFAULT = 0,
  LOGGING_IN = 1,
  LOADING_ITEMS = 2,
  LOADED_ITEMS = 3,
}

export enum TABS {
  STYLE = 'style',
  ALL_ITEMS = 'all-items',
  SELECTED_ITEMS = 'selected-items',
}

export enum MenuItemType {
  USER_MENU_ITEM = 'user-item',
  POS_MENU_ITEM = 'pos-item',
}

export enum ItemSource {
  ALL = 'all',
  POS = 'pos',
  PMW = 'pmw',
}

export enum MenuItemKeys {
  NAME = 'name',
  PRICE = 'price',
  ICONS_LIST = 'iconsList',
  ADDONS_LIST = 'addOnsList',
  DESCRIPTION = 'description',
  VARIATIONS_LIST = 'variationsList',
}

export enum BasicInfo {
  NAME = 'name',
  PRICE = 'price',
  DESCRIPTION = 'description',
}

export enum ItemVariationListType {
  SIZE = 'variationsList',
  ADDON = 'addOnsList',
}

export enum ItemVariationData {
  NAME = 'name',
  PRICE = 'price',
}

export interface MenuItem {
  id: string;
  name: string;
  price?: string;
  description: string;
  type: MenuItemType;
  iconsList: string[];
  variationsList: MenuItemVariation[];
  addOnsList: MenuItemVariation[];
  categories: MenuItemTag[];
}

export interface UserMenuItem extends MenuItem {
  type: MenuItemType.USER_MENU_ITEM;
}

export interface PosMenuItem extends MenuItem {
  posItemId: string;
  type: MenuItemType.POS_MENU_ITEM;
  source: string;
}

export interface ItemVariation {
  id: string;
  name: string;
  price: string;
}

export interface UserMenuItemVariation extends ItemVariation {
  type: MenuItemType.USER_MENU_ITEM;
}

export interface PosMenuItemVariation extends ItemVariation {
  posVariationId: string;
  type: MenuItemType.POS_MENU_ITEM;
}

export interface MenuItemIcon {
  id: string;
  name: string;
}

export interface MenuItemTag {
  id: string;
  name: string;
  isSelected: boolean;
}

export interface PosAccountInfo {
  isAvailable: boolean;
  data: PosMenuItem[];
}

export interface UnlinkAccountInfo {
  unlinked: boolean;
}

export interface PosCatalogBackendData {
  items: PosMenuItem[];
  categories: MenuItemTag[];
  cursor?: string;
}

export interface MenusBackendData {
  items: MenuItemData[];
  categories: MenuItemTag[];
}
