import {roundPrecision} from '@Utils/math.util';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';

export enum STATE {
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
}

const TIME_PRECISION = 3;
const TIME_UPDATE_INTERVAL = 33;

interface ClockLibraryOpts {
  onEnd?(): void;

  onTimeUpdated?(time: number): void;
}

export class Clock {
  private poster;
  private readonly onEnd;
  private currentTime = 0;
  private timeOffset = 0;
  private readonly onTimeUpdated;
  private state: STATE = STATE.PAUSED;

  constructor(poster: Poster, opts: ClockLibraryOpts = {}) {
    this.poster = poster;
    this.onEnd = (): void => {
      if (opts.onEnd) {
        opts.onEnd();
      }
    };
    this.onTimeUpdated = (val: number): void => {
      if (opts.onTimeUpdated) {
        opts.onTimeUpdated(val);
      }
    };
    this.startClock();
  }

  public getCurrentTime(): number {
    return this.currentTime;
  }

  public setCurrentTime(value: number): void {
    this.currentTime = value;
    this.onTimeUpdated(value);
  }

  play(): void {
    this.timeOffset = Date.now();
    this.state = STATE.PLAYING;
  }

  pause(): void {
    this.state = STATE.PAUSED;
  }

  stop(): void {
    this.state = STATE.PAUSED;
    this.reset();
  }

  isPlaying(): boolean {
    return this.state === STATE.PLAYING;
  }

  isPaused(): boolean {
    return this.state === STATE.PAUSED;
  }

  reset(): void {
    this.setCurrentTime(0);
  }

  private getTimeOffset(): number {
    if (this.timeOffset === 0) {
      this.timeOffset = Date.now();
    }
    return this.timeOffset;
  }

  private onClockEnd(): void {
    this.stop();
    this.onEnd();
  }

  private startClock(): void {
    setInterval(() => {
      this.updateCurrentTime();
    }, TIME_UPDATE_INTERVAL);
  }

  private updateCurrentTime(): void {
    if (this.isPlaying()) {
      const now = Date.now();
      const d = Math.round(now - this.getTimeOffset()) / 1000;

      this.setCurrentTime(roundPrecision(this.currentTime + d, TIME_PRECISION));
      this.timeOffset = now;
      if (this.currentTime > this.poster.getDuration()) {
        this.onClockEnd();
      }
    }
  }
}
