import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import type {ActivityIndicatorType} from '@Components/activity-indicator/activity-indicator.types';
import {ActivityIndicatorSize} from '@Components/activity-indicator/activity-indicator.types';
import {ActivityIndicator} from '@Components/activity-indicator/activity-indicator';
import styles from './add-item-thumbnail.module.scss';

interface AddItemThumbnailContentProps {
  id?: string;
  className?: string;
  iconClassName?: string;
  icon: string;
  activityIndicatorType?: ActivityIndicatorType;
}

export function AddItemThumbnail({id = '', className = '', iconClassName = '', ...props}: AddItemThumbnailContentProps): ReactElement {
  return (
    <div id={id} className={`${styles.container} radius-8 flex-center hover-transition-all ${className} `}>
      {props.activityIndicatorType ? <ActivityIndicator size={ActivityIndicatorSize.SIZE_16} type={props.activityIndicatorType} className={styles.activityIndicator} /> : null}
      <Icon className={`${styles.icon} ${iconClassName}`} icon={props.icon} size={IconSize.SIZE_ICON_24} />
    </div>
  );
}
