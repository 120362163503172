import type {ReactElement} from 'react';
import React, {useState} from 'react';
import type {GRID_ITEM_TYPE, GridItemProps} from '../grid-item';
import type {GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';

export interface GridImageItemStorage extends GridMediaItemStorage {
  type: GRID_ITEM_TYPE.IMAGE;
  thumbSrc: string;
}

interface GridImageItemProps extends GridItemProps {
  thumbSrc: string;
  paddedThumb?: boolean;
}

export function GridImageItem({...props}: GridImageItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <img
          onLoad={(): void => {
            return setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          className="radius-12"
          alt={window.i18next.t('pmwjs_image')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
