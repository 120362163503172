import type {
  BusinessProfileState,
  BusinessProfilesWizardMode,
  BusinessProfileWizardStep,
  BusinessHoursMap,
  BusinessProfilesWizardReducerState,
  BusinessProfileType,
  BusinessProfile,
} from '@Components/business-profiles-wizard/business-profiles-wizard.types';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {LoadingStates} from '@Utils/loading.util';
import {businessProfilesWizardExtraReducers} from '@Components/business-profiles-wizard/business-profiles-wizard-extra-reducer';
import type {SocialMediaProfile} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {initialState} from '@Libraries/business-profiles-wizard-library';
import type {BusinessProfilesActivityLogs} from '@Panels/business-profiles-activity-panel/business-profiles-activity-panel.types';

const businessProfilesWizardSlice = createSlice({
  name: 'businessProfilesWizard',
  initialState,
  reducers: {
    setInitialState: (state, action: PayloadAction<BusinessProfilesWizardReducerState>) => {
      state.businessProfile = action.payload.businessProfile;
      state.currentStep = action.payload.currentStep;
      state.isSavingChanges = action.payload.isSavingChanges;
      state.mode = action.payload.mode;
    },
    setBusinessProfile: (state, action: PayloadAction<BusinessProfile | undefined>) => {
      state.businessProfile = action.payload;
    },
    setMode: (state, action: PayloadAction<BusinessProfilesWizardMode>) => {
      state.mode = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<BusinessProfileWizardStep>) => {
      state.currentStep = action.payload;
    },
    setBusinessProfileState: (state, action: PayloadAction<BusinessProfileState>) => {
      if (state.businessProfile) {
        state.businessProfile.state = action.payload;
      }
    },
    setBusinessProfileType: (state, action: PayloadAction<{key: BusinessProfileType; value: string}>) => {
      if (state.businessProfile && state.businessProfile.types && Object.values(state.businessProfile.types).length > 0) {
        state.businessProfile.types[action.payload.key] = action.payload.value;
      } else if (state.businessProfile) {
        state.businessProfile.types = {};
        state.businessProfile.types[action.payload.key] = action.payload.value;
      }
    },
    removeBusinessProfileType: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        for (const [key, value] of Object.entries(state.businessProfile.types)) {
          if (value === action.payload) {
            delete state.businessProfile.types[key as BusinessProfileType];
          }
        }
      }
    },
    setBusinessProfileName: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        state.businessProfile.name = action.payload;
      }
    },
    setBusinessProfileWebsite: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        state.businessProfile.website = action.payload;
      }
    },
    setBusinessProfileContactEmail: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        state.businessProfile.contactEmail = action.payload;
      }
    },
    setBusinessProfilePhoneNumber: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        state.businessProfile.phoneNumber = action.payload;
      }
    },
    setBusinessProfileDialCode: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        state.businessProfile.dialCode = action.payload;
      }
    },
    setBusinessAddressCountry: (state, action: PayloadAction<{country: string; countryCode: string}>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.country = action.payload.country ?? undefined;
      address.countryCode = action.payload.countryCode ?? undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressStreetAddress: (state, action: PayloadAction<string[]>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.address = action.payload.length > 0 ? action.payload : undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressSector: (state, action: PayloadAction<string>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.sector = action.payload !== '' ? action.payload : undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressCity: (state, action: PayloadAction<string>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.city = action.payload !== '' ? action.payload : undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressPostalCode: (state, action: PayloadAction<string>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.postalCode = action.payload !== '' ? action.payload : undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressLongitude: (state, action: PayloadAction<string>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.longitude = action.payload ?? undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressLatitude: (state, action: PayloadAction<string>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.latitude = action.payload ?? undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressLocationName: (state, action: PayloadAction<string>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.locationName = action.payload ?? undefined;
      state.businessProfile.address = address;
    },
    setBusinessAddressPlaceId: (state, action: PayloadAction<string>) => {
      if (!state.businessProfile) {
        return;
      }
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.googleMapsPlaceId = action.payload ?? undefined;
      state.businessProfile.address = address;
    },
    setDisplayableBusinessHours: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        state.businessProfile.displayableBusinessHours = action.payload;
      }
    },
    setBusinessHours: (state, action: PayloadAction<BusinessHoursMap>) => {
      if (state.businessProfile) {
        state.businessProfile.businessHours = action.payload;
      }
    },
    updateSocialMediaProfileLink: (state, action: PayloadAction<{profile: SocialMediaProfile; url: string}>) => {
      if (state.businessProfile) {
        state.businessProfile.socialMediaProfileLinks[action.payload.profile] = action.payload.url;
      }
    },
    updateAllSocialMediaProfileLinks: (state, action: PayloadAction<Record<SocialMediaProfile, string>>) => {
      if (state.businessProfile) {
        state.businessProfile.socialMediaProfileLinks = action.payload;
      }
    },
    incrementStep: (state) => {
      state.currentStep += 1;
    },
    decrementStep: (state) => {
      state.currentStep -= 1;
    },
    setBrandID: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        state.businessProfile.idBrand = action.payload;
      }
    },
    addBusinessProfileCategory: (state, action: PayloadAction<{key: string; value: string}>) => {
      if (state.businessProfile) {
        state.businessProfile.categories[action.payload.key] = action.payload.value;
      }
    },
    removeBusinessProfileCategory: (state, action: PayloadAction<string>) => {
      if (state.businessProfile) {
        for (const [key, value] of Object.entries(state.businessProfile.categories)) {
          if (value === action.payload) {
            delete state.businessProfile.categories[key];
          }
        }
      }
    },
    popBusinessProfileCategory: (state) => {
      if (state.businessProfile) {
        const keys = Object.keys(state.businessProfile.categories);
        if (keys.length > 0) {
          delete state.businessProfile.categories[keys[keys.length - 1]];
        }
      }
    },
    setAllBusinessProfiles: (state, action: PayloadAction<BusinessProfile[] | undefined>) => {
      state.allBusinessProfiles = action.payload;
    },
    setIsFieldsErrorState: (state, action: PayloadAction<boolean>) => {
      state.isFieldsErrorState = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<LoadingStates>) => {
      state.loadingState = action.payload;
    },
    setIsImportingProfile: (state, action: PayloadAction<boolean>) => {
      state.isImportingProfile = action.payload;
    },
    setIsImportedProfile: (state, action: PayloadAction<boolean>) => {
      state.isImportedProfile = action.payload;
    },
    setActivityLogs: (state, action: PayloadAction<BusinessProfilesActivityLogs[]>) => {
      state.activityLogs = action.payload;
    },
    setSavedBusinessProfile: (state, action: PayloadAction<BusinessProfile | undefined>) => {
      state.savedBusinessProfile = action.payload;
    },
  },
  extraReducers: businessProfilesWizardExtraReducers,
});

export const {
  setInitialState,
  setBusinessProfile,
  setMode,
  setCurrentStep,
  setBusinessProfileState,
  setBusinessProfileType,
  removeBusinessProfileType,
  setBusinessProfileName,
  setBusinessProfileWebsite,
  setBusinessProfileContactEmail,
  setBusinessProfilePhoneNumber,
  setBusinessProfileDialCode,
  setBusinessAddressCountry,
  setBusinessAddressStreetAddress,
  setBusinessAddressSector,
  setBusinessAddressCity,
  setBusinessAddressPostalCode,
  setBusinessAddressLongitude,
  setBusinessAddressLatitude,
  setBusinessAddressLocationName,
  setBusinessAddressPlaceId,
  updateSocialMediaProfileLink,
  updateAllSocialMediaProfileLinks,
  incrementStep,
  decrementStep,
  setDisplayableBusinessHours,
  setBusinessHours,
  setBrandID,
  addBusinessProfileCategory,
  removeBusinessProfileCategory,
  popBusinessProfileCategory,
  setAllBusinessProfiles,
  setIsFieldsErrorState,
  setLoadingState,
  setIsImportingProfile,
  setIsImportedProfile,
  setActivityLogs,
  setSavedBusinessProfile,
} = businessProfilesWizardSlice.actions;

export const businessProfilesWizardReducer = businessProfilesWizardSlice.reducer;
