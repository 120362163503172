import type {Page} from '@PosterWhiteboard/page/page.class';
import {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import {checkSpell, getIsSpellCheckEnabledFromStore, SQUIGGLY_LINE_ERROR_COLOR, suggestWords, TEXT_HOVER_HIGHLIGHT} from '@Libraries/spell-check-library';
import {RESELECT_ITEM_EVENT_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {updateSpellCheckPopUpOpenState, updateSpellCheckPopUpTargetAndWords} from '@Components/poster-editor/components/text-item-popup/text-item-popup-slice';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {updateSpellCheckSettingsState} from '@Components/poster-editor/poster-editor-reducer';
import {closeTextSelectionPopup} from '@Libraries/text-selection-library';
import {isMobile} from 'react-device-detect';
import type {CanvasEvents} from '@postermywall/fabricjs-2';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

interface FirstCharLocation {
  lineIndex: number;
  charIndex: number;
}

export class PageSpellCheck extends CommonMethods {
  public page: Page;

  public constructor(page: Page) {
    super();
    this.page = page;

    this.page.fabricCanvas.on('selection:created', this.onSelectionCreated.bind(this));
    this.page.fabricCanvas.on('selection:updated', this.onSelectionCreated.bind(this));
    this.page.fabricCanvas.on('selection:cleared', this.onSelectionCleared.bind(this));
  }

  public async onSelectionCleared(): Promise<void> {
    window.PMW.redux.store.dispatch(updateSpellCheckSettingsState(false));
  }

  public async onSelectionCreated(fabricEvent: CanvasEvents['selection:created']): Promise<void> {
    if (fabricEvent.e?.type === RESELECT_ITEM_EVENT_TYPE) {
      return;
    }
    await this.checkSpellForSelectedObject();
  }

  public async checkSpellForSelectedObject(highlightMisspelledWord = false): Promise<void> {
    const fabricTextbox = this.getFabricTextForSpellCheck();
    if (fabricTextbox) {
      await this.checkSpell(fabricTextbox, highlightMisspelledWord);
    }
  }

  public async checkSpell(view: Fabric.Textbox, highlightMisspelledWord = false): Promise<void> {
    const spellCheckEnabled = getIsSpellCheckEnabledFromStore();
    if (view && !spellCheckEnabled) {
      this.clearSquigglyLineStyle(view);
      this.clearHightlight(view);
      this.page.fabricCanvas.requestRenderAll();
    } else if (view && spellCheckEnabled) {
      try {
        this.clearSquigglyLineStyle(view);
        this.clearHightlight(view);
        const text = view.text.split(/[\n -&(-/:-@[-`{-~0-9]/);
        let index = 0;
        const spellCheckCache = await checkSpell(text);
        for (let word of text) {
          word = word.trim();
          const isCorrect = spellCheckCache[word];
          if (!isCorrect && word.length) {
            view.squigglylineColor = SQUIGGLY_LINE_ERROR_COLOR;
            view.setSelectionStyles(
              {
                squigglyline: true,
                textBackgroundColor: highlightMisspelledWord ? TEXT_HOVER_HIGHLIGHT : '',
              },
              index,
              index + word.length
            );
            view.dirty = true;
          }
          index += word.length + 1;
        }
        const activeObject = this.getFabricTextForSpellCheck();
        setTimeout(() => {
          if (activeObject === view) {
            if (activeObject.group) {
              activeObject.group.dirty = true;
            }
          } else {
            this.clearSquigglyLineStyle(view);
          }
        }, 500);
        this.page.fabricCanvas.requestRenderAll();
      } catch (e) {
        console.error(e);
      }
    }
  }

  public getFabricTextForSpellCheck(): Fabric.Textbox | undefined {
    const itemWithText = this.getItemWithTextForSpellCheck();

    if (itemWithText) {
      if (itemWithText.isText()) {
        return itemWithText.clone ?? itemWithText.fabricTextbox;
      }
      if (itemWithText.isSlideshow()) {
        const selectedSlide = itemWithText.getSelectedSlide();
        if (selectedSlide.isTextSlide()) {
          return itemWithText.clone ?? selectedSlide.fabricTextbox;
        }
      }
    }

    return undefined;
  }

  public getItemWithTextForSpellCheck(): TextItem | SlideshowItem | undefined {
    const selectedItems = this.page.getSelectedItems();
    if (selectedItems.length === 1 && (selectedItems[0] instanceof TextItem || selectedItems[0].isSlideshow())) {
      return selectedItems[0];
    }
    return undefined;
  }

  public clearSquigglyLineStyle(clone: Fabric.Textbox): void {
    if (clone) {
      const start = 0;
      const end = clone.text.length;
      clone.setSelectionStyles({squigglyline: false}, start, end);
      if (clone.group) {
        clone.group.dirty = true;
      }
    }
  }

  public clearHightlight(clone: Fabric.Textbox): void {
    if (clone) {
      const start = 0;
      const end = clone.text.length;
      clone.setSelectionStyles({textBackgroundColor: ''}, start, end);
      if (clone.group) {
        clone.group.dirty = true;
      }
    }
  }

  public async handleSuggestionPopupMenu(target: Fabric.Textbox): Promise<void> {
    const start = target.searchWordBoundary(target.selectionStart, -1);
    const end = target.searchWordBoundary(target.selectionStart, 1);
    const text = target.text.substring(start, end).trim();
    const suggestedWords = await suggestWords(text);

    if (this.updatePopupStateNeeded(target)) {
      window.PMW.redux.store.dispatch(
        updateSpellCheckPopUpTargetAndWords({
          target,
          words: suggestedWords,
          wordPosition: {start, end},
          openSpellCheckPopUpMenu: true,
        })
      );
    } else {
      this.closeSuggestionPopUp();
    }
    if (isMobile && target.selectionStart === target.selectionEnd) {
      closeTextSelectionPopup();
    }
  }

  protected updatePopupStateNeeded(target: Fabric.Textbox): boolean {
    if (isMobile && target.selectionStart !== target.selectionEnd) {
      return false;
    }
    const start = target.searchWordBoundary(target.selectionStart, -1);
    const end = target.searchWordBoundary(target.selectionStart, 1);
    const text = target.text.substring(start, end).trim();
    const hasSquigglyLine = target.getValueOfPropertyAt(this.calcLocation(target).lineIndex, this.calcLocation(target).charIndex, 'squigglyline');

    return hasSquigglyLine && !!text.length;
  }

  protected calcLocation(target: Fabric.Textbox): FirstCharLocation {
    const cursorLocation = target.get2DCursorLocation();
    const {lineIndex} = cursorLocation;
    let charIndex = cursorLocation.charIndex > 0 ? cursorLocation.charIndex - 1 : 0;
    charIndex += target.text.length >= target.selectionStart + 1 && target.searchWordBoundary(target.selectionStart + 1, -1) === target.selectionStart ? 1 : 0;
    return {lineIndex, charIndex};
  }

  public closeSuggestionPopUp(): void {
    window.PMW.redux.store.dispatch(updateSpellCheckPopUpOpenState(false));
  }
}
