import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import type {AudioItem, AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import {createAudioItemFromObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import {POSTER_MAX_DURATION} from '@PosterWhiteboard/poster/poster.types';
import type {AudioData} from '@Libraries/add-media-library';
import {getUniqueString} from '@Utils/string.util';
import {openMessageModal} from '@Modals/message-modal';
import {openTrimAudioModal} from '@Modals/trim-audio-modal';
import {getAudioUrl} from '@Libraries/user-audio-library';
import {AudioMaxDurationReachedException} from '@PosterWhiteboard/classes/audio-clips/audio-max-duration-reached-exception';
import {AudioDurationExceededOnAddException} from '@PosterWhiteboard/classes/audio-clips/audio-duration-exceeded-on-add-exception';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {DeepPartial} from '@/global';

export const ADD_POSTER_AUDIO_DURATION_LIMIT_LEEWAY = 1;
const ADDING_AUDIO_LOADING_KEY = 'addingAudioItemsData';

export interface AudioClipsObject {
  audioItemsHashMap: Record<string, AudioItemObject>;
  selectedAudioItemUID: string;
}

export interface AddAudioData extends AudioData {
  uid?: string;
  onPosterStartTime?: number;
}

interface TrimAudioItemOpts {
  undoable?: boolean;
}

interface AddAudioItemsOpts {
  undoable?: boolean;
  updateRedux?: boolean;
  selectLastAudioItem?: boolean;
  selectOnAdd?: boolean;
}

export class AudioClips extends CommonMethods {
  public audioItemsHashMap: Record<string, AudioItem> = {};
  public selectedAudioItemUID = '';
  public poster: Poster;

  public constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public hasActiveSelection(): boolean {
    return this.selectedAudioItemUID !== '';
  }

  public hasAudio(): boolean {
    return Object.keys(this.audioItemsHashMap).length !== 0;
  }

  public selectAudioItem(uid: string): void {
    this.poster.clearItemSelection();
    this.selectedAudioItemUID = uid;
    this.poster.redux.updateSelectedAudioItemUID();
  }

  public selectFirstAudioItem(): void {
    const firstAudioItem = this.getMinOnPosterStartTimeAudioItem();
    if (firstAudioItem) {
      this.selectAudioItem(firstAudioItem.uid);
    }
  }

  public unselectAudioPlaylist(): void {
    if (this.selectedAudioItemUID) {
      this.selectedAudioItemUID = '';
      this.poster.redux.updateSelectedAudioItemUID();
    }
  }

  public toObject(): AudioClipsObject {
    const audioItemObjects: Record<string, AudioItemObject> = {};

    for (const [key, audioItem] of Object.entries(this.audioItemsHashMap)) {
      audioItemObjects[key] = audioItem.toObject();
    }

    return {
      audioItemsHashMap: audioItemObjects,
      selectedAudioItemUID: this.selectedAudioItemUID,
    };
  }

  public getSelectedAudioItem(): AudioItem | undefined {
    return this.selectedAudioItemUID ? this.audioItemsHashMap[this.selectedAudioItemUID] : undefined;
  }

  public deleteSelectedAudioItem(): void {
    const audioItem = this.getSelectedAudioItem();
    if (audioItem) {
      this.doRemoveItem(audioItem.uid);
      if (this.selectedAudioItemUID) {
        const minOnPosterStartTimeAudioItem = this.getMinOnPosterStartTimeAudioItem();
        this.selectedAudioItemUID = minOnPosterStartTimeAudioItem ? minOnPosterStartTimeAudioItem.uid : '';
      }
      this.poster.redux.updateReduxData();
      this.poster.history.addPosterHistory();
      this.updatePosterTimeIfNeeded();
    }
  }

  public updatePosterTimeIfNeeded(): void {
    if (!this.poster.isVideo()) {
      void this.poster.stop();
    }
  }

  public async replaceAudioItem(audioItemIdToReplace: string, audioItemToAdd: AudioData): Promise<void> {
    const {onPosterStartTime} = this.audioItemsHashMap[audioItemIdToReplace];
    const uid = getUniqueString();
    await this.addAudioItemsData(
      [
        {
          ...audioItemToAdd,
          uid,
          onPosterStartTime,
        },
      ],
      {
        updateRedux: false,
        undoable: false,
        selectLastAudioItem: false,
      }
    );
    this.selectedAudioItemUID = uid;
    this.doRemoveItem(audioItemIdToReplace);
    await this.poster.seekPosterToSelectedAudioItem();
    this.poster.getCurrentPage().calculateAndUpdatePageDuration();
    this.poster.history.addPosterHistory();
    this.poster.redux.updateReduxData();
  }

  public async addAudioItemsData(audioItemsData: AddAudioData[], {updateRedux = true, undoable = true, selectOnAdd = true}: AddAudioItemsOpts = {}): Promise<void> {
    showLoading(ADDING_AUDIO_LOADING_KEY, {
      text: audioItemsData.length > 1 ? window.i18next.t('pmwjs_loading_audio_clips') : window.i18next.t('pmwjs_loading_audio_clip'),
    });

    for (const audioItemData of audioItemsData) {
      try {
        // The ESLint rule is disabled here because the output of one iteration determines the remaining audio limit
        // for the next one, and we need to stop adding audio items once the limit is reached.
        // eslint-disable-next-line no-await-in-loop
        await this.addAudioItemData(audioItemData, selectOnAdd);
      } catch (e) {
        hideLoading(ADDING_AUDIO_LOADING_KEY);
        if (e instanceof AudioMaxDurationReachedException) {
          openMessageModal({
            title: window.i18next.t('pmwjs_audio_max_duration_reached_title'),
            text: window.i18next.t('pmwjs_audio_max_duration_reached'),
          });
          return;
        }

        if (e instanceof AudioDurationExceededOnAddException) {
          this.showTrimModalForDurationLimit(e.audioItemId);
          return;
        }

        throw e;
      }
    }

    this.poster.getCurrentPage().calculateAndUpdatePageDuration();
    if (undoable) {
      this.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.poster.redux.updateReduxData();
    }
    hideLoading(ADDING_AUDIO_LOADING_KEY);
  }

  private async addAudioItemData(audioItemData: AddAudioData, selectOnAdd = true): Promise<void> {
    const totalAudioDuration = this.getDuration();
    if (totalAudioDuration + ADD_POSTER_AUDIO_DURATION_LIMIT_LEEWAY > POSTER_MAX_DURATION) {
      throw new AudioMaxDurationReachedException();
    }

    const onPosterStartTime = audioItemData.onPosterStartTime ?? this.poster.getCurrentTime();
    const newTotalDuration = totalAudioDuration + audioItemData.duration;
    const playDuration = newTotalDuration < POSTER_MAX_DURATION ? audioItemData.duration : POSTER_MAX_DURATION - totalAudioDuration;
    const audioDurationExceededOnAdd = newTotalDuration > POSTER_MAX_DURATION;
    const uid = audioItemData.uid ?? getUniqueString();

    const audioItem = await createAudioItemFromObject(this, {
      uid,
      hashedFilename: audioItemData.filename,
      onPosterStartTime,
      audioPlayer: {
        audioUrl: getAudioUrl(audioItemData.filename, audioItemData.src),
        originalDuration: audioItemData.duration,
        trim: {
          isTrimmed: playDuration !== audioItemData.duration,
          startTime: 0,
          endTime: playDuration,
        },
      },
      source: audioItemData.src,
      name: audioItemData.name,
    });

    this.audioItemsHashMap[audioItem.uid] = audioItem;
    if (selectOnAdd) {
      this.selectedAudioItemUID = uid;
    }
    this.poster.convertTransparentBackgroundsToSolid();

    if (audioDurationExceededOnAdd) {
      throw new AudioDurationExceededOnAddException(audioItem.uid);
    }
  }

  public getMaxAllowedDurationForAudioItem(audioItem: AudioItem): number {
    return POSTER_MAX_DURATION - (this.getDuration() - audioItem.audioPlayer.getTrimmedDuration());
  }

  private showTrimModalForDurationLimit(audioItemId: string): void {
    const audioItem = this.getAudioItemForId(audioItemId);
    if (audioItem) {
      const maxDurationForAudio = this.getMaxAllowedDurationForAudioItem(audioItem);

      openTrimAudioModal({
        duration: audioItem.audioPlayer.originalDuration,
        start: audioItem.audioPlayer.getTrimmedStartTime(),
        end: maxDurationForAudio,
        source: getAudioUrl(audioItem.hashedFilename),
        audioTitle: audioItem.name,
        onTrim: (trimData) => {
          void this.trimAudioItem(audioItem.uid, trimData.startTime, trimData.endTime, {
            undoable: false,
          });
        },
        maxDuration: maxDurationForAudio,
        showMaxDurationError: true,
        forceTrim: true,
      });
    }
  }

  public async trimAudioItem(audioItemId: string, startTime: number, endTime: number, {undoable = true}: TrimAudioItemOpts = {}): Promise<void> {
    const audioItem = this.getAudioItemForId(audioItemId);
    if (audioItem) {
      await audioItem.updateFromObject(
        {
          audioPlayer: {
            trim: {
              isTrimmed: true,
              startTime,
              endTime,
            },
          },
        },
        {
          undoable: false,
          updateRedux: false,
        }
      );
      this.poster.getCurrentPage().calculateAndUpdatePageDuration({
        updateRedux: true,
      });
      if (undoable) {
        this.poster.history.addPosterHistory();
      }
    }
  }

  public getAudioItemForId(audioItemId: string): AudioItem | undefined {
    return this.audioItemsHashMap[audioItemId];
  }

  public async updateFromObject(audioClipsObject: DeepPartial<AudioClipsObject> = {}, {updateRedux = true, undoable = true}: UpdateFromObjectOpts = {}): Promise<void> {
    const {audioItemsHashMap, selectedAudioItemUID: _selectedAudioItemUID, ...otherAudioPlaylistItemsObject} = audioClipsObject;
    this.copyVals({
      ...otherAudioPlaylistItemsObject,
    });
    // delete items that are in this class but not in the audioClipsObject
    if (audioItemsHashMap) {
      // Update/Add items from audioClipsObject
      const updateFromObjectPromises = [];

      const newAudioItemsHashmap: Record<string, AudioItem> = {};
      const addItemPromises = [];

      for (const [uid, audioItemObject] of Object.entries(audioItemsHashMap)) {
        if (audioItemObject) {
          if (this.audioItemsHashMap[uid]) {
            newAudioItemsHashmap[uid] = this.audioItemsHashMap[uid];
            updateFromObjectPromises.push(
              this.audioItemsHashMap[uid].updateFromObject(audioItemObject, {
                undoable: false,
                updateRedux: false,
              })
            );
          } else {
            addItemPromises.push(createAudioItemFromObject(this, audioItemObject));
          }
        }
      }

      const itemsToAdd = await Promise.all(addItemPromises);
      for (const itemToAdd of itemsToAdd) {
        newAudioItemsHashmap[itemToAdd.uid] = itemToAdd;
      }

      for (const [uid] of Object.entries(this.audioItemsHashMap)) {
        if (audioItemsHashMap[uid] === undefined) {
          if (this.selectedAudioItemUID === uid) {
            this.selectedAudioItemUID = '';
          }
          this.audioItemsHashMap[uid].onRemove();
        }
      }

      this.audioItemsHashMap = newAudioItemsHashmap;
      await Promise.all(updateFromObjectPromises);
    }

    if (undoable) {
      this.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.poster.redux.updateReduxData();
    }
  }

  public getDuration(): number {
    return this.getMaxOnPosterEndTime() - this.getMinOnPosterStartTime();
  }

  public getMaxOnPosterEndTime(): number {
    const lastAudioItem = this.getMaxOnPosterEndTimeAudioItem();
    return lastAudioItem ? lastAudioItem.getOnPosterEndTime() : 0;
  }

  private getMaxOnPosterEndTimeAudioItem(): AudioItem | undefined {
    let maxOnPosterEndTimeAudioItem: AudioItem | undefined;

    for (const [, audioItem] of Object.entries(this.audioItemsHashMap)) {
      if (maxOnPosterEndTimeAudioItem === undefined) {
        maxOnPosterEndTimeAudioItem = audioItem;
      }

      if (audioItem.getOnPosterEndTime() > maxOnPosterEndTimeAudioItem.getOnPosterEndTime()) {
        maxOnPosterEndTimeAudioItem = audioItem;
      }
    }

    if (maxOnPosterEndTimeAudioItem === undefined) {
      return undefined;
    }

    return maxOnPosterEndTimeAudioItem;
  }

  public async trimAudiosExceedingPageDuration(minDurationThreshold = 1): Promise<void> {
    const currentPageDuration = this.poster.getCurrentPage().getDuration();
    const newAudioItemsHashMap: Record<string, AudioItemObject> = {};
    for (const [key, audioItem] of Object.entries(this.audioItemsHashMap)) {
      if (audioItem.getOnPosterEndTime() > currentPageDuration) {
        const endTime = audioItem.getEndTimeAccordingToPageDuration(currentPageDuration);
        if ((endTime - audioItem.audioPlayer.trim.startTime) / audioItem.audioPlayer.speed < minDurationThreshold) {
          continue;
        }

        newAudioItemsHashMap[key] = {
          ...audioItem.toObject(),
          audioPlayer: {
            ...audioItem.toObject().audioPlayer,
            trim: {
              ...audioItem.toObject().audioPlayer.trim,
              isTrimmed: true,
              endTime,
            },
          },
        };
      } else {
        newAudioItemsHashMap[key] = audioItem.toObject();
      }
    }

    await this.updateFromObject(
      {
        audioItemsHashMap: newAudioItemsHashMap,
      },
      {undoable: false}
    );
  }

  private getMinOnPosterStartTimeAudioItem(): AudioItem | undefined {
    let minOnPosterStartTimeAudioItem: AudioItem | undefined;

    for (const [, audioItem] of Object.entries(this.audioItemsHashMap)) {
      if (minOnPosterStartTimeAudioItem === undefined) {
        minOnPosterStartTimeAudioItem = audioItem;
      }

      if (audioItem.onPosterStartTime < minOnPosterStartTimeAudioItem.onPosterStartTime) {
        minOnPosterStartTimeAudioItem = audioItem;
      }
    }

    if (minOnPosterStartTimeAudioItem === undefined) {
      return undefined;
    }

    return minOnPosterStartTimeAudioItem;
  }

  private getMinOnPosterStartTime(): number {
    const firstAudioItem = this.getMinOnPosterStartTimeAudioItem();
    return firstAudioItem ? firstAudioItem.onPosterStartTime : 0;
  }

  public play(): void {
    for (const [, audioItem] of Object.entries(this.audioItemsHashMap)) {
      audioItem.play();
    }
  }

  public getTimeForAudioItem(audioItemId: string): number {
    return this.audioItemsHashMap[audioItemId].onPosterStartTime + 0.01;
  }

  public pause(): void {
    for (const [, audioItem] of Object.entries(this.audioItemsHashMap)) {
      audioItem.audioPlayer.pause();
    }
  }

  public async seekToPosterTime(posterTime: number): Promise<void> {
    const promises = [];
    for (const [, audioItem] of Object.entries(this.audioItemsHashMap)) {
      promises.push(audioItem.seekToPosterTime(posterTime));
    }
    await Promise.all(promises);
  }

  private doRemoveItem(uid: string): void {
    this.audioItemsHashMap[uid].onRemove();
    const {[uid]: _, ...audioItemsHashMap} = this.audioItemsHashMap;
    this.audioItemsHashMap = audioItemsHashMap;
  }

  public duplicateAudio(audioUID: string): void {
    const audioItem = this.getAudioItemForId(audioUID);
    if (!audioItem) {
      return;
    }
    void this.poster.getCurrentPage().items.addItems.addAudioItemFromAnotherAudioItem(audioItem);
  }
}
