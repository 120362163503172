import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Subtitle} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import type {WordObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word.types';
import {getUniqueString} from '@Utils/string.util';
import type {DeepPartial} from '@/global';

export class Word extends CommonMethods {
  public id = '';
  public subtitle: Subtitle;
  public startTime = 0;
  public endTime = 0;
  public text = '';

  public constructor(subtitle: Subtitle) {
    super();
    this.id = getUniqueString();
    this.subtitle = subtitle;
  }

  public updateFromObject(wordObject: DeepPartial<WordObject>): void {
    this.copyVals(wordObject);
  }

  public toObject(): WordObject {
    return {
      id: this.id,
      startTime: this.startTime,
      endTime: this.endTime,
      text: this.text,
    };
  }

  public addWordToSubtitle(): void {
    this.subtitle.words.push(this);
  }
}

export const createAndAddWordFromObject = (subtitle: Subtitle, wordObject: DeepPartial<WordObject>): Word => {
  const word = new Word(subtitle);
  word.updateFromObject(wordObject);
  word.addWordToSubtitle();
  return word;
};
