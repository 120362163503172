import type {
  CaptionErrorByAccountIds,
  PostStateFilter,
  RefreshAccountResponse,
  SocialAccountConnectionResponse,
  SocialMediaResizeAjaxParams,
  SocialMediaResizeResponse,
  TitleErrorByAccountIds,
  UserHasSeenResponse,
} from '@Components/social-media/social-media.types';
import {HAS_SEEN_SOCIAL_MEDIA_POPUP, SocialAccountConnectionResult, SocialPlatform, SocialPostEvent} from '@Components/social-media/social-media.types';
import {getReadBucket, repoURL} from '@Libraries/s3-library';
import type {AccountMediaTypeInformation, SocialAccount} from '@Components/social-media/account.vo';
import {AccountState, MaxPostersPerPlatform} from '@Components/social-media/account.vo';
import type {MediaTypePublishingParams, SocialMediaAccountId, SocialPost} from '@Components/social-media/post.vo';
import {PlatformMediaType, PostState, PrivacySettings, YouTubePrivacySettings} from '@Components/social-media/post.vo';
import type {GraphicMeta, PosterHashedId, SocialMediaPublishingParams} from '@Components/social-media/publish-params.types';
import {getNumPublishingParams, setIsRescheduled, setSocialPostDialogStatus} from '@Panels/social-posts-dialog-panel/social-posts-dialog-panel.reducer';
import {addSocialPostToGrid, deleteSocialPost} from '@Components/social-media/social-media-reducer';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {HasUserSeenData} from '@Libraries/user.library';
import {getUserHashedId, getUserPremiumLevel, isUserLoggedIn} from '@Libraries/user.library';
import {CaptionErrorTypes} from '@Components/social-media-wizard/social-media-wizard.types';
import {GA4EventName, GA4EventParamName, trackPublishingGA4Events} from '@Libraries/ga-events';
import type {SocialPostsDialogModalProps} from '@Modals/social-posts-dialog-modal';
import {openSocialPostsDialogModal} from '@Modals/social-posts-dialog-modal';
import {openConfirmationModal} from '@Modals/confirmation-modal';
import {USER_PREMIUM_LEVELS} from '@Utils/user.util';
import {getAssetUrl} from '@Utils/s3.util';
import {isIOSApp} from '@Utils/browser.util';
import {openErrorModal} from '@Modals/error-modal';
import type React from 'react';
import type {AjaxSuccessResponse} from '@Utils/ajax.util';
import {isNumber} from 'lodash';
import {readCookie} from '@Utils/cookie.util';
import {closeSocialAccountConnectionResponseModal, openSocialAccountConnectionResponseModal} from '@Modals/social-account-connection-response-modal';
import {noop} from '@/utils/general.util';

export const VIDEO_RESIZE_POLLING_TIMEOUT = 300000;
export const NUM_SKELETONS_TO_SHOW = 4;
export const MAX_PAYG_PARENT_ACCOUNTS = 1;
export const MAX_PREMIUM_LEVEL_1_PARENT_ACCOUNTS = 3;
export const PMW_SOCIAL_MEDIA_URL_FACEBOOK = 'https://www.facebook.com/postermywall';
export const PMW_SOCIAL_MEDIA_URL_INSTAGRAM = 'https://www.instagram.com/postermywall/';
export const PMW_SOCIAL_MEDIA_URL_TWITTER = 'https://www.twitter.com/postermywall';
export const PMW_SOCIAL_MEDIA_URL_YOUTUBE = 'https://www.youtube.com/postermywall';

export const CACHE_KEY_FOR_SCHEDULING = 'emc_schedule_v1';
export const BEFORE_UNLOAD_EVENT = 'beforeunload';
export const PORTRAIT_ASPECT_RATIO = 9 / 16;
export const SQUARE_ASPECT_RATIO = 1;

const UPLOAD_MEDIA_BANNER_SEEN_CACHE_PREFIX = 'upload_media_banner_seen_';
const SM_POST_COVER_TOOLTIP_SEEN_CACHE_PREFIX = 'sm_post_cover_tooltip_seen_'

export enum SocialMediaUpsellEvent {
  ACCOUNTS_PREMIUM_PLUS = 'SocialWizard_Upselling_Accounts_Premium',
  ACCOUNTS_PREMIUM = 'SocialWizard_Upselling_Accounts_Premium_Plus',
  SCHEDULE = 'SocialWizard_Upselling_Schedule',
}

export const getSocialAccountsUpsellByPremiumLevel = (): SocialMediaUpsellEvent => {
  if (window.PMW.getUserPremiumLevel() === USER_PREMIUM_LEVELS.PREMIUM) {
    return SocialMediaUpsellEvent.ACCOUNTS_PREMIUM_PLUS;
  }

  return SocialMediaUpsellEvent.ACCOUNTS_PREMIUM;
};

export enum SocialPostGridItemAction {
  EDIT,
  VIEW_POST,
  SCHEDULE,
  CANCEL_SCHEDULE,
  PUBLISH,
  DUPLICATE,
  DELETE,
  DELETE_PERMANENTLY,
  RESTORE,
  VIEW_PERFORMANCE,
}

export interface SocialPostActionData {
  key: string;
  type: SocialPostGridItemAction;
  iconClasses: string;
  text: string;
  textClasses: string;
  handler: VoidFunction;
}

export const doesAccountHaveCarouselPosts = (accountType: SocialPlatform | undefined): boolean => {
  switch (accountType) {
    case SocialPlatform.INSTAGRAM:
    case SocialPlatform.PINTEREST:
    case SocialPlatform.THREADS:
      return true;
    default:
      return false;
  }
};

export const hasUserSeenSocialMediaPopup = async (): Promise<boolean> => {
  const value = readCookie(HAS_SEEN_SOCIAL_MEDIA_POPUP);
  if (value !== null) {
    return !!value;
  }

  if (!isUserLoggedIn()) {
    return false;
  }

  const data = (await window.PMW.writeLocal('socialmedia/hasUserSeenSocialMediaPopup', {})) as HasUserSeenData;

  if ('hasUserSeen' in data) {
    return data.hasUserSeen;
  }

  return false;
};

export const markUserHasSeenSocialMediaPopup = (): void => {
  void window.PMW.writeLocal('socialmedia/markUserSeenSocialMediaPopup');
};

export const getSocialIconURLByType = (socialType: SocialPlatform, platformMediaType: PlatformMediaType): string => {
  const baseURL = getAssetUrl('social-icons/original/');
  let imageName = '';

  switch (socialType) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_GROUP:
      if (platformMediaType === PlatformMediaType.STORIES) {
        return repoURL('assets/facebook-stories-logo-thumb-circle.png', getReadBucket());
      }

      imageName = 'social-icon-facebook.png';
      break;
    case SocialPlatform.TWITTER:
      return getAssetUrl('social-icons/circle-black/social-icon-x.png');
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      imageName = 'social-icon-linkedin.png';
      break;
    case SocialPlatform.TIKTOK:
      imageName = 'social-icon-tiktok.png';
      break;
    case SocialPlatform.YOUTUBE:
      imageName = 'social-icon-youtube.png';
      break;
    case SocialPlatform.INSTAGRAM:
      if (platformMediaType === PlatformMediaType.STORIES) {
        return repoURL('assets/instagram-stories-logo-thumb-circle.png', getReadBucket());
      }

      imageName = 'social-icon-instagram.png';
      break;
    case SocialPlatform.PINTEREST:
      imageName = 'social-icon-pinterest.png';
      break;
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return repoURL('assets/google-business-profile-logo-thumb-circle.png', getReadBucket());
    case SocialPlatform.THREADS:
      return repoURL('assets/threads-logo-thumb-circle-v3.png', getReadBucket());
    default:
      return '';
  }

  return baseURL + imageName;
};

export const onConnectAccount = (accountType: SocialPlatform): void => {
  switch (accountType) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_GROUP:
      openOAuthPopup('socialmedia/facebookOAuth');
      break;
    case SocialPlatform.INSTAGRAM:
      openOAuthPopup('socialmedia/instagramOAuth');
      break;
    case SocialPlatform.YOUTUBE:
      onConnectYouTube();
      break;
    case SocialPlatform.TWITTER:
      openOAuthPopup('socialmedia/twitterOAuth');
      break;
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      openOAuthPopup('socialmedia/linkedInOAuth');
      break;
    case SocialPlatform.TIKTOK:
      openOAuthPopup('socialmedia/tiktokOAuth');
      break;
    case SocialPlatform.PINTEREST:
      openOAuthPopup('socialmedia/pinterestOAuth');
      break;
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      openOAuthPopup('socialmedia/googleBusinessProfileOAuth');
      break;
    case SocialPlatform.THREADS:
      openOAuthPopup('socialmedia/threadsOAuth');
      break;
    default:
      console.error('Illegal account type');
  }
};

export const getAuthUriForAccountType = (accountType: SocialPlatform): string => {
  switch (accountType) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_GROUP:
      return 'socialmedia/getFacebookOAuthUrl';
    case SocialPlatform.INSTAGRAM:
      return 'socialmedia/getInstagramOauthUrl';
    case SocialPlatform.YOUTUBE:
      return 'socialmedia/getYoutubeOAuthUrl';
    case SocialPlatform.TWITTER:
      return 'socialmedia/getTwitterOAuthUrl';
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      return 'socialmedia/getLinkedInOAuthUrl';
    case SocialPlatform.TIKTOK:
      return 'socialmedia/getTiktokOAuthUrl';
    case SocialPlatform.PINTEREST:
      return 'socialmedia/getPinterestOAuthUrl';
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return 'socialmedia/getGoogleBusinessProfileOAuthUrl';
    case SocialPlatform.THREADS:
      return 'socialmedia/getThreadsOAuthUrl';
    default:
      throw new Error(`Illegal account type${accountType}`);
  }
};

const initAccountConnection = async (accountType: SocialPlatform, onAccountCreated: () => void): Promise<void> => {
  const url = (await window.PMW.readLocal(getAuthUriForAccountType(accountType), {
    isIOSApp: isIOSApp(),
  })) as string;

  const accountConnectionWindow = window.open('about:blank', '_blank');

  if (!accountConnectionWindow) {
    openErrorModal();
    return;
  }

  const ACCOUNT_CONNECTION_TIMEOUT_MS = 300000;
  accountConnectionWindow.location.href = url;

  const connectionData = (await window.PMW.pollingAjaxCallAsync(
    async () => {},
    () => {
      return window.PMW.readLocal('socialmedia/hasAccountBeenAdded', {
        platform: accountType,
      });
    },
    ACCOUNT_CONNECTION_TIMEOUT_MS
  )) as SocialAccountConnectionResponse | null;

  handleAccountConnectionResponse(connectionData, onAccountCreated);
};

export const onConnectAccountForIosApp = async (accountType: SocialPlatform, onAccountCreated: () => void): Promise<void> => {
  if (accountType === SocialPlatform.YOUTUBE) {
    onConnectYouTube(initAccountConnection.bind(null, accountType, onAccountCreated));
  } else {
    await initAccountConnection(accountType, onAccountCreated);
  }
};

const onConnectYouTube = (initiateConnection?: () => Promise<void>): void => {
  openConfirmationModal({
    onConfirm: (): void => {
      if (initiateConnection) {
        void initiateConnection();
      } else {
        openOAuthPopup('socialmedia/youtubeOAuth');
      }
    },
    icon: 'icon-check',
    infoText: window.i18next.t('pmwjs_youtube_agreement_point', {
      ytCommunityGuidelines: 'https://www.youtube.com/howyoutubeworks/policies/community-guidelines/',
      googlePrivacyPolicy: 'http://www.google.com/policies/privacy',
      ytTermsOfService: 'https://www.youtube.com/t/terms',
      privacyPolicy: 'https://www.postermywall.com/index.php/info/termsofuse',
      googleSecuritySettings: 'https://security.google.com/settings/security/permissions',
    }),
    doShowContentIcon: false,
    confirmButtonText: window.i18next.t('pmwjs_agree_to_all'),
    panelTitle: window.i18next.t('pmwjs_add_youtube_channel'),
    modalHeight: 'fit-content',
  });
};

export const isTwitterCurrentlyUnusable = (account: SocialAccount): boolean => {
  if (account.type === SocialPlatform.TWITTER) {
    // TODO: switch to false when we're back up
    return false;
  }

  return false;
};

export const isPostersCountValidForMultiPosting = (posterHashedIds: string[], account: SocialAccount): boolean => {
  const accountPlatform = account.type;
  const maxNumberOfPosts = MaxPostersPerPlatform[accountPlatform];

  if (maxNumberOfPosts === null) {
    return true;
  }

  return posterHashedIds.length <= maxNumberOfPosts;
};

export const isYouTubeCurrentlyUnusable = (account: SocialAccount): boolean => {
  if (account.type === SocialPlatform.YOUTUBE) {
    // TODO: switch to false when we're back up
    return false;
  }

  return false;
};

export const isGoogleBusinessProfileAccountUnverified = (account: SocialAccount): boolean => {
  if (account.type === SocialPlatform.GOOGLE_BUSINESS_PROFILE) {
    return !account.isVerifiedGoogleBusinessProfileAccount;
  }

  return false;
};

const openOAuthPopup = (oAuthURI: string): void => {
  window.open(window.PMW.util.site_url(oAuthURI), '_blank');
};

export const getNumParentAccounts = (accounts: SocialAccount[]): number => {
  let numAccounts = 0;
  accounts.forEach((account) => {
    if (account.type !== SocialPlatform.FACEBOOK_PAGE && account.type !== SocialPlatform.FACEBOOK_GROUP) {
      numAccounts += 1;
    }
  });

  return numAccounts;
};

export const isEligibleForSocialAccountsUpsellDialog = (numAccounts: number): boolean => {
  const premiumLevel = getUserPremiumLevel();

  if (premiumLevel === USER_PREMIUM_LEVELS.PREMIUM && numAccounts >= MAX_PREMIUM_LEVEL_1_PARENT_ACCOUNTS) {
    return true;
  }

  if (premiumLevel === USER_PREMIUM_LEVELS.PAYG && numAccounts >= MAX_PAYG_PARENT_ACCOUNTS) {
    return true;
  }

  return false;
};

const onPremiumUpsellClicked = (eventToTrack = ''): void => {
  if (eventToTrack) {
    window.PMW.gtm.trackGA4CustomEvent(eventToTrack);
  }
};

const getUpsellDialogNameByPremiumLevel = (userPremiumLevel: USER_PREMIUM_LEVELS): string => {
  if (userPremiumLevel === USER_PREMIUM_LEVELS.PAYG) {
    return window.PMW.PREMIUM_ONLY_FEATURE_ADD_SOCIAL_MEDIA_ACCOUNTS as string;
  }

  return window.PMW.PREMIUM_PLUS_ONLY_FEATURE_ADD_SOCIAL_MEDIA_ACCOUNTS as string;
};

export const showSocialMediaPublishingUpsellDialog = (eventToTrack = '', userPremiumLevel: number = USER_PREMIUM_LEVELS.PAYG): void => {
  const upsellDialogName = getUpsellDialogNameByPremiumLevel(userPremiumLevel);
  const eventName = window.PMW.getPremiumUpsellClickEvent(upsellDialogName) as string;
  const eventHandler = (): void => {
    onPremiumUpsellClicked(eventToTrack);
  };

  window.PMW.showPremiumOnlyFeatureDialog(upsellDialogName);
  window.addEventListener(eventName, eventHandler, {
    once: true,
  });
};

export const doesAccountRequireReconnection = (account: SocialAccount): boolean => {
  return account.isAccessTokenExpired && !account.isRefreshTokenValid;
};

export const getCaptionErrorAccountId = (accountIdsToPublishTo: SocialMediaAccountId[], captionErrors: CaptionErrorByAccountIds): number | null => {
  const nAccounts = accountIdsToPublishTo.length;
  for (let i = 0; i < nAccounts; i++) {
    if (captionErrors[accountIdsToPublishTo[i]] && captionErrors[accountIdsToPublishTo[i]] !== CaptionErrorTypes.NO_ERROR) {
      return accountIdsToPublishTo[i];
    }
  }

  return null;
};

export const getTitleErrorAccountId = (accountIdsToPublishTo: SocialMediaAccountId[], titleErrors: TitleErrorByAccountIds): number | null => {
  const nAccounts = accountIdsToPublishTo.length;
  for (let i = 0; i < nAccounts; i++) {
    if (titleErrors[accountIdsToPublishTo[i]] && titleErrors[accountIdsToPublishTo[i]] !== null) {
      return accountIdsToPublishTo[i];
    }
  }

  return null;
};

const reAuthenticateForIOS = async (idAccount: number, accountType: SocialPlatform): Promise<void> => {
  try {
    const response = (await window.PMW.readLocal('socialmedia/refreshAccountForIOSApp', {
      idAccount,
      platform: accountType,
    })) as RefreshAccountResponse;
    if (response.url) {
      window.open(response.url, '_blank');
    }
  } catch (e) {
    openErrorModal();
  }
};

export const reAuthenticateInModal = (idAccount: number, accountType: SocialPlatform): void => {
  if (isIOSApp()) {
    void reAuthenticateForIOS(idAccount, accountType);
    return;
  }

  window.open(window.PMW.util.site_url(`socialmedia/refreshAccount?idAccount=${idAccount}&platform=${accountType}`), '', 'width=600,height=600,top=0,=right=50%,=scrollbars=yes');
};

export const getDisplayableSocialPlatformURL = (type: SocialPlatform): string => {
  switch (type) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_GROUP:
      return 'facebook.com';
    case SocialPlatform.TWITTER:
      return 'x.com';
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      return 'linkedin.com';
    case SocialPlatform.TIKTOK:
      return 'tiktok.com';
    case SocialPlatform.YOUTUBE:
      return 'youtube.com';
    case SocialPlatform.INSTAGRAM:
      return 'instagram.com';
    case SocialPlatform.PINTEREST:
      return 'pinterest.com';
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return 'google.com/business';
    case SocialPlatform.THREADS:
      return 'threads.net';
    default:
      return '';
  }
};

export const getRedirectableSocialPlatformURL = (type: SocialPlatform): string => {
  switch (type) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_GROUP:
      return 'https://www.facebook.com';
    case SocialPlatform.TWITTER:
      return 'https://www.twitter.com';
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      return 'https://www.linkedin.com';
    case SocialPlatform.TIKTOK:
      return 'https://www.tiktok.com';
    case SocialPlatform.YOUTUBE:
      return 'https://www.youtube.com';
    case SocialPlatform.INSTAGRAM:
      return 'https://www.instagram.com';
    case SocialPlatform.PINTEREST:
      return 'https://www.pinterest.com';
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return 'https://www.google.com/business';
    case SocialPlatform.THREADS:
      return 'https://www.threads.net/';
    default:
      return '';
  }
};

export const getSocialPlatformDetailedName = (type: SocialPlatform): string => {
  switch (type) {
    case SocialPlatform.FACEBOOK:
      return 'Facebook';
    case SocialPlatform.FACEBOOK_PROFILE:
      return window.i18next.t('pmwjs_facebook_profile');
    case SocialPlatform.FACEBOOK_PAGE:
      return window.i18next.t('pmwjs_facebook_page');
    case SocialPlatform.FACEBOOK_GROUP:
      return window.i18next.t('pmwjs_facebook_group');
    case SocialPlatform.TWITTER:
      return 'X (formerly Twitter)';
    case SocialPlatform.LINKEDIN:
      return 'LinkedIn';
    case SocialPlatform.LINKEDIN_PAGE:
      return window.i18next.t('pmwjs_linkedin_page');
    case SocialPlatform.LINKEDIN_PROFILE:
      return window.i18next.t('pmwjs_linkedin_profile');
    case SocialPlatform.TIKTOK:
      return 'TikTok';
    case SocialPlatform.YOUTUBE:
      return 'YouTube';
    case SocialPlatform.INSTAGRAM:
      return 'Instagram';
    case SocialPlatform.PINTEREST:
      return 'Pinterest';
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return 'Business Profile';
    case SocialPlatform.THREADS:
      return 'Threads';
    default:
      return '';
  }
};

export const getSocialPlatformName = (type: SocialPlatform): string => {
  switch (type) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_GROUP:
      return 'Facebook';
    case SocialPlatform.TWITTER:
      return 'X (formerly Twitter)';
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      return 'LinkedIn';
    case SocialPlatform.TIKTOK:
      return 'TikTok';
    case SocialPlatform.YOUTUBE:
      return 'YouTube';
    case SocialPlatform.INSTAGRAM:
      return 'Instagram';
    case SocialPlatform.PINTEREST:
      return 'Pinterest';
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return 'Google Business Profile';
    case SocialPlatform.THREADS:
      return 'Threads';
    default:
      return '';
  }
};

export const isAccountInSearch = (searchTerm: string | undefined, accountName: string): boolean => {
  if (!searchTerm || searchTerm === '') {
    return true;
  }

  // Convert both the account name and searchTerm to lowercase for case-insensitive matching
  const lowerCaseAccountName = accountName.toLowerCase();
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return lowerCaseAccountName.includes(lowerCaseSearchTerm);
};

export const getComparableSocialPlatform = (type: SocialPlatform): SocialPlatform => {
  switch (type) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_GROUP:
      return SocialPlatform.FACEBOOK;
    default:
      return type;
  }
};

export const isPostVisibleForFilter = (postState: PostState, postStateFilter: PostStateFilter): boolean => {
  switch (postState) {
    case PostState.DRAFT:
    case PostState.PUBLISHING:
    case PostState.READY:
    case PostState.DELETED:
      return postStateFilter[PostState.DRAFT];
    case PostState.SCHEDULED:
    case PostState.SCHEDULE_READY:
      return postStateFilter[PostState.SCHEDULED];
    case PostState.PUBLISHED:
      return postStateFilter[PostState.PUBLISHED];
    case PostState.ERROR:
    case PostState.SCHEDULE_ERROR:
    case PostState.WAITING_FOR_INSTAGRAM:
      return postStateFilter[PostState.ERROR];
    default:
      return false;
  }
};

export const startSocialMediaPublishing = (posterHashedId?: string, callback: () => void = (): void => {}): void => {
  if (posterHashedId) {
    window.location.href = window.PMW.util.site_url(`socialmedia/post/design/${posterHashedId}`);
  } else {
    window.location.href = window.PMW.util.site_url(`socialmedia/post/`);
  }

  callback();
};

export const isPostScheduled = (state: PostState): boolean => {
  return state === PostState.SCHEDULING || state === PostState.SCHEDULE_READY;
};

export const isPostPublished = (state: PostState): boolean => {
  return state === PostState.PUBLISHED;
};

export const isPostEditable = (state: PostState): boolean => {
  return (
    state === PostState.ERROR ||
    state === PostState.DRAFT ||
    state === PostState.READY ||
    state === PostState.SCHEDULE_ERROR ||
    state === PostState.SCHEDULED ||
    state === PostState.SCHEDULE_READY
  );
};

export const convertDateToUTCTimestamp = (date: Date) => {
  return Date.parse(date.toUTCString()) / 1000;
};

export const deletePostSchedule = (post: SocialPost): void => {
  const postId = post.id;
  void window.PMW.write(window.PMW.util.site_url('socialmedia/unschedulePost'), {idPost: post.id}, (r: any): void => {
    if (r.status === 'success') {
      getPostById(postId, SocialPostEvent.UNSCHEDULED);
    } else {
      window.PMW.setLoading(window.i18next.t('pmwjs_something_went_wrong'), true);
    }
  });
};

export const mapIndexToAccountPublishingParams = (socialPost: SocialPost, index: number): AccountMediaTypeInformation => {
  const allPublishingParams = socialPost.publishingParams;

  const correctedIndex = index === -1 ? 0 : index;
  let accountIndex = 0;

  for (let i = 0; i < getNumPublishingParams(socialPost); ) {
    const account = socialPost.accounts[accountIndex];

    if (allPublishingParams[account.id].POST) {
      if (i === correctedIndex) {
        return {
          account,
          platformMediaType: PlatformMediaType.POST,
        };
      }

      i += 1;
    }

    if (allPublishingParams[account.id].REELS) {
      if (i === correctedIndex) {
        return {
          account,
          platformMediaType: PlatformMediaType.REELS,
        };
      }

      i += 1;
    }

    if (allPublishingParams[account.id].STORIES) {
      if (i === correctedIndex) {
        return {
          account,
          platformMediaType: PlatformMediaType.STORIES,
        };
      }

      i += 1;
    }

    accountIndex += 1;
  }

  console.error('No publishing params for index found');
  return {
    account: socialPost.accounts[0],
    platformMediaType: PlatformMediaType.POST,
  };
};

export const viewPostOnSocialNetworks = (post: SocialPost): void => {
  try {
    openPostsOnSocialNetworks(post);
  } catch (error) {
    window.PMW.showMessageDialog(window.i18next.t('pmwjs_view_posts_pop_ups_blocked'), window.i18next.t('pmwjs_view_posts_pop_ups_blocked_message'));
  }
};

export const openPostsOnSocialNetworks = (post: SocialPost): void => {
  const totalPublishingParams = getNumPublishingParams(post);
  let popUpBlocked = false;

  for (let i = 0; i < totalPublishingParams; i++) {
    const accountInformation = mapIndexToAccountPublishingParams(post, i);
    const {account} = accountInformation;
    const {platformMediaType} = accountInformation;

    const window = viewPostOnSocialNetworkForAccountWithMediaType(post, account, platformMediaType);

    if (window === null || typeof window === 'undefined') {
      popUpBlocked = true;
    }
  }

  if (popUpBlocked) {
    throw new Error('Popup(s) blocked.');
  }
};

export const viewPostOnSocialNetworkForAccountWithMediaType = (post: SocialPost, account: SocialAccount, platformMediaType: PlatformMediaType): Window | null => {
  const publishingParams = post.publishingParams[account.id][platformMediaType];
  const url = getPublishUrlFromPublishParams(publishingParams);
  return window.open(url, '_blank');
};

export const getPublishUrlFromPublishParams = (publishingParams: SocialMediaPublishingParams | null): string => {
  if (!publishingParams) {
    return '';
  }

  const url = publishingParams.publishedURL;

  if (!url) {
    return '';
  }

  return publishingParams.publishedURL!;
};

export const processPostToEnableMetrics = (post: SocialPost): void => {
  if (post.state !== PostState.PUBLISHED) {
    return;
  }

  void window.PMW.write(window.PMW.util.site_url('socialmedia/enablePostMetrics'), {postId: post.id}, (r: any): void => {
    if (r.status === 'success') {
      window.dispatchEvent(
        new CustomEvent(SocialPostEvent.UPDATED, {
          detail: {
            postId: post.id,
            post: r.data.post,
          },
        })
      );
    }
  });
};

export const isPublishingAsPost = (publishingParamsToCheck: Record<SocialMediaAccountId, MediaTypePublishingParams>, accountId: number): boolean => {
  return publishingParamsToCheck[accountId].POST !== null;
};
export const isPublishingAsStory = (publishingParamsToCheck: Record<SocialMediaAccountId, MediaTypePublishingParams>, accountId: number): boolean => {
  return publishingParamsToCheck[accountId].STORIES !== null;
};

export const isPublishingAsReel = (publishingParamsToCheck: Record<SocialMediaAccountId, MediaTypePublishingParams>, accountId: number): boolean => {
  return publishingParamsToCheck[accountId].REELS !== null;
};

export const isPublishingAsPostOrReel = (publishingParamsToCheck: Record<SocialMediaAccountId, MediaTypePublishingParams>, accountId: number): boolean => {
  return isPublishingAsPost(publishingParamsToCheck, accountId) || isPublishingAsReel(publishingParamsToCheck, accountId);
};

export const isPublishingAsStoryOnly = (publishingParamsToCheck: Record<SocialMediaAccountId, MediaTypePublishingParams>, accountId: number): boolean => {
  return !isPublishingAsPostOrReel(publishingParamsToCheck, accountId) && isPublishingAsStory(publishingParamsToCheck, accountId);
};

export const isPublishingAsAPostOrReelOnly = (publishingParamsToCheck: Record<SocialMediaAccountId, MediaTypePublishingParams>, accountId: number): boolean => {
  return isPublishingAsPostOrReel(publishingParamsToCheck, accountId) && !isPublishingAsStory(publishingParamsToCheck, accountId);
};

export const hasPublishingTransitionedFromBothToPostOrReelOnly = (
  oldPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  newPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  accountId: number
): boolean => {
  const wasPublishingAsPostOrReel = isPublishingAsPostOrReel(oldPublishingParams, accountId);
  const wasPublishingAsStory = isPublishingAsStory(oldPublishingParams, accountId);

  const wasPublishingAsBoth = wasPublishingAsStory && wasPublishingAsPostOrReel;

  if (!wasPublishingAsBoth) {
    return false;
  }

  const isPublishingToPostOrReelOnly = isPublishingAsAPostOrReelOnly(newPublishingParams, accountId);

  return wasPublishingAsBoth && isPublishingToPostOrReelOnly;
};

export const hasPublishingTransitionedFromPostOrReelOnlyToBoth = (
  oldPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  newPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  accountId: number
): boolean => {
  const wasPublishingAsPostOrReelOnly = isPublishingAsAPostOrReelOnly(oldPublishingParams, accountId);

  if (!wasPublishingAsPostOrReelOnly) {
    return false;
  }

  const isPublishingToBoth = isPublishingAsStory(newPublishingParams, accountId) && isPublishingAsPostOrReel(newPublishingParams, accountId);

  return wasPublishingAsPostOrReelOnly && isPublishingToBoth;
};

export const hasPublishingTransitionedFromStoryOnlyToBoth = (
  oldPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  newPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  accountId: number
): boolean => {
  const wasPublishingAsStoryOnly = isPublishingAsStoryOnly(oldPublishingParams, accountId);

  if (!wasPublishingAsStoryOnly) {
    return false;
  }

  const isPublishingToBoth = isPublishingAsStory(newPublishingParams, accountId) && isPublishingAsPostOrReel(newPublishingParams, accountId);

  return wasPublishingAsStoryOnly && isPublishingToBoth;
};

export const hasPublishingTransitionedFromBothToStoryOnly = (
  oldPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  newPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  accountId: number
): boolean => {
  const wasPublishingAsPostOrReel = isPublishingAsPostOrReel(oldPublishingParams, accountId);
  const wasPublishingAsStory = isPublishingAsStory(oldPublishingParams, accountId);

  const wasPublishingAsBoth = wasPublishingAsStory && wasPublishingAsPostOrReel;

  if (!wasPublishingAsBoth) {
    return false;
  }

  const isPublishingStoryOnly = isPublishingAsStoryOnly(newPublishingParams, accountId);

  return wasPublishingAsBoth && isPublishingStoryOnly;
};

export const getNumParams = (publishingParamsProvided: Record<number, MediaTypePublishingParams>, accountIds: number[]) => {
  let totalParams = 0;

  for (let i = 0; i < accountIds.length; i++) {
    const accountId = accountIds[i];
    const mediaTypePublishingParams = publishingParamsProvided[accountId];
    const postParams = mediaTypePublishingParams.POST;
    const reelParams = mediaTypePublishingParams.REELS;
    const storyParams = mediaTypePublishingParams.STORIES;

    if (postParams) {
      totalParams += 1;
    }

    if (reelParams) {
      totalParams += 1;
    }

    if (storyParams) {
      totalParams += 1;
    }
  }

  return totalParams;
};

export const havePublishingParamsChangedForAccountWithId = (
  oldPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  newPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>,
  accountId: number
): boolean => {
  let checkPost = false;
  let checkReel = false;
  let checkStory = false;

  if ((oldPublishingParams[accountId].POST && newPublishingParams[accountId].POST) || (!oldPublishingParams[accountId].POST && !newPublishingParams[accountId].POST)) {
    checkPost = true;
  }

  if ((oldPublishingParams[accountId].STORIES && newPublishingParams[accountId].STORIES) || (!oldPublishingParams[accountId].STORIES && !newPublishingParams[accountId].STORIES)) {
    checkStory = true;
  }

  if ((oldPublishingParams[accountId].REELS && newPublishingParams[accountId].REELS) || (!oldPublishingParams[accountId].REELS && !newPublishingParams[accountId].REELS)) {
    checkReel = true;
  }

  return !(!checkReel && !checkPost && !checkStory);
};

export const deleteWithEndpoint = async (endpoint: string, postId: string): Promise<void> => {
  showLoading('delete-post', {
    text: window.i18next.t('pmwjs_deleting'),
  });

  try {
    await window.PMW.writeLocal(endpoint, {
      idPost: postId,
    });
    window.PMW.redux.store.dispatch(deleteSocialPost(postId));
  } catch (e) {
    window.PMW.openErrorModal({
      message: window.i18next.t('pmwjs_social_post_delete_error'),
    });
    return;
  } finally {
    hideLoading('delete-post');
  }
};

export const schedulePostByTimestamp = (postId: string, timezoneOffset: number, publishOn: number): void => {
  void window.PMW.write(
    window.PMW.util.site_url('socialmedia/rescheduleSocialPost'),
    {
      idPost: postId,
      publishOn,
      timezoneOffset,
    },
    (r: any): void => {
      if (r.status === 'success') {
        window.PMW.redux.store.dispatch(setSocialPostDialogStatus(PostState.SCHEDULE_READY));
        window.PMW.redux.store.dispatch(setIsRescheduled(true));
        getPostById(postId, SocialPostEvent.SCHEDULED);
      } else {
        window.PMW.setLoading(window.i18next.t('pmwjs_something_went_wrong'), true);
      }
    }
  );
};

export const schedulePost = (postId: string, date: Date): void => {
  const timezoneOffset = date.getTimezoneOffset();
  const dateTimestamp = convertDateToUTCTimestamp(date);

  void window.PMW.write(
    window.PMW.util.site_url('socialmedia/rescheduleSocialPost'),
    {
      idPost: postId,
      publishOn: dateTimestamp,
      timezoneOffset,
    },
    (r: any): void => {
      if (r.status === 'success') {
        window.PMW.redux.store.dispatch(setSocialPostDialogStatus(PostState.SCHEDULE_READY));
        window.PMW.redux.store.dispatch(setIsRescheduled(true));
        getPostById(postId, SocialPostEvent.SCHEDULED);
      } else {
        window.PMW.setLoading(window.i18next.t('pmwjs_something_went_wrong'), true);
      }
    }
  );
};

export const publishPostForAccounts = (accounts: SocialAccount[], postId: string): Promise<void[]> => {
  // const promiseArray = accounts.map((account: SocialAccount, index: number) => {
  //   return publishPostForAccount(account.id, postId)
  //     .then(() => {
  //       window.PMW.redux.store.dispatch(setPublishingSuccessForIndex(index));
  //     })
  //     .catch(() => {
  //       window.PMW.redux.store.dispatch(setPublishingFailureForIndex(index));
  //     });
  // });
  //
  return Promise.all([]);
};

export const publishPostForAccount = async (idSocialMediaAccount: number, idPost: string): Promise<void> => {};

export const addDuplicatedPostToGrid = (post: SocialPost): void => {
  window.PMW.redux.store.dispatch(addSocialPostToGrid(post));
};

export const duplicatePostAsDraft = (post: SocialPost): void => {
  window.PMW.setLoading(window.i18next.t('pmwjs_duplicating'));

  void window.PMW.write(
    window.PMW.util.site_url('socialmedia/duplicatePostAsDraft'),
    {
      postId: post.id,
    },
    (r: any): void => {
      if (r.status === 'success') {
        window.PMW.setLoading(window.i18next.t('pmwjs_duplicated'), true);
        const duplicatedPost = r.data.post;
        addDuplicatedPostToGrid(duplicatedPost);
      } else {
        window.PMW.setLoading(window.i18next.t('pmwjs_something_went_wrong'), true);
      }

      setTimeout(() => {
        window.PMW.setLoading(null);
      }, 3000);
    }
  );
};

export const triggerSocialPostEvent = (eventType: SocialPostEvent, detailObject: any): void => {
  window.dispatchEvent(
    new CustomEvent(eventType, {
      detail: detailObject,
    })
  );
};

export const triggerPublishingStatusSync = (isError: boolean, post: SocialPost): void => {
  const updatedPost: SocialPost = {...post, state: isError ? PostState.ERROR : PostState.PUBLISHED};

  triggerSocialPostEvent(SocialPostEvent.ERROR, {
    postId: post.id,
    post: updatedPost,
  });
};

const openSocialPostsDialog = (post: SocialPost): void => {
  const modalProps: SocialPostsDialogModalProps = {socialPost: post};
  openSocialPostsDialogModal(modalProps);
};

export const openModalForSocialPostById = (postId: string): void => {
  void window.PMW.write(
    window.PMW.util.site_url('socialmedia/getPostById'),
    {
      idPost: postId,
    },
    (r: any) => {
      if (r.status === 'success') {
        const {post} = r.data;
        openSocialPostsDialog(post);
      } else {
        window.PMW.setLoading(window.i18next.t('pmwjs_something_went_wrong'), true);
      }
    }
  );
};

const triggerSchedulingTrackingEvent = (accounts: SocialAccount[]): void => {
  for (let i = 0; i < accounts.length; i++) {
    const platform = accounts[i].type;
    const platformName = getSocialPlatformName(platform);

    trackPublishingGA4Events(GA4EventName.POST_SCHEDULED, {[GA4EventParamName.PLATFORM]: platformName});
  }
};

export const getPostById = (postId: string, triggerEvent?: SocialPostEvent): void => {
  void window.PMW.write(
    window.PMW.util.site_url('socialmedia/getPostById'),
    {
      idPost: postId,
    },
    (r: any) => {
      if (r.status === 'success') {
        if (triggerEvent !== undefined) {
          if (triggerEvent === SocialPostEvent.SCHEDULED) {
            triggerSchedulingTrackingEvent(r.data.post.accounts);
          }

          triggerSocialPostEvent(triggerEvent, {
            postId,
            post: r.data.post,
          });
        }
      } else {
        window.PMW.setLoading(window.i18next.t('pmwjs_something_went_wrong'), true);
      }
    }
  );
};

export const restoreSocialMediaPost = (idPost?: string): void => {
  window.PMW.setLoading(window.i18next.t('pmwjs_loading'));
  void window.PMW.write(window.PMW.util.site_url('socialmedia/restoreSocialPost'), {
    idPost,
  }).then((data: AjaxSuccessResponse) => {
    if (data.status !== 'success') {
      alert(window.i18next.t('pmwjs_error_message'));
    }
  });
};

export const openSocialMediaPost = (idPost?: string): void => {
  window.location.href = window.PMW.util.site_url(`socialmedia/post/${idPost}`);
};

export const hideUnnecessaryMyStuffElements = (): void => {
  document.querySelector('.js-padded-top-bar')?.classList.add('_hidden');
};

export const unhideUnnecessaryMyStuffElements = (): void => {
  document.querySelector('.js-padded-top-bar')?.classList.remove('_hidden');
};

export const getDummySocialPostForSkeleton = (): SocialPost => {
  return {
    id: '0',
    userId: 0,
    privacySetting: PrivacySettings.PUBLIC,
    state: PostState.PUBLISHED,
    publishingParams: {},
    accounts: [getDummySocialAccountForSkeleton()],
    posterHashedId: '0',
    isDeleted: false,
    isVideo: false,
  } as SocialPost;
};

export const getDummySocialAccountForSkeleton = (): SocialAccount => {
  return {
    id: 0,
    type: SocialPlatform.FACEBOOK,
    name: '',
    displayURL: '',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForStorybook = (): SocialAccount => {
  return {
    id: 61,
    type: SocialPlatform.FACEBOOK,
    name: 'Post Wallone',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForInstagram = (): SocialAccount => {
  return {
    id: 2023100201,
    type: SocialPlatform.INSTAGRAM,
    name: 'Pixie Pictures',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForLinkedin = (): SocialAccount => {
  return {
    id: 2022100145,
    type: SocialPlatform.LINKEDIN_PROFILE,
    name: 'Pixie Pictures',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForTikTok = (): SocialAccount => {
  return {
    id: 2023100201,
    type: SocialPlatform.TIKTOK,
    name: 'Pixie Pictures',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForPinterest = (): SocialAccount => {
  return {
    id: 2023100201,
    type: SocialPlatform.PINTEREST,
    name: 'Pixie Pictures',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForYoutube = (): SocialAccount => {
  return {
    id: 2023100314,
    type: SocialPlatform.YOUTUBE,
    name: 'Pixie Pictures',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForTwitter = (): SocialAccount => {
  return {
    id: 2023100276,
    type: SocialPlatform.TWITTER,
    name: 'Pixie Pictures',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialAccountForGoogleBusinessProfile = (): SocialAccount => {
  return {
    id: 2024050812,
    type: SocialPlatform.GOOGLE_BUSINESS_PROFILE,
    isVerifiedGoogleBusinessProfileAccount: true,
    name: 'Google My Business',
    displayURL: 'https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg',
    state: AccountState.CONNECTED,
  } as SocialAccount;
};

export const getTestSocialPostForStorybook = (): SocialPost => {
  return {
    isDeleted: false,
    isVideo: false,
    posterHashedId: '',
    privacySetting: PrivacySettings.PUBLIC,
    state: PostState.DRAFT,
    publishingParams: {},
    userId: 1,
    id: '1827398123',
    title: 'This is a test post',
    accounts: [getTestSocialAccountForStorybook()],
    caption: 'This is a test caption',
    posterHashedIds: ['1'],
    areMetricsProcessed: false,
  };
};

export const getTestSocialPostImages = (): SocialPost => {
  return {
    isDeleted: false,
    isVideo: false,
    posterHashedId: '',
    privacySetting: PrivacySettings.PUBLIC,
    state: PostState.PUBLISHED,
    publishingParams: {
      61: {
        [PlatformMediaType.POST]: getTestPublishingParamsForFacebook(),
        [PlatformMediaType.REELS]: null,
        [PlatformMediaType.STORIES]: null,
      },
      2023100201: {
        [PlatformMediaType.REELS]: getTestPublishingParamsForInstagram(),
        [PlatformMediaType.POST]: null,
        [PlatformMediaType.STORIES]: null,
      },
      2022100145: {
        [PlatformMediaType.POST]: getTestVideoPublishingParamsForLinkedin(),
        [PlatformMediaType.REELS]: null,
        [PlatformMediaType.STORIES]: null,
      },
      2023100202: {
        [PlatformMediaType.POST]: getTestVideoPublishingParamsForTiktok(),
        [PlatformMediaType.REELS]: null,
        [PlatformMediaType.STORIES]: null,
      },
    },
    userId: 1,
    id: 'da65698c53bc73e264bcaa18daecdfa0',
    title: 'This is a test post',
    accounts: [
      getTestSocialAccountForStorybook(),
      getTestSocialAccountForPinterest(),
      getTestSocialAccountForInstagram(),
      getTestSocialAccountForLinkedin(),
      getTestSocialAccountForTikTok(),
    ],
    caption: 'This is a test caption',
    timeZoneOffset: -300,
    publishOn: 1704345552,
    areMetricsProcessed: false,
    posterHashedIds: ['1', '2', '3', '4'],
    metrics: {
      19832901832123: {
        numImpressions: 30,
        numLikes: 1000,
        numClicks: 200,
        numComments: 120,
        numShares: 100,
        numReach: 90,
        numViews: 1387,
      },
      2022100145: {
        numImpressions: 730,
        numLikes: 1000,
        numClicks: 200,
        numComments: 1230,
        numShares: 150,
        numReach: 900,
        numViews: 137,
      },
    },
  };
};

export const getTestSocialPostSinglePlatform = (): SocialPost => {
  return {
    isDeleted: false,
    isVideo: false,
    posterHashedId: '',
    privacySetting: PrivacySettings.PUBLIC,
    state: PostState.PUBLISHED,
    publishingParams: {
      19832901832123: {
        [PlatformMediaType.POST]: getTestPublishingParamsForFacebook(),
        [PlatformMediaType.STORIES]: null,
        [PlatformMediaType.REELS]: null,
      },
    },
    userId: 1,
    id: 'da65698c53bc73e264bcaa18daecdfa0',
    title: 'This is a test post',
    accounts: [getTestSocialAccountForStorybook()],
    caption: 'This is a test caption',
    timeZoneOffset: -300,
    publishOn: 1704345552,
    areMetricsProcessed: false,
    posterHashedIds: ['1', '2', '3', '4', '9'],
  };
};

export const getTestDesignsGraphicsMetaForImages = (): Record<PosterHashedId, GraphicMeta> => {
  const imgUrl = 'https://ucarecdn.com/a380de20-3a80-45c9-b338-52d0ecaaf31a/-/preview/500x500/-/quality/smart_retina/-/format/auto/';

  return {
    '1': {
      isVideo: false,
      graphicURL: imgUrl,
      name: '',
    },
    '2': {
      isVideo: false,
      graphicURL: imgUrl,
      name: '',
    },
    '3': {
      isVideo: false,
      graphicURL: imgUrl,
      name: '',
    },
    '4': {
      isVideo: false,
      graphicURL: imgUrl,
      name: '',
    },
  };
};

export const getTestSocialPostVideos = (): SocialPost => {
  return {
    isDeleted: false,
    isVideo: true,
    posterHashedId: '',
    privacySetting: PrivacySettings.PUBLIC,
    state: PostState.DRAFT,
    publishingParams: {
      2023100314: {
        [PlatformMediaType.POST]: getTestVideoPublishingParamsForYoutube(),
        [PlatformMediaType.REELS]: null,
        [PlatformMediaType.STORIES]: null,
      },
      2023100201: {
        [PlatformMediaType.POST]: getTestVideoPublishingParamsForTiktok(),
        [PlatformMediaType.REELS]: null,
        [PlatformMediaType.STORIES]: null,
      },
      2022100145: {
        [PlatformMediaType.POST]: getTestVideoPublishingParamsForLinkedin(),
        [PlatformMediaType.REELS]: null,
        [PlatformMediaType.STORIES]: null,
      },
    },
    userId: 1,
    id: 'da65698c53bc73e264bcaa18daecdfa0',
    title: 'This is a test post',
    accounts: [getTestSocialAccountForYoutube(), getTestSocialAccountForTikTok(), getTestSocialAccountForLinkedin()],
    caption: 'This is a test caption',
    posterHashedIds: ['1', '2', '3', '4', '9'],
    areMetricsProcessed: false,
  };
};

export const getTestVideoPublishingParamsForLinkedin = (): SocialMediaPublishingParams => {
  return {
    caption: 'This is a test caption - Toronto!',
    title: 'Welcome to Canada',
    idSocialMediaAccount: 123,
    publishedURL: null,
    publishErrorMessage: null,
    publishId: null,
    state: PostState.DRAFT,
    graphicsMeta: {},
    isOriginalDeleted: false,
  };
};

export const getTestVideoPublishingParamsForYoutube = (): SocialMediaPublishingParams => {
  const VideoUrlFacebook = 'https://res.cloudinary.com/dmwe76qmz/video/upload/v1695613246/wideLarge_giihfm.mp4';
  const publishingParams: SocialMediaPublishingParams = {
    caption: 'This is a test caption - Toronto!',
    title: 'Welcome to Canada',
    idSocialMediaAccount: 123,
    privacySettings: YouTubePrivacySettings.PUBLIC,
    publishedURL: null,
    publishErrorMessage: null,
    publishId: null,
    state: PostState.DRAFT,
    graphicsMeta: {},
    isOriginalDeleted: false,
  };

  return publishingParams as SocialMediaPublishingParams;
};

export const getTestVideoPublishingParamsForTiktok = (): SocialMediaPublishingParams => {
  const publishingParams: SocialMediaPublishingParams = {
    caption: 'tiktok caption',
    idSocialMediaAccount: 123,
    publishedURL: null,
    publishErrorMessage: null,
    publishId: null,
    state: PostState.DRAFT,
    graphicsMeta: {},
    isOriginalDeleted: false,
  };

  return publishingParams as SocialMediaPublishingParams;
};

export const getTestPublishingParamsForFacebook = (): SocialMediaPublishingParams => {
  const imgUrlFacebook = 'https://ucarecdn.com/a380de20-3a80-45c9-b338-52d0ecaaf31a/-/preview/500x500/-/quality/smart_retina/-/format/auto/';
  const publishingParams: SocialMediaPublishingParams = {
    caption: 'This is a test caption - Burj Khalifa!',
    title: 'Welcome to Dubai',
    idSocialMediaAccount: 123,
    publishedURL: null,
    publishErrorMessage: null,
    publishId: null,
    state: PostState.DRAFT,
    graphicsMeta: {
      '1': {
        isVideo: false,
        graphicURL: imgUrlFacebook,
        name: 'DesignName1',
      },
      '2': {
        isVideo: false,
        graphicURL: imgUrlFacebook,
        name: 'DesignName1',
      },
      '3': {
        isVideo: false,
        graphicURL: imgUrlFacebook,
        name: 'DesignName1',
      },
      '4': {
        isVideo: false,
        graphicURL: imgUrlFacebook,
        name: 'DesignName1',
      },
    },
    isOriginalDeleted: false,
  };

  return publishingParams;
};

export const getTestPublishingParamsForInstagram = (): SocialMediaPublishingParams => {
  const imgUrlInstagram = 'https://ucarecdn.com/a380de20-3a80-45c9-b338-52d0ecaaf31a/-/preview/500x500/-/quality/smart_retina/-/format/auto/';
  const publishingParams: SocialMediaPublishingParams = {
    caption: 'This is a test caption - purple cityscape ',
    idSocialMediaAccount: 123,
    publishedURL: null,
    publishErrorMessage: null,
    publishId: null,
    state: PostState.DRAFT,
    graphicsMeta: {
      '1': {
        isVideo: false,
        graphicURL: imgUrlInstagram,
        name: 'DesignName1',
      },
      '2': {
        isVideo: false,
        graphicURL: imgUrlInstagram,
        name: 'DesignName2',
      },
      '3': {
        isVideo: false,
        graphicURL: imgUrlInstagram,
        name: 'DesignName2',
      },
      '4': {
        isVideo: false,
        graphicURL: imgUrlInstagram,
        name: 'DesignName3',
      },
    },
    isOriginalDeleted: false,
  };

  return publishingParams as SocialMediaPublishingParams;
};

const getInstagramProfileThumbnailByMediaType = (platformMediaType: PlatformMediaType): string => {
  if (platformMediaType === PlatformMediaType.STORIES) {
    return repoURL('assets/instagram-stories-logo-thumb-circle.png', getReadBucket());
  }

  return repoURL('assets/instagram-logo-thumb-circle.png', getReadBucket());
};

const getFacebookProfileThumbnailByMediaType = (platformMediaType: PlatformMediaType): string => {
  if (platformMediaType === PlatformMediaType.STORIES) {
    return repoURL('assets/facebook-stories-logo-thumb-circle.png', getReadBucket());
  }

  return repoURL('assets/facebook-logo-thumb-circle.png', getReadBucket());
};

export const getCircularSocialIconURLByType = (socialType: SocialPlatform, platformMediaType: PlatformMediaType): string => {
  switch (socialType) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_GROUP:
      return getFacebookProfileThumbnailByMediaType(platformMediaType);
    case SocialPlatform.TWITTER:
      return repoURL('assets/x-logo-thumb-circle.png', getReadBucket());
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      return repoURL('assets/linkedin-logo-thumb-circle.png', getReadBucket());
    case SocialPlatform.TIKTOK:
      return repoURL('assets/tiktok-logo-thumb-circle.png', getReadBucket());
    case SocialPlatform.YOUTUBE:
      return repoURL('assets/youtube-thumb-logo-icon.png', getReadBucket());
    case SocialPlatform.INSTAGRAM:
      return getInstagramProfileThumbnailByMediaType(platformMediaType);
    case SocialPlatform.PINTEREST:
      return repoURL('assets/pinterest-logo-thumb-circle.png', getReadBucket());
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return repoURL('assets/google-business-profile-logo-thumb-circle.png', getReadBucket());
    case SocialPlatform.THREADS:
      return repoURL('assets/threads-logo-thumb-circle-v3.png', getReadBucket());
    default:
      return '';
  }
};

export const makeSocialMediaResizeAjaxCall = async (ajaxResizeParams: SocialMediaResizeAjaxParams, isVideo: boolean | undefined): Promise<string> => {
  const resizeResponse: SocialMediaResizeResponse = (await window.PMW.writeLocal('socialmedia/getResizedDesign', ajaxResizeParams)) as SocialMediaResizeResponse;

  if (!resizeResponse) {
    throw new Error('Unable to resize');
  }

  const {s3FilePath} = resizeResponse;

  if (s3FilePath) {
    return s3FilePath;
  }

  if (!isVideo || !resizeResponse.videoS3FileName) {
    throw new Error('Unable to resize');
  }

  const pollingData = (await window.PMW.pollingAjaxCallAsync(
    async () => {},
    () => {
      return window.PMW.readLocal('socialmedia/checkVideoResizeStatus', {
        s3FileName: resizeResponse.videoS3FileName,
        posterHashedId: ajaxResizeParams.posterId,
        idPost: ajaxResizeParams.idPost,
        socialAccountId: ajaxResizeParams.socialAccountId,
        platformMediaType: ajaxResizeParams.platformMediaType,
      });
    },
    VIDEO_RESIZE_POLLING_TIMEOUT
  )) as string;

  if (pollingData) {
    return pollingData;
  }

  return '';
};

export const addConnectAccountBroadcastChannel = (onSuccessMessageReceived: (eventData: MessageEvent) => void): BroadcastChannel | undefined => {
  const accountCreatedBroadcastName = getConnectAccountBroadcastName();

  if (!accountCreatedBroadcastName) {
    return;
  }

  const accountCreatedBroadcastChannel = new BroadcastChannel(accountCreatedBroadcastName);
  accountCreatedBroadcastChannel.addEventListener('message', onSuccessMessageReceived);

  return accountCreatedBroadcastChannel;
};

export const removeConnectAccountBroadcastChannel = (accountCreatedBroadcastChannel?: BroadcastChannel): void => {
  if (!accountCreatedBroadcastChannel) {
    return;
  }

  accountCreatedBroadcastChannel.close();
};

export const getConnectAccountBroadcastName = (): string => {
  const hashedUserId = getUserHashedId();
  if (hashedUserId) {
    return hashedUserId;
  }

  return '';
};

export const getDefaultPublishParamsForMediaType = (account: SocialAccount, publishingParams: SocialMediaPublishingParams, isInstagramReel: boolean): MediaTypePublishingParams => {
  if (account.type === SocialPlatform.INSTAGRAM && isInstagramReel) {
    return {
      [PlatformMediaType.REELS]: publishingParams,
      [PlatformMediaType.POST]: null,
      [PlatformMediaType.STORIES]: null,
    };
  }

  return {
    [PlatformMediaType.POST]: publishingParams,
    [PlatformMediaType.REELS]: null,
    [PlatformMediaType.STORIES]: null,
  };
};

export const getNonStoryPublishingParams = (platformMediaTypeParams: MediaTypePublishingParams): SocialMediaPublishingParams | null => {
  if (platformMediaTypeParams.REELS) {
    return platformMediaTypeParams.REELS;
  }

  return platformMediaTypeParams.POST || null;
};

export const getStoryPublishingParams = (platformMediaTypeParams: MediaTypePublishingParams): SocialMediaPublishingParams | null => {
  return platformMediaTypeParams.STORIES;
};

export const getNonStoryPlatformMediaType = (platformMediaTypeParams: MediaTypePublishingParams): PlatformMediaType | null => {
  if (platformMediaTypeParams.REELS) {
    return PlatformMediaType.REELS;
  }

  if (!platformMediaTypeParams.POST) {
    return null;
  }

  return PlatformMediaType.POST;
};

export const seekAndPauseAutoplayVideo = (e: React.SyntheticEvent<HTMLVideoElement>, seekTimestamp: number): void => {
  const video = e.target as HTMLVideoElement;
  video.pause();
  video.currentTime = seekTimestamp;
};

export const getUserHasSeenCacheKey = (cacheKeyPrefix: string): string => {
  return cacheKeyPrefix + (window.PMW.getUserId() as string);
};

export const setUserHasSeenCache = (cacheKeyPrefix: string, value: number): void => {
  window.PMW.setCacheItem(getUserHasSeenCacheKey(cacheKeyPrefix), value);
};

export const getUserHasSeenCache = (cacheKeyPrefix: string): boolean | null => {
  const cachedValue = window.PMW.getCacheItem(getUserHasSeenCacheKey(cacheKeyPrefix)) as string | null;

  if (cachedValue === null) {
    return null;
  }

  return parseInt(cachedValue, 10) === 1;
};

export const hasUserSeenUploadMediaBanner = async (): Promise<boolean> => {
  const cachedValue = getUserHasSeenCache(UPLOAD_MEDIA_BANNER_SEEN_CACHE_PREFIX);

  if (cachedValue !== null) {
    return cachedValue;
  }

  if (!window.PMW.isUserLoggedIn()) {
    return false;
  }

  try {
    const data = (await window.PMW.readLocal('socialmedia/getHasUserSeenUploadMediaIntroBanner')) as UserHasSeenResponse;
    data.hasSeen = !isNumber(data.hasSeen) ? parseInt(data.hasSeen, 10) : data.hasSeen;

    setUserHasSeenCache(UPLOAD_MEDIA_BANNER_SEEN_CACHE_PREFIX, data.hasSeen);
    return data.hasSeen === 1;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const markUserHasSeenUploadMediaBanner = async (): Promise<void> => {
  try {
    await window.PMW.writeLocal('socialmedia/updateUserHasSeenUploadMediaIntroBanner');
    setUserHasSeenCache(UPLOAD_MEDIA_BANNER_SEEN_CACHE_PREFIX, 1);
  } catch (error) {
    console.error(error);
  }
};

export const hasUserSeenSMPostCoverTooltip = async (): Promise<boolean> => {
  const cachedValue = getUserHasSeenCache(SM_POST_COVER_TOOLTIP_SEEN_CACHE_PREFIX);

  if (cachedValue !== null) {
    return cachedValue;
  }

  if (!window.PMW.isUserLoggedIn()) {
    return false;
  }

  try {
    const data = (await window.PMW.readLocal('socialmedia/getHasUserSeenSMPostCoverTooltip')) as UserHasSeenResponse;
    data.hasSeen = !isNumber(data.hasSeen) ? parseInt(data.hasSeen, 10) : data.hasSeen;

    setUserHasSeenCache(SM_POST_COVER_TOOLTIP_SEEN_CACHE_PREFIX, data.hasSeen);
    return data.hasSeen === 1;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const markUserHasSeenSMPostCoverTooltip = async (): Promise<void> => {
  try {
    await window.PMW.writeLocal('socialmedia/updateUserHasSeenSMPostCoverTooltip');
    setUserHasSeenCache(SM_POST_COVER_TOOLTIP_SEEN_CACHE_PREFIX, 1);
  } catch (error) {
    console.error(error);
  }
};

export const getAccountConnectionResponseFromBroadcast = (broadcastEvent: MessageEvent): SocialAccountConnectionResponse | null => {
  const connectionResponse: SocialAccountConnectionResponse = broadcastEvent.data as SocialAccountConnectionResponse;

  if (!connectionResponse) {
    return null;
  }

  return connectionResponse;
};

export const handleAccountConnectionResponse = (
  connectionResponse: SocialAccountConnectionResponse | null,
  onSuccess: VoidFunction = noop,
  onNothingHappened: VoidFunction = noop,
  onFailure: VoidFunction = noop
): void => {
  if (!connectionResponse) {
    return;
  }

  if (connectionResponse.hideExistingModal) {
    closeSocialAccountConnectionResponseModal();
  }

  if (connectionResponse.socialAccountConnectionResult === SocialAccountConnectionResult.NOTHING_HAPPENED) {
    onNothingHappened();
    return;
  }

  if (connectionResponse.socialAccountConnectionResult === SocialAccountConnectionResult.ACCOUNT_ADDED_SUCCESS) {
    onSuccess();
    return;
  }

  onFailure();
  openSocialAccountConnectionResponseModal({connectionResponse: connectionResponse});
};
