import type * as Fabric from '@postermywall/fabricjs-2';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {AddOnData, Cell, VariationData} from '@PosterWhiteboard/items/layouts/cells/cell';
import {getIconSvg} from '@PosterWhiteboard/items/menu-item/menu-icons';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {rgbToHexString} from '@Utils/color.util';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {BOLD_STROKE_WIDTH_FACTOR, DEFAULT_SPACING_BETWEEN_ITEMS, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {Group, IText, Textbox} from '@postermywall/fabricjs-2';
import {Layout} from './layout';

const MIN_WIDTH_FOR_DESCRIPTION = 250;

export class MenuLayout5 extends Layout {
  public layoutType: LayoutTypes.MENU_LAYOUT_5 = LayoutTypes.MENU_LAYOUT_5;
  declare item: MenuItem;

  public setStylesForHighlightedItems(): void {
    throw new Error('Method not implemented.');
  }

  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups();
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.makePriceRightAlign();
  }

  async setItems(): Promise<void> {
    let i;

    const textObjectCaching = !this.item.page.poster.isHighRes;
    const columnMap = this.item.getColumnMap();
    const horizontalGroups: Fabric.Group[] = [];
    const rows = this.item.getNoOfRows();

    for (let a = 0; a < rows; a++) {
      const priceGroup = new Group([], {
        // @ts-expect-error custom pmw property
        itemType: CellType.PRICE,
        objectCaching: false,
      });
      const nameAndIconsGroup = new Group([], {
        // @ts-expect-error custom pmw property
        itemType: CellType.NAME,
        objectCaching: false,
      });
      const finalGroup = new Group([], {objectCaching: false});
      const iconsGroup = new Group([], {
        // @ts-expect-error custom pmw property
        itemType: CellType.ICONS,
        objectCaching: false,
      });
      const addOnsGroup = new Group([], {
        // @ts-expect-error custom pmw property
        itemType: CellType.ADDON,
        objectCaching: false,
      });
      for (const [cellType, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        let t = cells[a].getValue() as string;

        switch (cellType) {
          case CellType.NAME:
            nameAndIconsGroup.insertAt(
              nameAndIconsGroup.getObjects().length,
              new IText(t, {
                editable: false,
                itemType: cellType,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.DESCRIPTION:
            /*
             * At highres, we don't let the Textbox determine text wrapping. Instead, we specify wrapping ourselves that matches
             * what the user saw in their browser when the poster was saved
             */
            if (t.length > 0) {
              if (this.item.page.poster.isHighRes) {
                t = this.getWrapping(this.item, a, t);
              }
              finalGroup.insertAt(
                finalGroup.getObjects().length,
                new Textbox(t, {
                  editable: false,
                  itemType: cellType,
                  objectCaching: textObjectCaching,
                })
              );
            }
            break;

          case CellType.PRICE:
            priceGroup.insertAt(
              priceGroup.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.ICONS:
            for (let z = 0; z < t.length; z++) {
               
              const icon = await getIconSvg(t[z]);

              if (icon) {
                const scale = this.getScaleForMenuIcon(icon, this.item.iconsSize);
                icon.set({
                  scaleX: scale,
                  scaleY: scale,
                });
                nameAndIconsGroup.insertAt(nameAndIconsGroup.getObjects().length, icon);
              }
            }
            break;

          case CellType.VARIATION: {
            const cellVal = cells[a].getValue() as VariationData[];
            if (cellVal.length > 0) {
              priceGroup.remove(priceGroup.getObjects()[priceGroup.getObjects().length - 1]);
              for (i = 0; i < cellVal.length; i++) {
                priceGroup.insertAt(
                  priceGroup.getObjects().length,
                  new IText(`${cellVal[i].name} ${cellVal[i].price}`, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
              }
            }
            break;
          }

          case CellType.ADDON: {
            const cellVal = cells[a].getValue() as AddOnData[];
            if (cellVal.length > 0) {
              for (i = 0; i < cellVal.length; i++) {
                addOnsGroup.add(
                  new IText(`${cellVal[i].name} ${cellVal[i].price}`, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
              }
            }
            break;
          }

          default:
            break;
        }
      }
      // add all items in order
      finalGroup.insertAt(0, nameAndIconsGroup);
      finalGroup.add(priceGroup);

      if (addOnsGroup.getObjects().length > 0) {
        const obs = finalGroup.getObjects();

        // @ts-expect-error custom pmw property
        if (obs[1].itemType && obs[1].itemType === CellType.DESCRIPTION) {
          finalGroup.insertAt(2, addOnsGroup);
        } else {
          finalGroup.insertAt(1, addOnsGroup);
        }
      }

      if (iconsGroup.getObjects().length > 0) {
        finalGroup.add(iconsGroup);
      }

      // add final group to the this.item.fabricObject
      horizontalGroups.push(finalGroup);
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(horizontalGroups);
  }

  /**
   * Sets the this.item.fabricObject styles specific to this menu layout
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Fabric.Group[];
    const strokeWidth = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeWidthForFF1 = 0;
    let strokeWidthForFF2;
    let strokeColor = rgbToHexString(this.item.textStyles.fill.fillColor[0], 1);
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidthForFF1 = !isBoldAppliedOnFirstFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize + strokeWidth : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 = !isBoldAppliedOnSecondFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeWidthForFF1 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeColor = rgbToHexString(this.item.textStyles.strokeColor, 1);
    }
    for (let i = 0; i < groups.length; i++) {
      const groupItems = groups[i].getObjects() as Fabric.Group[];
      let itemName = null;
      let itemDescription = null;
      let priceGroup = null;
      let addOnsGroup = null;

      for (let b = 0; b < groupItems.length; b++) {
        // @ts-expect-error custom pmw property
        switch (groupItems[b].itemType) {
          case CellType.NAME: {
            [itemName] = groupItems[b].getObjects();
            itemName.set({
              fontSize: this.item.textStyles.fontSize * 1.1,
              fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
              strokeWidth: strokeWidthForFF1 ? strokeWidthForFF1 * 1.1 : strokeWidth,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: strokeColor,
              underline: this.item.textStyles.underLine,
              linethrough: this.item.textStyles.lineThrough,
            });
            const icons = groupItems[b].getObjects();
            for (let j = 1; j < icons.length; j++) {
              icons[j].set({
                fill: rgbToHexString(this.item.iconsColor, this.item.alpha),
              });
            }
            this.verticallyStackItems(groupItems[b], DEFAULT_SPACING_BETWEEN_ITEMS, 0);
            this.verticallyCenterItems(groupItems[b]);
            break;
          }
          case CellType.DESCRIPTION:
            itemDescription = groupItems[b];
            itemDescription.set({
              width: MIN_WIDTH_FOR_DESCRIPTION + this.item.xSpacing,
              fontFamily: `'${fontFamily2WithVariation}'`,
              fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
              underline: this.item.underLine2,
              linethrough: this.item.lineThrough2,
            });
            if (strokeWidthForFF2) {
              itemDescription.set({
                strokeWidth: strokeWidthForFF2,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
              });
            }
            break;
          case CellType.PRICE:
            priceGroup = groupItems[b];
            priceGroup.getObjects().forEach((obj) => {
              obj.set({
                fontSize: this.item.textStyles.fontSize * 1.5,
                fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
                strokeWidth: strokeWidthForFF1 ? strokeWidthForFF1 * 1.5 : strokeWidth,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
                underline: this.item.textStyles.underLine,
                linethrough: this.item.textStyles.lineThrough,
              });
            });
            this.verticallyStackItems(priceGroup, DEFAULT_SPACING_BETWEEN_ITEMS * 3, 0);
            this.verticallyCenterItems(priceGroup);
            break;
          case CellType.ADDON:
            addOnsGroup = groupItems[b];
            addOnsGroup.getObjects().forEach((obj) => {
              obj.set({
                fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
                underline: this.item.textStyles.underLine,
                linethrough: this.item.textStyles.lineThrough,
              });
              if (strokeWidthForFF1) {
                obj.set({
                  strokeWidth: strokeWidthForFF1,
                  strokeLineJoin: 'round',
                  paintFirst,
                  stroke: strokeColor,
                });
              }
            });
            this.verticallyStackItems(addOnsGroup, DEFAULT_SPACING_BETWEEN_ITEMS * 3, 0);
            this.verticallyCenterItems(addOnsGroup);
            break;
          default:
        }
      }
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   */
  layoutItemsInsideGroups(): void {
    const groups = this.item.fabricObject.getObjects() as Fabric.Group[];
    for (let i = 0; i < groups.length; i++) {
      this.horizontallyStackItems(groups[i], DEFAULT_SPACING_BETWEEN_ITEMS, 0);
    }
  }

  getWrappingInfo(): string[][] {
    const menuItems = this.item.fabricObject.getObjects() as Fabric.Group[];
    const wrappingData = [];

    for (let i = 0; i < menuItems.length; i++) {
      const items = menuItems[i].getObjects();
      if (items[1] instanceof Textbox) {
        wrappingData.push(items[1].textLines);
      } else {
        wrappingData.push('');
      }
    }

    return wrappingData as string[][];
  }

  makePriceRightAlign(): void {
    const groups = this.item.fabricObject.getObjects() as Fabric.Group[];
    for (let i = 0; i < groups.length; i++) {
      const item = groups[i].getObjects()[groups[i].getObjects().length - 1];
      item.set({
        left: item.left + this.item.fabricObject.width - item.width - this.edgePadding * 2,
      });
    }
  }
}
