import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice, createSelector} from '@reduxjs/toolkit';
import {isObjectEmpty} from '@Utils/object.util';
import type {RootState} from '@/store';
import type {ListItemStorage, SelectableListItems} from './list.types';
import {LIST_ITEM_TYPE} from './list.types';
import type {ListItemType1Storage} from './components/list-item-type-1';
import type {ListItemType2Storage} from './components/list-item-type-2';
import type {ListItemType3Storage} from './components/list-item-type-3';

interface State {
  listData: ListData;
  listItemData: ListItemData;
}

const initialState: State = {
  listData: {
    listHashMap: {},
  },
  listItemData: {
    listItemsHashMap: {},
  },
};

interface ListData {
  listHashMap: Record<string, ListStorage>;
}

interface ListItemData {
  listItemsHashMap: Record<string, ListItemStorage>;
}

interface ListStorage {
  keepAtleastOneItemSelected?: boolean;
  multiSelect: boolean;
  draggable: boolean;
  listItemIds: Array<string>;
}

export interface InitializeListActionProps {
  listId: string;
  draggable: boolean;
  listItemIds: Array<string>;
  multiSelect: boolean;
  keepAtleastOneItemSelected: boolean;
}

export interface InitializeListItemsActionProps {
  data: Array<ListItemStorage>;
}

export interface UpdateListsStateActionProps {
  listId: string;
  draggable: boolean;
  listItemsIds: Array<string>;
  multiSelect: boolean;
  keepAtleastOneItemSelected: boolean;
  listItems: Array<ListItemStorage>;
  keepOldSelectionState?: boolean;
  keepExitingItems?: boolean;
}

export interface ListItemIdsActionProps {
  listId: string;
  itemIds: Array<string>;
}

export interface ToggleListItemActionProps {
  listId: string;
  itemId: string;
}

export interface AddListItemsActionProps {
  listId: string;
  listItems: Array<ListItemStorage>;
  addInEnd?: boolean;
}

export interface ReplaceListItemsActionProps {
  listId: string;
  idToReplace: string;
  idToReplaceWith: string;
}

export interface AddItemIfItDoesntExistActionProps {
  itemId: string;
  object: ListItemStorage;
}

export interface UpdateListItemActionProps {
  listItemId: string;
  changes: Record<string, any>;
}

export interface UpdateItemSelection {
  listId: string;
  id: string;
}

export interface UpdateSubItemSelection {
  id: string;
  listItemId: string;
}

export interface ToggleLoadingStateActionProps {
  listId: string;
}

export interface ReorderListItemActionProps {
  listId: string;
  sourceIndex: number;
  destinationIndex: number;
}

export interface ClearListActionProps {
  id: string;
}

export interface UnselectedListItemsActionProps {
  listIds: Array<string>;
}

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    initializeListState: (state, action: PayloadAction<InitializeListActionProps>) => {
      if (state.listData.listHashMap[action.payload.listId] !== undefined) {
        return;
      }
      state.listData.listHashMap[action.payload.listId] = action.payload;
    },
    clearList: (state, action: PayloadAction<ClearListActionProps>) => {
      if (state.listData.listHashMap[action.payload.id] === undefined) {
        return;
      }
      if ('listItemIds' in state.listData.listHashMap[action.payload.id]) {
        const listItemsForList = state.listData.listHashMap[action.payload.id].listItemIds;
        removeListItemsForList(state, action.payload.id, listItemsForList);
        delete state.listData.listHashMap[action.payload.id];
      }
    },
    initializeListItemsState: (state, action: PayloadAction<InitializeListItemsActionProps>) => {
      const listItems = action.payload.data;
      if (!listItems) {
        return;
      }
      for (let a = 0; a < listItems.length; a++) {
        if (state.listItemData.listItemsHashMap[listItems[a].id] !== undefined) {
          return;
        }
        state.listItemData.listItemsHashMap[listItems[a].id] = listItems[a];
      }
    },
    updateListItemState: (state, action: PayloadAction<UpdateListItemActionProps>) => {
      const listItem = state.listItemData.listItemsHashMap[action.payload.listItemId];
      if (listItem) {
        state.listItemData.listItemsHashMap[action.payload.listItemId] = {
          ...listItem,
          ...action.payload.changes,
        };
      }
    },
    updateListItemSelectionState: (state, action: PayloadAction<UpdateItemSelection>) => {
      const listData = state.listData.listHashMap[action.payload.listId];

      if (listData.multiSelect) {
        toggleListItemSelectionState(state, action.payload.listId, action.payload.id);
      } else {
        toggleListItemSelectionWithSingleSelect(state, action.payload.listId, action.payload.id);
      }
    },
    updateListSubItemSelectedState: (state, action: PayloadAction<UpdateSubItemSelection>) => {
      const listItem = state.listItemData.listItemsHashMap[action.payload.listItemId];
      if (!listItem) {
        return;
      }

      switch (listItem.type) {
        case LIST_ITEM_TYPE.DEFAULT_2:
          toggleListSubItemSelectionState(state, action.payload.listItemId, action.payload.id);
          return;
        case LIST_ITEM_TYPE.DEFAULT_3:
          toggleListSubItemSelectionState(state, action.payload.listItemId, action.payload.id, true);
      }
    },
    toggleLoadingStateForListItems: (state, action: PayloadAction<ToggleLoadingStateActionProps>) => {
      if (!doesListExistInStore(state, action.payload.listId)) {
        return;
      }

      const {listItemIds} = state.listData.listHashMap[action.payload.listId];
      if (!listItemIds) {
        return;
      }

      toggleListItemsLoadingState(state, listItemIds);
    },
    reorderListItems: (state, action: PayloadAction<ReorderListItemActionProps>) => {
      const list: Array<string> = state.listData.listHashMap[action.payload.listId].listItemIds;
      const sourceId = list[action.payload.sourceIndex];
      list.splice(action.payload.sourceIndex, 1);
      list.splice(action.payload.destinationIndex, 0, sourceId);
      state.listData.listHashMap[action.payload.listId] = {
        ...state.listData.listHashMap[action.payload.listId],
        listItemIds: list,
      };
    },
    unSelectedItemsForMultipleLists: (state, action: PayloadAction<UnselectedListItemsActionProps>) => {
      unSelectItemsInLists(state, action.payload.listIds);
    },
    updateListsState: (state, action: PayloadAction<Array<UpdateListsStateActionProps>>) => {
      action.payload.map((list) => {
        state.listData.listHashMap[list.listId] = {
          listId: list.listId,
          draggable: list.draggable,
          listItemIds: list.keepExitingItems ? [...state.listData.listHashMap[list.listId].listItemIds, ...list.listItemsIds] : list.listItemsIds,
          multiSelect: list.multiSelect,
          keepAtleastOneItemSelected: list.keepAtleastOneItemSelected,
        } as ListStorage;

        list.listItems.map((item) => {
          let listItem = state.listItemData.listItemsHashMap[item.id];
          if (listItem) {
            listItem = {
              ...item,
              isSelected: list.keepOldSelectionState ? listItem.isSelected : item.isSelected,
            };
          }
          state.listItemData.listItemsHashMap[item.id] = listItem ?? item;
        });
      });
    },
    updateListItemsSelectionState: (state, action: PayloadAction<ListItemIdsActionProps>) => {
      const listItems = state.listData.listHashMap[action.payload.listId].listItemIds;
      listItems.map((itemId) => {
        const listItem = state.listItemData.listItemsHashMap[itemId];
        const isSelected = action.payload.itemIds.includes(itemId);
        state.listItemData.listItemsHashMap[itemId] = {
          ...listItem,
          isSelected,
        };
      });
    },
    toggleListItem: (state, action: PayloadAction<ToggleListItemActionProps>) => {
      const listItems = state.listData.listHashMap[action.payload.listId].listItemIds;
      if (listItems.includes(action.payload.itemId)) {
        state.listData.listHashMap[action.payload.listId].listItemIds = listItems.filter((id) => {
          return id !== action.payload.itemId;
        });
      } else {
        listItems.push(action.payload.itemId);
      }
    },
    updateListItems: (state, action: PayloadAction<ListItemIdsActionProps>) => {
      state.listData.listHashMap[action.payload.listId].listItemIds = action.payload.itemIds;
    },
    addListItems: (state, action: PayloadAction<AddListItemsActionProps>) => {
      action.payload.listItems.forEach((listItem) => {
        const {listItemIds} = state.listData.listHashMap[action.payload.listId];
        let updatedListItemIds: Array<string> = [];
        if (action.payload.addInEnd) {
          updatedListItemIds = [...listItemIds, listItem.id];
        } else {
          updatedListItemIds = [listItem.id, ...listItemIds];
        }
        state.listData.listHashMap[action.payload.listId].listItemIds = Array.from(new Set(updatedListItemIds));
        state.listItemData.listItemsHashMap[listItem.id] = listItem;
      });
    },
    removeListItems: (state, action: PayloadAction<ListItemIdsActionProps>) => {
      removeListItemsForList(state, action.payload.listId, action.payload.itemIds);
    },
    updateMultipleListItemsData: (state, action: PayloadAction<Array<ListItemStorage>>) => {
      action.payload.forEach((item) => {
        const listItem = state.listItemData.listItemsHashMap[item.id];
        state.listItemData.listItemsHashMap[item.id] = {
          ...item,
          isSelected: listItem.isSelected,
        };
      });
    },
    replaceListItemsInAList: (state, action: PayloadAction<ReplaceListItemsActionProps>) => {
      if (!doesListExistInStore(state, action.payload.listId)) {
        return;
      }
      if (!state.listItemData.listItemsHashMap[action.payload.idToReplace] || !state.listItemData.listItemsHashMap[action.payload.idToReplaceWith]) {
        return;
      }

      const listItems = state.listData.listHashMap[action.payload.listId].listItemIds;
      const index = listItems.indexOf(action.payload.idToReplace);
      if (index === -1) {
        return;
      }

      listItems.splice(index, 1, action.payload.idToReplaceWith);
      state.listData.listHashMap[action.payload.listId].listItemIds = listItems;
    },
    addListItemIfItDoesntExists: (state, action: PayloadAction<AddItemIfItDoesntExistActionProps>) => {
      if (state.listItemData.listItemsHashMap[action.payload.itemId]) {
        return;
      }

      state.listItemData.listItemsHashMap[action.payload.itemId] = {
        ...action.payload.object,
      };
    },
  },
});

const removeListItemsForList = (state: State, listID: string, listItemIDs: Array<string>) => {
  const {listItemIds} = state.listData.listHashMap[listID];
  state.listData.listHashMap[listID].listItemIds = listItemIds.filter((itemId) => {
    return !listItemIDs.includes(itemId);
  });
  listItemIDs.map((itemId) => {
    delete state.listItemData.listItemsHashMap[itemId];
  });
};

export const getListItemsForListId = (state: State, listId: string) => {
  const listItemIds = state.listData.listHashMap[listId]?.listItemIds;
  const listItemHash: Record<string, ListItemStorage> = {};
  if (listItemIds) {
    listItemIds.map((itemId) => {
      listItemHash[itemId] = state.listItemData.listItemsHashMap[itemId];
    });
  }
  return listItemHash;
};

export const getListItemsId = createSelector(
  [
    (state: RootState) => {
      return state.list;
    },
    (state: RootState, listId: string) => {
      return listId;
    },
  ],
  (list, listId) => {
    if (doesListExistInStore(list, listId)) {
      return list.listData.listHashMap[listId].listItemIds;
    }
    return [];
  }
);

export const getSelectedListItemIds = createSelector(
  [
    (state: RootState) => {
      return state.list;
    },
    (state: RootState, listId: string) => {
      return listId;
    },
  ],
  (list, listId) => {
    if (!doesListExistInStore(list, listId)) {
      return [];
    }
    return getIdsOfSelectedListItems(list, listId);
  }
);

const unSelectItemsInLists = (state: State, listIds: Array<string>) => {
  for (let index = 0; index < listIds.length; index++) {
    unSelectListItemsForList(state, listIds[index]);
  }
};

export const unSelectListItemsForList = (state: State, listId: string) => {
  if (doesListExistInStore(state, listId)) {
    const {listItemIds} = state.listData.listHashMap[listId];
    for (let index = 0; index < listItemIds.length; index++) {
      state.listItemData.listItemsHashMap[listItemIds[index]] = {
        ...state.listItemData.listItemsHashMap[listItemIds[index]],
        isSelected: false,
      };
    }
  }
};

export const doesListExistInStore = (state: State, listId: string) => {
  return state.listData.listHashMap[listId] !== undefined;
};

const isListItemSelected = (state: State, listItemId: string) => {
  const listItem = state.listItemData.listItemsHashMap[listItemId];
  if (listItem.type === LIST_ITEM_TYPE.HEADING) {
    return false;
  }

  return !!listItem.isSelected;
};

const areSubItemsPresent = (state: State, listItemId: string) => {
  const listItem = state.listItemData.listItemsHashMap[listItemId] as ListItemType2Storage | ListItemType3Storage;
  if (!listItem) {
    return false;
  }
  return !!listItem.subItems;
};

const toggleListItemSelectionState = (state: State, listId: string, listItemId: string) => {
  const listItemData = state.listItemData.listItemsHashMap[listItemId] as SelectableListItems;
  if (!listItemData) {
    return;
  }

  if (isListItemSelected(state, listItemId)) {
    if (state.listData.listHashMap[listId].keepAtleastOneItemSelected && getNumberOfSelectedListItems(state, listId) === 1) {
      return;
    }
  }

  state.listItemData.listItemsHashMap[listItemId] = {
    ...listItemData,
    isSelected: !listItemData.isSelected,
  };
};

const toggleListItemSelectionWithSingleSelect = (state: State, listId: string, listItemId: string) => {
  const {listItemIds} = state.listData.listHashMap[listId];

  toggleListItemSelectionState(state, listId, listItemId);
  for (let index = 0; index < listItemIds.length; index++) {
    if (listItemIds[index] !== listItemId && isListItemSelected(state, listItemIds[index])) {
      toggleListItemSelectionState(state, listId, listItemIds[index]);
    }
  }
};

const toggleListSubItemSelectionState = (state: State, listItemId: string, listSubItemId: string, isType3 = false) => {
  const listItem = state.listItemData.listItemsHashMap[listItemId] as ListItemType2Storage | ListItemType3Storage;
  if (!listItem.subItems) {
    return;
  }
  for (let a = 0; a < listItem.subItems.length; a++) {
    if (listItem.subItems[a].id === listSubItemId) {
      listItem.subItems[a] = {
        ...listItem.subItems[a],
        isSelected: !listItem.subItems[a].isSelected,
      };
    } else {
      if (!isType3) {
        continue;
      }
      listItem.subItems[a] = {
        ...listItem.subItems[a],
        isSelected: false,
      };
    }
  }
};

const toggleListItemsLoadingState = (state: State, listItemIds: Array<string>) => {
  const listItemHashmap = state.listItemData.listItemsHashMap;
  for (let a = 0; a < listItemIds.length; a++) {
    let listItem = listItemHashmap[listItemIds[a]] as ListItemType1Storage;
    listItem = {
      ...listItem,
      loading: !listItem.loading,
    };
  }
};

export const getNumberOfSelectedListItems = (state: State, listId: string) => {
  if (!doesListExistInStore(state, listId)) {
    return 0;
  }

  const listItems: Array<string> = state.listData.listHashMap[listId].listItemIds;
  let numberOfSelectedListItems = 0;
  for (let index = 0; index < listItems.length; index++) {
    if (isListItemSelected(state, listItems[index])) {
      numberOfSelectedListItems += 1;
    }
  }
  return numberOfSelectedListItems;
};

export const getIdsOfSelectedListItems = (state: State, listId: string) => {
  const selectedListItemIds: Array<string> = [];
  if (!doesListExistInStore(state, listId)) {
    return selectedListItemIds;
  }
  const {listItemIds} = state.listData.listHashMap[listId];
  const listItemHashmap = state.listItemData.listItemsHashMap;

  if (!listItemHashmap) {
    return;
  }

  for (let a = 0; a < listItemIds.length; a++) {
    const listItem = listItemHashmap[listItemIds[a]];
    if (isObjectEmpty(listItem)) {
      continue;
    }
    if (isListItemSelected(state, listItemIds[a])) {
      selectedListItemIds.push(listItem.id);
    }
  }
  return selectedListItemIds;
};

export const getDataForSelectedItems = (state: State, listID: string) => {
  const selectedIDs = getIdsOfSelectedListItems(state, listID);
  const listItemHashmap = state.listItemData.listItemsHashMap;

  const items: Record<string, ListItemStorage> = {};
  selectedIDs?.forEach((listItemID) => {
    items[listItemID] = listItemHashmap[listItemID];
  });
  return items;
};

export const getTextOfSelectedListItems = (state: State, listId: string) => {
  const selectedListItemIds: Array<string> = [];
  if (!doesListExistInStore(state, listId)) {
    return selectedListItemIds;
  }
  const {listItemIds} = state.listData.listHashMap[listId];
  const listItemHashmap = state.listItemData.listItemsHashMap;

  if (!listItemHashmap) {
    return;
  }

  for (let a = 0; a < listItemIds.length; a++) {
    const listItem = listItemHashmap[listItemIds[a]];
    if (isObjectEmpty(listItem)) {
      continue;
    }
    if (isListItemSelected(state, listItemIds[a])) {
      selectedListItemIds.push(listItem.text);
    }
  }
  return selectedListItemIds;
};

export const getSelectedListItems = (state: State, listId: string) => {
  const selectedListItems: Array<ListItemStorage> = [];
  if (!doesListExistInStore(state, listId)) {
    return selectedListItems;
  }
  const {listItemIds} = state.listData.listHashMap[listId];
  const listItemHashmap = state.listItemData.listItemsHashMap;

  if (!listItemHashmap) {
    return;
  }

  for (let a = 0; a < listItemIds.length; a++) {
    const listItem = listItemHashmap[listItemIds[a]];
    if (isObjectEmpty(listItem)) {
      continue;
    }
    if (isListItemSelected(state, listItemIds[a])) {
      selectedListItems.push(listItem);
    }
  }
  return selectedListItems;
};

export const getIdsOfSelectedListItemsAcrossMultipleLists = (state: State, listIds: Array<string>) => {
  if (listIds.length === 0) {
    return [];
  }

  let selectedListItemIds: Array<string> = [];

  for (let index = 0; index < listIds.length; index++) {
    const selectedListItemIdsForList: Array<string> | undefined = getIdsOfSelectedListItems(state, listIds[index]);
    if (selectedListItemIdsForList) {
      selectedListItemIds = selectedListItemIds.concat(selectedListItemIdsForList);
    }
  }

  return selectedListItemIds;
};

export const getDataOfSelectedListItemsAcrossMultipleLists = (state: State, listIds: Array<string>) => {
  if (listIds.length === 0) {
    return [];
  }

  const selectedListItemData: Record<string, Record<string, string>> = {};
  for (let index = 0; index < listIds.length; index++) {
    const selectedListItemIdsForList: Array<string> | undefined = getIdsOfSelectedListItems(state, listIds[index]);
    if (selectedListItemIdsForList) {
      const listItemAndTextHashmap: Record<string, string> = {};
      for (let i = 0; i < selectedListItemIdsForList.length; i++) {
        listItemAndTextHashmap[selectedListItemIdsForList[i]] = state.listItemData.listItemsHashMap[selectedListItemIdsForList[i]].text;
      }
      selectedListItemData[listIds[index]] = listItemAndTextHashmap;
    }
  }

  return selectedListItemData;
};

export const areSubItemsOfCorrespondingListItemBeingShown = (state: State, listId: string, listItemId: string) => {
  if (!doesListExistInStore(state, listId)) {
    return false;
  }
  const listItemsIds = state.listData.listHashMap[listId].listItemIds;
  for (let index = 0; index < listItemsIds.length; index++) {
    if (listItemsIds[index] === listItemId) {
      continue;
    }
    if (isListItemSelected(state, listItemsIds[index]) && areSubItemsPresent(state, listItemsIds[index])) {
      return true;
    }
  }
  return false;
};

export const {
  initializeListState,
  clearList,
  initializeListItemsState,
  updateListItemSelectionState,
  updateListSubItemSelectedState,
  toggleLoadingStateForListItems,
  reorderListItems,
  updateListItemState,
  unSelectedItemsForMultipleLists,
  updateListsState,
  updateListItemsSelectionState,
  toggleListItem,
  updateListItems,
  addListItems,
  removeListItems,
  updateMultipleListItemsData,
  replaceListItemsInAList,
  addListItemIfItDoesntExists,
} = listSlice.actions;
export const listReducer = listSlice.reducer;
