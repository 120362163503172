import {createSlice} from '@reduxjs/toolkit';
import type {AutoCaptionReduxStateType, InAutoCaptionModeType} from '@Components/social-media/social-media.types';

const initialState: AutoCaptionReduxStateType = {
  inAutoCaptionMode: {accountId: null},
  hasSeenIntroPopup: false,
  hasSeenReminderPopup: false,
};

const autoCaptionSlice = createSlice({
  name: 'AutoCaption',
  initialState,
  reducers: {
    setInAutoCaptionMode: (state, action: {payload: InAutoCaptionModeType}) => {
      state.inAutoCaptionMode = action.payload;
    },
    setHasSeenIntroPopup: (state, action: {payload: boolean}) => {
      state.hasSeenIntroPopup = action.payload;
    },
    setHasSeenReminderPopup: (state, action: {payload: boolean}) => {
      state.hasSeenReminderPopup = action.payload;
    },
  },
});

export const {setInAutoCaptionMode, setHasSeenIntroPopup, setHasSeenReminderPopup} = autoCaptionSlice.actions;

export const autoCaptionReducer = autoCaptionSlice.reducer;
