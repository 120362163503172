import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {RGB} from '@Utils/color.util';
import {hexToRgb, lightenDarkenColor, rgbToRGBString} from '@Utils/color.util';
import type * as Fabric from '@postermywall/fabricjs-2';
import {Gradient} from '@postermywall/fabricjs-2';

export enum FillTypes {
  SOLID = 0,
  LINEAR_GRADIENT = 1,
  RADIAL_GRADIENT = 2,
  NONE = 3,
}

export interface FillObject {
  fillType: FillTypes;
  fillColor: RGB[];
  fillAlpha: number;
}

export const DEFAULT_FILL_COLOR: RGB = [0, 0, 0];

export interface FillOpts {
  getRadialGradientOpts?(fillWidth: number, fillHeight: number, gradientFillColors: RGB[], alpha?: number): Fabric.GradientOptions<'radial'>;

  getLinearGradientOpts?(fillHeight: number, gradientFillColors: RGB[], alpha?: number): Fabric.GradientOptions<'linear'>;
}

export class Fill extends CommonMethods {
  public fillType: FillTypes = FillTypes.SOLID;
  public fillColor: RGB[] = [DEFAULT_FILL_COLOR];

  public fillAlpha = 1;
  public fillOpts: FillOpts = {};

  public constructor(opts: FillOpts = {}) {
    super();
    this.fillOpts = opts;
  }

  public getFill(fillWidth: number, fillHeight: number): Fabric.TFiller | string {
    if (this.hasLinearGradientFill()) {
      const gradientOpts = this.getLinearGradientOpts(fillHeight);
      return new Gradient(gradientOpts);
    }
    if (this.hasRadialGradientFill()) {
      const gradientOpts = this.getRadialGradientOpts(fillWidth, fillHeight);
      return new Gradient(gradientOpts);
    }

    if (!this.hasFill()) {
      return rgbToRGBString(this.fillColor[0], 0);
    }
    return rgbToRGBString(this.fillColor[0], this.fillAlpha);
  }

  public hasFill(): boolean {
    return this.fillType !== FillTypes.NONE;
  }

  public getFillOpacity(): number {
    return this.fillAlpha;
  }

  public hasGradientFill(): boolean {
    return this.hasLinearGradientFill() || this.hasRadialGradientFill();
  }

  public hasLinearGradientFill(): boolean {
    return this.fillType === FillTypes.LINEAR_GRADIENT;
  }

  public hasRadialGradientFill(): boolean {
    return this.fillType === FillTypes.RADIAL_GRADIENT;
  }

  public hasSolidFill(): boolean {
    return this.fillType === FillTypes.SOLID;
  }

  public toObject(): FillObject {
    return {
      fillType: this.fillType,
      fillColor: this.fillColor,
      fillAlpha: this.fillAlpha,
    };
  }

  public getColorForNewType(newType: FillTypes): RGB[] {
    if (newType === FillTypes.NONE) {
      return this.fillColor;
    }

    if (this.hasGradientFill() && (newType === FillTypes.LINEAR_GRADIENT || newType === FillTypes.RADIAL_GRADIENT)) {
      return this.fillColor;
    }

    if (newType === FillTypes.RADIAL_GRADIENT || newType === FillTypes.LINEAR_GRADIENT) {
      return this.getGradientColorsFromPrimaryColor(this.fillColor[0]);
    }

    return [this.fillColor[0]];
  }

  public getGradientColorsFromPrimaryColor(primaryColor: RGB): RGB[] {
    return [primaryColor, hexToRgb(lightenDarkenColor(primaryColor))];
  }

  private getLinearGradientOpts(fillHeight: number): Fabric.GradientOptions<'linear'> {
    if (this.fillOpts.getLinearGradientOpts) {
      return this.fillOpts.getLinearGradientOpts(fillHeight, this.fillColor, this.fillAlpha);
    }

    return getLinearGradientOpts(fillHeight, this.fillColor, this.fillAlpha);
  }

  private getRadialGradientOpts(fillWidth: number, fillHeight: number): Fabric.GradientOptions<'radial'> {
    if (this.fillOpts.getRadialGradientOpts) {
      return this.fillOpts.getRadialGradientOpts(fillWidth, fillHeight, this.fillColor, this.fillAlpha);
    }

    return getRadialGradientOpts(fillWidth, fillHeight, this.fillColor, this.fillAlpha);
  }
}

export const getLinearGradientOpts = (fillHeight: number, gradientFillColors: RGB[], alpha = 1): Fabric.GradientOptions<'linear'> => {
  const x = 0;
  const y = fillHeight * 0.75;

  const colorStops = [];
  for (let i = 0; i < gradientFillColors.length; i++) {
    colorStops.push({
      offset: (i + 1) / gradientFillColors.length,
      color: rgbToRGBString(gradientFillColors[i], alpha),
    });
  }

  return {
    colorStops,
    coords: {
      x1: x,
      y1: -y,
      x2: x,
      y2: y,
    },
    type: 'linear',
  };
};

export const getRadialGradientOpts = (fillWidth: number, fillHeight: number, gradientFillColors: RGB[], alpha = 1): Fabric.GradientOptions<'radial'> => {
  const x = fillWidth;
  const y = fillHeight;
  const midX = fillWidth / 2;
  const midY = fillHeight / 2;
  const maxDimension = Math.max(x, y);

  const colorStops = [];
  for (let i = 0; i < gradientFillColors.length; i++) {
    colorStops.push({
      offset: Math.round(i / gradientFillColors.length),
      color: rgbToRGBString(gradientFillColors[i], alpha),
    });
  }

  return {
    colorStops,
    coords: {
      r1: 0.1 * maxDimension,
      r2: maxDimension,
      x1: midX,
      y1: midY,
      x2: midX,
      y2: midY,
    },
    type: 'radial',
  };
};
