import type {Subtitle} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import {BACKGROUND_PADDING, createSubtitleFromObjectAndAddToTranscript} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {TranscriptGeneratedFrom, TranscriptItemObject, TranscriptUpdateFromObjectOpts} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {MIN_TIME_GAP_FOR_TRANSCRIPT} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {FabricObject, ObjectEvents} from '@postermywall/fabricjs-2';
import {Canvas, FixedLayout, Group, IText, LayoutManager, Point, Rect, Textbox} from '@postermywall/fabricjs-2';
import {getPmwMlControl, getPmwMrControl, type OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {degreesToRadians} from '@Utils/math.util';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR, TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {LetterCase} from '@Utils/string.util';
import {applyLetterCase} from '@Utils/string.util';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {addItemsToGroupWithOriginalScale} from '@Utils/fabric.util';
import {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import type {OverlappingSubtitleItemResizeData} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.reducer';
import {getFontFamilyNameForVariations} from '@Libraries/font-library';
import {rgbToHexString} from '@Utils/color.util';
import type {DeepPartial} from '@/global';
import {noop} from '@Utils/general.util';
import {deleteItemByID} from '@Components/poster-editor/library/poster-editor-library';

const TRANSCRIPT_ITEM_PADDING = 32;
const NEW_TRANSCRIPT_ITEM_OFFSET_FROM_BOTTOM = 150;

export class TranscriptItem extends Item {
  declare fabricObject: Group;
  public gitype = ITEM_TYPE.TRANSCRIPT;
  public width = this.page.poster.width - TRANSCRIPT_ITEM_PADDING - TRANSCRIPT_ITEM_PADDING;
  public height = 0;

  public subtitlesHashmap: Record<string, Subtitle> = {};
  public verticalAlign: TextVerticalAlignType = TextVerticalAlignType.BOTTOM;
  public generatedFrom?: TranscriptGeneratedFrom;

  public previewImageUrl: string | undefined;
  // CodeReviewTaimurDone: Why is this needed?

  public areFontsLoaded = false;
  public smallestWidthNeeded: number | undefined;

  private wasItemSelectedOnMouseDown = false;

  private readonly boundBringToFront;

  public constructor(page: Page) {
    super(page);
    this.boundBringToFront = this.bringToFront.bind(this);
  }

  public toObject(): TranscriptItemObject {
    const subtitleObjects: Record<string, SubtitleObject> = {};

    for (const [key, item] of Object.entries(this.subtitlesHashmap)) {
      subtitleObjects[key] = item.toObject();
    }

    return {
      ...super.toObject(),
      subtitlesHashmap: subtitleObjects,
      generatedFrom: this.generatedFrom,
      verticalAlign: this.verticalAlign,
    };
  }

  public setPreviewImageUrl(): void {
    const textbox = new Textbox('Aa', {
      lockMovementY: true,
      lockMovementX: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
      left: BACKGROUND_PADDING,
      top: BACKGROUND_PADDING,
    });

    const background = new Rect({
      width: textbox.width + BACKGROUND_PADDING * 2,
      height: textbox.height + BACKGROUND_PADDING * 2,
      strokeWidth: 0,
      evented: false,
      selectable: false,
    });

    const textStylesToUse = this.getAnySubtitle().sentenceTextStyles;

    textbox.set({
      ...textStylesToUse.getTextStyles(this.getAnySubtitle().backgroundFabricObject.width, this.getAnySubtitle().backgroundFabricObject.height),
      charSpacing: 10,
      shadow: this.getShadow(),
      fontFamily: textStylesToUse.fontFamily,
    });

    if (textStylesToUse.stroke) {
      textbox.set({
        strokeWidth: textStylesToUse.fontSize * textStylesToUse.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
        strokeLineJoin: 'round',
        paintFirst: 'stroke',
        stroke: rgbToHexString(textStylesToUse.strokeColor, 1),
      });
    } else {
      textbox.set({
        strokeWidth: 0,
        strokeLineJoin: 'miter',
        paintFirst: 'fill',
        stroke: undefined,
      });
    }

    background.set({
      rx: this.getAnySubtitle().backgroundBorderRadius,
      ry: this.getAnySubtitle().backgroundBorderRadius,
      fill: this.getAnySubtitle().backgroundFill.getFill(background.width, background.height),
    });

    background.set({
      width: textbox.width + BACKGROUND_PADDING * 2,
      height: textbox.height + BACKGROUND_PADDING * 2,
    });

    const dummyFabricGroup = new Group([background, textbox], {
      subTargetCheck: true,
      interactive: true,
      layoutManager: new LayoutManager(new FixedLayout()),
      lockMovementY: true,
      lockMovementX: true,
      hasControls: false,
      selectable: false,
    });

    dummyFabricGroup.set({
      width: background.width,
      height: background.height,
    });

    background.setPositionByOrigin(new Point(0, 0), 'center', 'center');
    textbox.setPositionByOrigin(new Point(0, 0), 'center', 'center');

    //CodeReviewTaimurDone: Use scale key in toDataURL to get a more resoable image with smaller dimensions --> i think you mean this way?
    this.previewImageUrl = dummyFabricGroup.toDataURL({format: 'jpeg', quality: 0.05});
  }

  public getPreviewImageUrl(): string | undefined {
    return this.previewImageUrl;
    //CodeReviewTaimurDone: previewImageUrl is storing multiple huge image data for every small change to subtitle. This previewImageUrl should only save current preview. Its interface can be {cacheKey: string, imageData: string}
  }

  //CodeReviewTaimurDiscuss: This is used but phpstorm is showing it has unused function. Maybe needs better ts where this is used:
  // was just comparing the usages of this in text-item.class.ts, and it leads to a isPDFSafe flow --> do we need something like that in this case too?
  public getFonts(withVariation: boolean): string[] {
    //CodeReviewTaimurDiscuss: cache this.getAnySubtitle() in a variable here
    // not sure why we need to cache it?
    return [
      withVariation
        ? getFontFamilyNameForVariations(
            this.getAnySubtitle().sentenceTextStyles.fontFamily,
            this.getAnySubtitle().sentenceTextStyles.isBold,
            this.getAnySubtitle().sentenceTextStyles.isItalic
          )
        : this.getAnySubtitle().sentenceTextStyles.fontFamily,
    ];
  }

  public getSubtitleIdsInOrder(): string[] {
    return Object.entries(this.subtitlesHashmap)
      .sort(([, subtitleA], [, subtitleB]) => {
        const startTimeDifference = subtitleA.startTime - subtitleB.startTime;

        if (startTimeDifference !== 0) {
          return startTimeDifference;
        }

        return subtitleA.endTime - subtitleB.endTime;
      })
      .map(([uid]) => {
        return uid;
      });
  }

  //CodeReviewTaimurDone: This eslint error is wrong here as its parent function uses await. See what we can do about this rule. --> couldn't find a way to change the eslint config to cater for these
  // cases specifically, could either add a eslint-disable-next-line comment, or do this instead:
  public async onItemAddedToPage(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.syncAllSubtitles();
        this.page.fabricCanvas.on('object:added', this.boundBringToFront);
        resolve();
      } catch (error) {
        reject(error instanceof Error ? error : new Error(String(error)));
      }
    });
  }

  public onRemove(): void {
    super.onRemove();
    this.page.fabricCanvas.off('object:added', this.boundBringToFront);
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      subtitle.onRemove();
    }
  }

  public canMoveInZIndex(): boolean {
    return false;
  }

  public async updateFromObject(
    transcriptItemObject: DeepPartial<TranscriptItemObject>,
    {undoable = true, updateRedux = true, onError = noop}: TranscriptUpdateFromObjectOpts = {}
  ): Promise<void> {
    const {subtitlesHashmap, ...obj} = transcriptItemObject;

    this.copyVals({
      ...obj,
    });
    await this.init();

    if (subtitlesHashmap) {
      // delete items that are in this page but not in the transcriptItemObject
      for (const [uid] of Object.entries(this.subtitlesHashmap)) {
        if (subtitlesHashmap[uid] === undefined) {
          this.removeSubtitle(uid);
        }
      }

      const promises = [];

      for (const [uid, subtitleObject] of Object.entries(subtitlesHashmap)) {
        if (subtitleObject) {
          // CodeReviewTaimurDone: What would be right approach to address this eslint error? Change subtitlesHashmap type from Record<string, Subtitle> to Record<string, Subtitle | undefined>?
          //
          // --> either that, or use Map<string, Subtitle>. Changing to  Record<string, Subtitle | undefined> seems a bit weird and is also not how hashmaps made using Record are used in the
          // codebase so far. In a hashmap, it seems implied that if a key is present, then a value is present too, but this way, we can have a key present whose value is undefined. Stuff like
          // Object.values, Object.keys and Object.entries will give different number of items, no? I think using Map will be a better alternative.
          //
          // But regardless, any of these changes will require changes to 20+ files, since it will produce TS errors saying subtitlesHashmap[uid] can be undefined.
          // If you think it is worth the effort, then unmark this as being done.
          if (uid in this.subtitlesHashmap) {
            promises.push(
              this.subtitlesHashmap[uid].updateFromObject(subtitleObject, {
                undoable: false,
                updateRedux: false,
                onError: onError,
              })
            );
          } else {
            promises.push(createSubtitleFromObjectAndAddToTranscript(this, subtitleObject));
          }
        }
      }

      await Promise.all(promises);
    }

    if (!this.previewImageUrl) {
      this.setPreviewImageUrl();
    }

    this.ensureMinDimensionsAreSatisfied();

    await this.invalidate();

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }

    this.syncAllSubtitles();
    this.page.fabricCanvas.requestRenderAll();
  }

  public getInitialCoordinatesForTranscript(count: number): {x: number; y: number} {
    const maxHeight = this.getLargestSubtitleFabricObjectHeight();

    const adjustedY = this.page.poster.height - maxHeight - TRANSCRIPT_ITEM_PADDING - (count - 1) * NEW_TRANSCRIPT_ITEM_OFFSET_FROM_BOTTOM;
    const yForCurrentlyAddedTranscriptItem = adjustedY >= TRANSCRIPT_ITEM_PADDING ? adjustedY : TRANSCRIPT_ITEM_PADDING;

    return {
      x: TRANSCRIPT_ITEM_PADDING,
      y: yForCurrentlyAddedTranscriptItem,
    };
  }

  public async updateFabricObjectPositionAndSize(count: number): Promise<void> {
    await this.updateFromObject(this.getInitialCoordinatesForTranscript(count), {updateRedux: false, undoable: false});
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      width: this.width,
      height: this.height,
    });
    this.updateTranscriptGroupItemsDimensionsAndAlignment();
    this.fabricObject.setCoords();
  }

  public getAnySubtitle(): Subtitle {
    return Object.values(this.subtitlesHashmap)[0];
  }

  public seekToSubtitleAtTime(time: number): void {
    let subtitleAtTime: Subtitle | undefined;

    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (time >= subtitle.startTime && time <= subtitle.endTime) {
        subtitleAtTime = subtitle;
      } else {
        subtitle.hide();
      }
    }

    if (subtitleAtTime) {
      subtitleAtTime.show();
      subtitleAtTime.handleCurrentWordAtTime(time);
    }
  }

  public getCurrentSubtitle(): Subtitle | undefined {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (subtitle.isCurrentlyActiveSubtitle) {
        return subtitle;
      }
    }

    return undefined;
  }

  public async updateAllSubtitlesToLetterCase(letterCase: LetterCase): Promise<void> {
    const wasPlaying = this.page.poster.isPlaying();

    if (wasPlaying) {
      await this.page.poster.pause();
    }

    const newSubtitlesHashmap: Record<string, DeepPartial<SubtitleObject>> = {};
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (subtitle.animationStyle !== SubtitleTemplateType.SIMPLE) {
        const wordsWithAppliedLetterCase = [];
        for (const word of subtitle.words) {
          wordsWithAppliedLetterCase.push({...word, text: applyLetterCase(word.text, letterCase)});
        }

        newSubtitlesHashmap[subtitle.subtitleUID] = {
          text: applyLetterCase(subtitle.text, letterCase),
          words: wordsWithAppliedLetterCase,
        };
      } else {
        newSubtitlesHashmap[subtitle.subtitleUID] = {
          text: applyLetterCase(subtitle.text, letterCase),
        };
      }
    }

    await this.updateFromObject({
      subtitlesHashmap: newSubtitlesHashmap,
    });

    if (wasPlaying) {
      await this.page.poster.play();
    }
  }

  public async updateAllSubtitles(subtitleObject: DeepPartial<SubtitleObject>, undoable = true, onError?: () => void): Promise<void> {
    const wasPlaying = this.page.poster.isPlaying();

    if (wasPlaying) {
      await this.page.poster.pause();
    }

    const newSubtitlesHashmap: Record<string, DeepPartial<SubtitleObject>> = {};
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      newSubtitlesHashmap[subtitle.subtitleUID] = subtitleObject;
    }

    this.previewImageUrl = undefined;
    this.areFontsLoaded = false;

    await this.updateFromObject(
      {
        subtitlesHashmap: newSubtitlesHashmap,
      },
      {undoable, onError}
    );

    if (wasPlaying) {
      await this.page.poster.play();
    }
  }

  public onScaling(): void {}

  public updateTranscriptGroupItemsDimensionsAndAlignment(): void {
    if (!this.areFontsLoaded) {
      return;
    }

    this.updateSubtitleDimensions();
    this.updateTranscriptHeight();
    this.updateSubtitlesAlignment();
  }

  public async onTextEditingExit(): Promise<void> {
    this.smallestWidthNeeded = undefined;
    this.setTranscriptItemAndFabricGroupWidth(Math.max(this.getSmallestWidthNeededBySubtitleFabricTextBox(), this.width));

    this.syncAllSubtitles();

    await this.updateFabricObject();

    this.fabricObject.set({
      subTargetCheck: false,
      interactive: false,
    });
  }

  public async onTextEditingEnter(): Promise<void> {
    this.smallestWidthNeeded = undefined;

    if (this.page.poster.isPlaying()) {
      await this.page.poster.pause();
    }
  }

  public onTextChanged(): void {
    this.smallestWidthNeeded = undefined;

    this.setTranscriptItemAndFabricGroupWidth(Math.max(this.getSmallestWidthNeededBySubtitleFabricTextBox(), this.width));
  }

  public doesfabricObjBelongtoItem(fabricObj: FabricObject | Group): boolean {
    return this.fabricObject === fabricObj || this.fabricObject === fabricObj.group?.group;
  }

  public getDuration(): number {
    let lastSubtitleEndTime: number | undefined;
    let firstSubtitleStartTime: number | undefined;

    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (firstSubtitleStartTime === undefined || (firstSubtitleStartTime && subtitle.startTime < firstSubtitleStartTime)) {
        firstSubtitleStartTime = subtitle.startTime;
      }

      if (lastSubtitleEndTime === undefined || (lastSubtitleEndTime && subtitle.endTime > lastSubtitleEndTime)) {
        lastSubtitleEndTime = subtitle.endTime;
      }
    }

    if (lastSubtitleEndTime === undefined || firstSubtitleStartTime === undefined) {
      throw new Error(`Failed to get duration for transcript!`);
    }

    return lastSubtitleEndTime - firstSubtitleStartTime;
  }

  public isStreamingMediaItem(): boolean {
    return true;
  }

  public async seek(time: number): Promise<void> {
    // CodeReviewTaimurDone: This will trigger seek for all items which out of scope for a function in transcript-item
    this.seekToSubtitleAtTime(time);
  }

  public addSubtitle(itemToAdd: Subtitle): void {
    this.subtitlesHashmap[itemToAdd.subtitleUID] = itemToAdd;
    addItemsToGroupWithOriginalScale(this.fabricObject, [itemToAdd.fabricObject]);
  }

  protected initEvents(): void {
    super.initEvents();
    this.fabricObject.on('mousedown:before', this.onMouseDown.bind(this));
    this.fabricObject.on('mouseup', this.onMouseUp.bind(this));
    this.fabricObject.on('deselected', this.onDeselected.bind(this));
  }

  public calculateSmallestWidthNeededBySubtitleFabricTextBox(): number {
    if (this.getAnySubtitle().animationStyle === SubtitleTemplateType.ONE_WORD) {
      return this.calculateSmallestWidthNeededBySingleWordSubtitleFabricTextbox();
    } else {
      let maxSubtitleMinWidth = 0;

      for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
        const minItemWidth = subtitle.getSmallestWidthNeededByFabricTextBox();
        if (minItemWidth > maxSubtitleMinWidth) {
          maxSubtitleMinWidth = minItemWidth;
        }
      }

      return maxSubtitleMinWidth;
    }
  }

  public async updateOverlappingSubtitles(data: OverlappingSubtitleItemResizeData): Promise<void> {
    const leeway = data.direction === 'end' ? MIN_TIME_GAP_FOR_TRANSCRIPT : -MIN_TIME_GAP_FOR_TRANSCRIPT;
    for (let i = 0; i < data.ids.length; i++) {
      await this.subtitlesHashmap[data.ids[i]].updateFromObject(
        {
          startTime: this.subtitlesHashmap[data.ids[i]].startTime + data.overlap + leeway,
          endTime: this.subtitlesHashmap[data.ids[i]].endTime + data.overlap + leeway,
        },
        {undoable: false}
      );
    }
  }

  public async trimSubtitlesExceedingDuration(duration: number): Promise<void> {
    for (const [, item] of Object.entries(this.subtitlesHashmap)) {
      if (item.startTime > duration) {
        this.removeSubtitle(item.subtitleUID);
      } else if (Math.abs(item.startTime - duration) < 1) {
        this.removeSubtitle(item.subtitleUID);
      } else if (item.endTime > duration) {
        await item.updateFromObject(
          {
            endTime: duration,
            hasUserEdited: true,
          },
          {undoable: false}
        );
      }
    }
  }

  public getEndTimeOfLastItem(): number {
    const listOfEndTimes = [];
    for (const [, item] of Object.entries(this.subtitlesHashmap)) {
      listOfEndTimes.push(item.endTime);
    }

    return Math.max(...listOfEndTimes);
  }

  // CodeReviewTaimurDone: Break this funciton into smaller readable functions if possible please
  protected getSmallestWidthNeededBySubtitleFabricTextBox(): number {
    //CodeReviewTaimurDone: smallestWidthNeeded is storing multiple data for every small change to subtitle. This smallestWidthNeeded should only save current val. Its interface can be {cacheKey: string, smallestWidthNeeded: number}
    if (!this.areFontsLoaded) {
      // cannot cache the result in this case, because once the font gets loaded in the async updateFromObject call of the subtitle class, the fabric object width needed will change
      return this.calculateSmallestWidthNeededBySubtitleFabricTextBox();
    }

    if (!this.smallestWidthNeeded) {
      this.smallestWidthNeeded = this.calculateSmallestWidthNeededBySubtitleFabricTextBox();
    }

    return this.smallestWidthNeeded;
  }

  protected getFabricObjectForItem(): Promise<Group> {
    const subtitleTextAndBackground: Group[] = [];

    return new Promise((resolve) => {
      Object.values(this.subtitlesHashmap).forEach((subtitle) => {
        subtitleTextAndBackground.push(subtitle.getFabricObject());
      });

      const groupItem = new Group(subtitleTextAndBackground, {
        ...super.getCommonOptions(),
        width: this.width,
        height: this.height,
        perPixelTargetFind: true,
        layoutManager: new LayoutManager(new FixedLayout()),
      });
      resolve(groupItem);
    });
  }

  protected initCustomControls(): void {
    super.initCustomControls();
    const pmwMlControl = getPmwMlControl(this.onResizeWithLeftHandle.bind(this));
    const pmwMrControl = getPmwMrControl(this.onResizeWithRightHandle.bind(this));
    this.fabricObject.controls[pmwMlControl.key] = pmwMlControl.control;
    this.fabricObject.controls[pmwMrControl.key] = pmwMrControl.control;
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    const isItemLocked = this.isLocked();
    this.fabricObject.setControlsVisibility({
      pmwMr: !isItemLocked,
      pmwMl: !isItemLocked,
    });
  }

  private ensureMinDimensionsAreSatisfied(): void {
    if (!this.areFontsLoaded) {
      return;
    }

    this.smallestWidthNeeded = undefined;

    const minWidth = this.getSmallestWidthNeededBySubtitleFabricTextBox();
    const height = this.getLargestSubtitleFabricObjectHeight();

    if (this.width < minWidth) {
      this.width = minWidth;
      this.height = height;
    }
  }

  private calculateSmallestWidthNeededBySingleWordSubtitleFabricTextbox(): number {
    let maxSubtitleMinWidth = 0;

    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      for (const word of subtitle.words) {
        const text = new IText(word.text, {
          shadow: this.aura.getItemAura(this.getScaleForShadow()),
          ...subtitle.sentenceTextStyles.getTextStyles(subtitle.textFabricObject.width, subtitle.textFabricObject.height),
        });

        const minItemWidth = text.width + BACKGROUND_PADDING * 2;
        if (minItemWidth > maxSubtitleMinWidth) {
          maxSubtitleMinWidth = minItemWidth;
        }
      }
    }

    const activeSubtitle = this.getCurrentSubtitle();

    if (activeSubtitle?.textFabricObject.isEditing) {
      const text = new IText(activeSubtitle.textFabricObject.text, {
        shadow: this.aura.getItemAura(this.getScaleForShadow()),
        ...this.getAnySubtitle().sentenceTextStyles.getTextStyles(this.getAnySubtitle().textFabricObject.width, this.getAnySubtitle().textFabricObject.height),
      });

      const minItemWidth = text.width + BACKGROUND_PADDING * 2;
      if (minItemWidth > maxSubtitleMinWidth) {
        maxSubtitleMinWidth = minItemWidth;
      }
    }
    return maxSubtitleMinWidth;
  }

  private onMouseDown(): void {
    if (!(this.page.fabricCanvas instanceof Canvas)) {
      throw new Error(`Page canvas neeed to be of type canvas`);
    }
    this.wasItemSelectedOnMouseDown = this.page.getSelectedItems()[0] === this;
  }

  private onDeselected(): void {
    const subtitlesHashmapWithoutEmptySubtitles: Record<string, Subtitle> = {};

    Object.entries(this.subtitlesHashmap).forEach(([id, subtitle]) => {
      if (subtitle.text !== '') {
        subtitlesHashmapWithoutEmptySubtitles[id] = subtitle;
      }
    });

    if (Object.values(subtitlesHashmapWithoutEmptySubtitles).length) {
      void this.updateFromObject({subtitlesHashmap: subtitlesHashmapWithoutEmptySubtitles});
    } else {
      deleteItemByID(this.uid);
    }
  }

  private onMouseUp(e: ObjectEvents['mouseup']): void {
    if (!(this.page.fabricCanvas instanceof Canvas)) {
      throw new Error(`Page canvas neeed to be of type canvas`);
    }

    if (e.isClick && this.wasItemSelectedOnMouseDown && !this.fabricObject.getActiveControl()) {
      this.fabricObject.set({
        subTargetCheck: true,
        interactive: true,
      });
      const currentSubtitleTextbox = this.getCurrentSubtitle()?.textFabricObject;
      if (currentSubtitleTextbox) {
        this.page.fabricCanvas.setActiveObject(currentSubtitleTextbox);
        currentSubtitleTextbox.enterEditing(e.e);
      }
    }
  }

  private onResizeWithRightHandle(event: OnResizeParams): void {
    const minWidth = this.getSmallestWidthNeededBySubtitleFabricTextBox();

    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < minWidth) {
      return;
    }

    this.setTranscriptItemAndFabricGroupWidth(newWidth);
  }

  private onResizeWithLeftHandle(event: OnResizeParams): void {
    const minWidth = this.getSmallestWidthNeededBySubtitleFabricTextBox();

    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < minWidth) {
      return;
    }

    this.fabricObject.set({
      left: this.fabricObject.left - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
    });
    this.setTranscriptItemAndFabricGroupWidth(newWidth);
  }

  private setTranscriptItemAndFabricGroupWidth(width: number): void {
    this.width = width;
    this.fabricObject.set({
      width,
    });
    this.updateTranscriptGroupItemsDimensionsAndAlignment();
  }

  private updateTranscriptHeight(): void {
    const heightToSet = this.getLargestSubtitleFabricObjectHeight();

    if (heightToSet === this.height) {
      return;
    }

    this.height = heightToSet;

    this.fabricObject.set({
      height: this.height,
    });
  }

  private getLargestSubtitleFabricObjectHeight(): number {
    if (this.getAnySubtitle().animationStyle === SubtitleTemplateType.ONE_WORD) {
      let maxHeight = -1;

      for (const subtitle of Object.values(this.subtitlesHashmap)) {
        for (const word of subtitle.words) {
          const tempTextFabricObject = new IText(word.text, {
            shadow: subtitle.aura.getItemAura(subtitle.getScaleForShadow()),
            ...subtitle.sentenceTextStyles.getTextStyles(subtitle.textFabricObject.width, subtitle.textFabricObject.height),
          });

          if (tempTextFabricObject.height > maxHeight) {
            maxHeight = tempTextFabricObject.height;
          }
        }
      }

      return maxHeight + BACKGROUND_PADDING * 2;
    }

    let maxObjectHeight = 0;
    for (const object of this.fabricObject.getObjects()) {
      if (object.height > maxObjectHeight) {
        maxObjectHeight = object.height;
      }
    }

    return maxObjectHeight;
  }

  private updateSubtitleDimensions(): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      subtitle.setSubtitleFabricGroupItemsDimensionsAndAlignment(this.width);
    }
  }

  private removeSubtitle(uid: string): void {
    if (this.hasItem(uid)) {
      this.subtitlesHashmap[uid].onRemove();
      this.fabricObject.remove(this.subtitlesHashmap[uid].fabricObject);
      delete this.subtitlesHashmap[uid];
    }
  }

  private hasItem(uid: string): boolean {
    return uid in this.subtitlesHashmap;
  }

  private updateSubtitlesAlignment(): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (this.verticalAlign === TextVerticalAlignType.TOP) {
        subtitle.fabricObject.setPositionByOrigin(new Point(0, -this.fabricObject.height / 2), 'center', 'top');
      } else if (this.verticalAlign === TextVerticalAlignType.CENTER) {
        subtitle.fabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');
      } else if (this.verticalAlign === TextVerticalAlignType.BOTTOM) {
        subtitle.fabricObject.setPositionByOrigin(new Point(0, this.fabricObject.height / 2 - subtitle.fabricObject.height), 'center', 'top');
      }
    }
  }

  private bringToFront(): void {
    this.page.fabricCanvas.bringObjectToFront(this.fabricObject);
  }

  private syncAllSubtitles(): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      subtitle.syncSubtitle();
    }
  }
}

export const getTranscriptItemFromSubtitleId = (id: string): TranscriptItem | null => {
  const items = window.posterEditor?.whiteboard?.getCurrentPage().items.itemsHashMap;
  if (!items) {
    return null;
  }

  let transcripItem: TranscriptItem | null = null;

  for (const [, item] of Object.entries(items)) {
    if (item.isTranscript()) {
      if (id in item.subtitlesHashmap) {
        transcripItem = item;
        break;
      }
    }
  }
  return transcripItem;
};
