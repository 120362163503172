import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import React from 'react';
import type {SocialAccountConnectionResponsePanelProps} from '@Panels/social-account-connection-response-panel';
import {MODALS_IDS} from '@Components/modal-container';
import {useAppSelector} from '@/hooks';
import {MOBILE_MODAL_WIDTH_THRESHOLD, Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import {PANEL_ID} from '@Modals/social-account-connection-response-modal/index';
import useWindowSize from '@Hooks/useWindowSize';

export type SocialAccountConnectionResponseModalProps = SocialAccountConnectionResponsePanelProps;

export default function SocialAccountConnectionResponseModal(): ReactElement | null {
  const [maxWidth, setMaxWidth] = useState<string>('');
  const maxHeight = '300px';

  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.SOCIAL_ACCOUNT_CONNECTION_RESPONSE_MODAL];
  });

  const {windowWidth} = useWindowSize();

  const isMobileModal = (): boolean => {
    return windowWidth < MOBILE_MODAL_WIDTH_THRESHOLD;
  };

  useEffect(() => {
    if (isMobileModal()) {
      setMaxWidth('100%');
    } else {
      setMaxWidth('500px');
    }
  }, []);

  return (
    <Modal
      modalId={MODALS_IDS.SOCIAL_ACCOUNT_CONNECTION_RESPONSE_MODAL}
      panelId={PANEL_ID}
      modalMaxWidth={maxWidth}
      modalMaxHeight={maxHeight}
      modalHeight="100%"
      modalWidth="100%"
    >
      <LazyPanel
        lazyPanelId={PANEL_ID}
        panelDirectoryName="social-account-connection-response-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}
