import type {ReactElement} from 'react';
import React, {useState} from 'react';
import {CostTag} from '@Components/cost-tag';
import {SourceTag} from '@Components/source-tag';
import type {GridMediaItemStorage, GridMediaItemTag} from '../grid-media-item';
import {GridMediaItem, GridMediaItemTagType} from '../grid-media-item';
import type {GRID_ITEM_TYPE, GridItemProps} from '../grid-item';
import styles from './grid-stock-image-item.module.scss';

export interface GridStockImageItemStorage extends GridMediaItemStorage {
  type: GRID_ITEM_TYPE.STOCK_IMAGE;
  thumbSrc: string;
  source: string;
  data: Record<string, any>;
}

export interface GridStockImageItemBackEndData {
  [key: string]: any;
}

interface GridStockImageItemProps extends GridItemProps {
  thumbSrc: string;
  paddedThumb?: boolean;
  isLoading?: boolean;
  tag?: GridMediaItemTag;
}

export function GridStockImageItem({...props}: GridStockImageItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const getTag = (): ReactElement => {
    return (
      <div className={`${styles.tagContainer} _full-width _full-height`}>
        {props.tag?.type === GridMediaItemTagType.COST ? (
          <CostTag containerStyle={props.tag.containerStyle} textStyle={props.tag.textStyle} icon={props.tag.icon} price={props.tag.price} />
        ) : (
          <SourceTag containerStyle={props.tag?.containerStyle} contentStyle={props.tag?.iconStyle} />
        )}
      </div>
    );
  };

  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <>
          <img
            onLoad={(): void => {
              return setIsImageLoaded(true);
            }}
            src={props.thumbSrc}
            className={`radius-12 ${styles.thumb}`}
            alt={window.i18next.t('pmwjs_image')}
            draggable="false"
            loading="lazy"
            onContextMenu={(e): void => {
              return e.preventDefault();
            }}
          />
          {props.tag ? getTag() : null}
        </>
      }
    />
  );
}
