import {isUserDesigner} from '@Utils/user.util';
import {getUserType} from '@Libraries/user.library';
import {openMessageModal} from '@Modals/message-modal';
import type {UserImageVOResponse} from '@Libraries/user-image-library';
import type {UserVideoVOResponse} from '@Libraries/user-video-library';

type StockStatusStorage = Record<SOURCE, boolean>;
let stockStatus: StockStatusStorage | null = null;

export enum SOURCE {
  GETTY = 'getty',
  FACEBOOK = 'facebook',
  PIXABAY = 'pixabay',
  STORYBLOCKS = 'storyblocks',
  STORYBLOCKS_ILLUSTRATIONS = 'storyblocks-illustrations',
  PIXABAY_ILLUSTRATIONS = 'pixabay-illustrations',
  GETTY_ILLUSTRATIONS = 'getty-illustrations',
  PMW = 'pmw',
  PMW_SHAPES = 'pmw-shapes',
  PMW_STICKERS = 'pmw-sticker',
  PMW_ICONS = 'pmw-icon',
  UPLOAD = 'upload',
  AI_TEXT_TO_IMAGE = 'AI_TEXT_TO_IMAGE',
  PMW_IMAGE = 'pmw-image',
}

export const MAX_GETTY_VIDEO_ITEMS = 1;

export const initStockStatus = async () => {
  if (!stockStatus) {
    stockStatus = await window.PMW.readLocal('stockmedia/getStockStatus');
  }
};

/**
 * You need to call initStockStatus before this function
 * @param source
 * @return {boolean}
 */
export const isStockSourceAvailable = (source: SOURCE) => {
  if (!stockStatus) {
    return true;
  }

  return stockStatus[source] ?? true;
};

/**
 * @param source
 * @return {string|*}
 */
export const getStockSourceUnavailableTitle = (source: SOURCE) => {
  if (isStockSourceAvailable(source)) {
    return '';
  }

  switch (source) {
    case SOURCE.STORYBLOCKS:
      return window.i18next.t('pmwjs_error_communicating_with_storyblocks');

    default:
      return window.i18next.t('pmwjs_error_communicating_with_media_stock_source');
  }
};

export const getAllStockSources = (): Array<SOURCE> => {
  const sources = [SOURCE.GETTY, SOURCE.STORYBLOCKS];
  if (!isUserDesigner(getUserType())) {
    sources.push(SOURCE.PIXABAY);
  }
  return sources;
};

export const isStoryblockAvailable = async () => {
  await initStockStatus();
  return stockStatus !== null ? stockStatus[SOURCE.STORYBLOCKS] : true;
};

export const isGettyAvailable = async () => {
  await initStockStatus();
  return stockStatus !== null ? stockStatus[SOURCE.GETTY] : true;
};

export const isPixabayAvailable = async () => {
  await initStockStatus();
  return stockStatus !== null ? stockStatus[SOURCE.PIXABAY] : true;
};

export interface GettyImageUploadProps {
  type: string;
  imageid: string;
  title: string;
  artist: string;
  collection: number;
}

/**
 * Sends data of image to be imported into our system from Getty Images
 * @param {object} p Parameters to send to server
 * @return {Promise<*>}
 * @throws Error
 */
export function gettyImageUpload(p: GettyImageUploadProps): Promise<UserImageVOResponse> {
  return window.PMW.writeLocal('posterimage/gettyupload', p) as Promise<UserImageVOResponse>;
}

export interface PixabyImageUploadProps {
  url: string;
}

/**
 * Sends data of image to be imported into our system from Pixabay
 * @param {object} p Parameters to send to server
 * @return {Promise<*>}
 * @throws Error
 */
export function pixabayImageUpload(p: PixabyImageUploadProps): Promise<UserImageVOResponse> {
  return window.PMW.writeLocal('posterimage/pixabayupload', p) as Promise<UserImageVOResponse>;
}

export interface StoryblockImageUploadProps {
  userid?: number;
  imageid: string;
  format: string;
}

/**
 * Sends data of image (photos) to be imported into our system from StoryBlocks
 * @param {object} p Parameters to send to server
 * @return {Promise<*>}
 * @throws Error
 */
export function storyBlocksImageUpload(p: StoryblockImageUploadProps): Promise<UserImageVOResponse> {
  return window.PMW.writeLocal('posterimage/storyBlocksImageUpload', p) as Promise<UserImageVOResponse>;
}

/**
 Sends data of image (clipart/vectors/illustrations) to be imported into our system from StoryBlocks
 * @param {object} p Parameters to send to server
 * @return {Promise<*>}
 * @throws Error
 */
export function storyBlocksIllustrationsUpload(p: StoryblockImageUploadProps): Promise<UserImageVOResponse> {
  return window.PMW.writeLocal('posterimage/storyBlocksIllustrationsUpload', p) as Promise<UserImageVOResponse>;
}

/**
 * Sends data of vectors to be imported into our system from Pixabay
 * @param {object} p Parameters to send to server
 */
export function pixabayIllustrationsUpload(p: PixabyImageUploadProps): Promise<UserImageVOResponse> {
  return window.PMW.writeLocal('posterimage/pixabayIllustrationUpload', p) as Promise<UserImageVOResponse>;
}

export interface GettyVideoUploadProps {
  videoId: string;
  title: string;
  artist: string;
  collection: number;
}

/**
 * Sends data of video to be imported into our system from Getty Images
 * @param {object} p Parameters to send to server
 * @return {Promise<*>}
 * @throws Error
 */
export function gettyVideoUpload(p: GettyVideoUploadProps): Promise<UserVideoVOResponse> {
  return window.PMW.writeLocal('postervideo/gettyUpload', p) as Promise<UserVideoVOResponse>;
}

export interface PixabyVideoUploadProps {
  videoId: string;
  videoHighRes: string;
  videoSmallRes: string;
}

/**
 * Sends data of video to be imported into our system from Pixabay
 * @param {object} p Parameters to send to server
 * @return {Promise<*>}
 * @throws Error
 */
export function pixabayVideoUpload(p: PixabyVideoUploadProps): Promise<UserVideoVOResponse> {
  return window.PMW.writeLocal('postervideo/pixabayUpload', p) as Promise<UserVideoVOResponse>;
}

export interface StoryblockVideoUploadProps {
  videoId: string;
}

/**
 * Sends data of video to be imported into our system from StoryBlocks
 * @param {object} p Parameters to send to server
 * @return {Promise<*>}
 * @throws Error
 */
export function storyBlocksVideoUpload(p: StoryblockVideoUploadProps): Promise<UserVideoVOResponse> {
  return window.PMW.writeLocal('postervideo/videoBlocksVideoUpload', p) as Promise<UserVideoVOResponse>;
}

export function storyBlocksAudioUpload(p: Object) {
  return window.PMW.writeLocal('posteraudio/storyBlocksAudioUpload', p);
}

export function shouldOpenStockModal() {
  if (window.postermywall && !window.postermywall.core.model.AppProxy.isFeatureEnabled('addStockMultiMediaItem')) {
    openMessageModal({
      title: window.i18next.t('pmwjs_add_stock_image_disabled_title'),
      text: window.i18next.t('pmwjs_add_stock_image_disabled_message'),
    });
    return false;
  }
  return true;
}
