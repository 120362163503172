import type {ReactElement} from 'react';
import React, {useState} from 'react';
import type {SOURCE} from '@Libraries/stock-graphic-library';
import type {GridItemProps} from '../grid-item';
import type {GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';

export interface GridClipartItemStorage extends GridMediaItemStorage {
  thumbSrc: string;
  source: SOURCE;
  data: Record<string, any>;
}

export interface GridClipartItemBackEndData {
  id: string;
  hidden: boolean;
  tu: string;
  u: string;

  [key: string]: any;
}

interface GridClipartItemProps extends GridItemProps {
  thumbSrc: string;
  paddedThumb?: boolean;
}

export function GridClipartItem({...props}: GridClipartItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <img
          onLoad={(): void => {
            return setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          className="radius-12"
          alt={window.i18next.t('pmwjs_image')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
