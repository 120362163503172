import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {MediaTypeLoadingStates, MediaTypePublishingParams, SocialMediaAccountId, SocialMediaAccountIdString, SocialPost} from '@Components/social-media/post.vo';
import {PlatformMediaType} from '@Components/social-media/post.vo';
import type {
  AccountNeedsResizeMapForDesigns,
  AccountPublishableUrlsForMediaType,
  AccountResizeStatusForDesign,
  AccountResizeStatusForMediaType,
  CaptionErrorByAccountIds,
  MultiGraphicDesignSelectorModeType,
  PublishableUrlForDesign,
  PublishableUrlUpdatePayload,
  PublishedUrlMapForAccount,
  PublishedUrlUpdatePayload,
  RetryStatusForMediaType,
  RetryStatusUpdatePayload,
  TitleErrorByAccountIds,
} from '@Components/social-media/social-media.types';
import {MultiGraphicDesignSelectorModes, SocialPlatform} from '@Components/social-media/social-media.types';
import type {SocialAccount} from '@Components/social-media/account.vo';
import {socialMediaWizardExtraReducers} from '@Components/social-media-wizard/social-media-wizard-extra-reducer';
import {
  CaptionErrorTypes,
  countHashtags,
  type DesignsGraphicMetaPayload,
  type DesignsGraphicMetaUpdatePayload,
  socialAccountPostCoverValidations,
  SocialMediaWizardMediaTypeSelection,
  SocialMediaWizardStep,
  titleValidations,
  WizardMobileNavView,
} from '@Components/social-media-wizard/social-media-wizard.types';
import type {
  GraphicMeta,
  PosterHashedId,
  PublishingParamsBoardIdPayload,
  PublishingParamsCaptionPayload,
  PublishingParamsCommonCaptionPayload,
  PublishingParamsCommonPostCoverPayload,
  PublishingParamsDisableComment,
  PublishingParamsDisableDuet,
  PublishingParamsDisableStitch,
  PublishingParamsGoogleBusinessProfileCtaTypePayload,
  PublishingParamsGoogleBusinessProfilePostTypePayload,
  PublishingParamsIsPromotingThirdParty,
  PublishingParamsIsSelfBrand,
  PublishingParamsLinkedInPrivacySettingsPayload,
  PublishingParamsPublishErrorMessagePayload,
  PublishingParamsSocialPostCoverPayload,
  PublishingParamsTikTokPrivacySettingsPayload,
  PublishingParamsTitlePayload,
  PublishingParamsWebsiteLinkPayload,
  PublishingParamsYouTubePrivacySettingsPayload,
  PublishingStatusForAccountPayload,
  SocialMediaPublishingParams,
  SocialPostCover,
  SocialPostPublishingAction,
} from '@Components/social-media/publish-params.types';
import {RetryStatus} from '@Components/social-media/publish-params.types';
import {getAccountForId, getCharacterLimitForAccountPost, getEmptyPublishingParamsForAccount} from '@Libraries/social-media-wizard-library';
import {LoadingStates} from '@Utils/loading.util';
import type {AccountResizeStatusForDesignPayload} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import {AccountResizeStatus} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import type {ErrorBoxInputs} from 'src/components/error-box';
import {DesignSelectorTab} from '@Components/design-selector/design-selector.types';
import {getDefaultPublishParamsForMediaType, getNonStoryPlatformMediaType, getNonStoryPublishingParams, getStoryPublishingParams} from '@Libraries/social-media';
import {getStoryGuidelineforIndex, getUnusedRandomGuidelineNumber} from '@Components/social-media-wizard/social-media-wizard-library';
import type {StoryGuideline} from '@Components/social-media-wizard/components/social-media-publish-params-story-guideline';
import {isValidURL} from '@Utils/url.util';
import {cloneDeep} from 'lodash';

type TriggerCounter = number;

export interface SocialMediaWizardState {
  post?: SocialPost;
  accounts: SocialAccount[];
  accountIdsToPublishTo: number[];
  triggerDesignMetaUpdate: number;
  commonCaption: string;
  hasCommonCaption: boolean;
  publishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>;
  accountTypeToConnect?: SocialPlatform;
  wizardStep: SocialMediaWizardStep;
  isTemplateSelected: boolean;
  accountLevelErrorMessage: ErrorBoxInputs;
  nextButtonDisabled: boolean;
  hideNextButton: boolean;
  savingPublishingParamsState: LoadingStates;
  publishingAction?: SocialPostPublishingAction;
  publishingStatusForMediaTypes: Record<SocialMediaAccountId, MediaTypeLoadingStates>;
  nextButtonText: string;
  nextButtonIcon: string;
  triggerNext: TriggerCounter;
  triggerPrevious: TriggerCounter;
  wizardMobileNavView: WizardMobileNavView;
  captionError: CaptionErrorByAccountIds;
  titleError: TitleErrorByAccountIds;
  areAccountsLoading: boolean;
  publishedAccountUrlMapForMediaType: PublishedUrlMapForAccount;
  numParentAccountsConnected: number;
  retryPublishingMapForAccount: Record<SocialMediaAccountId, RetryStatusForMediaType>;
  hasSeenOnboarding: boolean;
  hasSeenConfetti: boolean;
  hasUserPublishedBefore: boolean;
  isSocialMediaWizardAiCaptionsEnabled: boolean;
  isGoogleBusinessProfileEnabled: boolean;
  designIdsToPublish: PosterHashedId[];
  designsGraphicsMeta: Record<PosterHashedId, GraphicMeta>;
  accountNeedsResizeMapForDesigns: AccountNeedsResizeMapForDesigns;
  multiGraphicDesignSelectorMode: MultiGraphicDesignSelectorModeType;
  designSelectorTab: DesignSelectorTab;
  accountResizeStatusForMediaType: AccountResizeStatusForMediaType;
  publishableGraphicUrlsForAllMediaTypes: AccountPublishableUrlsForMediaType;
  platformSelectionForAccount: Record<SocialMediaAccountId, SocialMediaWizardMediaTypeSelection>;
  designIdToResize: string;
  storyGuidelineForAccount: Record<SocialMediaAccountId, StoryGuideline>;
  hasCommonPostCover: boolean;
  emphasizedPlatform?: SocialPlatform;
}

const initialState: SocialMediaWizardState = {
  post: undefined,
  accounts: [],
  accountIdsToPublishTo: [],
  triggerDesignMetaUpdate: 1,
  commonCaption: '',
  hasCommonCaption: true,
  publishingParams: {},
  accountTypeToConnect: undefined,
  isTemplateSelected: false,
  wizardStep: SocialMediaWizardStep.SELECT_DESIGN,
  accountLevelErrorMessage: {
    title: '',
    description: '',
  },
  nextButtonDisabled: false,
  hideNextButton: false,
  savingPublishingParamsState: LoadingStates.NOT_LOADED,
  publishingAction: undefined,
  nextButtonText: window.i18next.t('pmwjs_next'),
  nextButtonIcon: 'icon-arrow-right',
  triggerNext: 0,
  triggerPrevious: 0,
  wizardMobileNavView: WizardMobileNavView.CONTENT,
  captionError: {},
  titleError: {},
  areAccountsLoading: true,
  publishedAccountUrlMapForMediaType: {} as PublishedUrlMapForAccount,
  numParentAccountsConnected: 0,
  retryPublishingMapForAccount: {},
  hasSeenOnboarding: false,
  hasSeenConfetti: false,
  hasUserPublishedBefore: false,
  isSocialMediaWizardAiCaptionsEnabled: false,
  isGoogleBusinessProfileEnabled: false,
  designIdsToPublish: [],
  designsGraphicsMeta: {},
  accountNeedsResizeMapForDesigns: {},
  multiGraphicDesignSelectorMode: MultiGraphicDesignSelectorModes.Select,
  designSelectorTab: DesignSelectorTab.MY_DESIGNS,
  accountResizeStatusForMediaType: {},
  publishableGraphicUrlsForAllMediaTypes: {},
  publishingStatusForMediaTypes: {},
  platformSelectionForAccount: {},
  designIdToResize: '',
  storyGuidelineForAccount: {},
  hasCommonPostCover: true,
  emphasizedPlatform: undefined,
};

export const socialMediaWizardSlice = createSlice({
  name: 'socialMediaWizard',
  initialState,
  reducers: {
    updateAccountTypeToConnect: (state, {payload}: PayloadAction<SocialPlatform>) => {
      doUpdateAccountTypeToConnect(state, payload);
    },
    updateAccountIdsToPublishTo: (state, {payload}: PayloadAction<number[]>) => {
      doUpdateAccountIdsToPublishTo(state, payload);
    },
    updateIsTemplateSelected: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateIsTemplateSelected(state, payload);
    },
    updateAccountLevelErrorMessage: (state, {payload}: PayloadAction<ErrorBoxInputs>) => {
      doUpdateAccountLevelErrorMessage(state, payload);
    },
    hideAccountLevelErrorMessage: (state) => {
      doUpdateAccountLevelErrorMessage(state, {title: '', description: ''});
    },
    updatePublishingParams: (state, {payload}: PayloadAction<Record<SocialMediaAccountIdString, MediaTypePublishingParams>>) => {
      doUpdatePublishingParams(state, payload);
    },
    updateCommonCaption: (state, {payload}: PayloadAction<string>) => {
      doUpdateCommonCaption(state, payload);
    },
    updatePublishingStatusForAllAccountMediaTypes: (state, {payload}: PayloadAction<LoadingStates>) => {
      doUpdatePublishingStatusForAllAccountMediaTypes(state, payload);
    },
    updateHasSeenOnboarding: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateHasSeenOnboarding(state, payload);
    },
    updateHasSeenConfetti: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateHasSeenConfetti(state, payload);
    },
    updateHasUserPublishedBefore: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateHasUserPublishedBefore(state, payload);
    },
    updateIsSocialMediaWizardAiCaptionsEnabled: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateIsSocialMediaWizardAiCaptionsEnabled(state, payload);
    },
    updateIsGoogleBusinessProfileEnabled: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateIsGoogleBusinessProfileEnabled(state, payload);
    },
    updateHasCommonCaption: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateHasCommonCaption(state, payload);
    },
    updateEmphasizedPlatform: (state, {payload}: PayloadAction<SocialPlatform | undefined>) => {
      doUpdateEmphasizedPlatform(state, payload);
    },
    updateHasCommonPostCover: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateHasCommonPostCover(state, payload);
    },
    updateNextButtonText: (state, {payload}: PayloadAction<string>) => {
      doUpdateNextButtonText(state, payload);
    },
    updateNumParentAccountsConnected: (state, {payload}: PayloadAction<number>) => {
      doUpdateNumParentAccountsConnected(state, payload);
    },
    syncPublishingParams: (state) => {
      doSyncPublishingParams(state);
    },
    updateNextButtonIcon: (state, {payload}: PayloadAction<string>) => {
      doUpdateNextButtonIcon(state, payload);
    },
    updatePost: (state, {payload}: PayloadAction<SocialPost>) => {
      doUpdatePost(state, payload);
    },
    updateAccountNeedsResizeMapForDesigns: (state, {payload}: PayloadAction<AccountNeedsResizeMapForDesigns>) => {
      doUpdateAccountNeedsResizingMapForDesigns(state, payload);
    },
    updateWizardStep: (state, {payload}: PayloadAction<SocialMediaWizardStep>) => {
      doUpdateWizardStep(state, payload);
    },
    updateTriggerNext: (state) => {
      doUpdateTriggerNext(state);
    },
    updatePublishingStatusForAccountWithMediaType: (state, {payload}: PayloadAction<PublishingStatusForAccountPayload>) => {
      doUpdatePublishingStatusForAccountWithMediaType(state, payload);
    },
    updatePublishingParamCaption: (state, {payload}: PayloadAction<PublishingParamsCaptionPayload>) => {
      doUpdatePublishingParamCaption(state, payload);
    },
    updateSavingPublishingParamsState: (state, {payload}: PayloadAction<LoadingStates>) => {
      doUpdateSavingPublishingParamsState(state, payload);
    },
    updatePublishingParamTitle: (state, {payload}: PayloadAction<PublishingParamsTitlePayload>) => {
      doUpdatePublishingParamTitle(state, payload);
    },
    updatePublishingParamDisableComment: (state, {payload}: PayloadAction<PublishingParamsDisableComment>) => {
      doUpdatePublishingParamDisableComment(state, payload);
    },
    updatePublishingParamDisableStitch: (state, {payload}: PayloadAction<PublishingParamsDisableStitch>) => {
      doUpdatePublishingParamDisableStitch(state, payload);
    },
    updatePublishingParamDisableDuet: (state, {payload}: PayloadAction<PublishingParamsDisableDuet>) => {
      doUpdatePublishingParamDisableDuet(state, payload);
    },
    updatePublishingParamIsSelfBrand: (state, {payload}: PayloadAction<PublishingParamsIsSelfBrand>) => {
      doUpdatePublishingParamIsSelfBrand(state, payload);
    },
    updatePublishingParamIsPromotingThirdParty: (state, {payload}: PayloadAction<PublishingParamsIsPromotingThirdParty>) => {
      doUpdatePublishingParamIsPromotingThirdParty(state, payload);
    },
    updateTikTokPublishingParamPrivacySettings: (state, {payload}: PayloadAction<PublishingParamsTikTokPrivacySettingsPayload>) => {
      doUpdateTikTokPublishingParamPrivacySettings(state, payload);
    },
    updateLinkedInPublishingParamPrivacySettings: (state, {payload}: PayloadAction<PublishingParamsLinkedInPrivacySettingsPayload>) => {
      doUpdateLinkedInPublishingParamPrivacySettings(state, payload);
    },
    updateYouTubePublishingParamPrivacySettings: (state, {payload}: PayloadAction<PublishingParamsYouTubePrivacySettingsPayload>) => {
      doUpdateYouTubePublishingParamPrivacySettings(state, payload);
    },
    updateGoogleBusinessProfilePublishingParamCtaType: (state, {payload}: PayloadAction<PublishingParamsGoogleBusinessProfileCtaTypePayload>) => {
      doUpdateGoogleBusinessProfilePublishingParamCtaType(state, payload);
    },
    updateGoogleBusinessProfilePublishingParamPostType: (state, {payload}: PayloadAction<PublishingParamsGoogleBusinessProfilePostTypePayload>) => {
      doUpdateGoogleBusinessProfilePublishingParamPostType(state, payload);
    },
    updatePublishingParamAllCaptions: (state, {payload}: PayloadAction<PublishingParamsCommonCaptionPayload>) => {
      doUpdatePublishingParamAllCaptions(state, payload);
    },
    updatePublishingParamSocialPostCover: (state, {payload}: PayloadAction<PublishingParamsSocialPostCoverPayload>) => {
      doUpdatePublishingParamSocialPostCover(state, payload);
    },
    updatePublishingParamAllSocialPostCovers: (state, {payload}: PayloadAction<PublishingParamsCommonPostCoverPayload>) => {
      doUpdatePublishingParamAllSocialPostCovers(state, payload);
    },
    resetSocialPostCoverForAllPublishingParams: (state) => {
      doResetSocialPostCoverForAllPublishingParams(state);
    },
    updatePublishingAction: (state, {payload}: PayloadAction<SocialPostPublishingAction | undefined>) => {
      doUpdatePublishingAction(state, payload);
    },
    updateWizardSocialAccount: (state, {payload}: PayloadAction<SocialAccount>) => {
      doUpdateWizardSocialAccount(state, payload);
    },
    disableNextButton: (state) => {
      doDisableNextButton(state);
    },
    enableNextButton: (state) => {
      doEnableNextButton(state);
    },
    hideNextButton: (state) => {
      doHideNextButton(state);
    },
    showNextButton: (state) => {
      doShowNextButton(state);
    },
    updateTriggerPrevious: (state) => {
      doUpdateTriggerPrevious(state);
    },
    initPublishingParams: (state) => {
      const userAccountIds = state.accountIdsToPublishTo;

      userAccountIds.forEach((idSocialMediaAccount) => {
        setEmptyStatePublishingParamsIfNeeded(state, idSocialMediaAccount);
      });

      initTitleErrors(state);
      initCaptionErrors(state);
      initGraphicsMeta(state);
      initPublishableUrls(state);
      doInitializePublishUrlMapForAccounts(state);
      doInitializePublishStatusForAllAccounts(state);
      doInitializeRetryPublishingMapForAccounts(state);
      doInitializePlatformMediaTypeMap(state);
      initStoryGuidelines(state);
    },
    removeAccountIdFromPublishing: (state, {payload}: PayloadAction<number>) => {
      doRemoveAccountIdFromPublishing(state, payload);
    },
    addAccountIdForPublishing: (state, {payload}: PayloadAction<number>) => {
      doAddAccountIdForPublishing(state, payload);
    },
    setWizardMobileNavView: (state, {payload}: PayloadAction<WizardMobileNavView>) => {
      doUpdateWizardMobileNavView(state, payload);
    },
    setAccountResizeStatusForMediaType: (state, {payload}: PayloadAction<AccountResizeStatusForDesignPayload>) => {
      doSetAccountResizeStatusForMediaType(state, payload);
    },
    resetAccountResizeStatusForDesignsMap: (state): void => {
      doResetAccountResizeStatusForMediaType(state);
    },
    initRetryPublishingMapForAccount: (state): void => {
      doInitializeRetryPublishingMapForAccounts(state);
    },
    initPublishingStatusMapForAccounts: (state): void => {
      doInitializePublishStatusForAllAccounts(state);
    },
    initPublishedUrlMapForAccounts: (state): void => {
      doInitializePublishUrlMapForAccounts(state);
    },
    setRetryingStatusForAccountWithMediaType: (state, {payload}: PayloadAction<RetryStatusUpdatePayload>) => {
      doSetRetryingStatusForAccountWithMediaType(state, payload.accountId, payload.platformMediaType);
    },
    resetStateToDraft: (state) => {
      doResetStateToDraft(state);
    },
    updateAreAccountsLoading: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateAreAccountsLoading(state, payload);
    },
    unsetRetryingStatusForAccountWithMediaType: (state, {payload}: PayloadAction<RetryStatusUpdatePayload>) => {
      doUnsetRetryingStatusForAccountWithMediaType(state, payload.accountId, payload.platformMediaType);
    },
    updatePublishErrorMessageForAccount: (state, {payload}: PayloadAction<PublishingParamsPublishErrorMessagePayload>) => {
      doUpdatePublishErrorMessageForAccount(state, payload);
    },
    updatePublishErrorMessageForAllParams: (state, {payload}: PayloadAction<string>) => {
      doUpdatePublishErrorMessageForAllParams(state, payload);
    },
    updateDesignIdsToPublish: (state, {payload}: PayloadAction<string[]>) => {
      state.designIdsToPublish = payload;
    },
    updateDesignIdsToPublishAndTriggerNext: (state, {payload}: PayloadAction<string[]>) => {
      state.designIdsToPublish = payload;
      doUpdateTriggerNext(state);
    },
    updatePublishedAccountPostUrlMapForAccountWithMediaType: (state, {payload}: PayloadAction<PublishedUrlUpdatePayload>) => {
      doUpdatePublishedAccountUrlForMediaType(state, payload);
    },
    updateDesignsGraphicsMeta: (state, {payload}: PayloadAction<DesignsGraphicMetaPayload[]>) => {
      doUpdateDesignsGraphicsMeta(state, payload);
    },
    updateMultiGraphicDesignSelectorMode: (state, {payload}: PayloadAction<MultiGraphicDesignSelectorModeType>) => {
      state.multiGraphicDesignSelectorMode = payload;
    },
    toggleToPostOnly: (state, {payload}: PayloadAction<number>) => {
      const socialPostCover = getPosterCoverCopyFromPublishParams(state, state.publishingParams[payload].REELS);

      state.publishingParams[payload].STORIES = null;
      state.publishingParams[payload].REELS = null;
      doSetPublishToPost(state, payload, socialPostCover);
    },
    toggleToReelOnly: (state, {payload}: PayloadAction<number>) => {
      const socialPostCover = getPosterCoverCopyFromPublishParams(state, state.publishingParams[payload].POST);

      state.publishingParams[payload].STORIES = null;
      state.publishingParams[payload].POST = null;
      doSetPublishToReel(state, payload, socialPostCover);
    },
    toggleToStoryOnly: (state, {payload}: PayloadAction<number>) => {
      state.publishingParams[payload].POST = null;
      state.publishingParams[payload].REELS = null;
      doSetPublishToStory(state, payload);
    },
    setPublishToPost: (state, {payload}: PayloadAction<number>) => {
      doSetPublishToPost(state, payload);
    },
    setPublishToReel: (state, {payload}: PayloadAction<number>) => {
      doSetPublishToReel(state, payload);
    },
    setPublishToStory: (state, {payload}: PayloadAction<number>) => {
      doSetPublishToStory(state, payload);
    },
    unsetPublishToPost: (state, {payload}: PayloadAction<number>) => {
      state.publishingParams[payload].POST = null;
    },
    unsetPublishToStory: (state, {payload}: PayloadAction<number>) => {
      state.publishingParams[payload].STORIES = null;
    },
    unsetPublishToReel: (state, {payload}: PayloadAction<number>) => {
      state.publishingParams[payload].REELS = null;
    },
    updatePinterestPublishingParamsBoardId: (state, {payload}: PayloadAction<PublishingParamsBoardIdPayload>) => {
      doUpdatePinterestBoardIdForAccount(state, payload);
    },
    updatePinterestPublishingParamsWebsiteLink: (state, {payload}: PayloadAction<PublishingParamsWebsiteLinkPayload>) => {
      doUpdatePinterestWebsiteLinkForAccount(state, payload);
    },
    syncEditedDesignId: (state, {payload}: PayloadAction<PosterHashedId>): void => {
      doSyncEditedDesignId(state, payload);
    },
    updateDesignSelectorTab: (state: SocialMediaWizardState, {payload}: PayloadAction<DesignSelectorTab>): void => {
      state.designSelectorTab = payload;
    },
    updateGraphicURLInGraphicsMetaForDesign: (state: SocialMediaWizardState, {payload}: PayloadAction<DesignsGraphicMetaUpdatePayload>) => {
      doUpdateGraphicURLForGraphicsMeta(state, payload);
    },
    updatePublishableUrl: (state: SocialMediaWizardState, {payload}: PayloadAction<PublishableUrlUpdatePayload>) => {
      doUpdatePublishableUrl(state, payload);
    },
    setMediaTypeSelectionToPost: (state: SocialMediaWizardState, {payload}: PayloadAction<number>) => {
      state.platformSelectionForAccount[payload] = SocialMediaWizardMediaTypeSelection.POST;
    },
    setMediaTypeSelectionToStory: (state: SocialMediaWizardState, {payload}: PayloadAction<number>) => {
      state.platformSelectionForAccount[payload] = SocialMediaWizardMediaTypeSelection.STORY;
    },
    setMediaTypeSelectionToPostAndStory: (state: SocialMediaWizardState, {payload}: PayloadAction<number>) => {
      state.platformSelectionForAccount[payload] = SocialMediaWizardMediaTypeSelection.POST_AND_STORY;
    },
    setMediaTypeSelectionToReel: (state: SocialMediaWizardState, {payload}: PayloadAction<number>) => {
      state.platformSelectionForAccount[payload] = SocialMediaWizardMediaTypeSelection.REEL;
    },
    setMediaTypeSelectionToReelAndStory: (state: SocialMediaWizardState, {payload}: PayloadAction<number>) => {
      state.platformSelectionForAccount[payload] = SocialMediaWizardMediaTypeSelection.REEL_AND_STORY;
    },
    updateDesignIdToResize: (state, {payload}: PayloadAction<PosterHashedId>) => {
      state.designIdToResize = payload;
    },
  },
  extraReducers: socialMediaWizardExtraReducers,
});

const doSetPublishToPost = (state: SocialMediaWizardState, accountId: number, socialPostCover: SocialPostCover | undefined = undefined): void => {
  const matchedAccount = getAccountForId(state.accounts, accountId);

  if (!matchedAccount) {
    console.error('No matched account found');
    return;
  }

  const emptyPostParams = getEmptyPublishingParamsForAccount(matchedAccount);

  if (!emptyPostParams) {
    return;
  }

  if (state.hasCommonCaption) {
    emptyPostParams.caption = state.commonCaption;
  }

  const newGraphicsMeta: Record<string, GraphicMeta> = {};
  const posterIds = state.designIdsToPublish;

  for (let i = 0; i < posterIds.length; i++) {
    newGraphicsMeta[posterIds[i]] = {
      isVideo: state.designsGraphicsMeta[posterIds[i]].isVideo,
      graphicURL: state.publishableGraphicUrlsForAllMediaTypes[accountId][PlatformMediaType.POST][posterIds[i]],
      name: state.designsGraphicsMeta[posterIds[i]].name,
    };
  }

  emptyPostParams.graphicsMeta = newGraphicsMeta;

  if (isSingleGraphicPost(state)) {
    setPostCoverInPublishParamsIfNeeded(socialPostCover, emptyPostParams, state.designIdsToPublish[0]);
  }

  state.publishingParams[accountId] = {
    ...state.publishingParams[accountId],
    [PlatformMediaType.POST]: emptyPostParams,
    [PlatformMediaType.REELS]: null,
  };
};

const doSetPublishToReel = (state: SocialMediaWizardState, accountId: number, socialPostCover: SocialPostCover | undefined = undefined): void => {
  const matchedAccount = getAccountForId(state.accounts, accountId);

  if (!matchedAccount) {
    console.error('No matched account found');
    return;
  }

  const emptyPostParams = getEmptyPublishingParamsForAccount(matchedAccount);

  if (!emptyPostParams) {
    return;
  }

  if (state.hasCommonCaption) {
    emptyPostParams.caption = state.commonCaption;
  }

  const newGraphicsMeta: Record<string, GraphicMeta> = {};
  const posterIds = state.designIdsToPublish;

  for (let i = 0; i < posterIds.length; i++) {
    newGraphicsMeta[posterIds[i]] = {
      isVideo: state.designsGraphicsMeta[posterIds[i]].isVideo,
      graphicURL: state.publishableGraphicUrlsForAllMediaTypes[accountId][PlatformMediaType.REELS][posterIds[i]],
      name: state.designsGraphicsMeta[posterIds[i]].name,
    };
  }

  emptyPostParams.graphicsMeta = newGraphicsMeta;

  if (isSingleGraphicPost(state)) {
    setPostCoverInPublishParamsIfNeeded(socialPostCover, emptyPostParams, state.designIdsToPublish[0]);
  }

  state.publishingParams[accountId] = {
    ...state.publishingParams[accountId],
    [PlatformMediaType.REELS]: emptyPostParams,
    [PlatformMediaType.POST]: null,
  };
};

const doSetPublishToStory = (state: SocialMediaWizardState, accountId: number): void => {
  const matchedAccount = getAccountForId(state.accounts, accountId);

  if (!matchedAccount) {
    console.error('No matched account found');
    return;
  }

  const emptyPostParams = getEmptyPublishingParamsForAccount(matchedAccount);

  if (!emptyPostParams) {
    return;
  }

  const newGraphicsMeta: Record<string, GraphicMeta> = {};
  const posterIds = state.designIdsToPublish;

  for (let i = 0; i < posterIds.length; i++) {
    newGraphicsMeta[posterIds[i]] = {
      isVideo: state.designsGraphicsMeta[posterIds[i]].isVideo,
      graphicURL: state.publishableGraphicUrlsForAllMediaTypes[accountId][PlatformMediaType.STORIES][posterIds[i]],
      name: state.designsGraphicsMeta[posterIds[i]].name,
    };
  }

  emptyPostParams.graphicsMeta = newGraphicsMeta;

  // get the graphic url from publishable url
  state.publishingParams[accountId] = {
    ...state.publishingParams[accountId],
    [PlatformMediaType.STORIES]: emptyPostParams,
  };
};

const doUpdatePublishableUrl = (state: SocialMediaWizardState, payload: PublishableUrlUpdatePayload): void => {
  const {accountId} = payload;
  const {posterId} = payload;
  const {platformMediaType} = payload;
  const {graphicUrl} = payload;

  state.publishableGraphicUrlsForAllMediaTypes[accountId][platformMediaType][posterId] = graphicUrl;
};

const doSyncEditedDesignId = (state: SocialMediaWizardState, posterHashedIdEdited: PosterHashedId): void => {
  const oldDesignId = state.designIdsToPublish[0] ?? null;

  if (!oldDesignId) {
    console.error('No design ID found');
    return;
  }

  replacePosterHashedIdInDesignIds(state, posterHashedIdEdited);
  replacePosterHashedIdInGraphicsMeta(state, oldDesignId, posterHashedIdEdited);
  doResetAccountResizeStatusForMediaType(state);
};

const replacePosterHashedIdInGraphicsMeta = (state: SocialMediaWizardState, oldDesignId: string, newDesignId: string): void => {
  const graphicsMeta = state.designsGraphicsMeta[oldDesignId];

  if (!graphicsMeta) {
    console.log('Graphic meta not found for design with ID ', oldDesignId);
  }

  state.designsGraphicsMeta = {
    [newDesignId]: graphicsMeta,
  };
};

const replacePosterHashedIdInDesignIds = (state: SocialMediaWizardState, newDesignId: string): void => {
  state.designIdsToPublish = [newDesignId];
};

const doUpdateGraphicURLForGraphicsMeta = (state: SocialMediaWizardState, payload: DesignsGraphicMetaUpdatePayload): void => {
  const {idSocialMediaAccount} = payload;
  const {mediaType} = payload;
  const {designId} = payload;

  const mediaTypePublishingParams = state.publishingParams[idSocialMediaAccount];

  if (!mediaTypePublishingParams[mediaType]) {
    console.error('No publishing params for Media type found');
    return;
  }

  const publishingParamsForMediaType = mediaTypePublishingParams[mediaType];

  if (!publishingParamsForMediaType) {
    console.error('No publishing params for Media type found');
    return;
  }

  publishingParamsForMediaType.graphicsMeta[designId].graphicURL = payload.previewURL;
};

const initPublishableUrls = (state: SocialMediaWizardState): void => {
  const connectedAccounts = state.accounts;
  const publishableUrlsForMediaType: AccountPublishableUrlsForMediaType = {};
  const designIdsToPublishTo = state.designIdsToPublish;
  const mapForDesignIds: PublishableUrlForDesign = {};

  for (let i = 0; i < designIdsToPublishTo.length; i++) {
    mapForDesignIds[designIdsToPublishTo[i]] = state.designsGraphicsMeta[designIdsToPublishTo[i]].graphicURL;
  }

  for (let i = 0; i < connectedAccounts.length; i++) {
    publishableUrlsForMediaType[connectedAccounts[i].id] = {
      [PlatformMediaType.POST]: mapForDesignIds,
      [PlatformMediaType.STORIES]: mapForDesignIds,
      [PlatformMediaType.REELS]: mapForDesignIds,
    };
  }

  state.publishableGraphicUrlsForAllMediaTypes = publishableUrlsForMediaType;
};

const doUpdateDesignsGraphicsMeta = (state: SocialMediaWizardState, payload: DesignsGraphicMetaPayload[]): void => {
  const updatedDesignsMeta: Record<PosterHashedId, GraphicMeta> = {};
  payload.forEach((item) => {
    updatedDesignsMeta[item.designId] = {
      isVideo: item.isVideo,
      graphicURL: item.previewURL,
      name: item.name,
    };
  });

  state.designsGraphicsMeta = updatedDesignsMeta;
  initGraphicsMeta(state);
};

const doUpdateSavingPublishingParamsState = (state: SocialMediaWizardState, payload: LoadingStates): void => {
  state.savingPublishingParamsState = payload;
};

const doInitializePublishUrlMapForAccounts = (state: SocialMediaWizardState): void => {
  const accountsToPublishTo = state.accountIdsToPublishTo;

  for (let i = 0; i < accountsToPublishTo.length; i++) {
    const publishParams = state.publishingParams[accountsToPublishTo[i]];

    if (!publishParams) {
      return;
    }

    const storyParams = publishParams[PlatformMediaType.STORIES];
    const reelParams = publishParams[PlatformMediaType.REELS];
    const postParams = publishParams[PlatformMediaType.POST];

    state.publishedAccountUrlMapForMediaType[accountsToPublishTo[i]] = {
      [PlatformMediaType.POST]: postParams ? '' : null,
      [PlatformMediaType.REELS]: reelParams ? '' : null,
      [PlatformMediaType.STORIES]: storyParams ? '' : null,
    };
  }
};

const doInitializePublishStatusForAllAccounts = (state: SocialMediaWizardState): void => {
  const accountsToPublishTo = state.accountIdsToPublishTo;

  for (let i = 0; i < accountsToPublishTo.length; i++) {
    const publishParams = state.publishingParams[accountsToPublishTo[i]];
    const storyParams = publishParams[PlatformMediaType.STORIES];
    const reelParams = publishParams[PlatformMediaType.REELS];
    const postParams = publishParams[PlatformMediaType.POST];

    state.publishingStatusForMediaTypes[accountsToPublishTo[i]] = {
      [PlatformMediaType.POST]: postParams ? LoadingStates.LOADING : LoadingStates.LOADED,
      [PlatformMediaType.REELS]: reelParams ? LoadingStates.LOADING : LoadingStates.LOADED,
      [PlatformMediaType.STORIES]: storyParams ? LoadingStates.LOADING : LoadingStates.LOADED,
    };
  }
};

const doInitializePlatformMediaTypeMap = (state: SocialMediaWizardState): void => {
  const accountsToPublishTo = state.accountIdsToPublishTo;

  for (let i = 0; i < accountsToPublishTo.length; i++) {
    const storyExists = state.publishingParams[accountsToPublishTo[i]].STORIES;
    const postExists = state.publishingParams[accountsToPublishTo[i]].POST;
    const reelExists = state.publishingParams[accountsToPublishTo[i]].REELS;
    let defaultType = SocialMediaWizardMediaTypeSelection.POST;

    if (storyExists) {
      if (reelExists) {
        defaultType = SocialMediaWizardMediaTypeSelection.REEL_AND_STORY;
      } else if (postExists) {
        defaultType = SocialMediaWizardMediaTypeSelection.POST_AND_STORY;
      } else {
        defaultType = SocialMediaWizardMediaTypeSelection.STORY;
      }
    } else if (reelExists) {
      defaultType = SocialMediaWizardMediaTypeSelection.REEL;
    }

    state.platformSelectionForAccount[accountsToPublishTo[i]] = defaultType;
  }
};

const doInitializeRetryPublishingMapForAccounts = (state: SocialMediaWizardState): void => {
  const accountsToPublishTo = state.accountIdsToPublishTo;

  for (let i = 0; i < accountsToPublishTo.length; i++) {
    const publishParams = state.publishingParams[accountsToPublishTo[i]];
    const storyParams = publishParams[PlatformMediaType.STORIES];
    const reelParams = publishParams[PlatformMediaType.REELS];
    const postParams = publishParams[PlatformMediaType.POST];

    state.retryPublishingMapForAccount[accountsToPublishTo[i]] = {
      [PlatformMediaType.POST]: postParams ? RetryStatus.NOT_RETRYING : null,
      [PlatformMediaType.REELS]: reelParams ? RetryStatus.NOT_RETRYING : null,
      [PlatformMediaType.STORIES]: storyParams ? RetryStatus.NOT_RETRYING : null,
    };
  }
};

const doUpdateHasSeenOnboarding = (state: SocialMediaWizardState, payload: boolean): void => {
  state.hasSeenOnboarding = payload;
};

const doUpdateHasSeenConfetti = (state: SocialMediaWizardState, payload: boolean): void => {
  state.hasSeenConfetti = payload;
};

const doUpdateHasUserPublishedBefore = (state: SocialMediaWizardState, payload: boolean): void => {
  state.hasUserPublishedBefore = payload;
};

const doUpdateIsSocialMediaWizardAiCaptionsEnabled = (state: SocialMediaWizardState, payload: boolean): void => {
  state.isSocialMediaWizardAiCaptionsEnabled = payload;
};

const doUpdateIsGoogleBusinessProfileEnabled = (state: SocialMediaWizardState, payload: boolean): void => {
  state.isGoogleBusinessProfileEnabled = payload;
};

const doSetRetryingStatusForAccountWithMediaType = (state: SocialMediaWizardState, socialMediaAccountId: SocialMediaAccountId, platformMediaType: PlatformMediaType): void => {
  state.retryPublishingMapForAccount[socialMediaAccountId][platformMediaType] = RetryStatus.RETRYING;
};

const doResetStateToDraft = (state: SocialMediaWizardState): void => {
  doUpdatePublishingAction(state, undefined);
  doUpdatePublishingStatusForAllAccountMediaTypes(state, LoadingStates.LOADING);
  doUpdateSavingPublishingParamsState(state, LoadingStates.LOADING);
  doInitializePublishUrlMapForAccounts(state);
};

const doUnsetRetryingStatusForAccountWithMediaType = (state: SocialMediaWizardState, socialMediaAccountId: SocialMediaAccountId, platformMediaType: PlatformMediaType): void => {
  state.retryPublishingMapForAccount[socialMediaAccountId][platformMediaType] = RetryStatus.NOT_RETRYING;
};

const doUpdatePublishingParamsProperty = (
  state: SocialMediaWizardState,
  idSocialMediaAccount: SocialMediaAccountId,
  paramsUpdateFunc: (publishingParams: SocialMediaPublishingParams) => void,
  platformMediaType: PlatformMediaType
): void => {
  const idSocialMediaAccountStr = idSocialMediaAccount;
  const matchedAccount = getAccountForId(state.accounts, idSocialMediaAccount);

  if (matchedAccount && typeof state.publishingParams[idSocialMediaAccountStr] !== 'undefined') {
    const oldPublishingParams: SocialMediaPublishingParams | null = state.publishingParams[idSocialMediaAccountStr][platformMediaType];

    if (!oldPublishingParams) {
      return;
    }

    paramsUpdateFunc(oldPublishingParams);

    state.publishingParams[idSocialMediaAccountStr][platformMediaType] = oldPublishingParams;
  }
};

const doUpdatePublishingStatusForAllAccountMediaTypes = (state: SocialMediaWizardState, payload: LoadingStates): void => {
  const accountIds = state.accountIdsToPublishTo;
  const updatedPublishingStatuses: Record<SocialMediaAccountId, MediaTypeLoadingStates> = {};

  accountIds.forEach((accountId) => {
    updatedPublishingStatuses[accountId] = {
      [PlatformMediaType.POST]: payload,
      [PlatformMediaType.REELS]: payload,
      [PlatformMediaType.STORIES]: payload,
    };
  });

  state.publishingStatusForMediaTypes = updatedPublishingStatuses;
};

const doUpdateWizardMobileNavView = (state: SocialMediaWizardState, payload: WizardMobileNavView): void => {
  state.wizardMobileNavView = payload;
};

const doSyncPublishingParams = (state: SocialMediaWizardState): void => {
  const accountsToPublishTo = state.accountIdsToPublishTo;
  const currentPublishingParams = state.publishingParams;
  const updatedPublishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams> = {};

  accountsToPublishTo.forEach((accountId) => {
    if (typeof currentPublishingParams[accountId] === 'undefined') {
      setEmptyStatePublishingParamsIfNeeded(state, accountId);
      initGraphicsMeta(state);
    } else if (currentPublishingParams[accountId]) {
      updatedPublishingParams[accountId] = {
        [PlatformMediaType.STORIES]: currentPublishingParams[accountId].STORIES,
        [PlatformMediaType.POST]: currentPublishingParams[accountId].POST,
        [PlatformMediaType.REELS]: currentPublishingParams[accountId].REELS,
      };
    }
  });

  state.publishingParams = updatedPublishingParams;
};

const doUpdatePublishingStatusForAccountWithMediaType = (state: SocialMediaWizardState, payload: PublishingStatusForAccountPayload): void => {
  const mediaType = payload.platformMediaType;
  const accountId = payload.idSocialMediaAccount;

  const {publishingStatusForMediaTypes} = state;
  publishingStatusForMediaTypes[accountId][mediaType] = payload.publishingStatus;
};

const doUpdatePublishingAction = (state: SocialMediaWizardState, payload: SocialPostPublishingAction | undefined): void => {
  state.publishingAction = payload;
};

const isCountValid = (caption: string, account: SocialAccount): boolean => {
  const characterLimit = getCharacterLimitForAccountPost(account);

  return caption.length <= characterLimit;
};

const isTwitterHashtagCountValid = (caption: string, accountType: SocialPlatform): boolean => {
  return accountType === SocialPlatform.TWITTER ? countHashtags(caption) <= 30 : true;
};

const isCaptionValid = (caption: string, account: SocialAccount): boolean => {
  switch (account.type) {
    case SocialPlatform.TWITTER:
      return isCountValid(caption, account) && isTwitterHashtagCountValid(caption, account.type);
    default:
      return isCountValid(caption, account);
  }
};

const getTitleErrorIfTitleIsInvalid = (title: string, accountType: SocialPlatform, areUrlsAllowed: boolean): string | null => {
  if (!areUrlsAllowed && isValidURL(title)) {
    return window.i18next.t('pmwjs_title_url_error');
  }

  const currentTitleValidation = titleValidations[accountType];

  if (currentTitleValidation.limit && title.length > currentTitleValidation.limit) {
    return window.i18next.t('pmwjs_caption_long_error', {limit: currentTitleValidation.limit});
  }

  return null;
};

const doResetTitleErrorForAccount = (account: SocialAccount, state: SocialMediaWizardState): void => {
  if (state.titleError && state.titleError[account.id] !== undefined) {
    state.titleError[account.id] = null;
  }
};

const doSetTitleErrorForAccount = (account: SocialAccount, state: SocialMediaWizardState, titleError: string): void => {
  if (state.titleError && state.titleError[account.id] !== undefined) {
    state.titleError[account.id] = titleError;
  }
};

const doResetCaptionErrorForAccount = (account: SocialAccount, state: SocialMediaWizardState): void => {
  if (state.captionError && state.captionError[account.id] !== undefined) {
    state.captionError[account.id] = CaptionErrorTypes.NO_ERROR;
  }
};

const doSetCaptionCountErrorForAccount = (account: SocialAccount, state: SocialMediaWizardState): void => {
  if (state.captionError && state.captionError[account.id] !== undefined) {
    state.captionError[account.id] = CaptionErrorTypes.COUNT_ERROR;
  }
};

const doSetCaptionHashtagErrorForAccount = (account: SocialAccount, state: SocialMediaWizardState): void => {
  if (state.captionError && state.captionError[account.id] !== undefined) {
    state.captionError[account.id] = CaptionErrorTypes.HASHTAG_ERROR;
  }
};

const doUpdatePublishingParamCaption = (state: SocialMediaWizardState, payload: PublishingParamsCaptionPayload): void => {
  const {caption} = payload;
  const account = getAccountForId(state.accounts, payload.idSocialMediaAccount);

  if (!account) {
    return;
  }

  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('caption' in publishingParams) {
        publishingParams.caption = payload.caption;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );

  if (isCaptionValid(caption, account)) {
    doResetCaptionErrorForAccount(account, state);
  } else {
    if (!isCountValid(caption, account)) {
      doSetCaptionCountErrorForAccount(account, state);
      return;
    }

    doSetCaptionHashtagErrorForAccount(account, state);
  }
};

const doUpdatePublishingParamTitle = (state: SocialMediaWizardState, payload: PublishingParamsTitlePayload): void => {
  const account = getAccountForId(state.accounts, payload.idSocialMediaAccount);

  if (!account) {
    return;
  }

  const accountType = account.type;
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('title' in publishingParams) {
        publishingParams.title = payload.title;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );

  const titleError = getTitleErrorIfTitleIsInvalid(payload.title, accountType, payload.areURLsAllowed);

  if (titleError) {
    doSetTitleErrorForAccount(account, state, titleError);
  } else {
    doResetTitleErrorForAccount(account, state);
  }
};

const doUpdatePublishingParamDisableComment = (state: SocialMediaWizardState, payload: PublishingParamsDisableComment): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('disableComment' in publishingParams) {
        publishingParams.disableComment = payload.disableComment;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePublishingParamDisableStitch = (state: SocialMediaWizardState, payload: PublishingParamsDisableStitch): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('disableStitch' in publishingParams) {
        publishingParams.disableStitch = payload.disableStitch;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

export const doUpdateAreAccountsLoading = (state: SocialMediaWizardState, payload: boolean): void => {
  state.areAccountsLoading = payload;
};

const doUpdatePublishingParamDisableDuet = (state: SocialMediaWizardState, payload: PublishingParamsDisableDuet): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('disableDuet' in publishingParams) {
        publishingParams.disableDuet = payload.disableDuet;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePublishingParamIsPromotingThirdParty = (state: SocialMediaWizardState, payload: PublishingParamsIsPromotingThirdParty): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('isPromotingThirdParty' in publishingParams) {
        publishingParams.isPromotingThirdParty = payload.isPromotingThirdParty;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePublishingParamIsSelfBrand = (state: SocialMediaWizardState, payload: PublishingParamsIsSelfBrand): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('isSelfBrand' in publishingParams) {
        publishingParams.isSelfBrand = payload.isSelfBrand;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdateLinkedInPublishingParamPrivacySettings = (state: SocialMediaWizardState, payload: PublishingParamsLinkedInPrivacySettingsPayload): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('privacySettings' in publishingParams) {
        publishingParams.privacySettings = payload.privacySettings;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePublishErrorMessageForAllParams = (state: SocialMediaWizardState, payload: string): void => {
  const accountIdsToPublish = state.accountIdsToPublishTo;

  for (let i = 0; i < accountIdsToPublish.length; i++) {
    const accountId = accountIdsToPublish[i];
    const postPublishingParams = state.post?.publishingParams[accountId][PlatformMediaType.POST];
    const reelPublishingParams = state.post?.publishingParams[accountId][PlatformMediaType.REELS];
    const storyPublishingParams = state.post?.publishingParams[accountId][PlatformMediaType.STORIES];

    if (postPublishingParams) {
      const postUpdatePayload: PublishingParamsPublishErrorMessagePayload = {
        publishErrorMessage: payload,
        idSocialMediaAccount: accountId,
        platformMediaType: PlatformMediaType.POST,
      };

      doUpdatePublishErrorMessageForAccount(state, postUpdatePayload);
    }

    if (reelPublishingParams) {
      const reelUpdatePayload: PublishingParamsPublishErrorMessagePayload = {
        publishErrorMessage: payload,
        idSocialMediaAccount: accountId,
        platformMediaType: PlatformMediaType.REELS,
      };

      doUpdatePublishErrorMessageForAccount(state, reelUpdatePayload);
    }

    if (storyPublishingParams) {
      const storyUpdatePayload: PublishingParamsPublishErrorMessagePayload = {
        publishErrorMessage: payload,
        idSocialMediaAccount: accountId,
        platformMediaType: PlatformMediaType.STORIES,
      };

      doUpdatePublishErrorMessageForAccount(state, storyUpdatePayload);
    }
  }
};

const doUpdatePublishErrorMessageForAccount = (state: SocialMediaWizardState, payload: PublishingParamsPublishErrorMessagePayload): void => {
  const {platformMediaType} = payload;

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('publishErrorMessage' in publishingParams) {
        publishingParams.publishErrorMessage = payload.publishErrorMessage;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePinterestBoardIdForAccount = (state: SocialMediaWizardState, payload: PublishingParamsBoardIdPayload): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('boardId' in publishingParams) {
        publishingParams.boardId = payload.boardId;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePinterestWebsiteLinkForAccount = (state: SocialMediaWizardState, payload: PublishingParamsWebsiteLinkPayload): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('websiteLink' in publishingParams) {
        publishingParams.websiteLink = payload.websiteLink;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdateYouTubePublishingParamPrivacySettings = (state: SocialMediaWizardState, payload: PublishingParamsYouTubePrivacySettingsPayload): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('privacySettings' in publishingParams) {
        publishingParams.privacySettings = payload.privacySettings;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdateTikTokPublishingParamPrivacySettings = (state: SocialMediaWizardState, payload: PublishingParamsTikTokPrivacySettingsPayload): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('privacySettings' in publishingParams) {
        publishingParams.privacySettings = payload.privacySettings;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePublishingParamAllCaptions = (state: SocialMediaWizardState, commonCaptionPayload: PublishingParamsCommonCaptionPayload): void => {
  const {caption} = commonCaptionPayload;
  const {isMultiCharacterChange} = commonCaptionPayload;

  state.accountIdsToPublishTo.forEach((accountId) => {
    doUpdatePublishingParamCaption(state, {caption, idSocialMediaAccount: accountId, isMultiCharacterChange});
  });
};

const doUpdatePublishingParamSocialPostCover = (state: SocialMediaWizardState, socialPostCoverPayload: PublishingParamsSocialPostCoverPayload): void => {
  const {platformMediaType, idAccount, idDesign, coverObject} = socialPostCoverPayload;

  if (state.hasCommonPostCover) {
    doUpdatePublishingParamAllSocialPostCovers(state, {idDesign, coverObject});
  } else {
    setPostCoverInPublishParamsIfNeeded(coverObject, state.publishingParams[idAccount][platformMediaType], idDesign);
  }
};

const doUpdatePublishingParamAllSocialPostCovers = (state: SocialMediaWizardState, commonPostCoverPayload: PublishingParamsCommonPostCoverPayload): void => {
  const {idDesign, coverObject} = commonPostCoverPayload;

  const accountsToAddCoverTo = getAccountsToAddCommonCoverTo(state);

  accountsToAddCoverTo.forEach((account) => {
    const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[account.id]);
    if (!platformMediaType) {
      return;
    }

    if (isCoverGeneratedThroughUpload(coverObject) && !socialAccountPostCoverValidations[account.type].isUploadAllowed) {
      return;
    }

    setPostCoverInPublishParamsIfNeeded(coverObject, state.publishingParams[account.id][platformMediaType], idDesign);
  });
};

const doResetSocialPostCoverForAllPublishingParams = (state: SocialMediaWizardState): void => {
  state.accountIdsToPublishTo.forEach((accountId) => {
    const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[accountId]);
    if (!platformMediaType) {
      return;
    }

    const currentPublishingParams = state.publishingParams[accountId][platformMediaType];
    if (!currentPublishingParams) {
      return;
    }

    const graphicsMetaEntries = Object.entries(currentPublishingParams.graphicsMeta);
    const isSingleGraphic = graphicsMetaEntries.length === 1

    if (!isSingleGraphic) {
      return;
    }

    const [designId, graphicsMeta] = graphicsMetaEntries[0];
    currentPublishingParams.graphicsMeta[designId] = {
      ...graphicsMeta,
      cover: undefined,
    };
  });
}

const doUpdateGoogleBusinessProfilePublishingParamCtaType = (state: SocialMediaWizardState, payload: PublishingParamsGoogleBusinessProfileCtaTypePayload): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('ctaType' in publishingParams) {
        publishingParams.ctaType = payload.ctaType;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdateGoogleBusinessProfilePublishingParamPostType = (state: SocialMediaWizardState, payload: PublishingParamsGoogleBusinessProfilePostTypePayload): void => {
  const platformMediaType = getNonStoryPlatformMediaType(state.publishingParams[payload.idSocialMediaAccount]);

  if (!platformMediaType) {
    return;
  }

  doUpdatePublishingParamsProperty(
    state,
    payload.idSocialMediaAccount,
    (publishingParams) => {
      if ('postType' in publishingParams) {
        publishingParams.postType = payload.postType;
      }

      state.publishingParams[payload.idSocialMediaAccount][platformMediaType] = publishingParams;
    },
    platformMediaType
  );
};

const doUpdatePost = (state: SocialMediaWizardState, payload: SocialPost): void => {
  state.post = payload;
};

const doUpdateAccountNeedsResizingMapForDesigns = (state: SocialMediaWizardState, payload: AccountNeedsResizeMapForDesigns): void => {
  state.accountNeedsResizeMapForDesigns = payload;
};

const doUpdateCommonCaption = (state: SocialMediaWizardState, payload: string): void => {
  state.commonCaption = payload;
};

const doUpdateHasCommonCaption = (state: SocialMediaWizardState, payload: boolean): void => {
  state.hasCommonCaption = payload;
};

const doUpdateEmphasizedPlatform = (state: SocialMediaWizardState, payload: SocialPlatform | undefined): void => {
  state.emphasizedPlatform = payload;
};

const doUpdateHasCommonPostCover = (state: SocialMediaWizardState, payload: boolean): void => {
  state.hasCommonPostCover = payload;
};

const doUpdatePublishingParams = (state: SocialMediaWizardState, payload: Record<SocialMediaAccountIdString, MediaTypePublishingParams>): void => {
  state.publishingParams = payload;
};

const doUpdateWizardStep = (state: SocialMediaWizardState, payload: SocialMediaWizardStep): void => {
  state.wizardStep = payload;
};

const doUpdateNextButtonIcon = (state: SocialMediaWizardState, payload: string): void => {
  state.nextButtonIcon = payload;
};

const doUpdateNextButtonText = (state: SocialMediaWizardState, payload: string): void => {
  state.nextButtonText = payload;
};

const doUpdateNumParentAccountsConnected = (state: SocialMediaWizardState, payload: number): void => {
  state.numParentAccountsConnected = payload;
};

const doHideNextButton = (state: SocialMediaWizardState): void => {
  state.hideNextButton = true;
};

const doShowNextButton = (state: SocialMediaWizardState): void => {
  state.hideNextButton = false;
};

const doEnableNextButton = (state: SocialMediaWizardState): void => {
  state.nextButtonDisabled = false;
};

const doDisableNextButton = (state: SocialMediaWizardState): void => {
  state.nextButtonDisabled = true;
};

const doUpdateTriggerNext = (state: SocialMediaWizardState): void => {
  state.triggerNext += 1;
};

const doUpdateTriggerPrevious = (state: SocialMediaWizardState): void => {
  state.triggerPrevious += 1;
};

export const updateWizardSocialAccounts = (state: SocialMediaWizardState, payload: SocialAccount[]): void => {
  state.accounts = payload;
};

const doUpdateWizardSocialAccount = (state: SocialMediaWizardState, payload: SocialAccount): void => {
  const {accounts} = state;
  const updatedAccounts: SocialAccount[] = [];

  accounts.forEach((account) => {
    if (payload.id === account.id) {
      updatedAccounts.push(payload);
    } else {
      updatedAccounts.push(account);
    }
  });

  state.accounts = updatedAccounts;
};

const doUpdateIsTemplateSelected = (state: SocialMediaWizardState, payload: boolean): void => {
  state.isTemplateSelected = payload;
};

const doUpdateAccountLevelErrorMessage = (state: SocialMediaWizardState, payload: ErrorBoxInputs): void => {
  state.accountLevelErrorMessage = payload;
};

const doUpdateAccountIdsToPublishTo = (state: SocialMediaWizardState, payload: number[]): void => {
  const accounts = state.accounts.filter((account) => {
    return payload.includes(account.id);
  });

  if (state.post) {
    state.post.accounts = accounts;
  }

  state.accountIdsToPublishTo = payload;
};

const doRemoveAccountIdFromPublishing = (state: SocialMediaWizardState, payload: number): void => {
  state.accountIdsToPublishTo = state.accountIdsToPublishTo.filter((accountId) => {
    return accountId !== payload;
  });
};

const doAddAccountIdForPublishing = (state: SocialMediaWizardState, payload: number): void => {
  state.accountIdsToPublishTo.push(payload);
};

const doUpdateAccountTypeToConnect = (state: SocialMediaWizardState, payload: SocialPlatform): void => {
  state.accountTypeToConnect = payload;
};

const doSetAccountResizeStatusForMediaType = (state: SocialMediaWizardState, payload: AccountResizeStatusForDesignPayload): void => {
  const {idPoster} = payload;
  const {idSocialMediaAccount} = payload;

  if (payload.postResizeStatus) {
    state.accountResizeStatusForMediaType[idSocialMediaAccount][PlatformMediaType.POST] = {
      ...state.accountResizeStatusForMediaType[idSocialMediaAccount][PlatformMediaType.POST],
      [idPoster]: payload.postResizeStatus,
    };
  }

  if (payload.reelResizeStatus) {
    state.accountResizeStatusForMediaType[idSocialMediaAccount][PlatformMediaType.REELS] = {
      ...state.accountResizeStatusForMediaType[idSocialMediaAccount][PlatformMediaType.REELS],
      [idPoster]: payload.reelResizeStatus,
    };
  }

  if (payload.storyResizeStatus) {
    state.accountResizeStatusForMediaType[idSocialMediaAccount][PlatformMediaType.STORIES] = {
      ...state.accountResizeStatusForMediaType[idSocialMediaAccount][PlatformMediaType.STORIES],
      [idPoster]: payload.storyResizeStatus,
    };
  }
};

const doResetAccountResizeStatusForMediaType = (state: SocialMediaWizardState): void => {
  const connectedAccounts = state.accounts;
  const initialMap: AccountResizeStatusForMediaType = {};

  for (let i = 0; i < connectedAccounts.length; i++) {
    const accountId = connectedAccounts[i].id;

    const designs = state.designIdsToPublish;
    const mapForDesigns: Record<PlatformMediaType, AccountResizeStatusForDesign> = {
      [PlatformMediaType.POST]: {},
      [PlatformMediaType.STORIES]: {},
      [PlatformMediaType.REELS]: {},
    };

    for (const design of designs) {
      mapForDesigns[PlatformMediaType.POST] = {
        ...mapForDesigns[PlatformMediaType.POST],
        [design]: AccountResizeStatus.NOT_RESIZED,
      };

      mapForDesigns[PlatformMediaType.STORIES] = {
        ...mapForDesigns[PlatformMediaType.STORIES],
        [design]: AccountResizeStatus.NOT_RESIZED,
      };

      mapForDesigns[PlatformMediaType.REELS] = {
        ...mapForDesigns[PlatformMediaType.REELS],
        [design]: AccountResizeStatus.NOT_RESIZED,
      };
    }

    initialMap[accountId] = mapForDesigns;
  }

  state.accountResizeStatusForMediaType = initialMap;
};

const doUpdatePublishedAccountUrlForMediaType = (state: SocialMediaWizardState, payload: PublishedUrlUpdatePayload): void => {
  const idSocialMediaAccount = payload.accountId;
  const platformMediaType = payload.mediaType;
  state.publishedAccountUrlMapForMediaType[idSocialMediaAccount][platformMediaType] = payload.url;
};

const setEmptyStatePublishingParamsIfNeeded = (state: SocialMediaWizardState, idSocialMediaAccount: SocialMediaAccountId): void => {
  if (typeof state.publishingParams[idSocialMediaAccount] === 'undefined') {
    const matchedAccount: SocialAccount | null = getAccountForId(state.accounts, idSocialMediaAccount);

    if (matchedAccount) {
      const publishingParams: SocialMediaPublishingParams | null = getEmptyPublishingParamsForAccount(matchedAccount);

      if (publishingParams) {
        const isInstagramReel = state.designIdsToPublish.length === 1 && state.designsGraphicsMeta[state.designIdsToPublish[0]].isVideo;
        state.publishingParams[idSocialMediaAccount] = getDefaultPublishParamsForMediaType(matchedAccount, publishingParams, isInstagramReel);
      }
    }
  }
};

const initGraphicsMeta = (state: SocialMediaWizardState): void => {
  if (typeof state.publishingParams === 'undefined') {
    return;
  }

  const userAccounts = state.accountIdsToPublishTo;

  for (let i = 0; i < userAccounts.length; i++) {
    const accountId = userAccounts[i];

    if (typeof state.publishingParams[accountId] === 'undefined') {
      return;
    }

    const postPublishingParams = getNonStoryPublishingParams(state.publishingParams[accountId]);
    const storyPublishingParams = getStoryPublishingParams(state.publishingParams[accountId]);

    if (postPublishingParams) {
      const postCover = getPosterCoverCopyFromPublishParams(state, postPublishingParams);
      postPublishingParams.graphicsMeta = cloneDeep(state.designsGraphicsMeta);
      setPostCoverInPublishParamsIfNeeded(postCover, postPublishingParams, state.designIdsToPublish[0]);
    }

    if (storyPublishingParams) {
      storyPublishingParams.graphicsMeta = state.designsGraphicsMeta;
    }
  }
};

const initStoryGuidelines = (state: SocialMediaWizardState): void => {
  const storyGuidelineMap: Record<SocialMediaAccountId, StoryGuideline> = {};
  const randomNumbersTaken: number[] = [];
  for (let i = 0; i < state.accountIdsToPublishTo.length; i++) {
    const accountId = state.accountIdsToPublishTo[i];
    const newNumber = getUnusedRandomGuidelineNumber(randomNumbersTaken);
    storyGuidelineMap[accountId] = getStoryGuidelineforIndex(newNumber);
    randomNumbersTaken.push(newNumber);
  }

  state.storyGuidelineForAccount = storyGuidelineMap;
};

const getAccountsToAddCommonCoverTo = (state: SocialMediaWizardState): SocialAccount[] => {
  return state.accounts.filter((account) => {
    return socialAccountPostCoverValidations[account.type].doesSupportCover && state.accountIdsToPublishTo.includes(account.id);
  });
};

const getSocialPostCoverFromPublishingParams = (publishingParamsGraphicsMeta: Record<string, GraphicMeta>): SocialPostCover | undefined => {
  return Object.values(publishingParamsGraphicsMeta)[0]?.cover;
};

const getPosterCoverCopyFromPublishParams = (state: SocialMediaWizardState, postPublishingParams: SocialMediaPublishingParams | null): SocialPostCover | undefined => {
  let postCover;

  if (isSingleGraphicPost(state) && postPublishingParams?.graphicsMeta) {
    postCover = getSocialPostCoverFromPublishingParams(postPublishingParams.graphicsMeta);
  }

  return postCover;
};

const setPostCoverInPublishParamsIfNeeded = (postCover: SocialPostCover | undefined, postPublishingParams: SocialMediaPublishingParams | null, idDesign: string): void => {
  if (postCover && postPublishingParams) {
    postPublishingParams.graphicsMeta[idDesign].cover = postCover;
  }
};

const isSingleGraphicPost = (state: SocialMediaWizardState): boolean => {
  return state.designIdsToPublish.length === 1;
};

const isCoverGeneratedThroughUpload = (coverObject: SocialPostCover): boolean => {
  return coverObject.timestamp === null;
};

const initTitleErrors = (state: SocialMediaWizardState): void => {
  const numberAccounts = state.accountIdsToPublishTo.length;
  const accountsToPublish = state.accountIdsToPublishTo;
  const titleErrorsNew: TitleErrorByAccountIds = {};

  for (let i = 0; i < numberAccounts; i++) {
    if (typeof state.titleError[accountsToPublish[i]] === 'undefined') {
      const validPublishingParams = getNonStoryPublishingParams(state.publishingParams[accountsToPublish[i]]);

      if (validPublishingParams && 'title' in validPublishingParams && validPublishingParams.title !== 'undefined') {
        const {title} = validPublishingParams;
        const account = getAccountForId(state.accounts, accountsToPublish[i]);

        if (!account) {
          return;
        }

        const titleError = getTitleErrorIfTitleIsInvalid(title, account.type, titleValidations[account.type].areURLsAllowed);

        if (titleError) {
          titleErrorsNew[accountsToPublish[i]] = titleError;
        } else {
          titleErrorsNew[accountsToPublish[i]] = null;
        }
      }
    } else {
      titleErrorsNew[accountsToPublish[i]] = state.titleError[accountsToPublish[i]];
    }
  }

  state.titleError = titleErrorsNew;
};

const initCaptionErrors = (state: SocialMediaWizardState): void => {
  const numberAccounts = state.accountIdsToPublishTo.length;
  const accountsToPublish = state.accountIdsToPublishTo;
  const captionErrorsNew: CaptionErrorByAccountIds = {};

  for (let i = 0; i < numberAccounts; i++) {
    if (typeof state.captionError[accountsToPublish[i]] === 'undefined') {
      const validPublishingParams = getNonStoryPublishingParams(state.publishingParams[accountsToPublish[i]]);

      if (validPublishingParams && validPublishingParams.caption !== 'undefined') {
        const existingCaption = validPublishingParams.caption;
        const account = getAccountForId(state.accounts, accountsToPublish[i]);

        if (!account) {
          return;
        }

        if (isCaptionValid(existingCaption, account)) {
          captionErrorsNew[accountsToPublish[i]] = CaptionErrorTypes.NO_ERROR;
        }

        if (!isCountValid(existingCaption, account)) {
          captionErrorsNew[accountsToPublish[i]] = CaptionErrorTypes.COUNT_ERROR;
        }

        if (!isTwitterHashtagCountValid(existingCaption, account.type)) {
          captionErrorsNew[accountsToPublish[i]] = CaptionErrorTypes.HASHTAG_ERROR;
        }
      }
    } else {
      captionErrorsNew[accountsToPublish[i]] = state.captionError[accountsToPublish[i]];
    }
  }

  state.captionError = captionErrorsNew;
};

export const {
  updateAccountTypeToConnect,
  removeAccountIdFromPublishing,
  updateTriggerNext,
  updateTriggerPrevious,
  updateIsTemplateSelected,
  updateAccountLevelErrorMessage,
  hideAccountLevelErrorMessage,
  updateWizardStep,
  disableNextButton,
  enableNextButton,
  addAccountIdForPublishing,
  updateAccountIdsToPublishTo,
  updatePublishingParamCaption,
  updatePublishingParamAllCaptions,
  updatePublishingParamSocialPostCover,
  updatePublishingParamTitle,
  updatePublishingParams,
  updatePublishingAction,
  updateSavingPublishingParamsState,
  updateWizardSocialAccount,
  updatePublishingParamDisableComment,
  updatePublishingParamDisableDuet,
  updatePublishingParamIsSelfBrand,
  updatePublishingParamDisableStitch,
  updatePublishingParamIsPromotingThirdParty,
  resetSocialPostCoverForAllPublishingParams,
  updateTikTokPublishingParamPrivacySettings,
  updateLinkedInPublishingParamPrivacySettings,
  updateYouTubePublishingParamPrivacySettings,
  updatePinterestPublishingParamsWebsiteLink,
  updatePinterestPublishingParamsBoardId,
  updateNumParentAccountsConnected,
  initPublishingParams,
  syncPublishingParams,
  updatePost,
  updateNextButtonText,
  updateNextButtonIcon,
  updateHasCommonCaption,
  updateEmphasizedPlatform,
  updateCommonCaption,
  setWizardMobileNavView,
  updateHasSeenOnboarding,
  updateHasSeenConfetti,
  resetStateToDraft,
  updatePublishErrorMessageForAccount,
  updateAreAccountsLoading,
  updateHasUserPublishedBefore,
  updateIsSocialMediaWizardAiCaptionsEnabled,
  updateIsGoogleBusinessProfileEnabled,
  showNextButton,
  updateDesignIdsToPublish,
  updateDesignIdsToPublishAndTriggerNext,
  updateDesignsGraphicsMeta,
  updateAccountNeedsResizeMapForDesigns,
  updateMultiGraphicDesignSelectorMode,
  resetAccountResizeStatusForDesignsMap,
  syncEditedDesignId,
  updateDesignSelectorTab,
  updateGraphicURLInGraphicsMetaForDesign,
  setAccountResizeStatusForMediaType,
  updatePublishableUrl,
  setPublishToReel,
  setPublishToPost,
  setPublishToStory,
  updatePublishingStatusForAllAccountMediaTypes,
  updatePublishingStatusForAccountWithMediaType,
  updatePublishedAccountPostUrlMapForAccountWithMediaType,
  initRetryPublishingMapForAccount,
  unsetRetryingStatusForAccountWithMediaType,
  initPublishingStatusMapForAccounts,
  initPublishedUrlMapForAccounts,
  toggleToPostOnly,
  toggleToStoryOnly,
  setMediaTypeSelectionToPost,
  setMediaTypeSelectionToStory,
  setMediaTypeSelectionToReelAndStory,
  setMediaTypeSelectionToPostAndStory,
  setMediaTypeSelectionToReel,
  toggleToReelOnly,
  updateDesignIdToResize,
  unsetPublishToStory,
  updateHasCommonPostCover,
  updatePublishErrorMessageForAllParams,
  setRetryingStatusForAccountWithMediaType,
} = socialMediaWizardSlice.actions;

export const socialMediaWizardReducer = socialMediaWizardSlice.reducer;
