import type {ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {ALIGNMENT_TYPE, DROPDOWN_POSITION} from '@Components/dropdown-v2/dropdown.types';
import {ControlledList} from '@Components/controlled-list';
import type {ControlledListItemType} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE, ControlledListItemSize} from '@Components/controlled-list/controlled-list.types';
import {deleteGridItems, getGridItemStorageForId, updateGridItem} from '@Components/base-grid';
import {openConfirmDeleteModal} from '@Modals/confirm-delete-modal';
import {openShareCollectionModal} from '@Modals/share-collection-modal';
import {Dropdown} from '@Components/dropdown-v2';
import useWindowSize from '@Hooks/useWindowSize';
import {Text, TextSize} from '@Components/text';
import {ColorTypes} from '@Components/controlled-list/components/controlled-list-item-type-7';
import type {UserMediaType} from '@Libraries/user-media-library';
import {closeModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import type {GridItemBaseStorage, GridItemProps} from '@Components/base-grid/components/grid-item';
import {GRID_ITEM_TYPE, GridItem} from '@Components/base-grid/components/grid-item';
import type {InputFieldControlItemEvent, InputFieldControls} from '@Components/input-field-v2/input-field.types';
import {InputControlItemType, InputFieldSize} from '@Components/input-field-v2/input-field.types';
import {InputField} from '@Components/input-field-v2';
import {CircularProgressLoader, LOADER_SIZE} from '@Components/circular-progress-loader';
import {openCollectionItemMoreOptionsModal} from '@Modals/collection-item-more-options-modal';
import {isTouchDevice} from '@Utils/browser.util';
import {Icon} from '../../../icon-v2';
import {useAppDispatch, useAppSelector} from '@/hooks';
import styles from './grid-collection-item.module.scss';

const DEFAULT_COLLECTION_NAME = 'Untitled';
const SMALL_SCREEN_WIDTH = 560;

enum RenameInputButtons {
  CONFIRM = 'confirm-collection-rename',
  CANCEL = 'cancel-collection-rename',
}

export interface GridCollectionItemStorage extends GridItemBaseStorage {
  type: GRID_ITEM_TYPE.COLLECTION;
  collectionName: string;
  isShared: boolean;
  collectionId: string;
  multiMediaType: string;
  creatorName?: string;
}

interface GridCollectionItemProps extends GridItemProps {
  isShared: boolean;
  collectionName: string;
  multiMediaType: UserMediaType;
  creatorName?: string;
}

export interface GridCollectionItemBackEndData {
  i: string;
  n: string;
  s: number;
}

export const getGridCollectionItemStorageFromBackendData = (data: GridCollectionItemBackEndData, multiMediaType: string): GridCollectionItemStorage => {
  return {
    type: GRID_ITEM_TYPE.COLLECTION,
    id: data.i,
    collectionId: data.i,
    collectionName: data.n,
    isShared: data.s !== 0,
    multiMediaType,
  };
};

export function GridCollectionItem({onClicked = (): void => {}, ...props}: GridCollectionItemProps): ReactElement {
  const [renameMode, setRenameMode] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const elementRef = useRef<HTMLLIElement>(null);
  const collectionNameRef = useRef<string | null>(null);
  const gridItemData = useAppSelector((state) => {
    return getGridItemStorageForId(state, props.gridId ?? '', props.id) as GridCollectionItemStorage;
  });
  const dispatch = useAppDispatch();
  const {windowWidth} = useWindowSize();

  useEffect(() => {
    if (renameMode && nameInputRef.current) {
      nameInputRef.current.focus();
      nameInputRef.current.setSelectionRange(0, nameInputRef.current.value.length);
    }
  }, [renameMode]);

  useEffect(() => {
    if (isNewCollection()) {
      renameModeOn();
    }
  }, []);

  const updateCollectionName = (name: string): void => {
    dispatch(
      updateGridItem({
        gridId: props.gridId ?? '',
        itemId: props.id,
        changes: {
          collectionName: name,
        },
      })
    );
  };

  const onRenamingDone = (): void => {
    renameModeOff();
    void saveCollectionName();
  };

  const onRenamingCancelled = (): void => {
    renameModeOff();
    if (isNewCollection()) {
      onCollectionRemoved();
    } else {
      updateCollectionName(collectionNameRef.current ?? DEFAULT_COLLECTION_NAME);
    }
  };

  const isNewCollection = (): boolean => {
    return !gridItemData?.collectionId;
  };

  const saveCollectionName = async (name?: string): Promise<void> => {
    let collectionName = name ?? gridItemData?.collectionName;
    if (gridItemData?.collectionId && collectionName === collectionNameRef.current) {
      collectionNameRef.current = null;
      return;
    }
    if (!collectionName && collectionNameRef.current && gridItemData?.collectionId) {
      updateCollectionName(collectionNameRef.current);
      return;
    }
    setShowLoading(true);
    if (!collectionName) {
      collectionName = collectionNameRef.current ? collectionNameRef.current : DEFAULT_COLLECTION_NAME;
      updateCollectionName(collectionName);
    }
    collectionNameRef.current = null;
    try {
      const collectionId = (await window.PMW.writeLocal('collection/save', {
        name: collectionName,
        hash: gridItemData?.collectionId,
        collectionType: props.multiMediaType,
      })) as string;
      handleNewCollection(collectionId);
    } catch (e) {
      await onError(e);
    } finally {
      setShowLoading(false);
    }
  };

  const handleNewCollection = (collectionId: string): void => {
    if (isNewCollection()) {
      dispatch(
        updateGridItem({
          gridId: props.gridId ?? '',
          itemId: props.id,
          changes: {
            id: collectionId,
            collectionId,
          },
        })
      );
    }
  };

  const isSmallScreen = (): boolean => {
    return windowWidth < SMALL_SCREEN_WIDTH || isTouchDevice();
  };

  const getCollectionOptions = (): Array<ControlledListItemType> => {
    if (props.isShared) {
      return [
        {
          id: 'collection-option-delete',
          text: window.i18next.t('pmwjs_delete'),
          type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
          color: ColorTypes.danger,
          textClasses: styles.popupText,
          icon: 'icon-delete',
          listSize: ControlledListItemSize.SMALL,
          onClick: (): void => {
            dispatch(closeModal(MODALS_IDS.MORE_OPTIONS_MODAL));
            openConfirmDeleteModal({
              text: window.i18next.t('pmwjs_confirmation_removing_share_collection'),
              onDeleteConfirmation: (): void => {
                void removeSharedCollection();
              },
            });
          },
        },
      ];
    }
    return [
      {
        id: 'collection-option-share',
        text: window.i18next.t('pmwjs_share'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        icon: 'icon-group',
        listSize: ControlledListItemSize.SMALL,
        onClick: (): void => {
          dispatch(closeModal(MODALS_IDS.MORE_OPTIONS_MODAL));
          void shareCollection();
        },
      },
      {
        id: 'collection-option-edit',
        text: window.i18next.t('pmwjs_rename'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        icon: 'icon-pencil-line',
        listSize: ControlledListItemSize.SMALL,
        onClick: (): void => {
          dispatch(closeModal(MODALS_IDS.MORE_OPTIONS_MODAL));
          renameModeOn();
        },
      },
      {
        id: 'collection-option-delete',
        text: window.i18next.t('pmwjs_delete'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        color: ColorTypes.danger,
        textClasses: styles.popupText,
        icon: 'icon-delete',
        listSize: ControlledListItemSize.SMALL,
        onClick: (): void => {
          dispatch(closeModal(MODALS_IDS.MORE_OPTIONS_MODAL));
          openConfirmDeleteModal({
            text: window.i18next.t('pmwjs_confirmation_deleting_collection'),
            showBtnDeletingState: true,
            onDeleteConfirmation: async (): Promise<void> => {
              await deleteCollection();
            },
          });
        },
      },
    ];
  };

  const openCollectionOptionsModal = (): void => {
    openCollectionItemMoreOptionsModal({
      collectionName: gridItemData?.collectionName,
      deleteCollection,
      shareCollection: (): void => {
        void shareCollection();
      },
      isSharedCollection: gridItemData?.isShared,
      removeSharedCollection: (): void => {
        void removeSharedCollection();
      },
      renameCollection: (name: string) => {
        collectionNameRef.current = gridItemData?.collectionName;
        void saveCollectionName(name);
        updateCollectionName(name);
      },
    });
  };

  const getDropdownClasses = (): string => {
    return `radius-4 hover-transition-all ${isSmallScreen() && gridItemData?.collectionId ? styles.fixSelectorVisibility : ''} ${renameMode ? '' : styles.dropdownOptionsContainer} 
    ${styles.dropdownContainer} ${areOptionsOpen ? styles.dropdownOpen : ''}`;
  };

  const getCollectionOptionsDropdown = (): ReactElement => {
    if (showLoading) {
      return <CircularProgressLoader size={LOADER_SIZE.SMALL} classes="spacing-m-r-3" />;
    }
    if (isSmallScreen()) {
      return (
        <Icon icon="icon-menu-dots" className="content-heading" size={IconSize.SIZE_ICON_16} shape={IconShape.SQUARE} type={IconType.NONE} onClick={openCollectionOptionsModal} />
      );
    }
    return (
      <Dropdown
        className={getDropdownClasses()}
        selector={<Icon icon="icon-menu-dots" className="content-heading" size={IconSize.SIZE_ICON_16} shape={IconShape.SQUARE} type={IconType.NONE} />}
        popup={<ControlledList id="collections-option-dropdown" className="spacing-m-0" items={getCollectionOptions()} />}
        position={DROPDOWN_POSITION.ABSOLUTE}
        popupClassName={styles.dropdownContent}
        popupSpacingClass="spacing-p-2"
        popUpHasCustomWidth
        alignment={ALIGNMENT_TYPE.BOTTOM_END}
        onOpen={(): void => {
          setAreOptionsOpen(true);
        }}
        onClose={(): void => {
          setAreOptionsOpen(false);
        }}
      />
    );
  };

  const deleteCollection = async (): Promise<void> => {
    await window.PMW.writeLocal('collection/delete', {
      collectionId: gridItemData?.collectionId,
      collectionType: props.multiMediaType,
    });
    onCollectionRemoved();
  };

  const removeSharedCollection = async (): Promise<void> => {
    setShowLoading(true);
    try {
      await window.PMW.writeLocal('collection/removeSharedCollection', {
        id: gridItemData?.collectionId,
        collectionType: props.multiMediaType,
      });
      onCollectionRemoved();
    } catch (e) {
      await onError(e);
    } finally {
      setShowLoading(false);
    }
  };

  const onCollectionRemoved = (): void => {
    if (!props.gridId || !props.gridSelectionGroupId) {
      return;
    }
    dispatch(
      deleteGridItems({
        gridId: props.gridId,
        gridSelectionGroupId: props.gridSelectionGroupId,
        itemIdsToDelete: [props.id],
      })
    );
  };

  const onCollectionClicked = (e: React.MouseEvent, isDoubleClick: boolean): void => {
    const t = $(e.target);
    const isPopupContainer = t.parents('.options-container').length > 0;
    if (isPopupContainer || !gridItemData?.collectionId || renameMode) {
      return;
    }
    onClicked(e, isDoubleClick);
  };

  const renameModeOn = (): void => {
    if (!collectionNameRef.current) {
      collectionNameRef.current = gridItemData?.collectionName;
    }
    setAreOptionsOpen(false);
    setRenameMode(true);
  };

  const renameModeOff = (): void => {
    setRenameMode(false);
  };

  const shareCollection = async (): Promise<void> => {
    if (!gridItemData?.collectionId) {
      console.warn('tried to share collection without ID');
      return;
    }
    openShareCollectionModal({
      collectionId: gridItemData?.collectionId,
      collectionType: props.multiMediaType,
    });
  };

  const onError = async (e: any, message = window.i18next.t('pmwjs_connectivity_error')): Promise<void> => {
    if (e) {
      console.error(e);
    }
    await window.PMW.openErrorModal({
      message,
    });
  };

  const getRenamingState = (): ReactElement => {
    const inputControls: InputFieldControls = {
      showDefaultControls: false,
      showSingleDefaultControlOnly: false,
      customControls: [
        {
          id: RenameInputButtons.CANCEL,
          type: InputControlItemType.ICON_BTNS,
          icon: 'icon-close',
        },
        {
          id: RenameInputButtons.CONFIRM,
          type: InputControlItemType.ICON_BTNS,
          icon: 'icon-check',
        },
      ],
      onClick(iconName: RenameInputButtons, e?: InputFieldControlItemEvent) {
        if (e) {
          e.stopPropagation();
        }
        if (iconName === RenameInputButtons.CONFIRM) {
          onRenamingDone();
        } else if (iconName === RenameInputButtons.CANCEL) {
          onRenamingCancelled();
        }
      },
    };

    return (
      <InputField
        ref={nameInputRef}
        className="spacing-m-l-1 spacing-m-r-1"
        input={gridItemData?.collectionName}
        onInputSubmit={onRenamingDone}
        size={InputFieldSize.SMALL}
        onInputChange={updateCollectionName}
        controls={inputControls}
        stopClickPropagation
      />
    );
  };

  let icon;
  let title;
  if (!props.isShared) {
    title = gridItemData?.collectionName;
    icon = <Icon icon="icon-prints-stack" size={isSmallScreen() ? IconSize.SIZE_ICON_24 : IconSize.SIZE_ICON_16} />;
  } else {
    title = window.i18next.t('pmwjs_shared_collection_tooltip', {
      collectionName: gridItemData?.collectionName,
      creatorName: props.creatorName,
    });
    icon = <Icon icon="icon-collection-shared" size={isSmallScreen() ? IconSize.SIZE_ICON_24 : IconSize.SIZE_ICON_16} />;
  }

  return (
    <GridItem
      {...props}
      className={`${props.className ?? ''} flex-row-justify-between flex-items-center radius-4 ${renameMode && isSmallScreen() ? styles.renameMode : styles.collection} ${
        windowWidth > SMALL_SCREEN_WIDTH && !renameMode ? styles.border : ''
      }`}
      isSmallItem
      onClicked={onCollectionClicked}
      title={title}
      ref={elementRef}
    >
      {renameMode && !isSmallScreen() ? (
        getRenamingState()
      ) : (
        <>
          <div className={`flex-h-row flex-items-center flex-content-start _full-height ${styles.itemThumb}`}>
            <div className={`flex-center _full-height ${styles.iconContainer}`}>{icon}</div>
            <Text val={gridItemData?.collectionName} size={TextSize.XSMALL} className="body-xs spacing-m-0 spacing-p-l-3 spacing-p-r-3 -truncated-text" />
          </div>
          <div className="spacing-m-r-2 flex-center options-container">{getCollectionOptionsDropdown()}</div>
        </>
      )}
    </GridItem>
  );
}
