import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {calculateAdjustedModulus, getScaleToFit, roundPrecision, SCALE_PRECISION} from '@Utils/math.util';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';
import {isIOS} from 'react-device-detect';
import {PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import {debounce} from '@Utils/misc.util';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';

export interface PosterScalingObject {
  scale: number;
  isScaleAnimating: boolean;
  isCurrentScaleBestFit: boolean;
}

interface ZoomData {
  bestFit: boolean;
  bestFitScale: number;
  currentScale: number;
}

export const ZOOM_MAX = isIOS ? 2 : 4;
export const ZOOM_MIN = 0.1;
const FIT_TO_SCREEN_BUFFER_DESKTOP = 80;
const FIT_TO_SCREEN_BUFFER_MOBILE = 40;

export class PosterScaling extends CommonMethods {
  private poster: Poster;
  public scale = 1;
  public isFitToScreen = false;
  public isScaleAnimating = false;
  private resizeObserverWhiteboardContainer;
  private resizeObserverPostPosterElement;

  constructor(poster: Poster) {
    super();
    this.poster = poster;
    document.addEventListener('fullscreenchange', this.onWindowResize.bind(this));
    window.addEventListener('resize', debounce(this.onWindowResize.bind(this), 100));
    const postPosterElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POST_POSTER_ELEMENT]?.get(0);
    const whiteboardContainerElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.WHITEBOARD_CONTAINER]?.get(0);
    if (!whiteboardContainerElement || !postPosterElement) {
      throw new Error('Whiteboard container element not defined');
    }
    this.resizeObserverWhiteboardContainer = new ResizeObserver(debounce(this.refreshFitToScreen.bind(this), 100));
    this.resizeObserverWhiteboardContainer.observe(whiteboardContainerElement);

    this.resizeObserverPostPosterElement = new ResizeObserver(debounce(this.refreshFitToScreen.bind(this), 100));
    this.resizeObserverPostPosterElement.observe(postPosterElement);
  }

  public toObject(): PosterScalingObject {
    return {
      scale: this.scale,
      isScaleAnimating: this.isScaleAnimating,
      isCurrentScaleBestFit: this.isCurrentScaleBestFit(),
    };
  }

  public dispose(): void {
    this.resizeObserverWhiteboardContainer.disconnect();
    this.resizeObserverPostPosterElement.disconnect();
  }

  private refreshFitToScreen(): void {
    if (this.isFitToScreen) {
      this.fitToScreen(true);
    }
  }

  public updateFromObject(posterScalingObject: Partial<PosterScalingObject>, updateRedux = true): void {
    this.copyVals(posterScalingObject);
    this.invalidate(updateRedux);
  }

  public invalidate(updateRedux = true): void {
    this.poster.pages.applyScales();
    if (updateRedux) {
      this.poster.redux.updateReduxData();
    }
  }

  public fitToScreen(animate = false): void {
    this.setZoom(this.getFitToScreenScale(), animate);
  }

  public zoomOut(): void {
    const data = this.getZoomData();
    const zoomFactor = this.getZoomOutStepFromCurrentScale(data.currentScale);

    if (data.bestFitScale < data.currentScale && data.bestFitScale > data.currentScale - zoomFactor) {
      this.fitToScreen();
    } else {
      this.setZoom(data.currentScale - zoomFactor);
    }
  }

  public zoomOnWheelEvent = (e: WheelEvent): void => {
    if (e.ctrlKey && this.poster.scaling.scale) {
      e.preventDefault();
      const zoomSensitivity = 0.05;
      const zoomDirection = e.deltaY > 0 ? -1 : 1;
      const zoom = this.poster.scaling.scale * Math.exp(zoomSensitivity * zoomDirection);
      this.poster.scaling.setZoom(zoom);
    }
  };

  public zoomIn(): void {
    const data = this.getZoomData();
    const zoomFactor = this.getZoomInStepFromCurrentScale(data.currentScale);

    if (data.bestFitScale > data.currentScale && data.bestFitScale < data.currentScale + zoomFactor) {
      this.fitToScreen();
    } else {
      this.setZoom(data.currentScale + zoomFactor);
    }
  }

  public setZoom(scale: number, animate = false): void {
    if (animate) {
      const canvasContainer = document.querySelector<HTMLDivElement>('.canvas-container');
      const canvasContainerLowerCanvas = document.querySelector<HTMLCanvasElement>('.canvas-container .lower-canvas');
      if (!canvasContainer || !canvasContainerLowerCanvas) {
        return;
      }

      this.isScaleAnimating = true;
      canvasContainer.classList.add('animate-canvas-scaling');
      canvasContainer.addEventListener(
        window.PMW.util.transitionEndEvents,
        () => {
          this.isScaleAnimating = true;
          canvasContainer.classList.remove('animate-canvas-scaling');
        },
        {once: true}
      );

      canvasContainerLowerCanvas.classList.add('animate-canvas-scaling');
      canvasContainerLowerCanvas.addEventListener(
        window.PMW.util.transitionEndEvents,
        () => {
          canvasContainerLowerCanvas.classList.remove('animate-canvas-scaling');
        },
        {once: true}
      );
    }
    const validatedScale = this.validateNewZoom(scale);
    this.isFitToScreen = validatedScale === this.getFitToScreenScale();
    this.updateIsFitToScreenByScale(validatedScale);
    window.posterEditor?.whiteboard?.scaling.updateFromObject({
      scale: validatedScale,
    });
  }

  public updateIsFitToScreenByScale(scale: number): void {
    this.isFitToScreen = scale === this.getFitToScreenScale();
  }

  private getZoomData(): ZoomData {
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale;

    if (posterScale === undefined) {
      throw new Error('Undefined posterSacle');
    }

    return {
      bestFit: this.isCurrentScaleBestFit(),
      bestFitScale: this.getFitToScreenScale(),
      currentScale: roundPrecision(posterScale, SCALE_PRECISION),
    } as ZoomData;
  }

  public getFitToScreenScale(): number {
    return getFitToScreenScale(this.poster.width, this.poster.height, modeHasBufferForFitToScreen(this.poster.mode.details.type));
  }

  public isCurrentScaleBestFit(): boolean {
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale;
    const LEEWAY = 0.01;

    if (posterScale === undefined) {
      return false;
    }

    return Math.abs(roundPrecision(posterScale, SCALE_PRECISION) - this.getFitToScreenScale()) < LEEWAY;
  }

  public isCurrentScaleBestFitOrLower(): boolean {
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale;
    const LEEWAY = 0.01;

    if (posterScale === undefined) {
      return false;
    }

    const fitToScreenScale = this.getFitToScreenScale();
    if (roundPrecision(posterScale, SCALE_PRECISION) < fitToScreenScale) {
      return true;
    }

    return Math.abs(roundPrecision(posterScale, SCALE_PRECISION) - fitToScreenScale) < LEEWAY;
  }

  private onWindowResize(): void {
    if (this.poster.mode.isWebpage()) {
      this.fitToScreen();
    } else {
      // This is necessary so that our apparent canvas on zoom gets rerendered
      this.poster.scaling.updateFromObject({
        scale: this.poster.scaling.scale,
      });
    }
  }

  private validateNewZoom(val: number): number {
    const minZoom = this.getMinZoom();
    if (val < minZoom) {
      return minZoom;
    }
    if (val > ZOOM_MAX) {
      return ZOOM_MAX;
    }

    return roundPrecision(val, SCALE_PRECISION);
  }

  private getMinZoom(): number {
    return Math.min(this.getFitToScreenScale(), ZOOM_MIN);
  }

  private getZoomInStepFromCurrentScale = (currentScale: number): number => {
    let zoomFactor = 0.5;

    if (currentScale >= 0.1 && currentScale < 1.5) {
      const zoomStep = 0.1;
      const distanceFromNextZoomValue = calculateAdjustedModulus(currentScale, zoomStep);

      zoomFactor = zoomStep - distanceFromNextZoomValue;
    }
    if (currentScale >= 1.5 && currentScale < 2.0) {
      const zoomStep = 0.25;
      const distanceFromNextZoomValue = calculateAdjustedModulus(currentScale, zoomStep);

      zoomFactor = zoomStep - distanceFromNextZoomValue;
    }

    return zoomFactor;
  };

  private getZoomOutStepFromCurrentScale = (currentScale: number): number => {
    let zoomFactor = 0.5;

    if (currentScale > 0.1 && currentScale <= 1.5) {
      const zoomStep = 0.1;
      const distanceFromNextZoomValue = calculateAdjustedModulus(currentScale, zoomStep);

      zoomFactor = distanceFromNextZoomValue === 0 ? zoomStep : distanceFromNextZoomValue;
    }
    if (currentScale > 1.5 && currentScale <= 2.0) {
      const zoomStep = 0.25;
      const distanceFromNextZoomValue = calculateAdjustedModulus(currentScale, zoomStep);

      zoomFactor = distanceFromNextZoomValue === 0 ? zoomStep : distanceFromNextZoomValue;
    }

    return zoomFactor;
  };
}

export const getFitToScreenScale = (canvasWidth: number, canvasHeight: number, hasBuffer = true): number => {
  const buffer = hasBuffer ? getFitToScreenBuffer() : 0;
  const pw = canvasWidth;
  const ph = canvasHeight;
  const wbContainerWidth = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.WHITEBOARD_CONTAINER]?.width();

  if (wbContainerWidth === undefined || pw === undefined || ph === undefined) {
    throw new Error('Undefined whitebaord container width');
  }

  return getScaleToFit(wbContainerWidth - buffer, getAvailbaleHeightForCanvas() - buffer, pw, ph);
};

export const getFitToScreenBuffer = (): number => {
  if (isEditorMobileVariant()) {
    return FIT_TO_SCREEN_BUFFER_MOBILE;
  }
  return FIT_TO_SCREEN_BUFFER_DESKTOP;
};

export const modeHasBufferForFitToScreen = (modeType: PosterModeType): boolean => {
  return modeType !== PosterModeType.WEB_PAGE;
};

const getAvailbaleHeightForCanvas = (): number => {
  const whiteboardContainerHeight = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.WHITEBOARD_CONTAINER]?.height();
  if (whiteboardContainerHeight === undefined) {
    throw new Error('Undefined whitebaord container height');
  }
  return whiteboardContainerHeight - (window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POST_POSTER_ELEMENT]?.height() ?? 0);
};
