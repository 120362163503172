import type {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import type {RGB} from '@Utils/color.util';
import type {USER_VIDEO_SOURCE} from '@Libraries/user-video-library';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {CellObject} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {MaskingEffect, MaskingType} from '@PosterWhiteboard/classes/masking/masking.class';
import type {MaskingFreehandPoint} from '@PosterWhiteboard/classes/masking/masking-freehand.class';
import type {TransitionObject} from '@PosterWhiteboard/models/transition.class';
import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {MediaSlideObject} from '@PosterWhiteboard/items/slideshow-item/media-slide.class';
import type {TextHorizontalAlignType, TextStylesObject, TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {ImageItemSourceType} from '@Libraries/image-item.library';
import type {EdgeType} from '@PosterWhiteboard/classes/item-effects.class';
import type {VectorItemSource} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import type {FillObject, FillTypes} from '@PosterWhiteboard/classes/fill.class';
import type {TranscriptItemObject, TranscriptGeneratedFrom} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {ItemAuraObject} from '@PosterWhiteboard/classes/item-aura.class';
import type {WordObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word.types';
import type {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import {SubtitleTemplateProperties, SubtitleTemplate} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import type {TextBackgroundObject} from '@PosterWhiteboard/items/text-item/text-background';
import type {USER_AUDIO_SOURCE} from '@Libraries/user-audio-library';
import type {AudioPlayerObject} from '@Libraries/audio-player/audio-player.types';

export enum BackendExtraItemType {
  AUDIO = 'audio',
}

export enum ShapeType {
  VECTOR = 'vector',
  SHAPE = 'shape',
}

export interface PosterObjectBackend {
  createdOn: number;
  creator: Creator | null;
  description: string;
  hashedID: string;
  height: number;
  id: string;
  audioClips: AudioClipsBackendObject;
  idGalleryTemplate: string | null;
  idGalleryTemplateCreator: number;
  idLastModifier: string;
  idTemplate: string | null;
  isCopyable: boolean;
  isInternal: boolean;
  isPublic: boolean;
  isPurchaseable: boolean;
  isTemplate: boolean;
  lastModified: number;
  name: string;
  pages: PageBackendObject[];
  seoName: string;
  type: PosterTypeBackendObject;
  units: string;
  userHeight: number;
  userWidth: number;
  version: number;
  width: number;
}

interface Animation {
  type: string;
  slideType: string;
  speed: string;
}

export interface AudioClipsBackendObject {
  audioItemsHashMap: Record<string, AudioItemBackendObject>;
}

export interface AudioItemBackendObject {
  uid: string;
  hashedFilename: string;
  audioPlayer: AudioItemAudioPlayerBackendObject;
  source: USER_AUDIO_SOURCE;
  onPosterStartTime: number;
  name: string;
}

type AudioItemAudioPlayerBackendObject = AudioPlayerObject;

export type BackgroundBackendObject = SolidBackgroundBackendObject | GradientBackgroundBackendObject | TransparentBackgroundBackendObject | ImageBackgroundItemBackendObject;

export enum BackgroundTypeBackend {
  SOLID = 'postermywall.core.model.vo.SolidBackgroundVO',
  GRADIENT = 'postermywall.core.model.vo.GradientBackgroundVO',
  TRANSPARENT = 'postermywall.core.model.vo.TransparentBackgroundVO',
  IMAGE = 'postermywall.core.model.vo.ImageBackgroundVO',
}

export enum GradientBackgroundBackendType {
  LINEAR = 'linear',
  RADIAL = 'radial',
}

export interface SolidBackgroundBackendObject {
  pmvcName: BackgroundTypeBackend.SOLID;
  primaryColor: string;
}

export interface GradientBackgroundBackendObject {
  pmvcName: BackgroundTypeBackend.GRADIENT;
  primaryColor: string;
  secondaryColor: string;
  gradientType: GradientBackgroundBackendType;
}

export interface TransparentBackgroundBackendObject {
  pmvcName: BackgroundTypeBackend.TRANSPARENT;
}

export interface ImageBackgroundItemBackendObject extends BaseImageItemBackendObject {
  gitype: ITEM_TYPE.IMAGEBACKGROUND;
  pmvcName: BackgroundTypeBackend.IMAGE;
  idUser: null | number;
  transparency: string;
  attributionName?: string;
  attributionURL?: string;
  licenseName?: string;
  licenseURL?: string;
  displayAttribution: boolean;
}

interface Creator {
  id: string;
  fbId: string;
  name: string;
  type: string;
  watermark: string;
  premiumLevel: number;
  preferredLanguage: string;
  verificationNeededStatus: boolean;
}

export interface PosterTypeBackendObject {
  name: string;
  displayName: string;
  description: string;
  keywords: string;
  width: number;
  height: number;
  widthInch: number;
  heightInch: number;
  widthMm: number;
  heightMm: number;
  widthCm: number;
  heightCm: number;
  widthFt: number;
  heightFt: number;
  dpi: number;
  rotatable: string;
  hasPrintProduct: boolean;
  scaleFactors: string[];
  idUserPosterType: number;
}

export interface PageBackendObject {
  hashedID: string;
  takeDurationFrom: string;
  introAnimation: Animation;
  background: BackgroundBackendObject;
  duration: number;
  graphicItems: ItemBackendObject[];
}

// /////////// //////////// ///////////// /////////// /////////////

export interface BaseItemBackendObject {
  uid: string;
  idOriginalOwner: number | null;
  x: number;
  y: number;
  alpha: number;
  width: number;
  height: number;
  rotation: number;
  visible: boolean;
  glow: number;
  dropShadow: ShadowType;
  dropShadowColor: RGB;
  dropShadowAlpha: number;
  dropShadowAngle: number;
  dropShadowDistance: number;
  dropShadowBlur: number;
  solidBorderType: number;
  solidBorderColor: RGB;
  solidBorderThickness: number;
  scaleX: number;
  scaleY: number;
  lockMovement: boolean;
  flipX: boolean;
  flipY: boolean;
  fillType: FillTypes;
  gradientFillColor1: RGB;
  gradientFillColor2: RGB;
  patternFill: number;
  version: number;
  clickableLink: string;
}

export enum ShadowType {
  NONE = 0,
  LIGHT = 1,
  STRONG = 2,
  CUSTOM = 3,
}
export enum GlowType {
  NONE = 0,
  LIGHT = 1,
  STRONG = 2,
}

export interface GammaBackendObject {
  isEnabled: boolean;
  red: number;
  green: number;
  blue: number;
}

export interface MaskingEffectBackend {
  type: string;
  pmvcName: string;
  maskEffect: MaskingEffect;
  imageWidth: number;
  imageHeight: number;
}

export interface MaskingFabricItemBackend extends MaskingEffectBackend {
  angle: number;
  height: number;
  width: number;
  left: number;
  top: number;
  scaleX: number;
  scaleY: number;
}

export interface MaskingShapeBackend extends MaskingFabricItemBackend {
  type: MaskingType.SHAPE;
  shapeName: string;
  shapeType: ShapeType;
  insideMasking: boolean;
}

export interface MaskingTextBackend extends MaskingFabricItemBackend {
  type: MaskingType.TEXT;
  text: string;
  fontFamily: string;
  fontSize: number;
  charSpacing: number;
  lineHeight: number;
  textAlign: string;
  isBold: boolean;
  isItalic: boolean;
  underLine: boolean;
  lineThrough: boolean;
}

export interface MaskingFreehandBackend extends MaskingEffectBackend {
  type: MaskingType.FREEHAND;
  maskPoints: MaskingFreehandPoint[];
  isMaskCurved: boolean;
}

export type MaskingItemBackend = MaskingShapeBackend | MaskingTextBackend | MaskingFreehandBackend;
export type MaskingItemsBackend = MaskingItemBackend[];

export interface RemoveVideoBackgroundBackend {
  isBackgroundRemoved: boolean;
  startTime: number;
  endTime: number;
}

export interface EffectsBackend {
  blackAndWhite: boolean;
  sepia: boolean;
  invert: boolean;
  blur: number;
  pixelate: number;
  vibrance: number;
  saturation: number;
  contrast: number;
  gamma: GammaBackendObject;
  glowType: GlowType;
  glowColor: RGB | number;
  brightness: number;
  tint: boolean;
  tintColor: RGB;
  tintOpacity: number;
  multiply: boolean;
  multiplyColor: RGB;
  multiplyOpacity: number;
  edgeType: EdgeType | -1;
  edgeThickness: number;
  removeColor: boolean;
  removeColorValue: RGB;
  removeColorThickness: number;
  maskingItems: null | MaskingItemsBackend;
}

export interface TextStylesBackend {
  fontSize: number;
  fontFamily: string;
  fontStyle: string;
  fontWeight: string;
  letterSpacing: number;
  fontLicense: string;
  leading: number;
  textAlign: string;
  // TODO: Recheck the type, unsure.
  script: string;
  isBold: boolean;
  isItalic: boolean;
  underLine: boolean;
  lineThrough: boolean;
  stroke: boolean;
  strokeColor: RGB;
  strokeWidth: number;
  color: RGB;
  gradientFillColor1: RGB;
  gradientFillColor2: RGB;
  fillType: number;
}

export interface CropDataBackend {
  pmvcName: string;
  x: number;
  y: number;
  width: number;
  height: number;
  imageWidth: number;
  imageHeight: number;
}

export interface BaseImageItemBackendObject extends BaseItemBackendObject, EffectsBackend {
  gitype: ITEM_TYPE.IMAGE | ITEM_TYPE.IMAGESLIDE | ITEM_TYPE.IMAGEBACKGROUND;
  hashedFilename: string;
  isBackgroundRemoved: boolean;
  fileExtension: string;
  imageSource: ImageItemSourceType;
  type: number;
  hasTransparency: boolean;
  isPurchased: boolean;
  isRemoved: boolean;
  modification: ImageModficationBackendObject | null;
  cropData: CropDataBackend | null;
  imageUid: string;
  loadedImageWidth?: number;
  loadedImageHeight?: number;
}

export interface ImageModficationBackendObject {
  modtype: number;
  id: number;
  creationID: number;
}

export interface ImageItemBackendObject extends BaseImageItemBackendObject {
  gitype: ITEM_TYPE.IMAGE;
}

export interface ImageSlideItemBackendObject extends BaseImageItemBackendObject {
  gitype: ITEM_TYPE.IMAGESLIDE;
  slideDuration: number;
  horizontalAlign: string;
  verticalAlign: string;
}

export interface TabsItemBackendObject extends BaseItemBackendObject, TextTraitBackend {
  gitype: ITEM_TYPE.TAB;
  fontSize: number;
  text: string;
  numTabs: number;
  separatorColor: RGB;
  separatorType: number;
  backgroundType: number;
  backgroundColor: RGB;
  backgroundColorAlpha: number;
}

export interface SlideshowItemBackendObject extends BaseItemBackendObject {
  gitype: ITEM_TYPE.SLIDESHOW;
  transition: TransitionObject;
  slideshowItems: SlideBackendObject[];
  introAnimationPadding: number;
  introDelay: number;
  hasIntroOutroTransition: boolean;
}

export interface StickerItemBackendObject extends BaseItemBackendObject, EffectsBackend {
  gitype: ITEM_TYPE.STICKER;
  hashedFilename: string;
  duration: number;
  frameRate: number;
  hasTransparency: boolean;
  startTime: number;
  endTime: number;
  isMuted: boolean;
  highResAnimatedSprite: AnimatedSpriteBackendObject;
  screenAnimatedSprite: AnimatedSpriteBackendObject;
}

interface AnimatedSpriteBackendObject {
  id: number;
  hashedFilename: string;
  frameRate: number;
  frameWidth: number;
  numberOfSprites: number;
}

export interface VideoItemBackendObject extends BaseItemBackendObject, EffectsBackend {
  gitype: ITEM_TYPE.VIDEO;
  hashedFilename: string;
  duration: number;
  frameRate: number;
  hasTransparency: boolean;
  startTime: number;
  endTime: number;
  isMuted: boolean;
  fileExtension: string;
  videoSource: USER_VIDEO_SOURCE;
  removeBackground: RemoveVideoBackgroundBackend;
}

export interface VideoSlideItemBackendObject extends BaseItemBackendObject, EffectsBackend {
  gitype: ITEM_TYPE.VIDEOSLIDE;
  hashedFilename: string;
  duration: number;
  frameRate: number;
  hasTransparency: boolean;
  startTime: number;
  endTime: number;
  isMuted: boolean;
  fileExtension: string;
  videoSource: USER_VIDEO_SOURCE;
  slideDuration: number;
  horizontalAlign: string;
  verticalAlign: string;
  removeBackground: RemoveVideoBackgroundBackend;
}

export interface QRCodeItemBackendObject extends BaseItemBackendObject {
  gitype: ITEM_TYPE.QR_CODE;
  message: string;
  qrForegroundColor: RGB;
  qrBackgroundColor: RGB;
  isBackgroundTransparent: boolean;
}

export interface TextTraitBackend {
  text: string;
  color: RGB | number;
  fontWeight: string;
  fontFamily: string;
  fontStyle: string;
  letterSpacing: number;
  fontLicense: string;
  leading: number;
  textAlign: string | TextHorizontalAlignType;
  backgroundType: number;
  backgroundColor: RGB;
  backgroundColorAlpha: number;
  script: string;
  lineThrough: boolean;
  underLine: boolean;
  isBold: boolean;
  isItalic: boolean;
  stroke: boolean;
  strokeColor: RGB;
  strokeWidth: number;
}

export interface GraphicItemShapeBackend {
  fileName: string;
  fillColor: RGB | number;
  fillAlpha: number;
  strokeColor: RGB | number;
  strokeWeight: number;
  lockAspectRatio: boolean;
}

export interface TextListBackend {
  type: number;
  style: number;
  width: number;
  color: RGB;
  colorType: FillTypes;
  gradientColor1: RGB;
  gradientColor2: RGB;
}

export interface SubtitleBackendObject {
  words: Array<WordObject>;
  subtitleUID: string;
  text: string;
  startTime: number;
  endTime: number;
  hasUserEdited: boolean;
  sentenceTextStyles: TextStylesObject;
  activeWordTextStyles: TextStylesObject;
  aura: ItemAuraObject;
  backgroundFill: FillObject;
  backgroundBorderRadius: number;
  selectedTemplateId: string;
  animationStyle: SubtitleTemplateType;
}

export interface TranscriptItemBackendObject extends BaseItemBackendObject {
  gitype: ITEM_TYPE.TRANSCRIPT;
  subtitlesHashmap: Record<string, SubtitleBackendObject>;
  generatedFrom?: TranscriptGeneratedFrom;
  verticalAlign: TextVerticalAlignType;
}

export interface TextItemBackendObject extends BaseItemBackendObject, TextTraitBackend {
  gitype: ITEM_TYPE.TEXT;
  baseWidth: number;
  fontSize: number;
  wrappedLines: string[];
  verticalAlign: TextVerticalAlignType;
  verticalPadding: number;
  background: TextBackgroundObject;
  list: TextListBackend;
}

export interface TextSlideItemBackendObject extends BaseItemBackendObject, TextTraitBackend {
  gitype: ITEM_TYPE.TEXTSLIDE;
  baseWidth: number;
  fontSize: number;
  wrappedLines: any[];
  verticalAlign: TextVerticalAlignType;
  verticalPadding: number;
  background: TextBackgroundObject;
  list: TextListBackend;
  slideDuration: number;
}

export interface RectangleItemBackendObject extends BaseItemBackendObject {
  gitype: ITEM_TYPE.RECTANGLE;
  rx: number;
  ry: number;
  fill: FillObject;
}

export interface VectorItemBackendObject extends BaseItemBackendObject, GraphicItemShapeBackend {
  gitype: ITEM_TYPE.VECTOR;
  source: VectorItemSource;
  isComplexSVG: boolean;
}

export interface FancyTextItemBackendObject extends BaseItemBackendObject {
  gitype: ITEM_TYPE.FANCY_TEXT;
  idFancyText: string;
  text: string;
  fontFamily: string;
  color1: RGB | -1;
  color2: RGB | -1;
  color3: RGB | -1;
  morphType: number;
  morphAmount: number;
}

export interface TableItemBackendObject extends BaseItemBackendObject, Omit<TextTraitBackend, 'text'> {
  gitype: ITEM_TYPE.TABLE | ITEM_TYPE.MENU;
  rows: number;
  columns: number;
  fontSize: number;
  layoutDataMap: Record<string, CellObject[]>;
  unusedData: Record<string, CellObject[]>;
  layoutStyle: string;
  alternateBackgroundColor1: RGB;
  alternateBackgroundColor2: RGB;
  alternateColor1Alpha: number;
  alternateColor2Alpha: number;
  highlightedBackgroundColor: RGB;
  highlightedBackgroundColorAlpha: number;
  highlightedTextColor: RGB;
  xSpacing: number;
  ySpacing: number;
  fontFamily2: string;
  isBold2: boolean;
  isItalic2: boolean;
  underLine2: boolean;
  lineThrough2: boolean;
}

export interface MenuItemBackendObject extends TableItemBackendObject {
  gitype: ITEM_TYPE.MENU;
  itemIds: string[];
  copiedItemIds: string[];
  iconsColor: RGB;
  iconsSize: number;
  wrappingInfo: string[][] | null;
}

export type NonAudioItemBackendObject =
  | ImageItemBackendObject
  | StickerItemBackendObject
  | VideoItemBackendObject
  | QRCodeItemBackendObject
  | TextItemBackendObject
  | VectorItemBackendObject
  | FancyTextItemBackendObject
  | TableItemBackendObject
  | MenuItemBackendObject
  | TabsItemBackendObject
  | SlideshowItemBackendObject
  | SlideBackendObject
  | ImageBackgroundItemBackendObject
  | TranscriptItemBackendObject
  | RectangleItemBackendObject;

export type ItemBackendObject = NonAudioItemBackendObject | AudioPlaylistBackendObject;
export type SlideBackendObject = TextSlideItemBackendObject | ImageSlideItemBackendObject | VideoSlideItemBackendObject;

export type ItemObjectConvertedFromBackend<T> = Omit<T, 'isNew' | 'erasable'>;
export type ItemBackendObjectTypeWithTextStyles = TextItemBackendObject | TextSlideItemBackendObject | TableItemBackendObject | MenuItemBackendObject | TabsItemBackendObject;
export type TextItemObjectConvertedFromBackend = Omit<TextItemObject, 'isNew' | 'erasable'>;

export interface AudioPlaylistBackendObject extends BaseItemBackendObject {
  gitype: BackendExtraItemType.AUDIO;
  audioItemHashMap: Record<string, AudioItemObject>;
}

export interface TextSlideItemObjectConvertedFromBackend extends TextItemObjectConvertedFromBackend {
  slideDuration: number;
}

export interface TranscriptItemObjectConvertedFromBackend extends ItemObjectConvertedFromBackend<TranscriptItemObject> {}

export interface ImageSlideItemObjectConvertedFromBackend extends ItemObjectConvertedFromBackend<ImageItemObject> {
  slideDuration: number;
  mediaSlide: MediaSlideObject;
}

export interface VideoSlideItemObjectConvertedFromBackend extends ItemObjectConvertedFromBackend<VideoItemObject> {
  slideDuration: number;
  mediaSlide: MediaSlideObject;
}

export interface ImageBackgroundItemObjectConvertedFromBackend extends ItemObjectConvertedFromBackend<ImageItemObject> {
  transparency: string;
  attributionName?: string;
  attributionURL?: string;
  licenseName?: string;
  licenseURL?: string;
  displayAttribution: boolean;
  idUser: number | null;
}
