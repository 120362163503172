import type {ReactElement} from 'react';
import React from 'react';

interface CircleProps {
  percentage: number;
  strokeWidth: number;
  circleDiameter: number;
  color: string;
  fill?: string;
}

export function Circle({fill = '', ...props}: CircleProps): ReactElement {
  const getCirle = (): ReactElement => {
    const r = (props.circleDiameter - props.strokeWidth) / 2;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - props.percentage) * circ) / 100;
    return (
      <circle
        r={r}
        cx={props.circleDiameter / 2}
        cy={props.circleDiameter / 2}
        fill={fill === '' ? 'transparent' : fill}
        stroke={strokePct !== circ ? props.color : ''}
        strokeWidth={`${props.strokeWidth}px`}
        strokeDasharray={circ}
        strokeDashoffset={props.percentage ? strokePct : 0}
        strokeLinecap="round"
      ></circle>
    );
  };

  return getCirle();
}
