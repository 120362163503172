import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject, SlideItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {TextSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {TransitionObject} from '@PosterWhiteboard/models/transition.class';
import {Transition} from '@PosterWhiteboard/models/transition.class';
import {getScaledManualHorizontalPadding, getScaledManualVerticalPadding} from '@PosterWhiteboard/items/item/item.library';
import type {FabricItemDimensions} from '@PosterWhiteboard/items/text-item/text-item.class';
import {getTextFromClipboard, MAX_CHARACTERS_LIMIT, TextVersion} from '@PosterWhiteboard/items/text-item/text-item.class';
import {AnimationSpeed, isAnimation, isBlockAnimation} from '@PosterWhiteboard/animation/animation.class';
import type {OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {
  getPmwMbControl,
  getPmwMlControl,
  getPmwMrControl,
  getPmwMtControl,
  ITEM_CONTROL_DIMENSIONS,
  ItemBorderColor,
  ItemControlOption,
} from '@PosterWhiteboard/poster/poster-item-controls';
import type {SlideshowSlidesObject} from '@PosterWhiteboard/items/slideshow-item/slideshow-slides.class';
import {getMaxSlideshowDuration, SlideshowSlides} from '@PosterWhiteboard/items/slideshow-item/slideshow-slides.class';
import type {AddItemOpts, Page} from '@PosterWhiteboard/page/page.class';
import {getIsSpellCheckEnabledFromStore} from '@Libraries/spell-check-library';
import type {Point} from '@Utils/math.util';
import {degreesToRadians} from '@Utils/math.util';
import {getListTypeFromPastedText, LIST_TYPES} from '@PosterWhiteboard/items/text-item/text-list';
import type {ItemCoordinates, ItemData} from '@PosterWhiteboard/page/add-item.class';
import {POSTER_GETTY_LIMIT} from '@PosterWhiteboard/poster/poster.types';
import {AnimateItemType, getOutroForIntroAnimation} from '@PosterWhiteboard/animation/animate-item.class';
import {OutroAnimateItem} from '@PosterWhiteboard/animation/outro-animate-item.class';
import {IntroAnimateItem} from '@PosterWhiteboard/animation/intro-animate-item.class';
import {Deferred} from '@Libraries/deferred';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import {v4 as uuidv4} from 'uuid';
import {ElementDataType, ImageData, PMWExtractedGettyStickerData, PMWStillStickerData, TempImageData, VideoData} from '@Libraries/add-media-library';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {USER_VIDEO_SOURCE} from '@Libraries/user-video-library';
import {openMessageModal} from '@Modals/message-modal';
import type {RGB} from '@Utils/color.util';
import {getIsClipboardReadSupported} from '@Libraries/clipboard-library';
import {closeTextSelectionPopup, handleTextSelectionPopupMenu, shouldShowTextSelectionPopup} from '@Libraries/text-selection-library';
import {DEFAULT_SLIDE_DURATION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import {openGettyVideoLimitReachedMessageModal} from '@Components/poster-editor/library/poster-editor-open-modals';
import {validateText} from '@PosterWhiteboard/libraries/text.library';
import {createSlideItemFromObject} from '@PosterWhiteboard/items/item/item-factory';
import type {FabricObject, ObjectEvents, TPointerEvent} from '@postermywall/fabricjs-2';
import {Canvas, config, FixedLayout, Group, LayoutManager, Textbox, util} from '@postermywall/fabricjs-2';
import {SlideshowItemCustomControls} from '@PosterWhiteboard/items/slideshow-item/slideshow-item-custom-controls.class';
import {addItemsToGroupWithOriginalScale} from '@Utils/fabric.util';
import type {DeepPartial} from '@/global';
import {PMW_STOCK_IMAGE_EXTENSION, PMWStockImageSource} from '@Libraries/pmw-stock-media-library.types';
import {getCompatibleImageFileExtension} from '@Utils/image.util';

/**
 * saves the list style from last pasted text
 */
let pastedTextListType = LIST_TYPES.NONE;

const VERSION1_SELECTOR_PADDING = 11;
const PIXEL_TARGET_FIND_TOLERANCE = 10;
/**
 * Minimum scaled dimension of the animation item allowed.
 */
const MIN_SCALED_DIMENSION = 30;

export type SlideItem = TextSlideItem | ImageSlideItem | VideoSlideItem;

interface UpdateTextProps {
  slideWidth: number;
  slideHeight: number;
  groupCoordinates: ItemCoordinates;
  text: string;
  slideId: string;
  newVerticalPadding: number;
}

enum SLIDESHOW_VERSIONS {
  SLIDESHOW_VERSION_1 = 1,
  SLIDESHOW_VERSION_2 = 2,
}

enum SlideshowTransitionDurations {
  LOWER_SPEED = 1.3,
  LOW_SPEED = 0.9,
  MED_SPEED = 0.5,
  HIGH_SPEED = 0.25,
  HIGHER_SPEED = 0.15,
}

export interface SlideshowItemObject extends BaseItemObject {
  slides: SlideshowSlidesObject;
  transition: TransitionObject;
  introAnimationPadding: number;
  introDelay: number;
  hasIntroOutroTransition: boolean;
  selectedSlideUID: string;
}

export class SlideshowItem extends Item {
  declare fabricObject: Group;

  public version = SLIDESHOW_VERSIONS.SLIDESHOW_VERSION_2;
  public transition = new Transition();
  public gitype = ITEM_TYPE.SLIDESHOW;
  public slides!: SlideshowSlides;
  public slideshowItemCustomControls: SlideshowItemCustomControls;
  public introAnimationPadding = 0;
  public introDelay = 0;
  public hasIntroOutroTransition = false;
  public selectedSlideUID = '';
  public clone: Textbox | null = null;
  public enterEditModeOnMouseUp = true;
  /**
   * Sync to poster click function is cached in this variable so that it can be unmounted on item remove. Inline use of that
   * function doesn't work because of bind (https://stackoverflow.com/questions/28800850/jquery-off-is-not-unbinding-events-when-using-bind)
   */
  private readonly syncToPosterClockFunction;

  /**
   * Is set true when slideshow's updateFabricObject is called while transitioning, then it is done after transition is complete.
   */
  protected isUpdateFabricObjectPending = false;

  /**
   * Is set true when the user stops the poster.
   */
  protected isPosterStoppedWhileTransitioning = false;

  /**
   * Is set true when the user pauses the poster or if the user changes the selected slide while poster is playing.
   */
  protected isPosterPausedOrInterruptedWhileTransitioning = false;
  /**
   * Lock stat when calculating whether to start transition or not
   */
  protected transitionLock = false;
  /**
   * Is set true while the animation is playing.
   */
  protected isSlideshowAnimationPlaying = false;

  protected animation: IntroAnimateItem | OutroAnimateItem | undefined = undefined;

  /**
   * The jQuery deferred function used to keep the slideshow in sync during pausing and stopping processes.
   * @type {promise}
   */
  protected dfd: Deferred | undefined = undefined;

  /**
   * Track setTimeout's return value in this variable, to keep track of when the user stopped typing.
   */
  protected textChangedTimeout = 0;
  /**
   * Track setTimeout's return value in this variable, to keep track of when spell check was called on text.
   */
  protected spellCheckTimeout = 0;

  constructor(page: Page) {
    super(page);
    this.slides = new SlideshowSlides(this);
    this.slideshowItemCustomControls = new SlideshowItemCustomControls(this);
    this.transition = new Transition();
    this.syncToPosterClockFunction = this.syncSlideShow.bind(this);
  }

  public updateBoundItemsZIndex(): void {
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      slide.loading.updateZIndex();
    }
  }

  public async onItemAddedToPage(): Promise<void> {
    this.page.poster.on('time:updated', this.syncToPosterClockFunction);
    this.page.poster.on('seeked', this.onPosterSeeked.bind(this));

    if (this.page.isVideo()) {
      await this.seekToPageTime();
      if (this.page.background.isTransparentBackground()) {
        this.page.background.updateBackgroundToBeNonTransparent();
      }
    }
  }

  public getBorderColor(): ItemBorderColor {
    return this.isStreamingItem() ? ItemBorderColor.DYNAMIC_ITEM : ItemBorderColor.STATIC_ITEM;
  }

  protected async onEnded(): Promise<void> {
    await this.stop();
  }

  public onMoving(): void {
    super.onMoving();
    this.getSelectedSlide().onMoving();
  }

  public onRotating(): void {
    super.onRotating();
    this.getSelectedSlide().onRotating();
  }

  public onRemove(): void {
    this.loading.removeLoading();
    this.page.poster.off('time:updated', this.syncToPosterClockFunction);
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      slide.onRemove();
    }
  }

  public async seekToPageTime(): Promise<void> {
    await this.syncSlideShow(this.page.poster.getCurrentTime());
    this.page.fabricCanvas.requestRenderAll();
  }

  protected async syncSlideShow(time: number): Promise<void> {
    void this.syncVideoSlides(time);

    if (this.hasTransition()) {
      if (this.isTimeToTransitionToNextSlide(time)) {
        this.startTransition();
      } else if ((this.hasIntroOutroTransition || (this.hasIntroDelay() && this.hasTransition())) && this.timeForFirstSlideTransitionIn(time)) {
        this.transitionIn(0);
      } else if (this.areSlideTransitionTimesOverlapping() && this.isSlideshowAnimationPlaying) {
        this.stopAnimation();
      } else if (!this.isSlideshowAnimationPlaying) {
        this.updateSelectedSlideForTime(time);
        this.updateFirstSlideWithDelayVisibilty(time);
        this.conditionallyHideLastSlide(time);
      }
    } else {
      this.updateSelectedSlideForTime(time);
      this.updateFirstSlideWithDelayVisibilty(time);
    }

    if (this.hasIntroDelay() && time < this.introDelay && !this.isSlideshowAnimationPlaying) {
      this.slides.hideAllSlides();
      this.restoreDefaultState();
    }

    // if (time > this.getDuration()) {
    //   await this.onEnded();
    // }
  }

  /**
   * If the currently selected slide is a video, syncs it according to poster time
   */
  protected async syncVideoSlides(time: number): Promise<void> {
    if (this.slides.hasVideoSlide()) {
      const selectedSlide = this.getSelectedSlide();
      if (selectedSlide.isVideoSlide()) {
        const startTime = this.slides.getStartTimeForSlide(this.selectedSlideUID);
        await selectedSlide.syncItemToTime(time - (startTime ?? 0));
        this.page.fabricCanvas.requestRenderAll();
      }
      this.stopUnselectedVideoSlides();
    }
  }

  protected updateSelectedSlideForTime(time: number): void {
    const slideIndex = this.slides.getSlideIndexForTime(time);
    const slideModel = this.slides.slidesHashMap[this.slides.slidesOrder[slideIndex]];

    if (slideModel && this.selectedSlideUID !== slideModel.uid) {
      this.setSelectedSlideAndUpdateView(slideModel.uid);
    }
  }

  protected setSelectedSlideAndUpdateView(slideId: string): void {
    this.setSelectedSlide(slideId);
    this.updateSlideVisibility();

    if (this.isSlideEditable(this.selectedSlideUID)) {
      this.setCloneProperties();
    } else {
      this.restoreDefaultState();
    }
  }

  protected isSlideEditable(slideId: string): boolean {
    const slideModel = this.slides.slidesHashMap[slideId];
    return slideModel.isTextSlide();
  }

  public setSelectedSlide(slideId: string): void {
    this.selectedSlideUID = slideId;
    this.page.poster.redux.updateReduxData();
  }

  public getActiveText(): string {
    const selectedSlide = this.getSelectedSlide();
    if (selectedSlide.isTextSlide()) {
      return selectedSlide.text;
    }
    return '';
  }

  public async onPasteText(e?: Event): Promise<void> {
    if (await getIsClipboardReadSupported()) {
      e?.preventDefault();
    }
    const clipText = await getTextFromClipboard();
    if (this.clone && clipText) {
      const validatedText = validateText(clipText);
      const updatedSelection = this.clone.selectionStart;
      pastedTextListType = await getListTypeFromPastedText();
      const prevText = this.clone.text;
      const updatedText = prevText.substring(0, this.clone.selectionStart) + validatedText + prevText.substring(this.clone.selectionEnd, prevText.length);
      this.clone.selectionStart = updatedSelection + validatedText.length;
      this.clone.selectionEnd = this.clone.selectionStart;
      if (this.clone.hiddenTextarea) {
        this.clone.hiddenTextarea.selectionEnd = this.clone.selectionEnd;
      }
      this.onCanvasTextChange(updatedText);
    }
  }

  public async setFrameForGeneration(frameTime: number, videoItemFrames: Record<string, string>): Promise<void> {
    if (this.hasIntroOutroTransition && this.hasTransition() && frameTime > this.getDuration()) {
      this.selectedSlideUID = this.slides.getLastSlide().uid;
      this.slides.hideAllSlides();
      return;
    }

    this.updateSelectedSlideForTime(frameTime);

    if (frameTime <= this.introAnimationPadding) {
      this.conditionallyHideFirstSlide();
    }

    if (this.hasIntroDelay() && this.slides.getSlideIndex(this.selectedSlideUID) === 0) {
      if (frameTime < this.introAnimationPadding + this.introDelay) {
        this.conditionallyHideFirstSlide();
      } else {
        this.slides.showFirstSlide();
      }
    }

    const selectedSlide = this.getSelectedSlide();
    if (selectedSlide.isVideoSlide()) {
      await selectedSlide.setImageFrame(videoItemFrames[selectedSlide.uid]);
    }

    let animationTime;
    let currentAnimationType: AnimateItemType = AnimateItemType.INTRO_ANIMATE_ITEM;
    let isTransitioning;

    const startSlideTime = this.slides.getStartTimeForSlide(this.selectedSlideUID);

    if (startSlideTime === undefined) {
      throw new Error(`startSlideTime undefined for slide with id ${this.selectedSlideUID} of slideshow with id ${this.uid}`);
    }

    if ((!selectedSlide.isTextSlide() || (selectedSlide.isTextSlide() && !selectedSlide.isTextEmpty())) && this.hasTransition()) {
      if (this.isTransitioningOutTime(frameTime)) {
        isTransitioning = true;
        const remainingSlideTime = this.slides.getEndTimeForSlide(this.selectedSlideUID)! - frameTime;

        animationTime = this.getTransitionDuration() - remainingSlideTime;
        currentAnimationType = AnimateItemType.OUTRO_ANIMATE_ITEM;
      } else if (this.isTransitioningInTime(frameTime)) {
        isTransitioning = true;
        animationTime = frameTime - startSlideTime;
        currentAnimationType = AnimateItemType.INTRO_ANIMATE_ITEM;
      }
    }

    const isAnimationOutdated = this.animation && (this.animation.item.uid !== this.getSelectedSlide().uid || this.animation.type !== currentAnimationType);

    if (isAnimationOutdated && this.animation) {
      this.animation.stop();
      if (this.animation.internalOnComplete) {
        this.animation.internalOnComplete();
      }
    }

    if (isTransitioning && animationTime !== undefined) {
      if (!this.animation || isAnimationOutdated) {
        this.initializeAnimation(currentAnimationType);
      }
      this.animation?.setFrame(animationTime);
    }
  }

  private isTransitioningInTime(time: number): boolean {
    const startSlideTime = this.slides.getStartTimeForSlide(this.selectedSlideUID);
    if (startSlideTime === undefined) {
      throw new Error(`startSlideTime undefined for slide with id ${this.selectedSlideUID} of slideshow with id ${this.uid}`);
    }
    const slideTimeElapsed = time - startSlideTime;
    const slideIndex = this.slides.getSlideIndexForTime(time);
    return (
      slideTimeElapsed >= 0 &&
      slideTimeElapsed <= this.getTransitionDuration() &&
      (slideIndex !== 0 || (slideIndex === 0 && (this.hasIntroOutroTransition || this.hasIntroDelay())))
    );
  }

  private initializeAnimation(AnimationType: AnimateItemType): void {
    if (AnimationType === AnimateItemType.INTRO_ANIMATE_ITEM) {
      this.animation = new IntroAnimateItem(this.getSelectedSlide(), this.transition.type, {
        duration: this.getTransitionDuration(),
      });
      this.getSelectedSlide().fabricObject.set('opacity', 1);
    } else if (AnimationType === AnimateItemType.OUTRO_ANIMATE_ITEM) {
      this.animation = new OutroAnimateItem(this.getSelectedSlide(), getOutroForIntroAnimation(this.transition.type), {
        duration: this.getTransitionDuration(),
      });
    }
  }

  public updateActiveText(text = '', timeout = 0): void {
    if (this.textChangedTimeout > 0) {
      window.clearTimeout(this.textChangedTimeout);
    }

    if (this.clone) {
      const slideId = this.selectedSlideUID;
      const slide = this.slides.slidesHashMap[slideId] as TextSlideItem;
      const cloneDimensions = {
        width: this.clone.width + this.clone.strokeWidth,
        height: this.clone.height + this.clone.strokeWidth,
      };
      const selectorHorizontalPadding = getScaledManualHorizontalPadding(this.fabricObject.scaleX);
      const selectorVerticalPadding = getScaledManualVerticalPadding(this.fabricObject.scaleY);
      const heightWithoutVerticalPadding = this.clone.height + this.clone.strokeWidth + selectorVerticalPadding;
      const newVerticalPadding = Math.max(this.height - heightWithoutVerticalPadding, 0);
      // caching these 2 variables before updating group dimensions as they are needed in the next step.
      const cloneCenterPoint = this.clone.getCenterPoint();
      const groupBottomLeft = this.fabricObject.aCoords.bl;
      // This is old js code and prevBulletsWidth always is 0.
      // const prevBulletsWidth = slide.list.width;
      const prevBulletsWidth = slide.getBulletsWidth();

      const doCloneDimensionsExceedModel =
        this.clone.dynamicMinWidth + this.clone.strokeWidth + selectorHorizontalPadding > this.width - prevBulletsWidth ||
        this.clone.height + this.clone.strokeWidth + selectorVerticalPadding > this.height;
      let newModelCoords: Point = {
        x: this.x,
        y: this.y,
      };

      let slideWidth = slide.width - prevBulletsWidth;
      let slideHeight = slide.height;
      let groupCoordinates: ItemCoordinates;

      // line changed from old js, seemed to be causing width issues. come back to this if revisiting clone issues.
      this.clone.width = Math.max(this.clone.dynamicMinWidth, slide.baseWidth);

      if (pastedTextListType !== LIST_TYPES.NONE && !slide.list.type) {
        const updatedList = slide.getDefaultList();
        updatedList.type = pastedTextListType;
        slide.list = updatedList;
        pastedTextListType = LIST_TYPES.NONE;
      }
      // spm_.hideSelectionTextPopUp();
      slide.setBulletPoints();
      slideWidth += slide.getBulletsWidth(this.clone);

      if (doCloneDimensionsExceedModel) {
        const newSlideDimensions = this.getUpdatedTextGroupDimensions(cloneDimensions, newVerticalPadding);
        slide.fabricObject.set(newSlideDimensions);
        this.updateGroupViewComponentDimensions();

        newModelCoords = slide.getUpdatedModelCoords(cloneCenterPoint, groupBottomLeft, this.clone) as Point;
        this.fabricObject.set({
          left: newModelCoords.x,
          top: newModelCoords.y,
        });

        groupCoordinates = newModelCoords;
        slideWidth = newSlideDimensions.width;
        slideHeight = newSlideDimensions.height;
      } else {
        groupCoordinates = {x: this.x, y: this.y};
        this.fabricObject.set({
          left: this.x,
          top: this.y,
          width: this.width - prevBulletsWidth + slide.getBulletsWidth(this.clone),
          height: this.height,
        });
      }

      slide.fabricObject.set({
        width: this.fabricObject.width,
        height: this.fabricObject.height,
        left: -this.fabricObject.width / 2,
        top: -this.fabricObject.height / 2,
      });

      slide.setBulletsPosition(this.clone);
      this.clone.set(slide.getTextCloneAbsolutePosition(this.clone));
      slide.setBackgroundParams();

      const updateTextParams: UpdateTextProps = {
        slideWidth,
        slideHeight,
        groupCoordinates,
        text,
        slideId,
        newVerticalPadding,
      };

      this.textChangedTimeout = window.setTimeout(this.updateText.bind(this, updateTextParams), timeout);
      // facade_.sendNotification(postermywall.Core.UPDATE_SLIDE_TEXTAREA, {text: text});
    }
  }

  public doesfabricObjBelongtoItem(fabricObj: FabricObject): boolean {
    return this.fabricObject === fabricObj || this.clone === fabricObj;
  }

  public restoreDefaultState(): void {
    if (this.clone) {
      this.clone.exitEditing();
      this.page.activeSelection.setActiveObject(this.fabricObject);
    }
  }

  /**
   * Keeps the 1st slide's visibility updated according to time if slideshow has intro delay.
   */
  protected updateFirstSlideWithDelayVisibilty(time: number): void {
    if ((this.hasIntroDelay() || this.hasIntroOutroTransition) && this.slides.getSlideIndex(this.selectedSlideUID) === 0) {
      if (time < this.introAnimationPadding + this.introDelay && this.page.poster.isPlaying()) {
        this.conditionallyHideFirstSlide();
      } else {
        this.slides.showFirstSlide();
      }
    }
  }

  /**
   * Whether to start transition to next slide or not
   */
  protected isTimeToTransitionToNextSlide(time: number): boolean {
    if (this.transitionLock) {
      return false;
    }
    this.transitionLock = true;

    // Don't start transition if
    // 1-Slides are already transitioning
    // 2-Poster is not playing
    // 3-Its the last slide and the transition intro & outro setting is off
    if (this.isSlideshowAnimationPlaying || !this.page.poster.isPlaying() || (!this.slides.hasNextSlide() && !this.hasIntroOutroTransition)) {
      this.transitionLock = false;
      return false;
    }

    const endTimeForSlide = this.slides.getEndTimeForSlide(this.selectedSlideUID);
    if (endTimeForSlide) {
      const remainingSlideTime = endTimeForSlide - time;
      const startTransition = remainingSlideTime <= this.getTransitionDuration() && remainingSlideTime > 0;

      this.isSlideshowAnimationPlaying = startTransition;
      this.transitionLock = false;
      return startTransition;
    }
    return false;
  }

  /**
   * Whether it's time to transition in the first slide.
   */
  protected timeForFirstSlideTransitionIn(time: number): boolean {
    // Don't start transition if
    // 1-The first slide isn't the selected slide
    // 2-Slides are already transitioning
    // 3-Poster is not playing
    if (this.slides.getSlideIndex(this.selectedSlideUID) !== 0 || this.isSlideshowAnimationPlaying || !this.page.poster.isPlaying()) {
      return false;
    }

    const slideStartTime = this.slides.getStartTimeForSlide(this.selectedSlideUID) ?? 0;
    const startTransition = time > slideStartTime && time < slideStartTime + this.getTransitionDuration();

    this.isSlideshowAnimationPlaying = startTransition;
    return startTransition;
  }

  /**
   * Starts the transitions out/in of the current slide to next slide.
   */
  protected startTransition(): void {
    const nextSlideIndex = this.slides.getNextSlideIndex();
    if (nextSlideIndex === undefined && !this.hasIntroOutroTransition) {
      return;
    }

    this.animation = new OutroAnimateItem(this.getSelectedSlide(), getOutroForIntroAnimation(this.transition.type), {
      onComplete: this.onTransitionOutComplete.bind(this, nextSlideIndex),
      duration: this.getTransitionDuration(),
    });

    this.animation.start();
  }

  /**
   * Is called after transition out animation is over. If animation was interuppted, it displays the current slide on the canvas.
   * If animation was not interrupted, calls the transitionIn() function to continue the animation to the next slide.
   */
  protected onTransitionOutComplete(nextSlideIndex: undefined | number): void {
    if (this.isPosterStoppedWhileTransitioning) {
      this.isSlideshowAnimationPlaying = false;
      this.isPosterStoppedWhileTransitioning = false;
      this.dfd?.resolve();
    } else if (this.isPosterPausedOrInterruptedWhileTransitioning) {
      this.setSelectedSlideAndUpdateView(this.selectedSlideUID);
      this.isSlideshowAnimationPlaying = false;
      this.isPosterPausedOrInterruptedWhileTransitioning = false;
      this.dfd?.resolve();
    } else if (nextSlideIndex) {
      this.hideSlide(this.slides.slidesHashMap[this.selectedSlideUID]);
      this.transitionIn(nextSlideIndex);
    } else {
      this.isSlideshowAnimationPlaying = false;
    }
    this.updateRenderFlagsOfSlides();
  }

  private showSlide(slideItem: SlideItem): void {
    slideItem.fabricObject.set({opacity: 1});
    slideItem.loading.showLoading();
  }

  private hideSlide(slideItem: SlideItem): void {
    slideItem.fabricObject.set({opacity: 0});
    slideItem.loading.hideLoading();
  }

  private updateRenderFlagsOfSlides(): void {
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      slide.fabricObject.dirty = true;
    }
  }

  /**
   * Transitions in (animates) to the next slide.
   */
  protected transitionIn(slideIndex: number): void {
    const targetSlide = this.slides.slidesHashMap[this.slides.slidesOrder[slideIndex]];
    this.setSelectedSlide(targetSlide.uid);

    this.animation = new IntroAnimateItem(targetSlide, this.transition.type, {
      onComplete: this.onTransitionInComplete.bind(this),
      duration: this.getTransitionDuration(),
    });
    this.showSlide(this.slides.slidesHashMap[targetSlide.uid]);
    void this.play();
    this.animation.start();
  }

  /**
   * Is called after transition in animation is over. If animation was interuppted, it displays the current slide on the canvas.
   */
  protected onTransitionInComplete(): void {
    this.isSlideshowAnimationPlaying = false;
    if (this.isUpdateFabricObjectPending) {
      this.updateFabricObject().catch(() => {});
      this.isUpdateFabricObjectPending = false;
    }
    if (this.isPosterStoppedWhileTransitioning) {
      this.isPosterStoppedWhileTransitioning = false;
      this.dfd?.resolve();
    } else if (this.isPosterPausedOrInterruptedWhileTransitioning) {
      this.isPosterPausedOrInterruptedWhileTransitioning = false;
      this.dfd?.resolve();
    }
  }

  /**
   * Returns true if the transition In and Out times of the slide overlap.
   * This is the case when the slide duration is smaller than the total transition duration (sum of transition in and out durations).
   */
  protected areSlideTransitionTimesOverlapping(): boolean {
    return (
      this.isTransitioningOutTime(this.page.poster.clock.getCurrentTime()) &&
      this.isSlideshowAnimationPlaying &&
      !!this.animation &&
      this.animation.item.uid === this.getSelectedSlide().uid &&
      this.animation instanceof IntroAnimateItem
    );
  }

  /**
   * Returns true if the poster time passed is in the interval of a slide transitioning out.
   */
  protected isTransitioningOutTime(time: number): boolean {
    if (!this.slides.getSlideForId(this.selectedSlideUID)) {
      return false;
    }

    const endTimeForSlide = this.slides.getEndTimeForSlide(this.selectedSlideUID);
    if (endTimeForSlide) {
      const remainingSlideTime = endTimeForSlide - time;
      const startTransition = remainingSlideTime <= this.getTransitionDuration() && remainingSlideTime >= 0;
      const nextSlideExists = this.slides.hasNextSlide();

      return startTransition && (nextSlideExists || (!nextSlideExists && this.hasIntroOutroTransition));
    }
    return false;
  }

  /**
   * Stops the unselected video slides. Playing of selected video slide is handled by checkForPausedStream() function.
   */
  public async play(): Promise<void> {
    this.restoreDefaultState();
    this.stopUnselectedVideoSlides();
  }

  /**
   * Pause slideshow by stopping any currently running transitions.
   */
  public async pause(): Promise<void> {
    this.dfd = new Deferred();
    const selectedSlide = this.getSelectedSlide();

    const promises = [];
    if (selectedSlide !== undefined && selectedSlide.isVideoSlide()) {
      promises.push(selectedSlide.pause());
    }
    await Promise.all(promises);

    if (this.isSlideshowAnimationPlaying) {
      this.isPosterPausedOrInterruptedWhileTransitioning = true;
      this.stopAnimation();
    } else {
      this.dfd.resolve();
    }

    return this.dfd.promise as Promise<void>;
  }

  public onPosterSeeked(): Promise<void> {
    this.dfd = new Deferred();

    if (this.isSlideshowAnimationPlaying) {
      this.isPosterPausedOrInterruptedWhileTransitioning = true;
      this.stopAnimation();
    } else {
      this.dfd.resolve();
    }
    return this.dfd.promise as Promise<void>;
  }

  /**
   * Stop slideshow and stop transition, if transitioning.
   */
  public stop(): Promise<void> {
    return new Promise((resolve) => {
      void this.stopActiveAnimation().then(() => {
        this.setSelectedSlideAndUpdateView(this.slides.slidesOrder[0]);
        this.stopAllVideoSlides();
        resolve();
      });
    });
  }

  protected stopActiveAnimation(): Promise<void> {
    this.dfd = new Deferred();

    if (this.isSlideshowAnimationPlaying) {
      this.isPosterStoppedWhileTransitioning = true;
      this.stopAnimation();
    } else {
      this.dfd.resolve();
    }

    return this.dfd.promise as Promise<void>;
  }

  /**
   * Stops any unselected/inactive video slides.
   */
  protected stopUnselectedVideoSlides(): void {
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.isVideoSlide() && slide.uid !== this.selectedSlideUID) {
        slide.stopSlide();
      }
    }
  }

  protected stopAllVideoSlides(): void {
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.isVideoSlide()) {
        // slide.stopSlide();
        void slide.stop();
      }
    }
  }

  protected stopAnimation(): void {
    if (this.animation) {
      this.animation.stop();
    }
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    const isItemLocked = this.isLocked();
    this.fabricObject.setControlsVisibility({
      pmwMr: !isItemLocked,
      pmwMl: !isItemLocked,
      pmwMt: !isItemLocked,
      pmwMb: !isItemLocked,
      pmwPreviousSlideBtn: true,
      pmwNextSlideBtn: true,
    });
  }

  protected updateItemDimensions(): void {}

  public deleteSlide(uid: string, undoable = true): void {
    if (Object.keys(this.slides.slidesHashMap).length === 1) {
      if (this.slides.slidesOrder[0] === uid) {
        this.page.poster.deleteItemById(this.uid, true);
      } else {
        console.error(`Deleting a slide which doesn't exist in slideshow item class`);
      }
    } else {
      const {slidesHashMap, slidesOrder} = this.slides.toObject();
      const indexOfItemToDelete = slidesOrder.indexOf(uid);

      if (indexOfItemToDelete !== -1) {
        slidesOrder.splice(indexOfItemToDelete, 1);
      }

      if (slidesHashMap[uid]) {
        delete slidesHashMap[uid];
      }

      void this.updateFromObject(
        {
          slides: {
            slidesHashMap,
            slidesOrder,
          },
        },
        {checkForDurationUpdate: true, undoable}
      );
    }
  }

  public isStreamingMediaItem(): boolean {
    return true;
  }

  public async updateFromObject(
    slideshowItemObject: DeepPartial<SlideshowItemObject>,
    {updateRedux = true, undoable = true, checkForDurationUpdate = false, replayPosterOnUpdateDone = false}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    const {slides, transition, ...slideshowItemObjectWithoutSlides} = slideshowItemObject;

    this.copyVals({
      ...slideshowItemObjectWithoutSlides,
    });

    await this.init();

    if (slides) {
      await this.slides.updateFromObject(slides, {updateRedux: false, undoable: false, doInvalidate: false});
    }

    if (transition) {
      this.transition.updateFromObject(transition);
    }

    this.applyFixForVersion2();
    await this.invalidate();
    this.fabricObject.setCoords();

    await this.onItemUpdatedFromObject();

    if (checkForDurationUpdate) {
      this.checkItemForPageDurationUpdate();
    }
    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
    if (replayPosterOnUpdateDone) {
      await this.page.poster.replayPoster();
    }
  }

  protected async onItemUpdatedFromObject(): Promise<void> {
    const promises = [];
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.isImageSlide()) {
        promises.push(slide.checkForUploadComplete());
      }
    }

    const responses = await Promise.allSettled(promises);
    for (const response of responses) {
      if (response.status === 'rejected') {
        if (this.page.poster.mode.isGeneration()) {
          console.log('Slideshow image slides failed on checkForUploadComplete. Details:', response.reason);
          throw response.reason;
        }
        console.error('Slideshow image slides failed on checkForUploadComplete. Details:', response);
      }
    }
  }

  public toObject(): SlideshowItemObject {
    return {
      ...super.toObject(),
      transition: this.transition.toObject(),
      introAnimationPadding: this.introAnimationPadding,
      introDelay: this.introDelay,
      hasIntroOutroTransition: this.hasIntroOutroTransition,
      slides: this.slides.toObject(),
      selectedSlideUID: this.selectedSlideUID,
    };
  }

  protected async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();

    if (this.isSlideshowAnimationPlaying) {
      this.isUpdateFabricObjectPending = true;
    } else {
      if (typeof this.fabricObject.group !== 'undefined') {
        return;
      }

      await this.updateSlideshowFabricObject();
    }
  }

  protected async updateSlideshowFabricObject(): Promise<void> {
    // await this.updateSlides_();
    await this.updateSlidesFabricObject();

    this.applyTextStylesAndStrokeOnSelectedSlide();
    this.updateGroupDimensions();
    this.slides.updateSlideDimensions();
    this.slides.updateSlidePositions();
    this.setCloneProperties();

    this.updateSlideVisibility();
    // added line because we're not syncing slideshow now until poster time is updated. see if this is alright or should it be syncSlideshow()
    this.updateSelectedSlideForTime(this.page.poster.clock.getCurrentTime());
  }

  protected applyTextStylesAndStrokeOnSelectedSlide(): void {
    const selectedSlide = this.getSelectedSlide();
    if (selectedSlide.isTextSlide()) {
      this.applyTextStylesAndStrokeOnSlide(selectedSlide, selectedSlide.fabricTextbox);
    }
  }

  protected applyTextStylesAndStrokeOnClone(): void {
    const selectedSlide = this.getSelectedSlide();
    if (selectedSlide.isTextSlide() && this.clone !== null) {
      this.applyTextStylesAndStrokeOnSlide(selectedSlide, this.clone);
    }
  }

  protected applyTextStylesAndStrokeOnSlide(slide: TextSlideItem, fabricTextboxItem: Textbox): void {
    fabricTextboxItem.set(slide.textStyles.getTextStyles(fabricTextboxItem.width, fabricTextboxItem.height));
    slide.applyTextStroke(fabricTextboxItem);
  }

  protected async updateSlidesFabricObject(): Promise<void> {
    const promises = [];
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      promises.push(slide.updateFabricObject());
    }
    await Promise.all(promises);
  }

  /**
   * Update slideshow group's viewComponent and model dimensions depending upon slide dimensions
   */
  protected updateGroupDimensions(): void {
    this.updateGroupViewComponentDimensions();
    this.width = this.fabricObject.width;
    this.height = this.fabricObject.height;
  }

  protected getFabricObjectForItem(): Promise<Group> {
    return new Promise((resolve) => {
      const obj = new Group([], {
        ...super.getCommonOptions(),
        objectCaching: false,
        useSelectedFlag: true,
        perPixelTargetFind: true,
        layoutManager: new LayoutManager(new FixedLayout()),
      });

      resolve(obj);
    });
  }

  protected initCustomControls(): void {
    super.initCustomControls();
    const pmwMlControl = getPmwMlControl(this.onResizeWithLeftHandle.bind(this));
    const pmwMrControl = getPmwMrControl(this.onResizeWithRightHandle.bind(this));
    const pmwMtControl = getPmwMtControl(this.onResizeWithTopHandle.bind(this));
    const pmwMbControl = getPmwMbControl(this.onResizeWithBottomHandle.bind(this));
    const pmwPreviousSlideBtnControl = this.slideshowItemCustomControls.getPreviousSlideControl();
    const pmwNextSlideBtnControl = this.slideshowItemCustomControls.getNextSlideControl();
    this.fabricObject.controls[pmwMlControl.key] = pmwMlControl.control;
    this.fabricObject.controls[pmwMrControl.key] = pmwMrControl.control;
    this.fabricObject.controls[pmwMtControl.key] = pmwMtControl.control;
    this.fabricObject.controls[pmwMbControl.key] = pmwMbControl.control;
    this.fabricObject.controls[pmwPreviousSlideBtnControl.key] = pmwPreviousSlideBtnControl.control;
    this.fabricObject.controls[pmwNextSlideBtnControl.key] = pmwNextSlideBtnControl.control;
  }

  protected initEvents(): void {
    super.initEvents();
    this.fabricObject.on('mousedown:before', this.onMouseDownBefore.bind(this));
    this.fabricObject.on('mouseup', this.onMouseUp.bind(this));
    this.fabricObject.on('mouseup:before', this.onMouseUpBefore.bind(this));
    this.fabricObject.on('deselected', this.onDeselected.bind(this));
  }

  protected onFabricObjectModified(): void {
    const slideObjects: Record<string, any> = {};

    for (const [key, slide] of Object.entries(this.slides.slidesHashMap)) {
      slideObjects[key] = slide.toObject();
      if (slide.isTextSlide()) {
        const textSlide = slideObjects[key] as TextItemObject;
        textSlide.baseWidth = slide.getBaseWidth();
        textSlide.width = this.fabricObject.width;
        textSlide.height = this.fabricObject.height;
        textSlide.verticalPadding = slide.getVerticalPadding();
      }
    }

    this.updateFromObject({
      ...this.getValuesOnFabricObjectModified(),
      slides: {
        slidesHashMap: slideObjects,
        slidesOrder: this.slides.slidesOrder,
      },
    }).catch((e) => {
      console.error(e);
      console.error(`Failed to update item on fabric object modified, Details:${JSON.stringify(e)}`);
    });
  }

  public addSlide(newSlide: SlideItem, slideIndex: number, {updateRedux = true, undoable = true}: AddItemOpts = {}): void {
    newSlide.page = this.page;
    this.slides.addSlideToHashmap(newSlide);
    this.slides.slidesOrder.splice(slideIndex, 0, newSlide.uid);
    newSlide.onItemAddedToPage();
    this.addSlideToGroupWithOriginalScale(newSlide.fabricObject);
    this.onSlideAdded(newSlide);

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
  }

  protected onSlideAdded(slideItem: SlideItem): void {
    if (this.selectedSlideUID === '' && slideItem.uid === this.slides.slidesOrder[0]) {
      this.selectedSlideUID = slideItem.uid;
    }
    slideItem.fabricObject.set({
      left: this.fabricObject.left,
      top: this.fabricObject.top,
    });
  }

  public addSlideToGroupWithOriginalScale(newSlide: FabricObject): void {
    addItemsToGroupWithOriginalScale(this.fabricObject, [newSlide]);
  }

  protected onDeselected(): void {
    const selectedSlide = this.getSelectedSlide();
    if (selectedSlide.isTextSlide()) {
      this.page.spellCheck.clearSquigglyLineStyle(selectedSlide.fabricTextbox);
    }
  }

  /**
   * Selects the previous slide
   */
  public selectPreviousSlide(): void {
    const previousSlideIndex = this.slides.getPreviousSlideIndex();
    if (previousSlideIndex !== undefined) {
      this.slides.seekToSlideIndex(previousSlideIndex);
    }
  }

  /**
   * Selects the next slide
   */
  public selectNextSlide(): void {
    const nextSlideIndex = this.slides.getNextSlideIndex();
    if (nextSlideIndex !== undefined) {
      this.slides.seekToSlideIndex(nextSlideIndex);
    }
  }

  protected getMinWidth(): number {
    let maxSlideMinWidth = 0;
    let itemMinWidth = 0;

    const firstSlide = this.slides.slidesHashMap[this.slides.slidesOrder[0]];
    if (firstSlide.isTextSlide()) {
      maxSlideMinWidth = firstSlide.getMinWidth();
      itemMinWidth = maxSlideMinWidth;
    }

    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      itemMinWidth = slide.isTextSlide() ? slide.getMinWidth() : 2;

      if (itemMinWidth > maxSlideMinWidth) {
        maxSlideMinWidth = itemMinWidth;
      }
    }

    return maxSlideMinWidth;
  }

  /**
   * Returns the minimum possible height of group viewComponent according to the possible min height of slides.
   */
  protected getMinHeight(): number {
    let maxSlideMinHeight = 0;
    let itemMinHeight = 0;

    const firstSlide = this.slides.slidesHashMap[this.slides.slidesOrder[0]];
    if (firstSlide.isTextSlide()) {
      maxSlideMinHeight = firstSlide.getMinHeight();
      itemMinHeight = maxSlideMinHeight;
    }

    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      itemMinHeight = slide.isTextSlide() ? slide.getMinHeight() : 2;

      if (itemMinHeight > maxSlideMinHeight) {
        maxSlideMinHeight = itemMinHeight;
      }
    }

    return maxSlideMinHeight;
  }

  protected resizeGroupItemsWidth(newWidth: number): void {
    if (this.slides.hasTextSlide()) {
      this.resizeWidthOfGroupItemsContainingText(newWidth);
    } else {
      for (const [slideId] of Object.entries(this.slides.slidesHashMap)) {
        this.slides.scaleMediaSlide(slideId);
      }
    }
    this.slides.updateSlidePositions();
    this.updateLoadingOnItemResize();
  }

  /**
   * Resizes the items in the group to the new height of group
   */
  protected resizeGroupItemsHeight(newHeight: number): void {
    for (const [slideId, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.isTextSlide()) {
        slide.fabricObject.set({
          height: newHeight,
        });

        slide.onModifyHeight();
      } else {
        this.slides.scaleMediaSlide(slideId);
      }
    }
    this.slides.updateSlidePositions();
    this.updateLoadingOnItemResize();
  }

  private updateLoadingOnItemResize(): void {
    this.loading.updateTextOnItemResizeAndRender();
    this.getSelectedSlide().loading.updateTextOnItemResizeAndRender();
  }

  /**
   * Resizes the items in the group containing text slides to the new width of group
   */
  protected resizeWidthOfGroupItemsContainingText(newWidth: number): void {
    let slideNewHeight = 0;
    let maxSlideHeight = 0;

    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.isTextSlide()) {
        slide.fabricObject.set({
          width: newWidth,
        });

        slide.onModifyWidth();
        slideNewHeight = slide.fabricObject.getScaledHeight();
      }

      if (slideNewHeight > maxSlideHeight) {
        maxSlideHeight = slideNewHeight;
      }
    }

    this.fabricObject.set({height: maxSlideHeight});

    for (const [slideId, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.isTextSlide()) {
        slide.fabricObject.set({
          height: maxSlideHeight,
        });

        slide.onModifyHeight();
      } else {
        this.slides.scaleMediaSlide(slideId);
      }
    }
  }

  /**
   * Handler for adjusting width of item using right handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  protected onResizeWithRightHandle(event: OnResizeParams): void {
    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < this.getMinWidth() || this.fabricObject.getScaledWidth() + event.delta < MIN_SCALED_DIMENSION) {
      return;
    }

    this.fabricObject.set({width: newWidth});
    this.resizeGroupItemsWidth(newWidth);
  }

  /**
   * Handler for adjusting width of item using left handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  private onResizeWithLeftHandle(event: OnResizeParams): void {
    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < this.getMinWidth() || this.fabricObject.getScaledWidth() + event.delta < MIN_SCALED_DIMENSION) {
      return;
    }

    this.fabricObject.set({
      width: newWidth,
      left: this.fabricObject.left - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
    });
    this.resizeGroupItemsWidth(newWidth);
  }

  /**
   * Handler for adjusting height of item using bottom handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  private onResizeWithBottomHandle(event: OnResizeParams): void {
    const newHeight = this.fabricObject.height + event.delta / this.fabricObject.scaleY;
    if (newHeight < this.getMinHeight() || this.fabricObject.getScaledHeight() + event.delta < MIN_SCALED_DIMENSION) {
      return;
    }

    this.fabricObject.set({height: newHeight});
    this.resizeGroupItemsHeight(newHeight);
  }

  /**
   * Handler for adjusting height of item using top handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  private onResizeWithTopHandle(event: OnResizeParams): void {
    const newHeight = this.fabricObject.height + event.delta / this.fabricObject.scaleY;
    if (newHeight < this.getMinHeight() || this.fabricObject.getScaledHeight() + event.delta < MIN_SCALED_DIMENSION) {
      return;
    }

    this.fabricObject.set({
      height: newHeight,
      left: this.fabricObject.left + event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
    });

    this.resizeGroupItemsHeight(newHeight);
  }

  public onScaling(): void {
    this.checkForMinimumScale();

    const slideIds = Object.keys(this.slides.slidesHashMap);

    slideIds.forEach((slideId) => {
      this.slides.slidesHashMap[slideId].onScaling();
    });

    if (this.slides.hasTextSlide()) {
      const maxSlideDimensions = this.slides.getMaxDimensionsOfTextSlides();

      this.fabricObject.set({
        width: maxSlideDimensions.width,
        height: maxSlideDimensions.height,
      });
    }

    this.slides.updateSlideDimensions();
    this.slides.updateSlidePositions();
    this.loading.updateTextOnItemResizeAndRender();
  }

  protected checkForMinimumScale(): void {
    let newScale;

    if (this.fabricObject.getScaledWidth() < MIN_SCALED_DIMENSION) {
      newScale = MIN_SCALED_DIMENSION / this.fabricObject.width;
      this.fabricObject.set({
        scaleX: newScale,
        scaleY: newScale,
      });
    }
    if (this.fabricObject.getScaledHeight() < MIN_SCALED_DIMENSION) {
      newScale = MIN_SCALED_DIMENSION / this.fabricObject.height;
      this.fabricObject.set({
        scaleX: newScale,
        scaleY: newScale,
      });
    }
  }

  protected onMouseDownBefore(): void {
    if (this.fabricObject === this.page.activeSelection.getActiveObject()) {
      this.beforeEnterEditMode();
    }
  }

  private onMouseUp(options: ObjectEvents['mouseup']): void {
    // Don't enter the editing mode if the mouse up event happened because of an action performed by control button
    if (
      !this.enterEditModeOnMouseUp ||
      options.transform?.actionPerformed ||
      // @ts-expect-error TODO FABRIC6
      (options.e.button && options.e.button !== 1) ||
      options.transform?.action === ItemControlOption.SLIDE_BTN_ACTION_NAME
    ) {
      this.enterEditModeOnMouseUp = true;
      return;
    }

    this.onEnterEditMode(options.e);
  }

  private onMouseUpBefore(options: ObjectEvents['mouseup:before']): void {
    if (this.page.longPressInitiated || config.isCanvasTwoFingerPanning || options.target?.__corner) {
      this.enterEditModeOnMouseUp = false;
    }
  }

  public enterEditMode(e: MouseEvent): void {
    this.beforeEnterEditMode();
    this.onEnterEditMode(e);
  }

  protected beforeEnterEditMode(): void {
    this.fabricObject.selected = true;
  }

  protected onEnterEditMode(e: TPointerEvent): void {
    const cloneExists = !!this.clone;
    if (!cloneExists && this.fabricObject.selected && this.slides.isSlideEditable(this.selectedSlideUID)) {
      void this.page.poster.pause();
      this.enterCloneEditMode(e);

      this.clone?.selectAll();
    }
  }

  private enterCloneEditMode(e: TPointerEvent): void {
    const canvas = this.page.fabricCanvas;
    const textSlideItem = this.slides.slidesHashMap[this.selectedSlideUID] as TextSlideItem;
    const textSlideFabricObejct = textSlideItem.fabricTextbox;
    const index = canvas.getObjects().indexOf(this.fabricObject);

    this.clone = new Textbox(textSlideFabricObejct.text ?? '', {});
    this.clone.__PMWID = this.uid;
    this.clone.padding = 0;

    textSlideFabricObejct.set({opacity: 0});
    canvas.add(this.clone);
    canvas.moveObjectTo(this.clone, index + 1);
    if (canvas instanceof Canvas) {
      canvas.setActiveObject(this.clone);
    }

    this.clone.on('editing:entered', this.onTextEditModeEntered.bind(this));
    this.clone.on('editing:exited', this.onEditingExited.bind(this));

    this.clone.enterEditing(e);
    this.setCloneProperties();
    this.page.canvasPanOnTextEdit.beforeTextEditEntered();
    const cloneHiddenTextarea = this.clone.hiddenTextarea;
    if (cloneHiddenTextarea) {
      cloneHiddenTextarea.addEventListener('paste', (event) => {
        void this.onPasteText(event);
      });
      cloneHiddenTextarea.addEventListener('input', this.onTextInput.bind(this));
    }

    this.clone.on('changed', () => {
      this.onCanvasTextChange(this.clone?.text);
    });
    this.clone.on('selection:changed', this.onSelectionChanged.bind(this));
  }

  private onTextInput(): void {
    setTimeout(() => {
      this.page.canvasPanOnTextEdit.scrollCanvasToText();
    }, 150);
  }

  public onSelectionChanged(): void {
    if (shouldShowTextSelectionPopup(this.clone)) {
      handleTextSelectionPopupMenu(this.clone);
    } else if (getIsSpellCheckEnabledFromStore() && this.clone) {
      void this.page.spellCheck.handleSuggestionPopupMenu(this.clone);
    } else {
      closeTextSelectionPopup();
    }
  }

  protected onTextEditModeEntered(): void {
    if (this.clone && this.clone.hiddenTextarea) {
      this.clone.hiddenTextarea.setAttribute('maxlength', MAX_CHARACTERS_LIMIT);
      this.page.spellCheck.checkSpell(this.clone, true).catch(() => {});
    }
  }

  protected onEditingExited(): void {
    if (this.clone) {
      this.page.fabricCanvas.remove(this.clone);
      this.clone = null;
      this.updateSlideVisibility();
      this.page.spellCheck.closeSuggestionPopUp();
      closeTextSelectionPopup();
      this.page.canvasPanOnTextEdit.afterTextEditExited();
    }
  }

  /**
   * Sets the opacity of selected slide to 1 (overall slideshow's opacity) and to 0 for the remaining slides.
   */
  protected updateSlideVisibility(): void {
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.uid === this.selectedSlideUID) {
        this.showSlide(slide);
        this.updateSlideTextVisibility();
      } else {
        this.hideSlide(slide);
        if (slide.isTextSlide()) {
          slide.fabricTextbox.set({opacity: 1});
        }
      }
    }
  }

  /**
   * Sets the opacity of the first slide to 0 when the user has the transition intro & outro setting on,
   * so that the first slide can transition in.
   */
  protected conditionallyHideFirstSlide(): void {
    if ((this.hasIntroOutroTransition && this.hasTransition()) || this.hasIntroDelay()) {
      this.hideSlide(this.slides.slidesHashMap[this.slides.slidesOrder[0]]);
    }
  }

  /**
   * Hides last slide if current design time has passed its duration and intro/outo transition toggle is on with a transition selected.
   */
  protected conditionallyHideLastSlide(time: number): void {
    const slideEndTime = this.slides.getEndTimeForSlide(this.selectedSlideUID);
    if (this.hasIntroOutroTransition && this.hasTransition() && this.slides.isLastSlide(this.selectedSlideUID) && slideEndTime !== undefined && time > slideEndTime) {
      this.hideSelectedSlide();
    }
  }

  protected hideSelectedSlide(): void {
    this.hideSlide(this.getSelectedSlide());
  }

  /**
   * Hides the text of slide if clone is active (text is in editing state) and vice versa.
   */
  protected updateSlideTextVisibility(): void {
    const selectedSlide = this.getSelectedSlide();

    if (selectedSlide.isTextSlide() && selectedSlide.fabricTextbox) {
      if (this.clone) {
        selectedSlide.fabricTextbox.set({opacity: 0});
      } else {
        selectedSlide.fabricTextbox.set({opacity: 1});
      }
    }
  }

  public onCanvasTextChange(text = ''): void {
    this.updateActiveText(text, 500);
  }

  protected updateText(updateTextParams: UpdateTextProps): void {
    // let bulletsApplied = false;
    const slide = this.slides.slidesHashMap[updateTextParams.slideId] as TextSlideItem;
    const slidesObject = this.slides.toObject();
    const updatedTextSlideObject = slide.toObject();
    updatedTextSlideObject.text = updateTextParams.text;
    updatedTextSlideObject.width = updateTextParams.slideWidth;
    updatedTextSlideObject.height = updateTextParams.slideHeight;
    updatedTextSlideObject.verticalPadding = updateTextParams.newVerticalPadding;
    updatedTextSlideObject.textStyles = {
      ...updatedTextSlideObject.textStyles,
    };

    const scriptAndFontFamily = slide.textStyles.getLanguageScriptAndFontForText(updateTextParams.text);
    if (scriptAndFontFamily.script) {
      updatedTextSlideObject.textStyles.script = scriptAndFontFamily.script;
    }
    if (scriptAndFontFamily.fontFamily) {
      updatedTextSlideObject.textStyles.fontFamily = scriptAndFontFamily.fontFamily;
    }

    slidesObject.slidesHashMap[updatedTextSlideObject.uid] = updatedTextSlideObject;

    if (pastedTextListType !== LIST_TYPES.NONE) {
      const updatedList = slide.getDefaultList();
      updatedList.type = pastedTextListType;
      updatedTextSlideObject.list = updatedList;
      // bulletsApplied = true;
      pastedTextListType = LIST_TYPES.NONE;
    }
    void this.updateFromObject({
      x: updateTextParams.groupCoordinates.x,
      y: updateTextParams.groupCoordinates.y,
      slides: slidesObject,
      // slides: {
      //   slidesHashMap: slidesObject,
      // },
    });
    // if (bulletsApplied) {
    //   this.facade.sendNotification(postermywall.Core.REFRESH_TEXT_OPTIONS);
    // }
  }

  public async updateSlideFromObject(
    slideId: string,
    updatedSlideObject: DeepPartial<SlideItemObject>,
    {updateRedux = true, undoable = true, checkForDurationUpdate = false}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    const slidesObject: DeepPartial<SlideshowSlidesObject> = this.slides.toObject();

    if (slidesObject.slidesHashMap) {
      slidesObject.slidesHashMap[slideId] = updatedSlideObject;
    }

    await this.updateFromObject(
      {
        slides: slidesObject,
      },
      {
        updateRedux,
        undoable,
        checkForDurationUpdate,
      }
    );
  }

  protected getUpdatedTextGroupDimensions(cloneDimensions: FabricItemDimensions, newVerticalPadding: number): FabricItemDimensions {
    const selectedSlide = this.slides.slidesHashMap[this.selectedSlideUID] as TextSlideItem;
    return {
      height: cloneDimensions.height + getScaledManualVerticalPadding(this.fabricObject.scaleY) + newVerticalPadding,
      width: cloneDimensions.width + getScaledManualHorizontalPadding(this.fabricObject.scaleX) + selectedSlide.getBulletsWidth(this.clone),
    };
  }

  protected updateGroupViewComponentDimensions(): void {
    if (this.slides.hasTextSlide()) {
      const dimensions = this.slides.getMaxDimensionsOfTextSlides();

      if (dimensions.width !== this.fabricObject.width) {
        this.fabricObject.set({
          width: dimensions.width,
        });
      }

      if (dimensions.height !== this.fabricObject.height) {
        this.fabricObject.set({
          height: dimensions.height,
        });
      }
    }
    // if media slide is the 1st slide to be added, update dimensions according to media slide.
    else if (this.width === 0 || this.height === 0) {
      const maxPaddedDimensions = this.getPaddedDimensions(this.slides.getMaxDimensionsOfMediaSlides());
      this.fabricObject.set(maxPaddedDimensions);
    } else {
      this.fabricObject.width = this.width;
      this.fabricObject.height = this.height;
    }
  }

  protected getPaddedDimensions(unPaddedDimesions: FabricItemDimensions): FabricItemDimensions {
    return {
      width: unPaddedDimesions.width + getScaledManualHorizontalPadding(this.fabricObject.scaleX),
      height: unPaddedDimesions.height + getScaledManualVerticalPadding(this.fabricObject.scaleY),
    };
  }

  public updateIntroAnimationPadding(): void {
    this.introAnimationPadding = this.page.introAnimation.getAnimationMaxDuration();
  }

  /**
   * Returns the potential slideshow duration if a slide is added to the current slideshow.
   */
  public getSlideshowDurationAfterAddSide(): number {
    return this.getDuration() + DEFAULT_SLIDE_DURATION;
  }

  /**
   * Gets duration of slideshow item
   */
  public getDuration(): number {
    let duration = this.introAnimationPadding + this.introDelay;

    for (const id of this.slides.slidesOrder) {
      duration += this.slides.slidesHashMap[id].getSlideDuration();
    }

    return duration;
  }

  /**
   * Returns the duration time of the transition according to the transition speed.
   * IMPORTANT: MAKE SURE YOU CHANGE THE PHP getTransitionDuration FUNCTION TOO
   */
  public getTransitionDuration(): number {
    switch (this.transition.speed) {
      case AnimationSpeed.LOWER:
        return SlideshowTransitionDurations.LOWER_SPEED;

      case AnimationSpeed.LOW:
        return SlideshowTransitionDurations.LOW_SPEED;

      case AnimationSpeed.MEDIUM:
        return SlideshowTransitionDurations.MED_SPEED;

      case AnimationSpeed.HIGH:
        return SlideshowTransitionDurations.HIGH_SPEED;

      case AnimationSpeed.HIGHER:
        return SlideshowTransitionDurations.HIGHER_SPEED;

      default:
        console.error('Invalid transition speed value: ');
        return SlideshowTransitionDurations.MED_SPEED;
    }
  }

  public hasSingleSlide(): boolean {
    return Object.keys(this.slides.slidesHashMap).length === 1;
  }

  public isMultiSlide(): boolean {
    return Object.keys(this.slides.slidesHashMap).length > 1;
  }

  public isStreamingItem(): boolean {
    return this.slides.hasVideoSlide() || this.isMultiSlide() || this.hasIntroDelay() || (this.hasSingleSlide() && this.hasIntroOutroTransition && this.hasTransition());
  }

  public hasIntroDelay(): boolean {
    return this.introDelay > 0;
  }

  public hasTransition(): boolean {
    return isAnimation(this.transition.type);
  }

  public hasBlockTransition(): boolean {
    return isBlockAnimation(this.transition.type);
  }

  public onlyContainsEmptyTextSlide(): boolean {
    const slideIds = Object.keys(this.slides.slidesHashMap);
    if (slideIds.length === 1) {
      const onlySlide = this.slides.slidesHashMap[slideIds[0]];
      return onlySlide.isTextSlide() && onlySlide.isTextEmpty();
    }
    return false;
  }

  public isSlideshowVersion1(): boolean {
    return this.version === SLIDESHOW_VERSIONS.SLIDESHOW_VERSION_1;
  }

  public getManualSelectorPadding(): number {
    return ITEM_CONTROL_DIMENSIONS.PMW_ITEM_LEGACY_PADDING;
  }

  /**
   * Slideshow is valid if it has a minimum of one image, video, or non empty text slide
   */
  public isValid(): boolean {
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      if ((slide.isTextSlide() && slide.text) || slide.isMediaSlide()) {
        return true;
      }
    }
    return false;
  }

  protected applyFixForVersion2(): void {
    if (this.isSlideshowVersion1()) {
      this.width += getScaledManualHorizontalPadding(this.scaleX);
      this.height += getScaledManualVerticalPadding(this.scaleY);
      for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
        slide.width = this.width;
        slide.height = this.height;
        slide.version = TextVersion.TEXT_VERSION_2;
      }
      this.x -= this.getHorizontalDisplacementForVersion2();
      this.y -= this.getVerticalDisplacementForVersion2();
      this.version = SLIDESHOW_VERSIONS.SLIDESHOW_VERSION_2;
    }
    for (const [, slide] of Object.entries(this.slides.slidesHashMap)) {
      if (slide.isTextSlide()) {
        slide.fixVersioningChanges();
      }
    }
  }

  protected getHorizontalDisplacementForVersion2(): number {
    return Math.sqrt(2 * VERSION1_SELECTOR_PADDING ** 2) * Math.cos(util.degreesToRadians(45 + this.rotation));
  }

  protected getVerticalDisplacementForVersion2(): number {
    return Math.sqrt(2 * VERSION1_SELECTOR_PADDING ** 2) * Math.sin(util.degreesToRadians(45 + this.rotation));
  }

  public getSelectedSlide(): SlideItem {
    return this.slides.slidesHashMap[this.selectedSlideUID];
  }

  protected setCloneProperties(): void {
    const selectedSlide = this.getSelectedSlide();
    if (this.clone !== null && selectedSlide.isTextSlide()) {
      if (this.clone.hiddenTextarea) {
        this.clone.hiddenTextarea.value = selectedSlide.text;
      }

      this.clone.set({text: selectedSlide.text});
      this.applyTextStylesAndStrokeOnClone();

      const options = {
        ...this.getCommonOptions(),
        _fontSizeFraction: selectedSlide.fabricTextbox._fontSizeFraction,
        ...this.getOptionsForTextClone(selectedSlide),
      };

      this.clone.set(options);
      this.clone.set(selectedSlide.getTextCloneAbsolutePosition(this.clone));
      this.page.fabricCanvas.moveObjectTo(this.clone, this.page.fabricCanvas.getObjects().indexOf(this.fabricObject) + 1);
      this.clone.setCoords();

      if (this.spellCheckTimeout > 0) {
        clearTimeout(this.spellCheckTimeout);
      }

      this.spellCheckTimeout = window.setTimeout(this.doCheckSpell.bind(this), 500);
    }
  }

  protected doCheckSpell(): void {
    // if (!postermywall.Core.isPosterGenerating && !postermywall.Core.isViewPage) {
    if (this.clone) {
      this.page.spellCheck.checkSpell(this.clone, true).catch(() => {});
    }
    // }
  }

  protected getOptionsForTextClone(textSlideItem: TextSlideItem): Record<string, any> {
    return {
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
      cursorColor: textSlideItem.getCursorColor(),
      cursorWidth: textSlideItem.fabricTextbox.cursorWidth,
      fontSize: textSlideItem.fabricTextbox.fontSize,
      width: textSlideItem.fabricTextbox.width,
      fill: textSlideItem.fabricTextbox.fill,
      shadow: textSlideItem.fabricTextbox.shadow,
      cacheExpansionFactor: textSlideItem.fabricTextbox.cacheExpansionFactor,
    };
  }

  public hasEditMode(): boolean {
    return true;
  }

  public async addTextSlide(): Promise<void> {
    if (this.validateSlideshowUse()) {
      const lastTextSlide = this.slides.getLastTextSlide();
      let newTextSlide;

      // TODO: Maybe skip creating a new TextSlideItem (and eventually converting it to an object) and just do it through TextSlideItemObject.
      if (lastTextSlide) {
        newTextSlide = await createSlideItemFromObject(this.page, this, {
          ...lastTextSlide.toObject(),
          text: '',
          uid: uuidv4(),
        });
      } else {
        newTextSlide = await this.slides.getNewTextSlideItem();
      }

      const slidesObject = this.slides.toObject();
      const newSlidesOrder = slidesObject.slidesOrder;
      const newSlidesHashmap = slidesObject.slidesHashMap;
      const selectedSlideIndex = this.slides.getSlideIndex(this.selectedSlideUID);
      const targetIndex = selectedSlideIndex !== undefined ? selectedSlideIndex + 1 : 0;

      if (this.getDuration() + newTextSlide.getSlideDuration() > getMaxSlideshowDuration()) {
        newTextSlide.setSlideDuration(getMaxSlideshowDuration() - this.getDuration());
      }

      newSlidesOrder.splice(targetIndex, 0, newTextSlide.uid);
      newSlidesHashmap[newTextSlide.uid] = newTextSlide.toObject();

      await this.updateFromObject(
        {
          slides: {
            slidesOrder: newSlidesOrder,
            slidesHashMap: newSlidesHashmap,
          },
        },
        {checkForDurationUpdate: true}
      );

      this.slides.seekToSlideIndex(targetIndex);
      this.page.poster.fire('textSlide:added', newTextSlide.uid);
    }
  }

  public async prepareAndAddMediaSlides(elementsData: ItemData[]): Promise<void> {
    const imagesData: (ImageData | TempImageData | PMWStillStickerData | PMWExtractedGettyStickerData)[] = [];
    const videosData: VideoData[] = [];
    elementsData.forEach((data) => {
      if (data.type === ElementDataType.IMAGE || data.type === ElementDataType.STILL_STICKER || data.type === ElementDataType.EXTRACTED_GETTY_STICKER) {
        imagesData.push(data);
      } else if (data.type === ElementDataType.VIDEO) {
        videosData.push(data);
      }
    });
    if (imagesData.length) {
      await this.prepareAndAddImageSlides(imagesData);
    }
    if (videosData.length) {
      void this.prepareAndAddVideoSlides(videosData);
    }
  }

  protected async prepareAndAddImageSlides(elementsData: (ImageData | TempImageData | PMWStillStickerData | PMWExtractedGettyStickerData)[]): Promise<void> {
    let durationLimitReached = false;
    let additionalDuration = 0;
    const orderedImageSlidesUIDs: string[] = [];
    const slidesObject = this.slides.toObject();

    let newSlidesOrder = slidesObject.slidesOrder;
    const newSlidesHashmap = slidesObject.slidesHashMap;

    const selectedSlideIndex = this.slides.getSlideIndex(this.selectedSlideUID);
    const targetIndex = selectedSlideIndex !== undefined ? selectedSlideIndex + 1 : 0;
    const lastMediaSlideDuration = this.slides.getLastMediaSlideDuration();

    for (const elementData of elementsData) {
      if (this.getDuration() + additionalDuration >= getMaxSlideshowDuration()) {
        durationLimitReached = true;
        break;
      }

      let slideDuration = lastMediaSlideDuration !== undefined ? lastMediaSlideDuration : DEFAULT_SLIDE_DURATION;

      if (this.getDuration() + additionalDuration + slideDuration > getMaxSlideshowDuration()) {
        slideDuration = getMaxSlideshowDuration() - additionalDuration - this.getDuration();
      }

      additionalDuration += slideDuration;

      const slideId = 'uid' in elementData ? elementData.uid : uuidv4();
      orderedImageSlidesUIDs.push(slideId);

      const getSourceForStillSticker = (type: ElementDataType.STILL_STICKER | ElementDataType.EXTRACTED_GETTY_STICKER): string => {
        return type === ElementDataType.EXTRACTED_GETTY_STICKER ? PMWStockImageSource.PMW_EXTRACTED_GETTY_STICKER : PMWStockImageSource.PMW_STILL_STICKER;
      };

      newSlidesHashmap[slideId] = {
        gitype: ITEM_TYPE.IMAGESLIDE,
        imageSource: 'source' in elementData ? elementData.source : getSourceForStillSticker(elementData.type),
        uid: slideId,
        slideDuration,
      } as SlideItemObject;

      if ('hashedFilename' in elementData) {
        newSlidesHashmap[slideId] = {
          ...newSlidesHashmap[slideId],
          fileExtension: 'extension' in elementData ? elementData.extension : getCompatibleImageFileExtension(PMW_STOCK_IMAGE_EXTENSION),
          hashedFilename: elementData.hashedFilename,
        };
      } else {
        newSlidesHashmap[slideId] = {
          ...newSlidesHashmap[slideId],
          uploadingImageData: {
            tempUploadingImageUID: slideId,
            dataUrl: elementData.dataUrl,
            uploadStartTime: new Date().getTime(),
          },
        };
      }
    }

    if (durationLimitReached) {
      openMessageModal({
        title: window.i18next.t('pmwjs_maximum_slideshow_duration_reached_title'),
        text: window.i18next.t('pmwjs_maximum_slideshow_duration_reached'),
      });
    }

    newSlidesOrder = newSlidesOrder.slice(0, targetIndex).concat(orderedImageSlidesUIDs, newSlidesOrder.slice(targetIndex));
    try {
      await this.updateFromObject(
        {
          slides: {
            slidesOrder: newSlidesOrder,
            slidesHashMap: newSlidesHashmap,
          },
        },
        {updateRedux: true, undoable: true, checkForDurationUpdate: true}
      );
    } finally {
      this.slides.seekToSlideIndex(targetIndex);
    }
  }

  protected async prepareAndAddVideoSlides(elementsData: VideoData[]): Promise<void> {
    let gettyVideosCount = this.page.poster.getGettyVideoCount();
    let gettyLimitReached = false;
    let durationLimitReached = false;
    let additionalDuration = 0;

    const orderedVideoSlidesUIDs: string[] = [];
    const slidesObject = this.slides.toObject();

    let newSlidesOrder = slidesObject.slidesOrder;
    const newSlidesHashmap = slidesObject.slidesHashMap;

    const selectedSlideIndex = this.slides.getSlideIndex(this.selectedSlideUID);
    const targetIndex = selectedSlideIndex !== undefined ? selectedSlideIndex + 1 : 0;

    showLoading('prepareAndAddVideoSlides');
    for (const elementData of elementsData) {
      let slideDuration: undefined | number;

      if (elementData.source === USER_VIDEO_SOURCE.GETTY) {
        if (gettyVideosCount >= POSTER_GETTY_LIMIT.VIDEOS) {
          gettyLimitReached = true;
          break;
        }

        gettyVideosCount += 1;
      }

      if (this.getDuration() + additionalDuration >= getMaxSlideshowDuration()) {
        durationLimitReached = true;
        break;
      }

      if (this.getDuration() + additionalDuration + elementData.duration > getMaxSlideshowDuration()) {
        slideDuration = getMaxSlideshowDuration() - additionalDuration - this.getDuration();
      }

      additionalDuration += slideDuration === undefined ? elementData.duration : slideDuration;

      const slideId = uuidv4();
      orderedVideoSlidesUIDs.push(slideId);

      newSlidesHashmap[slideId] = {
        gitype: ITEM_TYPE.VIDEOSLIDE,
        videoSource: elementData.source,
        fileExtension: elementData.extension,
        hashedFilename: elementData.hashedFilename,
        duration: elementData.duration,
        frameRate: elementData.frameRate,
        uid: slideId,
        slideDuration: slideDuration !== undefined ? slideDuration : elementData.duration,
      } as SlideItemObject;
    }

    if (gettyLimitReached) {
      openGettyVideoLimitReachedMessageModal();
    } else if (durationLimitReached) {
      openMessageModal({
        title: window.i18next.t('pmwjs_maximum_slideshow_duration_reached_title'),
        text: window.i18next.t('pmwjs_maximum_slideshow_duration_reached'),
      });
    }

    newSlidesOrder = newSlidesOrder.slice(0, targetIndex).concat(orderedVideoSlidesUIDs, newSlidesOrder.slice(targetIndex));
    try {
      await this.updateFromObject(
        {
          slides: {
            slidesOrder: newSlidesOrder,
            slidesHashMap: newSlidesHashmap,
          },
        },
        {updateRedux: true, undoable: true, checkForDurationUpdate: true}
      );
    } finally {
      this.slides.seekToSlideIndex(targetIndex);
      hideLoading('prepareAndAddVideoSlides');
    }
  }

  protected validateSlideshowUse(): boolean {
    if (this.getSlideshowDurationAfterAddSide() > getMaxSlideshowDuration()) {
      openMessageModal({
        title: window.i18next.t('pmwjs_maximum_slideshow_duration_reached_title'),
        text: window.i18next.t('pmwjs_maximum_slideshow_duration_reached'),
      });
      return false;
    }
    return true;
  }

  public getColors(): RGB[] {
    let colors: RGB[] = super.getColors();
    const {slidesOrder, slidesHashMap} = this.slides;

    for (const id of slidesOrder) {
      colors = [...colors, ...slidesHashMap[id].getColors()];
    }

    return colors;
  }

  public getFonts(): string[] {
    const slides = Object.values(this.slides.slidesHashMap);
    const fonts: string[] = [];

    for (const eachSlide of slides) {
      if (eachSlide.isTextSlide()) {
        fonts.push(eachSlide.textStyles.fontFamily);
      }
    }

    return fonts;
  }

  public async applyStylesToAllSlides(): Promise<void> {
    const newSlidesHashmap: Record<string, DeepPartial<SlideItemObject>> = this.slides.toObject().slidesHashMap;
    const activeSlide = this.getSelectedSlide();

    for (const [, slide] of Object.entries(newSlidesHashmap)) {
      if (activeSlide.uid !== slide.uid && activeSlide.gitype === slide.gitype) {
        newSlidesHashmap[slide.uid!] = activeSlide.getAppliedSidebarProps();
      }
    }

    await this.updateFromObject({
      slides: {
        slidesOrder: this.slides.slidesOrder,
        slidesHashMap: newSlidesHashmap,
      },
    });
  }

  public updateIntroDelay(value: number, undoable = true): void {
    void this.updateFromObject(
      {
        introDelay: value,
      },
      {
        undoable,
        checkForDurationUpdate: true,
      }
    );
  }

  public getMaximumPossibleIntroDelay(): number {
    return this.slides.getMaxValidDuration() + this.introDelay;
  }

  public getMaxPossibleIntroDelayFromValue(value: number): number {
    if (value < 0) {
      return 0;
    }

    const maxPossibleDelay = this.getMaximumPossibleIntroDelay();
    if (maxPossibleDelay >= value) {
      return Number(value.toFixed(1));
    }
    return maxPossibleDelay;
  }

  protected onItemDoubleTapped(): void {}
}
