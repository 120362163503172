import type {PageObject} from '@PosterWhiteboard/page/page.types';
import type {UserObject} from '@PosterWhiteboard/user/user.types';
import type {PosterTypeObject} from '@PosterWhiteboard/poster-type/poster-type.types';
import type {AudioClipsObject} from '@PosterWhiteboard/classes/audio-clips/audio-clips.class';
import type {GridObject} from '@PosterWhiteboard/poster/grid.class';
import type {BleedObject} from '@PosterWhiteboard/poster/bleed.class';
import type {AlignmentGuidesObject} from '@PosterWhiteboard/poster/alignment-guides.class';
import type {FoldObject} from '@PosterWhiteboard/poster/fold.class';
import type {FolderObject} from '@PosterWhiteboard/classes/folder.class';
import type {PosterScalingObject} from '@PosterWhiteboard/poster/poster-scaling.class';
import type {DrawingObject} from '@PosterWhiteboard/poster/poster-drawing/poster-drawing';
import type {PosterModeObject} from '@PosterWhiteboard/poster/poster-mode.class';
import type {ResizeReferenceObject} from '@PosterWhiteboard/poster/resize-poster.class';
import type {ItemsMultiSelectObject} from '@PosterWhiteboard/poster/items-multi-select.class';

export const POSTER_VERSION = {
  CURRENT: 19,
  SHADOW_IMPROVEMENT: 15,
  FABRIC_3_UPDATE: 12,
  FABRIC_2_UPDATE: 11,
  HTML5: 10,
  LEGACY: 3,
};

export const POSTER_MAX_DURATION = 600;
export const POSTER_MIN_DURATION = 2;

export const MAX_NON_HIGHRES_ALLOWED_ITEM_LOADING_TIME = 333;
export const MAX_HIGHRES_ALLOWED_ITEM_LOADING_TIME = 1000;
export const DEFAULT_ALLOWED_ALL_ITEM_LOADING_TIME = 180000;

export interface PosterEvents {
  'time:updated': number;
  seeked: never;
  'textSlide:added': string;
  'fabricCanvas:touch:start': never;
}

export interface PosterPageObject {
  pagesHashMap: Record<string, PageObject>;
  pageOrder: string[];
}

export interface PosterLoadObject {
  hashedID: string;
  audioClips: AudioClipsObject;
  type: PosterTypeObject;
  userWidth: number;
  userHeight: number;
  units: string;
  version: number;
  name: string;
  width: number;
  height: number;
  idTemplate: string;
  description: string;
  isTemplate: boolean;
  isPublic: boolean;
  isCopyable: boolean;
  isPurchaseable: boolean;
  createdOn: number;
  lastModified: number;
  idLastModifier: string;
  isInternal: boolean;
  idGalleryTemplate?: string;
  idGalleryTemplateCreator: number;
  seoName: string;
  id: string;
  creator?: UserObject;
  owner?: UserObject;
  pages: PosterPageObject;
}

export interface PosterObject extends PosterLoadObject {
  scaling: PosterScalingObject;
  resizeReference: ResizeReferenceObject;
  duration: number;
  bleed: BleedObject;
  folder: FolderObject;
  grid: GridObject;
  mode: PosterModeObject;
  alignmentGuides: AlignmentGuidesObject;
  fold: FoldObject;
  drawing: DrawingObject;
  itemsMultiSelect: ItemsMultiSelectObject;
  copyKey: string;
  isInitialized: boolean;
  uploadingImages: string[];
}

export const POSTER_GETTY_LIMIT = {
  IMAGES: 6,
  VIDEOS: 3,
  EXTRACTED_GETTY_STICKER: 20,
};

export const MAX_POSTER_NAME_LENGTH = 100;
