import type {CSSProperties, ReactNode, Ref} from 'react';
import React, {useRef} from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import type {GridAudienceItemStorage} from '@Components/email-marketing-campaign-audience/components/audience-grid';
import type {GridEmailSignupFormItemStorage} from '@Components/email-signup-form-grid/components/email-signup-form-grid';
import type {GridSkeletonItemStorage} from '@Components/base-grid/components/grid-skeleton-item';
import {Text, TextSize} from '@Components/text';
import type {GridFancytextItemStorage} from '@Components/base-grid/components/grid-fancytext-item/grid-fancytext-item';
import type {GridPMWStockMediaItemStorage} from '@Components/base-grid/components/grid-pmwstockmedia-item/grid-pmw-stock-media-item';
import type {GridShapeItemStorage} from '@Components/base-grid/components/grid-shape-item';
import type {GridAnimatedStickerItemStorage} from '@Components/base-grid/components/grid-animated-sticker-item';
import type {GridClipartItemStorage} from '@Components/base-grid/components/grid-clipart-item';
import type {GridStockVideoItemStorage} from '@Components/base-grid/components/grid-stock-video-item';
import type {GridStockAudioItemStorage} from '@Components/base-grid/components/grid-stock-audio-item';
import type {GridCollectionItemStorage} from '@Components/base-grid/components/grid-collection-item';
import type {GridButtonItemStorage} from '@Components/base-grid/components/grid-button-item';
import {ClickableDiv} from '@Components/clickable-div';
import type {GridGenericButtonItemStorage} from '@Components/base-grid/components/grid-generic-button-item/grid-generic-button-item';
import type {GridExtractedGettyStickerItemStorage} from '@Components/base-grid/components/grid-extracted-getty-sticker-item';
import type {GridUserMediaItemStorage} from '@Libraries/user-media-library';
import {useAppSelector} from '@/hooks';
import styles from './grid-item.module.scss';

export type GridItemStorage =
  | GridButtonItemStorage
  | GridCustomItem
  | GridItemBaseStorage
  | GridShapeItemStorage
  | GridClipartItemStorage
  | GridStockVideoItemStorage
  | GridAnimatedStickerItemStorage
  | GridCollectionItemStorage
  | GridUserMediaItemStorage
  | GridAudienceItemStorage
  | GridStockAudioItemStorage
  | GridSkeletonItemStorage
  | GridEmailSignupFormItemStorage
  | GridFancytextItemStorage
  | GridPMWStockMediaItemStorage
  | GridGenericButtonItemStorage
  | GridExtractedGettyStickerItemStorage;

export interface GridStockMediaItemBackendData {
  idPmwStockMedia: string;
  keywords: string[];
  sortOrder: number;
  uploadedOn: number;
  type: string;
  isHidden: boolean;
  hashedFilename: string;
}

export enum GRID_ITEM_TYPE {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  USER_IMAGE = 'USER_IMAGE',
  USER_VIDEO = 'USER_VIDEO',
  USER_AUDIO = 'USER_AUDIO',
  BUTTON = 'BUTTON',
  IMAGE = 'IMAGE',
  STOCK_IMAGE = 'STOCK_IMAGE',
  STOCK_VIDEO = 'STOCK_VIDEO',
  STOCK_AUDIO = 'STOCK_AUDIO',
  PMW_STOCK_SHAPE = 'PMW_STOCK_SHAPE',
  SHAPE = 'SHAPE',
  COLLECTION = 'COLLECTION',
  STICKER = 'STICKER', // todo: delete this later
  PMW_STOCK_ANIMATED_STICKER = 'PMW_STOCK_ANIMATED_STICKER',
  ANIMATED_STICKER = 'ANIMATED_STICKER',
  CLIPART = 'CLIPART',
  EMAIL_CAMPAIGN_AUDIENCE = 'AUDIENCE',
  SKELETON = 'SKELETON',
  CUSTOM = 'CUSTOM',
  EMAIL_SIGNUP_FORM = 'EMAIL_SIGNUP_FORM',
  FANCYTEXT = 'FANCYTEXT',
  PMW_STOCK_MEDIA = 'PMW_STOCK_MEDIA',
  PMW_STOCK_ICON = 'PMW_STOCK_ICON',
  ICON = 'ICON',
  PMW_STOCK_STILL_STICKER = 'PMW_STOCK_STILL_STICKER',
  PMW_STOCK_EXTRACTED_GETTY_STICKER = 'PMW_STOCK_EXTRACTED_GETTY_STICKER',
  STILL_STICKER = 'STILL_STICKER',
  GENERIC_BUTTON = 'GENERIC_BUTTON',
}

export interface GridCustomItem extends GridItemBaseStorage {
  type: GRID_ITEM_TYPE.CUSTOM;
  children?: ReactNode;
  onClicked?: Function;
  title?: string;
  showSelectionNumber?: boolean;
}

export interface GridItemBaseStorage {
  type: GRID_ITEM_TYPE;
  id: string;
  className?: string;

  [key: string]: any;
}

export interface GridItemProps {
  id: string;
  className?: string;
  containerClassName?: string;
  children?: ReactNode;
  onClicked?: Function;
  title?: string;
  isSmallItem?: boolean;
  showSelectionNumber?: boolean;
  showHoverOverlay?: boolean;
  hasTransparency?: boolean;
  isSkeletion?: boolean;
  style?: CSSProperties;
  /**
   * This is added by the grid component they are a part of.
   * You shouldn't specify them directly
   */
  gridId?: string;
  /**
   * This is added by the grid component they are a part of.
   * You shouldn't specify them directly
   */
  gridSelectionGroupId?: string;
}

export const GridItem = React.forwardRef(
  (
    {
      onClicked = (): void => {},
      title = '',
      className = '',
      showSelectionNumber = true,
      showHoverOverlay = false,
      isSmallItem = false,
      gridSelectionGroupId = '',
      containerClassName = '',
      ...props
    }: GridItemProps,
    ref: Ref<HTMLLIElement>
  ) => {
    const clickCount = useRef(0);
    const selectionNumber = useAppSelector((state) => {
      return props.id ? state.grids.gridSelectionGroupHashmap[gridSelectionGroupId]?.selectedItemIdHashmap[props.id] : null;
    });

    const updateClickCount = (): void => {
      clickCount.current += 1;
      setTimeout(() => {
        clickCount.current = 0;
      }, 400);
    };

    const onItemClicked = (e: React.MouseEvent): void => {
      updateClickCount();
      onClicked(e, clickCount.current !== 1);
    };

    return (
      <li
        key={props.id}
        ref={ref}
        className={`spacing-p-0 ${styles.gridItemContainer} ${containerClassName} ${isSmallItem ? '' : styles.backgroundColor}`}
        style={props.style}
        data-test-is-skeletion={props.isSkeletion}
      >
        <ClickableDiv
          className={`flex-center _full-width _full-height ${className} ${styles.gridItem} ${showHoverOverlay && !selectionNumber ? styles.showOverlay : ''}`}
          onClick={onItemClicked}
          title={title}
        >
          {props.children}
          {selectionNumber ? <div className={`${styles.gridItemSelected} radius-12 _full-height _full-width`} /> : null}
          {selectionNumber ? (
            <Icon
              size={isSmallItem ? IconSize.SIZE_ICON_32 : IconSize.SIZE_ICON_64}
              icon={isSmallItem ? 'icon-check' : 'icon-check-circle'}
              className={styles.selectedItemOverlayCheckmark}
            />
          ) : null}
          {selectionNumber && showSelectionNumber ? (
            <div className={`${styles.selectionNumberContainer} ${isSmallItem ? styles.selectionNumberContainerSmall : ''} flex-center`}>
              <Text val={`${selectionNumber}`} className="content-body-white" size={TextSize.XSMALL} bold />
            </div>
          ) : null}
        </ClickableDiv>
      </li>
    );
  }
);
