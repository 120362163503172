import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {MediaSlideObject} from '@PosterWhiteboard/items/slideshow-item/media-slide.class';
import {MediaSlide} from '@PosterWhiteboard/items/slideshow-item/media-slide.class';
import {roundPrecision} from '@Utils/math.util';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {rgbToHex} from '@Utils/color.util';
import {DEFAULT_SLIDE_DURATION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import type {RemoveVideoBackgroundTrimData} from '@Utils/video.util';
import type * as Fabric from '@postermywall/fabricjs-2';
import type {ItemFabricObject} from '@PosterWhiteboard/items/item/item.class';
import type {DeepPartial} from '@/global';

const MAXIMUM_SYNC_THRESHOLD = 0.5;

export interface VideoSlideItemObject extends VideoItemObject {
  slideDuration: number;
  mediaSlide: MediaSlideObject;
}

export class VideoSlideItem extends VideoItem {
  public gitype = ITEM_TYPE.VIDEOSLIDE;
  public slideDuration = DEFAULT_SLIDE_DURATION;
  public slideshow: SlideshowItem;
  public mediaSlide: MediaSlide;
  protected syncing = false;
  private initGeneration = false;
  private frameTimeToPathMap: Record<number, string> = {};

  public constructor(page: Page, slideshow: SlideshowItem) {
    super(page);
    this.slideshow = slideshow;
    this.mediaSlide = new MediaSlide(this);
    this.syncToPosterClock.disable();
  }

  public getParentFabricObject(): ItemFabricObject {
    return this.slideshow.fabricObject;
  }

  public toObject(): VideoSlideItemObject {
    return {
      ...super.toObject(),
      slideDuration: this.slideDuration,
      mediaSlide: this.mediaSlide.toObject(),
    };
  }

  public copyVals(obj: DeepPartial<VideoSlideItemObject>): void {
    const {mediaSlide, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.mediaSlide.copyVals(mediaSlide);
  }

  protected getCommonOptions(): Record<string, any> {
    const {left, top, opacity, ...filteredProps} = super.getCommonOptions();
    return filteredProps;
  }

  protected async reInitFabricObject(): Promise<void> {
    const oldFabricObject = this.fabricObject;
    const slideshowFabricObject = this.slideshow.fabricObject;

    this.beforeInitFabricObject();
    await this.initFabricObject();
    this.fabricObject.set({left: oldFabricObject.left, top: oldFabricObject.top});
    slideshowFabricObject.remove(oldFabricObject as Fabric.Object);
    slideshowFabricObject.add(this.fabricObject as Fabric.Object);
  }

  public shouldPauseAtStreamEnd(): boolean {
    return true;
  }

  // Overriding so seeking isn't done by page time, but slideshow time.
  public async onItemAddedToPage(): Promise<void> {}

  /**
   * Getter for slideDuration property
   */
  public getSlideDuration(): number {
    return this.slideDuration;
  }

  public getStreamDuration(): number {
    return Math.min(this.slideDuration, this.getDuration());
  }

  /**
   * Setter for slideDuration property
   */
  public setSlideDuration(val: number): void {
    this.slideDuration = val;
  }

  public getFill(): string {
    return `#${rgbToHex(this.slideshow.transition.color)}`;
  }

  public async syncItemToTime(referenceItemTime: number): Promise<void> {
    if (this.htmlElement instanceof HTMLVideoElement) {
      if (referenceItemTime > this.getStreamDuration()) {
        await this.pauseSlideAtEndTime();
      } else {
        if (!this.syncing && referenceItemTime !== undefined && Math.abs(referenceItemTime - this.getCurrentTime()) > MAXIMUM_SYNC_THRESHOLD) {
          await this.seek(referenceItemTime);
          this.syncing = false;
        }

        if (referenceItemTime !== undefined && referenceItemTime > 0) {
          await this.checkForPausedStream(referenceItemTime);
        } else if (referenceItemTime < 0) {
          await this.pause();
        }
      }
    }
  }

  protected async checkForPausedStream(referenceItemTime: number): Promise<void> {
    if (this.htmlElement && this.page.poster.isPlaying() && !(referenceItemTime > this.getStreamDuration())) {
      const isVideoPlaying = this.htmlElement.currentTime > 0 && !this.htmlElement.paused && !this.htmlElement.ended && this.htmlElement.readyState > 2;
      if (!isVideoPlaying) {
        try {
          await this.play();
        } catch (e) {
          console.error(e);
          void this.page.poster.pause();
        }
      }
    }
  }

  /**
   * Pauses video slide and seeks it to its end time. This is done when design duration surpasses slide's time interval.
   */
  protected async pauseSlideAtEndTime(): Promise<void> {
    if (roundPrecision(this.htmlElement.currentTime, 1) !== roundPrecision(this.endTime, 1)) {
      await this.pause();
      await this.seek(this.getDuration());
      this.syncing = false;
    }
  }

  /**
   * Stops the video slide if it isn't already stopped.
   */
  public stopSlide(): void {
    if (this.htmlElement && roundPrecision(this.htmlElement.currentTime, 1) !== roundPrecision(this.startTime, 1)) {
      this.stop();
    }
  }

  protected async onEnded(): Promise<void> {
    await this.seek(this.endTime ?? this.startTime);
    await this.pause();
  }

  public isChildItem(): boolean {
    return true;
  }

  public getAppliedSidebarProps(): DeepPartial<VideoSlideItemObject> {
    return {
      aura: this.aura.toObject(),
      border: this.border.toObject(),
      effects: this.effects.toObject(),
      mediaSlide: this.mediaSlide.toObject(),
    };
  }

  public async enableRemoveBackgroundFlag(trimData: RemoveVideoBackgroundTrimData): Promise<void> {
    await this.slideshow.updateSlideFromObject(this.uid, {
      startTime: 0,
      endTime: trimData.trimEnd - trimData.trimStart,
      slideDuration: trimData.trimEnd - trimData.trimStart,
      removeBackground: {
        isBackgroundRemoved: true,
        trimData: {
          startTime: trimData.trimStart,
          endTime: trimData.trimEnd,
        },
      },
    });
  }

  public async disableRemoveBackgroundFlag(): Promise<void> {
    await this.slideshow.updateSlideFromObject(this.uid, {
      startTime: this.removeBackground.trimData.startTime,
      endTime: this.removeBackground.trimData.endTime,
      slideDuration: this.removeBackground.trimData.endTime - this.removeBackground.trimData.startTime,
      removeBackground: {
        isBackgroundRemoved: false,
      },
    });
  }

  public getFrameTimeForPosterTime(posterTime: number): number {
    const slideStartTime = this.slideshow.slides.getStartTimeForSlide(this.uid)!;
    let videoFrameTime;

    if (posterTime < slideStartTime) {
      videoFrameTime = this.startTime;
    } else if (posterTime > slideStartTime + this.getDuration()) {
      videoFrameTime = this.endTime;
    } else {
      videoFrameTime = posterTime - slideStartTime + this.startTime;
    }

    return videoFrameTime + 0.001;
  }

  public async onItemTrimmed(): Promise<void> {
    await this.slideshow.seekToPageTime();
  }
}
