import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {doesPublicFileExistAsync} from '@Utils/file.util';
import {openMessageModal} from '@Modals/message-modal';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import type {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {RemoveVideoBackgroundTrimData} from '@Utils/video.util';
import {getEstimatedTimeForBackgroundRemoval, removeVideoBackground} from '@Utils/video.util';
import type {TrimData} from '@Panels/trim-panels/trim-panels.types';
import {getRemovedBgFilenameWithExtension, getRepoVideoURL} from '@Libraries/user-video-library';
import {getCreditsCostForVideoBackgroundRemoval, updateVideoBgRemovalCredits} from '@Components/video-bg-removal-credits-cost/video-bg-removal-credits-cost-library';
import {ItemLoadingProgressType} from '@PosterWhiteboard/items/item/item.types';
import {onCreditsPurchaseSuccess, triggerBuyMoreCredits} from '@Components/poster-editor/library/poster-editor-open-modals';
import {secondsToMilliseconds} from '@Utils/math.util';
import {updateTeamCredits} from '@Components/team-credits/team-credits-slice';

const ESTIMATED_OVERHEAD_TIME = 180;

export interface RemoveVideoItemBackgroundObject {
  isBackgroundRemoved: boolean;
  isBackgroundBeingRemoved: boolean;
  trimData: TrimData;
}

export class RemoveVideoItemBackground extends CommonMethods {
  public isBackgroundRemoved = false;
  public isBackgroundBeingRemoved = false;
  public trimData: TrimData = {
    startTime: 0,
    endTime: 0,
  };

  // TODO: This is done to prevent isBackgroundBeingRemoved from coming from undo stack. This is wrong. Think of a better way because right now i can't use updateFromObject for this val because copy is ignored.
  protected ignoreKeysForCopy = {
    isBackgroundBeingRemoved: null,
  };

  private item: VideoItem | VideoSlideItem;

  constructor(item: VideoItem | VideoSlideItem) {
    super();
    this.item = item;
  }

  public toObject(): RemoveVideoItemBackgroundObject {
    return {
      isBackgroundRemoved: this.isBackgroundRemoved,
      isBackgroundBeingRemoved: this.isBackgroundBeingRemoved,
      trimData: this.trimData,
    };
  }

  public async ensureRemovedBackgroundVideoExistsIfNeeded(): Promise<void> {
    if (this.isBackgroundRemoved && !(await this.doesRemovedBackgroundFileExist())) {
      await removeVideoBackground(this.item.hashedFilename, this.item.removeBackground.trimData.startTime, this.item.removeBackground.trimData.endTime);
    }
  }

  public async doesRemovedBackgroundFileExist(): Promise<boolean> {
    const removedBgFilename = getRemovedBgFilenameWithExtension(
      this.item.hashedFilename,
      this.item.removeBackground.trimData.startTime,
      this.item.removeBackground.trimData.endTime
    );
    const destURL = getRepoVideoURL(removedBgFilename, this.item.page.poster.mode.isGeneration());
    return doesPublicFileExistAsync(destURL);
  }

  public async removeBackground(): Promise<void> {
    if (this.isBackgroundBeingRemoved) {
      return;
    }

    this.isBackgroundBeingRemoved = true;
    this.item.page.poster.redux.updateReduxData();
    try {
      const creditsToCharge: number = await this.getCreditsToChargeForBackgroundRemovalForUser();
      if ((await this.doesRemovedBackgroundFileExist()) && creditsToCharge === 0) {
        if (this.isBackgroundRemoved) {
          return;
        }

        const trimData: RemoveVideoBackgroundTrimData = {trimStart: this.item.startTime, trimEnd: this.item.endTime};
        await this.item.enableRemoveBackgroundFlag(trimData);
        return;
      }

      const estimatedBackgroundRemovalTime = await getEstimatedTimeForBackgroundRemoval(
        this.item.hashedFilename,
        this.item.getStartTimeForNonRemovedBgVideo(),
        this.item.getEndTimeForNonRemovedBgVideo()
      );
      const estimatedAjaxTimeoutInMilliseconds = secondsToMilliseconds(estimatedBackgroundRemovalTime + ESTIMATED_OVERHEAD_TIME);
      this.item.loading.startLoading({
        langTextKeys: ['pmwjs_removing_background_percentage', 'pmwjs_removing_bg_percentage'],
        progress: {
          type: ItemLoadingProgressType.FAKE,
          expectedTotalTime: estimatedBackgroundRemovalTime,
        },
      });
      const response = await removeVideoBackground(this.item.hashedFilename, this.item.startTime, this.item.endTime, estimatedAjaxTimeoutInMilliseconds);

      if (response.remainingRemoveBackgroundCredits !== undefined) {
        updateVideoBgRemovalCredits(this.item.hashedFilename, this.item.startTime, this.item.endTime, 0);
        window.PMW.redux.store.dispatch(updateTeamCredits({credits: response.remainingRemoveBackgroundCredits}));

        await this.item.enableRemoveBackgroundFlag(response.trimData);
      }
    } catch (e: any) {
      console.error(e);
      if (e.code === 'insufficient_credits') {
        void this.onInSufficientFunds();
        return;
      }

      openMessageModal({
        title: window.i18next.t('pmwjs_remove_video_bg_failed'),
        text: window.i18next.t('pmwjs_remove_video_bg_failed_desc'),
      });
    } finally {
      trackPosterBuilderGA4Events(GA4EventName.REMOVE_BACKGROUND_VIDEO);
      this.isBackgroundBeingRemoved = false;
      this.item.loading.removeLoading();
      this.item.page.poster.redux.updateReduxData();
    }
  }

  public async getCreditsToChargeForBackgroundRemovalForUser(): Promise<number> {
    return getCreditsCostForVideoBackgroundRemoval(this.item.hashedFilename, this.item.getStartTimeForNonRemovedBgVideo(), this.item.getEndTimeForNonRemovedBgVideo());
  }

  private onInSufficientFunds(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const cb = (credits: number): void => {
        onCreditsPurchaseSuccess(this.item, credits);
        resolve();
      };

      try {
        const creditsToCharge: number = await this.getCreditsToChargeForBackgroundRemovalForUser();
        const remainingCredits = window.PMW.redux.store.getState().teamCredits.credits;

        triggerBuyMoreCredits({
          headingText: window.i18next.t('pmwjs_credits_pack_on_background_removal', {creditsRemaining: remainingCredits, creditsCost: creditsToCharge}),
          modalTitle: window.i18next.t('pmwjs_youre_out_of_credits'),
          cb,
        });
      } catch (e) {
        console.error(e);
        reject();
      }
    });
  }
}
