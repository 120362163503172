import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {Cell, CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import {rgbToHexString} from '@Utils/color.util';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {BOLD_STROKE_WIDTH_FACTOR, TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {Group, IText} from '@postermywall/fabricjs-2';
import {Layout} from './layout';

export type BandLayoutType = LayoutTypes.BAND_LAYOUT_LEFT_ALIGN | LayoutTypes.BAND_LAYOUT_CENTER_ALIGN | LayoutTypes.BAND_LAYOUT_RIGHT_ALIGN;

export class BandLayout extends Layout {
  public layoutType: BandLayoutType = LayoutTypes.BAND_LAYOUT_LEFT_ALIGN;
  public constructor(item: TableItem, alignment: BandLayoutType) {
    super(item);
    this.layoutType = alignment ?? LayoutTypes.BAND_LAYOUT_LEFT_ALIGN;
  }

  /**
   * Function of parent class, overridden in child class.
   * Handles positioning of items inside the view and apply styles specific to this layout.
   * @override
   */
  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups();
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.setGroupAlignment(this.item.fabricObject);
    this.setStylesForHighlightedItems();
  }

  /**
   * Function of parent class, overridden in child class for insertion of data in view, specific to this layout.
   * @override
   */
  async setItems(): Promise<void> {
    const fabriObjectForRows = [];
    const columnMap = this.item.getColumnMap();
    const rows = this.item.getNoOfRows();

    for (let i = 0; i < rows; i++) {
      const textItems: IText[] = [];
      for (const [, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        textItems.push(new IText((cells[i].getValue() as string).toUpperCase(), {editable: false}));
      }
      fabriObjectForRows.push(new Group(textItems, {}));
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(fabriObjectForRows);
  }

  /**
   * Sets styles for highlighted items in the table
   * @override
   */
  setStylesForHighlightedItems(): void {
    const {highlightedRows} = this.item.fabricObject;
    const objects = this.item.fabricObject.getObjects() as Group[];
    const color = rgbToHexString(this.item.highlightedTextColor, 1);

    if (highlightedRows.length > 0) {
      for (let i = 0; i < highlightedRows.length; i++) {
        const item = objects[highlightedRows[i]];
        const obs = item.getObjects();

        for (let x = 0; x < obs.length; x++) {
          obs[x].set({
            fill: color,
            stroke: this.item.textStyles.stroke ? rgbToHexString(this.item.textStyles.strokeColor, 1) : color,
          });
        }
      }
    }
  }

  /**
   * Sets the view styles specific to this layout
   * @private
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    let strokeColor = rgbToHexString(this.item.textStyles.fill.fillColor[0], 1);
    const strokeWidth = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeWidthForFF1;
    let strokeWidthForFF2 = strokeWidth;
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidthForFF1 = !isBoldAppliedOnFirstFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 += !isBoldAppliedOnSecondFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * 1.5 : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 += TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * 1.5 * this.item.textStyles.strokeWidth;
      strokeWidthForFF1 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeColor = rgbToHexString(this.item.textStyles.strokeColor, 1);
    }
    for (let i = 0; i < groups.length; i++) {
      const groupItems = groups[i].getObjects();
      groupItems[0].set({
        fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
        underline: this.item.textStyles.underLine,
        linethrough: this.item.textStyles.lineThrough,
      });
      if (strokeWidthForFF1) {
        groupItems[0].set({
          strokeWidth: strokeWidthForFF1,
          strokeLineJoin: 'round',
          paintFirst,
          stroke: strokeColor,
        });
      }
      if (groupItems[1] instanceof IText) {
        groupItems[1].set({
          fontSize: groupItems[1].fontSize * 1.5,
          fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
          strokeWidth: strokeWidthForFF2,
          strokeLineJoin: 'round',
          paintFirst,
          stroke: strokeColor,
          fontFamily: fontFamily2WithVariation,
          // whenever the font family of a text item is modified, letterSpacing should be set again for this item.
          charSpacing: this.item.textStyles.letterSpacing,
          underline: this.item.underLine2,
          linethrough: this.item.lineThrough2,
        });
      }
    }
  }

  /**
   * Sets the alignment of the group
   * @private
   */
  setGroupAlignment(group: Group): void {
    if (this.layoutType === LayoutTypes.BAND_LAYOUT_CENTER_ALIGN) {
      this.horizontallyCenterItems(group);
    } else if (this.layoutType === LayoutTypes.BAND_LAYOUT_RIGHT_ALIGN) {
      this.doRightAlign(group);
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   * @private
   */
  layoutItemsInsideGroups(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];

    for (let i = 0; i < groups.length; i++) {
      const newDim = this.getNewViewDimensions(groups[i], 'horizontal');
      groups[i].set({
        width: newDim.width,
        height: newDim.height,
        left: 0,
        top: 0,
      });
      this.horizontallyStackItems(groups[i], 0, 0);
      this.setGroupAlignment(groups[i]);
    }
  }
}
