import type {FC, ReactElement} from 'react';
import React, {useState} from 'react';
import {useIsInViewport} from '@Hooks/useIsInViewport';
import {isIOS, isSafari} from 'react-device-detect';
import {CircularProgressLoader, LOADER_SIZE} from '@Components/circular-progress-loader';
import styles from './grid-media-item.module.scss';
import type {GridItemBaseStorage, GridItemProps} from '../grid-item';
import {GridItem} from '../grid-item';

export enum GridMediaItemTagType {
  COST,
  SOURCE,
}

export interface CostTag {
  type: GridMediaItemTagType.COST;
  containerStyle: string;
  price: string;
  icon: string;
  textStyle: string;
}

export interface SourceTag {
  type: GridMediaItemTagType.SOURCE;
  containerStyle: string;
  iconStyle: string;
}

export type GridMediaItemTag = CostTag | SourceTag;

interface GridMediaItemProps extends GridItemProps {
  thumb?: ReactElement;
  title?: string;
  className?: string;
  paddedThumb?: boolean;
  showViewportMediaOnly?: boolean;
  isImageLoaded?: boolean;
  isLoading?: boolean;
}

export interface GridMediaItemStorage extends GridItemBaseStorage {
  width?: number;
  height?: number;
  isLoading?: boolean;
}

/**
 * @class GridMediaItem
 */
export const GridMediaItem: FC<GridMediaItemProps> = ({paddedThumb = true, thumb = null, title = '', isImageLoaded = false, isLoading = false, ...props}) => {
  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const isInViewPort = useIsInViewport(gridRef as HTMLElement);

  const getLoader = (): ReactElement | null => {
    if (isLoading) {
      return <CircularProgressLoader size={LOADER_SIZE.DEFAULT} classes={`${styles.loadingItem} ${styles.loaderPosition}`} />;
    }

    return null;
  };

  const getLoaderOverlay = (): ReactElement | null => {
    if (isLoading) {
      return <div className={`_full-width _full-height flex-center ${styles.loadingOverlay}`} />;
    }

    return null;
  };

  return (
    <GridItem {...props}>
      <div
        className={`flex-v-row flex-center _full-width _full-height radius-12 ${isImageLoaded ? '' : styles.thumbBackground} ${
          props.hasTransparency ? styles.thumbWithTransparency : ''
        } ${isLoading ? styles.loadingContainer : ''} thumb`}
        ref={setGridRef}
      >
        {thumb && (isInViewPort || !(isIOS || isSafari))
          ? React.cloneElement(thumb, {
              className: `${thumb.props.className} ${styles.thumb} ${paddedThumb ? styles.paddedThumb : ''} ${isLoading ? styles.loadingItem : ''} `,
            })
          : null}
        {getLoaderOverlay()}
        {getLoader()}
      </div>
    </GridItem>
  );
};
