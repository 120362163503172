import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {AddOnData, Cell, VariationData} from '@PosterWhiteboard/items/layouts/cells/cell';
import {rgbToHexString} from '@Utils/color.util';
import {getIconSvg} from '@PosterWhiteboard/items/menu-item/menu-icons';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {DEFAULT_SPACING_BETWEEN_ITEMS, BOLD_STROKE_WIDTH_FACTOR, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {FixedLayout, LayoutManager, Group, IText, Textbox} from '@postermywall/fabricjs-2';
import {Layout} from './layout';

export class MenuLayout6 extends Layout {
  public layoutType: LayoutTypes.MENU_LAYOUT_6 = LayoutTypes.MENU_LAYOUT_6;
  declare item: MenuItem;

  public setStylesForHighlightedItems(): void {
    throw new Error('Method not implemented.');
  }

  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups(this.edgePadding / 2);
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.setExtraStyles();
    this.layoutItemsInsideGroups(this.edgePadding / 2);
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
  }

  async setItems(): Promise<void> {
    let i;

    const textObjectCaching = !this.item.page.poster.isHighRes;
    const columnMap = this.item.getColumnMap();
    const horizontalGroups: Group[] = [];
    const rows = this.item.getNoOfRows();

    for (let a = 0; a < rows; a++) {
      const nameAndIcons = new Group([], {
        objectCaching: false,
        layoutManager: new LayoutManager(new FixedLayout()),
      });
      let description: FabricObject | undefined;
      const priceGroup = new Group([], {
        objectCaching: false,
        layoutManager: new LayoutManager(new FixedLayout()),
      });
      const addOnsGroup = new Group([], {objectCaching: false});

      for (const [cellType, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        const t = cells[a].getValue() as string;

        switch (cellType) {
          case CellType.NAME:
            nameAndIcons.insertAt(
              nameAndIcons.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.DESCRIPTION:
            /*
             * At highres, we don't let the Textbox determine text wrapping. Instead, we specify wrapping ourselves that matches
             * what the user saw in their browser when the poster was saved
             */
            if (t.length > 0) {
              if (this.item.page.poster.isHighRes) {
                description = new IText(this.getWrapping(this.item, a, t), {
                  editable: false,
                  padding: 0,
                  objectCaching: textObjectCaching,
                });
              } else {
                description = new Textbox(t, {
                  editable: false,
                  padding: 0,
                  objectCaching: textObjectCaching,
                });
              }
            } else {
              description = new Group([], {objectCaching: false});
            }
            break;

          case CellType.PRICE:
            priceGroup.insertAt(
              priceGroup.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.ICONS:
            for (let z = 0; z < t.length; z++) {
               
              const icon = await getIconSvg(t[z]);

              if (icon) {
                const scale = this.getScaleForMenuIcon(icon, this.item.iconsSize);
                icon.set({
                  scaleX: scale,
                  scaleY: scale,
                });
                nameAndIcons.insertAt(nameAndIcons.getObjects().length, icon);
              }
            }
            break;

          case CellType.VARIATION: {
            const cellVal = cells[a].getValue() as VariationData[];
            if (cellVal.length > 0) {
              this.emptyGroup(priceGroup);
              for (i = 0; i < cellVal.length; i++) {
                const g = [];
                g.push(
                  new IText(cellVal[i].name, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                g.push(
                  new IText(cellVal[i].price, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                priceGroup.insertAt(0, new Group(g, {objectCaching: false}));
              }
            }
            break;
          }
          case CellType.ADDON: {
            const cellVal = cells[a].getValue() as AddOnData[];
            if (cellVal.length > 0) {
              for (i = 0; i < cellVal.length; i++) {
                addOnsGroup.insertAt(
                  addOnsGroup.getObjects().length,
                  new IText(`${cellVal[i].name} ${cellVal[i].price}`, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
              }
            }
            break;
          }

          default:
            break;
        }
      }

      const nameAndPrice = new Group([nameAndIcons, priceGroup], {
        objectCaching: false,
        layoutManager: new LayoutManager(new FixedLayout()),
      });
      const groupElements: FabricObject[] = [nameAndPrice];
      if (description !== undefined) {
        groupElements.push(description);
      }
      groupElements.push(addOnsGroup);

      horizontalGroups.push(new Group(groupElements, {objectCaching: false}));
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(horizontalGroups);
  }

  /**
   * Sets the this.item.fabricObject styles specific to this layout
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    let strokeWidthForFF1 = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeWidthForFF2 = 0;
    let strokeColor = rgbToHexString(this.item.textStyles.fill.fillColor[0], 1);
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidthForFF1 += !isBoldAppliedOnFirstFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 = !isBoldAppliedOnSecondFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeWidthForFF1 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeColor = rgbToHexString(this.item.textStyles.strokeColor, 1);
    }

    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects() as Group[];
      const nameAndPriceGroup = menuItems[0].getObjects();
      const nameAndIcon = nameAndPriceGroup[0] as Group;
      const price = nameAndPriceGroup[1] as Group;
      const description = menuItems[1];
      const addOns = menuItems[2];

      nameAndIcon.getObjects()[0].set({
        fontSize: this.item.textStyles.fontSize * 1.2,
        fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
        strokeWidth: strokeWidthForFF1,
        strokeLineJoin: 'round',
        paintFirst,
        stroke: strokeColor,
        underline: this.item.textStyles.underLine,
        linethrough: this.item.textStyles.lineThrough,
      });

      description.set({
        fontFamily: fontFamily2WithVariation,
        fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
        underline: this.item.underLine2,
        linethrough: this.item.lineThrough2,
      });
      if (strokeWidthForFF2) {
        description.set({
          strokeWidth: strokeWidthForFF2,
          strokeLineJoin: 'round',
          paintFirst,
          stroke: strokeColor,
        });
      }

      const icons = nameAndIcon.getObjects();
      for (let j = 1; j < icons.length; j++) {
        icons[j].set({
          fill: rgbToHexString(this.item.iconsColor, this.item.alpha),
        });
      }

      const priceItems = price.getObjects() as Group[];
      const priceStyles = {
        fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
        strokeWidth: strokeWidthForFF1,
        strokeLineJoin: 'round',
        paintFirst,
        stroke: strokeColor,
        underline: this.item.textStyles.underLine,
        linethrough: this.item.textStyles.lineThrough,
      };

      for (let a = 0; a < priceItems.length; a++) {
        if (priceItems[a] instanceof Group) {
          priceItems[a].getObjects()[0].set(priceStyles);
          priceItems[a].getObjects()[1].set(priceStyles);
          this.horizontallyStackItems(priceItems[a], DEFAULT_SPACING_BETWEEN_ITEMS, 0);
          this.horizontallyCenterItems(priceItems[a]);
        } else {
          priceItems[a].set(priceStyles);
        }
      }

      this.verticallyStackItems(nameAndIcon, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
      this.verticallyCenterItems(nameAndIcon);
      this.verticallyStackItems(menuItems[0], DEFAULT_SPACING_BETWEEN_ITEMS, 0);
      this.verticallyStackItems(price, DEFAULT_SPACING_BETWEEN_ITEMS * 2, 0);

      if (addOns) {
        this.verticallyStackItems(addOns, this.edgePadding, 0);
        addOns.set('fontFamily', fontFamily2WithVariation);
        addOns.getObjects().forEach((obj) => {
          obj.set({
            fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
            underline: this.item.underLine2,
            linethrough: this.item.lineThrough2,
          });
          if (strokeWidthForFF2) {
            obj.set({
              strokeWidth: strokeWidthForFF2,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: strokeColor,
            });
          }
        });
      }
    }
  }

  /**
   * This function inserts the dots between the description and price then re-layout the items
   */
  setExtraStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects() as Group[];
      const nameAndPriceGroup = menuItems[0].getObjects();
      const nameAndIcon = nameAndPriceGroup[0];
      const price = nameAndPriceGroup[1];
      const description = menuItems[1];

      let s;
      if (this.item.page.poster.isHighRes) {
        s = this.item.width - nameAndIcon.width - price.width - this.edgePadding * 2;
      } else {
        s = this.item.fabricObject.width - nameAndIcon.width - price.width + this.item.xSpacing;
      }
      this.verticallyStackItems(menuItems[0], s, 0);
      description.set({
        width: menuItems[0].width - price.width,
      });
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   */
  layoutItemsInsideGroups(xSpacing: number): void {
    const groups = this.item.fabricObject.getObjects() as Group[];

    for (let i = 0; i < groups.length; i++) {
      const newDim = this.getNewViewDimensions(groups[i], 'vertical');
      groups[i].set({
        width: newDim.width,
        height: newDim.height,
        left: 0,
        top: 0,
      });
      this.horizontallyStackItems(groups[i], xSpacing, 0);
    }
  }

  getWrappingInfo(): string[][] {
    const menuItems = this.item.fabricObject.getObjects() as Group[];
    const wrappingData = [];

    for (let i = 0; i < menuItems.length; i++) {
      const items = menuItems[i].getObjects();
      if (items[1] instanceof Textbox) {
        wrappingData.push(items[1].textLines);
      } else {
        wrappingData.push('');
      }
    }

    return wrappingData as string[][];
  }
}
