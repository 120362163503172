import type {AddSubtitleData, AddWordData} from '@Libraries/add-media-library';
import {ElementDataType} from '@Libraries/add-media-library';
import type {GeneratedFromType, TranscriptGeneratedFrom, TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {AiTranscriptSupportedLanguage} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {AiTranscriptForItemWithParams} from '@Hooks/poster-editor/useAiTranscriptGeneration';
import type {AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {WordObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word.types';
import {getUniqueString, removePunctuationFromWord} from '@Utils/string.util';
import type {SelectableItemWithAudio} from '@Panels/ai-subtitle-panel/ai-subtitle-panel.types';
import {repoVideoThumbImageURL} from '@Libraries/user-video-library';
import {getRandomThumbnailImageForAudioItem} from '@Libraries/poster-audio-item.library';
import {AiSubtitleState} from '@Components/poster-editor/components/poster-ai/poster-ai.types';
import styles from '@Components/add-ai-item-button/add-ai-item-button.module.scss';

export const NO_SPEECH_DETECTED_ERROR_CODE = 'NO_SPEECH_DETECTED';

export enum AiSubtitleMenuOpenedFrom {
  ADD_AI_OPTION = 'add_ai_option',
  ADD_TEXT_OPTION = 'add_text_option',
}

export interface GeneratedTranscriptWordsData {
  id: string;
  start: number;
  end: number;
  text: string;
}

export interface GeneratedTranscriptSubtitlesData {
  id: string;
  start: number;
  end: number;
  text: string;
  words: GeneratedTranscriptWordsData[];
}

export interface GeneratedAiTranscriptData {
  text: string;
  subtitles: GeneratedTranscriptSubtitlesData[];
}

export interface AiTranscriptGenerationResponse {
  transcript?: GeneratedAiTranscriptData;
  error?: string;
}

export const doesTranscriptForItemNeedRegeneration = (params: AiTranscriptForItemWithParams, existingTranscriptItem: TranscriptItemObject): boolean => {
  return (
    !doTrimTimesMatchGeneratedTranscript(params.startTime, params.endTime, params.onPosterStartTime, existingTranscriptItem) ||
    doesTranscriptHaveUserEditedSubtitles(existingTranscriptItem) ||
    hasSpeedBeenChangedAfterAiTranscriptGeneration(params.speed, existingTranscriptItem.generatedFrom?.speed) ||
    hasLoopCountChangedAfterAiTranscriptGenerationForVideo(params.loopCycles, existingTranscriptItem.generatedFrom?.loopCycles)
  );
};

export const doTrimTimesMatchGeneratedTranscript = (trimStartTime: number, trimEndTime: number, onPosterStartTime: number, generatedTranscript: TranscriptItemObject): boolean => {
  if (!generatedTranscript.generatedFrom) {
    return false;
  }
  const generatedFromStartTime = generatedTranscript.generatedFrom.startTime;
  const generatedFromEndTime = generatedTranscript.generatedFrom.endTime;
  const generatedFromOnPosterStartTime = generatedTranscript.generatedFrom.onPosterStartTime;

  return trimStartTime === generatedFromStartTime && trimEndTime === generatedFromEndTime && onPosterStartTime === generatedFromOnPosterStartTime;
};

export const generateAiTranscriptAndGetSubtitles = async (
  audioHashedFilename: string,
  trimStartTime: number,
  trimEndTime: number,
  mediaType: GeneratedFromType,
  loopCycles = 1,
  speed = 1,
  language = AiTranscriptSupportedLanguage.ENGLISH
): Promise<GeneratedAiTranscriptData> => {
  const res = (await window.PMW.pollingAjaxCallAsync(
    generateAiTranscript.bind(null, audioHashedFilename, trimStartTime, trimEndTime, mediaType, loopCycles, speed, language),
    checkAiTranscriptGenerationStatus.bind(null)
  )) as AiTranscriptGenerationResponse;

  if (res.error) {
    throw new Error(res.error);
  }

  if (res.transcript) {
    return res.transcript;
  }

  throw new Error('Neither error, nor transcript received in response!');
};

const checkAiTranscriptGenerationStatus = async (pollingKey: string): Promise<AiTranscriptGenerationResponse> => {
  return (await window.PMW.readLocal('aitranscript/getAiTranscriptGenerationStatus', {
    pollingKey: pollingKey,
  })) as AiTranscriptGenerationResponse;
};

const generateAiTranscript = async (
  audioHashedFilename: string,
  trimStartTime: number,
  trimEndTime: number,
  mediaType: GeneratedFromType,
  loopCycles = 1,
  speed = 1,
  language = AiTranscriptSupportedLanguage.ENGLISH
): Promise<string> => {
  return (await window.PMW.writeLocal('aitranscript/generateAiTranscriptAndGetSubtitles', {
    hashedFilename: audioHashedFilename,
    startTime: trimStartTime,
    endTime: trimEndTime,
    mediaType,
    loopCycles,
    speed,
    language,
  })) as Promise<string>;
};

export const replaceExistingTranscriptOnPosterForGeneratedAiTranscript = async (
  existingTranscriptItem: TranscriptItemObject,
  uidForTranscriptToAdd: string,
  generatedTranscript: GeneratedAiTranscriptData,
  onPosterStartTime: number,
  generatedFrom: TranscriptGeneratedFrom,
  selectOnAdd: boolean
): Promise<void> => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }

  const subtitles: AddSubtitleData[] = [];
  for (const subtitle of generatedTranscript.subtitles) {
    const words: AddWordData[] = [];

    for (const word of subtitle.words) {
      words.push({
        id: word.id,
        endTime: word.end + onPosterStartTime,
        text: word.text,
        startTime: word.start + onPosterStartTime,
      });
    }

    subtitles.push({
      id: subtitle.id,
      endTime: subtitle.end + onPosterStartTime,
      text: subtitle.text,
      startTime: subtitle.start + onPosterStartTime,
      words,
    });
  }

  const transcriptItemToReplace = currentPage.items.getItem(existingTranscriptItem.uid);
  if (transcriptItemToReplace) {
    await currentPage.items.replaceItems.replaceTranscript(
      existingTranscriptItem.uid,
      uidForTranscriptToAdd,
      {
        areAIGenerated: true,
        type: ElementDataType.TRANSCRIPT,
        subtitles,
      },
      {
        selectOnAdd,
        optionsToAdd: {generatedFrom},
      }
    );
  } else {
    // incase the user deletes the existing transcript item to replace, while new subtitles are being regenerated
    await currentPage.items.addItems.addTranscriptItem(
      {
        areAIGenerated: true,
        type: ElementDataType.TRANSCRIPT,
        subtitles,
      },
      {
        optionsToAdd: {uid: uidForTranscriptToAdd, generatedFrom},
        selectOnAdd,
      }
    );
  }
};

export const addNewTranscriptToPosterForGeneratedAiTranscript = async (
  uidForTranscriptToAdd: string,
  generatedTranscript: GeneratedAiTranscriptData,
  onPosterStartTime: number,
  generatedFrom: TranscriptGeneratedFrom,
  selectOnAdd: boolean
): Promise<void> => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  const currentPage = poster.getCurrentPage();

  const subtitles: AddSubtitleData[] = [];
  for (const subtitle of generatedTranscript.subtitles) {
    const words: AddWordData[] = [];

    for (const word of subtitle.words) {
      words.push({
        id: word.id,
        endTime: word.end + onPosterStartTime,
        text: word.text,
        startTime: word.start + onPosterStartTime,
      });
    }

    subtitles.push({
      id: subtitle.id,
      endTime: subtitle.end + onPosterStartTime,
      text: subtitle.text,
      startTime: subtitle.start + onPosterStartTime,
      words,
    });
  }

  await currentPage.items.addItems.addTranscriptItem(
    {
      areAIGenerated: true,
      type: ElementDataType.TRANSCRIPT,
      subtitles,
    },
    {
      optionsToAdd: {uid: uidForTranscriptToAdd, generatedFrom},
      selectOnAdd,
    }
  );
};

export const onGenerateAiTranscriptError = (e: Error): void => {
  console.error('oops', e.message);
};

export const showAiSubtitlesUpsellDialog = async (): Promise<void> => {
  window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_AI_SUBTITLES);
};

export const getKeyForAiTranscriptGenerationResult = (generatedFrom: TranscriptGeneratedFrom): string => {
  return `${generatedFrom.hashedFilename}_${generatedFrom.type}_${generatedFrom.startTime}_${generatedFrom.endTime}`;
};

export const doesTranscriptHaveUserEditedSubtitles = (transcript: TranscriptItemObject): boolean => {
  for (const [, subtitle] of Object.entries(transcript.subtitlesHashmap)) {
    if (subtitle.hasUserEdited) {
      return true;
    }
  }

  return false;
};

export const hasSpeedBeenChangedAfterAiTranscriptGeneration = (itemSpeed: number, transcriptGeneratedFromSpeed?: number): boolean => {
  if (!transcriptGeneratedFromSpeed) {
    return true;
  }

  return itemSpeed !== transcriptGeneratedFromSpeed;
};

export const hasLoopCountChangedAfterAiTranscriptGenerationForVideo = (itemLoopCycles: number, transcriptGeneratedFromLoopCycles?: number): boolean => {
  if (!transcriptGeneratedFromLoopCycles) {
    return true;
  }

  return itemLoopCycles !== transcriptGeneratedFromLoopCycles;
};

export const getAiTranscriptParamsForAudio = (audioItem: AudioItemObject): AiTranscriptForItemWithParams => {
  return {
    uid: audioItem.uid,
    type: 'audio',
    hashedFilename: audioItem.hashedFilename,
    startTime: audioItem.audioPlayer.trim.startTime,
    endTime: audioItem.audioPlayer.trim.endTime,
    onPosterStartTime: audioItem.onPosterStartTime,
    loopCycles: 1,
    speed: audioItem.audioPlayer.speed,
  };
};

export const getAiTranscriptParamsForVideo = (videoItem: VideoItemObject, loopCycles: number): AiTranscriptForItemWithParams => {
  return {
    uid: videoItem.uid,
    type: 'video',
    hashedFilename: videoItem.hashedFilename,
    startTime: videoItem.startTime,
    endTime: videoItem.endTime,
    onPosterStartTime: 0,
    loopCycles,
    speed: 1,
  };
};

export const getSubtitlesWordArrayAfterTextEdited = (editedText: string, subtitleStartTime: number, subtitleEndTime: number): WordObject[] => {
  const wordsToMake = editedText.trim().split(' ');
  const numberOfWords = wordsToMake.length;
  const durationOfEachWord = (subtitleEndTime - subtitleStartTime) / numberOfWords;

  const newWords: WordObject[] = [];

  let startTime = subtitleStartTime;

  for (let i = 0; i < numberOfWords; i++) {
    const cleanWord = removePunctuationFromWord(wordsToMake[i]);

    newWords.push({
      id: getUniqueString(),
      text: cleanWord,
      startTime,
      endTime: startTime + durationOfEachWord,
    });

    startTime = startTime + durationOfEachWord + 0.01;
  }

  return newWords;
};

export const addNewManualTranscript = async (): Promise<void> => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  const currentPage = poster.getCurrentPage();

  const subtitles: AddSubtitleData[] = [
    {
      id: getUniqueString(),
      endTime: 3,
      text: '',
      startTime: 0,
      words: [],
    },
  ];

  await currentPage.items.addItems.addTranscriptItem(
    {
      areAIGenerated: false,
      type: ElementDataType.TRANSCRIPT,
      subtitles,
    },
    {
      optionsToAdd: {},
      selectOnAdd: true,
    }
  );
};

const getNameForVideoItem = (videoOrder = 0): string => {
  return `Video ${videoOrder}`;
};

export const getSelectableItemDataForVideoData = (item: VideoItemObject, loopCycles: number, videoOrder: number): SelectableItemWithAudio => {
  return {
    duration: item.endTime - item.startTime,
    name: getNameForVideoItem(videoOrder),
    imageUrl: repoVideoThumbImageURL(item.hashedFilename, item.fileExtension),
    id: item.uid,
    isSelected: false,
    type: 'video',
    sourceProperties: {
      uid: item.uid,
      type: 'video',
      hashedFilename: item.hashedFilename,
      startTime: item.startTime,
      endTime: item.endTime,
      onPosterStartTime: 0,
      speed: 1,
      loopCycles: loopCycles,
    },
  };
};

export const getSelectableItemDataForAudioData = (item: AudioItemObject): SelectableItemWithAudio => {
  return {
    duration: item.audioPlayer.trim.endTime - item.audioPlayer.trim.startTime,
    name: item.name,
    imageUrl: getRandomThumbnailImageForAudioItem(),
    id: item.uid,
    isSelected: false,
    type: 'audio',
    sourceProperties: {
      uid: item.uid,
      type: 'audio',
      hashedFilename: item.hashedFilename,
      startTime: item.audioPlayer.trim.startTime,
      endTime: item.audioPlayer.trim.endTime,
      onPosterStartTime: item.onPosterStartTime,
      speed: item.audioPlayer.speed,
      loopCycles: 1,
    },
  };
};

export const getAiSubtitlesPanelHeightClass = (aiSubtitleState: AiSubtitleState, itemsCount: number, generationsCount: number, isPremium = false): string => {
  if (aiSubtitleState === AiSubtitleState.IDLE) {
    const numberOfItems = itemsCount;
    const baseStyle = numberOfItems <= 3 ? styles.aiSubtitlesChooseMediaLevel1 : numberOfItems <= 7 ? styles.aiSubtitlesChooseMediaLevel2 : styles.aiSubtitlesChooseMediaLevel3;

    return `${baseStyle} ${isPremium ? styles.premium : ''}`;
  }

  const numberOfItemsGenerating = generationsCount;
  if (numberOfItemsGenerating === 1) {
    return styles.aiSubtitlesGeneratingLevel1;
  }

  if (numberOfItemsGenerating === 2) {
    return styles.aiSubtitlesGeneratingLevel2;
  }

  if (numberOfItemsGenerating === 3) {
    return styles.aiSubtitlesGeneratingLevel3;
  }

  if (numberOfItemsGenerating === 4) {
    return styles.aiSubtitlesGeneratingLevel4;
  }

  return styles.aiSubtitlesGeneratingLevel5;
};
