import type {ReactElement} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import type {ThumbnailData} from '@Components/thumbnail';
import {Thumbnail, THUMBNAIL_TYPE} from '@Components/thumbnail';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import {noop} from '@Utils/general.util';
import {ActivityIndicatorSize, ActivityIndicatorType} from '@Components/activity-indicator/activity-indicator.types';
import {ActivityIndicator} from '@Components/activity-indicator/activity-indicator';
import styles from './controlled-list-item-type-2.module.scss';
import type {Pill} from '@Components/pill';

export interface ControlledListItemType2Props extends BaseControlledListItem {
  thumbnail?: ThumbnailData;
  hasPulsatingDot?: boolean;
  activityIndicatorType?: ActivityIndicatorType;
  subText: string;
  isDisabled?: boolean;
  disableHoverTransitions?: boolean;
  textClasses?: string;
  thumbnailContainerClasses?: string;
  pill?: ReactElement<typeof Pill>;
  textSize?: TextSize;

  onClick?(id?: string, event?: React.MouseEvent<HTMLElement>): void;
}

export function ControlledListItemType2({
  className = '',
  id = '',
  isSelected = false,
  isDisabled = false,
  hasPulsatingDot = false,
  onClick = noop,
  disableHoverTransitions = false,
  textClasses = '',
  textSize = TextSize.XSMALL,
  ...props
}: ControlledListItemType2Props): ReactElement {
  const activityIndicatorType = hasPulsatingDot ? ActivityIndicatorType.NEW_PULSE : props.activityIndicatorType;
  const onClickHandler = (event: React.MouseEvent<HTMLElement>): void => {
    if (onClick && !isDisabled) {
      onClick(id, event);
    }
  };

  const displayThumbnail = (): ReactElement | null => {
    if (props.thumbnail) {
      return (
        <div className={`${styles.thumbnailContainer} ${props.thumbnailContainerClasses}`}>
          {activityIndicatorType ? <ActivityIndicator size={ActivityIndicatorSize.SIZE_16} type={activityIndicatorType} className={styles.pulsePosition} /> : null}
          <Thumbnail
            className={`${props.thumbnail.type === THUMBNAIL_TYPE.CUSTOM ? props.thumbnail.className : ''} ${props.thumbnail.type !== THUMBNAIL_TYPE.ICON ? styles.image : ''}`}
            data={props.thumbnail}
          />
        </div>
      );
    }
    return null;
  };

  const display = (): ReactElement => {
    return (
      <li
        key={id}
        onClick={(event) => {
          onClickHandler(event);
        }}
        className={`
          ${className} spacing-p-1 flexbox flex-items-center flex-justify-start 
          ${isSelected ? styles.selected : ''} 
          ${isDisabled ? styles.disabledList : ''}
          ${styles.listItemContainer} 
          ${!disableHoverTransitions ? 'hover-transition-all' : ''} 
        `}
      >
        {displayThumbnail()}
        <div className={`${styles.textBox} flex-v-row`}>
          <div className="flexbox flex-align-items-center">
            <div>
              <Text val={props.text} className={`${textClasses} ${isDisabled ? styles.disableText : ''} ${isSelected ? 'content-primary' : 'content-body'}`} size={textSize} bold />
            </div>
            <div>{props.pill}</div>
          </div>
          {props.subText ? <Text val={props.subText} className={isSelected ? 'content-primary' : 'content-sub-text'} size={TextSize.XXSMALL} /> : null}
        </div>
      </li>
    );
  };

  return <>{display()}</>;
}
