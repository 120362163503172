import {isMilitaryFormat} from '@PosterWhiteboard/items/layouts/layout';
import {getFormattedDate} from '@Components/table/table-helper';
import {TimeFormat} from '@Components/table/table.types';

export interface CellObject {
  type: CellType;
  value: string | DateCellValue | TimeCellValue | boolean | any[];
}

export interface DateCellValue {
  timestamp: string;
  dateFormat: string;
}

export interface TimeCellValue {
  hours: string;
  minutes: string;
  meridiem: string;
  timeFormat: TimeFormat;
}
export interface VariationData {
  name: string;
  price: string;
}
export interface AddOnData {
  name: string;
  price: string;
}

export enum CellType {
  DATE = 'date',
  TIME = 'time',
  TEAM = 'team',
  VENUE = 'venue',
  HIGHLIGHT = 'highlight',
  MONTH = 'month',
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  ICONS = 'iconsList',
  VARIATION = 'variationsList',
  SIZE_LIST = 'sizesList',
  ADDON = 'addOnsList',
  COLUMN1 = 'column1',
  COLUMN2 = 'column2',
  COLUMN3 = 'column3',
}

export class Cell {
  public type: CellType;
  public value: string | DateCellValue | TimeCellValue | boolean | any[] | undefined = undefined;

  constructor(type: CellType, value: string | DateCellValue | TimeCellValue | boolean | any[]) {
    this.type = type;
    if (typeof value !== 'undefined') {
      this.value = value;
    } else {
      let currentDate: Date;
      switch (this.type) {
        case CellType.DATE:
          currentDate = new Date();
          this.value = {
            timestamp: Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).toString(),
            dateFormat: 'm-d-y',
          };
          break;
        case CellType.TIME:
          this.value = {
            hours: '0',
            minutes: '0',
            meridiem: 'PM',
            timeFormat: TimeFormat.STANDARD,
          };
          break;
        default:
          this.value = this.type;
      }
    }
  }

  public getValue(): string | boolean | any[] | VariationData[] {
    if (this.type === CellType.DATE && isDateCellValue(this.value)) {
      return getFormattedDate(this.value.dateFormat, parseInt(this.value.timestamp, 10));
    }
    if (this.type === CellType.TIME && isTimeCellValue(this.value)) {
      const minutes = `0${this.value.minutes}`.slice(-2);
      let {hours} = this.value;
      if (isMilitaryFormat(this.value.timeFormat)) {
        hours = `0${hours}`.slice(-2);
      }
      return `${hours}:${minutes}${!isMilitaryFormat(this.value.timeFormat) ? ` ${this.value.meridiem}` : ''}`;
    }
    return typeof this.value === 'string' || typeof this.value === 'boolean' || Array.isArray(this.value) ? this.value : '';
    // return this.value ?? '';
  }

  public getType(): string | undefined {
    return this.type;
  }

  public toObject(): CellObject {
    return {
      type: this.type ?? '',
      value: this.value ?? '',
    };
  }
}

export const isDateCellValue = (cellValue: string | DateCellValue | TimeCellValue | boolean | any[] | undefined): cellValue is DateCellValue => {
  return !!cellValue && typeof cellValue !== 'string' && typeof cellValue !== 'boolean' && 'timestamp' in cellValue && 'dateFormat' in cellValue;
};

export const isTimeCellValue = (cellValue: string | DateCellValue | TimeCellValue | boolean | any[] | undefined): cellValue is TimeCellValue => {
  return !!cellValue && typeof cellValue !== 'string' && typeof cellValue !== 'boolean' && 'hours' in cellValue && 'minutes' in cellValue && 'meridiem' in cellValue;
};

export const createCellFromObject = (cellObject: CellObject): Cell => {
  return new Cell(cellObject.type, cellObject.value);
};
