import type {CSSProperties, MouseEvent, ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import AudioItemWave from '@Components/base-grid/components/audio-item-wave/audio-item-wave';
import {PMW_COLORS_NEUTRAL} from '@Utils/color.util';
import {pauseAudioGridItem, playAudioGridItem} from '@Components/base-grid/components/grid-user-audio-item/grid-audio-item-reducer';
import {Dropdown} from '@Components/dropdown-v2';
import {ALIGNMENT_TYPE, DROPDOWN_POSITION} from '@Components/dropdown-v2/dropdown.types';
import {ControlledList} from '@Components/controlled-list';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {v4 as uuidv4} from 'uuid';
import {InputField} from '@Components/input-field-v2';
import type {InputFieldControlItemEvent, InputFieldControls} from '@Components/input-field-v2/input-field.types';
import {InputControlItemType, InputFieldSize} from '@Components/input-field-v2/input-field.types';
import type {GridUserAudioItemStorage} from '@Components/base-grid/components/grid-user-audio-item';
import {COLLECTION_MEDIA_GRID_ID, USER_AUDIO_UPLOADS_GRID_ID, USER_UPLOADS_SELECTION_GRID_ID} from '@Panels/user-media-panel/user-media-panel.types';
import {deleteGridItems, getGridItemStorageForId} from '@Components/base-grid';
import {openMoveMultimediaModal} from '@Modals/move-multimedia-modal';
import {removeUserItem, UserMediaType} from '@Libraries/user-media-library';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import {openConfirmDeleteModal} from '@Modals/confirm-delete-modal';
import {getUserId} from '@Libraries/user.library';
import {openMessageModal} from '@Modals/message-modal';
import useWindowSize from '@Hooks/useWindowSize';
import {isTouchDevice} from '@Utils/browser.util';
import {openAudioItemRenameModal} from '@Modals/audio-item-rename-modal';
import {type ContainerQueryObject, useContainerQuery} from '@Hooks/useContainerQuery';
import {getRandomThumbnailImageForAudioItem} from '@Libraries/poster-audio-item.library';
import styles from './audio-item.module.scss';
import {useAppDispatch, useAppSelector} from '@/hooks';
import type {AudioItemProps} from './audio-item.types';
import {AudioItemDropdownItemId} from './audio-item.types';
import {AudioItemProgressbar} from '@Components/base-grid/components/audio-item-progressbar/audio-item-progressbar';

const SMALL_SCREEN_WIDTH = 560;

const queryObject: ContainerQueryObject = {
  small: {
    minWidth: 0,
    maxWidth: 200,
  },
  large: {
    minWidth: 201,
  },
};

export function AudioItem({showThreeDots = false, ...props}: AudioItemProps): ReactElement {
  const audioGridItemPlaying = useAppSelector((state) => {
    return state.audioGridItem.audioGridItemPlaying;
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const params = useContainerQuery(containerRef, queryObject);
  const [isHovered, setIsHovered] = useState(false);
  const [seekTime, setSeekTime] = useState(0);
  const [isRenaming, setIsRenaming] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  const isPlaying = audioGridItemPlaying === props.uid;
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioGraphicBackdropURL = useRef(getRandomThumbnailImageForAudioItem());
  const dispatch = useAppDispatch();
  const editedtitle = useRef(props.title);
  const id = useRef(uuidv4());
  const renamingCloseBtnID = `rename-close-${id.current}`;
  const renamingDoneBtnID = `rename-done-${id.current}`;
  const textRef = useRef<HTMLSpanElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const {windowWidth} = useWindowSize();
  const currentCollectionId = useAppSelector((state) => {
    return state.mediaUploads.collection;
  });
  const audioItemData = useAppSelector((state) => {
    return getGridItemStorageForId(state, currentCollectionId ? COLLECTION_MEDIA_GRID_ID : USER_AUDIO_UPLOADS_GRID_ID, props.uid) as GridUserAudioItemStorage;
  });

  const isAudioNameLarge = (): boolean => {
    if (!textContainerRef.current || !textRef.current) {
      return false;
    }
    return textRef.current.offsetWidth > textContainerRef.current.offsetWidth;
  };

  useEffect(() => {
    return () => {
      if (isPlaying && audioRef && audioRef.current) {
        dispatch(pauseAudioGridItem(props.uid));
        audioRef.current.pause();
      }
    };
  }, []);

  const getStyleObj = (): CSSProperties => {
    const styleObj: CSSProperties = {};
    styleObj.width = props.width ? `${props.width}px` : '100%';
    styleObj.height = props.height ? `${props.height}px` : '100%';
    return styleObj;
  };

  const shouldDisplayPlayBtn = (): boolean => {
    return (isHovered || isSmallScreen()) && !isPlaying;
  };

  const onPlayClicked = async (e: MouseEvent): Promise<void> => {
    e.stopPropagation();
    dispatch(playAudioGridItem(props.uid));
    if (audioRef && audioRef.current) {
      await audioRef.current.play();
    }
  };
  const onPauseClicked = (e: MouseEvent): void => {
    e.stopPropagation();
    dispatch(pauseAudioGridItem(props.uid));
    if (audioRef && audioRef.current) {
      audioRef.current.pause();
    }
  };
  const handleSeekTimeUpdate = (): void => {
    if (audioRef.current) {
      const currentSeekTime = audioRef.current.currentTime;
      setSeekTime(currentSeekTime);
    }
  };

  const onAudioEnded = (): void => {
    dispatch(pauseAudioGridItem(props.uid));
  };
  const getAudioPlayer = (): ReactElement => {
    return (
      <audio ref={audioRef} onTimeUpdate={handleSeekTimeUpdate} onEnded={onAudioEnded}>
        <source src={props.source} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    );
  };

  const getAudioPlayingStyles = (): string => {
    return `${styles.playing} radius-round`;
  };

  const getAudioNotPlayingStyles = (): string => {
    return 'radius-8 _full-width _full-height';
  };

  const getAudioControlsOverlay = (): ReactElement => {
    return (
      <div className={`${styles.audioControlsOverlay} _cursor-pointer flex-center _full-height _full-width`}>
        <div className={`${styles.audioControlsHoverBackdrop} ${isPlaying ? getAudioPlayingStyles() : getAudioNotPlayingStyles()} flex-center`} />
        <Icon
          onClick={shouldDisplayPlayBtn() ? onPlayClicked : onPauseClicked}
          className={`${styles.playOverlayIcon} content-body-white `}
          icon={shouldDisplayPlayBtn() ? 'icon-play-video' : 'icon-pause'}
          size={IconSize.SIZE_ICON_24}
          shape={IconShape.NONE}
          type={IconType.NONE}
        />
      </div>
    );
  };

  const shouldAnimateAudioWave = (): boolean => {
    return !isHovered && isPlaying;
  };

  const getWaveOverlay = (): ReactElement => {
    const audioItemWidth = 36;
    const audioItemHeight = 16;
    return (
      <AudioItemWave className={styles.waveOverlay} isAnimating={shouldAnimateAudioWave()} width={audioItemWidth} height={audioItemHeight} color={PMW_COLORS_NEUTRAL.NEUTRAL_0} />
    );
  };

  const getAudioItemGraphicContainer = (): ReactElement => {
    return (
      <div className={`${styles.audioItemGraphicContainer} flex-center _full-height `}>
        {isPlaying ? (
          <AudioItemProgressbar value={(seekTime * 100) / props.duration}>
            <img loading="lazy" className="radius-round" src={audioGraphicBackdropURL.current} alt="" style={{width: '80%', height: '80%'}} />
            {isHovered || isSmallScreen() ? getAudioControlsOverlay() : getWaveOverlay()}
          </AudioItemProgressbar>
        ) : (
          <>
            <img loading="lazy" className="radius-8 _full-width _full-height" src={audioGraphicBackdropURL.current} alt="" />
            {isHovered || isSmallScreen() ? getAudioControlsOverlay() : getWaveOverlay()}
          </>
        )}
      </div>
    );
  };

  const onRenamingDone = (): void => {
    if (!title.trim()) {
      setTitle(editedtitle.current);
      return;
    }
    editedtitle.current = title;
    if (props.onTitleRenamed) {
      void props.onTitleRenamed(title);
    }
  };

  const onRenamingCancelled = (): void => {
    setTitle(editedtitle.current);
  };

  const onRenameFieldChanged = (value: string): void => {
    setTitle(value);
  };

  const getRenamingState = (): ReactElement => {
    const inputControls: InputFieldControls = {
      showDefaultControls: false,
      showSingleDefaultControlOnly: false,
      customControls: [
        {
          id: renamingCloseBtnID,
          type: InputControlItemType.ICON_BTNS,
          icon: 'icon-close',
          className: 'content-danger',
        },
        {
          id: renamingDoneBtnID,
          type: InputControlItemType.ICON_BTNS,
          icon: 'icon-check',
          className: 'content-success',
        },
      ],
      onClick(iconName: string, e?: InputFieldControlItemEvent) {
        if (e) {
          e.stopPropagation();
        }
        if (iconName === renamingDoneBtnID) {
          onRenamingDone();
        } else if (iconName === renamingCloseBtnID) {
          onRenamingCancelled();
        } else {
          throw new Error('Unhandled button id in rename flow');
        }
        setIsRenaming(false);
      },
    };

    return (
      <div className="_full-height flexbox align-items-center">
        <InputField input={title} className={styles.input} size={InputFieldSize.SMALL} onInputChange={onRenameFieldChanged} controls={inputControls} stopClickPropagation />
      </div>
    );
  };

  const onRenameClicked = (_: string, e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();

    setIsRenaming(true);
  };

  const openRenameModal = (): void => {
    openAudioItemRenameModal({
      audioName: title,
      renameAudio: async (name: string) => {
        onRenameFieldChanged(name);
        if (props.onTitleRenamed) {
          await props.onTitleRenamed(name);
        }
      },
    });
  };

  const onItemsMoved = (items: GridItemStorage[]): void => {
    const toRemoveIds: string[] = [];
    for (const gridItem of items) {
      toRemoveIds.push(gridItem.id);
    }
    dispatch(
      deleteGridItems({
        gridId: currentCollectionId ? COLLECTION_MEDIA_GRID_ID : USER_AUDIO_UPLOADS_GRID_ID,
        gridSelectionGroupId: USER_UPLOADS_SELECTION_GRID_ID,
        itemIdsToDelete: toRemoveIds,
      })
    );
  };

  const onMoveClicked = (_: string, e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();

    openMoveMultimediaModal({
      multimediaType: UserMediaType.AUDIO,
      currentCollectionId,
      selectedItems: [audioItemData],
      onMoveItems: onItemsMoved,
    });
  };

  const removeMedia = async (): Promise<void> => {
    const toRemoveIds: string[] = [];
    const userID = getUserId();
    if (!userID) {
      return;
    }

    try {
      const isNotOwner = currentCollectionId && userID !== audioItemData.uploaderId;
      if (isNotOwner) {
        openMessageModal({
          title: window.i18next.t('pmwjs_can_not_remove_image_title'),
          text: window.i18next.t('pmwjs_can_not_remove_image'),
        });
        return;
      }

      await removeUserItem(audioItemData.hashedFilename, audioItemData.uploaderId, UserMediaType.AUDIO);
      toRemoveIds.push(audioItemData.id);
    } catch (e) {
      console.error(e);
    }

    dispatch(
      deleteGridItems({
        gridId: currentCollectionId ? COLLECTION_MEDIA_GRID_ID : USER_AUDIO_UPLOADS_GRID_ID,
        gridSelectionGroupId: USER_UPLOADS_SELECTION_GRID_ID,
        itemIdsToDelete: toRemoveIds,
      })
    );
  };
  const onDeleteClicked = (_: string, e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();

    openConfirmDeleteModal({
      text: window.i18next.t('pmwjs_remove_user_upload_item'),
      showBtnDeletingState: true,
      onDeleteConfirmation: removeMedia,
    });
  };

  const getDropdownPopup = (): ReactElement => {
    return (
      <div className={styles.popup}>
        <ControlledList
          className="_unmargin"
          items={[
            {
              id: AudioItemDropdownItemId.RENAME,
              className: 'radius-8',
              type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
              icon: 'icon-pencil',
              text: window.i18next.t('pmwjs_rename'),
              onClick: isSmallScreen() ? openRenameModal : onRenameClicked,
            },
            {
              id: AudioItemDropdownItemId.MOVE,
              className: 'radius-8',
              type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
              icon: 'icon-move-folder',
              text: window.i18next.t('pmwjs_move'),
              onClick: onMoveClicked,
            },
            {
              id: AudioItemDropdownItemId.DELETE,
              className: 'radius-8',
              type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
              icon: 'icon-delete',
              iconClasses: styles.deleteIcon,
              text: window.i18next.t('pmwjs_delete'),
              onClick: onDeleteClicked,
            },
          ]}
        />
      </div>
    );
  };
  const isSmallScreen = (): boolean => {
    return windowWidth < SMALL_SCREEN_WIDTH || isTouchDevice();
  };
  const moreOptionsDropdown = (): ReactElement => {
    return (
      <div className=" _fit-height _full-width flexbox justify-content-end">
        <Dropdown
          id={props.uid}
          className={` rename-audio-option `}
          popupClassName="radius-8"
          popupSpacingClass="spacing-p-3"
          alignment={ALIGNMENT_TYPE.BOTTOM_START}
          selector={<Icon icon="icon-menu-dots" size={IconSize.SIZE_ICON_16} type={IconType.GHOST} shape={IconShape.SQUARE} />}
          popup={getDropdownPopup()}
          position={DROPDOWN_POSITION.FIXED}
          popUpHasCustomWidth
          onOpen={(): void => {
            setAreOptionsOpen(true);
          }}
          onClose={(): void => {
            setAreOptionsOpen(false);
          }}
        />
      </div>
    );
  };

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const getInfo = (): ReactElement => {
    return (
      <div
        className={`${(isHovered && showThreeDots) || areOptionsOpen ? styles.infoContainerWithThreeDotsWhenHovered : styles.infoContainer} _full-height flexbox align-items-center`}
      >
        <div className="flexWrap _full-width _full-height align-content-center ">
          <div className={`flexbox flex-column ${params.small ? 'flex-content-between _full-height' : ''} `}>
            <div className="flexbox">
              <div ref={textContainerRef} className={`${styles.textContainer} content-body`}>
                <Text ref={textRef} val={title} size={TextSize.XXSMALL} bold className={` ${isAudioNameLarge() ? styles.textContent : ''}`} />
              </div>
            </div>
            {isPlaying ? (
              <Text val={`${formatDuration(seekTime)} / ${formatDuration(props.duration)}`} size={TextSize.XXSMALL} className="content-primary" />
            ) : (
              <Text val={formatDuration(isPlaying ? seekTime : props.duration)} size={TextSize.XXSMALL} />
            )}
            {props.categories && props.categories[0] !== undefined && params.large ? (
              <div className={` flexbox  align-items-center _overflow-hidden`}>
                <Text val={props.categories[0]} size={TextSize.XXSMALL} className="content-disabled -truncated-text" />
                <div className={`${styles.dot} radius-round `} />
                <Text val={props.categories[1]} size={TextSize.XXSMALL} className="content-disabled -truncated-text" />
              </div>
            ) : (
              []
            )}
          </div>
        </div>
      </div>
    );
  };

  const getContent = (): ReactElement => {
    return (
      <div className="_full-height">
        <div className="_full-width flexbox _full-height">
          {getAudioItemGraphicContainer()}
          {getInfo()}
          {showThreeDots && (areOptionsOpen || isHovered || isSmallScreen()) ? moreOptionsDropdown() : []}
          {getAudioPlayer()}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (audioRef && audioRef.current && !isPlaying) {
      audioRef.current.pause();
    }
  }, [audioGridItemPlaying]);

  useEffect(() => {
    return () => {
      if (isPlaying && audioRef && audioRef.current) {
        dispatch(pauseAudioGridItem(props.uid));
        audioRef.current.pause();
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      key={props.uid}
      className={`${styles.body} border-s-standard radius-12`}
      style={getStyleObj()}
      onMouseEnter={(): void => {
        setIsHovered(true);
      }}
      onMouseLeave={(): void => {
        setIsHovered(false);
      }}
    >
      {isRenaming ? getRenamingState() : getContent()}
    </div>
  );
}
