import type {Point} from '@Utils/math.util';
import {scrollPosterHorizontalWithDelta} from '@Components/poster-whiteboard/components/poster-horizontal-scroll';
import {scrollPosterVerticalWithDelta} from '@Components/poster-whiteboard/components/poster-vertical-scroll';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

let lastPanPosition: Point | undefined;
let isPanning: NodeJS.Timeout;

export class PosterPan extends CommonMethods {
  public poster: Poster;

  public constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public initCanvasPanEvent(): void {
    const whiteboardContainer = window.document.getElementById('poster-whiteboard-container');
    if (!whiteboardContainer) {
      return;
    }

    const whiteboardContainerEventManager = new Hammer.Manager(whiteboardContainer);
    const pan = new Hammer.Pan({
      event: 'pan',
    });

    whiteboardContainerEventManager.add(pan);
    whiteboardContainerEventManager.on('pan', this.onWhiteboardContainerPanned.bind(this));
  }

  private onWhiteboardContainerPanned(data: HammerInput): void {
    clearTimeout(isPanning);

    isPanning = setTimeout(() => {
      lastPanPosition = undefined;
    }, 100);

    if (data.pointerType === 'mouse') {
      return;
    }
    if (this.poster.scaling.scale <= this.poster.scaling.getFitToScreenScale()) {
      return;
    }

    const whiteboardContainer = window.document.getElementById('poster-whiteboard-container');

    if (!whiteboardContainer || data.target !== whiteboardContainer) {
      return;
    }

    if (lastPanPosition) {
      this.panCanvas(data.center, lastPanPosition);
    }

    lastPanPosition = data.center;
  }

  public panCanvas(center: Point, lastCenter: Point): void {
    if (lastCenter) {
      const panningValues = this.getPanningValues(center, lastCenter);
      scrollPosterHorizontalWithDelta(panningValues.x);
      scrollPosterVerticalWithDelta(panningValues.y);
    }
  }

  public getPanningValues(center: Point, lastCenter: Point): Point {
    return {
      x: lastCenter.x - center.x,
      y: lastCenter.y - center.y,
    };
  }
}
