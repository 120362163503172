import type {
  LinkedInPrivacySettings,
  MetaMediaType,
  PostState,
  PrivacySettings,
  SocialMediaAccountId,
  TikTokPrivacySettings,
  YouTubePrivacySettings,
  GoogleBusinessProfilePostType,
  GoogleBusinessProfileCtaType,
  PlatformMediaType,
} from '@Components/social-media/post.vo';
import type {LoadingStates} from '@Utils/loading.util';

export type PosterHashedId = string;

export enum SocialPostPublishingAction {
  PUBLISH = 'PUBLISH',
  SCHEDULE = 'SCHEDULE',
  SAVE = 'SAVE',
}

export enum RetryStatus {
  NOT_RETRYING = 'NOT_RETRYING',
  RETRYING = 'RETRYING',
}

export interface SocialPostCover {
  timestamp: number | null;
  coverUrl: string;
}

export type SocialMediaPublishingParams =
  | InstagramPublishingParams
  | TwitterPublishingParams
  | FacebookPublishingParams
  | TikTokPublishingParams
  | YouTubePublishingParams
  | LinkedInPublishingParams
  | PinterestPublishingParams
  | GoogleBusinessProfilePublishingParams
  | ThreadsPublishingParams;

export interface LinkedInPublishingParams {
  caption: string;
  idSocialMediaAccount: number;
  privacySettings: LinkedInPrivacySettings;
  title: string;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface PinterestPublishingParams {
  caption: string;
  idSocialMediaAccount: number;
  privacySettings: PrivacySettings;
  title: string;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  boardId: null | string;
  websiteLink: null | string;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface TwitterPublishingParams {
  caption: string;
  idSocialMediaAccount: number;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface FacebookPublishingParams {
  caption: string;
  title: string;
  idSocialMediaAccount: number;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface InstagramPublishingParams {
  caption: string;
  idSocialMediaAccount: number;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface TikTokPublishingParams {
  caption: string;
  title: string;
  disableDuet: boolean;
  disableComment: boolean;
  disableStitch: boolean;
  isSelfBrand: boolean;
  isPromotingThirdParty: boolean;
  idSocialMediaAccount: number;
  privacySettings: TikTokPrivacySettings;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface YouTubePublishingParams {
  caption: string;
  title: string;
  privacySettings: YouTubePrivacySettings;
  idSocialMediaAccount: number;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface GraphicMeta {
  isVideo: boolean;
  graphicURL: string;
  name: string;
  mediaType?: MetaMediaType;
  cover?: SocialPostCover;
}

export interface ResizeMeta {
  isResizeNeeded: boolean;
}

export interface GoogleBusinessProfilePublishingParams {
  caption: string;
  postType: GoogleBusinessProfilePostType;
  ctaType: null | GoogleBusinessProfileCtaType;
  ctaURL: string;
  eventStartTime: null | number;
  eventEndTime: null | number;
  idSocialMediaAccount: number;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface ThreadsPublishingParams {
  caption: string;
  idSocialMediaAccount: number;
  publishedURL: null | string;
  publishErrorMessage: null | string;
  publishId: null | string;
  state: PostState;
  graphicsMeta: Record<PosterHashedId, GraphicMeta>;
  isOriginalDeleted: boolean;
}

export interface PublishingParamsInsertionData {
  publishingParams: SocialMediaPublishingParams;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsCaptionPayload {
  caption: string;
  idSocialMediaAccount: SocialMediaAccountId;
  isMultiCharacterChange: boolean;
}

export interface PublishingParamsCommonCaptionPayload {
  caption: string;
  isMultiCharacterChange: boolean;
}

export interface PublishingParamsIsPromotingThirdParty {
  isPromotingThirdParty: boolean;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsIsSelfBrand {
  isSelfBrand: boolean;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsDisableComment {
  disableComment: boolean;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsDisableStitch {
  disableStitch: boolean;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsDisableDuet {
  disableDuet: boolean;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsMediaTypePayload {
  mediaType: MetaMediaType;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsTitlePayload {
  title: string;
  idSocialMediaAccount: SocialMediaAccountId;
  areURLsAllowed: boolean;
}

export interface PublishingParamsLinkedInPrivacySettingsPayload {
  privacySettings: LinkedInPrivacySettings;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsYouTubePrivacySettingsPayload {
  privacySettings: YouTubePrivacySettings;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsTikTokPrivacySettingsPayload {
  privacySettings: TikTokPrivacySettings;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsGoogleBusinessProfileCtaTypePayload {
  ctaType: GoogleBusinessProfileCtaType | null;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsGoogleBusinessProfilePostTypePayload {
  postType: GoogleBusinessProfilePostType;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingStatusForAccountPayload {
  publishingStatus: LoadingStates;
  idSocialMediaAccount: SocialMediaAccountId;
  platformMediaType: PlatformMediaType;
}

export interface PublishingParamsPublishErrorMessagePayload {
  publishErrorMessage: string | null;
  idSocialMediaAccount: SocialMediaAccountId;
  platformMediaType: PlatformMediaType;
}

export interface PublishingParamsWebsiteLinkPayload {
  websiteLink: string | null;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsBoardIdPayload {
  boardId: string | null;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsPublishIdPayload {
  publishId: string | null;
  idSocialMediaAccount: SocialMediaAccountId;
}

export interface PublishingParamsSocialPostCoverPayload {
  coverObject: SocialPostCover;
  idAccount: number;
  idDesign: string;
  platformMediaType: PlatformMediaType;
}

export interface PublishingParamsCommonPostCoverPayload {
  coverObject: SocialPostCover;
  idDesign: string;
}

export enum PublishingMediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type MultiPostDesignSelectorPurpose = MultiPostDesignSelectorPurposes.Edit | MultiPostDesignSelectorPurposes.Resize;

export enum MultiPostDesignSelectorPurposes {
  Edit = 'edit',
  Resize = 'resize',
}

export type DesignMeta = {
  isVideo: boolean;
  designName: string;
};
